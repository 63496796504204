import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
// import news_active from "../../assets/images/news_active.svg";
import SideBarNew from "../../components/SideBar/SideBarNew";
import { INDEX_URL, SITE_TITLE } from "../../helpers/constants";
import { seo } from "../../helpers/seo";
import * as genericService from '../../services/genericService';
import { useSelector ,shallowEqual} from "react-redux";
import NewsRow from "./NewsRow";
import FlavorImages from "../../assetsFolderImport";
const News = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { authState } = useSelector(
    (state) => ({ authState: state.auth }),
    shallowEqual
  )
  const setNewsDetail = (news) => {
    //console.log("news at news is :", news.newsid)
    history.push(INDEX_URL + "news/" + news.newsid)
  }
  const [newsList, setNewsList] = useState([]);
  const getData = async () => {
    const newReponse = await genericService.post("news/newslist", { language: authState.activeLanguage });
    setNewsList(newReponse.data.news);
  };

  const on_load = () => {
    getData();

    seo({
      title: SITE_TITLE,
      metaDescription: "",
    });
  };

  useEffect(() => {
    on_load();
  }, []);

  return (
    <>
      <React.Fragment>
        <section className="section-home " id="login-page">
          <div className="middle-content d-flex justify-content-between h-100">
            <SideBarNew
              page="news"
              onClick={(e) => {
                history.push(INDEX_URL + e);
              }}
            />

            <div className={`main_ui_news  m-0 p-0`}>
              <div className={`row  main_ui_news  m-0 ml-3 ml-md-0 px-md-4 pt-4 position-absolute`}>
                <div className={`col-12 d-md-block d-none align-items-center  `}>
                  <img src={FlavorImages.news_active} className="home_title_icon vertical_align_sub" alt="..." />
                  <label className="text_34_700 mb-0 ml-3 ">
                    {t("News.News")}
                  </label>
                </div>

                <div className="col-12 mt-md-0 mt-5 pt-3 px-0  row ">
                  {newsList.map((news, index) => {
                    return (
                      <>
                        <div
                          className="col-sm-4 mt-2 d-none d-md-block  mb-md-5 mb-2 text-align-center "
                        >
                          <NewsRow
                            setNewsDetail={() => setNewsDetail(news)}
                            news={news} />

                        </div>
                        <div
                          className="col-sm-4 mt-2 d-md-none d-block  mb-md-5 mb-2 text-align-center "
                        >
                          <NewsRow
                            setNewsDetail={""}
                            news={news} />
                        </div>

                      </>
                    );
                  })}
                </div>


              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    </>
  );
};

export default News;
