import ClickAwayListener from "@mui/material/ClickAwayListener";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { livestreamSlice } from "../../slice/liveStreamSlice";
// import check_empty from "../../assets/images/check_empty.svg";
import { useTranslation } from "react-i18next";
// import checked from "../../assets/images/checked.svg";
import { shallowEqual, useSelector } from "react-redux";
// import  down_arrow from "../../assets/images/statistics-down.svg";
// import down_arrow_helkama from "../../assets/Helkama_images/chevron-circle-down_black.svg";
// import down_arrow_white_helkama from "../../assets/Helkama_images/chevron-circle-down_white.svg";
// import xCheckmark from "../../assets/images/xCheckmark.svg"
import _ from "lodash";
// import down_array_grey2 from "../../assets/images/down_array_grey2.svg";
import FlavorImages from "../../assetsFolderImport";


const Stats_Modal = (props) => {
  const { t } = useTranslation();
  // console.log("margin cond", props.SaveCo2Margin)
  const dispatch = useDispatch();
  const { liveStreamState } = useSelector(
    (state) => ({ liveStreamState: state.liveStream }),
    shallowEqual
  );
  const streamAction = livestreamSlice.actions;
  const [openCloseDropdown, setOpenCloseDropdown] = React.useState(false);
  const [DropDownOptions, setDropDownOptions] = useState([])
  const [devices, setDevices] = useState([])
  const [deviceName, setDeviceName] = useState()
  const [streamDevice, setStreamDevice] = useState([])
  const [deviceId, setDeviceId] = useState('')
  const liveStreamAction = livestreamSlice.actions;
  const [theme, setTheme] = useState();
  const [themeClasses, setThemeClasses] = useState({ background: ' bg_dark_helkama ', foreground: ' text-white ' })
  const onClick = (index) => {
    var tempOptions = _.cloneDeep(DropDownOptions);
    tempOptions[index].isSelected = !tempOptions[index].isSelected
    const selectedList = tempOptions.filter(item => item.isSelected)

    // if its single selection, selecting another item will deselect the other item
    if (props.singleSelection && selectedList.length > 1) {
      let remove = selectedList.filter(item => item !== tempOptions[index])[0]
      tempOptions[tempOptions.indexOf(remove)].isSelected = !tempOptions[tempOptions.indexOf(remove)].isSelected
    }

    setDropDownOptions(tempOptions)
  }

  const onDeviceClick = (deviceId, deviceName, device) => {
    setStreamDevice(device)
    setDeviceName(deviceName);
    setDeviceId(deviceId)
  }

  const onSelect = () => {
    props.onSelect(DropDownOptions)

    // props.getStreamDevices()
    // dispatch((liveStreamAction.setStreamDeviceData({ streamDeviceData: streamDevice })))
    // props.setDeviceName(deviceName)
    // dispatch(
    //   streamAction.setStreamDevice({
    //     streamDevice: deviceId,
    //   })
    // );

    setOpenCloseDropdown(false)
  }

  const onCancelClick = () => {
    setOpenCloseDropdown(false)
    setDropDownOptions(props.data)
  }

  useEffect(() => {
    if (props.theme) {
      let theme = props.theme === 'dark' ?
        { background: ' bg_dark_helkama ', foreground: ' text-white ' } :
        { background: ' bg-white ', foreground: ' text_dark_helkama ' }
      setThemeClasses(theme)
    }
    setDropDownOptions(props.data)
  }, [props.data, props.theme])

  return (
    <>
            <ClickAwayListener onClickAway={() => setOpenCloseDropdown(false)}>

      <div className={`${(props.SaveCo2Margin && props.classes.split('_')[0] !== 'device') || props.classes.split('_')[0] === 'summary' || props.insideCard ? '' : 'col-3'} 
      ${process.env.REACT_APP_ASSETS === 'helkama' ? themeClasses.background : 'bg-navy'} float-right dropdow-label search_capsule px-2 mb-2`}
        style={{
          marginLeft: props.insideCard ? '' : "10px",
          marginTop: "1%",
          width: props.width ? props.width : 'auto',
          position: props.inStats ? 'absolute' : '',
          right: props.inStats ? '1rem' : '',
          zIndex: props.inStats ? '1' : '',
          borderRadius: openCloseDropdown ? '12px 12px 0 0' : '12px',
        }}
      >
        {props.label ? (
          <label className={`text-center px-2 text-uppercase ${themeClasses.foreground}`}>
            {props.label}
          </label>
        ) : ""}
            <div onClick={() => setOpenCloseDropdown(!openCloseDropdown)}>
              <img style={{ marginTop: '-4px', position: 'relative' }} src={props.insideCard ?
                (FlavorImages.down_arrow ) :
                (FlavorImages.down_arrow_white )
              } className="home_title_icon2 size_15 cursor-pointer" alt="..." />
            </div>
        <div
          className={`
            ${openCloseDropdown ? (props.topFilters ? 'extended-label' : (props.insideCard ? 'extended-label-light' : 'extended-label-2')) : 'd-none'}
            ${props.SaveCo2Margin && openCloseDropdown ? (props.theme + '-before-left') : (props.theme + '-after-right')}
            ${props.classes.split('_')[0] === 'month' ? (props.theme + '-before-left ' + props.theme + '-after-right') : ''}
            ${props.classes.split('_')[0] === 'summary' ? "summary_modal_custom" : ""}
          `}
          style={{ width: '100%' }}
        ></div>



              {openCloseDropdown && (
                <div className={`stats-modal-width shadow-lg
                  ${props.SaveCo2Margin ? 'stats-dropdown-left-popup' : 'stats-dropdown-right-popup'} 
                  ${process.env.REACT_APP_ASSETS === 'helkama' ? themeClasses.background : 'bg-navy'}
                  border-0 p-1 pb-3 Statisticscard position-absolute ${props.classes}`
                }
                style={{
                  width: "13.938rem",
                  height: "fit-content",
                  paddingBottom: "8px",
                  zIndex: "99999",
                  marginTop: "3px",
                  whiteSpace: "nowrap"
                }}>
                  <div className="option-container m-3">
                    {DropDownOptions && DropDownOptions.map((dataRow, index) => {
                      return (
                        <div className={`d-flex justify-content-between ${index !== (DropDownOptions.length - 1) ? 'mb-3' : ''}`} key={index}>
                          <div>
                              <label className={`${themeClasses.foreground} text-truncate`} style={{ fontSize: "16px", fontWeight: 'normal', maxWidth:"140px" }}>
                                {dataRow.value}
                              </label>
                            </div>

                          <div className="d-flex justify-content-end pr-1" >
                              {dataRow.isSelected === true ? (
                                  <img
                                className="checkBoxStatistics"
                                    src={FlavorImages.checked}
                                    alt="checked"
                                    onClick={() => onClick(index)}
                                  />
                              ) : (
                                <img
                                className="checkBoxStatistics"
                                  src={FlavorImages.xCheckmark}
                                  alt="unchecked"
                                  onClick={() => onClick(index)}
                                />
                              )}
                            </div>
                          </div>
                      )
                    })}
                  </div>

                  <div className="mx-3">
                    <div className="d-flex justify-content-between">
                      <button
                        className="btn bg-grey2 font-bold py-0  btn-sm  text-grey"
                        onClick={() => onCancelClick()}
                        variant="light"
                        style={{ width: "83px" }}
                      >
                        {t("button.Cancel")}
                      </button>

                      <button
                        className={`btn statsButton py-0 btn-sm text-white bg-skyblue bg_green_helkama`}
                        onClick={() => onSelect()}
                        variant="light"
                      >
                        {t("button.Select")}
                      </button>
                    </div>
                  </div>

                  <div>
                  </div>
                </div>
              )}
            </div>

        </ClickAwayListener>
    </>
  );
};

export default Stats_Modal;
