import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual } from "react-redux";
import * as helper_functions from "../../helpers/helper_functions";
import { Marker, InfoWindow } from "react-google-maps";
// import battery_full from "../../assets/images/battery_full_info.svg";
// import battery_three_quarters from "../../assets/images/battery_three_quarters_info.svg";
// import battery_half from "../../assets/images/battery_half_info.svg";
// import battery_quarter from "../../assets/images/battery_quarter_info.svg";
// import battery_empty from "../../assets/images/battery_empty_info.svg";
// import battery_full_helkama from "../../assets/Helkama_images/battery-full.svg";
// import battery_three_quarters_helkama from "../../assets/Helkama_images/battery-three-quarters.svg";
// import battery_half_helkama from "../../assets/Helkama_images/battery-half.svg";
// import battery_quarter_helkama from "../../assets/Helkama_images/battery-quarter.svg";
// import battery_empty_helkama from "../../assets/Helkama_images/battery-empty.svg";
// import tachometer_alt from "../../assets/Helkama_images/tachometer-alt.svg";
// import clock_helkama from "../../assets/Helkama_images/clock.svg";
// import map_marker_alt_helkama from "../../assets/Helkama_images/map-marker-alt.svg";
// import wifi_disconnected from "../../assets/Helkama_images/wifi_disconnected.svg";
// import wifi_conneted from "../../assets/Helkama_images/wifi.svg";
import { devicesSlice } from "../../slice/deviceSlice";
import { useDispatch } from "react-redux";
import * as appExport from "../../App"
import Geocode from "react-geocode";
import FlavorImages from './../../assetsFolderImport';

const DeviceMarker = (props) => {
	Geocode.setApiKey(process.env.REACT_APP_MAP_API_KEY);
	Geocode.setLanguage("en");
	const { t } = useTranslation();
	const { mapSettingsState } = useSelector(
		(state) => ({ mapSettingsState: state.mapSettings }),
		shallowEqual
	);
	const { devicesState } = useSelector(
		(state) => ({ devicesState: state.devices }),
		shallowEqual
	);
	const [address, setAddress] = useState(t("deviceMarker.Tap to calculate address"))

	const dispatch = useDispatch();
	const deviceActions = devicesSlice.actions;
	const [positionLinkCopied, setPositionLinkCopied] = useState(false);
	const setDeiveInof = () => {
		if (props.device.id === devicesState.selectedMapDeviceId) {
			dispatch(
				deviceActions.setSlectedMapDeviceId({
					selectedMapDeviceId: "",
				})
			);
		} else {
			dispatch(
				deviceActions.setSlectedMapDeviceId({
					selectedMapDeviceId: props.device.id,
				})
			);
		}
	};
	const copyPositionLink = (device) => {
		var copyText =
			"http://www.google.com/maps/place/" +
			device.latitude +
			"," +
			device.longitude;
		helper_functions.default.copyToClipboard(copyText, setPositionLinkCopied);
	};
	const getLocationAddress = (latitude, longitude) => {
		const found = appExport.globaleStr.find(prevousAdress => prevousAdress.latitude == latitude || prevousAdress.longitude == longitude)
		if (found) {
			setAddress(found.address)
		}
		else {
			var addressString = ""
			let route, street_number, state, country;
			Geocode.fromLatLng(latitude, longitude).then(
				(response) => {
					const address = response.results[0].formatted_address;
					for (let i = 0; i < response.results[0].address_components.length; i++) {
						for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
							switch (response.results[0].address_components[i].types[j]) {
								case "street_number":
									if (response.results[0].address_components[i].long_name) {
										street_number = response.results[0].address_components[i].long_name;
									}
								case "route":
									if (response.results[0].address_components[i].long_name) {
										route = response.results[0].address_components[i].long_name;
									}
								case "administrative_area_level_1":
									if (response.results[0].address_components[i].long_name) {
										state = response.results[0].address_components[i].long_name;
									}
								case "country":
									if (response.results[0].address_components[i].long_name) {
										country = response.results[0].address_components[i].long_name;
									}
							}
						}
					}
					addressString = route + "," + street_number + "," + state + "," + country
					//   console.log(" addres for calcultaed  in google api", "city",street_number, "and state", state, "and country", country,"and ", addressString);
					appExport.globaleStr.push({
						latitude: latitude,
						longitude: longitude,
						address: addressString
					})
					setAddress(addressString)
				},
				(error) => {
					console.error(error);
				}
			);
		}
	}
	const getDeviceIcon = (device) => {

		let icon = "";
		let iconMain = ["airplane", "ambulance", "bicycle", "boat", "bus", "car", "caravan", "container", "container", "electriCcar",
			"engine", "excavator", "forklift", "helicopter", "jet", "monsterTruck", "moped", 
			"motorcycle", "pickup", "rv", "snowmobile", "default", "taxi", "tractor", "truck", "truckContainer", "van"]
		let iconColor = ["blue", "dark", "green", "grey", "light", "orange",
			"simple", "yellow"]
		if (process.env.REACT_APP_FLAVOR === "Bike") {
			if (device.status === "offline") {
				icon = FlavorImages.default_grey_pin;
			} else {
				icon = FlavorImages.default_blue_pin;
			}
		} else {
			if (device.mapicon) {
				icon = ``;
				let icon_splitted = device.mapicon.split("_");
				if (device.status === "offline") {
					if (!iconMain.includes(icon_splitted[0])) {
						icon = require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/MapPins/Default pins/png/default_grey.png`).default
					}
					else {
						if (icon_splitted[0].includes('default')) {
							icon = require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/MapPins/Default pins/png/default_grey.png`).default
						}
						else 
						{
							icon = require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/MapPins/Grey Pins/png/${icon_splitted[0]}_grey.png`).default;
						}
					}
				} else {

					if (!iconMain.includes(icon_splitted[0])) {
						icon = require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/MapPins/${icon_splitted[0]} pins/png/${device.mapicon}.png`).default;
					}
					else {
						if (icon_splitted[0].includes('default') ) {
							icon = require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/MapPins/Default pins/png/default.png`).default;
						}
						else {
							icon = require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/MapPins/${icon_splitted[1][0].toUpperCase() + icon_splitted[1].slice(1)} pins/png/${device.mapicon}.png`).default;
						}
					}
				}
			}
			else {
				icon = require(icon + `./../../assets/${process.env.REACT_APP_ASSETS}/markers/map-marker-alt.svg`).default;
			}
		}
		return icon;
	};

	return (
		<>
			{mapSettingsState.hide_offline_units &&
				props.device.status === "offline" ? (
				""
			) : (
				<Marker
					onClick={() => setDeiveInof()}
					icon={{
						url: getDeviceIcon(props.device),
						scaledSize: new window.google.maps.Size(45, 45),
						anchor: new window.google.maps.Point(22.5, 40) // anchor
						// 40 makes icon stay in correct vertical position and I cant calculate why :) Original map icon has a 4/5 ratio between outer size and inner size 

					}}
					position={{
						lat: parseFloat(props.device.latitude),
						lng: parseFloat(props.device.longitude),
					}}
				>
					{mapSettingsState.show_device_name ? (
						<InfoWindow options={{
							pixelOffset: new window.google.maps.Size(-2, 13), disableAutoPan: true
						}}>
							<div
								className={`custom-tooltip-container-device cursor-pointer  ${props.device.status === "offline" ? "color3" : "color1"
									}`}
							>
								<div
									className="fade show tooltip bs-tooltip-bottom"
									data-popper-reference-hidden="false"
									data-popper-escaped="false"
									data-popper-placement="bottom"
								>
									<div className="tooltip-arrow"></div>
									<div className="tooltip-inner">{props.device.name}</div>
								</div>
							</div>
						</InfoWindow>
					) : (
						""
					)}
					{props.device.id === devicesState.selectedMapDeviceId ? (
						<InfoWindow
						>
							<div
								className={`custom-popover-container popover info_device_mobile show bs-popover-bottom  `}
							>
								<div className="arrow"></div>
								<div className="popover-body px-0">
									<div className="row m-0 px-3">
										<div className="header_div d-flex col-12 pt-2 pr-0 my-1 justify-content-between">
											<div className="row">
												<img
													className=" pl-1 pb-3 pr-0 col-1"
													alt="Status"
													src={
														(props.device.status === 'online' ? FlavorImages.wifi_connected : FlavorImages.wifi_disconnected)
													}
												/>
												<p className="text-navy font-bold text-22-700 pt-1 ml-2 pl-1 break_line">
													{props.device.name}
												</p>
											</div>
											<img
												onClick={() =>
													dispatch(
														deviceActions.setSlectedMapDeviceId({
															selectedMapDeviceId: "",
														})
													)
												}
												className="cursor-pointer size_30 pb-1 closeimg"
												alt="Close device"
												src={FlavorImages.close_gray}
											/>
										</div>

										<div className="body_div col-12 pt-1 px-1">
											<div className="row mt-2 mb-1 align-items-center">
												<div className="col-1">
													<img
														className="size_30"
														alt={t("deviceMarker.Position")}
														src={FlavorImages.map_maker_alt}
													/>
												</div>
												<div className="col-9   offset-1 px-0 d-flex flex-column">
													<p className="text-navy text_12_700 p-0 m-0 line_height_17">
														{t("deviceMarker.Position")}
													</p>
													<p className=" p-0 m-0 text-12-400 line_height_17">
														{parseFloat(props.device.latitude).toFixed(5) +
															", " +
															parseFloat(props.device.longitude).toFixed(5)}
													</p>
													<p
														onClick={() => getLocationAddress(props.device.latitude, props.device.longitude)}
														className="cursor-pointer p-0 m-0 text-12-400 line_height_17">
														{address}
														{ }
													</p>
												</div>
											</div>


											<div className="row mt-3  mb-1">
												<div className="col-7 mr-0 pr-0">
													<div className="row align-items-center">
														<div className="col-2">
															<img
																className="size_30"
																alt={t("deviceMarker.Connection time")}
																src={FlavorImages.clock}
															/>
														</div>
														<div className="offset-1 pl-1 col-8">
															<p className="text-navy text_12_700 p-0 m-0 line_height_17">
																{t("deviceMarker.Connection time")}
															</p>
															<p className="mb-0 text-12-400 line_height_17">
																{helper_functions.default.formatDateTime(
																	props.device.lastconnectiontime, "yyyy/MM/dd HH:mm"
																)}
															</p>
														</div>
													</div>
												</div>

												<div className="col-5 pl-0 ml-0">
													<div className="row align-items-center">
														<div className="col-2">
															<img
																className="size_30"
																alt={t("deviceMarker.Speed")}
																src={FlavorImages.tachometer_alt}
															/>
														</div>
														<div className="offset-1 col-8">
															<p className="text-navy text_12_700 p-0 m-0 line_height_17">
																{t("deviceMarker.Speed")}
															</p>
															<p className="p-0 m-0 text-12-400 line_height_17">
																{props.device.communicationmode.speed.speedinfo}{" "}
																Km/h
															</p>
														</div>
													</div>
												</div>
											</div>

											{/* position time and battery */}

											<div className="row mt-3 mb-1">
												<div className="col-7 mr-0 pr-0">
													<div className="row align-items-center">
														<div className="col-2 padding_left_12">
															<img
																className="size_30"
																alt={t("deviceMarker.Position time")}
																src={FlavorImages.position_info_maker_time}
															/>
														</div>
														<div className="offset-1 pl-1 col-9 ">
															<p className="text-navy text_12_700 p-0 m-0 line_height_17">
																{t("deviceMarker.Position time")}
															</p>
															<p className="p-0 m-0 text-12-400 line_height_17">
																{helper_functions.default.formatDateTime(
																	props.device.lastpositiontime, "yyyy/MM/dd HH:mm"
																)}
															</p>
														</div>
													</div>
												</div>
												<div className="col-5 ml-0 pl-0">
													<div className="row align-items-center">
														<div className="col-2 ">
															{
																props.device.communicationmode.internalbatteryinfo > 95 ? (
																	<img
																		src={FlavorImages.battery_full}
																		className="size_30 "
																		alt="..."
																	/>
																) : props.device.communicationmode.internalbatteryinfo >
																	70 ? (
																	<img
																		src={FlavorImages.battery_three_quarters}
																		className="size_30"
																		alt="..."
																	/>
																) : props.device.communicationmode.internalbatteryinfo >
																	40 ? (
																	<img
																		src={FlavorImages.battery_half}
																		className="size_30"
																		alt="..."
																	/>
																) : props.device.communicationmode.internalbatteryinfo >
																	20 ? (
																	<img
																		src={FlavorImages.battery_quarter}
																		className="size_30"
																		alt="..."
																	/>
																) : (
																	<img
																		src={FlavorImages.battery_empty}
																		className="size_30"
																		alt="..."
																	/>
																)
															}
														</div>
														<div className="offset-1 col-8">
															<p className="text-navy text_12_700 p-0 m-0 line_height_17">
																{t("deviceMarker.Battery")}
															</p>
															<p className="p-0 m-0 text-12-400 line_height_17">
																{process.env.REACT_APP_FLAVOR === "Bike" 
																	? props.device.communicationmode.vehiclebattery.info
																		? props.device.communicationmode.vehiclebattery.info + "%"
																		: "0%"
																	: props.device.communicationmode.internalbatteryinfo
																		? props.device.communicationmode.internalbatteryinfo + "%"
																		: "0%"
																}
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="row link_div  mt-3">
										<div
											onClick={() => copyPositionLink(props.device)}
											className={`col-12 ${process.env.REACT_APP_FLAVOR === 'Bike' ? 'bg_dark_helkama text_white_helkama' : 'copy_position_link_button'} copy_position_link_button   text-center py-3`}
										>
											{positionLinkCopied
												? t("deviceMarker.Link copied!")
												: t("deviceMarker.Copy position link")}
										</div>
									</div>
								</div>
							</div>
						</InfoWindow>
					) : (
						""
					)}
				</Marker>
			)}
		</>
	);
};

export default DeviceMarker;
