import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
// import close_gray from "../../assets/images/close_gray.svg";
import FlavorImages from "../../assetsFolderImport";

const ConfirmDelete = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Dialog maxWidth={"xs"} fullWidth open={props.show}>
        <Card className="card">
          <div className="text-center">
            <div className="py-3 pl-1 d-flex justify-content-between align-items-center card-header bg-white">
              <label className=" mb-0 text-grey text_17_700 ml-4">
                {" "}
                {props.headingText ? props.headingText : t("forgotPassword.Confirm")}
              </label>
              <img
                className="size_1-5_rem cursor-pointer"
                src={FlavorImages.close_gray}
                onClick={() => props.closeConfirmDeleteDialog()}
                alt="..."
              />
            </div>
          </div>
          <div className="row ml-1 mt-3 py-2">
            <div className="col-12 col-md-10 offset-md-1 ">
              <div className=" w-100 row  justify-content-center">
                <p className=" text-grey text_12_500 ">
                  {props.select === "DeleteShareBike" &&
                    props.sharedbyme === true
                    ? t("ConfirmDelete.confirmTiltone")
                    : props.select === "DeleteBike" &&
                      props.sharedbyme === false
                      ? process.env.REACT_APP_FLAVOR === "Bike"
                        ? t("ConfirmDelete.confirmTilttwo")
                        : t("ConfirmDelete.confirmTilttwo_alt")
                      : props.select === "Delete"
                        ? t("ConfirmDelete.confirmTiltthree")
                        : props.select === "bikedetail"
                          ? t("ConfirmDelete.confirmTiltfour")
                          : props.confirmBodyText
                            ? props.confirmBodyText
                            : ""}
                </p>
              </div>
            </div>
          </div>
          <div className="m-0 card-footer  d-flex justify-content-end">
            <button
              className="btn bg-transparent btn-sm font-bold text-grey"
              onClick={() => props.closeConfirmDeleteDialog()}
              variant="light"
            >
              {t("actionDialog.cancel")}
            </button>
            <button
              onClick={() => props.onConfirm()}
              className="btn btn_red text-uppercase ml-3 "
              id="next"
            >
              {props.select === "DeleteBike" && props.sharedbyme == false && process.env.REACT_APP_FLAVOR !== "Bike"
                ? t("ConfirmDelete.DeleteBike_alt")
                  : process.env.REACT_APP_FLAVOR === "Bike" && props.select === "DeleteBike" && props.sharedbyme === false
                    ? t("ConfirmDelete.DeleteBike")
                    : props.select === "DeleteShareBike" && props.sharedbyme === true
                    ? t("ConfirmDelete.Remove")
                    : props.select === "Delete"
                      ? t("ConfirmDelete.DeleteAccount")
                      : props.select === "bikedetail"
                        ? t("Settings.DeleteHistory")
                        : t("ConfirmDelete.DeleteRoute")
                }
            </button>
          </div>
        </Card>
      </Dialog>
    </>
  );
};

export default ConfirmDelete;
