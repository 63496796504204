let FlavorImages
if (process.env.REACT_APP_ASSETS === "helkama") {
    FlavorImages = {
        bookmark_filled_white: require("./assets/helkama/bookmark_filled_white.svg").default,
        bookmark_filled: require("./assets/helkama/bookmark_filled.svg").default,
        device_icon: require("./assets/helkama/device_icon.svg").default,
        sort_button: require("./assets/helkama/sort_button.svg").default,
        search_black: require("./assets/helkama/search_black.svg").default,
        search: require("./assets/helkama/search.svg").default,
        map_maker_alt: require("./assets/helkama/map-marker-alt.svg").default,
        add_device_2: require("./assets/helkama/add_device_2.svg").default,
        add_device_1: require("./assets/helkama/add_device_1_2.svg").default,
        up_arrow: require("./assets/helkama/up_arrow.svg").default,
        plus: require("./assets/helkama/plus.svg").default,
        bell_active_setting: require('./assets/helkama/bell_bold_white.svg').default,
        bell_inactive_setting: require("./assets/helkama/bell_bold.svg").default,
        cluster_active: require("./assets/helkama/cluster_icons_white.svg").default,
        cluster_inactive: require("./assets/helkama/cluster_icons.svg").default,
        cluster_map: require("./assets/helkama/cluster_marker.svg").default,
        circle: require("./assets/helkama/circle.svg").default,
        card_icon: require("./assets/helkama/dataplan.svg").default,
        tachometer_alt: require("./assets/helkama/tachometer-alt.svg").default,
        wifi_disconnected_white: require("./assets/helkama/wifi_disconnected_white.svg").default,
        offline_units_active: require("./assets/helkama/wifi_disconnected_white.svg").default,
        offline_units_inactive: require("./assets/helkama/wifi_disconnected.svg").default,
        signal_green: require("./assets/helkama/wifi.svg").default,
        signal_red: require("./assets/helkama/wifi_disconnected.svg").default,
        signal_grey: require("./assets/helkama/wifi_disconnected.svg").default,
        KM_white: require("./assets/helkama/KM_white.svg").default,
        fire_alt: require("./assets/helkama/fire-alt_white.svg").default,
        KM: require("./assets/helkama/KM.svg").default,
        user_friends: require("./assets/helkama/user-friends.svg").default,
        edit_dots: require("./assets/helkama/edit-dots.svg").default,
        chart_line_white: require("./assets/helkama/chart_line_white.svg").default,
        clock_white: require("./assets/helkama/clock_white.svg").default,
        Rectangle_grey: require("./assets/helkama/Rectangle_grey.svg").default,
        calendar_alt: require("./assets/helkama/calendar-alt.svg").default,
        chart_line: require("./assets/helkama/chart_line.svg").default,
        dark_circle: require("./assets/helkama/dark_circle.svg").default,
        Rectangle_Black: require("./assets/helkama/Rectangle_Black.svg").default,
        user_friends_white: require("./assets/helkama/user-friends_white.svg").default,
        bell_bold_white: require("./assets/helkama/bell_bold_white.svg").default,
        draw_polygon: require("./assets/helkama/draw-polygon.svg").default,
        extended_info: require("./assets/helkama/extended_info.svg").default,
        bookmark_white: require("././assets/helkama/bookmark_white.svg").default,
        map_marker_offline: require("./assets/helkama/map-marker-offline.svg").default,
        // map_marker_offline: require("./assets/helkama/geofence.svg").default,
        livestream: require("./assets/helkama/livestream.svg").default,
        grip_veritcal_selected: require("./assets/helkama/grip_veritcal_selected.svg").default,
        wifi_disconnected: require("./assets/helkama/wifi_disconnected.svg").default,
        geofence_white: require("./assets/helkama/geofence_white.svg").default,
        calendar_white: require("./assets/helkama/calendar-white.svg").default,
        wifi_connected: require("./assets/helkama/wifi.svg").default,
        DeleteIcon: require("./assets/helkama/DeleteIcon-black.svg").default,
        cluster_icons_white: require("./assets/helkama/cluster_icons_white.svg").default,
        device_name_white: require("./assets/helkama/device_name_white.svg").default,
        dataplan: require("./assets/helkama/dataplan.svg").default,
        grip_vertical_unselect: require("./assets/helkama/grip-vertical_unselect.svg").default,
        device_name: require("./assets/helkama/device_name.svg").default,
        chevron_circle_down_white: require("./assets/helkama/chevron-circle-down_white.svg").default,
        Month_Chevron: require("./assets/helkama/Month_Chevron.svg").default,
        Month_Chevron_1: require("./assets/helkama/Month_Chevron-1.svg").default,
        eco_white: require("./assets/helkama/eco_white.svg").default,
        me: require("./assets/helkama/me.svg").default,
        biking: require("./assets/helkama/biking.svg").default,
        cog: require("./assets/helkama/cog.svg").default,
        cluster_icons: require("./assets/helkama/cluster_icons.svg").default,
        bookmark: require("././assets/helkama/bookmark.svg").default,
        clock: require("./assets/helkama/clock.svg").default,
        ham_burger: require("./assets/helkama/borgir.svg").default,
        up_arrow_two: require("./assets/helkama/up_arrow_black.svg").default,
        up_arrow_black: require("./assets/helkama/up_arrow_black.svg").default,
        up_arrow_grey: require("./assets/helkama/up_arrow_grey.svg").default,
        battery_three_quarters: require("./assets/helkama/battery-three-quarters.svg").default,
        low_battery_dark: require("./assets/helkama/Alarm Icons Dark/low_battery.svg").default,
        battery_full: require("./assets/helkama/battery-full.svg").default,
        battery_half: require("./assets/helkama/battery-half.svg").default,
        Side_left_Arrow: require('./assets/helkama/Side_left_Arrow_white.svg').default,
        Side_right_Arrow: require("./assets/helkama/Side_right_Arrow.svg").default,
        battery_quarter: require("./assets/helkama/battery-quarter.svg").default,
        battery_empty: require("./assets/helkama/battery-empty.svg").default,
        dot_selected: require("./assets/helkama/dot_selected.svg").default,
        bell_dark_generic_dark: require("./assets/helkama/Alarm Icons Dark/bell_dark_generic.svg").default,
        bike_internal_error_dark: require("./assets/helkama/Alarm Icons Dark/bike_internal_error.svg").default,
        bike_vibration_dark: require("./assets/helkama/Alarm Icons Dark/bike_vibration.svg").default,
        challenge_request_dark: require("./assets/helkama/Alarm Icons Dark/challenge_request.svg").default,
        device_internal_error_dark: require("./assets/helkama/Alarm Icons Dark/device_internal_error.svg").default,
        friend_request_dark: require("./assets/helkama/Alarm Icons Dark/friend_request.svg").default,
        geofence_enter_dark: require("./assets/helkama/Alarm Icons Dark/geofence_enter.svg").default,
        geofence_exit_dark: require("./assets/helkama/Alarm Icons Dark/geofence_exit.svg").default,
        inanition_off_dark: require("./assets/helkama/Alarm Icons Dark/ingnition_off.svg").default,
        inanition_on_dark: require("./assets/helkama/Alarm Icons Dark/ingnition_on.svg").default,
        offline_dark: require("./assets/helkama/Alarm Icons Dark/offline.svg").default,
        online_dark: require("./assets/helkama/Alarm Icons Dark/online.svg").default,
        power_off_dark: require("./assets/helkama/Alarm Icons Dark/power-off.svg").default,
        speed_alarm_dark: require("./assets/helkama/Alarm Icons Dark/speed_alarm.svg").default,
        lowExternalBattery_light: require("./assets/helkama/lowExternalBattery_light.svg").default,
        bike_internal_error_light: require("./assets/helkama/Alarm Icons Light/bike_internal_error.svg").default,
        bell_generic_light: require("./assets/helkama/Alarm Icons Light/bell_generic_light.svg").default,
        bike_vibration_light: require("./assets/helkama/Alarm Icons Light/bike_vibration.svg").default,
        challenge_request_light: require("./assets/helkama/Alarm Icons Light/challenge_request.svg").default,
        device_internal_error_light: require("./assets/helkama/Alarm Icons Light/device_internal_error.svg").default,
        friend_request_light: require("./assets/helkama/Alarm Icons Light/friend_request.svg").default,
        geofence_enter_light: require("./assets/helkama/Alarm Icons Light/geofence_enter.svg").default,
        geofence_exit_light: require("./assets/helkama/Alarm Icons Light/geofence_exit.svg").default,
        ingnition_off_light: require("./assets/helkama/Alarm Icons Light/ingnition_off.svg").default,
        ingnition_on_light: require("./assets/helkama/Alarm Icons Light/ingnition_on.svg").default,
        offline_light: require("./assets/helkama/Alarm Icons Light/offline.svg").default,
        online_light: require("./assets/helkama/Alarm Icons Light/online.svg").default,
        power_off_light: require("./assets/helkama/Alarm Icons Light/power-off.svg").default,
        speed_alarm_light: require("./assets/helkama/Alarm Icons Light/speed_alarm.svg").default,
        // search: require("./assets/helkama/search_black.svg").default,
        // map_marker_offline: require("./assets/helkama/map-marker-offline.svg").default,
        offline_icon: require("./assets/helkama/map-marker-offline.svg").default,
        online_icon: require("./assets/helkama/map-marker-alt.svg").default,
        map_maker_alt_two: require("./assets/helkama/map-marker-alt.svg").default,
        power_off: require("./assets/helkama/power_off.svg").default,
        location_marker_grey: require("./assets/helkama/location_marker_grey.svg").default,
        down_arrow: require("./assets/helkama/down_arrow.svg").default,

        info_icon: require("./assets/helkama/extended_info.svg").default,
        geo_fence_icon: require("./assets/helkama/geofence.svg").default,
        groups_select: require("./assets/helkama/grip-vertical_unselect.svg").default,
        groups_inactive: require("./assets/helkama/grip_veritcal_selected.svg").default,
        
        circle_select: require("./assets/helkama/circle.svg").default,
        circle_unselect: require("./assets/helkama/circle.svg").default,
        poly_select: require("./assets/helkama/draw-polygon.svg").default,
        poly_unselect: require("./assets/helkama/draw-polygon.svg").default,
        // up_arrow: require("./assets/helkama/up_arrow_black.svg").default,
        location_icon: require("./assets/helkama/routes.png").default,
        geo_fence_row: require("./assets/helkama/geofence.svg").default,
        bookmark_icon: require("./assets/helkama/bookmark.png").default,
        bookmark_icon_filled: require("./assets/helkama/bookmark_filled.svg").default,
        bookmark_icon_filled_white: require("./assets/helkama/bookmark_filled_white.svg").default,
        // bookmark_white: require("./assets/helkama/bookmark_white.svg").default,
        in_active_device_name: require("./assets/helkama/device_name.svg").default,
        active_device_name: require("./assets/helkama/device_name_white.svg").default,
        // Terrain_Map: require("./assets/helkama/Terrain_Map.svg").default,
        add_icon: require("./assets/helkama/add_icon.svg").default,
        Side_left_Arrow_white: require("./assets/helkama/Side_left_Arrow_white.svg").default,
        // Dark_Map: require("./assets/helkama/Dark_Map.svg").default,
        pen: require("./assets/helkama/pen.svg").default,
        setting_icon: require("./assets/helkama/cog.svg").default,
        // dot_selected: require("./assets/helkama/dot_selected.svg").default,
        dot_unselected: require("./assets/helkama/dot_unselected.svg").default,
        cross_red: require("./assets/helkama/cross_red.svg").default,
        check_light_navy: require("./assets/helkama/check_light_navy.svg").default,
        crossCricle: require("./assets/helkama/cross-circle-down.svg").default,
        statistics: require("./assets/helkama/statistics.png").default,
        time_white: require("./assets/helkama/clock_white.png").default,
        speed_white: require("./assets/helkama/speed_white.svg").default,
        distance_white: require("./assets/helkama/routes_white.png").default,
        co2_white: require("./assets/helkama/co2_white.png").default,
        distance_device: require("./assets/helkama/distance_device.svg").default,
        down_arrow_white: require("./assets/helkama/chevron-circle-down_white.svg").default,
        xCheckmark: require("./assets/helkama/xCheckmark.svg").default,
        Blue_cycle: require("./assets/helkama/biking.png").default,
        chevron_circle_up: require("./assets/helkama/chevron-circle-up.png").default,
        chevron_circle_down: require("./assets/helkama/chevron-circle-down.png").default,

        close_gray: require('./assets/helkama/close_gray.svg').default,
        default_avatar: require("./assets/helkama/default_awatar.svg").default,
        show_geofence_setting_active: require("./assets/helkama/geofence_white.svg").default,
        show_geofence_setting_inactive: require("./assets/helkama/geofence.svg").default,
        trace_unit_active: require("./assets/helkama/chart_line_white.svg").default,
        trace_unit_inactive: require("./assets/helkama/chart_line.svg").default,
        Terrain_Map: require("./assets/helkama/Terrain_Map.svg").default,
        Dark_Map: require("./assets/helkama/Dark_Map.svg").default,
        Hybrid_Map: require("./assets/helkama/Hybrid_Map.svg").default,
        Satellite_Map: require("./assets/helkama/Satellite_Map.svg").default,
        Light_Map: require("./assets/helkama/Light_Map.svg").default,
        white_circle: require("./assets/helkama/white_circle.svg").default,
        white_cross: require("./assets/helkama/white_corss.svg").default,
        vectortoggle: require("./assets/helkama/vectortoggle.svg").default,
        vector: require("./assets/helkama/Vector.svg").default,
        check: require('./assets/helkama/check.svg').default,
        Earth_Blue: require("./assets/helkama/Earth_Blue.svg").default,

        bike_moving: require("./assets/helkama/alarmMarker/bike_moving.png").default,
        bike_stopped: require("./assets/helkama/alarmMarker/bike_stopped.png").default,
        online: require("./assets/helkama/alarmMarker/online.png").default,
        offline: require("./assets/helkama/alarmMarker/offline.png").default,
        geofence_exit: require("./assets/helkama/alarmMarker/geofence_exit.png").default,
        geofence_enter: require("./assets/helkama/alarmMarker/geofence_enter.png").default,
        device_tamper: require("./assets/helkama/alarmMarker/device_tamper.png").default,
        low_battery: require("./assets/helkama/alarmMarker/low_battery.png").default,
        low_external_battery: require("./assets/helkama/alarmMarker/low_external_battery.png").default,
        ignition_on: require("./assets/helkama/alarmMarker/ignition_on.png").default,
        ignition_off: require("./assets/helkama/alarmMarker/ignition_off.png").default,
        device_vibration: require("./assets/helkama/alarmMarker/device_vibration.png").default,
        power_cut: require("./assets/helkama/alarmMarker/power_cut.png").default,
        device_sos: require("./assets/helkama/alarmMarker/device_sos.png").default,
        speed_alarm: require("./assets/helkama/alarmMarker/speed_alarm.png").default,
        device_unknown: require("./assets/helkama/alarmMarker/device_unknown.png").default,

        // device marker icons 

        default_blue_pin: require("./assets/helkama/device_icons/MapPins/Default pins/png/default_blue.png").default,
        default_grey_pin: require("./assets/helkama/device_icons/MapPins/Default pins/png/default_grey.png").default,
        position_info_maker: require("./assets/helkama/map-marker-alt.svg").default,
        position_info_maker_time: require("./assets/helkama/device_icons/position_info_maker.svg").default,
        moving_point: require("./assets/helkama/movingpoint.png").default,
        device_stopped_marker: require("./assets/helkama/markers/device_stopped_marker.svg").default,
        device_position_time: require("./assets/helkama//device_icons/device_position_time.svg").default,
        device_battery: require("./assets/helkama/device_icons/device_battery.svg").default,
        map_straming_marker: require("./assets/helkama/markers/map_straming_marker.svg").default,
        bike: require("./assets/helkama/bike.svg").default,
        app_logo: require('./assets/helkama/logo.svg').default,
        app_logo_login: require('./assets/helkama/logo_login.svg').default,
        distance_two: require("./assets/helkama/distance.svg").default,

        // default_grey_two: require("./assets/helkama/device_icons/MapPins/Grey Pins/png/default_grey.png").default
        active_arrow: require("./assets/helkama/sidebar/active_arrow.svg").default,
        up_arrow_active: require("./assets/helkama/sidebar/up_arrow_active.svg").default,
        device_active: require("./assets/helkama/sidebar/device_active.svg").default,
        device_inactive: require("./assets/helkama/sidebar/device_inactive.svg").default,
        bell_active: require("./assets/helkama/sidebar/bell_active.svg").default,
        bell_inactive: require("./assets/helkama/sidebar/bell_inactive.svg").default,
        geofence_inactive: require("./assets/helkama/sidebar/geofence_inactive.svg").default,
        geofence_active: require("./assets/helkama/sidebar/geofence_active.svg").default,
        stream_active: require("./assets/helkama/sidebar/stream_active.svg").default,
        stream_inactive: require("./assets/helkama/sidebar/stream_inactive.svg").default,
        medal_active: require("./assets/helkama/sidebar/medal_active.svg").default,
        medal_inactive: require("./assets/helkama/sidebar/medal_inactive.svg").default,
        statistics_active: require("./assets/helkama/sidebar/statistics_active.svg").default,
        statistics_inactive: require("./assets/helkama/sidebar/statistics_inactive.svg").default,
        news_active: require("./assets/helkama/sidebar/news_active.svg").default,
        news_inactive: require("./assets/helkama/sidebar/news_inactive.svg").default,
        card_active: require("./assets/helkama/sidebar/card_active.svg").default,
        card_inactive: require("./assets/helkama/sidebar/card_inactive.svg").default,
        settings_active: require("./assets/helkama/sidebar/settings_active.svg").default,
        settings_inactive: require("./assets/helkama/sidebar/settings_inactive.svg").default,
        switch_on: require("./assets/helkama/switch_on.svg").default,
        switch_off: require("./assets/helkama/switch_off.svg").default,

        route_info_img: require("./assets/helkama/route_info_img.svg").default,
        expand: require("./assets/helkama/expand_arrow.svg").default,
        holo_gray: require("./assets/helkama/holo_gray.svg").default,
        Not_available: require("./assets/helkama/Not_available.svg").default,
        fire_blue: require("./assets/helkama/fire_blue.svg").default,

        custom_dropdown_arrow: require("./assets/helkama/custom-dropdown-arrow.svg").default,
        down_arrow_red: require("./assets/helkama/down_arrow_red.svg").default,
        up_arrow_green: require("./assets/helkama/up_arrow_green.svg").default,
        roundBlueDown: require("./assets/helkama/Rundbluedown.svg").default,
        RoundDownBlue: require("./assets/helkama/Runddownblue.svg").default,

        checked: require("./assets/helkama/checked.svg").default,
        check_empty: require("./assets/helkama/check_empty.svg").default,
        forward_arrow_grey: require("./assets/helkama/forward_arrow_grey.svg").default,
        forward_arrow_white: require("./assets/helkama/forward_arrow_white.svg").default,
        pic_default: require("./assets/helkama/pic_default.svg").default,
        dots_vertical: require("./assets/helkama/dots_vertical.svg").default,
        plus_grey: require("./assets/helkama/plus_grey.svg").default,
        share_grey: require("./assets/helkama/share_grey.svg").default,
        user: require("./assets/helkama/user.svg").default,
        signup_congrats: require("./assets/helkama/signup_congrats.svg").default,
        placeholder_image: require("./assets/helkama/placeholder_image.svg").default,
        placeholder_circle: require("./assets/helkama/placeholder_circle.svg").default,
        placeholder_delete: require("./assets/helkama/placeholder_delete.svg").default,
        oop_char: require("./assets/helkama/oop_char.svg").default,
        statistics_line: require("./assets/helkama/statistics_line.svg").default,
        // news_active: require("./assets/helkama/news_active.svg").default,
        backward_arrow_grey: require("./assets/helkama/backward_arrow_grey.svg").default,
        SettingButton: require("./assets/helkama/settingButton.svg").default,
        Download_Dark: require("./assets/helkama/Download_Dark.svg").default,
        dark_bike: require("./assets/helkama/dark_bike.svg").default,
        download: require("./assets/helkama/download.svg").default,
        check_empty_white: require("./assets/helkama/check_empty_white.svg").default,

        geo_tutorial_1: require("./assets/helkama/geo_tutorial_1.svg").default,
        geo_tutorial_2: require("./assets/helkama/geo_tutorial_2.svg").default,
        geo_tutorial_3: require("./assets/helkama/geo_tutorial_3.svg").default,
        geo_tutorial_4: require("./assets/helkama/geo_tutorial_4.svg").default,
        geo_tutorial_5: require("./assets/helkama/geo_tutorial_5.svg").default,
        geo_tutorial_6: require("./assets/helkama/geo_tutorial_6.svg").default,
        geo_tutorial_7: require("./assets/helkama/geo_tutorial_7.svg").default,
        geo_tutorial_8: require("./assets/helkama/geo_tutorial_8.svg").default,

        RoutesTutorial: require("./assets/helkama/RoutesTutorial.svg").default,
        locationhistoryStart: require("./assets/helkama/markers/location_history_start_flag.svg").default,
        locationhistoryEnd: require("./assets/helkama/markers/location_history_end_flag.svg").default,

        users: require("./assets/helkama/users.svg").default,
        green_tick: require("./assets/helkama/green_tick.svg").default,

        amex_primary: require("./assets/helkama/amex_primary.svg").default,
        amex_second: require("./assets/helkama/amex_second.svg").default,
        default_primary: require("./assets/swetrack/default_primary.svg").default,
        default_second: require("./assets/swetrack/default_second.svg").default,

        trash_red_icon: require("./assets/helkama/trash_red_icon.svg").default,


    }
}
else if (process.env.REACT_APP_ASSETS === 'swetrack') {
    FlavorImages = {
        device_icon: require("./assets/swetrack/device_icon.svg").default,
        power_button_active: require("./assets/swetrack/power_button_active.svg").default,
        close_gray: require('./assets/swetrack/close_gray.svg').default,
        check: require('./assets/swetrack/check.svg').default,
        add_device_1: require("./assets/swetrack/add_device_1.svg").default,
        fire_alt: require("./assets/swetrack/cal_wthie.svg").default,
        add_device_2: require("./assets/swetrack/add_device_2.svg").default,
        power_button: require("./assets/swetrack/power_button.svg").default,
        search: require("./assets/swetrack/search_gray.svg").default,
        up_arrow: require("./assets/swetrack/up_arrow.svg").default,
        eco_white: require("./assets/swetrack/co2_saved_white.svg").default,
        Month_Chevron: require("./assets/swetrack/back_arrow_statistics.svg").default,
        plus: require("./assets/swetrack/plus.svg").default,
        Month_Chevron_1: require("./assets/swetrack/forward_arrow_stats.svg").default,
        up_arrow_two: require("./assets/swetrack/up_arrow_grey.svg").default,
        DeleteIcon: require("./assets/swetrack/DeleteIcon.svg").default,
        battery_full: require("./assets/swetrack/battery_full_info.svg").default,
        dark_circle: require("./assets/swetrack/dark_circle.svg").default,
        battery_three_quarters: require("./assets/swetrack//battery_three_quarters_info.svg").default,
        battery_half: require("./assets/swetrack/battery_half_info.svg").default,
        device_unknown_dark: require("./assets/swetrack/Dark/deviceUnknown_dark.svg").default,
        battery_quarter: require("./assets/swetrack/battery_quarter_info.svg").default,
        battery_empty: require("./assets/swetrack/battery_empty_info.svg").default,
        tachometer_alt: require("./assets/swetrack/device_speed_meter.svg").default,
        clock: require("./assets/swetrack/device_connection_time.svg").default,
        deviceMoving_dark: ("./assets/swetrack/deviceMoving_dark.svg").default,
        map_maker_alt: require("./assets/swetrack/device_position.svg").default,
        device_stopped_dark: require("./assets/swetrack/Dark/devicestopped_dark.svg").default,
        wifi_connected: require("./assets/swetrack/device_online.svg").default,
        signal_green: require("./assets/swetrack/signal_green.svg").default,
        signal_red: require("./assets/swetrack/signal_red.svg").default,
        signal_grey: require("./assets/swetrack/signal_grey.svg").default,
        wifi_disconnected: require("./assets/swetrack/device_offline.svg").default,
        ham_burger: require("./assets/swetrack/ham_burger.svg").default,
        Side_left_Arrow_white: require("./assets/swetrack/Side_left_Arrow_white.svg").default,
        Side_right_Arrow_white: require("./assets/swetrack/Side_right_Arrow_white.svg").default,
        Side_right_Arrow: require("./assets/swetrack/Side_right_Arrow.svg").default,
        bell_active_setting: require("./assets/swetrack/map_settings/show_alarms_active.svg").default,
        bell_inactive_setting: require("./assets/swetrack/map_settings/show_alarms_inactive.svg").default,
        checked: require("./assets/swetrack/checked.svg").default,
        check_empty: require("./assets/swetrack/check_empty.svg").default,
        mobile_priority_select: require("./assets/swetrack/priority_alarm_mobile.svg").default,
        mobile_priority: require("./assets/swetrack/mobile_priority.svg").default,
        Remove_email: require("./assets/swetrack/Remove_email.svg").default,
        mass_share: require("./assets/swetrack/mass_share.svg").default,
        massShareSelect: require("./assets/swetrack/massShareSelect.svg").default,
        // search: require("./assets/swetrack/search_gray.svg").default,
        deleteIconClick: require("./assets/swetrack/deleteIconClick.svg").default,
        deleteAlarmUncheckedButton: require("./assets/swetrack/deleteAlarmUncheckedButton.svg").default,
        // dark image import
        geofence_enter_dark: require("./assets/swetrack/Dark/geofence_enter_dark.svg").default,
        geofence_exit_dark: require("./assets/swetrack/Dark/geofence_exit_dark.svg").default,
        ingnition_off_dark: require("./assets/swetrack/Dark/ingnition_off_dark.svg").default,
        ingnition_on_dark: require("./assets/swetrack/Dark/ingnition_on_dark.svg").default,
        lowExternalBattery_dark: require("./assets/swetrack/Dark/lowExternalBattery_dark.svg").default,
        low_battery_dark: require("./assets/swetrack/Dark/low_battery_dark.svg").default,
        offline_dark: require("./assets/swetrack/Dark/offline_dark.svg").default,
        online_dark: require("./assets/swetrack/Dark/online_dark.svg").default,
        powercut_dark: require("./assets/swetrack/Dark/powercut_dark.svg").default,
        sos_dark: require("./assets/swetrack/Dark/sos_dark.svg").default,
        speed_alarm_dark: require("./assets/swetrack/Dark/speed_alarm_dark.svg").default,
        tamper_dark: require("./assets/swetrack/Dark/tamper_dark.svg").default,
        vibration_dark: require("./assets/swetrack/Dark/vibration_dark.svg").default,
        bypass_dark: require("./assets/swetrack/Dark/bypass_dark.svg").default,
        white_circle: require("./assets/swetrack/white_circle.svg").default,
        deviceMoving_light: require("./assets/swetrack/deviceMoving_light.svg").default,
        devicestopped_light: require("./assets/swetrack/devicestopped_light.svg").default,
        deviceUnknown_light: require("./assets/swetrack/deviceUnknown_light.svg").default,
        geofence_enter_light: require("./assets/swetrack/geofence_enter_light.svg").default,
        geofence_exit_light: require("./assets/swetrack/geofence_exit_light.svg").default,
        ingnition_off_light: require("./assets/swetrack/ingnition_off_light.svg").default,
        ingnition_on_light: require("./assets/swetrack/ingnition_on_light.svg").default,
        lowExternalBattery_light: require("./assets/swetrack/lowExternalBattery_light.svg").default,
        low_battery_light: require("./assets/swetrack/low_battery_light.svg").default,
        offline_light: require("./assets/swetrack/offline_light.svg").default,
        online_light: require("./assets/swetrack/online_light.svg").default,
        deleteAlarmCheckedMobileButton: require("./assets/swetrack/deleteAlarmCheckedMobileButton.svg").default,
        powercut_light: require("./assets/swetrack/powercut_light.svg").default,
        sos_light: require("./assets/swetrack/sos_light.svg").default,
        speed_alarm_light: require("./assets/swetrack/speed_alarm_light.svg").default,
        vibration_light: require("./assets/swetrack/vibration_light.svg").default,
        tamper_light: require("./assets/swetrack/tamper_light.svg").default,
        gray_cross: require("./assets/swetrack/gray_cross.svg").default,
        download: require("./assets/swetrack/download.svg").default,
        card_icon: require("./assets/swetrack/card_icon.svg").default,
        user_friends: require("./assets/swetrack/user_friends.svg").default,
        // signal_red: require("./assets/swetrack/signal_red.svg").default,
        // signal_grey: require("./assets/swetrack/signal_grey.svg").default,
        map_maker_alt_two: require("./assets/swetrack/map_marker_alt.svg").default,
        map_marker_offline: require("./assets/swetrack/location_marker_grey.svg").default,
        location_marker_grey: require("./assets/swetrack/location_marker_grey.svg").default,
        union: require("./assets/swetrack/union.svg").default,
        offline_icon: require("./assets/swetrack/offline_icon.svg").default,
        online_icon: require("./assets/swetrack/online_icon.svg").default,
        power_off: require("./assets/swetrack/power_off.svg").default,
        info_icon: require("./assets/swetrack/info_icon.svg").default,
        battery_charging: require("./assets/swetrack/battery_charging.svg").default,
        power_black_icon: require("./assets/swetrack/power_black_icon.svg").default,
        on_button: require("./assets/swetrack/Onbutton.svg").default,
        of_button: require("./assets/swetrack/Offbutton.svg").default,
        loc_history_icon: require("./assets/swetrack/loc_history_icon.svg").default,
        next_arrow_btn: require("./assets/swetrack/next_arrow_btn.svg").default,
        groups_select: require("./assets/swetrack/groups_select.svg").default,
        group: require("./assets/swetrack/groups.svg").default,
        geo_fence_icon: require("./assets/swetrack/geo_fence_icon.svg").default,
        circle_select: require("./assets/swetrack/circle_select.svg").default,
        circle_unselect: require("./assets/swetrack/circle_unselect.svg").default,
        poly_select: require("./assets/swetrack/poly_select.svg").default,
        poly_unselect: require("./assets/swetrack/poly_unselect.svg").default,
        // up_arrow: require("./assets/swetrack/up_arrow_grey.svg").default,
        geo_fence_row: require("./assets/swetrack/geo_fence_row.svg").default,
        edit_dots: require("./assets/swetrack/edit_dots.svg").default,
        livestream: require("./assets/swetrack/stream.svg").default,
        // calendar_alt: require("./assets/helkama/calendar-alt.svg").default,
        calendar_select: require("./assets/swetrack/calendar_select.svg").default,
        calender_unselect: require('./assets/swetrack/calendar_unselect.svg').default,
        Rectangle_grey: (""),
        time_select: require("./assets/swetrack/time_select.svg").default,
        time_unselect: require("./assets/swetrack/time_unselect.svg").default,
        location_icon: require('./assets/swetrack/location_icon.svg').default,
        bookmark_icon: require("./assets/swetrack/Inverted_Badge.svg").default,
        bookmark_icon_filled: require("./assets/swetrack/inverted_filled_badge.svg").default,
        Side_left_Arrow: require("./assets/swetrack/Side_left_Arrow_black.svg").default,
        groups_inactive: require("./assets/swetrack/show_groups_inactive.svg").default,
        cluster_active: require("./assets/swetrack/map_settings/cluster_markers_active.svg").default,
        cluster_inactive: require("./assets/swetrack/map_settings/cluster_markers_inactive.svg").default,
        cluster_map: require("./assets/swetrack/cluster_marker.svg").default,
        in_active_device_name: require("./assets/swetrack/show_device_name_inactive.svg").default,
        active_device_name: require("./assets/swetrack/show_device_name_active.svg").default,
        offline_units_active: require("./assets/swetrack/map_settings/hide_offline_units_active.svg").default,
        offline_units_inactive: require("./assets/swetrack/map_settings/hide_offline_units_inactive.svg").default,
        Terrain_Map: require("./assets/swetrack/Terrain_Map.svg").default,
        Dark_Map: require("./assets/swetrack/Dark_Map.svg").default,
        Hybrid_Map: require("./assets/swetrack/Hybrid_Map.svg").default,
        Satellite_Map: require("./assets/swetrack/Satellite_Map.svg").default,
        Light_Map: require("./assets/swetrack/Light_Map.svg").default,
        default_avatar: require("./assets/swetrack/default_awatar.svg").default,
        up_arrow_green: require("./assets/swetrack/up_arrow_green.svg").default,
        down_arrow_red: require("./assets/swetrack/down_arrow_red.svg").default,
        forward_arrow_grey: require("./assets/swetrack/forward_arrow_grey.svg").default,
        pic_default: require("./assets/swetrack/pic_default.svg").default,
        setting_icon: require("./assets/swetrack/settings.svg").default,
        dot_selected: require("./assets/swetrack/dot_selected.svg").default,
        dot_unselected: require("./assets/swetrack/dot_unselected.svg").default,
        cross_red: require("./assets/swetrack/cross_red.svg").default,
        check_light_navy: require("./assets/swetrack/check_light_navy.svg").default,
        backward_arrow_grey: require("./assets/swetrack/backward_arrow_grey.svg").default,
        statistics: require("./assets/swetrack/statistics.svg").default,
        time_white: require("./assets/swetrack/time_white.svg").default,
        distance_white: require("./assets/swetrack/distance_white.svg").default,
        co2_white: require("./assets/swetrack/co2_white.svg").default,
        down_arrow: require("./assets/swetrack/down_arrow.svg").default,
        xCheckmark: require("./assets/swetrack/xCheckmark.svg").default,
        Blue_cycle: require("./assets/swetrack/Blue_cycle.svg").default,
        show_geofence_setting_active: require("./assets/swetrack/map_settings/show_geofence_active.svg").default,
        show_geofence_setting_inactive: require("./assets/swetrack/map_settings/show_geofence_inactive.svg").default,
        trace_unit_active: require("./assets/swetrack/map_settings/trace_unit_active.svg").default,
        trace_unit_inactive: require("./assets/swetrack/map_settings/trace_unit_inactive.svg").default,
        white_cross: require("./assets/swetrack/white_corss.svg").default,
        vectortoggle: require("./assets/swetrack/vectortoggle.svg").default,
        vector: require("./assets/swetrack/Vector.svg").default,
        Earth_Blue: require("./assets/swetrack/Earth_Blue.svg").default,
        device_moving: require("./assets/swetrack/alarmMarker/device_moving.png").default,
        device_stopped: require("./assets/swetrack/alarmMarker/device_stopped.png").default,
        device_unknown: require("./assets/swetrack/alarmMarker/device_unknown.png").default,
        device_vibration: require("./assets/swetrack/alarmMarker/device_vibration.png").default,
        device_internal_error: require("./assets/swetrack/alarmMarker/device_internal_error.png").default,
        online: require("./assets/swetrack/alarmMarker/online.png").default,
        offline: require("./assets/swetrack/alarmMarker/offline.png").default,
        geofence_exit: require("./assets/swetrack/alarmMarker/geofence_exit.png").default,
        geofence_enter: require("./assets/swetrack/alarmMarker/geofence_enter.png").default,
        device_tamper: require("./assets/swetrack/alarmMarker/device_tamper.png").default,
        low_battery: require("./assets/swetrack/alarmMarker/low_battery.png").default,
        low_external_battery: require("./assets/swetrack/alarmMarker/low_external_battery.png").default,
        ignition_on: require("./assets/swetrack/alarmMarker/ignition_on.png").default,
        ignition_off: require("./assets/swetrack/alarmMarker/ignition_off.png").default,
        power_cut: require("./assets/swetrack/alarmMarker/power_cut.png").default,
        device_sos: require("./assets/swetrack/alarmMarker/device_sos.png").default,
        speed_alarm: require("./assets/swetrack/alarmMarker/speed_alarm.png").default,
        bypass: require("./assets/swetrack/Dark/bypass_dark.svg").default,
        position_info_maker: require("./assets/swetrack/position_info_maker.svg").default,
        moving_point: require("./assets/swetrack/movingpoint.png").default,
        device_stopped_marker: require("./assets/swetrack/markers/device_stopped_marker.png").default,
        device_position_time: require("./assets/swetrack//device_icons/device_position_time.svg").default,
        device_battery: require("./assets/swetrack/device_icons/device_battery.svg").default,
        map_straming_marker: require("./assets/swetrack/markers/map_straming_marker.svg").default,


        amex_primary: require("./assets/swetrack/amex_primary.svg").default,
        amex_second: require("./assets/swetrack/amex_second.svg").default,
        mastercard_primary: require("./assets/swetrack/mastercard_primary.svg").default,
        mastercard_second: require("./assets/swetrack/mastercard_second.svg").default,
        default_second: require("./assets/swetrack/default_second.svg").default,
        trash_red_icon: require("./assets/swetrack/trash_red_icon.svg").default,
        default_primary: require("./assets/swetrack/default_primary.svg").default,
        locaation_marker: require("./assets/swetrack/locaation_marker.svg").default,
        bikeActive: require("./assets/swetrack/bikeActive.svg").default,
        cross_inactive: require("./assets/swetrack/cross_inactive.svg").default,
        check_empty_light: require("./assets/swetrack/check_empty_light.svg").default,

        switch_on: require("./assets/swetrack/switch_on.svg").default,
        switch_off: require("./assets/swetrack/switch_off.svg").default,

        group_setting: require("./assets/swetrack/group_setting.svg").default,
        group_setting_active: require("./assets/swetrack/group_setting_active.svg").default,
        addGroupinctive: require("./assets/swetrack/addGroupinctive.svg").default,
        addGroupactive: require("./assets/swetrack/addGroupactive.svg").default,
        straigth_Arrow: require("./assets/swetrack/straigth_Arrow.svg").default,
        Upside_down: require("./assets/swetrack/Upside_down.svg").default,
        green_tick: require("./assets/swetrack/green_tick.svg").default,

        map_marker_malt_red: require("./assets/swetrack/markers/map-marker-alt-red.svg").default,
        // stream_active: require("./assets/swetrack/sidebar/stream_active.svg").default,

        live_tutorial_1: require("./assets/swetrack/live_tutorial_1.svg").default,
        live_tutorial_2: require("./assets/swetrack/live_tutorial_2.svg").default,
        live_tutorial_3: require("./assets/swetrack/live_tutorial_3.svg").default,
        live_tutorial_4: require("./assets/swetrack/live_tutorial_4.svg").default,
        live_tutorial_5: require("./assets/swetrack/live_tutorial_5.svg").default,
        live_tutorial_6: require("./assets/swetrack/live_tutorial_6.svg").default,
        live_tutorial_7: require("./assets/swetrack/live_tutorial_7.svg").default,
        live_tutorial_8: require("./assets/swetrack/live_tutorial_8.svg").default,
        marker_stream: require("./assets/swetrack/marker_stream.svg").default,
        marker_stream2: require("./assets/swetrack/marker_stream2.svg").default,
        copy: require("./assets/swetrack/copy.svg").default,
        app_logo: require('./assets/swetrack/logo.svg').default,
        app_logo_login: require('./assets/swetrack/logo_login.svg').default,

        default_blue_pin: require("./assets/swetrack/device_icons/MapPins/Default pins/png/default_blue.png").default,
        default_grey_pin: require("./assets/swetrack/device_icons/MapPins/Default pins/png/default_grey.png").default,


        ////// side bar icons ////////////

        active_arrow: require("./assets/swetrack/sidebar/active_arrow.svg").default,
        up_arrow_active: require("./assets/swetrack/sidebar/up_arrow_active.svg").default,
        device_active: require("./assets/swetrack/sidebar/device_active.svg").default,
        device_inactive: require("./assets/swetrack/sidebar/device_inactive.svg").default,
        bell_active: require("./assets/swetrack/sidebar/bell_active.svg").default,
        bell_inactive: require("./assets/swetrack/sidebar/bell_inactive.svg").default,
        geofence_inactive: require("./assets/swetrack/sidebar/geofence_inactive.svg").default,
        geofence_active: require("./assets/swetrack/sidebar/geofence_active.svg").default,
        medal_active: require("./assets/swetrack/sidebar/medal_active.svg").default,
        medal_inactive: require("./assets/swetrack/sidebar/medal_inactive.svg").default,
        stream_active: require("./assets/swetrack/sidebar/stream_active.svg").default,
        stream_inactive: require("./assets/swetrack/sidebar/stream_inactive.svg").default,
        statistics_active: require("./assets/swetrack/sidebar/statistics_active.svg").default,
        statistics_inactive: require("./assets/swetrack/sidebar/statistics_inactive.svg").default,
        news_active: require("./assets/swetrack/sidebar/news_active.svg").default,
        news_inactive: require("./assets/swetrack/sidebar/news_inactive.svg").default,
        card_active: require("./assets/swetrack/sidebar/card_active.svg").default,
        card_inactive: require("./assets/swetrack/sidebar/card_inactive.svg").default,


        watch: require("./assets/swetrack/watch.svg").default,
        distance_two: require("./assets/swetrack/distance.svg").default,
        RoutesTutorial: require("./assets/swetrack/RoutesTutorial.svg").default,
        forward_arrow_white: require("./assets/swetrack/forward_arrow_white.svg").default,
        dark_bike: require("./assets/swetrack/dark_bike.svg").default,
        Download_Dark: require("./assets/swetrack/Download_Dark.svg").default,
        SettingButton: require("./assets/swetrack/settingButton.svg").default,
        expand: require("./assets/swetrack/expand_arrow.svg").default,
        dots_vertical: require("./assets/swetrack/dots_vertical.svg").default,
        plus_grey: require("./assets/swetrack/plus_grey.svg").default,
        share_grey: require("./assets/swetrack/share_grey.svg").default,
        map_marker_alt_blue: require("./assets/swetrack/map-marker-alt_blue.svg").default,
        check_empty_white: require("./assets/swetrack/check_empty_white.svg").default,
        user: require("./assets/swetrack/user.svg").default,
        signup_congrats: require("./assets/swetrack/signup_congrats.svg").default,
        placeholder_image: require("./assets/swetrack/placeholder_image.svg").default,
        placeholder_circle: require("./assets/swetrack/placeholder_circle.svg").default,
        placeholder_delete: require("./assets/swetrack/placeholder_delete.svg").default,
        oop_char: require("./assets/swetrack/oop_char.svg").default,
        statistics_line: require("./assets/swetrack/statistics_line.svg").default,
        Download_icon: require("./assets/swetrack/Download_icon.svg").default,
        mass_share_select: require("./assets/swetrack/massShareSelect.svg").default,
        massshare_desktopSelect: require("./assets/swetrack/massshare_desktopSelect.svg").default,
        share_square: require("./assets/swetrack/share_square.svg").default,
        priority: require("./assets/swetrack/priority.svg").default,
        priority_select: require("./assets/swetrack/priority_select.svg").default,

        geo_tutorial_1: require("./assets/swetrack/geo_tutorial_1.svg").default,
        geo_tutorial_2: require("./assets/swetrack/geo_tutorial_2.svg").default,
        geo_tutorial_3: require("./assets/swetrack/geo_tutorial_3.svg").default,
        geo_tutorial_4: require("./assets/swetrack/geo_tutorial_4.svg").default,
        geo_tutorial_5: require("./assets/swetrack/geo_tutorial_5.svg").default,
        geo_tutorial_6: require("./assets/swetrack/geo_tutorial_6.svg").default,
        geo_tutorial_7: require("./assets/swetrack/geo_tutorial_7.svg").default,
        geo_tutorial_8: require("./assets/swetrack/geo_tutorial_8.svg").default,

        settings_active: require("./assets/swetrack/sidebar/settings_active.svg").default,
        settings_inactive: require("./assets/swetrack/sidebar/settings_inactive.svg").default,
        cross: require("./assets/swetrack/cross.svg").default,
        tick: require("./assets/swetrack/tick.svg").default,

        up_arrow_black: require("./assets/swetrack/up_arrow_black.svg").default,
        up_arrow_grey: require("./assets/swetrack/up_arrow_grey.svg").default,

        // signup_congrats: require("./assets/swetrack/signup_congrats.svg").default,
        sparking: require("./assets/swetrack/sparking.gif").default,
        position_info_maker_time: require("./assets/swetrack/position_info_maker.svg").default,
        sort_button: require("./assets/swetrack/sort_button.svg").default,
        cog: require("./assets/swetrack/cog.svg").default,

        distance_device: require("./assets/swetrack/distance_device.svg").default,
        bike_vibration_dark: require("./assets/swetrack/Dark/bike_vibration_dark.svg").default,
        bike_vibration_light: require("./assets/swetrack/bike_vibration_light.svg").default,

        locationhistoryStart: require("./assets/swetrack/markers/location_history_start_flag.svg").default,
        locationhistoryEnd: require("./assets/swetrack/markers/location_history_end_flag.svg").default,
        
        users: require("./assets/swetrack/users.svg").default,

        
    }
}
else if (process.env.REACT_APP_ASSETS === 'dignita') {
    FlavorImages = {
        device_icon: require("./assets/dignita/device_icon.svg").default,
        power_button_active: require("./assets/dignita/power_button_active.svg").default,
        close_gray: require('./assets/dignita/close_gray.svg').default,
        check: require('./assets/dignita/check.svg').default,
        add_device_1: require("./assets/dignita/add_device_1.svg").default,
        fire_alt: require("./assets/dignita/cal_wthie.svg").default,
        add_device_2: require("./assets/dignita/add_device_2.svg").default,
        power_button: require("./assets/dignita/power_button.svg").default,
        search: require("./assets/dignita/search_gray.svg").default,
        up_arrow: require("./assets/dignita/up_arrow.svg").default,
        eco_white: require("./assets/dignita/co2_saved_white.svg").default,
        Month_Chevron: require("./assets/dignita/back_arrow_statistics.svg").default,
        plus: require("./assets/dignita/plus.svg").default,
        Month_Chevron_1: require("./assets/dignita/forward_arrow_stats.svg").default,
        up_arrow_two: require("./assets/dignita/up_arrow_grey.svg").default,
        DeleteIcon: require("./assets/dignita/DeleteIcon.svg").default,
        battery_full: require("./assets/dignita/battery_full_info.svg").default,
        dark_circle: require("./assets/dignita/dark_circle.svg").default,
        battery_three_quarters: require("./assets/dignita//battery_three_quarters_info.svg").default,
        battery_half: require("./assets/dignita/battery_half_info.svg").default,
        device_unknown_dark: require("./assets/dignita/Dark/deviceUnknown_dark.svg").default,
        battery_quarter: require("./assets/dignita/battery_quarter_info.svg").default,
        battery_empty: require("./assets/dignita/battery_empty_info.svg").default,
        tachometer_alt: require("./assets/dignita/device_speed_meter.svg").default,
        clock: require("./assets/dignita/device_connection_time.svg").default,
        deviceMoving_dark: ("./assets/dignita/deviceMoving_dark.svg").default,
        map_maker_alt: require("./assets/dignita/device_position.svg").default,
        device_stopped_dark: require("./assets/dignita/Dark/devicestopped_dark.svg").default,
        wifi_connected: require("./assets/dignita/device_online.svg").default,
        signal_green: require("./assets/dignita/signal_green.svg").default,
        signal_red: require("./assets/dignita/signal_red.svg").default,
        signal_grey: require("./assets/dignita/signal_grey.svg").default,
        wifi_disconnected: require("./assets/dignita/device_offline.svg").default,
        ham_burger: require("./assets/dignita/ham_burger.svg").default,
        Side_left_Arrow_white: require("./assets/dignita/Side_left_Arrow_white.svg").default,
        Side_right_Arrow_white: require("./assets/dignita/Side_right_Arrow_white.svg").default,
        Side_right_Arrow: require("./assets/dignita/Side_right_Arrow.svg").default,
        bell_active_setting: require("./assets/dignita/map_settings/show_alarms_active.svg").default,
        bell_inactive_setting: require("./assets/dignita/map_settings/show_alarms_inactive.svg").default,
        checked: require("./assets/dignita/checked.svg").default,
        check_empty: require("./assets/dignita/check_empty.svg").default,
        mobile_priority_select: require("./assets/dignita/priority_alarm_mobile.svg").default,
        mobile_priority: require("./assets/dignita/mobile_priority.svg").default,
        Remove_email: require("./assets/dignita/Remove_email.svg").default,
        mass_share: require("./assets/dignita/mass_share.svg").default,
        massShareSelect: require("./assets/dignita/massShareSelect.svg").default,
        // search: require("./assets/dignita/search_gray.svg").default,
        deleteIconClick: require("./assets/dignita/deleteIconClick.svg").default,
        deleteAlarmUncheckedButton: require("./assets/dignita/deleteAlarmUncheckedButton.svg").default,
        // dark image import
        geofence_enter_dark: require("./assets/dignita/Dark/geofence_enter_dark.svg").default,
        geofence_exit_dark: require("./assets/dignita/Dark/geofence_exit_dark.svg").default,
        ingnition_off_dark: require("./assets/dignita/Dark/ingnition_off_dark.svg").default,
        ingnition_on_dark: require("./assets/dignita/Dark/ingnition_on_dark.svg").default,
        lowExternalBattery_dark: require("./assets/dignita/Dark/lowExternalBattery_dark.svg").default,
        low_battery_dark: require("./assets/dignita/Dark/low_battery_dark.svg").default,
        offline_dark: require("./assets/dignita/Dark/offline_dark.svg").default,
        online_dark: require("./assets/dignita/Dark/online_dark.svg").default,
        powercut_dark: require("./assets/dignita/Dark/powercut_dark.svg").default,
        sos_dark: require("./assets/dignita/Dark/sos_dark.svg").default,
        speed_alarm_dark: require("./assets/dignita/Dark/speed_alarm_dark.svg").default,
        tamper_dark: require("./assets/dignita/Dark/tamper_dark.svg").default,
        vibration_dark: require("./assets/dignita/Dark/vibration_dark.svg").default,
        bypass_dark: require("./assets/dignita/Dark/bypass_dark.svg").default,
        white_circle: require("./assets/dignita/white_circle.svg").default,
        deviceMoving_light: require("./assets/dignita/deviceMoving_light.svg").default,
        devicestopped_light: require("./assets/dignita/devicestopped_light.svg").default,
        deviceUnknown_light: require("./assets/dignita/deviceUnknown_light.svg").default,
        geofence_enter_light: require("./assets/dignita/geofence_enter_light.svg").default,
        geofence_exit_light: require("./assets/dignita/geofence_exit_light.svg").default,
        ingnition_off_light: require("./assets/dignita/ingnition_off_light.svg").default,
        ingnition_on_light: require("./assets/dignita/ingnition_on_light.svg").default,
        lowExternalBattery_light: require("./assets/dignita/lowExternalBattery_light.svg").default,
        low_battery_light: require("./assets/dignita/low_battery_light.svg").default,
        offline_light: require("./assets/dignita/offline_light.svg").default,
        online_light: require("./assets/dignita/online_light.svg").default,
        deleteAlarmCheckedMobileButton: require("./assets/dignita/deleteAlarmCheckedMobileButton.svg").default,
        powercut_light: require("./assets/dignita/powercut_light.svg").default,
        sos_light: require("./assets/dignita/sos_light.svg").default,
        speed_alarm_light: require("./assets/dignita/speed_alarm_light.svg").default,
        vibration_light: require("./assets/dignita/vibration_light.svg").default,
        tamper_light: require("./assets/dignita/tamper_light.svg").default,
        gray_cross: require("./assets/dignita/gray_cross.svg").default,
        download: require("./assets/dignita/download.svg").default,
        card_icon: require("./assets/dignita/card_icon.svg").default,
        user_friends: require("./assets/dignita/user_friends.svg").default,
        // signal_red: require("./assets/dignita/signal_red.svg").default,
        // signal_grey: require("./assets/dignita/signal_grey.svg").default,
        map_maker_alt_two: require("./assets/dignita/map_marker_alt.svg").default,
        map_marker_offline: require("./assets/dignita/location_marker_grey.svg").default,
        location_marker_grey: require("./assets/dignita/location_marker_grey.svg").default,
        union: require("./assets/dignita/union.svg").default,
        offline_icon: require("./assets/dignita/offline_icon.svg").default,
        online_icon: require("./assets/dignita/online_icon.svg").default,
        power_off: require("./assets/dignita/power_off.svg").default,
        info_icon: require("./assets/dignita/info_icon.svg").default,
        battery_charging: require("./assets/dignita/battery_charging.svg").default,
        power_black_icon: require("./assets/dignita/power_black_icon.svg").default,
        on_button: require("./assets/dignita/Onbutton.svg").default,
        of_button: require("./assets/dignita/Offbutton.svg").default,
        loc_history_icon: require("./assets/dignita/loc_history_icon.svg").default,
        next_arrow_btn: require("./assets/dignita/next_arrow_btn.svg").default,
        groups_select: require("./assets/dignita/groups_select.svg").default,
        group: require("./assets/dignita/groups.svg").default,
        geo_fence_icon: require("./assets/dignita/geo_fence_icon.svg").default,
        circle_select: require("./assets/dignita/circle_select.svg").default,
        circle_unselect: require("./assets/dignita/circle_unselect.svg").default,
        poly_select: require("./assets/dignita/poly_select.svg").default,
        poly_unselect: require("./assets/dignita/poly_unselect.svg").default,
        // up_arrow: require("./assets/dignita/up_arrow_grey.svg").default,
        geo_fence_row: require("./assets/dignita/geo_fence_row.svg").default,
        edit_dots: require("./assets/dignita/edit_dots.svg").default,
        livestream: require("./assets/dignita/stream.svg").default,
        // calendar_alt: require("./assets/helkama/calendar-alt.svg").default,
        calendar_select: require("./assets/dignita/calendar_select.svg").default,
        calender_unselect: require('./assets/dignita/calendar_unselect.svg').default,
        Rectangle_grey: (""),
        time_select: require("./assets/dignita/time_select.svg").default,
        time_unselect: require("./assets/dignita/time_unselect.svg").default,
        location_icon: require('./assets/dignita/location_icon.svg').default,
        bookmark_icon: require("./assets/dignita/Inverted_Badge.svg").default,
        bookmark_icon_filled: require("./assets/dignita/inverted_filled_badge.svg").default,
        // Side_left_Arrow: require("./assets/dignita/Side_left_Arrow_black.svg").default,
        groups_inactive: require("./assets/dignita/show_groups_inactive.svg").default,
        cluster_active: require("./assets/dignita/map_settings/cluster_markers_active.svg").default,
        cluster_inactive: require("./assets/dignita/map_settings/cluster_markers_inactive.svg").default,
        cluster_map: require("./assets/dignita/cluster_marker.svg").default,
        in_active_device_name: require("./assets/dignita/show_device_name_inactive.svg").default,
        active_device_name: require("./assets/dignita/show_device_name_active.svg").default,
        offline_units_active: require("./assets/dignita/map_settings/hide_offline_units_active.svg").default,
        offline_units_inactive: require("./assets/dignita/map_settings/hide_offline_units_inactive.svg").default,
        Terrain_Map: require("./assets/dignita/Terrain_Map.svg").default,
        Dark_Map: require("./assets/dignita/Dark_Map.svg").default,
        Hybrid_Map: require("./assets/dignita/Hybrid_Map.svg").default,
        Satellite_Map: require("./assets/dignita/Satellite_Map.svg").default,
        Light_Map: require("./assets/dignita/Light_Map.svg").default,
        default_avatar: require("./assets/dignita/default_awatar.svg").default,
        up_arrow_green: require("./assets/dignita/up_arrow_green.svg").default,
        down_arrow_red: require("./assets/dignita/down_arrow_red.svg").default,
        forward_arrow_grey: require("./assets/dignita/forward_arrow_grey.svg").default,
        pic_default: require("./assets/dignita/pic_default.svg").default,
        setting_icon: require("./assets/dignita/settings.svg").default,
        dot_selected: require("./assets/dignita/dot_selected.svg").default,
        dot_unselected: require("./assets/dignita/dot_unselected.svg").default,
        cross_red: require("./assets/dignita/cross_red.svg").default,
        check_light_navy: require("./assets/dignita/check_light_navy.svg").default,
        backward_arrow_grey: require("./assets/dignita/backward_arrow_grey.svg").default,
        statistics: require("./assets/dignita/statistics.svg").default,
        time_white: require("./assets/dignita/time_white.svg").default,
        distance_white: require("./assets/dignita/distance_white.svg").default,
        co2_white: require("./assets/dignita/co2_white.svg").default,
        down_arrow: require("./assets/dignita/down_arrow.svg").default,
        xCheckmark: require("./assets/dignita/xCheckmark.svg").default,
        Blue_cycle: require("./assets/dignita/Blue_cycle.svg").default,
        show_geofence_setting_active: require("./assets/dignita/map_settings/show_geofence_active.svg").default,
        show_geofence_setting_inactive: require("./assets/dignita/map_settings/show_geofence_inactive.svg").default,
        trace_unit_active: require("./assets/dignita/map_settings/trace_unit_active.svg").default,
        trace_unit_inactive: require("./assets/dignita/map_settings/trace_unit_inactive.svg").default,
        white_cross: require("./assets/dignita/white_corss.svg").default,
        vectortoggle: require("./assets/dignita/vectortoggle.svg").default,
        vector: require("./assets/dignita/Vector.svg").default,
        Earth_Blue: require("./assets/dignita/Earth_Blue.svg").default,
        device_moving: require("./assets/dignita/alarmMarker/device_moving.png").default,
        device_stopped: require("./assets/dignita/alarmMarker/device_stopped.png").default,
        device_unknown: require("./assets/dignita/alarmMarker/device_unknown.png").default,
        device_vibration: require("./assets/dignita/alarmMarker/device_vibration.png").default,
        device_internal_error: require("./assets/dignita/alarmMarker/device_internal_error.png").default,
        // // alarmMarker/////
        online: require("./assets/dignita/alarmMarker/online.png").default,
        offline: require("./assets/dignita/alarmMarker/offline.png").default,
        geofence_exit: require("./assets/dignita/alarmMarker/geofence_exit.png").default,
        geofence_enter: require("./assets/dignita/alarmMarker/geofence_enter.png").default,
        device_tamper: require("./assets/dignita/alarmMarker/device_tamper.png").default,
        low_battery: require("./assets/dignita/alarmMarker/low_battery.png").default,
        low_external_battery: require("./assets/dignita/alarmMarker/low_external_battery.png").default,
        ignition_on: require("./assets/dignita/alarmMarker/ignition_on.png").default,
        ignition_off: require("./assets/dignita/alarmMarker/ignition_off.png").default,
        power_cut: require("./assets/dignita/alarmMarker/power_cut.png").default,
        device_sos: require("./assets/dignita/alarmMarker/device_sos.png").default,
        speed_alarm: require("./assets/dignita/alarmMarker/speed_alarm.png").default,
        bypass: require("./assets/dignita/Dark/bypass_dark.svg").default,
        position_info_maker: require("./assets/dignita/position_info_maker.svg").default,
        moving_point: require("./assets/dignita/movingpoint.png").default,
        device_stopped_marker: require("./assets/dignita/markers/device_stopped_marker.svg").default,
        device_position_time: require("./assets/dignita//device_icons/device_position_time.svg").default,
        device_battery: require("./assets/dignita/device_icons/device_battery.svg").default,
        map_straming_marker: require("./assets/dignita/markers/map_straming_marker.svg").default,
        // /////////


        amex_primary: require("./assets/dignita/amex_primary.svg").default,
        amex_second: require("./assets/dignita/amex_second.svg").default,
        mastercard_primary: require("./assets/dignita/mastercard_primary.svg").default,
        mastercard_second: require("./assets/dignita/mastercard_second.svg").default,
        default_second: require("./assets/dignita/default_second.svg").default,
        trash_red_icon: require("./assets/dignita/trash_red_icon.svg").default,
        default_primary: require("./assets/dignita/default_primary.svg").default,
        locaation_marker: require("./assets/dignita/locaation_marker.svg").default,
        bikeActive: require("./assets/dignita/bikeActive.svg").default,
        cross_inactive: require("./assets/dignita/cross_inactive.svg").default,
        check_empty_light: require("./assets/dignita/check_empty_light.svg").default,

        switch_on: require("./assets/dignita/switch_on.svg").default,
        switch_off: require("./assets/dignita/switch_off.svg").default,

        group_setting: require("./assets/dignita/group_setting.svg").default,
        group_setting_active: require("./assets/dignita/group_setting_active.svg").default,
        addGroupinctive: require("./assets/dignita/addGroupinctive.svg").default,
        addGroupactive: require("./assets/dignita/addGroupactive.svg").default,
        straigth_Arrow: require("./assets/dignita/straigth_Arrow.svg").default,
        Upside_down: require("./assets/dignita/Upside_down.svg").default,
        green_tick: require("./assets/dignita/green_tick.svg").default,

        map_marker_malt_red: require("./assets/dignita/markers/map-marker-alt-red.svg").default,
        // stream_active: require("./assets/dignita/sidebar/stream_active.svg").default,

        live_tutorial_1: require("./assets/dignita/live_tutorial_1.svg").default,
        live_tutorial_2: require("./assets/dignita/live_tutorial_2.svg").default,
        live_tutorial_3: require("./assets/dignita/live_tutorial_3.svg").default,
        live_tutorial_4: require("./assets/dignita/live_tutorial_4.svg").default,
        live_tutorial_5: require("./assets/dignita/live_tutorial_5.svg").default,
        live_tutorial_6: require("./assets/dignita/live_tutorial_6.svg").default,
        live_tutorial_7: require("./assets/dignita/live_tutorial_7.svg").default,
        live_tutorial_8: require("./assets/dignita/live_tutorial_8.svg").default,
        marker_stream: require("./assets/dignita/marker_stream.svg").default,
        marker_stream2: require("./assets/dignita/marker_stream2.svg").default,
        copy: require("./assets/dignita/copy.svg").default,
        app_logo: require('./assets/dignita/logo.svg').default,
        app_logo_login: require('./assets/dignita/logo_login.svg').default,


        ////// side bar icons ////////////

        active_arrow: require("./assets/dignita/sidebar/active_arrow.svg").default,
        up_arrow_active: require("./assets/dignita/sidebar/up_arrow_active.svg").default,
        device_active: require("./assets/dignita/sidebar/device_active.svg").default,
        device_inactive: require("./assets/helkama/sidebar/device_inactive.svg").default,
        bell_active: require("./assets/dignita/sidebar/bell_active.svg").default,
        bell_inactive: require("./assets/dignita/sidebar/bell_inactive.svg").default,
        geofence_inactive: require("./assets/dignita/sidebar/geofence_inactive.svg").default,
        geofence_active: require("./assets/dignita/sidebar/geofence_active.svg").default,
        stream_active: require("./assets/dignita/sidebar/stream_active.svg").default,
        stream_inactive: require("./assets/dignita/sidebar/stream_inactive.svg").default,
        card_active: require("./assets/dignita/sidebar/card_active.svg").default,
        card_inactive: require("./assets/dignita/sidebar/card_inactive.svg").default,


        watch: require("./assets/dignita/watch.svg").default,
        distance_two: require("./assets/dignita/distance.svg").default,
        RoutesTutorial: require("./assets/dignita/RoutesTutorial.svg").default,
        forward_arrow_white: require("./assets/dignita/forward_arrow_white.svg").default,
        dark_bike: require("./assets/dignita/dark_bike.svg").default,
        Download_Dark: require("./assets/dignita/Download_Dark.svg").default,
        SettingButton: require("./assets/dignita/settingButton.svg").default,
        expand: require("./assets/dignita/expand_arrow.svg").default,
        dots_vertical: require("./assets/dignita/dots_vertical.svg").default,
        plus_grey: require("./assets/dignita/plus_grey.svg").default,
        share_grey: require("./assets/dignita/share_grey.svg").default,
        map_marker_alt_blue: require("./assets/dignita/map-marker-alt_blue.svg").default,
        check_empty_white: require("./assets/dignita/check_empty_white.svg").default,
        user: require("./assets/dignita/user.svg").default,
        signup_congrats: require("./assets/dignita/signup_congrats.svg").default,
        placeholder_image: require("./assets/dignita/placeholder_image.svg").default,
        placeholder_circle: require("./assets/dignita/placeholder_circle.svg").default,
        placeholder_delete: require("./assets/dignita/placeholder_delete.svg").default,
        oop_char: require("./assets/dignita/oop_char.svg").default,
        statistics_line: require("./assets/dignita/statistics_line.svg").default,
        Download_icon: require("./assets/dignita/Download_icon.svg").default,
        mass_share_select: require("./assets/dignita/massShareSelect.svg").default,
        massshare_desktopSelect: require("./assets/dignita/massshare_desktopSelect.svg").default,
        share_square: require("./assets/dignita/share_square.svg").default,
        priority: require("./assets/dignita/priority.svg").default,
        priority_select: require("./assets/dignita/priority_select.svg").default,

        geo_tutorial_1: require("./assets/dignita/geo_tutorial_1.svg").default,
        geo_tutorial_2: require("./assets/dignita/geo_tutorial_2.svg").default,
        geo_tutorial_3: require("./assets/dignita/geo_tutorial_3.svg").default,
        geo_tutorial_4: require("./assets/dignita/geo_tutorial_4.svg").default,
        geo_tutorial_5: require("./assets/dignita/geo_tutorial_5.svg").default,
        geo_tutorial_6: require("./assets/dignita/geo_tutorial_6.svg").default,
        geo_tutorial_7: require("./assets/dignita/geo_tutorial_7.svg").default,
        geo_tutorial_8: require("./assets/dignita/geo_tutorial_8.svg").default,

        settings_active: require("./assets/helkama/sidebar/settings_active.svg").default,
        settings_inactive: require("./assets/helkama/sidebar/settings_inactive.svg").default,
        cross: require("./assets/dignita/cross.svg").default,
        tick: require("./assets/dignita/tick.svg").default,

        up_arrow_black: require("./assets/dignita/up_arrow_black.svg").default,
        up_arrow_grey: require("./assets/dignita/up_arrow_grey.svg").default,

        // signup_congrats: require("./assets/dignita/signup_congrats.svg").default,
        sparking: require("./assets/dignita/sparking.gif").default,
        position_info_maker_time: require("./assets/dignita/position_info_maker.svg").default,
        sort_button: require("./assets/dignita/sort_button.svg").default,
        cog: require("./assets/dignita/cog.svg").default,

        default_blue_pin: require("./assets/dignita/device_icons/MapPins/Default pins/png/default_blue.png").default,
        default_grey_pin: require("./assets/dignita/device_icons/MapPins/Default pins/png/default_grey.png").default,

        locationhistoryStart: require("./assets/dignita/markers/location_history_start_flag.svg").default,
        locationhistoryEnd: require("./assets/dignita/markers/location_history_end_flag.svg").default,

        
    }
}
else if (process.env.REACT_APP_ASSETS === 'arctictracks') {
    FlavorImages = {
        device_icon: require("./assets/arctictracks/device_icon.svg").default,
        power_button_active: require("./assets/arctictracks/power_button_active.svg").default,
        close_gray: require('./assets/arctictracks/close_gray.svg').default,
        check: require(`./assets/arctictracks/check.svg`).default,
        add_device_1: require("./assets/arctictracks/add_device_1.svg").default,
        fire_alt: require("./assets/arctictracks/cal_wthie.svg").default,
        add_device_2: require("./assets/arctictracks/add_device_2.svg").default,
        power_button: require("./assets/arctictracks/power_button.svg").default,
        search: require("./assets/arctictracks/search_gray.svg").default,
        up_arrow: require("./assets/arctictracks/up_arrow.svg").default,
        eco_white: require("./assets/arctictracks/co2_saved_white.svg").default,
        Month_Chevron: require("./assets/arctictracks/back_arrow_statistics.svg").default,
        plus: require("./assets/arctictracks/plus.svg").default,
        Month_Chevron_1: require("./assets/arctictracks/forward_arrow_stats.svg").default,
        up_arrow_two: require("./assets/arctictracks/up_arrow_grey.svg").default,
        DeleteIcon: require("./assets/arctictracks/DeleteIcon.svg").default,
        battery_full: require("./assets/arctictracks/battery_full_info.svg").default,
        dark_circle: require("./assets/arctictracks/dark_circle.svg").default,
        battery_three_quarters: require("./assets/arctictracks//battery_three_quarters_info.svg").default,
        battery_half: require("./assets/arctictracks/battery_half_info.svg").default,
        device_unknown_dark: require("./assets/arctictracks/Dark/deviceUnknown_dark.svg").default,
        battery_quarter: require("./assets/arctictracks/battery_quarter_info.svg").default,
        battery_empty: require("./assets/arctictracks/battery_empty_info.svg").default,
        tachometer_alt: require("./assets/arctictracks/device_speed_meter.svg").default,
        clock: require("./assets/arctictracks/device_connection_time.svg").default,
        deviceMoving_dark: ("./assets/arctictracks/deviceMoving_dark.svg").default,
        map_maker_alt: require("./assets/arctictracks/device_position.svg").default,
        device_stopped_dark: require("./assets/arctictracks/Dark/devicestopped_dark.svg").default,
        wifi_connected: require("./assets/arctictracks/device_online.svg").default,
        signal_green: require("./assets/arctictracks/signal_green.svg").default,
        signal_red: require("./assets/arctictracks/signal_red.svg").default,
        signal_grey: require("./assets/arctictracks/signal_grey.svg").default,
        wifi_disconnected: require("./assets/arctictracks/device_offline.svg").default,
        ham_burger: require("./assets/arctictracks/ham_burger.svg").default,
        Side_left_Arrow_white: require("./assets/arctictracks/Side_left_Arrow_white.svg").default,
        Side_right_Arrow_white: require("./assets/arctictracks/Side_right_Arrow_white.svg").default,
        Side_right_Arrow: require("./assets/arctictracks/Side_right_Arrow.svg").default,
        bell_active_setting: require("./assets/arctictracks/map_settings/show_alarms_active.svg").default,
        bell_inactive_setting: require("./assets/arctictracks/map_settings/show_alarms_inactive.svg").default,
        checked: require("./assets/arctictracks/checked.svg").default,
        check_empty: require("./assets/arctictracks/check_empty.svg").default,
        mobile_priority_select: require("./assets/arctictracks/priority_alarm_mobile.svg").default,
        mobile_priority: require("./assets/arctictracks/mobile_priority.svg").default,
        Remove_email: require("./assets/arctictracks/Remove_email.svg").default,
        mass_share: require("./assets/arctictracks/mass_share.svg").default,
        massShareSelect: require("./assets/arctictracks/massShareSelect.svg").default,
        // search: require("./assets/arctictracks/search_gray.svg").default,
        deleteIconClick: require("./assets/arctictracks/deleteIconClick.svg").default,
        deleteAlarmUncheckedButton: require("./assets/arctictracks/deleteAlarmUncheckedButton.svg").default,
        // dark image import
        geofence_enter_dark: require("./assets/arctictracks/Dark/geofence_enter_dark.svg").default,
        geofence_exit_dark: require("./assets/arctictracks/Dark/geofence_exit_dark.svg").default,
        ingnition_off_dark: require("./assets/arctictracks/Dark/ingnition_off_dark.svg").default,
        ingnition_on_dark: require("./assets/arctictracks/Dark/ingnition_on_dark.svg").default,
        lowExternalBattery_dark: require("./assets/arctictracks/Dark/lowExternalBattery_dark.svg").default,
        low_battery_dark: require("./assets/arctictracks/Dark/low_battery_dark.svg").default,
        offline_dark: require("./assets/arctictracks/Dark/offline_dark.svg").default,
        online_dark: require("./assets/arctictracks/Dark/online_dark.svg").default,
        powercut_dark: require("./assets/arctictracks/Dark/powercut_dark.svg").default,
        sos_dark: require("./assets/arctictracks/Dark/sos_dark.svg").default,
        speed_alarm_dark: require("./assets/arctictracks/Dark/speed_alarm_dark.svg").default,
        tamper_dark: require("./assets/arctictracks/Dark/tamper_dark.svg").default,
        vibration_dark: require("./assets/arctictracks/Dark/vibration_dark.svg").default,
        bypass_dark: require("./assets/arctictracks/Dark/bypass_dark.svg").default,
        white_circle: require("./assets/arctictracks/white_circle.svg").default,
        deviceMoving_light: require("./assets/arctictracks/deviceMoving_light.svg").default,
        devicestopped_light: require("./assets/arctictracks/devicestopped_light.svg").default,
        deviceUnknown_light: require("./assets/arctictracks/deviceUnknown_light.svg").default,
        geofence_enter_light: require("./assets/arctictracks/geofence_enter_light.svg").default,
        geofence_exit_light: require("./assets/arctictracks/geofence_exit_light.svg").default,
        ingnition_off_light: require("./assets/arctictracks/ingnition_off_light.svg").default,
        ingnition_on_light: require("./assets/arctictracks/ingnition_on_light.svg").default,
        lowExternalBattery_light: require("./assets/arctictracks/lowExternalBattery_light.svg").default,
        low_battery_light: require("./assets/arctictracks/low_battery_light.svg").default,
        offline_light: require("./assets/arctictracks/offline_light.svg").default,
        online_light: require("./assets/arctictracks/online_light.svg").default,
        deleteAlarmCheckedMobileButton: require("./assets/arctictracks/deleteAlarmCheckedMobileButton.svg").default,
        powercut_light: require("./assets/arctictracks/powercut_light.svg").default,
        sos_light: require("./assets/arctictracks/sos_light.svg").default,
        speed_alarm_light: require("./assets/arctictracks/speed_alarm_light.svg").default,
        vibration_light: require("./assets/arctictracks/vibration_light.svg").default,
        tamper_light: require("./assets/arctictracks/tamper_light.svg").default,
        gray_cross: require("./assets/arctictracks/gray_cross.svg").default,
        download: require("./assets/arctictracks/download.svg").default,
        card_icon: require("./assets/arctictracks/card_icon.svg").default,
        user_friends: require("./assets/arctictracks/user_friends.svg").default,
        // signal_red: require("./assets/arctictracks/signal_red.svg").default,
        // signal_grey: require("./assets/arctictracks/signal_grey.svg").default,
        map_maker_alt_two: require("./assets/arctictracks/map_marker_alt.svg").default,
        map_marker_offline: require("./assets/arctictracks/location_marker_grey.svg").default,
        location_marker_grey: require("./assets/arctictracks/location_marker_grey.svg").default,
        union: require("./assets/arctictracks/union.svg").default,
        offline_icon: require("./assets/arctictracks/offline_icon.svg").default,
        online_icon: require("./assets/arctictracks/online_icon.svg").default,
        power_off: require("./assets/arctictracks/power_off.svg").default,
        info_icon: require("./assets/arctictracks/info_icon.svg").default,
        battery_charging: require("./assets/arctictracks/battery_charging.svg").default,
        power_black_icon: require("./assets/arctictracks/power_black_icon.svg").default,
        on_button: require("./assets/arctictracks/Onbutton.svg").default,
        of_button: require("./assets/arctictracks/Offbutton.svg").default,
        loc_history_icon: require("./assets/arctictracks/loc_history_icon.svg").default,
        next_arrow_btn: require("./assets/arctictracks/next_arrow_btn.svg").default,
        groups_select: require("./assets/arctictracks/groups_select.svg").default,
        group: require("./assets/arctictracks/groups.svg").default,
        geo_fence_icon: require("./assets/arctictracks/geo_fence_icon.svg").default,
        circle_select: require("./assets/arctictracks/circle_select.svg").default,
        circle_unselect: require("./assets/arctictracks/circle_unselect.svg").default,
        poly_select: require("./assets/arctictracks/poly_select.svg").default,
        poly_unselect: require("./assets/arctictracks/poly_unselect.svg").default,
        // up_arrow: require("./assets/arctictracks/up_arrow_grey.svg").default,
        geo_fence_row: require("./assets/arctictracks/geo_fence_row.svg").default,
        edit_dots: require("./assets/arctictracks/edit_dots.svg").default,
        livestream: require("./assets/arctictracks/stream.svg").default,
        // calendar_alt: require("./assets/arctictracks/calendar-alt.svg").default,
        calendar_select: require("./assets/arctictracks/calendar_select.svg").default,
        calender_unselect: require('./assets/arctictracks/calendar_unselect.svg').default,
        Rectangle_grey: (""),
        time_select: require("./assets/arctictracks/time_select.svg").default,
        time_unselect: require("./assets/arctictracks/time_unselect.svg").default,
        location_icon: require('./assets/arctictracks/location_icon.svg').default,
        bookmark_icon: require("./assets/arctictracks/Inverted_Badge.svg").default,
        bookmark_icon_filled: require("./assets/arctictracks/inverted_filled_badge.svg").default,
        Side_left_Arrow: require("./assets/arctictracks/Side_left_Arrow_black.svg").default,
        groups_inactive: require("./assets/arctictracks/show_groups_inactive.svg").default,
        cluster_active: require("./assets/arctictracks/map_settings/cluster_markers_active.svg").default,
        cluster_inactive: require("./assets/arctictracks/map_settings/cluster_markers_inactive.svg").default,
        cluster_map: require("./assets/arctictracks/cluster_marker.svg").default,
        in_active_device_name: require("./assets/arctictracks/show_device_name_inactive.svg").default,
        active_device_name: require("./assets/arctictracks/show_device_name_active.svg").default,
        offline_units_active: require("./assets/arctictracks/map_settings/hide_offline_units_active.svg").default,
        offline_units_inactive: require("./assets/arctictracks/map_settings/hide_offline_units_inactive.svg").default,
        Terrain_Map: require("./assets/arctictracks/Terrain_Map.svg").default,
        Dark_Map: require("./assets/arctictracks/Dark_Map.svg").default,
        Hybrid_Map: require("./assets/arctictracks/Hybrid_Map.svg").default,
        Satellite_Map: require("./assets/arctictracks/Satellite_Map.svg").default,
        Light_Map: require("./assets/arctictracks/Light_Map.svg").default,
        default_avatar: require("./assets/arctictracks/default_awatar.svg").default,
        up_arrow_green: require("./assets/arctictracks/up_arrow_green.svg").default,
        down_arrow_red: require("./assets/arctictracks/down_arrow_red.svg").default,
        forward_arrow_grey: require("./assets/arctictracks/forward_arrow_grey.svg").default,
        pic_default: require("./assets/arctictracks/pic_default.svg").default,
        setting_icon: require("./assets/arctictracks/settings.svg").default,
        dot_selected: require("./assets/arctictracks/dot_selected.svg").default,
        dot_unselected: require("./assets/arctictracks/dot_unselected.svg").default,
        cross_red: require("./assets/arctictracks/cross_red.svg").default,
        check_light_navy: require("./assets/arctictracks/check_light_navy.svg").default,
        backward_arrow_grey: require("./assets/arctictracks/backward_arrow_grey.svg").default,
        statistics: require("./assets/arctictracks/statistics.svg").default,
        time_white: require("./assets/arctictracks/time_white.svg").default,
        distance_white: require("./assets/arctictracks/distance_white.svg").default,
        co2_white: require("./assets/arctictracks/co2_white.svg").default,
        down_arrow: require("./assets/arctictracks/down_arrow.svg").default,
        xCheckmark: require("./assets/arctictracks/xCheckmark.svg").default,
        Blue_cycle: require("./assets/arctictracks/Blue_cycle.svg").default,
        show_geofence_setting_active: require("./assets/arctictracks/map_settings/show_geofence_active.svg").default,
        show_geofence_setting_inactive: require("./assets/arctictracks/map_settings/show_geofence_inactive.svg").default,
        trace_unit_active: require("./assets/arctictracks/map_settings/trace_unit_active.svg").default,
        trace_unit_inactive: require("./assets/arctictracks/map_settings/trace_unit_inactive.svg").default,
        white_cross: require("./assets/arctictracks/white_corss.svg").default,
        vectortoggle: require("./assets/arctictracks/vectortoggle.svg").default,
        vector: require("./assets/arctictracks/Vector.svg").default,
        Earth_Blue: require("./assets/arctictracks/Earth_Blue.svg").default,
        device_moving: require("./assets/arctictracks/alarmMarker/device_moving.png").default,
        device_stopped: require("./assets/arctictracks/alarmMarker/device_stopped.png").default,
        device_unknown: require("./assets/arctictracks/alarmMarker/device_unknown.png").default,
        device_vibration: require("./assets/arctictracks/alarmMarker/device_vibration.png").default,
        device_internal_error: require("./assets/arctictracks/alarmMarker/device_internal_error.png").default,
        online: require("./assets/arctictracks/alarmMarker/online.png").default,
        offline: require("./assets/arctictracks/alarmMarker/offline.png").default,
        geofence_exit: require("./assets/arctictracks/alarmMarker/geofence_exit.png").default,
        geofence_enter: require("./assets/arctictracks/alarmMarker/geofence_enter.png").default,
        device_tamper: require("./assets/arctictracks/alarmMarker/device_tamper.png").default,
        low_battery: require("./assets/arctictracks/alarmMarker/low_battery.png").default,
        low_external_battery: require("./assets/arctictracks/alarmMarker/low_external_battery.png").default,
        ignition_on: require("./assets/arctictracks/alarmMarker/ignition_on.png").default,
        ignition_off: require("./assets/arctictracks/alarmMarker/ignition_off.png").default,
        power_cut: require("./assets/arctictracks/alarmMarker/power_cut.png").default,
        device_sos: require("./assets/arctictracks/alarmMarker/device_sos.png").default,
        speed_alarm: require("./assets/arctictracks/alarmMarker/speed_alarm.png").default,
        bypass: require("./assets/arctictracks/Dark/bypass_dark.svg").default,
        position_info_maker: require("./assets/arctictracks/position_info_maker.svg").default,
        moving_point: require("./assets/arctictracks/movingpoint.png").default,
        device_stopped_marker: require("./assets/arctictracks/markers/device_stopped_marker.png").default,
        device_position_time: require("./assets/arctictracks//device_icons/device_position_time.svg").default,
        device_battery: require("./assets/arctictracks/device_icons/device_battery.svg").default,
        map_straming_marker: require("./assets/arctictracks/markers/map_straming_marker.svg").default,


        amex_primary: require("./assets/arctictracks/amex_primary.svg").default,
        amex_second: require("./assets/arctictracks/amex_second.svg").default,
        mastercard_primary: require("./assets/arctictracks/mastercard_primary.svg").default,
        mastercard_second: require("./assets/arctictracks/mastercard_second.svg").default,
        default_second: require("./assets/arctictracks/default_second.svg").default,
        trash_red_icon: require("./assets/arctictracks/trash_red_icon.svg").default,
        default_primary: require("./assets/arctictracks/default_primary.svg").default,
        locaation_marker: require("./assets/arctictracks/locaation_marker.svg").default,
        bikeActive: require("./assets/arctictracks/bikeActive.svg").default,
        cross_inactive: require("./assets/arctictracks/cross_inactive.svg").default,
        check_empty_light: require("./assets/arctictracks/check_empty_light.svg").default,

        switch_on: require("./assets/arctictracks/switch_on.svg").default,
        switch_off: require("./assets/arctictracks/switch_off.svg").default,

        group_setting: require("./assets/arctictracks/group_setting.svg").default,
        group_setting_active: require("./assets/arctictracks/group_setting_active.svg").default,
        addGroupinctive: require("./assets/arctictracks/addGroupinctive.svg").default,
        addGroupactive: require("./assets/arctictracks/addGroupactive.svg").default,
        straigth_Arrow: require("./assets/arctictracks/straigth_Arrow.svg").default,
        Upside_down: require("./assets/arctictracks/Upside_down.svg").default,
        green_tick: require("./assets/arctictracks/green_tick.svg").default,

        map_marker_malt_red: require("./assets/arctictracks/markers/map-marker-alt-red.svg").default,
        // stream_active: require("./assets/arctictracks/sidebar/stream_active.svg").default,

        live_tutorial_1: require("./assets/arctictracks/live_tutorial_1.svg").default,
        live_tutorial_2: require("./assets/arctictracks/live_tutorial_2.svg").default,
        live_tutorial_3: require("./assets/arctictracks/live_tutorial_3.svg").default,
        live_tutorial_4: require("./assets/arctictracks/live_tutorial_4.svg").default,
        live_tutorial_5: require("./assets/arctictracks/live_tutorial_5.svg").default,
        live_tutorial_6: require("./assets/arctictracks/live_tutorial_6.svg").default,
        live_tutorial_7: require("./assets/arctictracks/live_tutorial_7.svg").default,
        live_tutorial_8: require("./assets/arctictracks/live_tutorial_8.svg").default,
        marker_stream: require("./assets/arctictracks/marker_stream.svg").default,
        marker_stream2: require("./assets/arctictracks/marker_stream2.svg").default,
        copy: require("./assets/arctictracks/copy.svg").default,
        app_logo: require('./assets/arctictracks/logo.png').default,
        app_logo_login: require('./assets/arctictracks/logo_login.png').default,

        default_blue_pin: require("./assets/arctictracks/device_icons/MapPins/Default pins/png/default_blue.png").default,
        default_grey_pin: require("./assets/arctictracks/device_icons/MapPins/Default pins/png/default_grey.png").default,


        ////// side bar icons ////////////

        active_arrow: require("./assets/arctictracks/sidebar/active_arrow.svg").default,
        up_arrow_active: require("./assets/arctictracks/sidebar/up_arrow_active.svg").default,
        device_active: require("./assets/arctictracks/sidebar/device_active.svg").default,
        device_inactive: require("./assets/arctictracks/sidebar/device_inactive.svg").default,
        bell_active: require("./assets/arctictracks/sidebar/bell_active.svg").default,
        bell_inactive: require("./assets/arctictracks/sidebar/bell_inactive.svg").default,
        geofence_inactive: require("./assets/arctictracks/sidebar/geofence_inactive.svg").default,
        geofence_active: require("./assets/arctictracks/sidebar/geofence_active.svg").default,
        medal_active: require("./assets/arctictracks/sidebar/medal_active.svg").default,
        medal_inactive: require("./assets/arctictracks/sidebar/medal_inactive.svg").default,
        stream_active: require("./assets/arctictracks/sidebar/stream_active.svg").default,
        stream_inactive: require("./assets/arctictracks/sidebar/stream_inactive.svg").default,
        statistics_active: require("./assets/arctictracks/sidebar/statistics_active.svg").default,
        statistics_inactive: require("./assets/arctictracks/sidebar/statistics_inactive.svg").default,
        news_active: require("./assets/arctictracks/sidebar/news_active.svg").default,
        news_inactive: require("./assets/arctictracks/sidebar/news_inactive.svg").default,
        card_active: require("./assets/arctictracks/sidebar/card_active.svg").default,
        card_inactive: require("./assets/arctictracks/sidebar/card_inactive.svg").default,


        watch: require("./assets/arctictracks/watch.svg").default,
        distance_two: require("./assets/arctictracks/distance.svg").default,
        RoutesTutorial: require("./assets/arctictracks/RoutesTutorial.svg").default,
        forward_arrow_white: require("./assets/arctictracks/forward_arrow_white.svg").default,
        dark_bike: require("./assets/arctictracks/dark_bike.svg").default,
        Download_Dark: require("./assets/arctictracks/Download_Dark.svg").default,
        SettingButton: require("./assets/arctictracks/settingButton.svg").default,
        expand: require("./assets/arctictracks/expand_arrow.svg").default,
        dots_vertical: require("./assets/arctictracks/dots_vertical.svg").default,
        plus_grey: require("./assets/arctictracks/plus_grey.svg").default,
        share_grey: require("./assets/arctictracks/share_grey.svg").default,
        map_marker_alt_blue: require("./assets/arctictracks/map-marker-alt_blue.svg").default,
        check_empty_white: require("./assets/arctictracks/check_empty_white.svg").default,
        user: require("./assets/arctictracks/user.svg").default,
        signup_congrats: require("./assets/arctictracks/signup_congrats.svg").default,
        placeholder_image: require("./assets/arctictracks/placeholder_image.svg").default,
        placeholder_circle: require("./assets/arctictracks/placeholder_circle.svg").default,
        placeholder_delete: require("./assets/arctictracks/placeholder_delete.svg").default,
        oop_char: require("./assets/arctictracks/oop_char.svg").default,
        statistics_line: require("./assets/arctictracks/statistics_line.svg").default,
        Download_icon: require("./assets/arctictracks/Download_icon.svg").default,
        mass_share_select: require("./assets/arctictracks/massShareSelect.svg").default,
        massshare_desktopSelect: require("./assets/arctictracks/massshare_desktopSelect.svg").default,
        share_square: require("./assets/arctictracks/share_square.svg").default,
        priority: require("./assets/arctictracks/priority.svg").default,
        priority_select: require("./assets/arctictracks/priority_select.svg").default,

        geo_tutorial_1: require("./assets/arctictracks/geo_tutorial_1.svg").default,
        geo_tutorial_2: require("./assets/arctictracks/geo_tutorial_2.svg").default,
        geo_tutorial_3: require("./assets/arctictracks/geo_tutorial_3.svg").default,
        geo_tutorial_4: require("./assets/arctictracks/geo_tutorial_4.svg").default,
        geo_tutorial_5: require("./assets/arctictracks/geo_tutorial_5.svg").default,
        geo_tutorial_6: require("./assets/arctictracks/geo_tutorial_6.svg").default,
        geo_tutorial_7: require("./assets/arctictracks/geo_tutorial_7.svg").default,
        geo_tutorial_8: require("./assets/arctictracks/geo_tutorial_8.svg").default,

        settings_active: require("./assets/arctictracks/sidebar/settings_active.svg").default,
        settings_inactive: require("./assets/arctictracks/sidebar/settings_inactive.svg").default,
        cross: require("./assets/arctictracks/cross.svg").default,
        tick: require("./assets/arctictracks/tick.svg").default,

        up_arrow_black: require("./assets/arctictracks/up_arrow_black.svg").default,
        up_arrow_grey: require("./assets/arctictracks/up_arrow_grey.svg").default,

        // signup_congrats: require("./assets/swetrack/signup_congrats.svg").default,
        sparking: require("./assets/arctictracks/sparking.gif").default,
        position_info_maker_time: require("./assets/arctictracks/position_info_maker.svg").default,
        sort_button: require("./assets/arctictracks/sort_button.svg").default,
        cog: require("./assets/arctictracks/cog.svg").default,

        distance_device: require("./assets/arctictracks/distance_device.svg").default,
        bike_vibration_dark: require("./assets/arctictracks/Dark/bike_vibration_dark.svg").default,
        bike_vibration_light: require("./assets/arctictracks/bike_vibration_light.svg").default,

        locationhistoryStart: require("./assets/arctictracks/markers/location_history_start_flag.svg").default,
        locationhistoryEnd: require("./assets/arctictracks/markers/location_history_end_flag.svg").default,
        
        users: require("./assets/arctictracks/users.svg").default,

        
    }
}

export default FlavorImages