import { Card, Dialog } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
// import close_gray from "../../assets/images/close_gray.svg";
import ReactHtmlParser from "react-html-parser";
import FlavorImages from "../../assetsFolderImport";
const ErrorDialog = (props) => {
    const { t } = useTranslation();
    return (
        <>
            <Dialog open={!!props.showErrorDialog}>
                <Card className="card width_335_ConfirmPopup">
                    <div className="text-center">
                        <div className="dialog_top_sort_row py-0 pb-2 card-header bg-white">
                            <label className="text_17_700 text-grey">
                            {props.select==="locationhistory"  ?
                                t("errors.Nolocationhistory") : t("errors.Error")  }
                            </label>
                            <img
                               className="cursor-pointer"
                                src={FlavorImages.close_gray}
                                onClick={() => props.onClose()}
                                alt="..."
                            />
                        </div>
                        <div className="card-body py-4">
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center">
                                    <label
                                        className={`my-2 ml-2 text_13_700_no_color text-grey`}
                                    >
                                {props.select==="locationhistory"  ?
                                ReactHtmlParser(t("errors.Thereisnorecordlocation")) : props.errorMesage}
                                    </label>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="m-0 card-footer  d-flex justify-content-end">
                        <button
                            className="btn bg-transparent py-1 px-2 text-grey text_12_700  "
                            onClick={() => props.onClose()}
                            variant="light"
                            style={{ marginTop: "5px" }}
                        >
                            {t("actionDialog.cancel")}
                        </button>
                        <button
                            className="btn btn_navy py-1 px-2 ml-3 text_12_700"
                            onClick={() => props.onClose()}
                            style={{ marginTop: "5px", marginLeft: "10px" }}
                        >
                            {t("signup.Accept")}
                        </button>
                    </div>
                </Card>
            </Dialog>
        </>
    );
};
export default ErrorDialog;
