import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React from "react";
import { useTranslation } from "react-i18next";
// import close_gray from "../../assets/images/close_gray.svg";
import FlavorImages from "../../assetsFolderImport";
const GenericConfirmPopuptwo = (props) => {
    const { t } = useTranslation();
    return (
        <>
            <Dialog open={props.show}>
                <Card className="card width_335_ConfirmPopup" >
                    <div className="text-center">
                        <div
                            className="dialog_top_sort_row py-0  card-header"
                            style={{ backgroundColor: "white" }}
                        >
                            <label className="text_17_700 ml-2 text-grey">
                                {props.mainHeadingText}
                            </label>
                            <img
                                src={FlavorImages.close_gray}
                                className="cursor-pointer"
                                onClick={() => props.closeDialogBox()}
                                alt="..."
                            />
                        </div>
                    </div>
                    <div className="row px-2 mt-3">
                        <div className="col-12 col-md-10 offset-md-1 ">
                            <div className="form-outline text-center">
                                <label className="text-black1 mb-0  text_11_500 pb-4 text-grey">
                                    {props.bodyText}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="m-0 card-footer  d-flex justify-content-between">
                        <button
                            className={`btn-sm border-0 t py-1 bg-transparent px-3 text-grey`}
                            onClick={() => props.closeDialogBox()}
                            variant="light"
                        >
                        {t("actionDialog.cancel")}
                        </button>
                        <div>
                            <button
                                className={`btn-sm border-0 t py-1 ${props.firstButtoncolor} px-3 text-white`}
                                onClick={() => props.onDelete(props.value.id)}
                                variant="light"
                            >
                               
                                {props.confirmButtonText}
                            </button>
                            <button
                                onClick={() => props.onConfirm()}
                                className={`btn-sm border-0 py-1 px-3 ${props.secondButtoncolor} text-white ml-3`}
                            >
                                 {t(" Edit")}
                            </button>
                        </div>
                    </div>
                </Card>
            </Dialog>
        </>
    );
};

export default GenericConfirmPopuptwo;
