import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  step: "",
  email: "",
  confirmEmail: "",
  password: "",
  username: "",
  userData:"",
  imeinumber: "",
  agree: false,
  pretoken:false
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const registerSlice = createSlice({
  name: "register",
  initialState: initialState,
  reducers: {
    setStep: (state, action) => {
      state.step = action.payload.step;
    },
    setokenData :(state ,action)=>
    {
      state.pretoken = action.payload.pretoken;
    },
    setStepEmailData: (state, action) => {
      state.email = action.payload.email;
      state.step = "password";
    },
    setStepConfirmEmailData: (state, action) => {
      state.confirmEmail = action.payload.confirmEmail;
    },
    setStepPasswordData: (state, action) => {
      state.password = action.payload.password;
      state.step = "accountDetail";
    },
    setStepAccountDetailData: (state, action) => {
      state.username = action.payload.username;
      state.step = "vinnumber";
    },
    setStepVINNumberData: (state, action) => {
      state.imeinumber = action.payload.imeinumber;
    },
    setUserAccountData:(state, action)=>
    {
      state.userData= action.payload.userData
    },
    setAgree: (state, action) => {
      state.agree = action.payload.agree;
    },
    setAgree2: (state, action) => {
      state.agree2 = action.payload.agree2
    }
  },
});
