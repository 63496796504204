import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Chart from "react-apexcharts";
import SideBarNew from "../../components/SideBar/SideBarNew";
import { INDEX_URL, SITE_TITLE } from "../../helpers/constants";
import { seo } from "../../helpers/seo";
import * as genericService from "../../services/genericService";
import { authSlice } from "../../slice/authSlice";
import { devicesSlice } from "../../slice/deviceSlice";
import * as statisticsjson from "../mocks/statistics";
import _ from "lodash";
import Statsmodal from "./stats_Modal"
import StatisticsRow from "./StatisticsRow";
import * as helper_functions from "../../helpers/helper_functions";
// assets
// import back_arrow_statistics from "../../assets/images/back_arrow_statistics.svg"
// import chevron_left_helkama from "../../assets/Helkama_images/Month_Chevron.svg"
// import Month_Chevron_1 from "../../assets/Helkama_images/Month_Chevron-1.svg"
// import forward_arrow_stats from "../../assets/images/forward_arrow_stats.svg"
// import crossCricle from "../../assets/images/cross-circle-down.svg"
// import statistics from "../../assets/images/statistics.svg";
// import statistics_helkama from "../../assets/helkama_assets/statistics.png";
// import co2_saved_white from "../../assets/images/co2_saved_white.svg";
// import eco_white_helkama from "../../assets/Helkama_images/eco_white.svg";
// import distance_white from "../../assets/images/distance_white.svg";
// import distance_white_helkama from "../../assets/helkama_assets/routes_white.png";
// import speed_white from "../../assets/images/speed_white.svg";
// import time_white from "../../assets/images/time_white.svg";
// import time_white_helkama from "../../assets/helkama_assets/clock_white.png";
// import cal_wthie from "../../assets/images/cal_wthie.svg";
// import cal_white_helkama from "../../assets/Helkama_images/fire-alt_white.svg"
// import co2_white from "../../assets/images/co2_white.svg";
// import co2_white_helkama from "../../assets/helkama_assets/co2_white.png";

import ErrorDialog from "../LocationHistory/ErrorDialog";
import { width } from "@mui/system";
import FlavorImages from "../../assetsFolderImport";
import LocationHistory from "../LocationHistory/LocationHistory";

const Statistics = () => {
	const dispatch = useDispatch();
	const deviceActions = devicesSlice.actions;
	const { t } = useTranslation();
	const authAction = authSlice.actions;
	const history = useHistory();
	const [error, setError] = useState("");
	const [select, setSelect] = useState("");
	const [showTutorial, setShowTutorial] = useState(false);
	const [showEditDialog, setShowEditDialog] = useState(false);
	const [editEmailDialog, showEditEmailDialog] = useState(false);
	const [passwordDialog, setPasswordDialog] = useState(false);
	const [shairedBike, setShairedBike] = useState(false);
	const [signOutDialog, setSignoutDialog] = useState(false);
	const [selectedDevicesSummary, setSelectedDevicesSummary] = useState();
	const [speedData, setSpeedData] = useState(null);
	const [TimeData, setTimeData] = useState(null);
	const [distanceData, setDistanceData] = useState(null);
	const [Co2Data, setCo2Data] = useState(null);
	const [Co2SavedData, setCo2SavedData] = useState(null);
	const [CalData, setCalData] = useState(null);
	const [Co2VsData, setCo2VsData] = useState(null);
	const [Co2SavedTrainData, setCo2SavedTrainData] = useState(null);
	const [Co2SavedPlainData, setCo2SavedPlainData] = useState(null);
	const [Co2SavedType, setCo2SavedType] = useState("Car");
	const [errorDialog, setShowErrorDialog] = useState(false);
	const [unitsInAll, setUnitsInAll] = useState({})
	const [unitsInSummary, setUnitsInSummary] = useState({})
	const [YearDropdownOptions, setYearDropdownOptions] = useState([
		{ value: 2015, isSelected: false },
		{ value: 2016, isSelected: false },
		{ value: 2017, isSelected: false },
		{ value: 2018, isSelected: false },
		{ value: 2019, isSelected: false },
		{ value: 2020, isSelected: false },
		{ value: 2021, isSelected: false },
		{ value: 2022, isSelected: false },
		{ value: 2023, isSelected: false },
	])
	const [MonthDropDownOptions, setMonthDropDownOptions] = useState([
		{ id: 1, value: t('Statistics.January'), isSelected: false }, { id: 2, value: t('Statistics.February'), isSelected: false },
		{ id: 3, value: t('Statistics.March'), isSelected: false }, { id: 4, value: t('Statistics.April'), isSelected: false },
		{ id: 5, value: t('Statistics.May'), isSelected: false }, { id: 6, value: t('Statistics.June'), isSelected: false },
		{ id: 7, value: t('Statistics.July'), isSelected: false }, { id: 8, value: t('Statistics.August'), isSelected: false },
		{ id: 9, value: t('Statistics.September'), isSelected: false }, { id: 10, value: t('Statistics.October'), isSelected: false },
		{ id: 11, value: t('Statistics.November'), isSelected: false }, { id: 12, value: t('Statistics.December'), isSelected: false }
	])
	const [AllStatsDevices, setAllStatsDevices] = useState([])
	const [SummaryDevices, setSummaryDevices] = useState([])
	const [summaryDevicesSelected, setsummaryDevicesSelected] = useState([]);
	const [topSpeedata, setTopSpeed] = useState([
		{ value: t('Statistics.TopSpeed'), isSelected: true },
		{ value: t('Statistics.AverageSpeed'), isSelected: false }
	])
	const [carsData, setCarsData] = useState([
		{ value: t('Statistics.Car'), isSelected: true },
		{ value: t('Statistics.Train'), isSelected: false },
		{ value: t('Statistics.AirPlane'), isSelected: false }
	])

	const [selectedYear, setSelectedYear] = useState(null);
	const [selectedMonth, setSelectedMonth] = useState(null);
	const [selectedDeviceIds, setSelectedDeviceIds] = useState([]);

	const [showYearsDropDown, setShowYearsDropDown] = useState(false)
	const [showMonthsDropDown, setShowMonthsDropDown] = useState(false)
	const [showDevicesDropDown, setShowDevicesDropDown] = useState(false)

	const [SaveCo2Margin, setSaveCo2Margin] = useState(true)
	const [showStates, setShowStates] = useState(false)

	const [xMinimum, setXMinimum] = useState(null)
	const [xMaximum, setXMaximum] = useState(null)
	const [statsList, setstatsList] = useState([]);
	const { devicesState } = useSelector(
		(state) => ({ devicesState: state.devices }),
		shallowEqual
	);

	const createDataset = (stats, units) => {
		let xData = stats.map(item => item.date)
		// Distance data
		let yData = stats.map(item => item.distance_total);
		let distanceData = createChartData(
			xData,
			yData,
			t("Statistics.Distance"), '',
			units.distance_measurement
		);
		setDistanceData(distanceData);

		// Max Speed chart
		yData = stats.map(item => item.speed_max);
		let data = createChartData(
			xData,
			yData,
			t("Statistics.Speed"), '',
			units.speed_measurement
		);

		setSpeedData(data);

		// time
		yData = stats.map(item => item.time_total);
		let timeData = createChartData(
			xData,
			yData,
			t("Statistics.Time"), '',
			units.time_measurement
		);

		setTimeData(timeData);

		// co2
		yData = stats.map(item => item.co2_total);
		let co2Data = createChartData(
			xData,
			yData,
			t("Statistics.CO2"), '',
			units.co2_measurement + " " + t("Statistics.CO2")
		);

		setCo2Data(co2Data);

		// kcal
		yData = stats.map(item => item.kcal_total);
		let kcalData = createChartData(
			xData,
			yData,
			t("Statistics.Calories"), '',
			t("Statistics.Calories")
		);

		setCalData(kcalData);

		// co2 vs car
		yData = stats.map(item => item.co2_saved_vs_car);
		let co2vsData = createChartData(
			xData,
			yData,
			t("Statistics.COSavedVs"), '',
			units.co2_measurement + t("Statistics.CO2")
		);

		setCo2SavedData(co2vsData);
	};

	const createChartData = (XxisData, YxisData, title, xUnit, yUnit) => {
		const yMax = Math.max(...YxisData);
		const yMin = Math.min(...YxisData);

		//console.log(XxisData)
		const range = 7776020000

		const data = {
			series: [
				{
					name: title,
					data: YxisData,
				},
			],

			options: {
				backgroundColor: "#FFFFFF",
				title: {
					text: title,
					align: "left",
					style: {
						fontFamily: process.env.REACT_APP_FLAVOR === 'helkama' ? "GilroyBoldItalic" : "Roboto",
						fontWeight: 700,
						fontSize: "22px",
					},
				},

				chart: {
					height: 350,
					type: "area",
					toolbar: {
						autoSelected: 'pan',
						show: false,
					},
					events: {
						beforeZoom: function (ctx) {
							// we need to clear the range as we only need it on the iniital load.
							ctx.w.config.xaxis.range = undefined
						},
					},
				},

				tooltip: {
					enabled: true,
					x: {
						show: false,
					},
					marker: {
						show: false,
					},
					y: {
						formatter: function (value) {
							if (value === null) {
								return 0; // Return 0 if value is null
							}
							if (yUnit === 'minutes') {
								return helper_functions.default.formatTime(value, t);
							  } else {
								if( title == t("Statistics.Speed")){
									return value + " " + yUnit
								  }else{
									return value.toFixed(1) + " " + yUnit
								  }
							  }
							
						},
						title: {
							formatter: () => false,
						},
					},
				},

				colors: [process.env.REACT_APP_FLAVOR === 'helkama' ? "#22EB6F" : "#4FAADB"],

				stroke: {
					curve: process.env.REACT_APP_FLAVOR === 'helkama' ? "straight" : "smooth",
				},

				fill: {
					type: "gradient",
					gradient: {
						opacityFrom: process.env.REACT_APP_FLAVOR === 'helkama' ? 1.0 : 0.86,
						opacityTo: process.env.REACT_APP_FLAVOR === 'helkama' ? 0.5 : 0.3,
					},
				},
				dataLabels: {
					enabled: false,
				},

				xaxis: {
					categories: XxisData,
					labels: {
						format: 'yyyy-MM-dd',
						rotate: 0, // Set rotation to 0 degrees
					},
					tickAmount: Math.min(XxisData.length, 4), // Display up to 5 labels

				},

				yaxis: {
					type: "numeric",
					tickAmount: 2,
					max: yMax * 1.5,
					min: (yMin < 0) ? yMin * 1.5 : 0, // if minmum value was in negatives, create a space between boundary and data value
					labels: {
						formatter: function (val) {
							if (val === null) {
								return 0; // Return null if val is null
							}
							if (yUnit === 'minutes') {
								return helper_functions.default.formatTime(val, t);
							  } else {
								console.log(title);
								if( title == t("Statistics.Speed")){
									return Math.round(val) + " " + yUnit
								  }else{
									return val.toFixed(1) + " " + yUnit
								  }
							  }
						},
					},
				},
			},
		};

		return data;
	};

	const deleteDialog = () => {
		setSignoutDialog(true);
		setSelect("Signout");
	};
	const { authState } = useSelector(
		(state) => ({ authState: state.auth }),
		shallowEqual
	);

	const loadAllStatistics = async (year = null, month = null, deviceIds = []) => {
		var data = {}
		if (year) {
			data.year = year + ""
		}
		if (month) {
			data.month = month + ""
		}
		if (deviceIds.length !== 0) {
			data.deviceid = deviceIds
		}

		const response = await genericService.post("statistic/all", data);
		if (response.status === 200) {
			var statsAll = response.data;
			setstatsList(statsAll.response)
			setUnitsInAll(statsAll.measurement)

			// createDataset(response.data.response);
			createDataset(response.data.response, statsAll.measurement);
		} else {
			setShowErrorDialog(response.data.message || "Something went wrong with statistics data fetching")
		}
	};

	//  upper filters year, month and devices
	const onYearSelected = async (data) => {
		setYearDropdownOptions(data)
		const selection = data.filter(item => item.isSelected)[0]

		if (selection) {
			setSelectedYear(selection)
			loadAllStatistics(selection.value, selectedMonth ? selectedMonth.id : null, selectedDeviceIds.length ? selectedDeviceIds : [])
		} else {
			setSelectedYear(null)
			loadAllStatistics(null, selectedMonth ? selectedMonth.id : null, selectedDeviceIds.length ? selectedDeviceIds : [])
		}
	}

	const onMonthSelected = async (data) => {
		setMonthDropDownOptions(data)
		const selection = data.filter(item => item.isSelected)[0]

		if (selection) {
			setSelectedMonth(selection)
			loadAllStatistics(selectedYear ? selectedYear.value : null, selection.id, selectedDeviceIds.length ? selectedDeviceIds : [])
		} else {
			setSelectedMonth(null)
			loadAllStatistics(selectedYear ? selectedYear.value : null, null, selectedDeviceIds.length ? selectedDeviceIds : [])
		}
	}

	const onStatsDevicesSelected = async (data) => {
		setAllStatsDevices(data)
		const selection = data.filter(item => item.isSelected).map(item => item.id)

		if (selection.length !== 0) {
			setSelectedDeviceIds(selection)
			loadAllStatistics(selectedYear ? selectedYear.value : null, selectedMonth ? selectedMonth.id : null, selection)
		} else {
			setSelectedDeviceIds([])
			loadAllStatistics(selectedYear ? selectedYear.value : null, selectedMonth ? selectedMonth.id : null, [])
		}
	}

	// chart filters
	const onSpeedSelected = (data) => {
		const selection = data.filter(item => item.isSelected)[0]
		//console.log('top speed data:', data)

		if (selection) {
			setTopSpeed(data)
			let xData = statsList.map(item => item.date)
			switch (selection.value) {
				case t('Statistics.TopSpeed'):
					setSpeedData(
						createChartData(
							xData,
							statsList.map(item => item.speed_max),
							t('Statistics.Speed'), '',
							unitsInAll.speed_measurement
						));
					break;
				case t('Statistics.AverageSpeed'):
					setSpeedData(
						createChartData(
							xData,
							statsList.map(item => item.speed_average),
							t('Statistics.Speed'), '',
							unitsInAll.speed_measurement
						));
					break;
				default:
					break;

			}
		} else {
			setTopSpeed([
				{
					value: "Top speed",
					isSelected: true
				},
				{
					value: "Average speed",
					isSelected: false
				}
			])
			return
		}
	}

	const onCo2VsSelected = (data) => {
		const selection = data.filter(item => item.isSelected)[0]
		let xData = statsList.map(item => item.date)

		//console.log(selection)

		if (selection) {
			setCarsData(data)

			switch (selection.value) {
				case t('Statistics.Car'):
					setCo2SavedData(
						createChartData(
							xData,
							statsList.map(item => item.co2_saved_vs_car),
							t('Statistics.COSavedVs'), '',
							unitsInAll.co2_measurement + t('Statistics.CO2')
						));

					//console.log(statsList.map(item => item.co2_saved_vs_car))
					break;
				case t('Statistics.Train'):
					setCo2SavedData(
						createChartData(
							xData,
							statsList.map(item => item.co2_saved_vs_train),
							t('Statistics.COSavedVs'), '',
							unitsInAll.co2_measurement + t('Statistics.CO2')
						));

					//console.log(statsList.map(item => item.co2_saved_vs_train))
					break;
				case t('Statistics.AirPlane'):
					setCo2SavedData(
						createChartData(
							xData,
							statsList.map(item => item.co2_saved_vs_airplan),
							t('Statistics.COSavedVs'), '',
							unitsInAll.co2_measurement + t('Statistics.CO2')
						));

					//console.log(statsList.map(item => item.co2_saved_vs_airplan))
					break;
				default:
					break;

			}
		} else {
			setCarsData([
				{ value: t('Statistics.Car'), isSelected: true },
				{ value: t('Statistics.Train'), isSelected: false },
				{ value: t('Statistics.AirPlane'), isSelected: false }
			]);
			setCo2SavedData(
				createChartData(
					xData,
					statsList.map(item => item.co2_saved_vs_car),
					t('Statistics.COSavedVs'), '',
					unitsInAll.co2_measurement + t('Statistics.CO2')
				));

			//(statsList.map(item => item.co2_saved_vs_car))
			return
		}
	}

	const clearAllfilters = () => {
		setSelectedYear(null)
		setSelectedMonth(null)
		setSelectedDeviceIds([])

		var yersOptions = _.cloneDeep(YearDropdownOptions)
		setYearDropdownOptions(yersOptions.map((item, index) => {
			item.isSelected = false;
			return item;
		}))

		var monthsOptions = _.cloneDeep(MonthDropDownOptions)
		setMonthDropDownOptions(monthsOptions.map((item, index) => {
			item.isSelected = false;
			return item;
		}))


		var devicesOptions = _.cloneDeep(AllStatsDevices)
		setAllStatsDevices(devicesOptions.map(item => {
			item.isSelected = false;
			return item;
		}))

		var speedOptions = _.cloneDeep(topSpeedata)
		setTopSpeed(speedOptions.map((item, index) => {
			if (index === 0) {
				item.isSelected = true;
			} else {
				item.isSelected = false;
			}

			return item;
		}))

		var carOptions = _.cloneDeep(carsData)
		setCarsData(carOptions.map((item, index) => {
			if (index === 0) {
				item.isSelected = true;
			} else {
				item.isSelected = false;
			}

			return item;
		}))

		loadAllStatistics()
	}

	const loadStatsSummary = async (ids = []) => {
		var data = ids.length !== 0 ? { "deviceid": ids } : {};
		const response = await genericService.post("statistic/all/summery", data);
		if (response.status === 200) {
			var temp = _.cloneDeep(response.data.response)
			if (response.data?.errorCode ?? 0 === 30003) {
				// temp = []
				//console.log('do nothing if 3003 is coming')
			}
			else {
				Object.keys(temp).forEach(key => {
					if (temp[key] != null) { // Check if value is not null
						if (key === 'speed_average_per_ride' || key === 'speed_max' || key === 'kcal_avg' || key === 'kcal_total') {
							temp[key] = temp[key].toFixed(0);
						  } else {
							temp[key] = temp[key].toFixed(1);
						  }
					} else {
						temp[key] = "0"; // Set value to 0 if it's null
					}
				});
			}
			const zeroData = {
				distance_total: 0,
				distance_average_per_ride: 0,
				time_total: 0,
				time_average_per_ride: 0,
				speed_max: 0,
				speed_average_per_ride: 0,
				co2_total: 0,
				co2_average_per_ride: 0,
				co2_saved_vs_car: 0,
				co2_saved_vs_car_average_per_ride: 0,
				co2_saved_vs_train: 0,
				co2_saved_vs_train_average_per_ride: 0,
				co2_saved_vs_airplan: 0,
				co2_saved_vs_airplan_average_per_ride: 0,
				kcal_total: 0,
				kcal_av: 0
			};
			const zeroDataSummary = {
				co2_measurement: "kg",
				distance_measurement: "km",
				speed_measurement: "km/h",
				time_measurement: "minutes",
			};
			//console.log(response.data);
			setSelectedDevicesSummary(
				response.data.response?.length === 0 || response.data?.errorCode === 30003
					? zeroData : temp
			)
			setUnitsInSummary(
				response.data.response?.length === 0 || response.data?.errorCode === 30003
					? response.data.measurement
					: zeroDataSummary
			)
		} else {
			setShowErrorDialog(response.data.message || "Something went wrong with statistics data summary fetching")
		}
	}

	const onSummaryDevicesSelected = (selection) => {
		//console.log('summary', selection)
		setSummaryDevices(selection)
		const data = selection.filter(item => item.isSelected)
		loadStatsSummary(data.map(item => item.id))
	}

	const loadProfileSetting = async () => {
		const response = await genericService.get("account/info");
		if (response.status === 200) {
			var info = response.data;
			dispatch(
				authAction.setAuthData({
					authData: info,
				})
			);
		}
	};

	const getDevicesList = async () => {
		const response = await genericService.get("devices/compactinfo");
		if (response.status === 200) {
			var devices = response.data.devices.map(device => ({ id: device.id, value: device.name, isSelected: false }))
			setAllStatsDevices(devices)
			setSummaryDevices(devices)
		}
	};

	const on_load = () => {
		loadAllStatistics();
		loadStatsSummary();
		// loadProfileSetting();
		getDevicesList();

		seo({
			title: SITE_TITLE,
			metaDescription: "",
		});
	};

	useEffect(() => {
		on_load();
	}, []);

	const defaultProps = {
		center: {
			lat: 60.1282,
			lng: 18.6435,
		},
		zoom: 8,
	};

	return (
		<>
			<React.Fragment>
				<section className="section-home " id="login-page">
					<div className="middle-content d-flex justify-content-between h-100">
						<SideBarNew
							page="Statistics"
							onClick={(e) => {
								history.push(INDEX_URL + e);
							}}
						/>

						<div className="main_ui px-2 pt-4 w-100 overflow-y">
							<div className="stats-heading align-items-center col justifyMobileContent  m-md-0" style={{ marginTop: "20%", width: ' 100%', padding: '0' }}>
								<div className="d-flex align-items-center">
									<img src={ FlavorImages.statistics} className="d-none d-md-block home_title_icon" alt="..." />
									<label className="d-none d-md-block text_34_700 uppercase mb-0 ml-3">
										{t("Statistics.Statistics")}
									</label>
								</div>

								{!showStates ? (
									<>
										<div className="row justify-content-center align-items-center">
											<img src={FlavorImages.Month_Chevron} style={{ height: '23px' }} className="d-md-none d-sm-block home_title_icon" alt="..." onClick={() => setShowStates(true)} />
											<label className="d-md-none d-sm-block text_22_700 uppercase mb-0 ml-3">
												{t("Statistics.Filter")}
											</label>
											<img src={FlavorImages.Month_Chevron} style={{ height: '23px' }} className="d-md-none invisible d-sm-block home_title_icon size_20" alt="..." />
										</div>

										<div className="align-items-center d-flex ml-md-5 m-md-0 flex-mobile-wrap" style={{ paddingTop: "11px", width: '441px', overflow: 'unset	' }}>
											<Statsmodal
												data={YearDropdownOptions}
												label={
													YearDropdownOptions.filter(item => item.isSelected).length > 0 ?
														YearDropdownOptions.filter(item => item.isSelected)[0].value :
														t('Statistics.Year')
												}
												onSelect={onYearSelected}
												theme='dark'
												width='27%'
												classes={'year_selection_modal'}
												topFilters={true}
												singleSelection={true}
											/>

											<Statsmodal
												data={MonthDropDownOptions}
												label={
													MonthDropDownOptions.filter(item => item.isSelected).length > 0 ?
														MonthDropDownOptions.filter(item => item.isSelected)[0].value :
														t('Statistics.Month')
												}
												onSelect={onMonthSelected}
												theme='dark'
												width='27%'
												classes={'month_selection_modal'}
												topFilters={true}
												singleSelection={true}
											/>


											<Statsmodal
												data={AllStatsDevices}
												label={
													(AllStatsDevices.filter(item => item.isSelected).length > 0 ?
													AllStatsDevices.filter(item => item.isSelected).length : '') + " " + t(`${process.env.REACT_APP_FLAVOR=="Bike"?"Settings.Bikes": "Statistics.Devices"}`)
												}
												SaveCo2Margin={SaveCo2Margin}
												topFilters={true}
												onSelect={onStatsDevicesSelected}
												theme='dark'
												width='100%'
												classes={'device_selection_modal'}
											/>

											<div className="search_capsule px-2 mb-2"
												style={{
													backgroundColor: "#E04D43",

													marginLeft: "10px",
													marginTop: "1%"
												}}
											>
												<label className="text-center pr-1 px-md-2 text-uppercase">
													{t("common.ClearAll")}
												</label>
												<img src={FlavorImages.crossCricle} onClick={clearAllfilters} className="size_15 cursor-pointer mr-1" alt="..." />
											</div>
										</div>
									</>
								) : (
									<div className="align-items-center justify-content-center row">
										<img src={FlavorImages.Month_Chevron_1} style={{ height: '23px' }} className="d-md-none invisible d-sm-block home_title_icon" alt="..." />
										<label className="d-md-none d-sm-block text_22_700 uppercase mb-0 mr-3 ">
											{t("Statistics.Allstats")}
										</label>
										<img src={FlavorImages.Month_Chevron_1} style={{ height: '23px' }} className="d-md-none d-sm-block home_title_icon" alt="..." onClick={() => setShowStates(false)} />
									</div>
								)}
							</div>
							<div className="col-12 pt-2 px-0  row m-auto">
								{/* states start here */}
								<div className={`col-md-7 col-sm-12 pr-md-2  d-md-flex example ${showStates ? 'd-none' : 'd-sm-block'} p-0 `}>
									<div className="col-md-6 col-sm-12 p-0 m-0  ">
										<div className="col-12 p-0 m-0 bg-white rounded_8 small_shadow p-2">
											{speedData ? (
												<div>
													<Statsmodal
														data={topSpeedata}
														label={topSpeedata.filter(item => item.isSelected)[0].value}
														onSelect={onSpeedSelected}
														singleSelection={true}
														SaveCo2Margin={SaveCo2Margin}
														inStats={true}
														theme='dark'
														classes={'top_speed_selection_modal'}
													/>

													<Chart
														id="speed-chart"
														options={{
															...speedData.options,
															stroke: {
																curve: 'smooth' // Add smooth curve
															}
														}}
														series={speedData.series}
														type="area"
														height={250}
													/>
												</div>
											) : ""}
										</div>

										<div className="col-12 p-0 m-0 mt-2 bg-white  rounded_8 small_shadow p-2">
											{Co2Data ? (
												<Chart
													id="co2-chart"
													options={{
														...Co2Data.options,
														stroke: {
															curve: 'smooth' // Add smooth curve
														}
													}}
													series={Co2Data.series}
													type="area"
													height={250}
												/>
											) : null}
										</div>
										<div className="col-12 p-0 m-0 mt-2 bg-white  rounded_8 small_shadow p-2">
											{TimeData ? (
												<Chart
													options={{
														...TimeData.options,
														stroke: {
															curve: 'smooth' // Add smooth curve
														}
													}}
													series={TimeData.series}
													type="area"
													height={250}
												/>
											) : null}
										</div>
									</div>

									<div className="col-md-6 col-sm-12 p-0 m-0 ml-md-2 ml-0 mt-2 mt-sm-0 pb-3">
										<div className="col-12 p-0 m-0 bg-white rounded_8 small_shadow p-2">
											{distanceData ? (
												<Chart
													options={{
														...distanceData.options,
														stroke: {
															curve: 'smooth' // Add smooth curve
														}
													}}
													series={distanceData.series}
													type="area"
													height={250}
												/>
											) : null}
										</div>

										<div className="col-12 p-0 m-0 mt-2 bg-white  rounded_8 small_shadow p-2">
											{Co2SavedData ? (
												<>
													<Statsmodal
														data={carsData}
														label={carsData.filter(item => item.isSelected)[0].value}
														SaveCo2Margin={SaveCo2Margin}
														singleSelection={true}
														inStats={true}
														onSelect={onCo2VsSelected}
														theme='dark'
														classes={'car_selection_modal'}
													/>

													<Chart
														options={{
															...Co2SavedData.options,
															stroke: {
																curve: 'smooth' // Add smooth curve
															}
														}}
														series={Co2SavedData.series}
														type="area"
														height={250}
													/>
												</>
											) : null}
										</div>


										{process.env.REACT_APP_ASSETS !== "helkama"	
										? <div className="col-12 p-0 m-0 mt-2 bg-white  rounded_8 small_shadow p-2">
											{CalData ? (
												<Chart
													id="kcal-chart"
													options={{
														...CalData.options,
														stroke: {
															curve: 'smooth' // Add smooth curve
														}
													}}
													series={CalData.series}
													type="area"
													height={250}
												/>
											) : null}
										</div>
										: ""}
									</div>
								</div>
								{/* states end here */}

								<div className={`col-sm-12 col-md-5  d-md-block px-2 px-md-3 pb-3 ${showStates ? 'd-sm-block' : 'd-none'}`}>

									<div className="d-flex">
										<Statsmodal
											data={SummaryDevices}
											label={(SummaryDevices.filter(item => item.isSelected).length > 0 ?
												SummaryDevices.filter(item => item.isSelected).length : '') + " " + t(`${process.env.REACT_APP_FLAVOR=="Bike"?"Settings.Bikes": "Statistics.Devices"}`)}
											onSelect={onSummaryDevicesSelected}
											theme='dark'
											classes={'summary_devices_selection_modal'}
										/>
									</div>

									{selectedDevicesSummary ? (
										<>


											<div className="p-0 m-0 w-100">
												<StatisticsRow
													image={FlavorImages.speed_white}
													title={t("Statistics.Speed")}
													title1={t("Statistics.AveragePerRide")}
													title2={t("Statistics.MaxSpeed")}
													detail1={selectedDevicesSummary.speed_average_per_ride + " " + unitsInSummary.speed_measurement}
													detail2={selectedDevicesSummary.speed_max + " " + unitsInSummary.speed_measurement}
												/>

												<StatisticsRow
													image={FlavorImages.distance_white}
													title={t("Statistics.Distance")}
													title1={t("Statistics.AveragePerRide")}
													title2={t("Statistics.Total")}
													detail1={selectedDevicesSummary.distance_average_per_ride + " " + unitsInSummary.distance_measurement}
													detail2={selectedDevicesSummary.distance_total + " " + unitsInSummary.distance_measurement}
												/>

												<StatisticsRow
													image={FlavorImages.time_white}
													title={t("Statistics.Time")}
													title1={t("Statistics.AveragePerRide")}
													title2={t("Statistics.Total")}
													detail1={selectedDevicesSummary.time_average_per_ride + " " + t("LocationHistory.minutes")}
													detail2={helper_functions.default.formatTime(selectedDevicesSummary.time_total, t)}
												/>

												<StatisticsRow
													image={FlavorImages.eco_white}
													Showdevices={true}
													title={t("Statistics.COSavedVs")}
													title1={t("Statistics.AveragePerRide")}
													title2={t("Statistics.Total")}
													device={selectedDevicesSummary}
													unit={unitsInSummary.co2_measurement + ' CO₂'}
												/>

												<StatisticsRow
													image={ FlavorImages.co2_white}
													title={t("Statistics.CarbonDioxid")}
													title1={t("Statistics.AveragePerRide")}
													title2={t("Statistics.Total")}
													detail1={selectedDevicesSummary.co2_average_per_ride + unitsInSummary.co2_measurement + " CO₂"}
													detail2={selectedDevicesSummary.co2_total + unitsInSummary.co2_measurement + " CO₂"}
												/>

											{process.env.REACT_APP_ASSETS !== "helkama"
												?<StatisticsRow
													image={FlavorImages.fire_alt}
													title={t("Statistics.Calories")}
													title1={t("Statistics.AveragePerRide")}
													title2={t("Statistics.Total")}
													detail1={selectedDevicesSummary.kcal_avg + " kcal"}
													detail2={selectedDevicesSummary.kcal_total + " kcal"}
												/>
												: ""
											}
											</div>
										</>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</section>
			</React.Fragment>


			<ErrorDialog
				showErrorDialog={errorDialog}
				errorMesage={errorDialog}
				// select={"settings"}
				onClose={() => setShowErrorDialog(false)}
			/>
		</>
	);
};

export default Statistics;
