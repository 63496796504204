import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React from "react";
import { useTranslation } from "react-i18next";
// import close_gray from "../../assets/images/close_gray.svg";
import { shallowEqual, useSelector } from "react-redux";
// import user from "../../assets/images/user.svg";
// import check_empty_white from "../../assets/images/check_empty_white.svg";
// import checked from "../../assets/images/checked.svg";
import _ from "lodash";
import { useState, useEffect } from "react";
import * as genericService from "../../services/genericService";
import toast, { Toaster } from 'react-hot-toast';
import { processNodes } from "react-html-parser";
import FlavorImages from "../../assetsFolderImport";


const ShareBike = (props) => {
  const { t } = useTranslation();
  const [slectedDevice, setSelectDevice] = useState([]);
  const [email, setEmail] = useState("");
  const { devicesState } = useSelector(
    (state) => ({ devicesState: state.devices }),
    shallowEqual
  );
  useEffect(() => {
    let personalDevice = _.cloneDeep(devicesState.devices)
    setSelectDevice(personalDevice);
  }, [devicesState]);
  const { authState } = useSelector(
    (state) => ({ authState: state.auth }),
    shallowEqual
  );
  const onDeviceClick = (index) => {
    var array = _.cloneDeep(slectedDevice);
    var device = array[index];
    if (device.isSelected !== true) device.isSelected = true;
    else device.isSelected = false;
    array[index] = device;
    setSelectDevice(array);
  };
  const shareDevices = async () => {
    let dispatchArray = [];
    slectedDevice.map((device) => {
      if (device.isSelected === true) {
        dispatchArray.push(device.id);
      }
    });
    //console.log(dispatchArray);
    const response = await genericService.post("device/share/create", {
      deviceid: dispatchArray,
      email: email,
    });
    //console.log(response);
    if (response.status === 200 && response.data.successful) {
      toast.success(t("toast.Share"));
      // props.setSucces("The shared device has been successfull");
      props.closeShairedBikeDialog();
    } else {
      props.setShowErrorDialog();
      props.setErrorMesage(response.data.message)
      props.closeShairedBikeDialog();
    }
  };
  return (
    <>
      <Dialog open={props.show}>
        <Card className="card" style={{ width: "600px" }}>
          <div
            className="dialog_top_sort_row  "
            style={{ backgroundColor: "white" }}
          >
            <label className="text_22_700 ml-2"> {t("Device.ShareDevice")}</label>
            <img
              src={FlavorImages.close_gray}
              className="cursor-pointer"
              onClick={() => props.closeShairedBikeDialog()}
              alt="..."
            />
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-6 d-none d-md-block mt-5">
                <div className="row">
                  <div className="col d-flex justify-content-center">
                    <div className="rcorners">
                      <img src={FlavorImages.user} alt="..." />
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col d-flex justify-content-center">
                    <label className="text_17_700">
                      {process.env.REACT_APP_FLAVOR === "Bike"
                        ? t("Settings.ShareBike")
                        : t("Settings.ShareBike_alt")
                      }
                      </label>
                  </div>
                </div>
                <div className="row ml-2">
                  <label className="text_12_400">
                    {process.env.REACT_APP_FLAVOR === "Bike"
                      ? t("sharedevice.titleOne")
                      : t("sharedevice.titleOne_alt")
                    }
                  </label>
                </div>
              </div>
              <div className="col">
                <div className="row">
                  <div className="col-12">
                    <div className="form-group col-12">
                      <label
                        className="col-form-label text-black1"
                        style={{ fontSize: "small" }}
                        data-success="right"
                      >
                        {t("sharedevice.titleTwo")}
                      </label>
                      <input
                        type="text"
                        style={{ background: "#DDDDDD" }}
                        onChange={(e) => setEmail(e.target.value)}
                        className={`form-control`}
                        placeholder={t("login.Email")}
                      />
                    </div>
                    <div className="col-12 mt-2">
                      {devicesState.filteredDevices.length == 0 ? (
                        ""
                      ) : (
                        <label
                          className="col-form-label text-black1 font-weight-bold"
                          style={{ fontSize: "small" }}
                          data-success="right"
                        >
                          {process.env.REACT_APP_FLAVOR === "Bike"
                            ? t("sharedevice.Biketoshare")
                            : t("sharedevice.Biketoshare_alt")
                          }
                        </label>
                      )}
                      <div className="d-flex flex-column  rounded_grey scroll_div_8 height_150">
                        {slectedDevice.map((device, index) => {

                          return (
                            device.sharedstatus.sharedwithme ? "" :
                              <div key={index}>
                                <div className="px-3 py-2 d-flex  border-bottom justify-content-between align-items-center">
                                  <label
                                    className="text-black1 mb-0 text_10_400 "
                                    style={{ fontSize: "small" }}
                                  >
                                    {device.name}{" "}
                                  </label>

                                  <div className="d-flex border_radius_25 justify-content-end">
                                    {device.isSelected ? (
                                      <img
                                        className="cursor-pointer size_20 border_radius_25  "
                                        src={FlavorImages.checked}
                                        onClick={() => onDeviceClick(index)}
                                        alt="unchecked"
                                      />
                                    ) : (
                                      <img
                                        className="cursor-pointer size_20 border_radius_25 "
                                        src={FlavorImages.check_empty_white}
                                        onClick={() => onDeviceClick(index)}
                                        alt="unchecked"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5 d-flex justify-content-center">
              <div className="col-6">
                <button
                  className="cancel_btn grey_btn"
                  onClick={() => props.closeShairedBikeDialog()}
                  variant="light"
                >
                  {" "}
                  {t("actionDialog.cancel")}
                </button>
              </div>
              <div className="col-6">
                <button
                  className="Share_btn btn btn_navy bg_dark_helkama"
                  onClick={() => shareDevices()}
                  variant="light"
                >
                  {" "}
                  {process.env.REACT_APP_FLAVOR === "Bike"
                    ? t("Settings.ShareBike")
                    : t("Settings.ShareBike_alt")
                  }
                </button>
              </div>
            </div>
          </div>
        </Card>
      </Dialog>
    </>
  );
};

export default ShareBike;
