import React, { useState } from "react";
// import default_avatar from "../../assets/images/default_awatar.svg";
import DropDown from './DropDown';
import { useTranslation } from "react-i18next";
import FlavorImages from "../../assetsFolderImport";

const LeaderBoardCard = (props) => {
    const [defaultValue, setdefaultValue] = useState("distance")
    const { t } = useTranslation();

    const getNamePart = (name, part) => {
        let myString = name;
        let [firstPartName, secondPartName] = myString.split(" ");
        if (part == "firstpart") return firstPartName
        return secondPartName
    }
    const getValueWithUnit = (leader) => {
        if (defaultValue === 'distance' && leader && leader.distance) {
          return leader.distance.toFixed(1) + " " + "km";
        } else if (defaultValue === 'time' && leader && leader.durationtime) {
          return leader.durationtime.toFixed(0) + " " + "h";
        } else if (defaultValue === 'kcal' && leader && leader.kcal) {
          return leader.kcal.toFixed(0) + " " + "kcal";
        } else if (defaultValue === 'co2' && leader && leader.co2_saved) {
          return Math.round(leader.co2_saved * 10) / 10 + " " + "kg";
        }
      };
    let option = [
        {
            label: 'distance',
            value: 'distance'
        },
        {
            label: 'time',
            value: 'time'
        },
        {
            label: "kcal",
            value: "kcal"
        },
        {
            label: "co2",
            value: "co2"
        },
    ]
    const setDropDownValue = (value, label) => {
        setdefaultValue(value)
        props.sortLeaderBoard(value)
    }
    return (
        props.leaderBoard &&
        <div className="mt-2 mx-1 mb-3 alarm_list_shadow">
            <div className="d-flex px-2 py-3 bg-white justify-content-end align-items-center rounded-top">
                <label className="text_12_700 mr-2 mb-0 box_shadow_none">{t("Medal.Leaderboard")} <span className='text_10_300'> in </span> </label>
                <DropDown
                    caseSensitivity={"uppercase"}
                    setValue={setDropDownValue}
                    value={defaultValue}
                    values={option}
                />
            </div>
            {props.leaderBoard.length  ?
            <div className='scroll_div_16 legend_even '>
                {
                    props.leaderBoard.map((leader, index) => {
                        return (
                            <div
                                key={index}
                                className={`stripRow ${!leader.you ? " " : " whiteBackground"} ${index == props.leaderBoard.length - 1 ? "mb-2" : ""}`}>
                                <div className={`card border-0 py-1 pl-4 pr-2 ${!leader.you ? " rounded-0  accordion-no-styles" : "border me_card_style accordion-no-styles"}`}>
                                    <div className="shadow-none d-flex align-items-center">
                                        <label className="text_12_700 mt-3">{leader.number}</label>
                                        <div className="col-8 shadow-none d-flex align-items-center">
                                            <img
                                                className="mt-2 width_height_50  border_36 "
                                                src={leader.image ? leader.image : FlavorImages.default_avatar}
                                                alt={"...No image"}
                                            />
                                            <label className="text_16_700 text-center    mt-3 ml-3">{getNamePart(leader.username, "firstpart")}<br />  <span className="text_13_300 height_20 d-block">{getNamePart(leader.username, "secondpart") ? getNamePart(leader.username, "secondpart") : " "}</span> </label>
                                        </div>
                                        <div className="col-4 shadow-none d-flex align-items-center">
                                            <label className="text_16_700 mt-3 text-center ml-3">{getValueWithUnit(leader)}<br /> <span className="text_11_400">Total</span></label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        )
                    })
                }
                </div> :
                        <div className="bg-white mx-1 border-0 py-4 px-4 text-center accordion-no-styles">
                            <div className="shadow-none d-flex flex-column">
                                <label className="text_22_700 text-grey mt-2 ml-3">you have no friend yet</label>
                                <label className="text_16_700 text-grey mt-2 ml-3">press the<span className='text-bold text-black'> + </span>   to send a friend request</label>
            </div>
        </div>
            }
        </div>
    )

}
export default LeaderBoardCard;
