import ClickAwayListener from "@mui/material/ClickAwayListener";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { livestreamSlice } from "../../slice/liveStreamSlice";
// import check_empty from "../../assets/images/check_empty.svg";
// import checked from "../../assets/images/checked.svg";
import { shallowEqual,  useSelector } from "react-redux";
import FlavorImages from "../../assetsFolderImport";

const CustomDropdown3 = (props) => {
  const dispatch = useDispatch();
	const { liveStreamState } = useSelector(
    (state) => ({ liveStreamState: state.liveStream }),
    shallowEqual
  );
  const streamAction = livestreamSlice.actions;
  const [openCloseDropdown, setOpenCloseDropdown] = React.useState(false);
	const [devices,setDevices]=useState([])
	const [deviceName,setDeviceName]=useState()
	const [streamDevice,setStreamDevice]=useState()
	const [deviceId,setDeviceId]=useState('')
	const liveStreamAction = livestreamSlice.actions;

  const onDeviceClick=(deviceId,deviceName,device)=>
	{
		setStreamDevice(device)
		setDeviceName(deviceName);
		setDeviceId(deviceId)
	}
	const selectDeviceStream=()=>
	{
		props.getStreamDevices()
    if(streamDevice){

		dispatch((liveStreamAction.setStreamDeviceData({streamDeviceData:streamDevice})))
		props.setDeviceName(deviceName)
    dispatch(
      streamAction.setStreamDevice({
        streamDevice: deviceId,
      })
    );

    }
	}
	useEffect(()=>{
		setDeviceName(props.deviceName)
		if(devices!==props.streamgDevices)
		{
			setDevices(props.streamgDevices)
		}
	},[])
  return (
    <>
      {props.label ? (
        <label className={`custom-label`}>{props.label}</label>
      ) : (
        <></>
      )}
      <ClickAwayListener onClickAway={() => setOpenCloseDropdown(false)}>
        <div
          className={`custom-ul ${
            props.caseSensitivity ? `text-${props.caseSensitivity}` : ""
          }`}
        >
          <div
            className={`custom-ul-header  ${
              openCloseDropdown ? "opened cornerBefore2" : ""
            } rounded-circle   py-2 px-2`}
            onClick={() => setOpenCloseDropdown(!openCloseDropdown)}
          >
            <span className="text_11_700 ellipsis pr-1" >{props.deviceName}</span>
            <img
              className="custom-ul-header-arrow"
              alt="show"
              src={`${process.env.REACT_APP_BASE_URL}images/custom-dropdown-arrow.svg`}
            />
          </div>
          <div className="w-100 position-absolute">
            {openCloseDropdown && (
              <div className="card border-0" style={{width: "10rem",height:"fit-content",background:"var(--navy)",paddingBottom: "8px",zIndex:"99999",marginTop: "-2px" }}>
							<div className="pt-2"
										style={{height:"125px",overflowX:"auto"}}

							>
								{props.streamgDevices.map((device,index)=>{
                    return (
										<div key={index} className="d-flex  pl-2 pt-3"
										>
                        <div className=" d-flex  justify-content-between align-items-center">
                          <label
                            className="text-white pr-4 mb-0  text_12_400 "
                            style={{ width: "84px" }}
                          >
                            {device.devicename}
                          </label>

                          <div className="d-flex pl-4 justify-content-end">
										{
                    device.devicename ===deviceName? (
                      <img
                        className="mr-3 ml-2 check_box_style border_radius_25 mt-2"
                        src={FlavorImages.checked}
                        alt="checked"
                        onClick={() => onDeviceClick(device.id,device.devicename,device)}
                      />
                    ) : (
                      <img
                        className="mr-3 ml-2 check_box_style border_radius_25 mt-2"
                        src={FlavorImages.check_empty}
                        alt="unchecked"
                        onClick={() => onDeviceClick(device.id,device.devicename,device)}
                            />
                    )
                   }
                          </div>
                        </div>
                      </div>
									)
                  })}
                </div>
														<div className="row mt-2">
															<div className="col-md-10 offset-md-1">
															<div className="d-flex">
															<button
																	className="btn bg-grey2 mr-2 py-0 btn-sm font-bold text-grey"
																	onClick={() => setOpenCloseDropdown(false)}
																	variant="light"
																>
																	{("cancel")}
																</button>
																<button
																	className="btn bg-skyblue py-0  btn-sm font-bold text-white"
																	onClick={() => selectDeviceStream()}
																	variant="light"
																	style={{background:"#4FAADB"}}
																>
																	{("select")}
																</button>
															</div>
															</div>

															</div>
														<div>

														</div>
              </div>
            )}
          </div>
        </div>
      </ClickAwayListener>
    </>
  );
};

export default CustomDropdown3;
