import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// import Side_left_Arrow_white from "../../assets/images/Side_left_Arrow_white.svg"
// import Side_right_Arrow_white from "../../assets/images/Side_right_Arrow_white.svg"
import Chart from "react-apexcharts";
import { shallowEqual, useSelector } from "react-redux";
import CustomDropdown3 from "../../components/dropdowns/CustomDropdown3";
import { useParams } from "react-router-dom";
import * as helper_functions from "../../helpers/helper_functions";
import { formatDuration, intervalToDuration } from "date-fns";
import FlavorImages from "../../assetsFolderImport";

const StatististicsGraphLIveStream = (props) => {
  let { token } = useParams();
  const { t } = useTranslation();
  const [speedData, setSpeedData] = useState(null);
  const [distanceData, setDistanceData] = useState(null);
  const [Co2Data, setCo2Data] = useState(null);
  const [CalData, setCalData] = useState(null);
  const [selectedMap, setSelectedMap] = useState("speedData");
  const [graphTitle, setGraphTitle] = useState("");
  const [distance, setDistance] = useState(0)
  const { liveStreamState } = useSelector(
    (state) => ({ liveStreamState: state.liveStream }),
    shallowEqual
  );
  const distanceIsFound = async () => {
    let distance = await helper_functions.default.distanceBetweenLatLong(liveStreamState.locationLiveStream)
    setDistance(distance / 1000)
  }

  const setTitleAndMap = (title) => {
    if (title === "Co2Data") {
      setSelectedMap("Co2Data")
    }
    else if (CalData) {
      setSelectedMap("CalData")
    }
    setGraphTitle(title)
  }
  const createDataset = (device) => {
    let speedObjectList = device.speed;
    let speedWeek = [];
    let speedTotal = [];

    speedObjectList.map((speedList, index) => {
      if (index === 1) {
        speedList.map((speed, index) => {
          speedWeek.push(speed.week);
          speedTotal.push(speed.topspeed);
        });
      }
    });




    let data = createChartData(
      speedWeek,
      speedTotal,
      t("Statistics.Speed"),
      t("Statistics.Week"),
      "km/h"
    );
    setSpeedData(data);

    let distanceObjectList = device.distance;
    let distanceWeek = [];
    let distanceTotal = [];

    distanceObjectList.map((distanceList, index) => {
      if (index === 1) {
        distanceList.map((distance, index) => {
          distanceWeek.push(distance.week);
          distanceTotal.push(distance.totaldistance);
        });
      }
    });

    let distanceData = createChartData(
      distanceWeek,
      distanceTotal,
      t("Statistics.Distance"),
      t("Statistics.Week"),
      "km"
    );
    setDistanceData(distanceData);

    let CO2ObjectList = device.co2;
    let CO2Week = [];
    let Co2Total = [];
    let Co2Car = [];
    let Co2Train = [];
    let Co2Plain = [];

    CO2ObjectList.map((CO2List, index) => {
      if (index === 1) {
        CO2List.map((co2, index) => {
          CO2Week.push(co2.week);
          Co2Total.push(co2.totalco2);
          Co2Car.push(co2.totalco2savedcomparedtocar);
          Co2Train.push(co2.totalco2savedcomparedtotrain);
          Co2Plain.push(co2.totalco2savedcomparedtoairplan);
        });
      }
    });

    let dataCo2 = createChartData(
      CO2Week,
      Co2Total,
      "CO₂",
      t("Statistics.Week"),
      "CO₂"
    );
    setCo2Data(dataCo2);
    let TimebjectList = device.time;
    let TimeWeek = [];
    let TimeTotal = [];

    TimebjectList.map((TimeList, index) => {
      if (index === 1) {
        TimeList.map((time, index) => {
          TimeWeek.push(time.week);
          TimeTotal.push(time.totaltime);
        });
      }
    });
    let CaljectObjectList = device.calories;
    let CalWeek = [];
    let CalTotal = [];

    CaljectObjectList.map((CalList, index) => {
      if (index === 1) {
        CalList.map((cal, index) => {
          CalWeek.push(cal.week);
          CalTotal.push(cal.totalcalories);
        });
      }
    });

    let dataCal = createChartData(
      CalWeek,
      CalTotal,
      "Kcal",
      t("Statistics.Week"),
      "Kcal"
    );
    setCalData(dataCal);
  };

  const createChartData = (XxisData, YxisData, title) => {
    setGraphTitle(title);
    let xUnit = "";
    const data = {
      series: [
        {
          name: title,
          data: YxisData,
        },
      ],
      options: {
        backgroundColor: "#FFFFFF",
        title: {
          show: false,
        },

        chart: {
          height: 150,
          type: "area",
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: false,
          },
        },

        colors: ["#4FAADB"],

        stroke: {
          curve: "smooth",
        },
        fill: {
          type: "gradient",
          gradient: {
            opacityFrom: 0.86,
            opacityTo: 0.3,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "numeric",
          categories: XxisData,

          labels: {
            formatter: function (val) {
              return val.toFixed(1) + " " + xUnit;
            },
            style: {
              cssClass: "apexcharts-yaxis-label",
            },
          },
        },
        yaxis: {
          type: "numeric",
          show: false,
        },
      },
    };

    return data;
  };

  distanceIsFound()

  return (
    <>
      <div className={`card streamingCard map_statistics_detail_livestream border_radius_15`}
      >
        <div className="py-3 pl-2 pr-3 mb-0 border_radius_15 d-flex  align-items-center  bg-white">
          <img
            src={
              FlavorImages.stream_active
            }
            className="side_bar_icon2 d-none  d-md-inline"
            alt="..."
          />
          <h2 className=" mb-0 text-22-700 ml-2">
            {props.streamName}
          </h2>
        </div>
        <div className="row">
          <div className="col">

          </div>
        </div>

        <div className="row">
          <div className="col-6 col-md-3 ml-3 p-2">
            <CustomDropdown3
              caseSensitivity={"uppercase"}
              setValue={"setGender"}
              label={t("")}
              dropdownLabel={t("signup.Gender")}
              streamgDevices={props.streamgDevices}
              getStreamDevices={() => props.getStreamDevices()}
              setDeviceName={(deviceName) => props.setDeviceName(deviceName)}
              deviceName={props.deviceName}
            />
          </div>
        </div>
        {
          <>
            <div className="row">
              <div className="col-6 d-flex pl-4 pr-0 ">
                <p className="text-navy text_12_400 ">
                  {t(" Active For")}
                </p>
                <p className="text-navy text_12_700 pl-2">

                  &nbsp;  {formatDuration(
                    intervalToDuration({
                      start: new Date(),
                      end: new Date(props.streamTime),
                    }),
                    {
                      format: ["days", "hours", "minutes"],
                      delimiter: ", ",
                    }
                  )}
                </p>
              </div>
              <div className="col-6 d-flex pl-3  justify-content-start">
                <p className="text-navy text_12_400 ">
                  {t(" Bike has covered")}
                </p>
                <p className="text-navy text_12_700 pl-2">


                  {distance} {" "} {t("LocationHistory.km")}

                </p>
              </div>
            </div>
            {process.env.REACT_APP_FLAVOR === "Bike" ?
              <>
                <div className="row ml-5">
                  <div className="col">
                    <div class="d-flex flex-row">
                      <label class="pl-4 ml-2 text_22_700">{graphTitle}</label>
                      <label class="pl-4 text_22_700 text-grey"
                        onClick={() => setTitleAndMap("Co2Data")}
                      >{("Co2Data")}</label>
                      <label class="pl-4 text_22_700 text-grey ellipsis" onClick={() => setTitleAndMap("CalData")}>{("CalData")}</label>
                    </div>
                  </div>
                </div>
                <div className="col-12 ">
                  <div className="row ">
                    <img
                      className="size_1-5_rem p-1 bg-grey2 float-right ml-3 btnLeftArrowGraph"
                      src={FlavorImages.Side_left_Arrow_white}
                      onClick={() => setTitleAndMap("Co2Data")}
                      alt="..."
                    />
                    <div className="col-12 marginminus_52 pl-4">
                      {speedData && selectedMap === "speedData" ? (
                        <Chart
                          options={speedData.options}
                          series={speedData.series}
                          type="area"
                          height={180}
                          width={340}
                        />
                      ) : Co2Data && selectedMap === "Co2Data" ? (
                        <Chart
                          options={Co2Data.options}
                          series={Co2Data.series}
                          type="area"
                          height={180}
                          width={340}
                        />)
                        :
                        CalData && selectedMap === "CalData" ? (
                          <Chart
                            options={CalData.options}
                            series={CalData.series}
                            type="area"
                            height={180}
                            width={340}
                          />) :
                          distanceData ? (
                            <Chart
                              options={distanceData.options}
                              series={distanceData.series}
                              type="area"
                              height={180}
                              width={340}
                            />
                          ) : ""
                      }
                    </div>
                    <img
                      className="size_1-5_rem p-1 ml-4 bg-grey2 btnRightArrowGraph"
                      src={FlavorImages.Side_right_Arrow_white}
                      onClick={() => setTitleAndMap("CalData")}
                      alt="..."
                    />
                  </div>

                </div>
              </>
              :

              <div className="row mb-3 ml-1 align-items-center">
                <div className="col-sm-2">
                  <img
                    className="size_20"
                    alt={t("deviceMarker.Position")}
                    src={FlavorImages.map_maker_alt}
                  />
                </div>
                <div className="col-sm-8 px-0 d-flex flex-column">
                  <p className="text-navy text_12_700 p-0 m-0">
                    {t("deviceMarker.Position")}
                  </p>

                  {liveStreamState.streamDeviceData && <p className=" p-0 m-0 text-12-400">
                    {parseFloat(liveStreamState.streamDeviceData.points[0].latitude).toFixed(5) +
                      ", " +
                      parseFloat(liveStreamState.streamDeviceData.points[0].longitude).toFixed(5)}
                  </p>}
                  <p className="cursor-pointer p-0 m-0 text-12-400">
                    {t("deviceMarker.Tap to calculate address")}
                  </p>
                </div>
              </div>


            }

          </>}
      </div>


    </>
  );
};

export default StatististicsGraphLIveStream;
