import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  groups: [],
  filteredGroups: [],
  selectedGroup: [],
  selectedGroupDevices: [],
  groupName: "",
  sort: "",
};
export const groupSlice = createSlice({
  name: "groups",
  initialState: initialState,
  reducers: {
    setUpFilteredGroupsData: (state, action) => {
      state.filteredGroups = action.payload.groups;
    },
    setUpGroupData: (state, action) => {
      state.groups = action.payload.groups;
    },
    setSort: (state, action) => {
      state.sort = action.payload.sort;
    },
    setUpSlectedGroup: (state, action) => {
      state.selectedGroup = action.payload.selectedGroup;
    },
    setUpSelectdGroupDevices: (state, action) => {
      state.selectedGroupDevices = action.payload.selectedGroupDevices;
    },
    setGroupName: (state, action) => {
      state.groupName = action.payload.groupName;
    }
  },
});
