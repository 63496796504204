import React from "react";
// import locaation_marker from "../../assets/images/locaation_marker.svg";
// import bikeActive from "../../assets/images/bikeActive.svg";
// import checked from "../../assets/images/checked.svg";
// import cross_inactive from "../../assets/images/cross_inactive.svg";
import FlavorImages from "../../assetsFolderImport";
const DeviceRowMobile = (props) => {
	return (
		<React.Fragment>
			<div className="col-12 px-0">
				<div className="device_row_small_select ">
					<div className="card pl-2 p-0 w-100 bg-white">
						<div
							onClick={() => props.onClick()}
							className="px-1 ml-2 bg-white rounded">
							<div className="row">
								<div className="col-1 ml-1 mt-3 mr-2 mb-0 m-0">
									<div className="device_mobile_row_marker px-2">
										<img
											src={props.page === "liveStream" ? FlavorImages.bikeActive : FlavorImages.locaation_marker}
											className={`mb-2 mt-2}`}
											alt="..."
										/>
									</div>
								</div>
								<div className="col-8 p-0 ml-1 mt-2">
									<p className="text_12_400 mt-2">{props.device.name}</p>
								</div>
								<div className="col-2 mt-2">
									{props.isSelection ? (
										props.selected || props.device.isSelected ? (
											<img
												className="mr-3 ml-2 check_box_style mt-2"
												src={FlavorImages.checked}
												alt="checked"
												onClick={() => props.setChooseAll(false)}
											/>
										) : (
											<img
												className="mr-3 ml-2 check_box_style mt-2"
												src={FlavorImages.cross_inactive}
												alt="unchecked"
											/>
										)
									) : null}
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default DeviceRowMobile;
