import React, { useState, useEffect } from "react";
import { Marker, InfoWindow } from "react-google-maps";
import * as helper_functions from "../../helpers/helper_functions";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { devicesSlice } from "../../slice/deviceSlice";
import { locationHistorySlice } from "../../slice/locationHistorySlice";
import { useSelector, shallowEqual } from "react-redux";
import FlavorImages from "../../assetsFolderImport";

const DeviceNonStoppedMarker = React.memo((props) => {
	const deviceActions = devicesSlice.actions;
	const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);
  useEffect(() => {
    let intervalId;

    // Function to check if Google Maps is loaded
    const checkGoogleMapsLoaded = () => {
      if (window.google && window.google.maps && window.google.maps.Point) {
        setIsGoogleMapsLoaded(true);
        clearInterval(intervalId);
      }
    };

    intervalId = setInterval(checkGoogleMapsLoaded, 100);

    // Cleanup interval on unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const { devicesState } = useSelector(
		(state) => ({ devicesState: state.devices }),
		shallowEqual
	);
  const { locationHistoryState } = useSelector(
    (state) => ({ locationHistoryState: state.locationHistory }),
    shallowEqual
  );
  const locationHistoryActions = locationHistorySlice.actions;

    const openIW = (point) => {
      dispatch(locationHistoryActions.setlocationHistoryTime({ locationHistoryTime: new Date(point.datetime).getTime() }))
      dispatch(
        deviceActions.setSelectedLocationPointId({
        selectedLocationPointId: "",
      })
       )
    }

    const removeIW = () => {
      dispatch(
        locationHistoryActions.setlocationHistoryTime({
          locationHistoryTime: ""
      }))
    }

  // console.log(devicesState.selectedLocationPointId)
  if (!isGoogleMapsLoaded) {
    // If not loaded, you can return null or a loader
    return null;
  }

  return (
    <>
      <Marker
        onClick={() => props.hideMarker()}
        opacity= {0.8}
        icon={{
          url:
            FlavorImages.moving_point,
          scaledSize: { width: 8, height: 8 },
					anchor: new window.google.maps.Point(4, 4),
        }}
        position={{
          lat: parseFloat(props.point.latitude),
          lng: parseFloat(props.point.longitude),
        }}
      >
          <InfoWindow
          options={{pixelOffset: new window.google.maps.Size(1,-11), disableAutoPan: true}}
					>
            <div onClick={() => props.hideMarker()} className={`custom-tooltip-container cursor-pointer color4 mt-0`}>
              <div
                className="fade show tooltip cursor-pointer bs-tooltip-bottom"
                data-popper-reference-hidden="false"
                data-popper-escaped="false"
                data-popper-placement="bottom"
              >
                <div className="tooltip-arrow cursor-pointer mr-1 margin_top_mins_13" style={{
                  transform: "rotate(0deg)"
                }}></div>
                <div className="tooltip-inner cursor-pointer  custom_marker_styling">
                  <p className="text_17_700 cursor-pointer text-white m-0">  {Math.round((props.point.speed) * 10) / 10 + " " + t("LocationHistory.Km/h")}</p>
                  <span className="text_17_700 cursor-pointer text-white">  {helper_functions.default.formatDateTime(
                    props.point.datetime,
                    "MM/dd HH:mm"
                  )} </span>
                </div>
              </div>
            </div>
          </InfoWindow>
      </Marker>
    </>
  );
});

export default DeviceNonStoppedMarker;
