import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import circle_select from "../../assets/images/circle_select.svg";
// import circle_unselect from "../../assets/images/circle_unselect.svg";
// import circle_select_helkama from "../../assets/Helkama_images/circle.svg";
// import poly_select from "../../assets/images/poly_select.svg";
// import poly_unselect from "../../assets/images/poly_unselect.svg";
// import poly_select_helkama from "../../assets/Helkama_images/draw-polygon.svg";
import ChooseAllCheckBox from "../../components/CheckBoxes/ChooseAllCheckBox";
import ScrollMenuName from "../../components/ScrollBar/ScrollMenuName";
import SearchControl from "../../components/Search/SearchControl";
import { INDEX_URL, SITE_TITLE } from "../../helpers/constants";
import * as helper_functions from "../../helpers/helper_functions";
import { seo } from "../../helpers/seo";
import * as devicesService2 from "../../services/devices/devicesService2";
import { devicesSlice } from "../../slice/deviceSlice";
import DeviceRow from "../Device/DeviceRow";
import SortDevice from "./../Device/SortDevice";
import { useHistory } from "react-router-dom";
import { geofencesSlice } from "../../slice/geofencesSlice";
import * as genericService from "../../services/genericService";
import { useParams } from "react-router-dom";
// import close_gray from "../../assets/images/close_gray.svg";
import DeviceCheckList from "./DeviceCheckList"
import toast, { Toaster } from 'react-hot-toast';
import FlavorImages from "../../assetsFolderImport";

const geofencesActions = geofencesSlice.actions;
const CreateGeofence = (props) => {
  const [downArrowClick, setDownArrowClick] = useState(true);
  const { t } = useTranslation();
  const history = useHistory();
  const [searchKeys, addSearchKey] = useState([]);
  const [name, setName] = useState("");
  const [method, setMethod] = useState("");
  const deviceActions = devicesSlice.actions;
  const [deviceOk, setDeviceOk] = useState(true);
  const [showSortDialog, setShowSortDialog] = useState(false);
  const [dataOnceSet, setDataOnceSet] = useState(false);
  const [devices, setDevices] = useState([]);
  const [methodOk, setMethodOk] = useState(true);
  const [nameFieldError, setNameFieldError] = useState("");
  const [deviceFieldError, setDeviceFieldError] = useState("");
  const dispatch = useDispatch();
  let { id } = useParams();
  let width = window.screen.width;
  if (id) id = parseInt(id);

  const { geofencesState } = useSelector(
    (state) => ({ geofencesState: state.geofences }),
    shallowEqual
  );

  const openSortDialog = () => {
    setShowSortDialog(true);
  };
  const [newBtn, setBtn] = useState("Select");
  const { devicesState } = useSelector(
    (state) => ({ devicesState: state.devices }),
    shallowEqual
  );
  const [chooseAll, setChooseAll] = useState(false);
  const sortDevicesArray = () => {
    let deviceData = _.cloneDeep(devicesState.filteredDevices);
    deviceData.sort(helper_functions.default.sortFunctionWIthOrder(devicesState.sort, devicesState.sortOrder));
    dispatch(
      deviceActions.setUpFilteredDeviceData({
        devices: deviceData,
      })
    );
    setShowSortDialog(false);
  };
  const loadDevices = async () => {
    const response = await devicesService2.getDevicesCompactInfo();
    if (response.status === 200) {
      const devices = response.data.devices ?? [];
      setDevices(devices);
      dispatch(
        deviceActions.setUpDeviceData({
          devices: devices,
        })
      );
      dispatch(
        deviceActions.setUpFilteredDeviceData({
          devices: devices,
        })
      );
    }
  };

  const onPlace = () => {
    if (id)
      setDownArrowClick(!downArrowClick);
    else
      setDownArrowClick(!downArrowClick);
  }

  const setGeofenceData = async (id) => {
    const response = await genericService.get("geofence/info");
    if (response.status === 200) {
      const geofences = response.data ?? [];
      const geofence = geofences.find((geofence) => geofence.id === id);
      if (geofence) {
        const tempDevices = _.cloneDeep(devicesState.filteredDevices);
        tempDevices.map((device) => {
          if (geofence.deviceid.includes(device.id)) {
            device.isSelected = true;
          }
        });
        setDataOnceSet(true);
        dispatch(
          deviceActions.setUpFilteredDeviceData({
            devices: tempDevices,
          })
        );
        setName(geofence.name);
        dispatch(geofencesActions.setEditGeofence({ editgeofence: geofence }));
        const shape = geofence.type === "CIRCLE" ? "Circle" : "Poly";
        drawShape(shape);
      } else {
        history.push(INDEX_URL + "geofences");
      }
    } else {
      history.push(INDEX_URL + "geofences");
    }
  };
  useEffect(() => {
    if (width < 768) {
      drawShape("Poly");
    }
  }, []);

  useEffect(() => {
    if (id && devicesState.filteredDevices.length && !dataOnceSet) {
      setGeofenceData(id);
    }
    else if (width < 768) {
      drawShape("Poly");
    }
  }, [id, devicesState.filteredDevices]);

  useEffect(() => {
    return () => {
      dispatch(geofencesActions.setEditGeofence({ editgeofence: null }));
      dispatch(geofencesActions.setShapeMethod({ shapeMethod: "" }));
      dispatch(geofencesActions.setCircleData({}));
      dispatch(geofencesActions.setPolygonData({ path: [] }));
      setDataOnceSet(false);

      dispatch(
        deviceActions.setUpDeviceData({
          devices: [],
        })
      );
      dispatch(
        deviceActions.setUpFilteredDeviceData({
          devices: [],
        })
      );
    };
  }, []);

  const on_load = () => {
    loadDevices();

    seo({
      title: SITE_TITLE,
      metaDescription: "",
    });
  };

  useEffect(() => {
    on_load();

  }, []);

  const OnSearchRemove = (index) => {
    var array = [...searchKeys];
    if (index !== -1) {
      array.splice(index, 1);
      addSearchKey(array);
      filterDevices(array);
    }
  };

  const OnClearAll = () => {
    const results = _.cloneDeep(devicesState.devices);
    var array = [...searchKeys];
    array.splice(0, array.length);
    addSearchKey(array);
    results.sort(helper_functions.default.sortFunctionWIthOrder(devicesState.sort, devicesState.sortOrder));
    dispatch(
      deviceActions.setUpFilteredDeviceData({
        devices: results,
      })
    );
  };

  const addKey = (searchKey) => {
    var searchObject = { name: searchKey };
    var array = [...searchKeys];
    array.push(searchObject);
    addSearchKey(array);
    setDeviceOk(true);
    filterDevices(array);
  };

  const filterDevices = (array) => {
    if (!array.length) {
      const results = _.cloneDeep(devicesState.devices);
      results.sort(helper_functions.default.sortFunctionWIthOrder(devicesState.sort, devicesState.sortOrder));
      dispatch(
        deviceActions.setUpFilteredDeviceData({
          devices: results,
        })
      );
      return;
    }

    const items = [];
    const SearchedItems = [];
    array.map((arr) => items.push(arr.name));
    for (const item of items) {
      let temp = devices.filter((device) => {

        return device.name.toLowerCase().indexOf(item.toLowerCase()) > -1;
      });

      Array.prototype.push.apply(SearchedItems, temp);
    }
    for (const item of items) {
      let temp = devices.filter((device) => {
        if (device.groupname) {
          return device.groupname.toLowerCase().indexOf(item.toLowerCase()) > -1;
        }
      });
      Array.prototype.push.apply(SearchedItems, temp);
    }
    dispatch(
      deviceActions.setUpFilteredDeviceData({
        devices: SearchedItems,
      })
    );
  };

  const handleChange = (field, e) => {
    setNameFieldError("")
    setName(e.target.value);
  };

  const getDeviceIDs = () => {
    var deviceIds = [];

    devicesState.filteredDevices.map((device, index) => {
      if (device.isSelected === true) {
        deviceIds.push(device.id);
      }
    });

    return deviceIds;
  };


  const createGeofence = async () => {
    if (!name) {
      setNameFieldError("Field required");
      if (width < 768) {
        setDownArrowClick(true)
      }
    }
    if (!devicesState.filteredDevices.find((device) => device.isSelected)) {
      setDeviceFieldError("Field Required");
      if (width < 768) {
        setDownArrowClick(true)
      }
      setDeviceOk(false);
    }
    if (!method) {
      setMethodOk(false);
    }
    if (!geofencesState.shapeMethod) return false;
    var geofenceName = name;
    var type = geofencesState.shapeMethod === "Circle" ? "CIRCLE" : "POLYGON";
    var latitude = 0;
    var longitude = 0;
    var radius = 0;
    var points = [];
    if (type === "CIRCLE") {
      latitude = geofencesState.circle.latitude;
      longitude = geofencesState.circle.longitude;
      radius = parseInt(geofencesState.circle.radius);
    } else if (type === "POLYGON") {
      points = geofencesState.polygon;
    }
    var deviceId = getDeviceIDs();
    if (deviceId.length === 0) {
      setDeviceFieldError("Field Required");
    }
    if (
      geofenceName !== "" &&
      deviceId.length &&
      ((type === "CIRCLE" && latitude && longitude && radius) ||
        type === "POLYGON")
    ) {
      const data = {
        name: geofenceName,
        deviceid: deviceId,
        type: type,
        latitude: latitude,
        longitude: longitude,
        radius: radius,
        points: points,
      };

      let apiName = "geofence/";

      if (id) {
        data.geofenceid = id;
        apiName = apiName + "update";
      } else {
        apiName = apiName + "create";
      }

      const response = await genericService.post(apiName, data);
      if (response.status === 200){
        history.push(INDEX_URL + "geofence")
        // window.location.reload()
        toast.success(t("toast.Done"));
    } else {
        toast.error(t("toast.Error") + " " + response.data.message);
    }}
  };
  const setChooseAllDevices = (e) => {
    setDeviceOk(true)
    setDeviceFieldError("")
    setChooseAll(e)
  }
  const onKeyChange = (searchKey) => {
    setDeviceFieldError("")
    setDeviceOk(true);
    if (!searchKey) {
      var array = [...searchKeys];
      filterDevices(array);
    }
    else {
      var searchObject = { name: searchKey };
      var array = [...searchKeys];
      array.push(searchObject);
      filterDevices(array)
    }

  }
  const onDeviceClick = (index) => {
    var array = _.cloneDeep(devicesState.filteredDevices);
    setDeviceOk(true)
    setDeviceFieldError("")
    if (index !== -1) {
      var device = array[index];
      if (device.isSelected !== true) device.isSelected = true;
      else device.isSelected = false;
      array[index] = device;
      dispatch(
        deviceActions.setUpFilteredDeviceData({
          devices: array,
        })
      );
    }
  };
  const onClocse = () => {
    history.push(INDEX_URL + "geofence");
  };
  const onEdit = () => {
    id ? createGeofence() : onClocse();
  }
  const drawShape = (shapeMethod) => {
    setMethod(shapeMethod)
    setMethodOk(true)
    dispatch(geofencesActions.setShapeMethod({ shapeMethod }));
  };
  return (
    <>
      <React.Fragment>
        <div className={`w-100 ${!downArrowClick ? "d-flex" : "d-none"} p-4 mb-5 d-md-none bottom-fixed-left justify-content-around`}>
          <button onClick={() => onClocse()} className="grey_btn shadow-sm text_15_700 w-100 m-1 b">
            {t("Geofences.Cancel")}
          </button>
          <button
            onClick={() => createGeofence()}
            className="btn_theme bg_dark_helkama shadow-sm text_15_700 w-100 m-1 "
            id="next"
          >
            {t("Device.Save")}
          </button>
        </div>
        <div className="d-flex flex-column  ">
          <div className="d-flex align-items-center justify-content-between px-3 px-md-0 ">
            <label className="dialog_body_title text_22_700 d-none d-md-block text-left mt-4 mt-md-3">
              {id ? t("geofences.Edit") + ('\u00a0') + name + t("geofences.geofence") : t("Geofences.CreateGeofence")}
            </label>
            <label className="dialog_body_title text_22_700 d-md-none text-left mt-4 mt-md-3">
              {t("Geofences.CreateGeofence")}
            </label>
            <div className="row col-2 d-md-none justify-content-end">
              <img
                onClick={() => history.push(INDEX_URL + "geofence")}
                src={FlavorImages.close_gray}
                alt="..."
              />
            </div>
          </div>
          <div className={`height_mobile_470 ${downArrowClick ? "d-block" : "d-none"}`}>
            <div className={`d-flex mt-md-3 px-3 px-md-0 `}>
              <div className=" d-flex col-7 px-0 flex-column">
                <div className="d-flex">
                  <label className="geo_row_name_title ml-0">
                    {t("signup.Name")}
                  </label>
                  <div className="invalid-feedback ml-5 mt-0">{nameFieldError}</div>
                </div>

                <input
                  type="text"
                  className="form-control input_box w-100 p-3 creategeo_bg rounded"
                  style={{ height: "35px" }}
                  id="newName"
                  autoComplete="off"
                  value={name}
                  onChange={(e) => {
                    handleChange("Name", e);
                  }}
                />
              </div>

              <div className=" d-flex px-2 flex-column">
                <label className="geo_row_name_title ml-0">
                  {t("Geofences.Method")}
                </label>

                <div className={`d-flex ${methodOk ? "" : "border border-danger"}`}>
                  <div className={process.env.REACT_APP_FLAVOR === 'Bike' ?
                    (geofencesState.shapeMethod === "Poly" ? 'bg_dark_helkama  d-flex p-2' : 'bg_grey_helkama  d-flex p-2') :
                    ''
                  }
                    style={process.env.REACT_APP_FLAVOR === 'Bike' ? {
                      width: '35px',
                      height: '35px',
                      borderRadius: '8px'
                    } : {}}>
                    <img
                      src={
                        geofencesState.shapeMethod === "Poly"
                          ?  FlavorImages.poly_select
                          :  FlavorImages.poly_unselect
                      }
                      onClick={() => drawShape("Poly")}
                      className={`cursor-pointer ${process.env.REACT_APP_FLAVOR === 'Bike' ? geofencesState.shapeMethod === "Poly" ? 'white_icon' : '' : ""} `}
                      alt="..."
                    />
                  </div>

                  <div className={process.env.REACT_APP_FLAVOR === 'Bike' ?
                    (geofencesState.shapeMethod === "Circle" ? 'bg_dark_helkama  d-flex p-2 ml-2' : 'bg_grey_helkama  d-flex p-2 ml-2') :
                    'ml-2'
                  }
                    style={process.env.REACT_APP_FLAVOR === 'Bike' ? {
                      width: '35px',
                      height: '35px',
                      borderRadius: '8px'
                    } : {}}>
                    <img
                      src={
                        geofencesState.shapeMethod === "Circle"
                          ? FlavorImages.circle_select
                          : FlavorImages.circle_unselect
                      }
                      onClick={() => drawShape("Circle")}
                      className={`cursor-pointer ${process.env.REACT_APP_FLAVOR === 'Bike' ? geofencesState.shapeMethod === "Circle" ? 'white_icon' : '' : ""}`}
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex">
              <label className="geo_row_name_title d-md-none ml-0 margin_top_2_point_1 px-3 px-md-0">
                {t("customFile.Choosebike")}
              </label>
              <div
                className="invalid-feedback d-md-none d-block mt-1 margin_left_132"
              >
                {deviceFieldError}
              </div>
            </div>

            <label className="geo_row_name_title d-none d-md-block ml-0 mt-md-4">
              {t("customFile.Choosebike")}
            </label>
            <div className="control_bar d-none d-md-flex mt-md-1 ">
              <div className="accordion" id="accordionone">
                <SearchControl
                  onSortClick={() => openSortDialog()}
                  onChangeSearchKey={(e) => onKeyChange(e)}
                  onAddSearchKey={(e) => addKey(e)}
                />
                <SortDevice
                  newBtn={newBtn}
                  setBtn={setBtn}
                  sortDevicesArray={sortDevicesArray}
                  loadDevices={loadDevices}
                  show={showSortDialog}
                  onClose={() => setShowSortDialog(false)}
                />
              </div>
            </div>

            <div className="control_bar d-none d-md-flex mt-md-3  "
              style={{ marginLeft: '13px' }}
            >
              <ChooseAllCheckBox
                isChecked={chooseAll}
                devicesState={devicesState}
                select={"geofence"}
                onClick={(e) => setChooseAllDevices(e)}
              />
              {deviceFieldError === "" ? (
                <div className="search_capsule_bar row ">
                  <ScrollMenuName
                    menuItems={searchKeys}
                    onRemove={(e) => OnSearchRemove(e)}
                    OnClearAll={() => OnClearAll()}
                  />
                </div>
              ) : (
                <div
                  className="invalid-feedback bottom mt-0 "
                  style={{ marginLeft: "110px" }}
                >
                  {deviceFieldError}
                </div>
              )}
            </div>
            <div className={` ${!deviceOk && width > 768 ? "border border-danger borderOfDevicesListLiveStreamDevices" : ""}`}>
              <div className={` scroll_div_20_b  ml-n1 pr-0 d-none d-md-block mt-md-3 alarm_list_shadow p-0`}>
                {devicesState.filteredDevices.map((device, index) => {
                  return (
                    <DeviceRow
                      isSelection={true}
                      key={index}
                      index={index}
                      selected={chooseAll ? true : false}
                      device={device}
                      onClick={() => onDeviceClick(index)}
                      setChooseAll={setChooseAll}
                    />
                  );
                })}
              </div>
            </div>

            <div className="scroll_div_20 d-md-none mt-md-4 pb-5 mb-5 p-0" style={{ height: 'calc(100% - 195px)' }}>
              {devicesState.filteredDevices.map((device, index) => {
                return (
                  <DeviceCheckList
                    isSelection={true}
                    key={index}
                    index={index}
                    selected={chooseAll ? true : false}
                    device={device}
                    onClick={() => onDeviceClick(index)}
                    setChooseAll={setChooseAll}
                  />
                );
              })}
            </div>

            <div className="w-100 mt-3 d-none border_8 d-md-flex justify-content-end">
              <button onClick={() => onClocse()} className="grey_btn">
                {t("Geofences.Back")}
              </button>
              <button
                style={{ marginRight: '11px', backgroundColor: process.env.REACT_APP_FLAVOR === 'Bike' ? 'var(--dark_helkama)' : "" }}

                onClick={() => createGeofence()}
                className={`btn_theme bg_dark_helkama ml-3 ${process.env.REACT_APP_FLAVOR === 'Bike' ? '' : ''}`}
                id="next"
              > {id ? t("Geofences.editgeofecne") : t("Geofences.SaveGeofence")}
              </button>
            </div>
            <div className="w-100 d-flex p-4 bg-white d-md-none bottom-fixed-left justify-content-around">
              <button onClick={() => onEdit()} className="grey_btn text_15_700 w-100 m-1">
                {id ? t("geofences.Edit") : t("Geofences.Cancel")}
              </button>
              <button
                onClick={() => onPlace()}
                className="btn_theme bg_dark_helkama text_15_700 w-100 m-1 px-2"
                id="next"
              >
                {id ? t("Geofences.Place") : t("Geofences.Placegeofence")}
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default CreateGeofence;
