import React, { useState } from "react";
// import default_avatar from "../../assets/images/default_awatar.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AchievementView from './AchievementView';
import { useTranslation } from "react-i18next";
import * as genericService from "../../services/genericService";
// import cross_red from "../../assets/images/cross_red.svg";
import FlavorImages from "../../assetsFolderImport";

const FriendsCard = (props) => {
    const { t } = useTranslation();
    let myString = props.friend?.name;
    let [firstPartName, secondPartName] = myString.split(" ");
    const [dragable, setdragable] = useState(false)
    const [achievements, setAchievements] = useState([])
    const Toggle = async (id) => {
        let friendResponse = await genericService.post("social/friends/achievements", {
            friendsuserid: id
        });
        setAchievements(friendResponse.data.response)
    }
    return (
        <Accordion
            className={`accordion-no-styles  mb-0 mt-0 ${props.index % 2 == 0 ? "index_odd_friend" : "index_even_friends"}`} >
            <AccordionSummary
                onMouseEnter={() => Toggle(props.friend?.frienduserid)}
                className="p-0 " aria-controls="panel1a-content">

                <div
                    onCopy="false"
                    draggable="true"
                    onTouchMove={() => setdragable(!dragable)
                    }
                    onDragEndCapture={() => {
                        setdragable(!dragable)
                    }}
                    className={`d-flex justify-content-between align-items-center ${dragable ? "ml-n5" : ""}`}
                    style={{ width: "100%" }}
                >
                    <div
                        className="shadow-none d-flex border-0 rounded-0 pt-1 pb-2 px-4 ">
                        <img
                            className="mt-2 width_height_50  border_36 fit_cover_image"
                            src={props.friend?.image ? props.friend?.image : FlavorImages.default_avatar}
                            alt={"...No image"}
                        />
                        <label className={`text_16_700 mt-2 font_700_gloria ${secondPartName ? "" : 'd-flex align-items-center'}   ml-3`}>{firstPartName}<br /> <span className="text_13_300 font_300_gloria height_20 d-block">{secondPartName ? secondPartName : " "}</span></label>
                    </div>
                    <img
                        onClick={(e) => {
                            props.acceptCancelRequest('deletefriend', props.friend?.name)
                            props.setselectedId(props.friend?.frienduserid)
                            e.stopPropagation();
                        }}
                        role="button"
                        className={` ${dragable ? "" : "d-none"}`} s
                        src={FlavorImages.cross_red}
                        alt="..."
                        style={{ width: "25px", height: "19px" }}
                    />
                </div>
            </AccordionSummary>
            <AccordionDetails className={`p-0 pb-3 d-flex flex-column ${props.index % 2 == 0 ? "index_even_friends" : "index_odd_friend"}`}>
                <label className="text_22_700 mt-4 text-center font_700_italic_gloria shadow-none">{props.friend?.name} {t("Medal.Achievements")}</label>
                {achievements ?
                    <>
                        <div className="d-flex">
                            {achievements.map((achievement, index) => {
                                return (
                                    index < 4 &&
                                    <>
                                        {achievement.visible ? (
                                            <AchievementView
                                                achievement={achievement}
                                                setAchievementsPopup={(value) => props.setAchievementsPopup(value)}
                                                setAchievementsType={(leveltype, type, achievementdetail) => props.setAchievementsType(leveltype, type, achievementdetail)}
                                                index={index}
                                                visibility={achievement.visible}
                                                lastindex={achievements.length}
                                            />
                                        ) : (
                                            <AchievementView
                                                achievement={achievement}
                                                setAchievementsPopup={() => props.setAchievementsPopup(true)}
                                                index={index}
                                                visibility={achievement.visible}
                                                lastindex={achievements.length}
                                            />
                                        )}
                                    </>
                                );
                            })}
                        </div>
                        <div className="d-flex">
                            {achievements.map((achievement, index) => {
                                return (
                                    index >= 4 && index <= 8 &&
                                    <>
                                        {achievement.visible ? (
                                            <AchievementView
                                                achievement={achievement}
                                                setAchievementsPopup={() => props.setAchievementsPopup(true)}
                                                setAchievementsType={(leveltype, type, achievementdetail) => props.setAchievementsType(leveltype, type, achievementdetail)}
                                                index={index}
                                                visibility={achievement.visible}
                                                lastindex={achievements.length}
                                            />
                                        ) : (
                                            <AchievementView
                                                achievement={achievement}
                                                setAchievementsPopup={() => props.setAchievementsPopup(true)}
                                                index={index}
                                                visibility={achievement.visible}
                                                lastindex={achievements.length}
                                            />
                                        )}
                                    </>
                                );
                            })}
                        </div>
                    </>
                    : ""}
            </AccordionDetails>
        </Accordion>

    )

}
export default FriendsCard;
