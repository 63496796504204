import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import up_arrow_grey from "../../assets/images/up_arrow_grey.svg";
// import up_arrow_black from "../../assets/Helkama_images/up_arrow_black.svg";
import { SITE_TITLE } from "../../helpers/constants";
import { seo } from "../../helpers/seo";
import FlavorImages from "../../assetsFolderImport";

const SupportRow = (props) => {
  const [shrink, setShrink] = useState(true);
  const on_load = () => {
    seo({
      title: SITE_TITLE,
      metaDescription: "",
    });
  };

  const convertedText = () => {
		return {__html: props.question.body};
	};

  useEffect(() => {
    on_load();
  }, []);
  return (
    <>
      <>
        <div
          className={`p-2 pb-3 d-flex ${props.index == 0 ? "" : "mt-2"}
            shrink flex-column w-100 white_shadow rounded_white`}
          onClick={() => setShrink(!shrink)}
        >
          <div className="row">
            <div className="col-10 ml-4 mt-2">
              <div className="row">
                <label className=" text_17_700 pt-1 text-navy my-0">
                  {props.question.headline}{" "}
                </label>
              </div>
              <div className="row tostext">
              {/* <div dangerouslySetInnerHTML={convertedText()}/> */}
              <div className={`${shrink ? "d-none" : ""} text_13_400 pt-3 mr-3 text-justify`}>
                {props.question.body.split('\n').map((line, index) => (
                  <div key={index}>{line}<br/></div>
                ))}
              </div>
                {/* <label
                  className={`${shrink ? "text-truncate" : ""
                    } text_13_300 pt-1  mr-3 text-grey text-justify`}
                >
                  {props.question.body}{" "}
                </label> */}
              </div>
            </div>

            <div className="col-1 mt-2">
              <img src={process.env.REACT_APP_FLAVOR === 'helkama' ? FlavorImages.up_arrow_black : FlavorImages.up_arrow_grey} className={`size_13 ml-2 cursor-pointer 
                ${shrink
                  ? "rotate_btn_maximize"
                  : "rotate_btn_minmize"
                }`} alt="..." />
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default SupportRow;
