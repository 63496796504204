import React from "react";
// import close_gray from "../../assets/images/close_gray.svg";
import FlavorImages from "../../assetsFolderImport";
const SearchOptionButton = (props) => {
  const iconClick = async () => {
    props.onRemove();
  };
  return (
    <React.Fragment>
      <div
        className={`${props.id === 0  ? "ml-2" : ""} search_capsule `}
      >
        <label className="px-1" htmlFor="Email">
          {props.name}
        </label>
        <img
          src={FlavorImages.close_gray}
          className="add_button_icon pill_close ml-1 cursor-pointer"
          onClick={() => iconClick()}
          alt="..."
        />
      </div>
    </React.Fragment>
  );
};

export default SearchOptionButton;
