import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
// import close_gray from "../../assets/images/close_gray.svg";
import { useTranslation } from "react-i18next";
import * as genericService from '../../services/genericService';
import ErrorDialog from '../LocationHistory/ErrorDialog';
// import user_friends from "../../assets/Helkama_images/user-friends_white.svg";
import { useState } from "react";
import FlavorImages from "../../assetsFolderImport";
const AddFriend = (props) => {
    const { t } = useTranslation();
    const [emailField, setEmailField] = useState('')
    const [errorDialog, setShowErrorDialog] = useState(false)
    const [nameError, setnameError] = useState('')
    const addFriend = async () => {
        if (!emailField) setnameError('Field required')
        else {
        const response = await genericService.post("/social/friends/add", {
            email: emailField,
        });
        if (response.status == 200 || response.status == 201) {
            props.closeAddFrinds();
            props.getAccountInfo();
        }
        else {
            setShowErrorDialog(response.data.message)
        }
    }
    }
    return (
        <>
            <Dialog open={props.show} >
                <Card className="card width_365">
                    <div
                        className="d-flex justify-content-end mr-3 mt-4  "
                    >
                        <img
                            src={FlavorImages.close_gray}
                            className="cursor-pointer size_30"
                            onClick={() => props.closeAddFrinds()}
                            alt="..." s
                        />
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <div className="rcorners d-flex justify-content-center">
                                    <img src={FlavorImages.user_friends} alt="..." />
                                </div>
                            </div>
                            <div className="col-12 flex-column text-center">
                                <label className="mt-2 text_22_700 font_700_italic_gloria">{t("Medal.AddFriend")}</label>
                                <label className="mt-2 text-center  text_16_400 font_400_gloria">{t("Medal.Searchforafriendstext")}</label>
                                <input
                                    type="text"
                                    style={{ background: "#DDDDDD" }}
                                    value={emailField}
                                    onChange={(e) => setEmailField(e.target.value)}
                                    className={`form-control mt-3 ${nameError === "" ? "" : "is-invalid"
                                        }`}
                                    placeholder={t("login.Email")}
                                />
                                <div className="invalid-feedback  text-left ml-1 bottom">
                                    {nameError === "confirmerror" ? "" : nameError}
                                </div>
                            </div>
                            <div className="col-12 mt-4 d-flex ">
                                <div className="d-flex">
                                    <button
                                        className="btn size_40_150_add_frined margin_right_23 text_15_700 font_700_gloria text_primary  background_grey"
                                        onClick={() => {
                                            props.closeAddFrinds()
                                            setEmailField('')
                                        }}
                                        variant="light"
                                    >
                                        {" "}
                                        {t("actionDialog.cancel")}
                                    </button>
                                    <button
                                        className="btn size_40_150_add_frined text_15_700 font_700_gloria text-white  bg-dark"
                                        onClick={() => addFriend()}
                                        variant="light"
                                    >
                                        {" "}
                                        {t("Medal.AddFriend")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </Dialog>
            <ErrorDialog
                showErrorDialog={errorDialog}
                select={"Device"}
                errorMesage={errorDialog}
                onClose={() => setShowErrorDialog(false)}
            />
        </>
    )
}
export default AddFriend