import React, { useEffect, useState } from "react";
import SweetAlert from "sweetalert2-react";
let isMounted = false;
const Succespopup = (props) => {
  const [show, setState] = useState(true);
  let color = "#132439";
  useEffect(() => {
    isMounted = true;
    props.setSuccess("");
    return () => {
      isMounted = false;
    };
  }, []);
  return (
    <SweetAlert
      show={show}
      text={props.success}
      confirmButtonColor={color}
      onConfirm={() => isMounted && setState(false)}
    />
  );
};
export default Succespopup;
