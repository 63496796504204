import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import close_gray from "../../assets/images/close_gray.svg";
import { useSelector, shallowEqual } from "react-redux";
import * as devicesService2 from "../../services/devices/devicesService2";
import toast, { Toaster } from 'react-hot-toast';
import FlavorImages from "../../assetsFolderImport";


const EditBikeName = (props) => {
  const { t } = useTranslation();
  const { devicesState } = useSelector(
    (state) => ({ devicesState: state.devices }),
    shallowEqual
  );
  const [speedUnit,setSpeedUnit]=useState("")
  const [speedLimit, setSpeedLimit] = useState("");
  const [speedFieldError, setSpeedFieldError] = useState("");
  const [bikeDetail, setBikeDetail] = useState([]);
  const deviceDetail = () => {
    devicesState.devices.map((device) => {
      if (device.name === props.deviceName) {
        setBikeDetail(device);
      }
    });
  };
  const setNameField = (value) => {
    setSpeedLimit(value);
    setSpeedFieldError("");
  };
  useEffect(() => {
      deviceDetail();
      setSpeedUnit(props.speedLimit?.speedlimitunit)
      setSpeedLimit(props.speedLimit?.speedlimitinfo) 
  }, [props.speedLimit]);
  

  const updateDeviceName = async () => {
    if (speedLimit === "") {
      setSpeedFieldError("This field is required");
    } else {
      let editDevice
        if(typeof(parsedSpeed) !== 'string')
        editDevice = {
          deviceid: bikeDetail.id,
          speedlimit: parseInt(speedLimit),
        } 
      const response = await devicesService2.updateDevice(editDevice);
      if (response.status === 200) {
          props.setBikeSpeed(parseInt(speedLimit))
        props.closedEditbikespeedDialog();
        toast.success(t("toast.Saved"));
      } else {
        props.closedEditbikespeedDialog();
        props.setShowErrorDialog();
        props.setErrorMesage(response.data.message)
      }
    }
  };

  return (
    <>
      <Dialog open={props.show}>
        <Card className="card">
          <div className="text-center">
            <div
              className="dialog_top_sort_row py-2 card-header"
              style={{ backgroundColor: "white" }}
            >
              <label className="card-title text-grey text_17_700 ml-4">
                {" "}
                 { "Change Speed"}
              </label>
              <img
                className="cursor-pointer"
                src={FlavorImages.close_gray}
                onClick={() => props.closedEditbikespeedDialog()}
                alt="..."
              />
            </div>
            <div className="card-body py-3">
              <div className="row">
                <div className="col-12">
                  <div className="form-group col-12 mt-2">
                    <div className="d-flex">                    <input
                      type="text"
                      onChange={(e) => setNameField(e.target.value)}
                      style={{ background: "#DDDDDD" }}
                      value={speedLimit}
                      className={`form-control ${speedFieldError === "" ? "" : "is-invalid"
                        }`}
                    />
                    <label className={`text-12-400 ml-2 mt-2 text-grey`}>{`(${speedUnit})`}</label>
                    </div>
                    <div className="invalid-feedback bottom">{speedFieldError}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="m-0 card-footer  d-flex justify-content-end">
            <button
              className="btn_cancel_transparent"
              onClick={() => props.closedEditbikespeedDialog()}
              variant="light"
            >
              {t("actionDialog.cancel")}
            </button>

            <button
              className="btn btn_navy bg_dark_helkama text-uppercase ml-3"
              onClick={() => updateDeviceName()}
              id="next"
            >
              { "save speed"}
            </button>
          </div>
        </Card>
      </Dialog>
    </>
  );
};

export default EditBikeName;
