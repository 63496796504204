import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// import close_gray from "../../assets/images/close_gray.svg";
import * as genericService from "../../services/genericService";
// import white_corss from "../../assets/images/white_corss.svg";
import Succespopup from "../Device/SuccesPopup";
import ConfirmDelete from "./ConfirmDelete";
import ErrorDialog from "../LocationHistory/ErrorDialog";
import toast from 'react-hot-toast';
import FlavorImages from "../../assetsFolderImport";

const ShareRemoveEmails = (props) => {
  const { t } = useTranslation();
  const [input, setInput] = useState("");
  const [showActionDialog, setShowActionDialog] = useState(false);
  const [tags, setTags] = useState([]);
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const [disableShareBtn, setDisableShareBtn] = useState(true);
  const [errorDialog, setShowErrorDialog] = useState(false)
  const [emailFieldError, setEmailFieldError] = useState("");
  const [success, setSucces] = useState("")
  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
  };
  const confirmDeleteEmails = async () => {
    let selectedalarmid = [];
    if (props.sharedAlarm) {
      let result = props.sharedAlarm.map((alarm, index) => {
      //  if (alarm.isSelected === true) {
          selectedalarmid.push(alarm.deviceid);
      //  }
      });
    }
    const response = await genericService.post("events/share/delete", {
      deviceid: selectedalarmid,
      email: tags,
    });
    if (response.status === 200) {
      props.closeRemoveEmailDialog();
      props.loadAlarms()
      setTags([]);
      setDisableShareBtn(true);
      setShowActionDialog(false);
      toast.success(t("toast.Done"))
    } else {
      setShowErrorDialog(response.data.message)
    }
  };
  const shareRemoveEmail = async () => {
    if (props.shareRemoveAction === "shareDevice") {
      let alramIdArray = []
      props.sharedAlarm.forEach(alarm => {
        if(alarm.isSelected==true)
        {
          alramIdArray.push(alarm.deviceid)
        }
      });
      const response = await genericService.post("events/share/add", {
        deviceid: alramIdArray,
        email: tags,
      });
      if (response.status === 200) {
        props.closeRemoveEmailDialog();
        toast.success(t("toast.Share"))
        props.loadAlarms()
        setTags([]);
        setDisableShareBtn(true);
      } else {
        setTags([]);
        setShowErrorDialog(response.data.message)
        setDisableShareBtn(true);
      }
    } else {
      props.closeRemoveEmailDialog();
      setShowActionDialog(true);
    }
  };
  const closeTagAndDialog = () => {
    props.closeRemoveEmailDialog();
    setTags([]);
    setDisableShareBtn(true);
  };
  const onKeyDown = (e) => {
    const { key } = e;
    setEmailFieldError("");
    const trimmedInput = input.trim();
    if (key === "Enter"  || key === "," && trimmedInput.length && !tags.includes(trimmedInput)) {
      var re = /\S+@\S+\.\S+/;
      let result = re.test(trimmedInput);
      if (result === true) {
        e.preventDefault();
        setTags((prevState) => [...prevState, trimmedInput]);
        setDisableShareBtn(false);
        setInput("");
      } else {
        setEmailFieldError("Invalid Foramte");
      }
    }

    if (key === "Backspace" && !input.length && tags.length) {
      setEmailFieldError("");
      e.preventDefault();
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();
      setTags(tagsCopy);
      setInput(poppedTag);
    }
    setIsKeyReleased(false);
  };
  const deleteTag = (index) => {
    setTags((prevState) => prevState.filter((tag, i) => i !== index));
  };
  const onKeyUp = () => {
    setIsKeyReleased(true);
  };
  return (
    <>
      <Dialog maxWidth={"sm"} open={props.show}>
        <Card className="card">
          <div className="text-center">
            <div
              className="dialog_top_sort_row py-2 card-header"
              style={{ backgroundColor: "white" }}
            >
              <label className="text_17_700 ml-2 text-grey">
                {props.shareRemoveAction === "shareDevice"
                  ? t("Alarm.ShareDeviceAlarm")
                  : t("Alarm.Removeemial")
                }
              </label>
              <img
                src={FlavorImages.close_gray}
                onClick={() => props.closeRemoveEmailDialog()}
                alt="..."
              />
            </div>
            <div className="card-body py-3">
              <div className="row">
                <div className="col-12">
                  <div className=" col-12 mt-2">
                    <div className="form-group col-12 mt-2">
                      <label
                        className="text_12_500 text-grey"
                        style={{ fontSize: "small", maxWidth: "350px" }}
                        data-success="right"
                      >
                        {props.shareRemoveAction === "shareDevice"
                          ? t("Alarm.ShareWith")
                          : t("Alarm.DeleteShared")
                        }
                      </label>
                      <div className="containerTag py-1 input-mass-share inset_shadow">
                        {tags.map((tag, index) => (
                          <div className="inputTag m-1 px-2">
                            {tag}
                            <img
                              className="tagbutton ml-2"
                              src={FlavorImages.white_corss}
                              onClick={() => deleteTag(index)}
                              alt="..."
                            />
                          </div>
                        ))}
                        <input
                          className={`no-outline border-0 ml-1 width_300 ${emailFieldError === "" ? "" : "is-invalid"
                            }`}
                          value={input}
                          onKeyDown={onKeyDown}
                          onKeyUp={onKeyUp}
                          onChange={onChange}
                        />
                      </div>
                      <div className="no-outline invalid-feedback bottom justify-content-center mr-5">
                        {emailFieldError}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="m-0 card-footer  d-flex justify-content-end">
            <button
              className="btn bg-transparent py-1 px-2 text-grey text_12_700"
              onClick={() => closeTagAndDialog()}
              variant="light"
            >
              {t("actionDialog.cancel")}
            </button>
            {props.shareRemoveAction === "shareDevice" ? (
              <button
                className={`${disableShareBtn
                    ? "btn bg-grey2 text-grey py-1 px-2 text_12_700"
                    : "btn btn_navy py-1 px-2 text_12_700"
                  } ml-2`}
                onClick={() => shareRemoveEmail()}
                disabled={disableShareBtn}
              >
                {t("deviceMarker.Share")}
              </button>
            ) : (
              <button
                className={`${disableShareBtn ? "bg-grey2 " : "btn-danger text-white"
                  } btn py-1 px-2 text_12_700 ml-2`}
                onClick={() => shareRemoveEmail()}
                disabled={disableShareBtn}
              >
                {t("Alarm.StopShare")}
              </button>
            )}
          </div>
        </Card>
      </Dialog>
      {success ? <Succespopup setSuccess={setSucces} success={success} /> : ""}
      <ErrorDialog
        showErrorDialog={errorDialog}
        select={"Device"}
        errorMesage={errorDialog}
        onClose={() => setShowErrorDialog(false)}
      />
      <ConfirmDelete
        show={showActionDialog}
        closeConfirmDeleteDialog={() => setShowActionDialog(false)}
        onConfirm={() => confirmDeleteEmails()}
        select={"RemoveEmail"}
      />
    </>
  );
};

export default ShareRemoveEmails;
