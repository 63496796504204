import React, { useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import { mapSettingsSlice } from "../../slice/mapSettingsSlice";
// import Light_Map from "../../assets/images/Light_Map.svg";
// import Satellite_Map from "../../assets/images/Satellite_Map.svg";
// import Hybrid_Map from "../../assets/images/Hybrid_Map.svg";
// import groupSelected from "../../assets/Helkama_images/grip_veritcal_selected.svg";
// import groupUnselected from "../../assets/Helkama_images/grip-vertical_unselect.svg";
// import Terrain_Map from "../../assets/images/Terrain_Map.svg";
// import Dark_Map from "../../assets/images/Dark_Map.svg";
// import geo_fence_icon_helkama from "../../assets/Helkama_images/geofence.svg";
// import geo_fence_icon_helkama_select from "../../assets/Helkama_images/geofence_white.svg";
// import device_name from "../../assets/Helkama_images/device_name.svg";
// import device_name_white from "../../assets/Helkama_images/device_name_white.svg";
// import cluster_icons from "../../assets/Helkama_images/cluster_icons.svg";
// import cluster_icons_select from "../../assets/Helkama_images/cluster_icons_white.svg";
// import groups_select from "../../assets/images/groups_select.svg";
// import bell_helkama from "../../assets/Helkama_images/bell_bold.svg";
// import chart_line from "../../assets/Helkama_images/chart_line.svg";
// import wifi_dicsonnect from "../../assets/Helkama_images/wifi_disconnected.svg";
// import wifi_disconnected_white from "../../assets/Helkama_images/wifi_disconnected_white.svg";
// import chart_line_white from "../../assets/Helkama_images/chart_line_white.svg";
// import bell_helkama_inactive from "../../assets/Helkama_images/bell_bold_white.svg";
import * as genericService from "../../services/genericService";
import { devicesSlice } from "../../slice/deviceSlice";
import { alarmsSlice } from "../../slice/alarmsSlice";
import FlavorImages from "../../assetsFolderImport";
const alarmActions = alarmsSlice.actions;
const mapSettingsActions = mapSettingsSlice.actions;
const deviceActions = devicesSlice.actions;

const MapSettings = (props) => {
  const { t } = useTranslation();
  const [openedGroupId, setOpenedGroupId] = useState(null);
  const [firstClick, setFirstClick] = useState(false)
  const dispatch = useDispatch();
  const { mapSettingsState } = useSelector(
    (state) => ({ mapSettingsState: state.mapSettings }),
    shallowEqual
  );
  const setLimitedAlarm = async () => {
    alterSettings("show_alarms", !mapSettingsState.show_alarms)
    const limitedAlarmResponse = await genericService.get("/events/list/limitformap");
    if (limitedAlarmResponse.status === 200) {
      const alarms = limitedAlarmResponse.data;
      dispatch(
        alarmActions.setLimittedAlarm({
          limitedAlarm: alarms
        })
      );
    }
  }
  const { groupState } = useSelector(
    (state) => ({ groupState: state.groups }),
    shallowEqual
  );

  const { devicesState } = useSelector(
    (state) => ({ devicesState: state.devices }),
    shallowEqual
  );
  const setGroup = (group) => {
    if (group.id !== openedGroupId) {
      let tempDevices = [];
      for (let i = 0; i < group.deviceId.length; i++) {
        let filterIndex = devicesState.mapDevicesorignal.findIndex((dev) => dev.id === group.deviceId[i])
        tempDevices.push(devicesState.mapDevicesorignal[filterIndex]);
      }
      dispatch(
        deviceActions.setMapDevices({
          mapDevices: tempDevices,
        })
      );
    } else {
      dispatch(
        deviceActions.setMapDevices({
          mapDevices: [],
        })
      );
    }
    if (group.id === openedGroupId) {
      setOpenedGroupId(null);
    } else {
      setOpenedGroupId(group.id);
    }
  };

  const alterSettings = (name, value) => {
    const data = {};
    data[name] = value;
    dispatch(mapSettingsActions.setSetting(data));
  };
  const setGroupsRow = async () => {
    setFirstClick(true)
    setshowGroups(!showGroups)
    setOpenedGroupId(null)
    dispatch(
      deviceActions.setMapDevices({
        mapDevices: [],
      })
    );
    dispatch(
      deviceActions.setGroupRowSettings({
        showgroupssetting: !devicesState.showgroupssetting,
      })
    );
  }

  const { mapType } = useSelector(
    (state) => ({ mapType: state.mapSettings.Maptype }),
    shallowEqual
  );
  const [showGroups, setshowGroups] = useState(false)
  const setMapType = (name) => {
    dispatch(mapSettingsActions.setMapType(name))
  }
  return (
    <>
      <div className={`${devicesState.showgroupssetting && firstClick ? "slide_up_group_setting_btn d-md-flex" : firstClick ? "slide_down_group_setting_btn" : "invisible"}  group_map_setting   position-fixed`}>
        <div
          className={`d-flex flex-column flex_warp_group`}
        >
          {groupState.filteredGroups.map((group, index) => {
            return (
              <div
                key={index}
                onClick={() =>
                  setGroup(group)
                }
                className="map_settings_icons mb-3"
              >
                <img className={` ${openedGroupId === group.id ? "group_active group_active_helkam" : "map_settings_icons_img group_inactive"}`}
                  alt={t("mapOptions.Show groups")}
                  src={openedGroupId === group.id ? FlavorImages.groups_select : FlavorImages.groups_inactive
                  }
                />
                <span>{group.name}</span>
              </div>
            );
          })}
        </div>
      </div>

      <div className="map_settings d-none d-md-flex position-fixed" >
        <div
          className={`${mapSettingsState.show_settings ? `d-flex flex-column` : "d-none"}`} style={{ marginBottom: "-17px" }}
        >
          {process.env.REACT_APP_FLAVOR === "Bike"
            ?
            ""
            :
            <div
              onClick={() =>
                setGroupsRow()
              }
              className="map_settings_icons"
            >
              <span>{t("mapOptions.Show groups")}</span>

              <img className={` ${devicesState.showgroupssetting ? "group_active group_active_helkam" : "map_settings_icons_img group_inactive"}`}
                alt={t("mapOptions.Show groups")}
                src={devicesState.showgroupssetting ? FlavorImages.groups_select :
                  FlavorImages.groups_inactive
                }
              />
            </div>
          }
          <div
            onClick={() =>
              setLimitedAlarm()
            }
            className="map_settings_icons"
          >
            <span>{t("mapOptions.Show alarms")}</span>
            <img
              className={`map_settings_icons_img  ${mapSettingsState.show_alarms ? "group_active_helkam" : "map_settings_icons_img group_inactive"}`}
              alt={t("mapOptions.Show alarms")}
              src={mapSettingsState.show_alarms ? FlavorImages.bell_active_setting : FlavorImages.bell_inactive_setting}
            />
          </div>
          {process.env.REACT_APP_FLAVOR === "Alco"
            ?
            ""
            :
            <div
              onClick={() =>
                alterSettings("show_geofence", !mapSettingsState.show_geofence)
              }
              className="map_settings_icons"
            >
              <span>{t("mapOptions.Show geofence")}</span>

              <img
                className={`map_settings_icons_img ${mapSettingsState.show_geofence ? "group_active_helkam" : "map_settings_icons_img group_inactive"}`}
                alt={t("mapOptions.Show geofence")}
                src={mapSettingsState.show_geofence ? FlavorImages.show_geofence_setting_active : FlavorImages.show_geofence_setting_inactive}
              />
            </div>
          }

          {process.env.REACT_APP_FLAVOR === "Alco"
            ?
            ""
            :
            <div
              onClick={() =>
                alterSettings("trace_unit", !mapSettingsState.trace_unit)
              }
              className="map_settings_icons"
            >
              <span>{t("mapOptions.Trace unit")}</span>

              <img className={`map_settings_icons_img ${mapSettingsState.trace_unit ? "group_active_helkam" : "map_settings_icons_img group_inactive"}`}
                alt={t("mapOptions.Trace unit")}
                src={mapSettingsState.trace_unit ? FlavorImages.trace_unit_active : FlavorImages.trace_unit_inactive}
              />
            </div>
          }
          <div
            onClick={() =>
              alterSettings(
                "hide_offline_units",
                !mapSettingsState.hide_offline_units
              )
            }
            className="map_settings_icons"
          >
            <span>{t("mapOptions.Hide offline units")}</span>

            <img
              className={`map_settings_icons_img ${mapSettingsState.hide_offline_units ? "group_active_helkam" : "map_settings_icons_img group_inactive"}`}
              alt={t("mapOptions.Hide offline units")}
              src={mapSettingsState.hide_offline_units ? FlavorImages.offline_units_active : FlavorImages.offline_units_inactive}
            />
          </div>

          <div
            onClick={() =>
              alterSettings(
                "cluster_markers",
                !mapSettingsState.cluster_markers
              )
            }
            className="map_settings_icons"
          >
            <span>{t("mapOptions.Cluster markers")}</span>

            <img
              className={`map_settings_icons_img ${mapSettingsState.cluster_markers ? "group_active_helkam" : "map_settings_icons_img group_inactive"}`}
              alt={t("mapOptions.Cluster markers")}
              src={mapSettingsState.cluster_markers ? FlavorImages.cluster_active : FlavorImages.cluster_inactive}
            />
          </div>

          <div
            onClick={() =>
              alterSettings(
                "show_device_name",
                !mapSettingsState.show_device_name
              )
            }
            className="map_settings_icons"
          >
            <span>{t("mapOptions.Show device name")}</span>

            <img
              className={`map_settings_icons_img ${mapSettingsState.show_device_name ? "group_active_helkam" : "map_settings_icons_img group_inactive"}`}
              alt={t("mapOptions.Show device name")}
              src={mapSettingsState.show_device_name ? FlavorImages.active_device_name : FlavorImages.in_active_device_name}
            />
          </div>
        </div>
        <div
          className={`map_settings_icons m-0`}
        >

          <span onClick={() => alterSettings("show_settings", !mapSettingsState.show_settings)}>{t("mapOptions.Map settings")}</span>

          <div onClick={() => alterSettings("show_settings", !mapSettingsState.show_settings)}
            className={`m-0 p-1 map_setting_box`}
          >
            <div>
              <img className="map_settings_icons_img" alt={t(" Light")}
                src={mapType === "roadmap" ? FlavorImages.Light_Map : mapType === "satellite" ?
                  FlavorImages.Satellite_Map : mapType === "hybrid" ? FlavorImages.Hybrid_Map : mapType === "terrain" ? FlavorImages.Terrain_Map : mapType === 'DarkMode' ? FlavorImages.Dark_Map :
                    ""
                } />
              <img
                className="setting_icon"
                alt="Settings"
                src={
                  FlavorImages.cog
                }
              />
            </div>
          </div>
          <div
            className="map_settings inline-block position-fixed "
            style={{ marginBottom: "90px" }}
          >
            <div
              className={`${mapSettingsState.show_settings ? `d-flex` : "d-none"
                } flex-column `}
            >
              <div
                onClick={() => setMapType("roadmap")}
                className={`map_settings_icons m-0 `}
              >
                <div >
                  <span style={{ marginTop: `-10px` }}>{t("mapOptions.RoadMap")}</span>
                </div>
                <div
                  className={`m-0 p-1  ${mapType === "roadmap" ? "map_setting_box" : ""
                    }`}
                >
                  <img className="map_settings_icons_img" alt={t("Light")} src={FlavorImages.Light_Map} />
                </div>

              </div>

              <div
                onClick={() => { setMapType("DarkMode") }}
                className={`map_settings_icons m-0 `}
              >

                <div >
                  <span style={{ marginTop: `-10px` }}>{t("mapOptions.Dark Mode")}</span>
                </div>
                <div
                  className={`m-0 p-1  ${mapType === "DarkMode" ? "map_setting_box" : ""
                    }`}
                >
                  <img className="map_settings_icons_img" alt={t(" Light")} src={FlavorImages.Dark_Map} />
                </div>

              </div>

              <div
                onClick={() => setMapType("satellite")}
                className="map_settings_icons"
              >
                <span>{t("mapOptions.Satellite")}</span>
                <div
                  className={`m-0 p-1  ${mapType === "satellite" ? "map_setting_box" : ""
                    }`}
                >
                  <img
                    className="map_settings_icons_img"
                    alt={t("mapOptions.Show geofence")} src={FlavorImages.Satellite_Map} />
                </div>
              </div>

              <div
                onClick={() => setMapType("hybrid")}
                className="map_settings_icons"
              >
                <span>{t("mapOptions.Hybride")}</span>
                <div
                  className={`m-0 p-1  ${mapType === "hybrid" ? "map_setting_box" : ""
                    }`}
                >
                  <img
                    className="map_settings_icons_img"
                    alt={t("mapOptions.Trace unit")} src={FlavorImages.Hybrid_Map} />
                </div>
              </div>

              <div
                onClick={() => setMapType("terrain")}
                className="map_settings_icons"
              >
                <span>{t("mapOptions.Terrain")}</span>
                <div
                  className={`m-0 p-1  ${mapType === "terrain" ? "map_setting_box" : ""
                    }`}
                >
                  <img
                    className="map_settings_icons_img"
                    alt={t("mapOptions.Hide offline units")} src={FlavorImages.Terrain_Map} />
                </div>
              </div>
            </div>
          </div>
          <div >
          </div>
        </div>
      </div>
    </>
  );
};

export default MapSettings;
