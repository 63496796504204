import { useEffect, useState } from "react";
// import forward_arrow_grey from "../../assets/images/forward_arrow_grey.svg";
import SwitchRow from "./SwitchRow";
import DoubleSwitchRow from "./DoubleSwitchRow";

import { useTranslation } from "react-i18next";

import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { settingsSlice } from "../../slice/settingsSlice";
import BackWardArrow from "../../components/Buttons/BackwardArrow";
const settingsActions = settingsSlice.actions;

const GeneralNotifications = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [activateForAll, setActivateForAll] = useState(false);
  const [online, setOnLine] = useState(false);
  const [offline, setOffLine] = useState(false);
  return (
    <>
      <div
        className={`${props.showUI === true ? "show_ui" : "hide_ui"
          } col-md-8 d-flex  mt-3 flex-column  inner`}
      >
        <BackWardArrow show={false}       //if passed false, it will be visible on below md devices, if passed true it will be visible irrespectively
          onClick={() => {
            props.setSelect_null()
            dispatch(settingsActions.setPage({ page: "" }));
          }}
        />
        <div className="col-12 mt-3 scroll_div_6 d-flex flex-column pb-2">
          {/* <label className="text-uppercase text_11_300 text-left mt-4">
            {t("Settings.NotificationSound")}
          </label>
          <div
            className="p-3  d-flex bg-white w-100 rounded_white justify-content-between align-items-center"
            onClick={() => props.setSelect("SoundNotification")}
          >
            <label className=" text_16_400 text-navy my-0">
              {t("Settings.Sound")}
            </label>

            <div>
              <label className=" text_12_500 text-navy my-0">
                {props.alarmName}
              </label>

              <img
                src={forward_arrow_grey}
                className="size_15 ml-4"
                alt="..."
              />
            </div>
          </div> */}
          <label className="text-uppercase text_11_300 text-left mt-4">
            {t("Settings.NotificationSettings")}
          </label>
          <SwitchRow
            title={"Activate all notifications"}
            switch={activateForAll}
            SwitchClick={(e) => {
              setActivateForAll(e);
              setOnLine(e);
              setOffLine(e);
            }}
          />

          <DoubleSwitchRow
            title1={"Friend Request"}
            title2={"Challenge request"}
            switch1={online}
            switch2={offline}
            SwitchOneClick={(e) => setOnLine(e)}
            SwitchTwoClick={(e) => setOffLine(e)}
          />
        </div>
      </div>
    </>
  );
};
export default GeneralNotifications;
