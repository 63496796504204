/*
 * @Author: Abhi Bhatt
 * @Date:   2020-12-23 14:12:09
 * @Last Modified by:   abhi
 * @Last Modified time: 2021-04-14 12:41:01
 */
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import * as devicesService2 from "../../services/devices/devicesService2";
import DeviceSelectionRow from "./DeviceSelectionRow";
import _ from "lodash";
// import spinner from "../../assets/lotties/spinner.json";
import lottie from "lottie-web/build/player/lottie_light";
import ErrorDialog from "../LocationHistory/ErrorDialog";
import toast, { Toaster } from 'react-hot-toast';
import FlavorLottie from "../../lottiesFilesImport";

const GroupDetailRow = (props) => {
  const { t } = useTranslation();
  const [devices, setDevices] = useState([]);
  const [changing, setChanging] = useState(false)
  const [allSelected, setAllSelected] = useState(false);
  const [errorDialog, setShowErrorDialog] = useState(false)
  const [groupNameError, setGroupNameError]=useState("")
  const [updating, setUpdating] = useState(false);
  const [deleteMode, setDeleteMode] = useState("None");
  const anime = useRef(null);
  const animationContainer = useRef(null);
  const [groupName, setGroupName] = useState(props.group?.name)
  const loadDevices = async () => {
    var groupDevices = [];
    // console.log(props)
    var deviceList = _.cloneDeep(props.devices);
    // console.log(deviceList)
    deviceList.map((tempDevice, index) => {
      if (
        tempDevice.groupid === undefined ||
        tempDevice.groupid === null ||
        tempDevice.groupid === ""
      ) {
        groupDevices.push(tempDevice);
      } else if (
        props.mode === "Edit" &&
        props.group.deviceId.indexOf(tempDevice.id) > -1
      ) {
        tempDevice.isSelected = true;
        groupDevices.push(tempDevice);
      }
    });
    
    if(!changing){
        setDevices(groupDevices);
    }
  };

  const handleChange = (field, e) => {
    setGroupNameError("")
    setGroupName(e.target.value)
  };

  const onDeviceClick = (index) => {
    setChanging(true)
    var array = [...devices];
    if (index !== -1) {
      var device = array[index];
      if (device.isSelected !== true) device.isSelected = true;
      else device.isSelected = false;
      array[index] = device;
      setDevices(array);
    }
  };
  const on_load = () => {
    if(groupName === props.group?.name){
      setGroupName(props.group?.name)
    }
    loadDevices();
  };

  useEffect(() => {
    on_load();
 
    if (animationContainer.current) {
      anime.current = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: FlavorLottie.spinner,
      });
    }
  }, [props]);


  const getDeviceIDs = () => {
    var deviceIds = [];
    devices.map((device, index) => {
      console.log(device.isSelected);
      if (device.isSelected === true) {
        deviceIds.push(device.id);
      }else if(allSelected){
        deviceIds.push(device.id);
      }
    });
    return deviceIds;
  };


  const CreateGroup = async () => {
    if (!groupName) {
      setGroupNameError("Group name field empty")
    }
    else 
    if (getDeviceIDs().length > 0) {
      const data = { name: groupName, deviceid: getDeviceIDs() };
      // setUpdating(true);
      const response = await devicesService2.createGroup(data);
      if (response.status === 200 && response.data.successful) {
        // setUpdating(false);
        props.onAddGroup();
        toast.success(t("toast.Saved"));
      }
    }
    else if(getDeviceIDs().length == 0)
    {
      setShowErrorDialog(t("errors.selectAtleastOneDevice")) 
    }
  };

  const EditGroup = async () => {
    if (getDeviceIDs().length > 0) {
      const data = {
        groupid: props.group.id,
        name: groupName,
        deviceid: getDeviceIDs(),
      };
      // setUpdating(true);
      const response = await devicesService2.updateGroup(data);
      if (response.status === 200 && response.data.successful) {
        // setUpdating(false);
        setChanging(false)
        props.onAddGroup();
        setAllSelected(false)
        toast.success(t("toast.Saved"));
      }
    }
    else if(getDeviceIDs().length == 0 )
    {
      setShowErrorDialog(t("errors.emptyDevicesGroupError")) 
    }
  };
  const DeleteGroup = async () => {
    const data = { groupid: props.group.id };
    const response = await devicesService2.deleteGroup(data);
    if (response.status === 200 && response.data.successful) {
      props.onAddGroup();
      setDeleteMode("None");
    }
  };

  const onCancel = () => {
    setDeleteMode("None");
  };

  const createButtons = () => {
    return (
      <div
        className={`d-flex  mt-1 align-items-start justify-content-center mt-4 w-100`}
      >
        <button
          className="btn btn_grey text-uppercase height_30 text_11_700"
          onClick={() => props.onCancel()}
        >
          {t("Device.Cancel")}
        </button>
        <div
          type="submit"
          className={` ${updating === true ? "" : "d-none"
            } btn  btn_navy height_30 text_11_700 text-uppercase ml-3`}
          ref={animationContainer}
        />

        {updating === false ? (
          <button
            type="submit"
            className="btn btn_navy bg_dark_helkama height_30 text_11_700 text-uppercase ml-3"
            onClick={() => CreateGroup()}
            id="loginSubmit"
          >
            {t("Device.CreateGroup")}
          </button>
        ) : null}
      </div>
    );
  };

  const editButtons = () => {
    return (
      <div className="d-flex mt-1 align-items-start justify-content-center mt-4 w-100  ">
        <button
          type="submit"
          className="btn btn_red height_30 text_11_700 text-uppercase  box_shadow_1"
          onClick={() => setDeleteMode("Group")}
          id="loginSubmit"
        >
          {t("Device.RemoveGroup")}
        </button>

        <div
          type="submit"
          className={` ${updating === true ? "" : "d-none"
            } btn  btn_navy height_30 text_11_700 text-uppercase ml-3`}
          ref={animationContainer}
        />

        {updating === false ? (
          <button
            type="submit"
            className="btn btn_navy bg_dark_helkama height_30 text_11_700 text-uppercase ml-3"
            onClick={() => EditGroup()}
          >
            {t("Device.SaveEdits")}
          </button>
        ) : null}
      </div>
    );
  };

  const renderConfirmDelete = () => {
    return (
      <div className="device_row_delete_confirm p-5 ">
        <label className="text_22_700 text-center text-white ml-0 ">
          {t("Device.RemoveGroupMsg")}
        </label>

        <div className="d-flex mt-1 justify-content-center  mt-3 w-100 ">
          <button
            className="btn btn_grey text-uppercase height_30 text_11_700"
            onClick={() => onCancel()}
          >
            {t("Device.Cancel")}
          </button>
          <button
            type="submit"
            className="btn btn_red text-uppercase ml-3 height_30 text_11_700"
            onClick={() => DeleteGroup()}
            id="loginSubmit"
          >
            {t("Device.RemoveGroup")}
          </button>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className={`pb-0 px-0 alarm_list_shadow`}>
        <div className={`device_row_select_group p-20 w-100`}>
          <div
            className={
              props.hideDetail
                ? "device_row_select_bottom_hide px-0"
                : "device_row_select_bottom group_rounding px-0 "
            }
            style={{ backgroundColor: "#F2F2F2" }}
          >
            <div className="d-flex flex-column align-items-center  mt-2 py-2 ">
              <div className="d-flex  flex-column align-items-start group_detail  ">
                <label
                  className="device_row_left_active_label ml-0 "
                  style={{ fontSize: "12px" }}
                >
                  {t("Device.Name")}
                </label>

                <div className="d-flex mt-1 align-items-start w-100">
                  <input
                    className="form-control edit_device_label inset_shadow"
                    onChange={(e) => handleChange("Name", e)}
                    value={groupName}
                  />
                 
                </div>
                <div className="d-flex pb-2 align-items-start w-100">
                <div className="invalid-feedback bottom">
                    {groupNameError === "confirmerror" ? "" : groupNameError}
                  </div>
                  </div>
                <label
                  className="device_row_left_active_label mt-3 ml-0 "
                  style={{ fontSize: "12px" }}
                >
                  {t("Device.DevicesInGroup")}
                </label>

                <div className="grey_rounded_layout w-100 rounded mt-3 pl-0 group_scroll">
                  <DeviceSelectionRow
                    text={t("Device.ChooseAllDevices")}
                    hideLine={false}
                    isSelected={allSelected}
                    onClick={() => setAllSelected(!allSelected)}
                  />
                  {devices.map((device, index) => {
                    return (
                      <DeviceSelectionRow
                        text={device.name}
                        key={index}
                        isSelected={
                          allSelected || device.isSelected ? true : false
                        }
                        onClick={() => onDeviceClick(index)}
                        hideLine={devices.length === index + 1 ? true : false}
                      />
                    );
                  })}
                </div>

                {props.mode === "Create" ? createButtons() : editButtons()}
              </div>
            </div>
            {deleteMode !== "None" ? renderConfirmDelete() : null}
          </div>
        </div>
      </div>
      <ErrorDialog
                    showErrorDialog={errorDialog}
                    select={"Device"}
                    errorMesage={errorDialog}
                    onClose={() => setShowErrorDialog(false)}
                  />
    </React.Fragment>
  );
};

export default GroupDetailRow;
