import { shallowEqual, useSelector } from "react-redux";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
// import group_setting from "../../assets/images/group_setting.svg";
// import group_setting_active from "../../assets/images/group_setting_active.svg";
// import addGroupinctive from "../../assets/images/addGroupinctive.svg";
// import addGroupactive from "../../assets/images/addGroupactive.svg";
import { devicesSlice } from "../../slice/deviceSlice";
import { groupSlice } from "../../slice/groupSlice";
import _ from "lodash";
import { useState } from "react";
import FlavorImages from "../../assetsFolderImport";
const deviceActions = devicesSlice.actions;
const groupActions = groupSlice.actions;
const GroupSelector = (props) => {
	const { devicesState } = useSelector(
		(state) => ({ devicesState: state.devices }),
		shallowEqual
	);
	const dispatch = useDispatch();
	const [selectedGroup, setSelectedGroup] = useState("none")
	const { t } = useTranslation();
	const addNewGroup = () => {
		props.setEdit_AddGroup("addGroup")
		props.showEditGroup()
		setSelectedGroup("addGroup")
		dispatch(
			groupActions.setUpSelectdGroupDevices({
				selectedGroupDevices: devicesState.devices,
			})
		);
	}
	const load_group_Devices = (group) => {
		var groupDevices = [];
		var deviceList = _.cloneDeep(devicesState.devices);
		deviceList.map((tempDevice, index) => {
			if (
				tempDevice.groupid === undefined ||
				tempDevice.groupid === null ||
				tempDevice.groupid === ""
			) {
				tempDevice.isSelected = false;
				groupDevices.push(tempDevice);
			}
			else if (
				group.deviceId.indexOf(tempDevice.id) > -1
			) {
				tempDevice.isSelected = true;
				groupDevices.push(tempDevice);
			}
		});
		dispatch(
			groupActions.setUpSelectdGroupDevices({
				selectedGroupDevices: groupDevices,
			})
		);
	};
	const setGroupData = (group) => {
		load_group_Devices(group)
		props.setEdit_AddGroup("editGroup")
		props.showEditGroup()
		dispatch(
			groupActions.setUpSlectedGroup({
				selectedGroup: group,
			})
		);
		dispatch(
			groupActions.setGroupName({
				groupName: group.name,
			})
		);
	}
	const restDevices = () => {
		props.setDeviceLabelMobile(t("Device.labelMobile"))
		setSelectedGroup("none")
		dispatch(
			deviceActions.setUpFilteredDeviceData({
				devices: devicesState.devices,
			})
		);
	}
	const setGroupDevices = (group) => {
		let groupFilterDevices = []
		setSelectedGroup(group.id)
		props.setDeviceLabelMobile(group.name)
		devicesState.devices.map((device, index) => {
			if (group.deviceId.includes(device.id)) {
				groupFilterDevices.push(device)
			}
		})
		if (groupFilterDevices.length) {
			dispatch(
				deviceActions.setUpFilteredDeviceData({
					devices: groupFilterDevices,
				})
			);
		}

	}
	return (
		<>
			<div
				id="collapsemobile"
				className="collapse mt-1"
				style={{ width: "300px" }}
				data-parent="#accordianMobile"
			>
				<div className="row d-flex flex-column">
					<div className={`${selectedGroup === "none" ? "mobile_Group_Device_Tag_active" : "mobile_Group_Device_Tag_inactive"}`}>
						<p
							onClick={() => restDevices()}
							className={` ${selectedGroup === "none" ? "text_group_active" : "text_group_inactive"} `}  >{t("Device.devices")}</p>
					</div>
					{props.groups.map((group, index) => {
						return (
							<div
								key={group.id}
								className={`${selectedGroup === group.id ? "mobile_Group_Device_Tag_active" : "mobile_Group_Device_Tag_inactive"} d-flex`}>
								<p className={` ${selectedGroup === group.id ? "text_group_active" : "text_group_inactive"}`}
									onClick={() => setGroupDevices(group)}
									style={{
									}} >{group.name}</p>
								<img
									className="signal_icon p-1 mt-1 d-md-none d-lg-none d-sm-flex"
									onClick={() => setGroupData(group)}
									src={`${selectedGroup === group.id ? FlavorImages.group_setting_active : FlavorImages.group_setting}`}
									alt="..."
								/>
							</div>
						);
					})}
					<div className={`${selectedGroup === "addGroup" ? "mobile_Group_Device_Tag_active" : "mobile_Group_Device_Tag_inactive"} d-flex`}>
						<img
							className="signal_icon p-1  mr-1 mt-1 d-md-none d-lg-none d-sm-flex"
							src={`${selectedGroup === "addGroup" ? FlavorImages.addGroupactive : FlavorImages.addGroupinctive}`}
							alt="..."
						/>
						<p className={` ${selectedGroup === "addGroup" ? "text_group_active" : "text_group_inactive"}`}
							onClick={() => addNewGroup()}
							data-toggle="modal" data-target="#exampleModalCenter"
							style={{
							}} >{t("GroupSelector.addgroup")}</p>
					</div>
				</div>
			</div>

		</>
	);
};
export default GroupSelector;
