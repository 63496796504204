import React, { useState } from "react";
import { Marker, InfoWindow } from "react-google-maps";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as helper_functions from "../../helpers/helper_functions";
// import clock from "../../assets/images/clock.svg";
import { alarmsSlice } from "../../slice/alarmsSlice";
import FlavorImages from './../../assetsFolderImport';


import * as appExport from "../../App"
import Geocode from "react-geocode";

let flavor = process.env.REACT_APP_FLAVOR; //when we have APIs for helkama; remove static value and use flavor from env

const AlarmMarker = (props) => {
  const getAlarmIcon = () => {
    let icon = ""
    if (props.device.type === "deviceMoving") icon = FlavorImages.device_moving
    else if (props.device.type === "deviceStopped") icon = FlavorImages.device_stopped
    else if (props.device.type === "deviceUnknown") icon = FlavorImages.device_unknown
    else if (props.device.type === "vibration") icon = FlavorImages.device_vibration
    else if (props.device.type === "internalError") icon = FlavorImages.device_internal_error
    else if (props.device.type === "deviceOnline") icon = FlavorImages.online
    else if (props.device.type === "deviceOffline") icon = FlavorImages.offline
    else if (props.device.type === "geofenceExit") icon = FlavorImages.geofence_exit
    else if (props.device.type === "geofenceEnter") icon = FlavorImages.geofence_enter
    else if (props.device.type === "tampering") icon = FlavorImages.device_tamper
    else if (props.device.type === "lowBattery") icon = FlavorImages.low_battery
    else if (props.device.type === "lowExternalBattery") icon = FlavorImages.low_external_battery
    else if (props.device.type === "ignitionOn") icon = FlavorImages.ignition_on
    else if (props.device.type === "ignitionOff") icon = FlavorImages.ignition_off
    else if (props.device.type === "powerCut") icon = FlavorImages.power_cut
    else if (props.device.type === "sos") icon = FlavorImages.device_sos
    else if (props.device.type === "deviceOverspeed") icon = FlavorImages.speed_alarm
    else if (props.device.type === "bypass") icon = FlavorImages.bypass
    return icon
  }

  const getHelkamaAlarmIcon = () => {
    let icon = ""
    if (props.device.type === "deviceMoving") icon = FlavorImages.bike_moving
    else if (props.device.type === "deviceStopped") icon = FlavorImages.bike_stopped
    else if (props.device.type === "deviceOnline") icon = FlavorImages.online
    else if (props.device.type === "deviceOffline") icon = FlavorImages.offline
    else if (props.device.type === "geofenceExit") icon = FlavorImages.geofence_exit
    else if (props.device.type === "geofenceEnter") icon = FlavorImages.geofence_enter
    else if (props.device.type === "tampering") icon = FlavorImages.device_tamper
    else if (props.device.type === "lowBattery") icon = FlavorImages.low_battery
    else if (props.device.type === "lowExternalBattery") icon = FlavorImages.low_external_battery
    else if (props.device.type === "ignitionOn") icon = FlavorImages.ignition_on
    else if (props.device.type === "ignitionOff") icon = FlavorImages.ignition_off
    else if (props.device.type === "vibration") icon = FlavorImages.device_vibration
    else if (props.device.type === "powerCut") icon = FlavorImages.power_cut
    else if (props.device.type === "sos") icon = FlavorImages.device_sos
    else if (props.device.type === "deviceOverspeed") icon = FlavorImages.speed_alarm
    else if (props.device.type === "deviceUnknown") icon = FlavorImages.device_unknown
    return icon
  }

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { alarmState } = useSelector(
    (state) => ({ alarmState: state.alarms }),
    shallowEqual
  );
  const [address, setAddress] = useState("")
  const getLocationAddress = (latitude, longitude) => {
    const found = appExport.globaleStr.find(prevousAdress => prevousAdress.latitude == latitude || prevousAdress.longitude == longitude)
    if (found) {
      setAddress(found.address)
    }
    else {
      var addressString = ""
      let route, street_number, state, country;
      Geocode.fromLatLng(latitude, longitude).then(
        (response) => {
          const address = response.results[0].formatted_address;
          for (let i = 0; i < response.results[0].address_components.length; i++) {
            for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
              switch (response.results[0].address_components[i].types[j]) {
                case "street_number":
                  if (response.results[0].address_components[i].long_name) {
                    street_number = response.results[0].address_components[i].long_name;
                  }
                case "route":
                  if (response.results[0].address_components[i].long_name) {
                    route = response.results[0].address_components[i].long_name;
                  }
                case "administrative_area_level_1":
                  if (response.results[0].address_components[i].long_name) {
                    state = response.results[0].address_components[i].long_name;
                  }
                case "country":
                  if (response.results[0].address_components[i].long_name) {
                    country = response.results[0].address_components[i].long_name;
                  }
              }
            }
          }
          addressString = route + "," + street_number + "," + state + "," + country
          // console.log(" addres for calcultaed  in google api", "city",street_number, "and state", state, "and country", country,"and ", addressString);
          appExport.globaleStr.push({
            latitude: latitude,
            longitude: longitude,
            address: addressString
          })
          setAddress(addressString)
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  const alarmActions = alarmsSlice.actions;
  const setAlarmMarkerid = (deviceid) => {
    if (alarmState.selectedAlarmMarkerid === deviceid) {
      dispatch(
        alarmActions.setAlarmMarkerid({
          selectedAlarmMarkerid: null,
        })
      );
    } else {
      dispatch(
        alarmActions.setAlarmMarkerid({
          selectedAlarmMarkerid: deviceid,
        })
      );
    }
  };
  const getAlarmName = () => {
    if (props.device.type === "deviceMoving") return t("Alarm.DeviceMoving");
    else if (props.device.type === "deviceStopped")
      return t("Alarm.DeviceStopped");
    else if (props.device.type === "deviceOnline")
      return t("Alarm.deviceOnline");
    else if (props.device.type === "deviceOffline")
      return t("Alarm.deviceOffline");
    else if (props.device.type === "geofenceExit")
      return t("Alarm.GeofenceExit");
    else if (props.device.type === "geofenceEnter")
      return t("Alarm.GeofenceEnter");
    else if (props.device.type === "tampering") return t("Alarm.Tampering");
    else if (props.device.type === "lowBattery") return t("Alarm.LowBattery");
    else if (props.device.type === "lowExternalBattery")
      return t("Alarm.LowExternalBattery");
    else if (props.device.type === "ignitionOn") return t("Alarm.IgnitionOn");
    else if (props.device.type === "ignitionOff") return process.env.REACT_APP_FLAVOR === "Alco"
      ? t("Alarm.PositiveBlow")
      : t("Alarm.IgnitionOff")
    else if (props.device.type === "vibration") return t("Alarm.Vibration");
    else if (props.device.type === "powerCut") return t("Alarm.PowerCut");
    else if (props.device.type === "sos") return t("Alarm.sos");
    else if (props.device.type === "deviceOverspeed")
      return t("Alarm.DeviceOverspeed");
    else if (props.device.type === "deviceUnknown")
      return t("Alarm.DeviceUnknown");
    else if (props.device.type === "bypass")
      return t("Alarm.Bypass");
    else
      return -1;
  };
  const [positionLinkCopied, setPositionLinkCopied] = useState(false);
  const copyPositionLink = (device) => {
    var copyText =
      "http://www.google.com/maps/place/" +
      device.latitude +
      "," +
      device.longitude;
    helper_functions.default.copyToClipboard(copyText, setPositionLinkCopied);
  };
  return (
    <>
      <Marker
        onClick={() => setAlarmMarkerid(props.device.eventid)}
        icon={{
          url: process.env.REACT_APP_FLAVOR === "Bike" ? getHelkamaAlarmIcon() : getAlarmIcon(),
          scaledSize: { width: 30, height: 30 },
        }}
        position={{
          lat: parseFloat(props.device.latitude),
          lng: parseFloat(props.device.longitude),
        }}
      >
        {props.device.eventid === alarmState.selectedAlarmMarkerid ? (
          <InfoWindow
            zIndex={9999}
          >
            <div
              className={`custom-popover-container  popover fade show bs-popover-bottom marginTopAlarmInfoWindow`}
            >
              <div className="arrow"></div>
              <div className="popover-body ">
                <div className="row m-0">
                  <div className="header_div d-flex col-12 pt-2 pr-0 my-1 justify-content-between">
                    <div className="row">
                      <p className="text-navy font-bold text-22-700 pt-1 ml-2 pl-1 break_line">
                        {props.device.devicenameorfrom}
                      </p>
                    </div>
                    <img
                      onClick={() =>
                        dispatch(
                          alarmActions.setAlarmMarkerid({
                            selectedAlarmMarkerid: null,
                          })
                        )
                      }
                      className="cursor-pointer size_30 mr-2 pb-1 closeimg"
                      alt="Close device"
                      src={FlavorImages.close_gray}
                    />
                  </div>

                  <div className="body_div col-12 pt-2 px-3">
                    <div className="row mt-2">
                      <div className="col-6 p-0 ">
                        <div className="row align-items-center">
                          <div className="col-2 mr-0 pr-0">
                            <img
                              alt={t("Connection time")}
                              className="size_20"
                              src={FlavorImages.bell_active}
                            />
                          </div>
                          <div className="offset-1 pl-1 col-9">
                            {props.device.date ? (
                              <>
                                <p className="text-navy text_12_700 p-0 m-0 line_height_17">
                                  {t("AlarmMarker.AlarmType")}
                                </p>
                                <p className="p-0 m-0 text-12-400 line_height_17">
                                  {getAlarmName()}
                                </p>{" "}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-6 mr-0 p-0">
                        <div className="row align-items-center">
                          <div className="col-2">
                            <img
                              alt={t("Time of alarm")}
                              className="size_20"
                              src={FlavorImages.clock}
                            />
                          </div>
                          <div className="pl-3 col-10">
                            {props.device.date ? (
                              <>
                                <p className="text-navy text_12_700 p-0 m-0 line_height_17">
                                  {t("AlarmMarker.Timeofalarm")}
                                </p>
                                <p className="p-0 m-0 text-12-400 line_height_17">
                                  {helper_functions.default.formatDateTime(
                                    props.device.date, "yyyy/MM/dd HH:mm"
                                  )}
                                </p>{" "}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3 pl-3">
                      <div className="col-12 ml-0 p-0">
                        <div className="row align-items-center">

                          <img
                            className="size_20"
                            alt={t("earth")}
                            src={FlavorImages.Earth_Blue}
                          />

                          <div
                            onClick={() => getLocationAddress(props.device.latitude, props.device.longitude)}
                            className="m-0 col-10 cursor-pointer">
                            <>
                              <p className="text-navy text_12_700 p-0 m-0  line_height_17 cursor-pointer">
                                {t("AlarmMarker.presstoestimateaddress")}
                              </p>
                              <p className=" text-12-400 p-0 m-0 line_height_17 cursor-pointer">
                                {address ? address : `${props.device.latitude.toFixed(5)} , ${props.device.longitude.toFixed(5)} `}
                              </p>{" "}
                            </>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="row mt-2 ">
                      <div className="col-5 p-0 ">
                        <div className="row align-items-center">
                          <div className="col-2 mr-0 pr-0">
                            <img
                              alt={t("Connection time")}
                              className="size_20"

                              src={process.env.REACT_APP_FLAVOR === 'helkama' ? bell_helkama : `${process.env.REACT_APP_BASE_URL}images/sidebar/${process.env.REACT_APP_FLAVOR}/bell_active.svg`}
                            />
                          </div>
                          <div className="offset-1 pl-1 col-9">
                            {props.device.date ? (
                              <>
                                <p className="text-navy text_12_700 p-0 m-0 line_height_17">
                                  {t("AlarmMarker.AlarmType")}
                                </p>
                                <p className="p-0 m-0 text-12-400 line_height_17">
                                  {getAlarmName()}
                                </p>{" "}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="row link_div_alarm  mt-3">
                      <div
                        onClick={() => copyPositionLink(props.device)}
                        className="col-12 copy_position_link_button text-center py-3"
                      >
                        {positionLinkCopied
                          ? t("deviceMarker.Link copied!")
                          : t("AlarmMarker.CopyAlarmpositionlink")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </InfoWindow>
        ) : (
          ""
        )}
      </Marker>
    </>
  );
};

export default AlarmMarker;
