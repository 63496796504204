import React from "react";
// import plus from "../../assets/images/plus.svg";
// import plus_helkama from "../../assets/Helkama_images/plus.svg";
import FlavorImages from './../../assetsFolderImport';

const AddButton = (props) => {
  const addClick = () => {
    props.onClick();
  };
  return (
    <React.Fragment>
      <div
        className="add_button cursor-pointer"
        style={props.active === true ? { backgroundColor: process.env.REACT_APP_FLAVOR === 'Bike' ? "var(--dark_helkama)"  : "var(--light_navy)", boxShadow: "inset 0px 1px 2px rgba(0, 0, 0, 0.1)"
           } : {}}
        onClick={() => addClick()}
      >
        {props.active === true ? (
          <img src={FlavorImages.plus} className="add_button_icon white_icon cursor-pointer" alt="..." />
        ) : (
          <img src={FlavorImages.plus} className="add_button_icon cursor-pointer" alt="..." />
        )}
        <label className="cursor-pointer text_11_700" style={props.active === true ? { color: "#FFFFFF" } : {}}>
          {props.title}
        </label>
      </div>
    </React.Fragment>
  );
};

export default AddButton;
