import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import calendar_select from "../../assets/images/calendar_select.svg";
// import calendar_select_helakma from "../../assets/Helkama_images/calendar-alt.svg";
// import calendar_unselect_helakma from "../../assets/Helkama_images/calendar-white.svg";
// import Rectangle_grey from "../../assets/Helkama_images/Rectangle_grey.svg";
// import calendar_unselect from "../../assets/images/calendar_unselect.svg";
// import clock from "../../assets/images/clock.svg";
// import clock_select from "../../assets/Helkama_images/clock_white.svg"
// import time_select from "../../assets/images/time_select.svg";
// import time_unselect from "../../assets/images/time_unselect.svg";
import ScrollMenuName from "../../components/ScrollBar/ScrollMenuName";
// import Rectangle_Black from "../../assets/Helkama_images/Rectangle_Black.svg";
import SearchControl from "../../components/Search/SearchControl";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { INDEX_URL, SITE_TITLE } from "../../helpers/constants";
import { seo } from "../../helpers/seo";
import * as devicesService2 from "../../services/devices/devicesService2";
import DeviceRowMobile from "../Device/DeviceRowMobile";
import ChooseAllCheckBox from "../../components/CheckBoxes/ChooseAllCheckBox";

import DeviceRow from "../Device/DeviceRow";
import { groupSlice } from "../../slice/groupSlice";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { addHours, addMinutes } from "date-fns";
import 'rc-time-picker/assets/index.css';
import * as genericService from "../../services/genericService";
import PopUp from "../Device/PopUp";
import { useHistory } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import SortDevice from "../../pages_v2/Device/SortDevice";
import * as helper_functions from "../../helpers/helper_functions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { devicesSlice } from "../../slice/deviceSlice";
import TimePicker from 'rc-time-picker';
import moment from 'moment'
import _ from "lodash";
import FlavorImages from "../../assetsFolderImport";
const deviceActions = devicesSlice.actions;

const now = moment().hour(0).minute(0);
const CreateLiveStream = (props) => {
  let { id } = useParams();
  if (id && !id.search(",") < 0) {
    id = parseInt(id);
  }
  const location = useLocation();
  let path = location.pathname
  const format = 'HH:mm';
  const { t } = useTranslation();
  const history = useHistory();
  const groupActions = groupSlice.actions;
  const dispatch = useDispatch();
  const [searchKeys, addSearchKey] = useState([]);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [nameFieldError, setNameFieldError] = useState("");
  const [deviceFieldError, setDeviceFieldError] = useState("");
  const [devices, setDevices] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [method, setMethod] = useState("");
  const [error, setError] = useState("");
  const [starttime, setStartTime] = useState(new Date());
  const [stoptime, setStopTime] = useState(new Date());
  const [duration, setDuration] = useState(new Date());
  const [chooseAll, setChooseAll] = useState(false);
  const [dataOnceSet, setDataOnceSet] = useState(false);
  const [nameOk, setNameOk] = useState(true);
  const [methodOk, setMethodOk] = useState(true);
  const [starttimeOk, setStarttimeOk] = useState(true);
  const [deviceOk, setDeviceOk] = useState(true);
  const [showSortDialog, setShowSortDialog] = useState(false);
  let streamId = 0
  const loadDevices = async () => {
    const response = await devicesService2.getDevicesCompactInfo();
    if (response.status === 200) {
      const devices = response.data.devices ?? [];
      const pendingDevices = response.data.pendingSharedDevices ?? [];
      const newDevices = devices.concat(pendingDevices);
      setDevices(newDevices);
      setFilteredDevices(newDevices);
      dispatch(
        deviceActions.setUpDeviceData({
          devices: newDevices,
        })
      );
      if (id && id.search(",") > -1) {
        let arrayTobePushed = []
        let keyFilter = id
        let keyTogetFilter = keyFilter.split(",")
        for (let i = 1; i < keyTogetFilter.length; i++) {
          arrayTobePushed.push(
            {
              "name": keyTogetFilter[i]
            }
          )
        }
        let items = []
        let SearchedItems = []
        addSearchKey(arrayTobePushed);
        arrayTobePushed.map((arr) => {
          return items.push(arr.name)
        })
        for (const item of items) {
          let temp = newDevices.filter((device) => {
            return device.name.toLowerCase().indexOf(item.toLowerCase()) > -1;
          });
          Array.prototype.push.apply(SearchedItems, temp);
        }
        dispatch(
          deviceActions.setUpFilteredDeviceData({
            devices: _.uniq(SearchedItems)
          })
        );
      }
      else {
        dispatch(
          deviceActions.setUpFilteredDeviceData({
            devices: newDevices,
          })
        );
      }
    }
    setDataOnceSet(true)
  };
  const loadGroups = async () => {
    const response = await devicesService2.getDeviceGroupsList();
    if (response.status === 200) {
      const groups = response.data ?? [];
      dispatch(
        groupActions.setUpGroupData({
          groups: groups,
        })
      );
      dispatch(
        groupActions.setUpFilteredGroupsData({
          groups: groups,
        })
      );
    }
  }
  const setChooseAllDevices = (e) => {
    setDeviceOk(true)
    setDeviceFieldError("")
    setChooseAll(e)
  }
  const on_load = async () => {
    await loadDevices();
    seo({
      title: SITE_TITLE,
      metaDescription: "",
    });
  };
  const onKeyChange = (searchKey) => {
    var array = [...searchKeys];
    if (searchKey) {
      var searchObject = { name: searchKey };
      array.push(searchObject);
    }
    filterDevices(array);
  }

  const setLiveStreamData = async (id) => {
    const response = await genericService.get("streaminglive/list");
    if (response.status === 200) {
      const liveStream = response.data[0];
      setName(liveStream.name);
      if (liveStream.password) setPassword(liveStream.password);
      setMethod("date");
      setStartTime(liveStream.starttime);
      setStarttimeOk(true);
      setStopTime(liveStream.stoptime);
      const streamDevices = liveStream.devices;
      if (streamDevices) {
        let temp = _.cloneDeep(devicesState.filteredDevices);

        temp.map((device) => {
          if (streamDevices.find((sd) => sd.deviceid === device.id))
            device.isSelected = true;
        });

        dispatch(
          deviceActions.setUpFilteredDeviceData({
            devices: temp,
          })
        );
      }
      return
    } else {
      history.push(INDEX_URL + "LiveStream");
    }
  };
  function onChangeHours(value) {
    setDuration(value.format(format))
  }


  useEffect(() => {
    loadGroups()
    if (!id && !dataOnceSet) {
      on_load();
    }

    else if (!dataOnceSet) {
      on_load();
    }
    else if (id) {
      if (!devicesState.filteredDevices.length && !dataOnceSet) {
        on_load();
      }
      else {
        if ((id && id.search(",") < 0) || id['0'] !== ',') {

          if (id['0'] !== ',') {
            streamId = id.split(',')
            setLiveStreamData(streamId[0]);

          }
        }
      }
    }

    return () => {
      dispatch(
        deviceActions.setGroupRowSettings({
          showgroupssetting: false,
        })
      );
      setDataOnceSet(false);
      dispatch(
        deviceActions.setUpFilteredDeviceData({
          devices: devicesState.devices,
        })
      );
    }
  }, [id, filteredDevices]);
  const filterDevices = (array) => {
    if (!array.length) {
      const results = _.cloneDeep(devicesState.devices);
      results.sort(helper_functions.default.sortFunctionWIthOrder(devicesState.sort, devicesState.sortOrder));
      dispatch(
        deviceActions.setUpFilteredDeviceData({
          devices: results,
        })
      );
      return;
    }

    const items = [];
    const filteredDevices = [];
    array.map((arr) => items.push(arr.name));
    for (const item of items) {
      let temp = devices.filter((device) => {
        return device.name.toLowerCase().indexOf(item.toLowerCase()) > -1;
      });

      Array.prototype.push.apply(filteredDevices, temp);
    }

    for (const item of items) {
      let temp = devices.filter((device) => {
        if (device.groupname) {
          return device.groupname.toLowerCase().indexOf(item.toLowerCase()) > -1;
        }
      });
      Array.prototype.push.apply(filteredDevices, temp);
    }
    dispatch(
      deviceActions.setUpFilteredDeviceData({
        devices: filteredDevices,
      })
    );
  };
  const OnSearchRemove = (index) => {
    var array = [...searchKeys];
    if (index !== -1) {

      let searchKey = array[index]

      let path2
      if (searchKeys.length > 1)
        path2 = path.replace(`,${searchKey.name}`, '');
      else
        path2 = path.replace(`,${searchKey.name}`, '');

      array.splice(index, 1);
      addSearchKey(array);
      filterDevices(array);
      history.push(path2);
    }
  };

  const handleChange = (field, e) => {
    setNameFieldError("");
    setNameOk(true);
    if (field === "name") setName(e.target.value);
    else if (field === "password") setPassword(e.target.value);
  };

  const OnClearAll = () => {
    var array = [...searchKeys];
    array.splice(0, array.length);
    addSearchKey(array);
    dispatch(
      deviceActions.setUpFilteredDeviceData({
        devices: array,
      })
    );
    filterDevices(array);


    history.push(INDEX_URL + "addeditlivestream/")
  };

  const addKey = (searchKey) => {
    setDeviceFieldError("");
    setDeviceOk(true);
    var searchObject = { name: searchKey };
    var array = [...searchKeys];
    array.push(searchObject);
    addSearchKey(array);
    if (!id) {
      history.push(INDEX_URL + 'addeditlivestream/search/,' + searchKey);
    }

    if (id && id[0] === ',') {
      history.push(path + "," + searchKey);

    }
    else if (id && id.search(",") > -1) {

      history.push(path + "," + searchKey);
    }
    else if (id && id[0] !== ',') {
      history.push(INDEX_URL + 'addeditlivestream/search/' + id + ',' + searchKey);
    }
    filterDevices(array);

  };

  const startLiveStream = async () => {
    let valid = true;
    setNameOk(true);
    setMethodOk(true);
    setStarttimeOk(true);
    setDeviceOk(true);
    setError("");

    if (!name) {
      valid = false;
      setNameFieldError("Field required");
      setNameOk(false);
    }
    if (!method) {
      valid = false;
      setMethodOk(false);
    }

    if (method === "date" && !starttime) {
      valid = false;
      setStarttimeOk(false);
    }

    if (method === "date" && !stoptime) {
      valid = false;
    }

    if (method === "time" && !duration) {
      valid = false;
    }

    if (!devicesState.filteredDevices.find((device) => device.isSelected)) {
      valid = false;
      setDeviceFieldError("Field required");
      setDeviceOk(false);
    }

    if (valid) {
      let data = { name };

      if (id) data.streamid = parseInt(id);

      if (password) data.password = password;

      let newSTartTime = new Date(starttime)
      let newStopTime = new Date(stoptime)
      if (method === "date") {
        data.starttime = helper_functions.default.UtctoDateTimeConverter(newSTartTime.toISOString())
        data.stoptime = helper_functions.default.UtctoDateTimeConverter(newStopTime.toISOString())
      }
      else {
        let now = new Date();
        const hoursAndMinutes = duration.split(":")
        newStopTime = addHours(now, hoursAndMinutes[0]);
        newStopTime = addMinutes(newStopTime, hoursAndMinutes[1])
        newStopTime = new Date(newStopTime)
        data.starttime = helper_functions.default.UtctoDateTimeConverter(now.toISOString())
        data.stoptime = helper_functions.default.UtctoDateTimeConverter(newStopTime.toISOString())
      }

      const deviceid = [];
      devicesState.filteredDevices.forEach((device) => {
        if (device.isSelected) deviceid.push(device.id);
      });

      data.deviceid = deviceid;
      let apiName = id ? "update" : "create";
      const response = await genericService.post(
        "streaminglive/" + apiName,
        data
      );
      if (response.status === 200 && response.data.successful) {

        history.push(INDEX_URL + "LiveStream");
      } else {
        setError("Error");
      }
    }
  };
  const { devicesState } = useSelector(
    (state) => ({ devicesState: state.devices }),
    shallowEqual
  );
  const sortDevicesArray = () => {
    let deviceData = _.cloneDeep(devicesState.devices);
    deviceData.sort(helper_functions.default.sortFunctionWIthOrder(devicesState.sort, devicesState.sortOrder));
    dispatch(
      deviceActions.setUpFilteredDeviceData({
        devices: deviceData,
      })
    );
    setShowSortDialog(false);
  };

  const openSortDialog = () => {
    setShowSortDialog(true);
  };
  const onDeviceClick = (index) => {
    setDeviceOk(true)
    var array = _.cloneDeep(devicesState.filteredDevices);
    if (index !== -1) {
      var device = array[index];

      if (device.isSelected !== true) device.isSelected = true;
      else device.isSelected = false;

      array[index] = device;
      dispatch(
        deviceActions.setUpFilteredDeviceData({
          devices: array,
        })
      );
    }
  };

  return (
    <>
      <React.Fragment>
        {error ? <PopUp setError={setError} error={error} /> : ""}
        <div className={` flex-column mt-3 position-relative `}>
          <label className="dialog_body_title dialog_body_title text-left mt-2 d-md-block d-none font_700_italic_gloria">
            {t("LiveStream.StartALivestream")}
          </label>
          <div className="d-flex mt-md-2">
            <div className=" d-flex col-md-5 col-6 pl-sm-2 px-0 flex-column alingToSearchBar">
              <form autoComplete="off">
                <div className="d-flex flex-row">
                  <label className="text_16_700 ml-0">
                    {t("LiveStream.StreamName")}
                  </label>
                  <div className="invalid-feedback bottom mt-1 text-12-400 padding_105">
                    {nameFieldError}
                  </div>
                </div>
                <input
                  autoComplete="false"
                  className={`${!nameOk ? "is-invalid" : ""
                    } form-control edit_device_label inset_shadow`}
                  onChange={(e) => handleChange("name", e)}
                  value={name}
                />
              </form>
            </div>

            <div className=" d-flex col-md-5 col-5 px-0 ml-3 flex-column">
              <div className="d-flex align-items-center">
                <label className="text_16_700 ml-0">
                  {t("login.Password")}
                </label>
                <label className="text_10_300 ml-2 mb-0">
                  {" (" + t("LiveStream.Optional") + ")"}
                </label>
              </div>
              <form autoComplete="off">
                <input
                  autoComplete="false"
                  className="form-control edit_device_label inset_shadow"
                  onChange={(e) => handleChange("password", e)}
                  value={password}
                />
              </form>
            </div>
          </div>

          <div className="d-flex
          ">
            <div className="pl-sm-2 pl-md-0 col-2  px-0 flex-column">
              <label className="text_16_700 ml-0">
                {t("Geofences.Method")}
              </label>

              <div>

                <div
                  className={` ${methodOk ? "" : "border border-danger"} d-flex liveStreamMethodButtonAlignment`}
                  style={{ borderRadius: "5px", width: "5.3rem" }}
                >
                  {process.env.REACT_APP_FLAVOR === 'Bike' ?
                    <div
                      className="d-flex cursor-pointer  align-items-center " >
                      <div
                        onClick={() => setMethod("date")}
                        className=" px-0 cursor-pointer  picContainer">
                        {method == 'date' ?
                          <img
                            src={FlavorImages.Rectangle_grey}
                            className="cursor-pointer"
                            style={{ height: "38px" }}
                            alt="..."
                          /> : <img
                            src={FlavorImages.Rectangle_Black}
                            className="cursor-pointer"
                            style={{ height: "38px" }}
                            alt="..."
                          />}
                        {method == 'date' ?
                          <img
                            className="size_35 cursor-pointer pic2 p-2 "
                            style={{ marginTop: "2px" }}
                            src={FlavorImages.calendar_white}
                            alt="..." /> : <img
                            className="size_35 cursor-pointer pic2 p-2 "
                            style={{ marginTop: "2px" }}
                            src={FlavorImages.calendar_alt}
                            alt="..." />}
                      </div>
                      <div
                        onClick={() => setMethod("time")}
                        className=" px-0 cursor-pointer ml-3  picContainer">
                        {method == 'time' ?
                          <img
                            src={FlavorImages.Rectangle_grey}
                            className="cursor-pointer"
                            style={{ height: "38px" }}
                            alt="..."
                          /> : <img
                            src={FlavorImages.Rectangle_Black}
                            className="cursor-pointer"
                            style={{ height: "38px" }}
                            alt="..."
                          />}
                        {method == 'time' ?
                          <img
                            className="size_35 cursor-pointer pic2 p-2 "
                            style={{ marginTop: "2px" }}
                            src={FlavorImages.clock_white}
                            alt="..." /> : <img
                            className="size_35 cursor-pointer pic2 p-2 "
                            style={{ marginTop: "2px" }}
                            src={FlavorImages.clock}
                            alt="..." />}
                      </div>
                    </div>
                    :
                    <>
                      <img
                        src={method === "date" ? FlavorImages.calendar_select : FlavorImages.calender_unselect}

                        onClick={() => setMethod("date")}
                        className="next_arrow_icon cursor-pointer"
                        alt="..."
                      />
                      <img

                        src={method === "time" ? FlavorImages.time_select : FlavorImages.time_unselect}
                        onClick={() => setMethod("time")}
                        className="next_arrow_icon cursor-pointer"
                        style={{ marginLeft: '0.8rem' }}

                        alt="..."
                      />
                    </>
                  }
                </div>
              </div>
            </div>

            {method === "date" ? (
              <div className="col-md-12 px-0 ml-md-2 pl-sm-2 flex-column ml-3 LiveStreamInputmobileMargin ">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <>
                    <div className="d-flex">
                      <div className="col-md-5 col-sm-6 flex-column">
                        <label className="text_16_700 mx-0">
                          {t("LocationHistory.Start")}
                        </label>
                        <DateTimePicker

                          value={starttime}
                          inputFormat="MM/dd HH:mm"
                          onChange={(value) => setStartTime(value)}
                          renderInput={(params) => (
                            <TextField
                              className="date_label Livestream_DatePicker_Marigns_StartTime" {...params}
                              error={starttimeOk ? false : true}
                            />
                          )}
                        />
                      </div>
                      {
                        <div className="col-md-5 col-sm-6 flex-column " >
                          <label className="text_16_700 mx-0" >
                            {t("LocationHistory.End")}
                          </label>
                          <DateTimePicker
                            value={stoptime}
                            inputFormat="MM/dd HH:mm"
                            onChange={(value) => setStopTime(value)}
                            renderInput={(params) => (
                              <TextField

                                className="date_label " {...params}
                                error={starttimeOk ? false : true}
                              />
                            )}
                          />
                        </div>
                      }
                    </div>
                  </>
                </LocalizationProvider>
              </div>
            ) : method === "time" ? (
              <div className="   col-6 col-md-8 px-0 ml-md-4 pl-sm-2 flex-column " style={{ marginLeft: "2.25rem" }}>
                <label className="text_16_700 mx-0">
                  {t("LiveStream.Duration")}
                </label>

                <div className="row px-0 mx-0 col-4">
                  <TimePicker
                    showSecond={false}
                    defaultValue={now}
                    className="xxx"
                    onChange={onChangeHours}
                    format={format}
                    use24Hours
                    inputReadOnly
                  />
                </div>

              </div>
            ) : null}
          </div>
          <div className={` ${!deviceOk ? "border border-danger borderOfDevicesListLiveStreamDevices" : ""}`} style={{ borderRadius: "" }}>
            <div className="d-flex pl-sm-2  pl-md-0 flex-row mt-3">
              <label className="text_16_700 ml-0 mb-0">
                {t("customFile.Choosebike")}
              </label>
              <div
                className="invalid-feedback bottom text-12-400"
                style={{ paddingLeft: "120px" }}
              >
                {deviceFieldError}
              </div>
            </div>
            <div className="control_bar d-md-block d-none mt-1 ">
              <div className="accordion" id="accordionone">
                <SearchControl
                  isLiveStreamDeviceSearch={true}
                  error={deviceOk ? false : true}
                  onSortClick={() => openSortDialog()}
                  onChangeSearchKey={(e) => onKeyChange(e)}
                  onAddSearchKey={(e) => addKey(e)}
                />
                <SortDevice
                  sortDevicesArray={sortDevicesArray}
                  loadDevices={loadDevices}
                  show={showSortDialog}
                  onClose={() => setShowSortDialog(false)}
                />
              </div>
            </div>

            <div className="control_bar d-md-flex d-none mt-3 margin-left_13" >
              <ChooseAllCheckBox
                isChecked={chooseAll}
                devicesState={devicesState}
                select={"livestream"}
                onClick={(e) => setChooseAllDevices(e)}
              />
              <div className="search_capsule_bar row ">
                <ScrollMenuName
                  menuItems={searchKeys}
                  onRemove={(e) => OnSearchRemove(e)}
                  OnClearAll={() => OnClearAll()}
                />
              </div>
            </div>

            <div className="scroll_div_34_liveStream alarm_list_shadow d-md-block d-none mt-md-3 ml-n1 p-0 ">
              {devicesState.filteredDevices.map((device, index) => {
                return (
                  device.sharedstatus.sharedwithme ? "" :
                    <DeviceRow
                      isSelection={true}
                      key={index}
                      index={index}
                      selected={chooseAll ? true : false}
                      setChooseAll={setChooseAll}
                      device={device}
                      onClick={() => onDeviceClick(index)}
                    />
                );
              })}
            </div>
          </div>
          <div className="scroll_div_20  height_mobile_163 d-md-none d-block row mt-2 p-0">
            {error ? <PopUp setError={setError} error={error} /> : ""}
            {devicesState.filteredDevices.map((device, index) => {
              return (
                <DeviceRowMobile
                  isSelection={true}
                  page={"liveStream"}
                  key={index}
                  index={index}
                  selected={false}
                  device={device}
                  onClick={() => onDeviceClick(index)}
                  setChooseAll={() => {
                    return false;
                  }}
                />
              );
            })}
          </div>
          <div className="w-100 mt-2 d-flex justify-content-end liveStremBtnStyle">
            <button
              onClick={() => props.setDownArrowClick()}
              className="grey_btn_liveStream_mobile   mr-2 d-md-none d-block"
              id="next"
            >
              {t(" Cancel")}
            </button>
            <button
              onClick={() => startLiveStream()}
              className="border-0 btn_theme_start_liveStream_button background_black_helkama width_159_mobile"
              id="next"
            >
              {t("LiveStream.Startlivestream")}
            </button>

          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default CreateLiveStream;
