import _ from "lodash";
import React, {  useState } from "react";
import { useTranslation } from "react-i18next";
// import amex_primary from "../../assets/images/amex_primary.svg";
// import amex_second from "../../assets/images/amex_second.svg";
// import mastercard_primary from "../../assets/images/mastercard_primary.svg";
// import mastercard_second from "../../assets/images/mastercard_second.svg";
// import default_second from "../../assets/images/default_second.svg";
// import default_primary from "../../assets/images/default_primary.svg";
// import trash_red_icon from "../../assets/images/trash_red_icon.svg";
import * as genericService from "../../services/genericService";
// import dot_selected from "../../assets/images/dot_selected.svg";
// import dot_unselected from "../../assets/images/dot_unselected.svg";
import FlavorImages from "../../assetsFolderImport";

const CardDetail = (props) => {
  const { t } = useTranslation();
  const [deleteButton, setdeletButton] = useState(false);
  const [edit, setEdit] = useState(false);
  const [primary, setPrimary] = useState(false);
  const [card, setCard] = useState(props.card);
  const [Imgindex, setindex] = useState(0)
  const setPrimaryCard = async () => {
    // console.log(card);
    const data = {
      payment_method: card.id,
    };
    const response = await genericService.post("dataplan/cards/primary", data);
    if (response.status === 200 && response.data.successful) {
      setEdit(false);
      props.loadCards();
    }
  };
  const dropzoneStyle = (index) => ({
    marginLeft: `0.${index + 1}rem`,
    zIndex: index
  });
  const updateSelectedCard = (increaseDecrease) => {
    var index = _.cloneDeep(Imgindex);
    if (increaseDecrease === "increase" && index + 1 < props.cards.length) {
      index = index + 1;
    } else if (increaseDecrease === "decrease" && index > 0) {
      index = index - 1;
    }
    setindex(index);
    setCard(props.cards[index]);
  };
  const setCardIndex = (index) => {
    setindex(index)
    setCard(props.cards[index]);
  }
  const deleteCard = async () => {
    const response = await genericService.post("dataplan/cards/delete", {
      cardid: card.id,
    });
    if (response.status === 200 && response.data.successful) {
      props.loadCards();
      setEdit(false);
    }
  };

  const renderDotUI = () => {
    return (
      <div className="dot_layout" style={{ marginTop: "180px" }}>
        {props.cards.map((card, index) => {
          return (
            <img
              alt="card"
              onClick={() => setCardIndex(index)}
              className="dot ml-2"
              src={index === Imgindex ? FlavorImages.dot_selected : FlavorImages.dot_unselected}
              key={index}
            />
          );
        })}
      </div>
    );
  };
  const setCardAnimation = (index) => {
    if (index === props.cards.length - 1 ) {
      setindex(0)
      setCard(props.cards[0]);
    }
    else {
      setindex(index + 1)
      setCard(props.cards[index + 1]);
    }
  }
  const setEditUI = () => {
    setEdit(true);
    setdeletButton(true);
  };
  const showAddEditButtons = () => {
    return (
      <div>
        {deleteButton ? (
          <img
            onClick={deleteCard}
            src={FlavorImages.trash_red_icon}
            className="cursor-pointer size_35 margin_right_mobile_45 slide_right_delete_btn"
            alt="..."
          />
        ) : (
          ""
        )}
        <button
          className="grey_btn "
          onClick={() => setEditUI()}
          style={{ minWidth: "90px", height: "36px", fontSize: "12px" }}
        >
          {t("DataPlan.EditCard")}
        </button>
        <button
          onClick={() => props.setMode("add")}
          className={props.cards.length>0?"grey_btn ml-3":"btn_theme text_dark_helkama ml-3"}
          style={{ minWidth: "90px", height: "36px", fontSize: "12px" }}
          id="next"
          disabled={props.cards.length>0?"disabled":null}
        >
          {t("DataPlan.AddCard")}
        </button>
      </div>
    );
  };
  const renderImages = () => {
    return (
      <div className="ImageLayout" style={{ marginTop: "10px" }}>
        {props.cards.map((card, index) => {
          if (card !== undefined && card.brand === "American Express")
            if (index === 0)
            {return <img key={index} style={dropzoneStyle(index)} onClick={() => setCardAnimation(index)}
            className={`card_data_plane cursor-pointer ${index === Imgindex ? "card--tabs" : "card--space"}  `} src={FlavorImages.amex_primary} alt="..." />
            }
            else
            {
              return (
                <img key={index} style={dropzoneStyle(index)} onClick={() => setCardAnimation(index)} className={`card_data_plane cursor-pointer ${index === Imgindex ? "card--tabs" : "card--space"}  `} src={FlavorImages.amex_second} alt="..." />
              )
            }
          else if (card !== undefined && card.brand === "MasterCard")
          {
            if (index === 0)
            {return <img key={index} style={dropzoneStyle(index)} onClick={() => setCardAnimation(index)}
             className={`card_data_plane cursor-pointer  ${index === Imgindex ? "card--tabs" : "card--space"}  `}
              src={FlavorImages.mastercard_primary} alt="..." />;
              }
            else
            {
              return(
              <img key={index} style={dropzoneStyle(index)} onClick={() => setCardAnimation(index)} className={`card_data_plane cursor-pointer
                ${index === Imgindex ? "card--tabs" : "card--space"}  `} src={FlavorImages.mastercard_second} alt="..." />)
            }
          }
        else if (index === 0)
          {
            return <img key={index} style={dropzoneStyle(index)} onClick={() => setCardAnimation(index)} className={`card_data_plane cursor-pointer   ${index === Imgindex ? "card--tabs" : "card--space"}  `} src={FlavorImages.default_primary} alt="..." />;
          }
          else return <img key={index} style={dropzoneStyle(index)} onClick={() => setCardAnimation(index)} className={`card_data_plane cursor-pointer  ${index === Imgindex ? "card--tabs" : "card--space"}  `} src={FlavorImages.default_second} alt="..." />;
        })}
      </div>
    );
  }
  const showEditDeleteButtons = () => {
    return (
      <div>
        <img
          onClick={deleteCard}
          src={FlavorImages.trash_red_icon}
          className="cursor-pointer margin_right_mobile_45 size_35 slide_left_delete_btn"
          alt="..."
        />
        <button
          className="grey_btn ml-2 "
          onClick={() => setEdit(false)}
          style={{ minWidth: "90px", height: "36px", fontSize: "12px" }}
        >
          {t("button.Cancel")}
        </button>
        <button
          onClick={() => setPrimaryCard()}
          className="btn_theme ml-3"
          style={{
            minWidth: "90px",
            height: "36px",
            fontSize: "12px",
          }}
          id="next"
        >
          {t("button.Save")}
        </button>
      </div>
    );
  };

  return (
    <>

      <div className="rounded_white row m-0 py-4">
        <div className="col-md-5 col-12">
          {renderImages()}
          {/* {renderDotUI()} */}
        </div>

        {/* <div className="d-flex flex-column justify-content-center mr-2">
          <img
            className="cursor-pointer"
            onClick={() => updateSelectedCard("increase")}
            src={arrow_up}
            alt="..."
          />

          <img
            className="mt-3 cursor-pointer"
            src={arrow_down}
            alt="..."
            onClick={() => updateSelectedCard("decrease")}
          />
        </div> */}
        {card ? <div className="col-md-6 col-12">
          <label className="text_17_700">{t("DataPlan.CardInfo")}</label>

          <div className=" d-flex justify-content-between">
            <label className="text_12_500">{t("DataPlan.CardNumber")}</label>
            <div>
              <label
                className={`text_12_500 text-light-navy text_dark_helkama  ${edit
                  ? "slide_left_text_card"
                  : deleteButton
                    ? "slide_right_text_card "
                    : ""
                  }`}
              >
                {card !== undefined ? "**** **** **** " : ""}
              </label>
              <label
                className={`text_12_500 text-light-navy text_dark_helkama ${edit ? "visible" : "invisible"
                  }`}
              >
                {card.card.last4}
              </label>
            </div>
          </div>
          <div className=" d-flex justify-content-between mt-2">
            <label className="text_12_500">{t("DataPlan.Expires")}</label>
            <label className="text_12_500 text-light-navy text_dark_helkama">
              {card !== undefined
                ? card.card.exp_month < 10
                  ? "0" + card.card.exp_month + "/" + card.card.exp_year
                  : card.card.exp_month + "/" + card.card.exp_year
                : ""}
            </label>
          </div>


          {/* STATUS FOR PRIMARY AND SECONDARY CARD, UNCOMMENT UNDER TO ENABLE */}
          {/* <div className=" d-flex justify-content-between mt-2">
            <label className="text_12_500">{t("DataPlan.Status")}</label>

            <div>
              <label
                className={`text_12_500 text-light-navy text_dark_helkama   ${edit
                  ? "slide_left_text_card"
                  : deleteButton
                    ? "slide_right_text_card"
                    : "magin_minus28"
                  }`}
              >
                {Imgindex === 0 || edit
                  ? t("DataPlan.PrimaryCard")
                  : t("DataPlan.SecondaryCard")}
              </label>

              {Imgindex === 0 || primary ? (
                <img
                  onClick={() => setPrimary(!primary)}
                  className={`ml-2 ${edit ? "visible" : "invisible"}`}
                  style={{ width: "20px", height: "20px" }}
                  src={checked}
                  alt="checked"
                />
              ) : (
                <img
                  onClick={() => setPrimary(!primary)}
                  className={`ml-2 ${edit ? "visible" : "invisible"}`}
                  style={{ width: "20px", height: "20px" }}
                  src={check_empty}
                  alt="unchecked"
                />
              )}
            </div>
          </div> */}

          <div className="mt-3 d-flex justify-content-md-end">
            {edit ? showEditDeleteButtons() : showAddEditButtons()}
          </div>
        </div> : ""}


      </div>

    </>
  );
};

export default CardDetail;
