import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// import close_gray from "../../assets/images/close_gray.svg";
import helper_functions from "../../helpers/helper_functions";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authSlice } from "../../slice/authSlice";
import * as genericService from "../../services/genericService";
import { settingsSlice } from "../../slice/settingsSlice";
import { devicesSlice } from "../../slice/deviceSlice";
import { geofencesSlice } from "../../slice/geofencesSlice";
import { livestreamSlice } from "../../slice/liveStreamSlice";
import { mapSettingsSlice } from "../../slice/mapSettingsSlice";
import { alarmsSlice } from "../../slice/alarmsSlice";
import { INDEX_URL } from "../../helpers/constants";
import FlavorImages from "../../assetsFolderImport";
const settingsActions = settingsSlice.actions;
const deviceActions = devicesSlice.actions;
const geofencesActions = geofencesSlice.actions;
const alarmActions = alarmsSlice.actions;
const streamAction = livestreamSlice.actions;
const mapSettingsActions = mapSettingsSlice.actions;
const SignOutDialog = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authactions = authSlice.actions;
  const history = useHistory();
  const closeDialog = () => {
    dispatch(settingsActions.setlogout({ logoutdial: false }));
    props.closeSingoutDialog();
    props.setSelect();
  };
  const logout = async () => {
    if (props.select === "Safety") {
      const response = await genericService.get("login/signOutAll");
      if (response.status === 200 ) {
        removeLocalStorage();
      } else {
        props.setError(response.data.message);
        props.closeSingoutDialog()
      }
    } else {
      removeLocalStorage();
    }
  };

  const removeLocalStorage = async () => {
    dispatch(authactions.setAuthToken({ authToken: null }));
    dispatch(authactions.setAuthData({ mapDevices: [] }));
    dispatch(deviceActions.resetAllDeviceState());
    dispatch(geofencesActions.resetAllgeoFencestate());
    dispatch(alarmActions.resetAllAlarmstate());
    dispatch(streamAction.resetAllliveStreamstate());
    dispatch(mapSettingsActions.resetMapsettingsState());
    await helper_functions.removeLocalStorageValue("token");
    await helper_functions.removeLocalStorageValue("data");
    history.push(INDEX_URL);
  };
  return (
    <>
      <Dialog maxWidth={"xs"} fullWidth open={props.show}>
        <Card className="card">
          <div className="text-center">
            <div
              className="py-3 px-4 d-flex justify-content-between align-items-center card-header bg-white"
              style={{ backgroundColor: "white" }}
            >
              <label className=" mb-0 text-grey text_17_700 ">
                {" "}
                {t("forgotPassword.Confirm")}
              </label>
              <img
                className="size_1-5_rem"
                src={FlavorImages.close_gray}
                onClick={() => props.closeSingoutDialog()}
                alt="..."
              />
            </div>
          </div>
          <div className="row ml-1 my-4 py-2 ">
            <div className=" w-100 row  justify-content-center ">
              <label className=" text-grey text_12_500 ">
                {" "}
                {t("Settings.Logout_confirm_message")}
              </label>
            </div>
          </div>
          <div className="m-0 card-footer  d-flex justify-content-end">
            <button
              className="btn text_11_700 btn-sm py-2 font-bold text-grey"
              onClick={() => closeDialog()}
              variant="light"
            >
              {t("actionDialog.cancel")}
            </button>

            <button
              className="btn text_11_700  bg-danger btn-sm text-white py-2 text-uppercase ml-3"
              onClick={() => logout()}
              id="next"
            >
              {t("Settings.Logout")}
            </button>
          </div>
        </Card>
      </Dialog>
    </>
  );
};

export default SignOutDialog;
