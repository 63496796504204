// import green_tick from "../../assets/images/green_tick.svg";
// import cross_red from "../../assets/images/cross_red.svg";
import * as genericService from "../../services/genericService";
// import users from "../../assets/images/users.svg";
import { useTranslation } from "react-i18next";
import FlavorImages from "../../assetsFolderImport";



const WaitingForAcceptRow = (props) => {
  const { t } = useTranslation();
  const setAcceptDeny = async (data, accept) => {
    const response = await genericService.post(
      "device/share/acceptdenyinvitation",
      {
        deviceid: [data],
        accept: accept,
      }
    );
    if (response.status === 200) {
      props.updateShareWithMe();
    } else {
    }
  };

  return (
    <>
      <div>
        <div className={`card border_radius_0_mobile ${props.groupSelctor
          ? "background_dull"
          : ""
          }   p-0`}>
          <div
            className={`p-1 pl-2 ${props.groupSelctor
              ? "bg-transparent"
              : ""
              } card-body border_radius_0_mobile`}
            style={{ background: "#ffffff", width: "100%" }}
          >
            <div className="row">
              <div className="px-3 py-2">
                <img src={FlavorImages.users} className="signal_icon ml-2" alt="..." />
              </div>
              <div className="col-8 ">
                <div className="row mt-2">
                  <label className="device_row_left_active_label mr-1 ">
                    {props.device.sharedstatus.sharedfrom}
                  </label>
                  <label className="device_row_left_active_date">
                    {t("Settings.wanttoShare")}
                  </label>
                </div>
                <div className="row">
                  <label className="device_row_left_active_label mr-1 ">
                    {props.device.name}
                  </label>
                  <label className="device_row_left_active_date">
                    {t("Settings.withYou")}
                  </label>
                </div>
              </div>
              <div className="col-2 p-2 mt-1">
                <img
                  onClick={() => setAcceptDeny(props.device.id, true)}
                  src={FlavorImages.green_tick}
                  className="size_20 shareimg"
                  alt="..."
                />
                <img
                  onClick={() => setAcceptDeny(props.device.id, false)}
                  src={FlavorImages.cross_red}
                  className="size_20 ml-3 shareimg"
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WaitingForAcceptRow;
