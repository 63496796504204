import { Card } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
// import close_gray from "../../assets/images/close_gray.svg";
import { shallowEqual, useSelector } from "react-redux";
// import check from "../../assets/images/check.svg";
import { useDispatch } from "react-redux";
import { mapSettingsSlice } from "../../slice/mapSettingsSlice";
import { devicesSlice } from "../../slice/deviceSlice";
const deviceActions = devicesSlice.actions;
const mapSettingsActions = mapSettingsSlice.actions;
const ActionDialog = (props) => {
  const { devicesState } = useSelector(
    (state) => ({ devicesState: state.devices }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const setCheckbar = () => {
    if (devicesState.action) {
      dispatch(mapSettingsActions.setSetting({ show_groups: false }));
      props.setenableDisblaeButton(true);
    }
  };
  const resetCheckBar = () => {
    props.resetActionButton()
    props.resetIsSelection();
  };
  const handleChange = (newaction) => {
    dispatch(
      deviceActions.setAction({
        action: newaction,
      })
    );
  };

  const renderBlock = () => {
    return (
      <div className="row" onClick={() => handleChange(!devicesState.action)}>
        <div className="col-7 d-flex justify-content-start">
          <label
            className={`my-3 ml-2 text-12-400 cursor-pointer ${devicesState.action ? "" : "text-gray"
              }`}
            style={{ fontSize: "15px" }}
          >
            {t("devicepower.enabledisable")}
          </label>
        </div>
        <div className="col-5 mt-1 d-flex justify-content-end">
          <img
            style={{ height: "16px" }}
            className={`my-3 pr-3 ' ${devicesState.action ? "" : "set-check"}`}
            src={props.FlavorImages.check}
            alt="..."
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div id="collapsetwo" className="collapse " data-parent="#accordiontwo">
        <Card className="card" style={{ width: "335px", marginTop: "5px" }}>
          <div className="text-center">
            <div className="dialog_top_sort_row py-0 card-header bg-white">
              <label className="text_17_700 mb-3 close_img">
                {" "}
                {t("actionDialog.actions")}
              </label>
              <img
                data-toggle="collapse"
                data-target="#collapsetwo"
                aria-expanded="true"
                aria-controls="collapsetwo"
                className="cursor-pointer"
                src={props.FlavorImages.close_gray}
                alt="..."
              />
            </div>
            <div className="card-body py-1">{renderBlock()}</div>
          </div>

          <div className="m-0 card-footer  d-flex justify-content-end">
            <button
              className="btn bg-transparent btn-sm font-bold text-grey text_12_700"
              data-toggle="collapse"
              data-target="#collapsetwo"
              aria-expanded="true"
              aria-controls="collapsetwo"
              variant="light"
              style={{ marginTop: "5px" }}
            >
              {t("actionDialog.cancel")}
            </button>
            {devicesState.action !== false ? (
              <button
                className="btn btn_navy bg_dark_helkama py-0 px-4 text-white ml-3"
                data-toggle="collapse"
                data-target="#collapsetwo"
                aria-expanded="true"
                aria-controls="collapsetwo"
                onClick={() => setCheckbar()}
                style={{ marginTop: "5px", marginLeft: "10px" }}
                id="next"
              >
                {t("actionDialog.select")}
              </button>
            ) : (
              <button
                className="btn btn_navy bg_dark_helkama py-0 px-4 text-white ml-3"
                data-toggle="collapse"
                data-target="#collapsetwo"
                aria-expanded="true"
                aria-controls="collapsetwo"
                onClick={() => resetCheckBar()}
                style={{ marginTop: "5px", marginLeft: "10px" }}
                id="next"
              >
                {t("actionDialog.select")}
              </button>
            )}
          </div>
        </Card>
      </div>
    </>
  );
};
export default ActionDialog;
