import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { SITE_TITLE } from "../../helpers/constants";
import * as helper_functions from "../../helpers/helper_functions";
import { seo } from "../../helpers/seo";

const SafetyRow = (props) => {
  const { t } = useTranslation();
  const on_load = () => {
    seo({
      title: SITE_TITLE,
      metaDescription: "",
    });
  };
  useEffect(() => {
    on_load();
  }, []);

  return (
    <>
      <div>
        <div
          className={`
          ${props.isFirst ? "rounded-top" : props.isLast ? "rounded-bottom" : ""
            }
           ${props.isLast ? "" : ""}
           ${props.index % 2 === 0 ? "white_shadow" : "grey_shadow_f2"}
            p-2 d-flex flex-column w-100`}
        >
          <div className="d-flex justify-content-between align-items-center">
            <label className=" text_17_700 text-navy my-0">
              {props.history.client_model}{" "}
            </label>

            <label className=" text_16_700 text-navy my-0">
              {props.history.country_city}{" "}
            </label>
          </div>

          <div className="d-flex justify-content-between align-items-center">
            <label className=" text_12_400 text-navy my-0">
              {props.history.operating_system}{" "}
            </label>

            <label className=" text_12_400 text-navy my-0">
              { helper_functions.default.formatDateTime(
                  props.history.date_time, "yyyy/MM/dd HH:mm"
                )}{" "}
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default SafetyRow;
