import { useEffect } from "react";
// import download from "../../assets/images/download.svg";
import * as helper_functions from "../../helpers/helper_functions";
import FlavorImages from "../../assetsFolderImport";

const UserManualRow = (props) => {
  useEffect(() => {}, []);

  return (
    <>
      <div>
        <div
          className={` 
          ${
            props.isFirst ? "rounded-top" : props.isLast ? "rounded-bottom" : ""
          }
           ${props.isLast ? "" : "border-bottom"} 
           p-3   w-100 bg-white d-flex  border-bottom justify-content-between align-items-center"`}
        >
          <label className=" text_16_400 text-navy my-0">
            {props.manual.productname}
          </label>

          <img
            onClick={() =>
              helper_functions.default.downloadFileFromURL(props.manual.pdfurl)
            }
            src={FlavorImages.download}
            className="size_15 cursor-pointer"
            alt="..."
          />
        </div>
      </div>
    </>
  );
};

export default UserManualRow;
