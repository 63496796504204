export const sounds1sec = [
  {
    id: "alarm-siren",
    name: "Alarm Siren",
    file_1: "alarm-siren_1sec.wav",
  },
  {
    name: "bird",
    id: "bird",
    file_1: "bird_1sec.wav",
  },
  {
    name: "Burglar Alert",
    id: "burglar-alert",
    file_1: "burglar-alert_1sec.wav",
  },
  {
    name: "Car Alarm",
    id: "car_alarm",
    file_1: "car_alarm_1sec.wav",
  },
  {
    name: "City Siren",
    id: "city-siren",
    file_1: "city-siren_1sec.wav",
  },
  {
    name: "ComputerSFX",
    id: "computerSFX",
    file_1: "computerSFX_1sec.wav",
  },
  {
    name: "Danger Male Voice",
    id: "danger-male-voice",
    file_1: "danger-male-voice_1sec.wav",
  },
  {
    name: "Easy Money",
    id: "easy-money",
    file_1: "easy-money_1sec.wav",
  },
  {
    name: "El_Synthefx",
    id: "el_synthefx",
    file_1: "el_synthefx_1sec.wav",
  },
  {
    name: "Electricringbell",
    id: "electricringbell",
    file_1: "electricringbell_1sec.wav",
  },
  {
    name: "Futuristic Alert",
    id: "futuristic-alert",
    file_1: "futuristic-alert_1sec.wav",
  },
  {
    name: "Heartmonitor",
    id: "heartmonitor",
    file_1: "heartmonitor_1sec.wav",
  },
  {
    name: "Intercom Alert",
    id: "intercom_alert",
    file_1: "intercom_alert_1sec.wav",
  },
  {
    name: "Leave Building",
    id: "leave-building",
    file_1: "leave-building_1sec.wav",
  },
  {
    name: "Old Siren",
    id: "old-siren",
    file_1: "old-siren_1sec.wav",
  },
  {
    name: "Red Alert",
    id: "red-alert",
    file_1: "red-alert_1sec.wav",
  },
  {
    name: "Scifi Shutdown",
    id: "scifi-shutdown",
    file_1: "scifi-shutdown_1sec.wav",
  },
  {
    name: "Signal Alert",
    id: "signal-alert",
    file_1: "signal-alert_1sec.wav",
  },
];

export const sounds3sec = [
  {
    id: "alarm-siren",
    name: "Alarm Siren",
    file_1: "alarm-siren_3sec.wav",
  },
  {
    name: "bird",
    id: "bird",
    file_1: "bird_3sec.wav",
  },
  {
    name: "Burglar Alert",
    id: "burglar-alert",
    file_1: "burglar-alert_3sec.wav",
  },
  {
    name: "Car Alarm",
    id: "car_alarm",
    file_1: "car_alarm_3sec.wav",
  },
  {
    name: "City Siren",
    id: "city-siren",
    file_1: "city-siren_3sec.wav",
  },
  {
    name: "ComputerSFX",
    id: "computerSFX",
    file_1: "computerSFX_3sec.wav",
  },
  {
    name: "Danger Male Voice",
    id: "danger-male-voice",
    file_1: "danger-male-voice_3sec.wav",
  },
  {
    name: "Easy Money",
    id: "easy-money",
    file_1: "easy-money_3sec.wav",
  },
  {
    name: "El_Synthefx",
    id: "el_synthefx",
    file_1: "el_synthefx_3sec.wav",
  },
  {
    name: "Electricringbell",
    id: "electricringbell",
    file_1: "electricringbell_3sec.wav",
  },
  {
    name: "Futuristic Alert",
    id: "futuristic-alert",
    file_1: "futuristic-alert_3sec.wav",
  },
  {
    name: "Heartmonitor",
    id: "heartmonitor",
    file_1: "heartmonitor_3sec.wav",
  },
  {
    name: "Intercom Alert",
    id: "intercom_alert",
    file_1: "intercom_alert_3sec.wav",
  },
  {
    name: "Leave Building",
    id: "leave-building",
    file_1: "leave-building_3sec.wav",
  },
  {
    name: "Old Siren",
    id: "old-siren",
    file_1: "old-siren_3sec.wav",
  },
  {
    name: "Red Alert",
    id: "red-alert",
    file_1: "red-alert_3sec.wav",
  },
  {
    name: "Scifi Shutdown",
    id: "scifi-shutdown",
    file_1: "scifi-shutdown_3sec.wav",
  },
  {
    name: "Signal Alert",
    id: "signal-alert",
    file_1: "signal-alert_3sec.wav",
  },
];

export const sounds10sec = [
  {
    id: "alarm-siren",
    name: "Alarm Siren",
    file_1: "alarm-siren_10sec.wav",
  },
  {
    name: "bird",
    id: "bird",
    file_1: "bird_10sec.wav",
  },
  {
    name: "Burglar Alert",
    id: "burglar-alert",
    file_1: "burglar-alert_10sec.wav",
  },
  {
    name: "Car Alarm",
    id: "car_alarm",
    file_1: "car_alarm_10sec.wav",
  },
  {
    name: "City Siren",
    id: "city-siren",
    file_1: "city-siren_10sec.wav",
  },
  {
    name: "ComputerSFX",
    id: "computerSFX",
    file_1: "computerSFX_10sec.wav",
  },
  {
    name: "Danger Male Voice",
    id: "danger-male-voice",
    file_1: "danger-male-voice_10sec.wav",
  },
  {
    name: "Easy Money",
    id: "easy-money",
    file_1: "easy-money_10sec.wav",
  },
  {
    name: "El_Synthefx",
    id: "el_synthefx",
    file_1: "el_synthefx_10sec.wav",
  },
  {
    name: "Electricringbell",
    id: "electricringbell",
    file_1: "electricringbell_10sec.wav",
  },
  {
    name: "Futuristic Alert",
    id: "futuristic-alert",
    file_1: "futuristic-alert_10sec.wav",
  },
  {
    name: "Heartmonitor",
    id: "heartmonitor",
    file_1: "heartmonitor_10sec.wav",
  },
  {
    name: "Intercom Alert",
    id: "intercom_alert",
    file_1: "intercom_alert_10sec.wav",
  },
  {
    name: "Leave Building",
    id: "leave-building",
    file_1: "leave-building_10sec.wav",
  },
  {
    name: "Old Siren",
    id: "old-siren",
    file_1: "old-siren_10sec.wav",
  },
  {
    name: "Red Alert",
    id: "red-alert",
    file_1: "red-alert_10sec.wav",
  },
  {
    name: "Scifi Shutdown",
    id: "scifi-shutdown",
    file_1: "scifi-shutdown_10sec.wav",
  },
  {
    name: "Signal Alert",
    id: "signal-alert",
    file_1: "signal-alert_10sec.wav",
  },
];
