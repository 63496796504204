import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual } from "react-redux";
import BackWardArrow from "../../components/Buttons/BackwardArrow";
// import forward_arrow_grey from "../../assets/images/forward_arrow_grey.svg";
// import dots_vertical from "../../assets/images/dots_vertical.svg";
import EditBikeName from "./EditBikeName";
import * as devicesService2 from "../../services/devices/devicesService2";
import ErrorDialog from "../LocationHistory/ErrorDialog";
import * as genericService from "../../services/genericService";
import ConfirmDelete from "./ConfirmDelete";
import EditSpeedLimit from "./EditSpeedLimit"
import FlavorImages from "../../assetsFolderImport";

let flavor = process.env.REACT_APP_FLAVOR; //when we have APIs for helkama; remove static value and use flavor from env
const AcceptedShareBike = (props) => {
  const { t } = useTranslation();
  const [showDeleteHistory, setShowDeleteHistory] = useState(false);
  const [bikeDetail, setBikeDetail] = useState([]);
  const [speedLimit,setspeedLimit]=useState("")
  const [selectedbikeName, setselectedBikeName] = useState("")
  const [editBikesepeed, setEditbikeSpeed]=useState(false)
  const [error, setError] = useState("");
  const [bikeSpeed,setBikeSpeed]=useState("")
  const [editdetail, setEditdetail] =useState("")
  const [errorDialog, setShowErrorDialog] = useState(false)
  const [editBike, setEditBikeName] = useState(false);
  const [pinSelected, setMapPinSelected] = useState("");
  const [iconName, setIconName] = useState("caravan");
  const [errorMesage, setErrorMesage] = useState("")
  const { devicesState } = useSelector(
    (state) => ({ devicesState: state.devices }),
    shallowEqual
  );
  const removeHistory = async () => {
    const response = await genericService.post("history/delete", {
      deviceid: bikeDetail.id,
    });
    if (response.status === 200) {
      setShowDeleteHistory(false);
    } else {
      setShowErrorDialog(response.data.message)
      setShowDeleteHistory(false);
    }
  };
  const deviceDetail = () => {
    const devicesSharedWithme = devicesState.devices.filter((device) => device.sharedstatus.sharedwithme)
    devicesSharedWithme.map((device) => {
      if (device.name === props.deviceName) {
        setselectedBikeName(device.name)
        setBikeSpeed(device.modelspecificinfo?.speedlimit
          .speedlimitinfo)
        setBikeDetail(device);
        props.setBikeDetail(device)
        let icon_splitted = device.mapicon.split("_");
        let iconMain = ["airplane", "ambulance", "bicycle", "boat", "bus", "car", "caravan", "container", "electricCar", "engine",
        "excavator", "forklift", "jet", "monsterTruck", "moped", "motorcycle", "pickup",
        "rv", "sailboat", "snowmobile", "default", "taxi", "tractor", "truck", "truckContainer", "van"]
        if(iconMain.includes(icon_splitted[0]))
        {
          setIconName(icon_splitted[0])
          setMapPinSelected(icon_splitted[1])
        }
      }
    });
  };
  const setEditBikeSpeedPopup=(speedLimit)=>
  {
    setspeedLimit(speedLimit)
    setEditbikeSpeed(true)
  }
  const handleIconChange = async (iconName) => {
    setIconName(iconName);
    let fullIconName = iconName + "_" + pinSelected;
    let data = {
      mapicon: fullIconName,
      deviceid: bikeDetail.id,
    };
    const response = await devicesService2.updateDevice(data);
    if (response.status === 200) {
    } else {
    }
  };
  const setMapPins = async (event) => {
    setMapPinSelected(event.target.alt);
    let fullIconName = iconName + "_" + event.target.alt;
    let data = {
      mapicon: fullIconName,
      deviceid: bikeDetail.id,
    };
    const response = await devicesService2.updateDevice(data);
    if (response.status === 200) {
    } else {
    }
  };
  useEffect(() => {
    deviceDetail();
  }, []);

  return (
    <>
      <div
        className={`${props.showUI === true ? "show_ui" : "hide_ui"
          } col-md-8 d-flex  mt-3 flex-column inner `}
        >
        <BackWardArrow show={true} onClick={() => props.setSelect("Bikes")} />
        <label className="ml-1 mt-4 text-uppercase text_16_700 text-left mt-2">
          {selectedbikeName}
        </label>

      <div className="alarm_list_shadow">
        <div className="d-flex flex-column rounded-top ">
          <div
            className="p-3 d-flex cursor-pointer border-bottom justify-content-between align-items-center rounded-top"
            style={{ background: "#ffffff", width: "100%" }}
            onClick={() => setEditBikeName(true)}
          >
            <label className=" text_16_400 text-navy my-0">
              {t("signup.Name")}{" "}
            </label>
            <div>
              <label className=" text_12_500 text-navy my-0">
                {selectedbikeName}{" "}
              </label>
              <img
                onClick={() => setEditBikeName(true)}
                src={FlavorImages.dots_vertical}
                className="size_15 ml-2 cursor-pointer"
                alt="..."
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column   ">
          <div className="p-3 w-100 d-flex border-bottom justify-content-between align-items-center"
           style={{ background: "#ffffff", width: "100%" }}
           >
            <label className=" text_16_400 text-navy my-0">
              {t("Device.IMEI")}{" "}
            </label>
            <div>
              <label className=" text_12_500 text-navy my-0">
                {bikeDetail.uniqueid}{" "}
              </label>
            </div>
          </div>
        </div>
        <div className={`${flavor === "Bike" ? "d-none" : "d-flex flex-column"}`}>
          <div className="p-3 w-100 d-flex border-bottom justify-content-between align-items-center"
            style={{ background: "#ffffff", width: "100%" }}
            >
            <label className=" text_16_400 text-navy my-0">
              {t("Device.internalpower")}{" "}
            </label>
            <div>
              <label className=" text_12_500 text-navy my-0">
                {bikeDetail.modelspecificinfo?.internalbattery?.internalbatteryinfo}{" %"}
              </label>
            </div>
          </div>
        </div>
        <div 
        // onClick={() => props.setSelect("SharingStatus")} 
        className=" cursor-pointer p-3  w-100  d-flex  border-bottom white_shadow justify-content-between align-items-center">
          <label className=" text_16_400 text-navy my-0">
              {t("Device.SpeedLimit")}{" "}
            </label>

            <div>
            {bikeDetail?.modelspecificinfo?.speedlimit.speedlimitvalid &&
              <label className=" text_12_500 text-navy my-0">
              {
                       bikeSpeed
                        }{" "}
                        {
                          bikeDetail?.modelspecificinfo?.speedlimit
                            .speedlimitunit
                        }{" "}
              </label>    
              }
              <img
                onClick={() =>setEditBikeSpeedPopup(bikeDetail?.modelspecificinfo?.speedlimit) }
                src={FlavorImages.dots_vertical}
                className="size_15 ml-2"
                alt="..."
              />
            </div>
        </div> 
        {process.env.REACT_APP_FLAVOR === "Bike"
        ? ""
        :<div 
          onClick={() => setShowDeleteHistory(true)}
          className="cursor-pointer p-3  w-100  d-flex  border-bottom white_shadow justify-content-between align-items-center">
            <label className=" text_16_400  my-0">{t("Settings.DeleteHistory")} </label>

            <img
              onClick={() => setShowDeleteHistory(true)}
              src={FlavorImages.forward_arrow_grey}
              className="size_15"
              alt="..."
            />
          </div>
        }
        <div
          onClick={() => props.setSelect("DeleteShareBike")}
          className="p-3 cursor-pointer d-flex border-bottom justify-content-between align-items-center rounded-bottom"
          style={{ background: "#ffffff", width: "100%" }}
        >
          <label className=" text_16_400 text-red my-0">
            {t("bikeDetail.removeBike")}{" "}
          </label>

          <img
            onClick={() => props.setSelect("DeleteShareBike")}
            src={FlavorImages.forward_arrow_grey}
            className="size_15"
            alt="..."
          />
        </div>
        </div>

        <div className={`${flavor === "Bike" ? "" : "d-none"}`}>

      <label className="ml-1 text-uppercase text_11_300 text-left mt-5">
        {t("bikeDetail.batteryHealth")}
      </label>
      <div className="d-flex flex-column   ">
        <div className="p-3 w-100   d-flex  border-bottom white_shadow rounded-top justify-content-between align-items-center">
          <label className=" text_16_400 text-navy my-0">
            {t("bikeDetail.BikeBattery")}{" "}
          </label>

          <div>
            {bikeDetail.communicationmode?.vehiclebattery.valid &&
              bikeDetail.communicationmode?.vehiclebattery.info !== null ? (
              <label className=" text_12_500 text-navy my-0">
                {bikeDetail.communicationmode?.vehiclebattery.info + " %"}
              </label>
            ) : !bikeDetail.communicationmode?.vehiclebattery.valid ? (
              <label className=" text_12_500 text-navy my-0">
                {t("bikeDetail.zeroPercent")}
              </label>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <div className="d-flex flex-column">
            <div className="p-3 w-100 d-flex rounded-bottom white_shadow justify-content-between align-items-center">
              <label className=" text_16_400 text-navy my-0">
                {t("bikeDetail.GpsBattery")}{" "}
              </label>

              <div>
                {bikeDetail?.modelspecificinfo?.internalbattery.internalbatteryvalid &&
                  bikeDetail?.modelspecificinfo?.internalbattery.internalbatteryinfo !== null ? (
                  <label className=" text_12_500 text-navy my-0">
                    {bikeDetail?.modelspecificinfo?.internalbattery.internalbatteryinfo + " %"}
                  </label>
                ) : bikeDetail?.modelspecificinfo?.internalbattery.internalbatteryvalid ? (
                  <label className=" text_12_500 text-navy my-0">
                    {t("errors.Error")}
                  </label>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

{/* <div className="d-flex flex-column">
  <div className="p-3 w-100   d-flex  border-bottom white_shadow  justify-content-between align-items-center">
    <label className=" text_16_400 text-navy my-0">
      {t("bikeDetail.GpsBattery")}{" "}
    </label>

    <div>
      {bikeDetail?.modelspecificinfo?.internalbattery.internalbatteryvalid &&
        bikeDetail?.modelspecificinfo?.internalbattery.internalbatteryinfo !== null ? (
        <label className=" text_12_500 text-navy my-0">
          {bikeDetail?.modelspecificinfo?.internalbattery.internalbatteryinfo + " %"}
        </label>
      ) : bikeDetail?.modelspecificinfo?.internalbattery.internalbatteryvalid ? (
        <label className=" text_12_500 text-navy my-0">
          {t("errors.Error")}
        </label>
      ) : (
        ""
      )}
    </div>
  </div>
</div> */}

{/* <div className="d-flex flex-column  ">
  <div className="p-3  w-100  d-flex   white_shadow rounded-bottom justify-content-between align-items-center">
    <label className=" text_16_400 text-navy my-0">
      {t("bikeDetail.totalChargeCycles")}{" "}
    </label>

    <div>
      {bikeDetail.batteryhealth &&
        bikeDetail.batteryhealth.totalchargecycles !== null ? (
        <label className=" text_12_500 text-navy my-0">
          {bikeDetail.batteryhealth.totalchargecycles}
        </label>
      ) : bikeDetail.batteryhealth ? (
        <label className=" text_12_500 text-navy my-0">{"0"}</label>
      ) : (
        ""
      )}
    </div>
  </div>
</div> */}

</div>

        {flavor === "Tracking" || flavor === "Tracking"
          ?
        <div className="d-flex scroll_bar_device_icon flex-column mt-3 p-1 w-100">
          <label className="ml-1 text-uppercase text_11_300 text-left mt-2">
            {t("Map Icon")}{" "}
          </label>
          <div className="d-flex justify-content-center w-100">
          <img
              onClick={(event) => setMapPins(event)}
              className={`map_icon_selection ${pinSelected === "blue" ? "width_40 " : "width_icon_50"
                }`}
              alt="blue"
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/blue_pins/svg/${iconName}_blue.svg`).default}
            />
            <img
              onClick={(event) => setMapPins(event)}
              className={`map_icon_selection ${pinSelected === "dark" ? "width_40 " : "width_icon_50"
                }`}
              alt="dark"
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/dark_pins/svg/${iconName}_dark.svg`).default}
            />
            <img
              onClick={(event) => setMapPins(event)}
              className={`map_icon_selection ${pinSelected === "green" ? "width_40 " : "width_icon_50"
                }`}
              alt="green"
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/green_pins/svg/${iconName}_green.svg`).default}
            />
            {/* <img
              onClick={(event) => setMapPins(event)}
              className={`map_icon_selection ${pinSelected === "grey" ? "width_40 " : "width_icon_50"
                }`}
              alt="grey"
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/grey_pins/svg/${iconName}_grey.svg`}
            /> */}
            <img
              onClick={(event) => setMapPins(event)}
              className={`map_icon_selection ${pinSelected === "light" ? "width_40 " : "width_icon_50"
                }`}
              alt="light"
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/light_pins/svg/${iconName}_light.svg`).default}
            />
            <img
              onClick={(event) => setMapPins(event)}
              className={`map_icon_selection ${pinSelected === "orange" ? "width_40 " : "width_icon_50"
                }`}
              alt="orange"
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/orange_pins/svg/${iconName}_orange.svg`).default}
            />
            <img
              onClick={(event) => setMapPins(event)}
              className={`map_icon_selection ${pinSelected === "yellow" ? "width_40 " : "width_icon_50"
                }`}
              alt="yellow"
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/yellow_pins/svg/${iconName}_yellow.svg`).default}
            />
          </div>

          <div
            className={`d-flex justify-content-start  w-50 my-2`}
            style={{ overflowX: "scroll", marginLeft: "150px" }}
          >
            <img
            onClick={() => handleIconChange("default")}
            alt="Select icon"
            className={`map_icon_selection_2 ml-2 ${iconName === "default" ? "width_40 mb-1" : "width_icon_50"
              }`}
            src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/default.svg`).default}
            />
            <img
              onClick={() => handleIconChange("airplane")}
              alt="Select icon"
              className={`map_icon_selection_2 ml-2 ${iconName === "airplane" ? "width_40 mb-1" : "width_icon_50"
                }`}
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/airplane.svg`).default}
            />
            <img
              onClick={() => handleIconChange("ambulance")}
              alt="Select icon"
              className={`map_icon_selection_2 ml-2 ${iconName === "ambulance" ? "width_40 mb-1" : "width_icon_50"
                }`}
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/ambulance.svg`).default}
            />

            
            <img
              onClick={() => handleIconChange("bicycle")}
              alt="Select icon"
              className={`map_icon_selection_2 ml-2 ${iconName === "bicycle" ? "width_40 mb-1" : "width_icon_50"
                }`}
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/bicycle.svg`).default}
            />
            <img
              onClick={() => handleIconChange("boat")}
              alt="Select icon"
              className={`map_icon_selection_2 ml-2 ${iconName === "boat" ? "width_40 mb-1" : "width_icon_50"
                }`}
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/boat.svg`).default}
            />
            <img
              onClick={() => handleIconChange("bus")}
              alt="Select icon"
              className={`map_icon_selection_2 ml-2 ${iconName === "bus" ? "width_40 mb-1" : "width_icon_50"
                }`}
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/bus.svg`).default}
            />
            <img
              onClick={() => handleIconChange("car")}
              alt="Select icon"
              className={`map_icon_selection_2 ml-2 ${iconName === "car" ? "width_40 mb-1" : "width_icon_50"
                }`}
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/car.svg`).default}
            />
            <img
              onClick={() => handleIconChange("electriccar")}
              alt="Select icon"
              className={`map_icon_selection_2 ml-2 ${iconName === "electriccar" ? "width_40 mb-1" : "width_icon_50"
                }`}
              // src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/electriccar.svg`).default}
            />
            <img
              onClick={() => handleIconChange("taxi")}
              alt="Select icon"
              className={`map_icon_selection_2 ml-2 ${iconName === "taxi" ? "width_40 mb-1" : "width_icon_50"
                }`}
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/taxi.svg`).default}
            />
            <img
              onClick={() => handleIconChange("caravan")}
              alt="Select icon"
              className={`map_icon_selection_2 ml-2 ${iconName === "caravan" ? "width_40 mb-1" : "width_icon_50"
                }`}
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/caravan.svg`).default}
            />
            <img
              onClick={() => handleIconChange("container")}
              alt="Select icon"
              className={`map_icon_selection_2 ml-2 ${iconName === "container" ? "width_40 mb-1" : "width_icon_50"
                }`}
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/container.svg`).default}
            />
            <img
              onClick={() => handleIconChange("engine")}
              alt="Select icon"
              className={`map_icon_selection_2 ml-2 ${iconName === "engine" ? "width_40 mb-1" : "width_icon_50"
                }`}
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/engine.svg`).default}
            />
            <img
              onClick={() => handleIconChange("excavator")}
              alt="Select icon"
              className={`map_icon_selection_2 ml-2 ${iconName === "excavator" ? "width_40 mb-1" : "width_icon_50"
                }`}
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/excavator.svg`).default}
            />
            <img
              onClick={() => handleIconChange("forklift")}
              alt="Select icon"
              className={`map_icon_selection_2 ml-2 ${iconName === "forklift" ? "width_40 mb-1" : "width_icon_50"
                }`}
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/forklift.svg`).default}
            />
            <img
              onClick={() => handleIconChange("helicopter")}
              alt="Select icon"
              className={`map_icon_selection_2 ml-2 ${iconName === "helicopter" ? "width_40 mb-1" : "width_icon_50"
                }`}
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/helicopter.svg`).default}
            />
            <img
              onClick={() => handleIconChange("jet")}
              alt="Select icon"
              className={`map_icon_selection_2 ml-2 ${iconName === "jet" ? "width_40 mb-1" : "width_icon_50"
                }`}
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/jet.svg`).default}
            />
            <img
              onClick={() => handleIconChange("monstertruck")}
              alt="Select icon"
              className={`map_icon_selection_2 ml-2 ${iconName === "monstertruck" ? "width_40 mb-1" : "width_icon_50"
                }`}
              // src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/monstertruck.svg`).default}
            />
            <img
              onClick={() => handleIconChange("moped")}
              alt="Select icon"
              className={`map_icon_selection_2 ml-2 ${iconName === "moped" ? "width_40 mb-1" : "width_icon_50"
                }`}
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/moped.svg`).default}
            />
            <img
              onClick={() => handleIconChange("motorcycle")}
              alt="Select icon"
              className={`map_icon_selection_2 ml-2 ${iconName === "motorcycle" ? "width_40 mb-1" : "width_icon_50"
                }`}
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/motorcycle.svg`).default}
            />
            <img
              onClick={() => handleIconChange("pickup")}
              alt="Select icon"
              className={`map_icon_selection_2 ml-2 ${iconName === "pickup" ? "width_40 mb-1" : "width_icon_50"
                }`}
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/pickup.svg`).default}
            />
            <img
              onClick={() => handleIconChange("rv")}
              alt="Select icon"
              className={`map_icon_selection_2 ml-2 ${iconName === "rv" ? "width_40 mb-1" : "width_icon_50"
                }`}
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/rv.svg`).default}
            />
            <img
              onClick={() => handleIconChange("sailboat")}
              alt="Select icon"
              className={`map_icon_selection_2 ml-2 ${iconName === "sailboat" ? "width_40 mb-1" : "width_icon_50"
                }`}
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/sailboat.svg`).default}
            />
            <img
              onClick={() => handleIconChange("snowmobile")}
              alt="Select icon"
              className={`map_icon_selection_2 ml-2 ${iconName === "snowmobile" ? "width_40 mb-1" : "width_icon_50"
                }`}
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/snowmobile.svg`).default}
            />
            <img
              onClick={() => handleIconChange("tractor")}
              alt="Select icon"
              className={`map_icon_selection_2 ml-2 ${iconName === "tractor" ? "width_40 mb-1" : "width_icon_50"
                }`}
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/tractor.svg`).default}
            />
            <img
              onClick={() => handleIconChange("truckcontainer")}
              alt="Select icon"
              className={`map_icon_selection_2 ml-2 ${iconName === "truckcontainer" ? "width_40 mb-1" : "width_icon_50"
                }`}
              // src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/truckcontainer.svg`).default}
            />
            <img
              onClick={() => handleIconChange("truck")}
              alt="Select icon"
              className={`map_icon_selection_2 ml-2 ${iconName === "truck" ? "width_40 mb-1" : "width_icon_50"
                }`}
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/truck.svg`).default}
            />
            <img
              onClick={() => handleIconChange("van")}
              alt="Select icon"
              className={`map_icon_selection_2 ml-2 ${iconName === "van" ? "width_40 mb-1" : "width_icon_50"
                }`}
              src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/van.svg`).default}
            />
            </div>
          </div>
                :
                ""}
      </div>

      <EditBikeName
        setShowErrorDialog={() => setShowErrorDialog(true)
        }
        setErrorMesage={(msg) => setErrorMesage(msg)}
        closeditBikeNameDialog={() => setEditBikeName(false)} 
        setBikeName={(data) => setselectedBikeName(data)}
        show={editBike}
        deviceName={bikeDetail.name}
      />
         <ErrorDialog
                    showErrorDialog={errorDialog}
                    select={"Device"}
                    errorMesage={errorDialog}
                    onClose={() => setShowErrorDialog(false)}
                  />
            <ConfirmDelete
        show={showDeleteHistory}
        closeConfirmDeleteDialog={() => setShowDeleteHistory(false)}
        select={"bikedetail"}
        confirmBodyText={t("ConfirmDelete.confirmTiltsix")}
        confirmBtnText={t("ConfirmDelete.DeleteBike")}
        onConfirm={() => removeHistory()}
      />
    </>
  );
};

export default AcceptedShareBike;
