import { useRef, useState, useEffect } from "react";
import "./segmentStyles.css";

/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-a-segmented-control-component
 */
const SegmentedControl = ({
  powerSaveValue,
  name,
  segments,
  callback,
  segmentWidth,
}) => {
  const componentReady = useRef();
  // Determine when the component is "ready"
  useEffect(() => {
    componentReady.current = true;
    // console.log(powerSaveValue);
  }, []);

    const onInputChange = (name, value) => {
        callback(value);
    };

  return (
    <div className="controls-container col-12 px-0">
      <div
        className={`controls ${
          componentReady.current ? "ready" : "idle"
        } col-12`}
      >
        {segments?.map((item, i) => (
          <div
            key={item.value}
            className={`segment ${
              item.value === powerSaveValue ? "active" : "inactive"
            } col`}
            ref={item.ref}
          >
             <label className="col-12 px-0" htmlFor={item.label} 
            >
            <input
              type="radio"
              value={item.value}
              id={name}
              name={name}
              onChange={() => onInputChange(name, item.value)}
              checked={item.value === powerSaveValue}
            />
           
              {item.label}{" "}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SegmentedControl;
