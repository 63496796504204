/*
 * @Author: Abhi Bhatt
 * @Date:   2020-12-23 14:12:09
 * @Last Modified by:   abhi
 * @Last Modified time: 2021-04-14 12:41:01
 */
import React from "react";
// import checked from "../../assets/images/checked.svg";
// import check_empty_light from "../../assets/images/check_empty_light.svg";
import FlavorImages from "../../assetsFolderImport";
const DeviceSelectionRow = (props) => {
  return (
    <React.Fragment>
      <div className="d-flex flex-column w-100 ">
        <div
          className="d-flex align-items-center  justify-content-between w-100 height_50 px-3 "
          onClick={() => props.onClick()}
        >
          <label className="text_12_700 text-navy m-0  ">{props.text}</label>
          {props.isSelected ? (
            <img className="size_20 mr-2" src={FlavorImages.checked} />
          ) : (
            <img className="size_20 mr-2" src={FlavorImages.check_empty_light} />
          )}
        </div>

        {props.hideLine === false ? <div className="grey_line " /> : null}
      </div>
    </React.Fragment>
  );
};

export default DeviceSelectionRow;
