import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import React, { useState, useEffect, useRef } from "react";
// import chevron_circle_down from "../../assets/images/chevron-circle-down.svg";
import _ from "lodash";
import lottie from "lottie-web/build/player/lottie_light";
// import challenge_completed from "../../assets/lotties/challenge_completed.json";
// import challenge_Failed from "../../assets/lotties/challenge_cancelled_or_failed.json"
import { formatDistance } from "date-fns";
// import fire_alt from "../../assets/images/fire-alt.svg";
// import km from "../../assets/images/KM.svg";
import * as genericService from "../../services/genericService";
// import default_avatar from "../../assets/images/default_awatar.svg";
// import dots_vertical from "../../assets/images/dots_vertical.svg";
import GenericConfirmPopuptwo from "./GenericConfirmPopuptwo";
// import biking from "../../assets/Helkama_images/biking.svg";
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import FlavorImages from "../../assetsFolderImport";
import FlavorLottie from "../../lottiesFilesImport";
const RunningChallengeCard = (props) => {
    const [downArrowClick, setDownArrowClick] = useState(false)
    const achievedChallengValueRef = React.useRef(props.challenge.currentvalue);
    const anime = useRef(null);
    const [confirmDeleteEditbox, setconfirmDeleteEditbox] = useState(false)
    const [selected, setselected] = useState('')
    const [showConfirmPop, setshowConfirmPop] = useState(false)
    const [showLeavePop, setshowLeavePop] = useState(false)

    const animationContainer = useRef(null);
    const setachievedChallengValue = (x) => {
        achievedChallengValueRef.current = _.cloneDeep(x);
    };
    const { t } = useTranslation();
    const [friendProgress, setfriendProgress] = useState(0)
    const [imagewidthProgress, setimagewidthProgress] = useState(0)
    const [participantID, setParticipantID] = useState(false)
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#4FAADB' : '#308fe8',
        },
    }));
    const getFriendAchievement = (value, id, pending) => {
        if (pending == 1) return
        let checkprogess = 0;
        setfriendProgress(0);
        if (id === participantID) {
            let newValue = (props.challenge.currentvalue / props.challenge.goal) * 100
            setachievedChallengValue(props.challenge.currentvalue)
            setParticipantID("")
            if (newValue >= 50)
                setimagewidthProgress((newValue / 100) * 100 - 18)
            else if (30 > newValue < 50) {
                setimagewidthProgress((newValue / 100) * 100 - 10)
            }
            else if (newValue < 30) {
                setimagewidthProgress((newValue / 100) * 100 - 8)
            }
            else if (newValue > 100) {
                setimagewidthProgress((newValue / 100) * 100 - 8)
            }
            setfriendProgress(0)
        }
        else {
            let newValue = (value / props.challenge.goal) * 100
            setachievedChallengValue(value)

            const interval = setInterval(() => {
                if (checkprogess >= newValue) {
                    clearInterval(interval);
                } else {
                    checkprogess = checkprogess + 10
                    setfriendProgress((prevProgress) => prevProgress + 10);
                }
            }, 50);
            setParticipantID(id)
            if (newValue >= 50) {
                setimagewidthProgress((newValue / 100) * 100 - 18)
            }
            else if (30 > newValue < 50) {
                setimagewidthProgress((newValue / 100) * 100 - 10)
            }
            else if (newValue <= 30) {
                setimagewidthProgress((newValue / 100) * 100 - 5)
            }
        }

    }

    const onDeleteChalllenge = async (value) => {
        let deleteChallengeresponse = await genericService.post(`/challenges/deleteleave`,
            {
                challengeid: value,
            });
        if (deleteChallengeresponse.status === 200 || deleteChallengeresponse.status === 201) {
            setshowConfirmPop(false)
            props.refreshChallenges()
        }
    }
    useEffect(() => {
        let achievedgoal = props.challenge.currentvalue / props.challenge.goal * 100
        if (achievedgoal >= 100) {
            setimagewidthProgress(achievedgoal - 20)
        }
        else {
            setimagewidthProgress(achievedgoal - 18)
        }
        setachievedChallengValue(props.challenge.currentvalue)
    }
        , [props.challenge.currentvalue]);
    const setLevelUpanimation = (status) => {
        if (animationContainer.current) {
            anime.current = lottie.loadAnimation({
                container: animationContainer.current,
                renderer: "svg",
                loop: true,
                autoplay: true,
                animationData: status === "failed" ? FlavorLottie.challenge_Failed : FlavorLottie.challenge_completed,
            });
        }
    }
    useEffect(() => {
        if (props.challenge.challengestatus === "active") return
        setLevelUpanimation(props.challenge.challengestatus)
    }
        , [props.challenge.challengestatus]);
    const BorderLinearProgressTransparent = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.mode === 'light' ? '#DDDDDD' : '#DDDDDD',
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? ' #393939' : ' #393939',
        },
    }));
    return (
        <div className="card running_challenge border-0 pt-1 px-0 mt-2 mb-3 small_shadow">
            {props.challenge.challengestatus === "active" ?
                <>
                    <div className="row padding_x_40_29 d-flex justify-content-between pt-2">
                        <div>
                            <div className="row">
                                <img
                                    className="mr-2 size_35_40 "
                                    src={props.challenge.type === "km" 
                                    ? FlavorImages.km
                                    : FlavorImages.fire_alt
                                    }
                                    alt={"...No image"}
                                />
                                <div className="d-flex flex-column mt-2">
                                    <label className="text_16_700 font_700_gloria line_height_5">{props.challenge?.name}</label>
                                    <label className="text_12_400 font_400_gloria">
                                    {props.challenge.type === "km" 
                                    ? "Distance challenge"
                                    : "Calorie burn challenge"
                                    }</label>
                                </div>
                            </div>
                        </div>
                        {props.adminCheck ?
                            <img
                                className="mr-2 cursor-pointer size_25_20 border_36"
                                src={FlavorImages.dots_vertical}
                                onClick={() => {
                                    setshowConfirmPop(true)
                                    setselected(props.challenge)
                                }
                                }
                                alt={"...No image"}
                            /> :
                            <img
                                className="mr-2 cursor-pointer size_25_20 border_36"
                                src={FlavorImages.dots_vertical}
                                onClick={() => {
                                    setshowLeavePop(true)
                                    setselected(props.challenge)
                                }
                                }
                                alt={"...No image"}
                            />
                            }
                    </div>
                    <div className="row padding_x_29">
                        <label className="text_12_400 font_400_gloria">
                            {props.challenge.type === "km" 
                                ? 
                                <>
                                {t("Medal.Distance")} <span className="font-weight-bold font_700_gloria"> “{props.challenge?.adminusername}”</span> {t("Medal.CreatedChallenge")} <span className="font-weight-bold font_700_gloria"> “{props.challenge?.name}”</span >. {t("Medal.goalthistime")} <span className="font-weight-bold font_700_gloria"> {props.challenge?.goal} {t("Medal.km")} </span> {t("Medal.totaltime")} <span className="font-weight-bold font_700_gloria">
                                {formatDistance(new Date(props.challenge.starttime),
                                new Date(props.challenge.stoptime),
                                { addSuffix: false })}</span> {t("Medal.todoit")}<br /><span className="text_16_700 font_700_gloria"> {t("Medal.getbiking")}</span>
                                </>
                                : 
                                <>
                                {t("Medal.CalorieBurn")} <span className="font-weight-bold font_700_gloria"> “{props.challenge?.adminusername}”</span> {t("Medal.CreatedChallenge")} <span className="font-weight-bold font_700_gloria"> “{props.challenge?.name}”</span >. {t("Medal.goalthistime")} <span className="font-weight-bold font_700_gloria"> {props.challenge?.goal} {t("Medal.kcal")} </span> {t("Medal.totaltime")} <span className="font-weight-bold font_700_gloria">
                                {formatDistance(new Date(props.challenge.starttime),
                                new Date(props.challenge.stoptime),
                                { addSuffix: false })}</span> {t("Medal.todoit")}<br /><span className="text_16_700 font_700_gloria"> {t("Medal.getbiking")}</span>
                                </>
                                }
                            
                        </label>
                    </div>
                    <Accordion
                        className={`shadow-none bg-white   running_challenge mb-0 mt-0 ${props.index % 2 == 0 ? "index_odd_friend" : "index_even_friends"}`}  >
                        <div className="bg-white">
                            <AccordionSummary
                                onClick={() => setDownArrowClick(!downArrowClick)}
                                className=" bg-white padding_x_29  align-items-center px-0 mx-3 " aria-controls="panel1a-content" style={{ width: "140px" }}>
                                <label
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                    className="text_16_700 font_700_gloria mr-2 mb-0  pb-0"> {t("Medal.Participants")} {props.challenge?.participants.length}</label>
                                <img
                                    className={`mb-1 cursor-pointer border_36 
                               ${downArrowClick ? "rotate_btn_maximize" : "rotate_btn_minmize"}
                               `}
                                    src={FlavorImages.chevron_circle_down}
                                    alt={"...No image"}
                                />
                            </AccordionSummary>
                        </div>
                        <AccordionDetails
                            className={`p-0`}>
                            <div className="border-top legend_even alarm_list_shadow">
                                {props.challenge?.participants.map((participant, index) => {
                                    return (
                                        <div className={` ${participantID == participant.userid ? "bg-black2" : "stripRow"}`}
                                            onClick={() => getFriendAchievement(participant.value, participant.userid, participant.pending)}

                                        >
                                            <div className={`card border-0 py-2 px-2  cursor-pointer rounded-0  ${participantID == participant.userid ? "bg-black2" : ""}`}>
                                                <div className={`shadow-none h-100 d-flex ${participant.pending === 1 ? "opacity_05" : ''}`}>
                                                    <div className="col-sm-8 shadow-none d-flex align-items-center">
                                                        <img
                                                            className=" border_36"
                                                            src={participant.userimage ? participant.userimage : FlavorImages.default_avatar}
                                                            style={{ width: "40px", height: "40px" }}
                                                            alt={"...No image"}
                                                        />
                                                        <label className={` ${participantID == participant.userid ? "text-white" : ""} cursor-pointer  text_16_700 mt-2 font_700_gloria ml-3`}>{participant.username}<br />

                                                            {participant.pending == 1 ?
                                                                <span className={` text-danger text_13_300 font_300_gloria`}>{'Awaiting confimation'}</span>
                                                                :
                                                                <span className={`${participantID == participant.userid ? "text-white" : ""} cursor-pointer text_13_300 font_300_gloria`}>{participant.devicename ? participant.devicename : " "}</span>}</label>
                                                    </div>
                                                    <div className="col-sm-4 mt-2 shadow-none d-flex justify-content-end">
                                                        <label className={`text_16_700 font_700_gloria  text-center ml-3 line_height_2 cursor-pointer ${participantID == participant.userid ? "text-white" : ""}`}>{participant.value}<br /> <span className={`text_13_300 ${participantID == participant.userid ? "text-white" : ""}`}>{props.challenge.type === "km" ? t("Medal.km") : t("Medal.kcal")}</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                        </AccordionDetails>
                    </Accordion>
                </>
                : <>
                    <div className="lottie_section">
                        <div
                            className=" size_150 "
                            ref={animationContainer}></div>
                    </div>
                    <div className="row mt-3 padding_x_40_29 d-flex justify-content-center">
                        <div className="text-center text_19_700">
                            {props.challenge.challengestatus === "failed" ?
                                <label className="text_17_700 " >{t("Medal.textUHOH")} <br /> <span className="text_22_700">{t("Medal.Challengefailedorcancelled")}</span> <br /> {t("Medal.Bettertryagain")}  </label>
                                :
                                <label className="text_17_700 " >{t("Medal.youthaveCompleted")} <br /> <span className="text_22_700">{props.challenge.name}</span> <br /> {t("Medal.greatework")}  </label>
                            }
                        </div>
                    </div>
                </>
            }
            <div className="row py-2 padding_x_29">
                {props.challenge.challengestatus === "failed" || props.challenge.challengestatus === "succeeded" ? "" :
                    <label className="text_12_500"> {formatDistance(new Date(props.challenge.starttime),
                        new Date(props.challenge.stoptime),
                        { addSuffix: false })} {t("Medal.remaining")}</label>}
                <BorderLinearProgress
                    style={{ width: "700px", height: '30px', }}
                    className={`${props.challenge.challengestatus == "failed" ? "bg-danger" : ""}`}
                    variant="determinate" value={((props.challenge.currentvalue / props.challenge.goal) * 100) - 5} />
                <BorderLinearProgressTransparent
                    style={{ width: `700px`, height: '30px', background: "transparent", marginTop: "-30px" }}
                    variant="determinate" value={friendProgress} />

                {props.challenge.challengestatus == "failed" ? "" :
                    <>
                        {imagewidthProgress > 0 ? <img
                            className="mb-3 tranistionWidth position-absolute size_20"
                            src={FlavorImages.biking}
                            style={{ marginTop: `${props.challenge.challengestatus === "succeeded" ? "6px" : "32px"}`, marginLeft: `${imagewidthProgress}%` }}
                            alt={"...No image"}
                        /> : <img
                            className="mb-3 tranistionWidth position-absolute size_20"
                            src={FlavorImages.biking}
                            style={{ marginTop: "32px", marginLeft: `10px` }}
                            alt={"...No image"}
                        />}
                        {imagewidthProgress > 0 ?
                            <label
                                className="text_12_500 mt-2  tranistionWidth" style={{ marginLeft: `${imagewidthProgress - 10}%`, }}>{achievedChallengValueRef.current}/{props.challenge.goal} {props.challenge.type === "km" ? t("Medal.km") : t("Medal.kcal")}</label> : <label
                                    className="text_12_500 mt-2" style={{ marginLeft: `10px` }}>{achievedChallengValueRef.current.toFixed(1)}/{props.challenge.goal} {props.challenge.type === "km" ? t("Medal.km") : t("Medal.kcal")}</label>}
                    </>}
            </div>
            <GenericConfirmPopuptwo
                mainHeadingText={'Confirm'}
                show={showConfirmPop}
                value={selected}
                firstButtoncolor={'bg-danger'}
                secondButtoncolor={'bg_dark_helkama'}
                onConfirm={() => {
                    props.setCreateEditChallengeshow(selected)
                    setshowConfirmPop(false)
                }}
                onDelete={(value) => onDeleteChalllenge(value)}
                bodyText={'Do you want to delete or edit challenge?'}
                closeDialogBox={() => setshowConfirmPop(false)}
                confirmButtonText={'Delete'}
            />
            <GenericConfirmPopuptwo
                mainHeadingText={'Confirm'}
                show={showLeavePop}
                value={selected}
                firstButtoncolor={'bg-danger'}
                secondButtoncolor={'d-none'}
                onConfirm={() => {
                    props.setCreateEditChallengeshow(selected)
                    setshowLeavePop(false)
                }}
                onDelete={(value) => onDeleteChalllenge(value)}
                bodyText={'Do you want to leave the challenge?'}
                closeDialogBox={() => setshowLeavePop(false)}
                confirmButtonText={'Leave'}
            />
        </div>
    )

}
export default RunningChallengeCard;
