import RenderMap from "./RenderMap";
import React, { useState } from "react";
import { GOOGLE_MAP_KEY } from "../../helpers/constants";
import MapSettings from "./MapSettings";
import MobileMapSettings from "./MobileMapSettings"
import StatisticsGraph from "./StatisticsGraph";
import Slide from '@mui/material/Slide';
import SideBarNew from "../../components/SideBar/SideBarNew";
import { INDEX_URL } from "../../helpers/constants";
import { useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';

const Map = (props) => {
  const [downArrowClick, setDownArrowClick] = useState(false);
  const [firstDownClick, setFirstDownClick] = useState(false)
  const { MobmapType } = useSelector(
    (state) => ({ MobmapType: state.mapSettings.Maptype }),
    shallowEqual
  );
  const [showSettingCard, setShowSettingCard] = useState(false)
  const history = useHistory();
  const onClose = () => {
    setShowSettingCard(!showSettingCard)
    setDownArrowClick(!downArrowClick)
    setFirstDownClick(true)
  }
  return (
    <>
      <div style={{ paddingTop: 1, paddingBottom: 1 }}>
        <SideBarNew
          page="map"
          onClick={(e) => {
            history.push(INDEX_URL + e);

          }}
          onClose={onClose}
        />
        {props.page !== "urlscreen" ? (
          <>
            <div className="d-none d-xl-block d-lg-block">
              <MapSettings
              />
            </div>
          </>
        ) : (
          ""
        )}
        <RenderMap
          mapType={MobmapType}
          screen={props.screen}
          page={props.page ?? ""}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_KEY}&v=3.exp&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          containerElement={
            <div
              className={`alarm_list_shadow ${props.page === "urlscreen" ? "url_screen_map_style" : "map_style"
                }`}
            />
          }
        />
        {(props.page === "locationHistory" || "routes") &&
          process.env.REACT_APP_FLAVOR === "Bike" ? (
          <StatisticsGraph screen={"route"} />
        ) : (
          ""
        )}
      </div>

      <Box style={{ position: "absolute", bottom: 0, width: "100%" }}>
        <div className={`${downArrowClick === false && firstDownClick ? "animate_hight_down_map " :
          firstDownClick && downArrowClick ?
            `animate_hight_up_map` : "height_0_mobile"} `} >
          <div className="d-xs-block d-md-block d-sm-block d-lg-none d-xl-none" >
            <div className="settingCard">
              <MobileMapSettings
                onClose={onClose}
              />
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

export default Map;
