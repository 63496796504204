import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual } from "react-redux";
import BackWardArrow from "../../components/Buttons/BackwardArrow";
import ConfirmDelete from "./ConfirmDelete";
import * as genericService from "../../services/genericService";
import { useDispatch } from "react-redux";
import { devicesSlice } from "../../slice/deviceSlice";
import toast, { Toaster } from 'react-hot-toast';
const DeleteBike = (props) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [bikeDetail, setBikeDetail] = useState([]);
  const [confirmDelete, setConfrimDelete] = useState(false);
  const { devicesState } = useSelector(
    (state) => ({ devicesState: state.devices }),
    shallowEqual
  );
  const deviceActions = devicesSlice.actions;
  
  const getDevicesList = async () => {
    const response = await genericService.get("devices/compactinfo");
    if (response.status === 200) {
      const devices = response.data.devices ?? [];
      const pendingDevices = response.data.pendingSharedDevices ?? [];
      dispatch(
        deviceActions.setUpPendingDevices({
          devices: pendingDevices,
        })
      );
      dispatch(
        deviceActions.setUpDeviceData({
          devices: devices,
        })
      );
    }
  };
  const confirmDeleteBike = async () => {
    let deviceid = [bikeDetail.id];
    const response = await genericService.post("device/delete", {
      deviceid: deviceid,
    });
    if (response.status === 200) {
      setConfrimDelete(false);
      getDevicesList()
      props.setSelect("Bikes")
      toast.success(t("toast.Done"));
    } else {
      props.setError(response.data.message);
      setConfrimDelete(false);
    }
  };
  const deletBike = () => {
    if (
      bikeDetail.dataplanpaymentmethod === "fail" ||
      bikeDetail.dataplanpaymentmethod === "cancelled" ||
      bikeDetail.dataplanpaymentmethod === null || bikeDetail.dataplanpaymentmethod === undefined ||
      bikeDetail.dataplanpaymentmethod === "card_cancel_at_period_end"
    ) {
      setConfrimDelete(true);
    } else {
      props.setSelect("DataPlan");
    }
  };

  const deviceDetail = () => {
    devicesState.devices.map((device) => {
      if (device.name === props.deviceName) {
        setBikeDetail(device);
      }
    });
  };
  useEffect(() => {
    deviceDetail();
  });
  return (
    <>
      <div
        className={`${props.showUI === true ? "show_ui" : "hide_ui"
          } col-md-8 d-flex  mt-3 flex-column  inner`}
      >
        <BackWardArrow show={true} onClick={() => props.setSelect("BikeDetail")} />
        <label className="text_28_700 mt-2">
          {t("bikeDetail.removeBike")}
        </label>
        <label className="ml-1 text-uppercase text_11_300 text-left mt-2">
          {t("DeleteBike.importantinformation")}
        </label>

        <div className="d-flex flex-column  rounded_white ">
          <div className="p-3  w-100  d-flex  justify-content-between align-items-center">
            <p className="text_13_400">
              {process.env.REACT_APP_FLAVOR === "Bike"
              ?
              t("DeleteBike.titleone")
              :
              t("DeleteBike.titleoneDevice")
              }
            </p>
            <div></div>
          </div>
        </div>

        <label className="ml-1 text-uppercase text_11_300 text-left mt-2">
          {t("forgotPassword.Confirm")}
        </label>

        <div className="d-flex cursor-pointer flex-column  rounded_white ">
          <div
            onClick={() => deletBike()}
            className="p-3  w-100 cursor-pointer  d-flex  border-bottom justify-content-center align-items-center"
          >
            <label
              className=" text_16_400 cursor-pointer text-red my-0"
            >
              {t("geofences.Delete")}
            </label>
            <div></div>
          </div>
        </div>
      </div>
      <ConfirmDelete
        onConfirm={() => confirmDeleteBike()}
        select={props.select}
        show={confirmDelete}
        confirmBodyText={t("ConfirmDelete.confirmTilttwo")}
        confirmBtnText={t("ConfirmDelete.DeleteBike")}
        sharedbyme={false}
        closeConfirmDeleteDialog={() => setConfrimDelete(false)}
      />
    </>
  );
};

export default DeleteBike;
