import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import LanguageChanger from "../../components/LanguageChanger/LanguageChanger";
import LanguageChangerMobile from "../../components/LanguageChanger/LanguageChangerMobile";
import helper_functions from "../../helpers/helper_functions";
import { authSlice } from "../../slice/authSlice";
import { alarmsSlice } from "../../slice/alarmsSlice";
// import ham_burger from "../../assets/images/ham_burger.svg";
import { INDEX_URL } from "../../helpers/constants";
// import Side_left_Arrow_white from "../../assets/images/Side_left_Arrow_white.svg";
// import Side_right_Arrow_white from "../../assets/images/Side_right_Arrow_white.svg";
import { useState } from "react";
import * as genericService from "../../services/genericService";
import { useSelector } from "react-redux";
import { shallowEqual } from "react-redux";
import { settingsSlice } from "../../slice/settingsSlice";
import { devicesSlice } from "../../slice/deviceSlice";
import * as devicesService2 from "../../services/devices/devicesService2";
import { useHistory } from "react-router-dom";
import FlavorImages from "../../assetsFolderImport";
const settingsActions = settingsSlice.actions;
const deviceActions = devicesSlice.actions;
const SideBarClassic = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const authAction = authSlice.actions;
    const alarmActions = alarmsSlice.actions;
    const [hideMainMenu, setshowMainMenu] = useState(false);
    const [showLanguageMoble, setShowLanguageMoble] = useState(false)
    const [menuBtnClick, setMenuBtnClick] = useState(false)
    const { alarmState } = useSelector(
        (state) => ({ alarmState: state.alarms }),
        shallowEqual
    );
    const { authState } = useSelector(
        (state) => ({ authState: state.auth }),
        shallowEqual
      )

    const { deviceState } = useSelector(

        (state) => ({
            deviceState: state.devices
        })
    )


    return (
        <React.Fragment>
            <div className={`alignment sidebar_new_classic  col d-md-none  ${props.hideMainMenu ? "slide_right_menu_classic"
                : props.menuBtnClick ?
                    "slide_left_menu_classic" : ""}
                    ${!props.CLASSICHAMBURGER ? "d-none" : "d-flex" }
			 `} id="container">
                <div className="d-flex justify-content-center mt-3" style={{width:"247px"}}>
                        <img
                            src={FlavorImages.app_logo}
                            className="mx-auto logo_img pr-2 cursor-pointer"
                            alt="..."
                        />
                </div>
                <div className="d-flex justify-content-end" >
                    <div
                    onClick={()=>setShowLanguageMoble(true)}

                        className={`sid_bar_bg_lang mr-3 mb-2 cursor-pointer  ${props.page === "Devices" ? "bg-navy" : ""}  margin_top_55 `}
                    >
                        <span className={`  pr-2  text-12-500`}>{authState.activeLanguage}</span>
                    </div>
                </div>

                <div
                    className={`sid_bar_bg_classic cursor-pointer  ${props.page === "map" ? "bg-navy" : ""}  margin_top_55 `}
                    onClick={() => props.iconClick("map")}
                >
                    <img
                        src={props.page === "map" ?
                            process.env.REACT_APP_BASE_URL +
                            "images/sidebar/active_arrow.svg"
                            :
                            process.env.REACT_APP_BASE_URL +
                            "images/sidebar/up_arrow_active.svg"
                        }
                        className="side_bar_icon_classic  cursor-pointer "
                        alt="..."
                    />
                    <span className={`  ${props.page === "map" ? "text-white" : ""} pr-2  text_sideBar_Mobile_classic`}>{(" Map")}</span>
                </div>

                <div
                    className={`sid_bar_bg_classic cursor-pointer  ${props.page === "Devices" ? "bg-navy" : ""}  margin_top_55 `}
                    onClick={() => props.iconClick("devices")}
                >
                    <img
                        src={props.page === "Devices" ?
                            process.env.REACT_APP_BASE_URL +
                            "images/sidebar/device_inactive.svg"
                            :
                            process.env.REACT_APP_BASE_URL +
                            "images/sidebar/device_active.svg"
                        }
                        className="side_bar_icon_classic d-md-none cursor-pointer d-sm-inline"
                        alt="..."
                    />
                    <span className={`  ${props.page === "Devices" ? "text-white" : ""} pr-2  text_sideBar_Mobile_classic`}>{(" My Devices")}</span>
                </div>

                <div
                    onClick={() => props.iconClick("geofence")}
                    className={`sid_bar_bg_classic  mt-1 ${props.page === "Geofence" ? "bg-navy" : ""}  cornerBefore `}>
                    <img
                        src={
                            props.page === "Geofence" ? process.env.REACT_APP_BASE_URL +
                                "images/sidebar/geofence_inactive.svg" :
                                process.env.REACT_APP_BASE_URL +
                                "images/sidebar/geofence_active.svg"
                        }

                        className="side_bar_icon  cursor-pointer "
                        alt="..."
                    />
                    <span className={` ${props.page === "Geofence" ? "text-white" : ""} pr-2 text_sideBar_Mobile_classic`}>{("Geofence")}</span>
                </div>
                <div
                    onClick={() => props.iconClick("locationHistory")}

                    className={`sid_bar_bg_classic mt-md-2 mt-1  cornerBefore  ${props.page === "LocationHistory" ? "bg-navy" : ""} `}>
                    <img
                        src={
                            props.page === "LocationHistory" ?
                                process.env.REACT_APP_BASE_URL +
                                "images/sidebar/direction_inactive.svg" : process.env.REACT_APP_BASE_URL +
                                "images/sidebar/direction_active.svg"
                        }
                        className="side_bar_icon_classic  cursor-pointer "
                        alt="..."
                    />
                    <span className={`${props.page === "LocationHistory" ? "text-white" : ""}  text_sideBar_Mobile_classic ${process.env.REACT_APP_FLAVOR !== "Bike" ? "width_118_mobile" : "pr-2"} `}
                    >{process.env.REACT_APP_FLAVOR === "Bike" ? (" Routes") : ("Location History")} </span>

                </div>
                <div
                    onClick={() => props.iconClick(deviceState.streamingDevice ? "LiveStream" : "addeditlivestream")}
                    className={`sid_bar_bg_classic mt-md-2 mt-1  cornerBefore  ${props.page === "LiveStream" ? "bg-navy" : ""} `}>
                    <img
                        src={
                            props.page === "LiveStream" ?
                                process.env.REACT_APP_BASE_URL +
                                "images/sidebar/stream_inactive.svg" : process.env.REACT_APP_BASE_URL +
                                "images/sidebar/stream_active.svg"
                        }
                        className="side_bar_icon_classic  cursor-pointer "
                        alt="..."
                    />
                    <span className={`${props.page === "LiveStream" ? "text-white" : ""}  text_sideBar_Mobile_classic ${process.env.REACT_APP_FLAVOR !== "Bike" ? "width_118_mobile" : "pr-2"} `}
                    >{("Live Stream")} </span>

                </div>
                <div
                    onClick={() => props.iconClick("Alarm")}

                    className={`sid_bar_bg_classic mt-md-2 mt-1  cornerBefore ${props.page === "Alarm" ? "bg-navy" : ""} `}>
                    <img
                        src={
                            props.page === "Alarm" ?
                                process.env.REACT_APP_BASE_URL +
                                "images/sidebar/bell_inactive.svg" : process.env.REACT_APP_BASE_URL +
                                "images/sidebar/bell_active.svg"
                        }
                        className="side_bar_icon_classic  cursor-pointer "
                        alt="..."
                    />
                    <span className={`${props.page === "Alarm" ? "text-white" : ""}  text_sideBar_Mobile_classic ${process.env.REACT_APP_FLAVOR !== "Bike" ? "width_118_mobile" : "pr-2"} `}
                    >{("Alarm")} </span>
                </div>
                <div
                    onClick={() => props.iconClick("DataPlan")}
                    className={`sid_bar_bg_classic mt-md-2 mt-1 ${props.page === "DataPlan" ? "bg-navy" : ""}  cornerBefore `}>
                    <img
                        src={
                            props.page === "DataPlan" ?
                                process.env.REACT_APP_BASE_URL +
                                "images/sidebar/card_inactive.svg" : process.env.REACT_APP_BASE_URL +
                                "images/sidebar/card_active.svg"
                        }
                        className="side_bar_icon_classic  cursor-pointer "
                        alt="..."
                    />
                    <span className={`${props.page === "DataPlan" ? "text-white" : ""}  text_sideBar_Mobile_classic `}
                    >{("Data Plan")} </span>
                </div>
                <div
                    onClick={() => props.iconClick("Settings")}
                    className={`sid_bar_bg_classic mt-md-2 mt-1 ${props.page === "Settings" ? "bg-navy" : ""}  cornerBefore `}>
                    <img
                        src={
                            props.page === "Settings" ?
                                process.env.REACT_APP_BASE_URL +
                                "images/sidebar/settings_inactive.svg" : process.env.REACT_APP_BASE_URL +
                                "images/sidebar/settings_active.svg"
                        }
                        className="side_bar_icon_classic  cursor-pointer "
                        alt="..."
                    />
                    <span className={`${props.page === "Settings" ? "text-white" : ""}  text_sideBar_Mobile_classic `}
                    >{("Settings")} </span>
                </div>
            </div>
            <LanguageChangerMobile
            showLang={showLanguageMoble}
            closeShowLanguageMoble={()=>setShowLanguageMoble(false)}
            select={"sidebarclassic"}/>
        </React.Fragment>
    );
};

export default SideBarClassic;
