/*
 * @Author: Abhi Bhatt
 * @Date:   2020-12-17 20:35:51
 * @Last Modified by:   abhi
 * @Last Modified time: 2021-03-15 16:48:37
 */
import React, { useEffect, useState } from "react";
import i18n from "i18next";
import { authSlice } from "../../slice/authSlice";
import { useDispatch } from "react-redux";
import * as loginService from "../../services/user/loginService2";

const LanguageChanger = (props) => {
  const [showLang, setShowLang] = useState(false);
  const [activeLanguage, setActiveLang] = useState(false);
  const authAction = authSlice.actions;
  const dispatch = useDispatch();
  let flavor = process.env.REACT_APP_FLAVOR;

  const updateLangApi = async (lang) => {
    const response = await loginService.updateTokenLang(lang);
    if(response.status === 800){

    }
  }

  const changeLanguage = (e, language) => {
    e.preventDefault();
    i18n.changeLanguage(language);
    localStorage.setItem("language", language);
    setActiveLang(language);
    updateLangApi(language)
    dispatch(
      authAction.setActiveLanguage({
        activeLanguage: language,
      })
    );
    setShowLang(false);
  };

  const getLangValue = () => {
    const lang = navigator.language.split("-");
    var activeLanguage = i18n.language;
    var splitText = i18n.language.split("-");

    if (i18n.options.whitelist.includes(lang[0])) {
      activeLanguage = splitText[0];
    } else {
      activeLanguage = "en";
    }

    setActiveLang(activeLanguage);
    dispatch(
      authAction.setActiveLanguage({
        activeLanguage: activeLanguage,
      })
    );
  };

  const on_load = () => {};

  useEffect(() => {
    on_load();
    getLangValue();
  }, []);

  return (
    <React.Fragment>
      <div
        className={`col-3 d-none  d-md-flex pb-4 row align-items-end fit_content ${
          props.select === "sidebar" ? "position-fixed" : "position-fixed"
        }`}
        style={{ bottom: 0 }}
      >
        <div className="col-1 p-0">
          <button
            className={` text-uppercase p-2 ${
              props.select === "sidebar" ? "transparent_lang_btn" : "lang_btn"
            }`}
            onClick={() => setShowLang(!showLang)}
          >
            {activeLanguage}
          </button>
        </div>

        <div
          className={
            showLang === true
              ? " lang_box_select d-flex flex-wrap justify-content-between"
              : "lang_box_unselect d-flex flex-wrap justify-content-between"
          }
        >

            {/* {flavor === "Tracking" 
            ?
              <button
                className={
                  showLang === true
                    ? "  lang_btn_small"
                    : " lang_btn_small_unselect"
                }
                onClick={(e, i) => changeLanguage(e, "ru")}
              >
                RU
              </button>
            :
             ""
             } */}
            
            {flavor === "Tracking" 
            ?
              <button
                className={
                  showLang === true
                    ? "  lang_btn_small"
                    : "   lang_btn_small_unselect"
                }
                onClick={(e, i) => changeLanguage(e, "pt")}
              >
                PT
              </button>
              :
              ""
              }
            {flavor === "Tracking" 
            ?
            <button
              className={
                showLang === true
                  ? "  lang_btn_small"
                  : "  lang_btn_small_unselect"
              }
              onClick={(e, i) => changeLanguage(e, "es")}
            >
              ES
              </button>
           
            :
            ""
            }

            {flavor === "Tracking" 
            ?
            <button
              className={
                showLang === true
                  ? " lang_btn_small "
                  : " lang_btn_small_unselect"
              }
              onClick={(e, i) => changeLanguage(e, "pl")}
            >
              PL
            </button>
            :
            ""
            }

            {flavor === "Tracking" 
            ?
            <button
              className={
                showLang === true
                  ? "  lang_btn_small"
                  : "  lang_btn_small_unselect"
              }
              onClick={(e, i) => changeLanguage(e, "el")}
            >
              EL
            </button>
            :
            ""
            }
          {flavor === "Tracking" 
            ?
            <button
              className={
                showLang === true
                  ? "  lang_btn_small"
                  : "   lang_btn_small_unselect"
              }
              onClick={(e, i) => changeLanguage(e, "cs")}
            >
              CZ
            </button>
            :
            ""
          }

            {flavor === "Tracking" 
            ?
            <button
              className={
                showLang === true
                  ? " lang_btn_small "
                  : " lang_btn_small_unselect"
              }
              onClick={(e, i) => changeLanguage(e, "it")}
            >
              IT
            </button>
            :
            ""
            }

          {flavor === "Tracking" 
            ?
            <button
              className={
                showLang === true
                  ? "  lang_btn_small"
                  : "  lang_btn_small_unselect"
              }
              onClick={(e, i) => changeLanguage(e, "fr")}
            >
              FR
            </button>
            :
            ""
            }

            {flavor === "Tracking" 
            ?
            <button
              className={
                showLang === true
                  ? "  lang_btn_small"
                  : "  lang_btn_small_unselect"
              }
              onClick={(e, i) => changeLanguage(e, "nl")}
            >
              NE
            </button>
            :
            ""
            }

            {flavor === "Tracking"
            ?
            <button
              className={
                showLang === true
                  ? " lang_btn_small "
                  : " lang_btn_small_unselect"
              }
              onClick={(e, i) => changeLanguage(e, "da")}
            >
              DA
            </button>
            :
            ""
            }

          {flavor === "Tracking" || flavor === "Bike"
            ?
            <button
              className={
                showLang === true
                  ? "  lang_btn_small"
                  : "  lang_btn_small_unselect"
              }
              onClick={(e, i) => changeLanguage(e, "fi")}
            >
              FI
            </button>
            :
            ""
            }
            {flavor === "Tracking" 
            ?
            <button
              className={
                showLang === true
                  ? "  lang_btn_small"
                  : "  lang_btn_small_unselect"
              }
              onClick={(e, i) => changeLanguage(e, "de")}
            >
              DE
            </button>
            :
            ""
            }

            {flavor === "Tracking" || flavor === "Alco" || flavor === "Bike"
            ?
            <button
              className={
                showLang === true
                  ? " lang_btn_small "
                  : " lang_btn_small_unselect"
              }
              onClick={(e, i) => changeLanguage(e, "sv")}
            >
              SV
            </button>
            :
            ""
            }

            {flavor === "Tracking" || flavor === "Alco" || flavor === "Bike"
            ?
            <button
              className={
                showLang === true
                  ? "  lang_btn_small"
                  : "  lang_btn_small_unselect"
              }
              onClick={(e, i) => changeLanguage(e, "en")}
            >
              EN
            </button>
            :
            ""
            }

            {flavor === "Tracking" 
            ?
            <button
              className={
                showLang === true
                  ? "  lang_btn_small"
                  : "  lang_btn_small_unselect"
              }
              onClick={(e, i) => changeLanguage(e, "no")}
            >
              NO
            </button>
            :
            ""
            }
        </div>
      </div>
    </React.Fragment>
  );
};

export default LanguageChanger;
