import React from "react";

const CustomInput1 = (props) => {
  return (
    <>
      <label className="custom-label font_700_italic_gloria">{props.label}</label>
      <div className="custom_field1  background_black_helkama py-2 px-2">
        <input
          className="text_11_700 font_700_gloria text-center "
          type={props.type}
          value={props.value}
          onChange={(event) => props.setValue(event.target.value)}
        />
        <span className="text-uppercase text_11_700 ">{props.addonLabel}</span>
      </div>
    </>
  );
};

export default CustomInput1;
