/*
 * @Author: Abhi Bhatt
 * @Date:   2020-12-23 14:12:09
 * @Last Modified by:   abhi
 * @Last Modified time: 2021-04-14 12:41:01
 */
import React from "react";
// import add_icon from "../../assets/images/add_icon.svg";
import { useTranslation } from "react-i18next";
import GroupDetailRow from "./GroupDetailRow";
import FlavorImages from "../../assetsFolderImport";

const CreateGroupRow = (props) => {
  const { t } = useTranslation();
  const renderDeviceRow = () => {
    return (
      <div className="row row_expend px-0">
        <div className=" create_group_row_top  row align-items-center">
          <img src={FlavorImages.add_icon} className="size_35 ml-3" alt="..." />

          <label className="device_row_name_title  p-0 m-0 ml-3">
            {t("Device.CreateNewGroup")}
          </label>
        </div>
        <div className="w-100 bg-dark">
          <GroupDetailRow
            mode={"Create"}
            onCancel={() => props.onCancel()}
            onAddGroup={() => props.onAddGroup()}
            devices={props.devices}
          />
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="device_row w-100 px-0 ">{renderDeviceRow()}</div>
    </React.Fragment>
  );
};

export default CreateGroupRow;
