import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  page: "",
  dialog: false,
  logoutdial: false,
  cleardial: false,
};
export const settingsSlice = createSlice({
  name: "settings",
  initialState: initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload.page;
    },
    setDialog: (state, action) => {
      state.dialog = action.payload.dialog
    },
    setlogout: (state, action) => {
      state.logoutdial = action.payload.logoutdial;
    },
    setClear: (state, action) => {
      state.cleardial = action.payload.cleardial
    }
  },
});