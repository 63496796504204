import RenderMap from "./RenderMap";
import React from "react";
import { GOOGLE_MAP_KEY } from "../../helpers/constants";
import MapSettings from "./MapSettings";
import StatisticsGraph from "./StatisticsGraph";
import { useSelector, shallowEqual } from "react-redux";

const Map = (props) => {
  let width = window.screen.width;
  const { MobmapType } = useSelector(
    (state) => ({ MobmapType: state.mapSettings.Maptype }),
    shallowEqual
  );
  return (
    <>
      {props.page !== "urlscreen" ? (
        <div className="d-none d-xl-block d-lg-block">
          <MapSettings
            mapType={MobmapType}
          />
        </div>
      ) : (
        ""
      )}
      <RenderMap
        mapType={MobmapType}
        screen={props.screen}
        page={props.page ?? ""}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_KEY}&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        containerElement={
          <div
            className={`${props.page === "urlscreen" ? "url_screen_map_style" : "map_style"
              }`}
          />
        }
      />
      {(props.page === ("locationHistory" || "routes")) && width > 768 &&
        process.env.REACT_APP_FLAVOR === "Bike" ? (
        <StatisticsGraph screen={"route"} />
      ) : (
        ""
      )}
    </>
  )
}
export default Map;
