import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { registerSlice } from "../../slice/registerSlice";
import _ from "lodash";
import { Link } from "react-router-dom";
import { MAIL_FORMATE, INDEX_URL, SITE_TITLE } from "../../helpers/constants";
import { seo } from "../../helpers/seo";
import ReactHtmlParser from "react-html-parser";
import LanguageChanger from "../../components/LanguageChanger/LanguageChanger";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CheckBox from "../../components/Control/CheckBox";
// import dot_selected from "../../assets/images/dot_selected.svg";
// import dot_unselected from "../../assets/images/dot_unselected.svg";
// import cross_red from "../../assets/images/cross_red.svg";
// import check_light_navy from "../../assets/images/check_light_navy.svg";
// import backward_arrow_grey from "../../assets/images/backward_arrow_grey.svg";
import FlavorImages from "../../assetsFolderImport";


const registerActions = registerSlice.actions;
const SignUpEmail = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [termAndServiceError, setTermAndServiceError]=useState("confirmerror")
  const [termAndServiceError2, setTermAndServiceError2]=useState("confirmerror")

  const { registerState } = useSelector(
    (state) => ({ registerState: state.register }),
    shallowEqual
  );

  const [fields, setFields] = useState({
    Email: registerState.email,
    ConfirmEmail: registerState.confirmEmail,
  });
  const [errors, setErrors] = useState({});
  const [is_valid_email, setIsValidEmail] = useState(-1);
  const [is_valid_confirm_email, setIsValidConfirmEmail] = useState(-1);
  const [isChecked, setIsChecked] = useState(false);

  const clickNext = async (e) => {
    e.preventDefault();
    if (handleValidation() && registerState.agree && registerState.agree2) {
      dispatch(registerActions.setStepEmailData({ email: fields["Email"] }));
      history.push(INDEX_URL + "signup/password");
    }
    else if(!registerState.agree && !registerState.agree2)
    {
      setTermAndServiceError(t("errorCode.AgreeTermsCondition"))
      setTermAndServiceError2(t("errorCode.AgreeServiceLocation")) 

    }
    else if(!registerState.agree){
      setTermAndServiceError(t("errorCode.AgreeTermsCondition")) 

    }
    else if(!registerState.agree2){
      setTermAndServiceError2(t("errorCode.AgreeServiceLocation")) 
    }
    if(registerState.agree2){
      setTermAndServiceError2("confirmerror")
    }
  };

  const handleValidation = () => {
    let fields_temp = _.cloneDeep(fields);
    let errors = {};
    let formIsValid = true;

    if (!fields_temp["Email"]) {
      formIsValid = false;
      errors["Email"] = t("errors.Please enter email");
      setIsValidEmail(0);
    } else if (fields_temp.Email !== "") {
      if (MAIL_FORMATE.test(fields_temp.Email)) {
        errors["Email"] = "";
        setIsValidEmail(1);
      } else {
        formIsValid = false;
        errors["Email"] = t("login.Incorrect email Please try again");
        setIsValidEmail(0);
      }
    } else {
      errors["Email"] = "";
      setIsValidEmail(-1);
    }

    if (!fields_temp["ConfirmEmail"]) {
      formIsValid = false;
      errors["ConfirmEmail"] = t("errors.Please enter email");
      setIsValidEmail(0);
    } else if (fields_temp.ConfirmEmail !== "") {
      if (MAIL_FORMATE.test(fields_temp.ConfirmEmail)) {
        errors["ConfirmEmail"] = "";
        setIsValidConfirmEmail(1);
      } else {
        formIsValid = false;
        errors["ConfirmEmail"] = t("login.Incorrect email Please try again");
        setIsValidConfirmEmail(0);
      }
    } else {
      errors["ConfirmEmail"] = "";
      setIsValidConfirmEmail(-1);
    }

    if (
      fields_temp["ConfirmEmail"] !== fields_temp["Email"] &&
      formIsValid === true
    ) {
      formIsValid = false;
      errors["ConfirmEmail"] = t("errors.EmailsNotMatch");
      setIsValidConfirmEmail(0);
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleChange = (field, e) => {
    let fields_temp = _.cloneDeep(fields);
    let errors = {};
    fields_temp[field] = e.target.value;

    if (fields_temp.Email !== "" && fields_temp.Email !== undefined) {
      if (MAIL_FORMATE.test(fields_temp.Email)) {
        setIsValidEmail(1);

        errors["Email"] = "";
      } else {
        setIsValidEmail(0);
      }
    } else {
      errors["Email"] = "";
      setIsValidEmail(-1);
    }

    if (
      fields_temp.ConfirmEmail !== "" &&
      fields_temp.ConfirmEmail !== undefined
    ) {
      if (MAIL_FORMATE.test(fields_temp.ConfirmEmail)) {
        setIsValidConfirmEmail(1);

        errors["ConfirmEmail"] = "";
      } else {
        setIsValidConfirmEmail(0);
      }
    } else {
      errors["ConfirmEmail"] = "";
      setIsValidConfirmEmail(-1);
    }

    setFields(fields_temp);
    setErrors(errors);
  };

  const setCheckMark = async (e) => {
    if (registerState.agree) {
      dispatch(registerActions.setAgree({ agree: false }));
    } else {
      dispatch(registerActions.setStepEmailData({ email: fields["Email"] }));
      dispatch(
        registerActions.setStepConfirmEmailData({
          confirmEmail: fields["ConfirmEmail"],
        })
      );

      history.push(INDEX_URL + "terms-of-use");
    }
  };


  const setCheckMark2 = async (e) => {
    if (registerState.agree2) {
      dispatch(registerActions.setAgree2({ agree2: false }));
    } else {
      dispatch(registerActions.setAgree2({ agree2: true }));

    }
  };

  const on_load = () => {
    dispatch(registerActions.setStep({ step: "email" }));

    seo({
      title: SITE_TITLE,
      metaDescription: "",
    });
  };

  useEffect(() => {
    on_load();
    dispatch(
      registerActions.setokenData({
        pretoken: false,
      })
    );
  }, []);

  const emptyEmail = () => {
    setFields({ ...fields, Email: "" });
    setIsValidEmail(-1);
  };

  const emptyConfirmEmail = () => {
    setFields({ ...fields, ConfirmEmail: "" });
    setIsValidConfirmEmail(-1);
  };

  return (
    <>
      <React.Fragment>
        <div className="col-12 p-4 h-100 " id="login-page">
                <div className="row pt-2">
                    <div
                      className="btn_back rounded_white cursor-pointer justify-content-center align-items-center ml-3"
                      style={{ width: "35px", height: "35px" }}
                      onClick={() => window.history.back()}
                    >
                      <img src={FlavorImages.backward_arrow_grey} className="backward_button cursor-pointer" alt="..." />
                    </div>
                </div>
          <div className="container-fluid p-0">
            <div className="col-md-6 offset-md-3 col-lg-4 offset-lg-4 pt-4 align-items-center">
              <img
                src={FlavorImages.app_logo}
                className="img-fluid mx-auto d-none d-sm-block photo"
                alt="..."
              />
              <div className="col-12 mt-5 pt-5">
                <div 
                className="col-12 d-flex align-items-center px-0 postition-relative"
                style={{ left: "-32px" }}
                >
                  <label
                    htmlFor="Email"
                    className="text_34_700 col-form-label font_700_italic_gloria "
                  >
                    {"1. " + ReactHtmlParser(t("signup.Email"))}
                  </label>
                </div>

                <div className="col-12 p-0">
                  <label
                    htmlFor="Email"
                    className="text_16_400 text-navy col-form-label font_400_italic_gloria"
                  >
                    {ReactHtmlParser(t("signup.EmailSubTitle"))}
                  </label>
                </div>

                <div className="col-12 inputBlock col-sm-12">
                  <TextField
                    htmlFor="Email"
                    value={fields["Email"]}
                    className="col-12 Mui-focused label-input-bold"
                    id="Email"
                    label={ReactHtmlParser(t("login.Email"))}
                    variant="standard"
                    placeholder=""
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {is_valid_email === 0 ? (
                            <img
                              src={FlavorImages.cross_red}
                              className="input-icon cursor-pointer"
                              onClick={() => {
                                emptyEmail();
                              }}
                            ></img>
                          ) : is_valid_email === 1 ? (
                            <img
                              src={FlavorImages.check_light_navy}
                              className="input-icon"
                            ></img>
                          ) : (
                            ""
                          )}
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleChange("Email", e);
                    }}
                    defaultValue={fields["Email"]}
                  />

                  <span></span>
                  {typeof errors["Email"] !== "undefined" &&
                    errors["Email"] !== "" ? (
                    <span className="invalid-feedback font_400_gloria  bottom">{errors["Email"]}</span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-12 inputBlock col-sm-12">
                  <TextField
                    htmlFor="ConfirmEmail"
                    value={fields["ConfirmEmail"]}
                    className="col-12 Mui-focused label-input-bold"
                    id="ConfirmEmail"
                    label={ReactHtmlParser(t("signup.ConfirmEmail"))}
                    variant="standard"
                    placeholder=""
                    onChange={(e) => {
                      handleChange("ConfirmEmail", e);
                    }}
                    defaultValue={fields["ConfirmEmail"]}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {is_valid_confirm_email === 0 ? (
                            <img
                              src={FlavorImages.cross_red}
                              className="input-icon cursor-pointer"
                              onClick={() => {
                                emptyConfirmEmail();
                              }}
                            ></img>
                          ) : is_valid_confirm_email === 1 ? (
                            <img
                              src={FlavorImages.check_light_navy}
                              className="input-icon"
                            ></img>
                          ) : (
                            ""
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />

                  <span></span>
                  {typeof errors["ConfirmEmail"] !== "undefined" &&
                    errors["ConfirmEmail"] !== "" ? (
                    <span className="invalid-feedback font_400_gloria  m-0">
                      {errors["ConfirmEmail"]}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-12 row privacy-box px-0 mx-0 mt-5 justify-content-between">
                  <div className="col-8 px-0 privacy">
                    <label htmlFor="Email">
                      {t("signup.agreeToOur")}
                      <Link
                        className="custom-checkbox-Link"
                        to={INDEX_URL + "terms-of-use"}
                      >
                        {ReactHtmlParser(t("signup.privacyPolicy"))}
                      </Link>
                      {t("signup.and")}
                      <Link
                        className="custom-checkbox-Link"
                        to={INDEX_URL + "terms-of-use"}
                      >
                        {ReactHtmlParser(t("signup.TermOfUse"))}
                      </Link>
                      {t("signup.toContinue")}
                    </label>
                  </div>
                  <div className="custom-checkbox">
                    <CheckBox
                      isChecked={registerState.agree}
                      onClick={(e) => setCheckMark(e)}
                    />
                  </div>
                </div>
                <div className={`col-12 row privacy-box px-0 mx-0 ${termAndServiceError === "confirmerror" && "d-none"} pb-4 justify-content-between`}>
                  <div className="invalid-feedback font_400_gloria bottom">
                      {termAndServiceError === "confirmerror" ? "" : termAndServiceError}
                  </div>
                </div>

                    {/* Ny knapp */}
                <div className="col-12 row privacy-box px-0 mx-0 mt-3 justify-content-between">
                  <div className="col-8 px-0 privacy">
                    <label htmlFor="Email">
                      {t("signup.AgreeToLocation")}
                    </label>
                  </div>
                  <div className="custom-checkbox">
                    <CheckBox
                      isChecked={registerState.agree2}
                      onClick={(e) => setCheckMark2(e)}
                    />
                  </div>
                </div>
                <div className={`col-12 row privacy-box px-0 mx-0 ${termAndServiceError2 === "confirmerror" && "d-none"} pb-4 justify-content-between`}>
                  <div className="invalid-feedback font_400_gloria bottom">
                      {termAndServiceError2 === "confirmerror" ? "" : termAndServiceError2}
                  </div>
                </div>
                
                <div className="px-0 col-12 mt-4 no-gutters" >
                  <div className="col-sm-12   ">
                    <button
                      type="submit"
                      className="btn_login  background_black_helkama text_gloria_17_700_italic"
                      id="loginSubmit"
                      onClick={(e) => clickNext(e)}
                    >
                      {ReactHtmlParser(t("signup.Next"))}
                    </button>
                  </div>
                </div>

                <div className="row col-12 mt-4 justify-content-center align-items-center mx-0">
                  <img alt="Step" className="dot" src={FlavorImages.dot_selected} />
                  <img alt="Step" className="dot ml-2" src={FlavorImages.dot_unselected} />
                  <img alt="Step" className="dot ml-2" src={FlavorImages.dot_unselected} />
                  <img alt="Step" className="dot ml-2" src={FlavorImages.dot_unselected} />
                </div>
              </div>
            </div>
            <LanguageChanger />
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default SignUpEmail;
