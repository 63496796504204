/*
 * @Author: Abhi Bhatt
 * @Date:   2020-12-23 14:12:09
 * @Last Modified by:   abhi
 * @Last Modified time: 2021-04-14 12:41:01
 */
import React from "react";
import ReactHtmlParser from "react-html-parser";
// import groups from "../../assets/images/groups.svg";
// import groups_select from "../../assets/images/groups_select.svg";
import { useTranslation } from "react-i18next";
import FlavorImages from "../../assetsFolderImport";
const GroupsButton = (props) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div
        className="add_button  disply_none_small cursor-pointer"
        style={
          props.active === true
            ? { backgroundColor: process.env.REACT_APP_FLAVOR === 'Bike' ? "var(--dark_helkama)" : "var(--light_navy)", width: "80px", marginLeft: "15px", boxShadow: "inset 0px 1px 2px rgba(0, 0, 0, 0.1)" }
            : { width: "80px", marginLeft: "15px" }
        }
        onClick={() => props.onClick()}
      >
        {props.active === true ? (
          <img
            src={FlavorImages.groups_select}
            className="add_button_icon white_icon cursor-pointer"
            alt="..."
          />
        ) : (
          <img src={FlavorImages.group} className="add_button_icon " alt="..." />
        )}
        <label className="cursor-pointer text_11_700" style={props.active === true ? { color: "#FFFFFF" } : {}}>
          {ReactHtmlParser(t("labels.Groups"))}
        </label>
      </div>
    </React.Fragment>
  );
};

export default GroupsButton;
