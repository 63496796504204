import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React from "react";
import { useTranslation } from "react-i18next";
// import close_gray from "../../assets/images/close_gray.svg";
import { shallowEqual, useSelector } from "react-redux";
import * as devicesService2 from "../../services/devices/devicesService2";
import FlavorImages from "../../assetsFolderImport";
const DevicePower = (props) => {
  const { devicesState } = useSelector(
    (state) => ({ devicesState: state.devices }),
    shallowEqual
  );
  if (devicesState.filteredDevices) {
    devicesState.filteredDevices.map((device, index) => { });
  }
  const EnabeldisableDevice = async () => {

    var disableDeviceIDs = [];
    var enableDeviceIDs = [];
    let powerSwitchTrue = true;
    let powerSwitchFalse = false;

    // console.log(devicesState.filteredDevices)

    devicesState.filteredDevices.map((device, index) => {
      // console.log(device);
      if (process.env.REACT_APP_FLAVOR === 'Bike') {
        if (device.isSelected && !device.communicationmode.powerswitch.pending) {
          if (device.communicationmode.powerswitch.info) {
          // console.log(device.name);
          enableDeviceIDs.push(device.id);
          } else {
            disableDeviceIDs.push(device.id);
        }
        }
      } else {
        if (!device.sharedstatus.waitingforaccept && device.isSelected) {
          if (device.modelspecificinfo.powerswitch.powerswitchinfo) {
            disableDeviceIDs.push(device.id);
          } else {
          enableDeviceIDs.push(device.id);
        }
      }
      }
    });

    const allPromise = await Promise.all(
      disableDeviceIDs.length > 0 && enableDeviceIDs.length > 0 
      ?
        [(devicesService2.updateDevicePower(disableDeviceIDs, powerSwitchFalse), devicesService2.updateDevicePower(enableDeviceIDs, powerSwitchTrue))]
      : disableDeviceIDs.length > 0 && enableDeviceIDs.length === 0
        ? [devicesService2.updateDevicePower(disableDeviceIDs, powerSwitchFalse)]
        : disableDeviceIDs.length === 0 && enableDeviceIDs.length > 0
          ? [devicesService2.updateDevicePower(enableDeviceIDs, powerSwitchTrue)]
          : []
    );
    // console.log(allPromise);
    for (var i=0; i < allPromise.length; i++) {
      if(allPromise[i].status === 200){
        
        props.onClose();
      }else{
        props.onClose();
        props.setError(t("Device.ErrorinENABLE/DISABLEpower"));
      }
    } 
    // if (allPromise[0].status === 200 && allPromise[1].status === 200) {
    //   props.onClose();
    // } else {
    //   props.onClose();
    //   props.setError(t("Device.ErrorinENABLE/DISABLEpower"));
    // }
  };
  const { t } = useTranslation();
  const setEnabletitle = () => {
    let orignalDeives = devicesState.filteredDevices.filter(
      (device) => !device.sharedstatus.waitingforaccept
    );

    const filterForHelkama = (element) => element.communicationmode.powerswitch.info && element.isSelected
    const filterForOthers = (element) => element.modelspecificinfo.powerswitch.powerswitchvalid && element.isSelected

    var data_filter = orignalDeives.filter(process.env.REACT_APP_FLAVOR === 'Bike' ? filterForHelkama : filterForOthers)

    if (data_filter.length !== 0) {
      return (
        <>
          <label className="power_device-label text-12-400">
            {t("devicepower.titletwo")}
          </label>
          <div className="row">
            <div className="col-6">
              <label className="power_device-label text-12-400">
                {t("devicepower.Name")}
              </label>
            </div>
            <div className="col-6">
              <label className="power_device-label text-12-400">
                {t("devicepower.IMEI")}
              </label>
            </div>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };
  const setDisbletitle = () => {
    let orignalDeives = devicesState.filteredDevices.filter(
      (device) => !device.sharedstatus.waitingforaccept
    );

    const filterForHelkama = (element) => !element.communicationmode.powerswitch.info && element.isSelected
    const filterForOthers = (element) => !element.modelspecificinfo.powerswitch.powerswitchvalid && element.isSelected

    var data_filter = orignalDeives.filter(process.env.REACT_APP_FLAVOR === 'Bike' ? filterForHelkama : filterForOthers)

    if (data_filter.length !== 0) {
      return (
        <>
          <label className="power_device-label text_12_400">
            {t("devicepower.titleone")}
          </label>
          <div className="row">
            <div className="col-6">
              <label className="power_device-label text_12_400">
                {t("devicepower.Name")}
              </label>
            </div>
            <div className="col-6">
              <label className="power_device-label text_12_400">
                {t("devicepower.IMEI")}
              </label>
            </div>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };
  return (
    <>
      <Dialog open={props.showPowerDialog}>
        <Card className="card" style={{ width: "366px" }}>
          <div
            className="dialog_top_sort_row py-2 card-header"
            style={{ backgroundColor: "white" }}
          >
            <label className="text_17_700 text-grey">{t("geofences.CONFIRM")}</label>
            <img className="cursor-pointer" src={FlavorImages.close_gray} onClick={() => props.onClose()} alt="..." />
          </div>
          <div className="card-body overflow-auto">
            {setEnabletitle()}
            {devicesState.filteredDevices.map((device, index) => {
              if ((!device.sharedstatus.waitingforaccept  ) && device.isSelected) {
                if ((device.modelspecificinfo.powerswitch && device.modelspecificinfo.powerswitch.powerswitchvalid)
                ) {
                  return (
                    <div key={`enabled_${index}`} className="disabled-devices">
                      <div className="row font">
                        <div className="col-6">
                          <label className="power_device-label text_12_400 font-weight-bold">
                            {device.name}
                          </label>
                        </div>
                        <div className="col-6">
                          <label className="power_device-label text_12_400 font-weight-bold">
                            {" "}
                            {device.uniqueid}
                          </label>
                        </div>
                      </div>
                    </div>
                  );
                } else return "";
              }
            })}

            {setDisbletitle()}
            {devicesState.filteredDevices.map((device, index) => {
              if ((!device.sharedstatus.waitingforaccept) && device.isSelected) {
                if ((device.modelspecificinfo.powerswitch && !device.modelspecificinfo.powerswitch.powerswitchvalid)
                ) {
                  return (
                    <div key={index} className="disabled-devices">
                      <div className="row font">
                        <div className="col-6">
                          <label className=" text_12_400 font-weight-bold">
                            {device.name}
                          </label>
                        </div>
                        <div className="col-6">
                          <label className="power_device-label text_12_400 font-weight-bold">
                            {device.uniqueid}
                          </label>
                        </div>
                      </div>
                    </div>
                  );
                } else return "";
              }
            })}
          </div>
          <div className="m-0 card-footer  d-flex justify-content-end">
            <button onClick={() => props.onClose()} className="btn text-12-700 btn-sm  py-1 px-3 text-grey">
              {t("actionDialog.cancel")}
            </button>
            <button
              onClick={() => EnabeldisableDevice()}
              className="btn btn-sm py-1 text-12-700 px-3 bg-navy text-white ml-2 text-uppercase"
            >
              {t("devicepower.enable/disble")}
            </button>
          </div>
        </Card>
      </Dialog>
    </>
  );
};
export default DevicePower;
