import React, { useState, useEffect } from "react";
import { Marker, InfoWindow } from "react-google-maps";
import * as helper_functions from "../../helpers/helper_functions";
import { devicesSlice } from "../../slice/deviceSlice";
import { locationHistorySlice } from "../../slice/locationHistorySlice";
import { subSeconds } from "date-fns";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual } from "react-redux";
import { useDispatch } from "react-redux";
import FlavorImages from "../../assetsFolderImport";

const DeviceStoppedMarker = React.memo((props) => {
	const deviceActions = devicesSlice.actions;
  const locationHistoryActions = locationHistorySlice.actions;

  const { t } = useTranslation();
	const dispatch = useDispatch();
  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);

  useEffect(() => {
    let intervalId;

    // Function to check if Google Maps is loaded
    const checkGoogleMapsLoaded = () => {
      if (window.google && window.google.maps && window.google.maps.Point) {
        setIsGoogleMapsLoaded(true);
        clearInterval(intervalId);
      }
    };

    intervalId = setInterval(checkGoogleMapsLoaded, 100);

    // Cleanup interval on unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  
  const getstartTime = (timeinSecond, datetime) => {
    if (timeinSecond == null) {} 
    else {
    let newMintsecond = subSeconds(new Date(datetime), timeinSecond)
    return helper_functions.default.formatDateTime(
      newMintsecond,"HH:mm MM/dd"
    )
    }
  }
  const { locationHistoryState } = useSelector(
    (state) => ({ locationHistoryState: state.locationHistory }),
    shallowEqual
  );
  const openIW = (point) => {
    dispatch(locationHistoryActions.setlocationHistoryTime({ locationHistoryTime: new Date(point.datetime).getTime() }))
    dispatch(
      deviceActions.setSelectedLocationPointId({
      selectedLocationPointId: "",
    })
     )
  }

  const removeIW = () => {
    dispatch(
      locationHistoryActions.setlocationHistoryTime({
        locationHistoryTime: ""
    }))
  }
  const setStoppedMarkerinfo=(point)=> {

    console.log(point);
    // console.log('show condition:', props.point.positionid, devicesState.selectedLocationPointId)
    // console.log('selected point:', point, props.point.positionid)
    dispatch(
      deviceActions.setSelectedLocationPointId({
        selectedLocationPointId: "",
      })
    );
    dispatch(
      locationHistoryActions.setlocationHistoryTime({
        locationHistoryTime: ""
    }))
  }
  const { devicesState } = useSelector(
		(state) => ({ devicesState: state.devices }),
		shallowEqual
	);

  if (!isGoogleMapsLoaded) {
    // If not loaded, you can return null or a loader
    return null;
  }

  return (
    <>
      <Marker
        onClick={() => openIW(props.point)}
        zIndex={99999999}
        icon={{
          url:
            FlavorImages.device_stopped_marker,
						scaledSize: { width: 8, height: 8 },
						anchor: new window.google.maps.Point(4, 4),
        }}
        position={{
          lat: parseFloat(props.point.latitude),
          lng: parseFloat(props.point.longitude),
        }}
      >
        {new Date(props.point.datetime).getTime() === locationHistoryState.locationHistoryTime ? (
          <InfoWindow
					options={{pixelOffset: new window.google.maps.Size(0,-3), disableAutoPan: true}}
					>
            <div
              onClick={() => removeIW()} 
              className={`custom-tooltip-container cursor-pointer color2 mt-0`}>
              <div
                className="fade show tooltip cursor-pointer bs-tooltip-bottom"
                data-popper-reference-hidden="false"
                data-popper-escaped="false"
                data-popper-placement="bottom"
              >
                <div className="tooltip-arrow cursor-pointer  mr-1 margin_top_mins_21"
                  style={{
                    transform: "rotate(0deg)"
                  }}
                ></div>
               <div className="tooltip-inner cursor-pointer  size_120_64 line_height_point_0 padding_top_14 margin_top_minus_70">
               <p className="text_17_700 text-light cursor-pointer line_height_point_7">{process.env.REACT_APP_FLAVOR == "Bike" ? props.point.speed+"km/h":t("LocationHistory.stopped")}</p>
                  {process.env.REACT_APP_FLAVOR !== "Bike" ? (
                  <span className="text_17_700 cursor-pointer text-white line_height_point_0">
                    {getstartTime(props.point.stoptime, props.point.datetime)}
                    {" - "} 
                    {helper_functions.default.formatDateTime(props.point.datetime, "HH:mm MM/dd")}
                    </span>
                  ) : ('')}
                </div>
              </div>
            </div>
          </InfoWindow>
        ) : (
          ""
        )}
      </Marker>
    </>
  );
});

export default DeviceStoppedMarker;
