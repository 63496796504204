import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store  from "./store";
import {persistStore} from 'redux-persist';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import axios from "axios";
import i18next from "i18next";
import { INDEX_URL } from "./helpers/constants";
import { PersistGate } from "redux-persist/integration/react";
/*
import common_sv from "./translations/sv/common.json";
import common_it from "./translations/it/common.json";
import common_no from "./translations/no/common.json";
import common_da from "./translations/da/common.json";
import common_fi from "./translations/fi/common.json";
import common_de from "./translations/de/common.json";
import common_fr from "./translations/fr/common.json";
import common_nl from "./translations/nl/common.json";
import common_pl from "./translations/pl/common.json";
import common_es from "./translations/es/common.json";
import common_cs from "./translations/cs/common.json";
import common_pt from "./translations/pt/common.json";
import common_el from "./translations/el/common.json";
import common_ru from "./translations/ru/common.json";
*/
var common_sv = require("./translations/" + process.env.REACT_APP_ASSETS + "/sv/common.json");
var common_it = require("./translations/" + process.env.REACT_APP_ASSETS + "/it/common.json");
var common_no = require("./translations/" + process.env.REACT_APP_ASSETS + "/no/common.json");
var common_da = require("./translations/" + process.env.REACT_APP_ASSETS + "/da/common.json");
var common_fi = require("./translations/" + process.env.REACT_APP_ASSETS + "/fi/common.json");
var common_de = require("./translations/" + process.env.REACT_APP_ASSETS + "/de/common.json");
var common_fr = require("./translations/" + process.env.REACT_APP_ASSETS + "/fr/common.json");
var common_nl = require("./translations/" + process.env.REACT_APP_ASSETS + "/nl/common.json");
var common_pl = require("./translations/" + process.env.REACT_APP_ASSETS + "/pl/common.json");
var common_es = require("./translations/" + process.env.REACT_APP_ASSETS + "/es/common.json");
var common_cs = require("./translations/" + process.env.REACT_APP_ASSETS + "/cs/common.json");
var common_pt = require("./translations/" + process.env.REACT_APP_ASSETS + "/pt/common.json");
var common_el = require("./translations/" + process.env.REACT_APP_ASSETS + "/el/common.json");
var common_ru = require("./translations/" + process.env.REACT_APP_ASSETS + "/ru/common.json");
var common_en = require("./translations/" + process.env.REACT_APP_ASSETS + "/en/common.json");

/*
const customFile = require("./translations/en/custom_file" +
  process.env.REACT_APP_FLAVOR +
  ".json");
  var common_en
if (process.env.REACT_APP_FLAVOR == "Bike") {
   common_en = require('./translations/en/custom_filehelkama.json')
}
else 
{
  common_en = require('./translations/en/common.json')
}
*/
function setupAxios(axios, store) {
  axios.interceptors.request.use((request) => {
    const authToken = store.getState("auth")?.auth?.authToken;
    if (authToken) {
      request.headers.Authorization = `${authToken}`;
    }

    return request;
  });
}

setupAxios(axios, store);

i18next.init({
  ns: ["common", "translation"],
  defaultNS: "common",
  whitelist: [
    "en",
    "sv",
    "it",
    "nb",
    "no",
    "da",
    "fi",
    "de",
    "fr",
    "nl",
    "pl",
    "es",
    "cs",
    "pt",
    "el",
    "ru",
  ],
  fallbackLng: "en",
  interpolation: { escapeValue: false }, 
  resources: {
    en: {
      common: common_en, // these are languages which user can select while using website
     // customFile: customFile,
    },
    sv: {
      common: common_sv, 
    },
    it: {
      common: common_it, 
    },
    nb: {
      common: common_no,  
    },
    no: {
      common: common_no, 
    },
    da: {
      common: common_da,
    },
    fi: {
      common: common_fi, 
    },
    de: {
      common: common_de,
    },
    fr: {
      common: common_fr, 
    },
    nl: {
      common: common_nl, 
    },
    pl: {
      common: common_pl,
    },
    es: {
      common: common_es, 
    },
    cs: {
      common: common_cs, 
    },
    pt: {
      common: common_pt, 
    },
    el: {
      common: common_el, 
    },
    ru: {
      common: common_ru,
    },
  },
  lng:
    localStorage.getItem("language") === "" ||
    localStorage.getItem("language") === null
      ? navigator.language
      : localStorage.getItem("language"),
});

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <PersistGate loading={null} persistor={persistStore(store)}>
        <App/>
        </PersistGate>
      </I18nextProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
reportWebVitals();
