/*
 * @Author: SweTrack
 */
// export const API_URL = 'https://cloudappapi.com/api/'
export const API_URL = process.env.REACT_APP_API_URL; //https://cloudappapi.com/api/
// IF SUBDOMAIN ---> /subdomain/ 
// IF NO SUBDOMAIN ---> /
export const INDEX_URL = "/";
export const GOOGLE_MAP_KEY = process.env.REACT_APP_MAP_API_KEY;
export const MAIL_FORMATE =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const NUMBER_FORMATE = /^\d+$/;
export const SITE_TITLE = "ArcticTracks";
export const PLUS = "SweTrack Plus";
export const LITE = "SweTrack Lite";
export const MAXI = "MaxiTracker";
export const MULTI = "MultiTracker";
export const DEFAULT_MARKER = "bike-blue.svg";
export const INTERVAL = 10000;
export const DARK_MODE = [
  { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
  { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#263c3f" }],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{ color: "#6b9a76" }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#38414e" }],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [{ color: "#212a37" }],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9ca5b3" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#746855" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ color: "#1f2835" }],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{ color: "#f3d19c" }],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{ color: "#2f3948" }],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#17263c" }],
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [{ color: "#49464a" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: "#515c6d" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [{ color: "#17263c" }],
  },
];
export const ZOOM_COMMON = 3;
export const ZOOM_SELECTED = 12;
export const POSITION_ZOOM = 6;
export const CENTER = {
  lat: 60.1282,
  lng: 18.6435,
};
export const STRIPE_PUBLISHABLE_KEY = "pk_live_nDfMIpn8k5UwTp0oNV2oXZFs";

export const getInfoWindow = (mark, t) => {
  let display = deviceNamesStatus();
  let name = mark.name;
  let LatestPosition = t("marker.Latest Position");

  if (name.length > 14) {
    name = name.substring(0, 14);
    name = name + "...";
  }

  let content_string = "";
  content_string +=
    ' <div id="location-with-dropdown-' +
    mark.id +
    '" class="location-with-dropdown popup-directoin02" style="display:' +
    (display == "On" ? "block;" : "none;") +
    '">';
  content_string += ' <div class="dropdown" id="drop-' + mark.id + '">';
  content_string +=
    ' <div class="uparrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="icon-dropdown-arrow"></i></div>';
  content_string +=
    ' <a id="toggle-' +
    mark.id +
    '" class="dropdown-toggle toggle-click dropdown-toggle-infowindow d-flex w-100 justify-content-between" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">' +
    name +
    "</a>";
  content_string +=
    ' <div id="dropdownmenu-' +
    mark.id +
    '" class="dropdown-menu" aria-labelledby="dropdownMenuButton">';
  content_string += ' <ul class="list-group list-group-flush">';
  content_string += ' <li class="list-group-item">';
  content_string += ' <div class="online-vehicle">' + mark.status + "</div>";
  content_string += " <p>" + t("marker.Latest Position") + ":</p>";
  content_string += " </li>";
  content_string += ' <li class="list-group-item">';
  content_string +=
    ' <p class="d-flex w-100 justify-content-between"><strong>' +
    t("marker.Date") +
    "  :</strong> <span>" +
    window.moment(mark.lastupdate).format("YYYY-MM-DD") +
    "</span></p>";
  content_string +=
    ' <p class="d-flex w-100 justify-content-between"><strong>' +
    t("marker.Time") +
    " : </strong> <span>" +
    window.moment(mark.lastupdate).format("HH:mm") +
    "</span></p>";
  content_string += "  </li>";
  content_string += '  <li class="list-group-item">';
  content_string +=
    '  <p class="d-flex w-100 justify-content-between"><span>' +
    t("marker.Current speed") +
    " : </span> <span>" +
    mark.speed +
    " Km/h</span> </p>";
  content_string += "  </li>";
  content_string += '  <li class="list-group-item">';
  content_string +=
    '  <p class="d-flex w-100 justify-content-between"><span>' +
    t("marker.Coordinates") +
    ' : </span><span class="mark-width">' +
    (typeof mark.latitude != "undefined" ? mark.latitude.toFixed(7) : "") +
    " , " +
    (typeof mark.longitude != "undefined" ? mark.longitude.toFixed(7) : "") +
    "</span></p>";
  content_string += "  </li>";
  content_string += "  </ul>";
  content_string += "  </div>";
  content_string += "  </div>";
  content_string += "  </div>";
  return content_string;
};

/**
 *
 */
export const deviceNamesStatus = () => {
  let status = localStorage.getItem("name_status");

  if (typeof status == "undefined" || status == null) {
    status = "On";
    localStorage.setItem("name_status", status);
  }
  return status;
};

/**
 *
 * @param {*} category
 * @param {*} status
 */
export const getMapType = () => {
  let type = localStorage.getItem("mapType");
  if (typeof type == "undefined" || type == null) {
    type = "ROADMAP";
    localStorage.setItem("mapType", type);
  }
  return type;
};

/**
 *
 * @param {*} category
 */
export const getMarkerImage = (category, status) => {
  var markerDetail = category.split("_");
  var color = "";
  var marker = "Default";
  var markerImage = INDEX_URL + "images/markers/Blue/Default.png";

  if (typeof category !== "undefined" && category !== "") {
    //marker = markerDetail[0];
    //color = markerDetail[1];
    if (
      typeof markerDetail[0] !== "undefined" &&
      markerDetail[0] !== "" &&
      typeof markerDetail[1] !== "undefined" &&
      markerDetail[1] !== ""
    ) {
      marker = markerDetail[0];
      color = markerDetail[1];
      if (status != "online") {
        color = "Grey";
      }
      var folder =
        color === "Grey"
          ? "Grey Offline"
          : color === "Lightblue"
          ? "Light Blue"
          : "" + color + "";
      switch (marker) {
        case "Bicycle":
          markerImage =
            INDEX_URL +
            "images/markers/" +
            folder +
            "/" +
            marker +
            "_" +
            color +
            ".png";
          break;
        case "Boat":
          markerImage =
            INDEX_URL +
            "images/markers/" +
            folder +
            "/" +
            marker +
            "_" +
            color +
            ".png";
          break;
        case "Car":
          markerImage =
            INDEX_URL +
            "images/markers/" +
            folder +
            "/" +
            marker +
            "_" +
            color +
            ".png";
          break;
        case "Jetski":
          markerImage =
            INDEX_URL +
            "images/markers/" +
            folder +
            "/" +
            marker +
            "_" +
            color +
            ".png";
          break;
        case "MonsterTruck":
          markerImage =
            INDEX_URL +
            "images/markers/" +
            folder +
            "/" +
            marker +
            "_" +
            color +
            ".png";
          break;
        case "Motorcycle":
          markerImage =
            INDEX_URL +
            "images/markers/" +
            folder +
            "/" +
            marker +
            "_" +
            color +
            ".png";
          break;
        case "Pet":
          markerImage =
            INDEX_URL +
            "images/markers/" +
            folder +
            "/" +
            marker +
            "_" +
            color +
            ".png";
          break;
        case "Motorcycle":
          markerImage =
            INDEX_URL +
            "images/markers/" +
            folder +
            "/" +
            marker +
            "_" +
            color +
            ".png";
          break;
        case "RV":
          markerImage =
            INDEX_URL +
            "images/markers/" +
            folder +
            "/" +
            marker +
            "_" +
            color +
            ".png";
          break;
        case "Sailboat":
          markerImage =
            INDEX_URL +
            "images/markers/" +
            folder +
            "/" +
            marker +
            "_" +
            color +
            ".png";
          break;
        case "Scooter":
          markerImage =
            INDEX_URL +
            "images/markers/" +
            folder +
            "/" +
            marker +
            "_" +
            color +
            ".png";
          break;
        case "Tractor":
          markerImage =
            INDEX_URL +
            "images/markers/" +
            folder +
            "/" +
            marker +
            "_" +
            color +
            ".png";
          break;
        case "Truck":
          markerImage =
            INDEX_URL +
            "images/markers/" +
            folder +
            "/" +
            marker +
            "_" +
            color +
            ".png";
          break;
      }
    } else {
      markerImage = INDEX_URL + "images/markers/Blue/Default.png";
    }
  } else {
    markerImage = INDEX_URL + "images/markers/Blue/Default.png";
  }

  return markerImage;
};
