import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ActionButton from "../../components/Buttons/ActionButton";
import _ from "lodash";
import RoutesRows from "./RoutesRows";
import * as genericService from "../../services/genericService";
import ErrorDialog from "../LocationHistory/ErrorDialog";
import RouteInfo from "./RouteInfo";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import ConfrimDeleteAction from "./ConfrimDeleteAction";
import { devicesSlice } from "../../slice/deviceSlice";
// import checked from "../../assets/images/checked.svg";
import ConfirmDelete from "../Settings/ConfirmDelete";
import StatisticsGraphMobile from "./StatisticsGraphMobile";
// import check_empty from "../../assets/images/check_empty.svg";
import { useHistory } from "react-router-dom";
import { INDEX_URL } from "../../helpers/constants";
import FlavorImages from "../../assetsFolderImport";

const deviceActions = devicesSlice.actions;
const SearchLocationHistory = (props) => {
	const { t } = useTranslation();
	const [routeIdstrip, setRouteIdStrip] = useState("");
	const [saveRouteIdStrip, setsaveRouteIdStrip] = useState("")
	const [showActionDialog, setShowActionDialog] = useState(false);
	const [clickedRouteId, setClickedRouteId] = useState("");
	const [clickedSavedRouteId, setClickedSavedRouteId] = useState("");
	const [errorDialog, setShowErrorDialog] = useState(false);
	const [showRoutInfoDialog, setShowRoutInfoDialog] = useState(false);
	const [showConfirmDeletRoute, setshowConfirmDeleteRoute] = useState(false);
	const [isSelection, setIsSelection] = useState(false);
	const [chooseAll, setChooseAll] = useState(false);
	const [chooseAllSaved, setChooseAllSaved] = useState(false);
	const [selectedAllRoutes, setSelectedAllRoutes] = useState([]);
	const [selectedSavedRoutes, setSelectedSavedRoutes] = useState([]);
	const dispatch = useDispatch();
	const { locationHistoryState } = useSelector(
		(state) => ({ locationHistoryState: state.locationHistory }),
		shallowEqual
	);
	const closeConfirmDialog = () => {
		dispatch(
			deviceActions.setAction({
				action: "",
			})
		);
		setIsSelection(false);
		setShowActionDialog(false);
	};
	const setRoute = (id) => {
		setRouteIdStrip(id)
		if (routeIdstrip !== id && id) {
			props.setStatisticsGraphShow(true)
		} else {
			props.setStatisticsGraphShow(false)
		}
	}

	const setRouteId = (id, routeype) => {
		if (routeype == "allRoute") {
			setClickedRouteId(id)
			setClickedSavedRouteId("")
		}
		else {
			setClickedRouteId("")
			setClickedSavedRouteId(id)
		}

	}
	const setrouteIdForStrip = (id, routeype) => {
		if (routeype == "allRoute") {
			if (routeIdstrip === id) {
				setRouteIdStrip('')
			}
			else {
				setRouteIdStrip(id)
			}
			setsaveRouteIdStrip("")
		}
		else {
			if (saveRouteIdStrip === id) {
				setsaveRouteIdStrip('')
			}
			else {
				setsaveRouteIdStrip(id)
			}
			setRouteIdStrip('')
		}
	}
	useEffect(() => {
		setRouteId('')
	}
		, [props.routesaveCheck]);
	const setShowConfirmDeleteDialog = () => {
		setshowConfirmDeleteRoute(true);
	};
	const bulkDeleteRoute = async () => {
		if (selectedAllRoutes.length || selectedSavedRoutes.length) {
			const response = await genericService.post("/routes/delete", {
				routeid: _.uniq([...selectedAllRoutes, ...selectedSavedRoutes]),
			});

			if (response.status === 200 || response.status === 200) {
				props.loadRoutes();

				setIsSelection(false)
				setshowConfirmDeleteRoute(false);
			} else {
				setShowErrorDialog(response.data.message);
				setshowConfirmDeleteRoute(false);
			}
		}
	};

	//action to perform should be ('select'/'unselect') 
	const updateSelection = (id, action, routeType) => {
		if (!id && action === '') return

		if (action === 'select' && routeType === 'allRoute' && selectedAllRoutes.indexOf(id) === -1) {
			// select an element from all routes
			const addRoute = props.filterRoute.filter(route => route.routesid === id)[0];
			setSelectedAllRoutes([...selectedAllRoutes, addRoute.routesid])
		} else if (action === 'select' && routeType === 'savedRoute' && selectedSavedRoutes.indexOf(id) === -1) {
			// select an element from saved routes
			const addRoute = props.savedRoutesList.filter(route => route.routesid === id)[0];
			setSelectedSavedRoutes([...selectedSavedRoutes, addRoute.routesid])
		} else if (action === 'unselect' && routeType === 'allRoute') {
			// unselect from all routes
			setSelectedAllRoutes(selectedAllRoutes.filter(route => route !== id))
		} else if (action === 'unselect' && routeType === 'savedRoute') {
			// unselect from saved routes
			setSelectedSavedRoutes(selectedSavedRoutes.filter(route => route !== id))
		}
	}

	const checkAllClick = () => {
		setSelectedAllRoutes(chooseAll ? [] : props.filterRoute.map(route => route.routesid))
	};
	// const OnSearchRemove = (index) => {
	// 	var array = [...searchKeys];
	// 	if (index !== -1) {
	// 		array.splice(index, 1);
	// 		addSearchKey(array);
	// 		filterRoutes(array);
	// 	}
	// };
	// const OnClearAll = () => {
	// 	var array = [...searchKeys];
	// 	array.splice(0, array.length);
	// 	addSearchKey(array);
	// 	setFilterRoute(routes);
	// 	history.push(INDEX_URL + "locationHistory")

	const checkAllSavedClick = () => {
		setSelectedSavedRoutes(chooseAllSaved ? [] : props.savedRoutesList.map(route => route.routesid))
	};

	useEffect(() => {
		if (!isSelection) {
			setSelectedAllRoutes([])
			setSelectedSavedRoutes([])
		}
	}, [isSelection])


	// check if all are selected or not. This runs on each selection/unselection
	useEffect(() => {
		// console.log('inside route component:', props.savedRoutesList)
		const isAllSelected = props.filterRoute.map(route => route.routesid).every(v => selectedAllRoutes.includes(v))
		const includesAllSaved = props.savedRoutesList.map(route => route.routesid).every(v => selectedSavedRoutes.includes(v))

		setChooseAll(isAllSelected)
		setChooseAllSaved(includesAllSaved)
	}, [selectedAllRoutes, selectedSavedRoutes, props.filterRoute, props.savedRoutesList]);

	useEffect(() => {
		props.loadRoutes();
	}, []);

	return (
		<>
			<React.Fragment>
				<div className={` flex-column d-block ${props.downArrowClick ? " d-md-block" : "d-md-flex"}`}>
					<div className="control_bar d-md-flex d-none d-md-block margin_left_6 mt-1" style={{ marginTop: "15px" }}>
						{isSelection ? (
							<img
								className="mr-2 mt-1 ml-2"
								style={{ width: "20px", height: "20px" }}
								src={chooseAll ? FlavorImages.checked : FlavorImages.check_empty}
								alt={(chooseAll ? "" : "un") + "checked"}
								onClick={() => checkAllClick()}
							/>
						) : null}
						<label className={`mt-2 pr-3 text_22_700 uppercase d-none d-md-block ${!isSelection && "margin_left_6"}`}>
							{t("Routes.AllRoutes")}
						</label>
						<div className="accordion disply_none_small" id="accordiontwo">
							<ActionButton onClick={() => setShowActionDialog(true)} />
							<ConfrimDeleteAction
								closeConfirmDialog={() => closeConfirmDialog()}
								show={showActionDialog}
								isSelection={isSelection}
								closeActionDialog={() => setShowActionDialog(false)}
								setIsSelection={(value) => setIsSelection(value)}
							/>
						</div>
					</div>
					<div className={`${isSelection ? "scroll_div_routes_action" : "scroll_div_routes"} mt-1 white_shadow d-none d-md-block legend rounded`}>
						{props.filterRoute.length !== 0 ? (
							props.filterRoute.map((routes, index) => {
								return (
									<React.Fragment key={index}>
										<RoutesRows
											Routes={routes}
											selectedRoutes={selectedAllRoutes}
											updateSelection={updateSelection}
											Route={"allRoute"}
											isSelection={isSelection}
											index={index}
											selected={chooseAll}
											// routeIdstrip={locationHistoryState.routeIdstrip}
											// setRouteIdStrip={() => props.setStatisticsGraphShow(true)}
											setRouteIdStrip={(id) => setrouteIdForStrip(id, "allRoute")}
											routeIdstrip={routeIdstrip}
											clickedRouteId={clickedRouteId}
											setClickedRouteId={(id) => setRouteId(id, "allRoute")}
											reloadRoutes={props.loadRoutes}
										/>
									</React.Fragment>
								);
							})
						) : (
							<>
								<div className="d-flex row pb-5">
									<label className="no_data_row justify-content-center w-100 mt-4r text-22-700 text-grey2 px-2">
										{t("Routes.NoRegisteredRoutes")}
									</label>
								</div>
							</>
						)}
					</div>
				</div>

				{/* Saved routes */}
				<div
					className={`d-none d-md-block`}
				>
					<div
						className="control_bar d-md-block margin_left_6  mt-4"
						style={{ marginTop: "15px" }}
					>
						{isSelection ? (
							<img
								className="mr-2 mt-1 ml-2"
								style={{ width: "20px", height: "20px" }}
								src={chooseAllSaved ? FlavorImages.checked : FlavorImages.check_empty}
								alt={(chooseAllSaved ? "" : "un") + "checked"} //checked | unchecked
								onClick={() => checkAllSavedClick()}
							/>
						) : null}
						<label
							className={`mt-2 pt-1 pr-3 text-22-700 uppercase d-none d-md-inline ${!isSelection && "margin_left_6"
								}`}
						>
							<label className="text_22_700 uppercase ">
								{t("Routes.SavedRoutes")}{" "}
							</label>
							<span className="text_16_700 pl-2" style={{ lineHeight: 'normal' }}>{props.savedRoutesText}</span>
						</label>
					</div>
					<div className={`${isSelection ? "scroll_div_routes_action" : "scroll_div_routes"} scroll_div_routes legend white_shadow d-md-block rounded`}>
						{props.savedRoutesList.length !== 0 ? (
							props.savedRoutesList.map((routes, index) => {
								return (
									<React.Fragment key={index}>
										<RoutesRows
											Routes={routes}
											selectedRoutes={selectedSavedRoutes}
											updateSelection={updateSelection}
											Route={"savedRoute"}
											routeIdstrip={saveRouteIdStrip}
											isSelection={isSelection}
											selected={chooseAllSaved}
											index={index}
											setRouteIdStrip={(id) => setrouteIdForStrip(id, "savedRoute")}
											clickedRouteId={clickedSavedRouteId}
											setClickedRouteId={(id) => setRouteId(id, "savedRoute")}
											reloadRoutes={props.loadRoutes}
										/>
									</React.Fragment>
								);
							})) : (
							<>
								<div className="d-flex row pb-5">
									<label className="no_data_row justify-content-center w-100 mt-4r text-22-700 text-grey2 px-2">
										{t("Routes.NoSavedRoutes")}
									</label>
								</div>
							</>
						)}
					</div>
				</div>

				{/* for mobile routes rows work differently */}
				<div className={`mt-3 scroll_div_mobile ${props.statisticsGraphShow ? "h-100" : "height_mobile_380"}  d-block d-md-none legend rounded
					`}>
					{
						props.statisticsGraphShow ?
							<StatisticsGraphMobile />

							: <>
								{props.routesaveCheck ?
									props.savedRoutesList.length !== 0 ? (props.savedRoutesList.map((routes, index) => {
										return (
											<React.Fragment key={index}>
												<RoutesRows
													Routes={routes}
													selectedRoutes={selectedAllRoutes}
													updateSelection={updateSelection}
													Route={"savedRoute"}
													isSelection={isSelection}
													index={index}
													selected={chooseAll}
													routeIdstrip={routeIdstrip}
													setRouteIdStrip={(id) => setRoute(id)}
													clickedRouteId={clickedRouteId}
													setClickedRouteId={(id) => setClickedRouteId(id)}
													reloadRoutes={props.loadRoutes}
												// Routes={routes}
												// selectedRoutes={selectedSavedRoutes}
												// updateSelection={updateSelection}
												// Route={"savedRoute"}
												// isSelection={isSelection}
												// index={index}
												// selected={chooseAllSaved}
												// routeIdstrip={routeIdstrip}
												// setRouteIdStrip={(id) => setRoute(id)}
												// clickedRouteId={clickedSavedRouteId}
												// setClickedRouteId={(id) => setClickedRouteId(id)}
												// reloadRoutes={props.loadRoutes}
												/>
											</React.Fragment>
										);
									})) : (
										<>
											<div className="d-flex row pb-5">
												<label className="no_data_row justify-content-center w-100 mt-4r text-22-700 text-grey2 px-2">
													{t("Routes.NoSavedRoutes")}
												</label>
											</div>
										</>
									) : props.filterRoute.length !== 0 ? (
										props.filterRoute.map((routes, index) => {
											return (
												<React.Fragment key={index}>
													<RoutesRows
														Routes={routes}
														selectedRoutes={selectedAllRoutes}
														updateSelection={updateSelection}
														Route={"allRoute"}
														isSelection={isSelection}
														index={index}
														selected={chooseAll}
														routeIdstrip={routeIdstrip}
														setRouteIdStrip={(id) => setRoute(id)}
														clickedRouteId={clickedRouteId}
														setClickedRouteId={(id) => setClickedRouteId(id)}
														reloadRoutes={props.loadRoutes}
													/>
												</React.Fragment>
											);
										})
									) : (
										<>
											<div className="d-flex row pb-5">
												<label className="no_data_row justify-content-center w-100 mt-4r text-22-700 text-grey2 px-2">
													{t("Routes.NoRegisteredRoutes")}
												</label>
											</div>
										</>
									)}
							</>
					}
				</div>
				{/* -------------------------- */}
				{isSelection === true ? (
					<div className="w-100 pt-3 pt-md-3 slide_up_toggle_btn d-flex  justify-content-md-end bottom_button_devices">
						<button
							onClick={() => setIsSelection(false)}
							className={`grey_btn_device  `}
						>
							{t("Device.CANCEL")}
						</button>
						<button
							onClick={() => setShowConfirmDeleteDialog()}
							className={`btn_theme_device bg_dark_helkama ml-2 `}
							id="next"
						>
							{t("ConfirmDelete.DeleteRoute")}
						</button>
					</div>
				) : (
					" "
				)}
				<ConfirmDelete
					closeConfirmDeleteDialog={() => setshowConfirmDeleteRoute(false)}
					show={showConfirmDeletRoute}
					headingText={t("ConfirmDelete.DeleteRoute")}
					confirmBodyText={t("ConfirmDelete.confirmTiltfive")}
					confirmBtnText={t("ConfirmDelete.Delete")}
					onConfirm={() => bulkDeleteRoute()}
				/>
				{/* <ConfirmDeleteRoute
					closeConfirmDialog={() => closeConfirmDialog()}
					show={showActionDialog}
					closeActionDialog={() => setShowActionDialog(false)}
					setIsSelection={() => setIsSelection(true)}
				/> */}
				<ErrorDialog
					showErrorDialog={errorDialog}
					select={"Device"}
					errorMesage={errorDialog}
					onClose={() => setShowErrorDialog(false)}
				/>
				<RouteInfo
					show={showRoutInfoDialog}
					onClose={() => setShowRoutInfoDialog(false)}
				/>
			</React.Fragment>
		</>
	);
};

export default SearchLocationHistory;
