import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { INDEX_URL } from "../../helpers/constants";
const AuthChecker = () => {
  const history = useHistory();
  const location = useLocation();
  const { authState } = useSelector(
    (state) => ({ authState: state.auth }),
    shallowEqual
  )
  const isTokenExist = async () => {
    let current_path = location.pathname;
    if (authState.authToken) {
      if (
        current_path === INDEX_URL ||
        current_path.indexOf("forget-password") > -1 ||
        current_path.indexOf("signup") > -1
      ) {
        history.push(INDEX_URL + "devices");
      }
    } else {
      if (
        current_path !== INDEX_URL &&
        current_path.indexOf("forget-password") === -1 &&
        current_path.indexOf("signup") === -1 &&
        current_path.indexOf("forgotpass") === -1 && current_path.indexOf("createaccountbyinvitation") === -1
      ) {
        history.push(INDEX_URL);
      }
    }
  };

  useEffect(() => {
    isTokenExist();
  }, []);

  return <></>;
};

export default AuthChecker;
