import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { shallowEqual, useSelector } from "react-redux";
import React from "react";
import { useTranslation } from "react-i18next";
// import check from "../../assets/images/check.svg";
// import close_gray from "../../assets/images/close_gray.svg";
import { useDispatch } from "react-redux";
import { devicesSlice } from "../../slice/deviceSlice";
// import straigth_Arrow from "../../assets/images/straigth_Arrow.svg";
// import Upside_down from "../../assets/images/Upside_down.svg"
import FlavorImages from "../../assetsFolderImport";
const deviceActions = devicesSlice.actions;
const flavor = process.env.REACT_APP_FLAVOR;
const SortDevice = (props) => {
  const { sortState } = useSelector(
    (state) => ({ sortState: state.devices }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const changeSortOrder = (status) => {
    let sortOrder = undefined;
    if (sortState.sortOrder === 'ASC') sortOrder = 'DSC';
    else if (sortState.sortOrder === 'DSC') sortOrder = '';
    else if (sortState.sortOrder === '') sortOrder = 'ASC';
    dispatch(deviceActions.setSortOrder({ sortOrder: sortOrder }));

    dispatch(
      deviceActions.setSort({
        sort: status,
      })

    );
  }

  const renderDetailCard = (status) => {
    return (
      <Card className={`py-0 px-1 ${props.deviceType === "mobile" ? "width_250" : "width_300"}`} >
        <div className="d-flex px-1 pt-3 border-bottom">
          <label className="text_17_700">{t("customFile.SortVIN")}</label>
          <img
            className="size_20 right_7 cursor-pointer position-absolute close_img"
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
            src={FlavorImages.close_gray}
            onClick={() => props.onClose()}
            alt="..."
          />
        </div>
        <ul className="list-group list-group-flush ">
          <li
            className="cursor-pointer py-0 list-group-item d-flex justify-content-between align-items-center"
            onClick={() => {
              changeSortOrder("name")
            }}
          >
            <p className={`my-2 text_12_700 ${sortState.sortOrder != "" && sortState.sort === "name" ? "text-navy" : "text-gray"
              }`}
            >
              {t("deviceSortList.Name")}
              <img
                className=" right_sor_icon cursor-pointer ml-1"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
                src={sortState.sortOrder === "DSC" && sortState.sort === "name" ? FlavorImages.straigth_Arrow : sortState.sortOrder === "ASC" && sortState.sort === "name" ? FlavorImages.Upside_down : ""}
              />
            </p>
            {sortState.sortOrder !== "" && renderCheckImage("name")}
          </li>
          <li
            className="cursor-pointer py-0 list-group-item d-flex justify-content-between align-items-center"
            onClick={() => {
              changeSortOrder("status")
            }}
          >
            <p className={`my-2 text_12_700 ${sortState.sortOrder != "" && sortState.sort === "status" ? "text-navy" : "text-gray"
              }`}
            >
              {t("deviceSortList.Status")}
              <img
                className=" right_sor_icon cursor-pointer ml-1"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
                src={sortState.sortOrder === "DSC" && sortState.sort === "status" ? FlavorImages.Upside_down : sortState.sortOrder === "ASC" && sortState.sort === "status" ? FlavorImages.straigth_Arrow : ""}
              />
            </p>
            {sortState.sortOrder !== "" && renderCheckImage("status")}
          </li>
          <li
            className="cursor-pointer py-0 list-group-item d-flex justify-content-between align-items-center"
            onClick={() => {
              changeSortOrder("lastupdate")
            }}
          >
            <p className={`my-2 text_12_700 ${sortState.sortOrder != "" && sortState.sort === "lastupdate" ? "text-navy" : "text-gray"
              }`}
            >
              {t("deviceSortList.Lastupdate")}
              <img
                style={{ left: "71px" }}
                className=" right_sor_icon cursor-pointer ml-1"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
                src={sortState.sortOrder === "DSC" && sortState.sort === "lastupdate" ? FlavorImages.Upside_down : sortState.sortOrder === "ASC" && sortState.sort === "lastupdate" ? FlavorImages.straigth_Arrow : ""}
              />
            </p>
            {sortState.sortOrder !== "" && renderCheckImage("lastupdate")}
          </li>
          {flavor !== "Bike" ?           
            <li
              className="cursor-pointer py-0 list-group-item d-flex justify-content-between align-items-center"
              onClick={() => {
                changeSortOrder("groupname")
              }}
            >
              <p className={`my-2 text_12_700 ${sortState.sortOrder != "" && sortState.sort === "groupname" ? "text-navy" : "text-gray"
                }`}
              >
                {t("deviceSortList.Groups")}
                <img
                  style={{ left: "45px" }}
                  className=" right_sor_icon cursor-pointer ml-1"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  src={sortState.sortOrder === "DSC" && sortState.sort === "groupname" ? FlavorImages.Upside_down : sortState.sortOrder === "ASC" && sortState.sort === "groupname" ? FlavorImages.straigth_Arrow : ""}
                />
              </p>
              {sortState.sortOrder !== "" && renderCheckImage("groupname")}
            </li>
          : 
            null
          }

          <li
            className="cursor-pointer py-0 list-group-item d-flex justify-content-between align-items-center"
            onClick={() => {
              changeSortOrder(`battery`)
            }}
          >
            <p className={`my-2 text_12_700 ${sortState.sortOrder != "" && sortState.sort === `battery` ? "text-navy" : "text-gray"
              }`}
            >
              {t("deviceSortList.Battery")}
              <img
                style={{ left: "46px" }}
                className=" right_sor_icon cursor-pointer ml-1"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
                src={sortState.sortOrder === "DSC" && sortState.sort === `battery` ? FlavorImages.Upside_down : sortState.sortOrder === "ASC" && sortState.sort === `battery` ? FlavorImages.straigth_Arrow : ""}
              />
            </p>
            {sortState.sortOrder !== "" && renderCheckImage("battery")}
          </li>
        </ul>
        <div className=" d-flex justify-content-end mb-2 mr-2">
          <button
            onClick={() => props.onClose()}
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
            className="btn bg-transparent btn-sm font-bold text-grey text_12_700 "
          >
            {t("button.Cancel")}
          </button>
          <button
            onClick={() => props.sortDevicesArray()}
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
            className="btn btn_navy bg_dark_helkama py-0 px-4 text-white ml-3"
            id="next"
          >
            {t("button.Select")}
          </button>
        </div>
      </Card>
    );
  };
  const renderCheckImage = (status) => {
    return (
      <img
        className={
          sortState.sort === status ? "remove-check pr-2" : "set-check pr-2"
        }
        src={FlavorImages.check}
        alt="..."
      />
    );
  };

  return (
    <>
      {props.deviceType === "mobile" ? <Dialog
        open={props.show}>
        {renderDetailCard()}
      </Dialog>
        : <div
          id="collapseOne"
          className="collapse mt-1 "
          style={{ width: "300px" }}
          data-parent="#accordionone"
        >
          {renderDetailCard()}
        </div>}
    </>
  );
};
export default SortDevice;
