import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React from "react";
import { useTranslation } from "react-i18next";
// import close_gray from "../../assets/images/close_gray.svg";
import { settingsSlice } from "../../slice/settingsSlice";
import { useDispatch } from "react-redux";
import FlavorImages from "../../assetsFolderImport";
const settingsActions = settingsSlice.actions;
const ConfirmDialog = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const clearDialog = () => {
    dispatch(settingsActions.setClear({ cleardial: false }));
    props.closeConfirmDialog();
  };

  return (
    <>
      <Dialog maxWidth={"xs"} fullWidth open={props.show}>
        <Card className="card">
          <div className="text-center">
            <div className="py-3 px-4 d-flex justify-content-between align-items-center card-header bg-white">
              <label className=" mb-0 text-grey text_17_700">
                {props.select === "ServiceLocation"
                  ? t("Settings.OpenExternalLink")
                  : t("forgotPassword.Confirm")}
              </label>
              <img
                className="size_1-5_rem cursor-pointer"
                src={FlavorImages.close_gray}
                onClick={() => clearDialog()}
                alt="..."
              />
            </div>
          </div>
          <div className="row ml-1 mt-3 py-2">
            <div className="col-12 col-md-10 offset-md-1 ">
              <div className=" w-100 row  justify-content-center">
                {props.select === "SharingStatus" ? (
                  <p className=" text-grey text_12_500 ">
                    {t("Settings.StropShareMsg1")}{" "}
                    <strong>{props.deviceName}</strong>{" "}
                    {t("Settings.StropShareMsg2")}{" "}
                    <strong>{props.email}</strong>?
                  </p>
                ) : props.select === "ServiceLocation" ? (
                  <p className=" text-grey text_12_500 ">
                    {t("Settings.OpenNavigationMsg")}{" "}
                  </p>
                ) : (
                  <p className=" text-grey text_12_500 ">
                    {t("Settings.ClearLoginHistoryMsg")}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="m-0 card-footer  d-flex justify-content-end">
            <button
              className="btn bg-transparent btn-sm font-bold text-grey"
              onClick={() => clearDialog()}
              variant="light"
            >
              {t("actionDialog.cancel")}
            </button>

            <button
              className="btn btn_navy bg_dark_helkama text-uppercase ml-3"
              onClick={() => props.onConfirm()}
              id="next"
            >
              {props.select === "SharingStatus"
                ? t("geofences.CONFIRM")
                : props.select === "ServiceLocation"
                  ? t("Settings.open")
                  : t("loginHistory.Clear history")}
            </button>
          </div>
        </Card>
      </Dialog>
    </>
  );
};

export default ConfirmDialog;
