import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import share_grey from "../../assets/images/share_grey.svg";
import { SITE_TITLE } from "../../helpers/constants";
import { seo } from "../../helpers/seo";
import * as genericService from "../../services/genericService";
import BikeRow from "./BikeRow";
import AddBike from "./AddBike";
import ShareInviteRow from "./ShareInviteRow";
import { shallowEqual, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { devicesSlice } from "../../slice/deviceSlice";
import ErrorDialog from "../LocationHistory/ErrorDialog";
import { settingsSlice } from "../../slice/settingsSlice";
import BackWardArrow from "../../components/Buttons/BackwardArrow";
import FlavorImages from "../../assetsFolderImport";

const settingsActions = settingsSlice.actions;
const Bikes = (props) => {
  const { devicesState } = useSelector(
    (state) => ({ devicesState: state.devices }),
    shallowEqual
  );
  const { t } = useTranslation();
  const [devices, setDevices] = useState();
  const [myBikes, setMyBikes] = useState([]);
  const [errorDialog, setShowErrorDialog] = useState(false)
  const [errorMesage, setErrorMesage] = useState("")
  const dispatch = useDispatch();
  const [shareMe, setShareMe] = useState([]);
  const [waitingForAcceptBikes, setwaitingforacceptBikes] = useState([]);
  const [addBikeDialog, setAddBikeDialog] = useState(false);
  const [MySharedDevices, setMySharedDevices] = useState([])
  const deviceActions = devicesSlice.actions;

  const getDevicesList = async () => {


    const response = await genericService.get("devices/compactinfo");
    if (response.status === 200) {
      const devices = response.data.devices ?? [];
      const pendingDevices = response.data.pendingSharedDevices ?? [];
      setDevices(devices);
      const devicesSharedWithme = devices.filter((device) => device.sharedstatus.sharedwithme)
      setMySharedDevices(devicesSharedWithme)
      const myDevices = devices.filter((device) => !device.sharedstatus.sharedwithme)
      updateMyBikes(myDevices);
      setMyBikes(myDevices)
      updateShareWithMe(pendingDevices);
      dispatch(
        deviceActions.setUpPendingDevices({
          devices: pendingDevices,
        })
      );
      dispatch(
        deviceActions.setUpDeviceData({
          devices: devices,
        })
      );
    }
  };

  const updateMyBikes = (devices) => {
    var MyBikes = [];
    devices.map((device, index) => {
      if (device.sharedstatus.sharedwithme !== true) {
        MyBikes.push(device);
      }
    });
    dispatch(
      deviceActions.setUpFilteredDeviceData({
        devices: MyBikes
      })
    );
  };

  const updateShareWithMe = (devices) => {
    var MyBikes = [];
    var waitingForAcceptBikes = [];
    devices.map((device, index) => {
      if (device.sharedstatus.sharedwithme === true) {
        MyBikes.push(device);
      }
      if (
        device.sharedstatus.sharedwithme === true &&
        device.sharedstatus.waitingforaccept === true
      ) {
        waitingForAcceptBikes.push(device);
      }
    });
    setShareMe(MyBikes);
    setwaitingforacceptBikes(waitingForAcceptBikes);
  };

  const on_load = () => {
    getDevicesList();
    seo({
      title: SITE_TITLE,
      metaDescription: "",
    });
  };

  useEffect(() => {
    on_load();
  }, []);

  return (
    <>
      <div
        className={`${props.showUI === true ? "show_ui" : "hide_ui"
          } col-md-8 d-flex  margin-top_60 flex-column  inner`}
      >
        <BackWardArrow show={false}       //if passed false, it will be visible on below md devices, if passed true it will be visible irrespectively
          onClick={() => {
            props.setSelect_null()
            dispatch(settingsActions.setPage({ page: "" }));
          }}
        />
        {devicesState.filteredDevices !== undefined ? (
          <div className="d-flex  flex-column">
            <label className="text-uppercase text-left mt-4 text_11_300">
              {process.env.REACT_APP_FLAVOR === "Bike" ? t("customFile.MyBikes")  : t("customFile.MyDevice") }
            </label>

            <div className="d-flex flex-column  ml-md-0 ml-n1 ">
              <div className="scroll_div15 alarm_list_shadow">
                {myBikes.map((device, index) => {
                  return (
                    <BikeRow
                      device={device}
                      index={index}
                      key={index}
                      selectBikeDetail={(data) => props.setSelect("BikeDetail")}
                      setDeviceName={(data) => props.setDeviceName(data)}
                      isFirst={index === 0}
                      isLast={myBikes.length === index + 1}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        ) : null}
        {devices ? (
          <>
            <div className="d-flex mt-4 flex-column ml-md-0 ml-n1">
              <label className="text-uppercase text-left text_11_300">
                {process.env.REACT_APP_FLAVOR === "Bike" ? t("Settings.BikesSharedWithMe")  : t("Settings.DeviceSharedWithMe")}
              </label>
              <div className="scroll_div15 legend rounded">
                {MySharedDevices.map((device, index) => {
                  return (
                    <div key={index} className="d-flex flex-column">
                        <div className="scroll_rem_12">
                          <BikeRow
                            selectBikeDetail={() =>
                              props.setSelect("AcceptedShareBike")
                            }
                            device={device}
                            index={index}
                            key={index}
                            isFirst={index === 0}
                            isLast={MySharedDevices.length === index + 1}
                            setDeviceName={(data) =>
                              props.setDeviceName(data)
                            }
                          />
                        </div>
                      </div>
                  );
                })}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {waitingForAcceptBikes !== undefined ? (
          <div className="d-flex mt-4 flex-column">
            <label className="text-uppercase  text-left mt-4 text_11_300">
              {process.env.REACT_APP_FLAVOR === "Bike" ? t("Settings.BikeShareInvitation")  : t("Settings.DeviceShareInvitation")}
            </label>

            <div className="d-flex flex-column">
              <div className="scroll_rem_12 rounded alarm_list_shadow">
                {waitingForAcceptBikes.map((device, index) => {
                  return (
                    <ShareInviteRow
                      updateShareWithMe={() => getDevicesList()}
                      device={device}
                      index={index}
                      key={index}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        ) : null}

        <label className="text-uppercase  text-left mt-4 text_11_300">
          {t("Settings.AddAndShare")}
        </label>

        <div className="d-flex flex-column pb-5">
          <div onClick={() => setAddBikeDialog(true)} className="p-3 cursor-pointer rounded-top  white_shadow d-flex  border-bottom justify-content-between align-items-center bg-white w-100">
            <label className=" text_16_400 text-navy my-0">
              {t("customFile.AddNewBike")}
            </label>

            <img
              onClick={() => setAddBikeDialog(true)}
              src={FlavorImages.plus_grey}
              className="size_15"
              alt="..."
            />
          </div>

          <div onClick={() => props.setShairedBike()} className="p-3 cursor-pointer rounded-bottom white_shadow  d-flex   justify-content-between align-items-center bg-white w-100">
            <label className=" text_16_400 text-navy my-0">
              {t("customFile.ShareBike")}
            </label>

            <img
              onClick={() => props.setShairedBike()}
              src={FlavorImages.share_grey}
              className="size_15"
              alt="..."
            />
          </div>
        </div>
      </div>
      <AddBike
        loadDevices={() => getDevicesList()}
        show={addBikeDialog}
        setShowErrorDialog={() => setShowErrorDialog(true)
        }
        setErrorMesage={(msg) => setErrorMesage(msg)}
        closeAddBikeDialog={() => setAddBikeDialog(false)}
      />
      <ErrorDialog
        showErrorDialog={errorDialog}
        errorMesage={errorMesage}
        select={"settings"}
        onClose={() => setShowErrorDialog(false)}
      />
    </>
  );
};

export default Bikes;
