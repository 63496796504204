import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { registerSlice } from "../../slice/registerSlice";
import { INDEX_URL, SITE_TITLE } from "../../helpers/constants";
import { seo } from "../../helpers/seo";
import ReactHtmlParser from "react-html-parser";
import LanguageChanger from "../../components/LanguageChanger/LanguageChanger";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
// import dot_selected from "../../assets/images/dot_selected.svg";
// import dot_selected_helkam from "../../assets/Helkama_images/dot_selected_helkam.svg";
// import dot_unselected from "../../assets/images/dot_unselected.svg";
// import cross_red from "../../assets/images/cross_red.svg";
// import check_light_navy from "../../assets/images/check_light_navy.svg";
// import backward_arrow_grey from "../../assets/images/backward_arrow_grey.svg";

import { MAIL_FORMATE } from '../../helpers/constants';
import FlavorImages from "../../assetsFolderImport";

const registerActions = registerSlice.actions;
const SignUpPassword = () => {
  const { registerState } = useSelector(
    (state) => ({ registerState: state.register }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [removedot, setRemoveDot] = useState(false)
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [is_valid_password, setIsValidPassword] = useState(-1);
  const [is_valid_confirm_password, setIsValidConfirmPassword] = useState(-1);

  const clickNext = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      dispatch(
        registerActions.setStepPasswordData({ password: fields["Password"] })
      );
      history.push(INDEX_URL + "signup/accountDetail");
    }
  };

  const handleValidation = () => {
    let fields_temp = _.cloneDeep(fields);
    let errors = {};
    let formIsValid = true;

    if (!fields_temp["Password"]) {
      formIsValid = false;
      errors["Password"] = t("errors.Please enter password");
      setIsValidPassword(0);
    } else if (
      fields_temp["Password"] !== "undefined" &&
      fields["Password"].length < 6
    ) {
      formIsValid = false;
      errors["Password"] = t("errors.Password must be at least 6 characters");
      setIsValidPassword(0);
    } else if (!fields_temp["ConfirmPassword"]) {
      formIsValid = false;
      errors["ConfirmPassword"] = t("errors.Please enter confirm password");
      setIsValidConfirmPassword(0);
    } else if (fields_temp["ConfirmPassword"] !== fields_temp["Password"]) {
      formIsValid = false;
      errors["ConfirmPassword"] = t("errors.PasswordNotMatch");
      setIsValidConfirmPassword(0);
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleChange = (field, e) => {
    let fields_temp = _.cloneDeep(fields);
    let errors = {};
    fields_temp[field] = e.target.value;

    if (fields_temp.Password !== "" && fields_temp.Password !== undefined) {
      setIsValidPassword(-1);
    } else {
      errors["Password"] = "";
      setIsValidPassword(-1);
    }

    if (
      fields_temp.ConfirmPassword !== "" &&
      fields_temp.ConfirmPassword !== undefined
    ) {
      setIsValidConfirmPassword(-1);
    } else {
      errors["ConfirmPassword"] = "";
      setIsValidConfirmPassword(-1);
    }

    setFields(fields_temp);
    setErrors(errors);
  };

  const validatePrefilledData = () => {
    const fields_to_validate = ["email"];
    for (const field of fields_to_validate) {
      if (!registerState[field]) {
        history.push(INDEX_URL + "signup");
        break;
      }
    }
  };

  const on_load = () => {
    validatePrefilledData();

    seo({
      title: SITE_TITLE,
      metaDescription: "",
    });
  };

  useEffect(() => {
    on_load();
    if (registerState.pretoken) {
      setRemoveDot(true)
    }
  }, []);

  const emptyPassword = () => {
    setFields({ ...fields, NewPassword: "" });
    is_valid_password(-1);
  };

  const emptyConfirmPassword = () => {
    setFields({ ...fields, ConfirmPassword: "" });
    is_valid_confirm_password(-1);
  };

  return (
    <>
      <React.Fragment>
        <div className="col-12 p-4 h-100" id="login-page">
                <div className="row pt-2">
                    <div
                      className="btn_back rounded_white cursor-pointer justify-content-center align-items-center ml-3"
                      style={{ width: "35px", height: "35px" }}
                      onClick={() => window.history.back()}
                    >
                      <img src={FlavorImages.backward_arrow_grey} className="backward_button cursor-pointer" alt="..." />
                    </div>
                </div>
                <div className="container-fluid p-0">
            <div className="col-md-6 offset-md-3 col-lg-4 offset-lg-4 mt-4 pt-4  align-items-center">
              <img
                src={FlavorImages.app_logo}
                className="img-fluid mx-auto d-none d-sm-block photo"
                alt="..."
              />
              <div className="col-12  mt-5 pt-5">
                <div 
                className="col-12 d-flex align-items-center px-0 postition-relative"
                style={{ left: "-32px" }}>
                  <label className="text_34_700 col-form-label font_700_italic_gloria">
                    {"2. " + ReactHtmlParser(t("signup.Password"))}
                  </label>
                </div>

                <div className="col-12 p-0">
                  <label className="text_16_400 font_400_italic_gloria text-navy col-form-label">
                    {t("signup.PasswordSubTitle")}
                  </label>
                </div>

                <div className="col-12 inputBlock  col-sm-12 p-0 ">
                  <TextField
                    value={fields["Password"]}
                    type="Password"
                    className="col-12 Mui-focused label-input-bold"
                    id="Password"
                    label={ReactHtmlParser(t("signup.Password"))}
                    variant="standard"
                    placeholder="• • • • • •"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {is_valid_password === 0 ? (
                            <img
                              src={FlavorImages.cross_red}
                              className="text-red input-icon"
                            />
                          ) : is_valid_password === 1 ? (
                            <img
                              src={FlavorImages.FlavorImages.check_light_navy}
                              className="input-icon"
                            />
                          ) : (
                            <></>
                          )}
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleChange("Password", e);
                    }}
                    defaultValue={fields["Password"]}
                  />

                  <span></span>
                  {typeof errors["Password"] !== "undefined" &&
                    errors["Password"] !== "" ? (
                    <span className="invalid-feedback font_400_gloria font_400_gloria bottom">
                      {errors["Password"]}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-12 inputBlock col-sm-12  p-0">
                  <TextField
                    value={fields["ConfirmPassword"]}
                    type="Password"
                    className="col-12 Mui-focused label-input-bold"
                    id="ConfirmPassword"
                    label={ReactHtmlParser(t("Password.ConfirmPassword"))}
                    variant="standard"
                    placeholder="• • • • • •"
                    onChange={(e) => {
                      handleChange("ConfirmPassword", e);
                    }}
                    defaultValue={fields["ConfirmPassword"]}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {is_valid_confirm_password === 0 ? (
                            <img
                              src={FlavorImages.cross_red}
                              className="input-icon cursor-pointer"
                              onClick={() => {
                                emptyConfirmPassword();
                              }}
                            />
                          ) : is_valid_confirm_password === 1 ? (
                            <img
                              src={FlavorImages.check_light_navy}
                              className="input-icon"
                            />
                          ) : (
                            ""
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />

                  <span></span>
                  {typeof errors["ConfirmPassword"] !== "undefined" &&
                    errors["ConfirmPassword"] !== "" ? (
                    <span className="invalid-feedback font_400_gloria bottom">
                      {errors["ConfirmPassword"]}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="px-0 p-0 col-12 mt-5 no-gutters  p-0">
                  <div className="col-sm-12">
                    <button
                      type="submit"
                      className="btn_login background_black_helkama text_gloria_17_700_italic"
                      id="loginSubmit"
                      onClick={(e) => clickNext(e)}
                    >
                      {ReactHtmlParser(t("signup.Next"))}
                    </button>
                  </div>
                </div>

                <div className="p-0 row col-12 mt-4 justify-content-center align-items-center mx-0">
                  <img
                    alt="Step"
                    className="dot"
                    src={FlavorImages.dot_selected}
                    onClick={history.goBack}
                  />
                  <img alt="Step" className="dot ml-2 "
                    src={FlavorImages.dot_selected}
                  />
                  <img alt="Step" className="dot ml-2" src={FlavorImages.dot_unselected} />
                  {!removedot && <img alt="Step" className="dot ml-2" src={FlavorImages.dot_unselected} />}
                </div>
              </div>
            </div>
            <LanguageChanger />
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default SignUpPassword;
