import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BackWardArrow from "../../components/Buttons/BackwardArrow";
import ConfirmDialog from "./ConfirmDialog";
import * as genericService from "../../services/genericService";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import FlavorImages from "../../assetsFolderImport";

const LocationServiceRow = (props) => {
  const [locationService, setLocationService] = useState([]);
  const [userLocation, setUserLocation] = useState({ lat: null, lng: null });
  const [selectedLocation, setSelectedLocation] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setUserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  }, []);

  const haversineDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  };

  const getLocationService = async () => {
    const response = await genericService.get("services/locations");
    if (response.status === 200) {
      const locationservices = response.data.servicelocations ?? [];
      setLocationService(locationservices);
    }
  };

  useEffect(() => {
    getLocationService();
  }, []);

  const showPosition = (address) => {
    var url = "https://maps.google.com/?q=" + address;
    window.open(url);
  };

  const openGoogleMap = () => {
    if (selectedLocation) {
      showPosition(selectedLocation.address);
    }
  };

  const { t } = useTranslation();
  const [OpenLinkDialogBox, showOpenLinkDialogBox] = useState(false);

  const handleOpenDialog = (location) => {
    setSelectedLocation(location);
    showOpenLinkDialogBox(true);
  };

  return (
    <>
      <div
        className={`${props.showUI === true ? "show_ui" : "hide_ui"
          } col-md-8 mt-3 ml-1 d-flex flex-column inner`}
      >
        <BackWardArrow show={true} onClick={() => props.setSelect("Support")} />
        <div className="mt-2 mt-md-4 mb-3">
          {locationService.map((location, index) => {
            return (
              <div
                key={index}
                className="col-12 p-0  text-break"
                onClick={() => handleOpenDialog(location)}
              >
                <div className="device_row_small_select mx-1">
                  <Accordion className="accordion-no-styles">
                    <AccordionSummary
                      className=" p-0 m-0 "
                      aria-controls="panel1a-content"
                    >
                      <div className="col-12 px-0">
                        <div
                          className={`card border-0 height_75
               ${"bg-light-grey"}
               p-0`}
                        >
                          <div
                            className={`p-0 m-0 d-flex flex-column justify-content-center card-body w-100
                  ${index % 2 === 0
                                ? "white_background"
                                : "grey_shadow_f2"
                              }
                  ${index === 0
                                ? "border_top_8_8"
                                : locationService.length ===
                                  index + 1
                                  ? "border_bottom_8_8"
                                  : ""
                              }
                  `}
                          >
                            <div className="col-12 flex-row justify-content-between px-3">
                              <div className="col flex-column py-3">
                                <div className="col-12 m-0 p-0 text_16_700">
                                  {location.name}
                                </div>
                                <div className="m-0 p-0 text_12_400">
                                  {location.address}
                                </div>
                              </div>
                              <div className="flex-row align-items-center">
                                <div className="flex-row justify-content-between">
                                  <div className="flex-column mx-2">
                                    <div className="flex-row justify-content-center align-items-center">
                                      <img
                                        src={FlavorImages.map_maker_alt_two}
                                        className="mapicon d-none d-md-block"
                                        alt="..."
                                      />
                                      <img
                                        src={FlavorImages.map_marker_alt_blue}
                                        className="mapicon d-md-none"
                                        alt="..."
                                      />
                                    </div>
                                    <div className="flex-row justify-content-center align-items-center">
                                      {userLocation.lat && userLocation.lng ? (
                                        <label
                                          className="device_row_left_active_label mb-0"
                                          style={{
                                            marginLeft: "0px",
                                            fontSize: "12px",
                                            textAlign: "center",
                                          }}
                                        >
                                          {`${haversineDistance(
                                            userLocation.lat,
                                            userLocation.lng,
                                            location.latitude,
                                            location.longitude
                                          ).toFixed(0)} km`}
                                        </label>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </AccordionSummary>
                  </Accordion>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <ConfirmDialog
        onConfirm={openGoogleMap}
        closeConfirmDialog={() => showOpenLinkDialogBox(false)}
        show={OpenLinkDialogBox}
        select={props.select}
      />
    </>
  );
};

export default LocationServiceRow;
