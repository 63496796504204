import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
// import dot_selected from "../../assets/images/dot_selected.svg";
// import dot_unselected from "../../assets/images/dot_unselected.svg";
import CheckBox from "../../components/Control/CheckBox";
import LanguageChanger from "../../components/LanguageChanger/LanguageChanger";
import { INDEX_URL } from "../../helpers/constants";
import { registerSlice } from "../../slice/registerSlice";
import { useParams } from "react-router-dom";
import FlavorImages from "../../assetsFolderImport";

const registerActions = registerSlice.actions;
const PreassginedSignup = () => {
  const dispatch = useDispatch();
  const [preAssigneEamil,setpreAssigneEamil]=useState("")
  let { token } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const [termAndServiceError2, setTermAndServiceError2]=useState("confirmerror")
  const [termAndServiceError, setTermAndServiceError] = useState("confirmerror")
  const [receieverEmail, setReceiverEmail] = useState("")
  const [signupToken, setSignupToken] = useState("")

  const { registerState } = useSelector(
    (state) => ({ registerState: state.register }),
    shallowEqual
  );
  const clickNext = async (e) => {
    e.preventDefault();
    if (registerState.agree && registerState.agree2) {
      dispatch(registerActions.setStepEmailData({ email: token }));
      dispatch(registerActions.setokenData({pretoken: true}))
      //console.log(signupToken);
      history.push(INDEX_URL + "signup/password");
    }
    else if(!registerState.agree && !registerState.agree2)
    {
      setTermAndServiceError(t("errorCode.AgreeTermsCondition"))
      setTermAndServiceError2(t("errorCode.AgreeServiceLocation")) 
    }
    else if(!registerState.agree){
      setTermAndServiceError(t("errorCode.AgreeTermsCondition")) 
    }
    else if(!registerState.agree2){
      setTermAndServiceError2(t("errorCode.AgreeServiceLocation")) 
    }
    if(registerState.agree2){
      setTermAndServiceError2("confirmerror")
    }
  };

  const setCheckMark2 = async (e) => {
    if (registerState.agree2) {
      dispatch(registerActions.setAgree2({ agree2: false }));
    } else {
      dispatch(registerActions.setAgree2({ agree2: true }));
    }
  };

  useEffect(() => {
    //setpreEmail(token)
    decodeToken()
    dispatch(registerActions.setStep({ step: "email" }));
  }
    , []);

  const setCheckMark = async (e) => {
    if (registerState.agree) {
      dispatch(registerActions.setAgree({ agree: false }));
    } else {
      dispatch(registerActions.setStepEmailData({ email: token }));
      dispatch(
        registerActions.setStepConfirmEmailData({
          confirmEmail: token,
        })
      );
      history.push(INDEX_URL + "terms-of-use");
    }
  };

  const decodeToken = () => {
    var Authorization = new Buffer.from(token, 'base64').toString('utf8');
    var AuthorizationSplit = Authorization.split(':');
    //console.log(AuthorizationSplit);
    setReceiverEmail(AuthorizationSplit[0])
    setSignupToken(AuthorizationSplit[1])
  }



    //console.log(useParams());

  return (
    <>
      <React.Fragment>
        <div className="col-12 p-4 vh-100" id="login-page">
          <div className="container-fluid p-0">
            <div className="vh-100 d-flex col-md-6 offset-md-3 col-lg-4 offset-lg-4  align-items-center">
              <div className="col-12 mt-4 pl-2  ">
                <div className="col-12   d-flex align-items-center  px-0">
                  <label
                    htmlFor="Email"
                    className="text_34_700 col-form-label "
                  >
                    {"1. " + ReactHtmlParser(t("signup.Email"))}
                  </label>
                </div>

                <div className="col-12 ml-3 pl-4 ">
                  <label
                    htmlFor="Email"
                    className="text_16_400 text-navy col-form-label"
                  >
                    {process.env.REACT_APP_FLAVOR === "Bike" 
                    ? ReactHtmlParser(t("signup.Predefined Email Bike"))
                    : ReactHtmlParser(t("signup.Predefined Email Device"))
                    }
                    
                  </label>
                </div>

                <div className="col-12 ml-3 pt-5 d-flex flex-column inputBlock  col-sm-12  pl-4">
                  <label
                    className="text_17_700 text-navy col-form-label"
                  >
                    {ReactHtmlParser(t("signup.Email"))}
                  </label>
                  <label
                    className="text_12_400_grey text-gray col-form-label"
                  >
                    {receieverEmail}
                  </label>
                </div>
                <div className="col-12 row privacy-box px-0 mx-0 mt-5  pl-4 justify-content-between">
                  <div className="col-8 px-0 privacy">
                    <label htmlFor="Email">
                      {t("signup.agreeToOur")}
                      <Link
                        className="custom-checkbox-Link"
                        to={INDEX_URL + "terms-of-use"}
                      >
                        {ReactHtmlParser(t("signup.privacyPolicy"))}
                      </Link>
                      {t("signup.and")}
                      <Link
                        className="custom-checkbox-Link"
                        to={INDEX_URL + "terms-of-use"}
                      >
                        {ReactHtmlParser(t("signup.TermOfUse"))}
                      </Link>
                      {t("signup.toContinue")}
                    </label>
                  </div>
                  <div className="custom-checkbox">
                    <CheckBox
                      isChecked={registerState.agree}
                      onClick={(e) => setCheckMark(e)}
                    />
                  </div>
                </div>
                <div className={`col-12 row privacy-box px-0 mx-0  ${termAndServiceError === "confirmerror" && "d-none"} pb-4   justify-content-between`}>
                  <div className="invalid-feedback bottom">

                    {termAndServiceError === "confirmerror" ? "" : termAndServiceError}
                  </div>
                </div>
                  {/* Ny knapp */}
                  <div className="col-12 row privacy-box px-0 mx-0 mt-3 pl-4 justify-content-between">
                  <div className="col-8 px-0 privacy">
                    <label htmlFor="Email">
                      {t("signup.AgreeToLocation")}
                    </label>
                  </div>
                  <div className="custom-checkbox">
                    <CheckBox
                      isChecked={registerState.agree2}
                      onClick={(e) => setCheckMark2(e)}
                    />
                  </div>
                </div>
                <div className={`col-12 row privacy-box px-0 mx-0 ${termAndServiceError2 === "confirmerror" && "d-none"} pb-4 justify-content-between`}>
                  <div className="invalid-feedback font_400_gloria bottom">
                      {termAndServiceError2 === "confirmerror" ? "" : termAndServiceError2}
                  </div>
                </div>
                
                <div className="px-0 pl-4 col-12 mt-3 no-gutters" >
                  <div className="col-sm-12   ">
                    <button
                      type="submit"
                      className="btn_login"
                      id="loginSubmit"
                      onClick={(e) => clickNext(e)}
                    >
                      {ReactHtmlParser(t("signup.Next"))}
                    </button>
                  </div>
                </div>

                <div className="pl-4 row col-12 mt-4 justify-content-center align-items-center mx-0">
                  <img alt="Step" className="dot" src={FlavorImages.dot_selected} />
                  <img alt="Step" className="dot ml-2" src={FlavorImages.dot_unselected} />
                  <img alt="Step" className="dot ml-2" src={FlavorImages.dot_unselected} />
                </div>
              </div>
            </div>
            <LanguageChanger />
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default PreassginedSignup;
