import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SwitchUI from "../../components/CheckBoxes/SwitchUI";
import { SITE_TITLE } from "../../helpers/constants";
import { seo } from "../../helpers/seo";

const DoubleSwitchRow = (props) => {
  const on_load = () => {
    seo({
      title: SITE_TITLE,
      metaDescription: "",
    });
  };

  useEffect(() => {
    on_load();
  }, []);

  return (
    <>
      <div className=" bg-white w-100 mt-3 d-flex flex-column rounded justify-content-start align-items-start">
        <div className="p-3 d-flex w-100 border-bottom white_shadow rounded-top justify-content-between align-items-center">
          <label className=" text_16_400 text-navy my-0">{props.title1} </label>

          <SwitchUI
            isChecked={props.switch1}
            onClick={(e) => props.SwitchOneClick(!props.switch1)}
          />
        </div>

        <div className="p-3 d-flex w-100 white_shadow rounded-bottom justify-content-between align-items-center">
          <label className=" text_16_400 text-navy my-0">{props.title2} </label>

          <SwitchUI
            isChecked={props.switch2}
            onClick={(e) => props.SwitchTwoClick(!props.switch2)}
          />
        </div>
      </div>
    </>
  );
};

export default DoubleSwitchRow;
