/*
 * @Author: Abhi Bhatt
 * @Date:   2020-12-23 14:12:09
 * @Last Modified by:   abhi
 * @Last Modified time: 2021-04-14 12:41:01
 */
import React from "react";
import { useState } from "react";
// import Rundbluedown from "../../assets/images/Rundbluedown.svg";
// import twitter_active from "../../assets/images/twitter_active.svg";
// import Ellipse_sky_blue from "../../assets/images/Ellipse_sky_blue.svg";
// import envelope_active from "../../assets/images/envelope_active.svg";
// import Runddownblue from "../../assets/images/Runddownblue.svg";
import * as helper_functions from "../../helpers/helper_functions";
import FlavorImages from "../../assetsFolderImport";
// import facebook_active from "../../assets/images/facebook_active.svg";
const NewsRow = (props) => {
	const [newDetailId,setNewsDetail]=useState(false)
	const setNewsID = (id) => {
		if (id !== newDetailId) {
			setNewsDetail(id)
		}
		else {
			setNewsDetail("")
		}
	}
  return (
		<>
    <div
				onClick={props.setNewsDetail}
      className=" p-0   news_gradient rounded news_height"
      style={{ backgroundImage: `url(${props.news.newsimage})` }}
    >
      <div className="position-relative cursor-pointer p-0 news_gradient news_height rounded small_shadow">
        <div className=" w-100 d-flex flex-column bottom_div">
          <label className="text_17_700 text-white cursor-pointer ml-3 mb-0">
            {props.news.newstitle}
          </label>

          <label className="text_12_400 cursor-pointer text-white ml-3 mb-0">
            {helper_functions.default.formatDate(props.news.newsdate)}
          </label>
        </div>
				
      </div> 
    </div>
			<div className="row  d-md-none  p-0">
			<div className="col">
			{/* */}
			<div className={`position-relative  p-0 bg-white alarm_list_shadow ${newDetailId===props.news.newsid ?  "d-md-none d-block" : "d-none" }   `}>
			<div className=" w-100 d-flex flex-column">
				<label className="text_12_500 my-3 ml-3 mb-0">
								{props.news.newsbody}
				</label>	
			</div>
						{/* <div className="row ml-2 pb-2">
							<img
								src={facebook_active
								}
								className="size_25 mt-1" alt="..." />
							<div className="size_25 ml-2 picContainer">
								<img
									src={twitter_active
									}
									className="pic2 p-2 size_35" alt="..." />
								<img
									src={Ellipse_sky_blue
									}
									className="size_35" alt="..." />
							</div>
							<div className="size_25 ml-2 picContainer">
								<img
									src={envelope_active
									}
									className="pic2 p-2 size_35" alt="..." />
								<img
									src={Ellipse_sky_blue
									}
									className="size_35" alt="..." />
							</div>
						</div> */}
					</div>

			<div className={`col mt-n2 d-flex d-none justify-content-center`}>
			<img 
				src={newDetailId === props.news.newsid ?  FlavorImages.RoundDownBlue  : FlavorImages.roundBlueDown
				}
				onClick={() => setNewsID(props.news.newsid)}
			className="size_35" alt="..." />
		</div> 
</div>
		</div>


		</>
  );
};

export default NewsRow;
