import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import close_gray from "../../assets/images/close_gray.svg";
import { useSelector, shallowEqual } from "react-redux";
import * as devicesService2 from "../../services/devices/devicesService2";
import toast, { Toaster } from "react-hot-toast";
import FlavorImages from "../../assetsFolderImport";

const DeleteData = (props) => {
	const { t } = useTranslation();

	const submitDelete = async (type) => {
		const response = await devicesService2.deleteStats({deviceid: props.deviceDetails.id, type: type});
		if (response.status === 200) {
      props.closeModal();
      toast.success(t("toast.deleted"));
		} else {
			props.closeModal();
			props.setShowErrorDialog();
			props.setErrorMesage(response.data.message);
		}
	};
	return (
		<>
			<Dialog open={props.show}>
				<Card className="card">
					<div className="text-center">
						<div className="dialog_top_sort_row py-2 card-header" style={{ backgroundColor: "white" }}>
							<label className="card-title text-grey text_17_700 ml-4"> {t("bikeDetail.delete_data")}</label>
							<img className="cursor-pointer" src={FlavorImages.close_gray} onClick={() => props.closeModal()} alt="..." />
						</div>
						<div className="card-body py-3">
              <p className="text_12_700">{t("bikeDetail.delete_data_body")}</p>
							<div className="row">
								<div className="col-12">
									<div className="form-group col-12 mt-2">
										<div className="d-flex">
											<button className="btn btn_red text-uppercase" onClick={() => submitDelete("stats")} id="next">
												{t("bikeDetail.delete_stats")}
											</button>
											<button className="btn btn_red text-uppercase ml-2" onClick={() => submitDelete("routes")} id="next">
                        {t("bikeDetail.delete_routes")}
											</button>
											<button className="btn btn_red text-uppercase ml-2" onClick={() => submitDelete("events")} id="next">
                       {t("bikeDetail.delete_events")}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="m-0 card-footer  d-flex justify-content-end">
						<button className="btn btn_navy bg_dark_helkama text-uppercase ml-3" onClick={() => props.closeModal()} id="next">
            {t("bikeDetail.close")}
						</button>
					</div>
				</Card>
			</Dialog>
		</>
	);
};

export default DeleteData;
