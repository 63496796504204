import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SITE_TITLE } from "../../helpers/constants";
import { seo } from "../../helpers/seo";
import * as genericService from "../../services/genericService";

import { settingsSlice } from "../../slice/settingsSlice";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import BackWardArrow from "../../components/Buttons/BackwardArrow";
const settingsActions = settingsSlice.actions;
const Credit = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <>
      <div
        className={`${props.showUI === true ? "show_ui" : "hide_ui"
          } col-md-8 d-flex  mt-3 flex-column  inner`}
      >
        <BackWardArrow show={false}       //if passed false, it will be visible on below md devices, if passed true it will be visible irrespectively
          onClick={() => {
            props.setSelect_null()
            dispatch(settingsActions.setPage({ page: "" }));
          }}
        />
        <label className="text_28_700 text-left mt-4">
          {t("Settings.Credits")}
        </label>
        <label className="text-uppercase text_11_300 text-left mt-2">
          {t("Settings.Icons")}
        </label>

        <div className="d-flex flex-column p-3  rounded_white ">
          <label className=" text_11_300 text-grey my-0">
            {t("Settings.IllustrationStoryset")}
          </label>

          <label className=" text_11_300 text-grey my-0">
            {t("Settings.IllustrationStorysetURL")}
          </label>
        </div>

        <label className="text-uppercase text_11_300 text-left mt-2">
          {t("Settings.Maps")}
        </label>

        <div className="d-flex flex-column p-3  rounded_white ">
          <label className=" text_11_300 text-grey my-0">
            {t("Settings.GoogleMaps")}
          </label>

          <label className=" text_11_300 text-grey my-0">
            {t("Settings.GoogleMapsURL")}
          </label>
        </div>
      </div>
    </>
  );
};

export default Credit;
