import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual } from "react-redux";
import BackWardArrow from "../../components/Buttons/BackwardArrow";
import ConfirmDelete from "./ConfirmDelete";
import * as genericService from "../../services/genericService";

const DeleteShareBike = (props) => {
  const { t } = useTranslation();
  const [bikeDetail, setBikeDetail] = useState([]);
  const [confirmDelete, setConfrimDelete] = useState(false);
  const { devicesState } = useSelector(
    (state) => ({ devicesState: state.devices }),
    shallowEqual
  );
  const confirmDeleteBike = async () => {
    let deviceid = [bikeDetail.id];
    const response = await genericService.post("device/delete", {
      deviceid: deviceid,
    });
    if (response.status === 200) {
      setConfrimDelete(false);
      props.setSelect("Bikes")
    } else {
      props.setError(response.data.message);
      setConfrimDelete(false);
    }
  };

  const deviceDetail = () => {
        setBikeDetail(props.bikeDetail);
  };
  useEffect(() => {
    deviceDetail();
  },[]);
  return (
    <>
      <div
        className={`${props.showUI === true ? "show_ui" : "hide_ui"
          } col-md-8 ml-2 d-flex  mt-5 flex-column  inner`}
      >
        <BackWardArrow
          show={true}
          onClick={() => props.setSelect("AcceptedShareBike")}
          select={props.select}
        />
        <label className="text_28_700 mt-2">
          {t("bikeDetail.removeBike")}
        </label>
        <label className="ml-1 text-uppercase text_11_300 text-left mt-2">
          {t("DeleteShareBike.titleOne")}
        </label>

        <div className="d-flex flex-column  rounded_white ">
          <div
            className="p-3    d-flex  border-bottom justify-content-between align-items-center rounded-top rounded-bottom"
            style={{ background: "#ffffff", width: "100%" }}
          >
            <p className="text_13_400">
              {t("DeleteShareBike.titleTwo")}{" "}
            </p>
            <div></div>
          </div>
        </div>

        <label className="ml-1 text-uppercase text_11_300 text-left mt-2">
          {t("forgotPassword.Confirm")}
        </label>

        <div className="d-flex flex-column  rounded_white ">
          <div
            onClick={() => setConfrimDelete(true)}
            className="p-3 cursor-pointer   d-flex  border-bottom justify-content-center align-items-center rounded-top rounded-bottom"
            style={{ background: "#ffffff", width: "100%" }}
          >
            <label
              style={{ color: "red" }}
              className="cursor-pointer text_16_400 text-navy my-0"
            >
              {" "}
              {t("bikeDetail.removeBike")}{" "}
            </label>
            <div></div>
          </div>
        </div>
      </div>
      <ConfirmDelete
        onConfirm={() => confirmDeleteBike()}
        select={props.select}
        show={confirmDelete}
        confirmBodyText={t("ConfirmDelete.confirmTiltone")}
        confirmBtnText={t("ConfirmDelete.Remove")}
        sharedbyme={true}
        closeConfirmDeleteDialog={() => setConfrimDelete(false)}
      />
    </>
  );
};

export default DeleteShareBike;
