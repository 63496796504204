import axios from "axios";
import { API_URL } from "../../helpers/constants.js";
export async function getDevicesCompactInfo() {
  const url = `${API_URL}devices/compactinfo`;
  const headers = {
    "Content-Type": "application/json",
    "cache-control": "no-cache",
  };

  return axios({
    url: url,
    method: "GET",
    config: { headers: headers },
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export async function updateDevice(data) {
  const url = `${API_URL}device/update`;
  const headers = {
    "Content-Type": "application/json",
    "cache-control": "no-cache",
  };

  return axios({
    url: url,
    method: "POST",
    data,
    config: { headers: headers },
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}


export async function deleteStats(data) {
  const url = `${API_URL}data/device/delete`;
  const headers = {
    "Content-Type": "application/json",
    "cache-control": "no-cache",
  };

  return axios({
    url: url,
    method: "POST",
    data,
    config: { headers: headers },
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export async function getDevicesInfo() {
  const url = `${API_URL}devices/info`;

  return axios({
    url: url,
    method: "GET",
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export async function getDeviceGroupsList() {
  const url = `${API_URL}device/group/list`;
  const headers = {
    "Content-Type": "application/json",
    "cache-control": "no-cache",
  };

  return axios({
    url: url,
    method: "GET",
    config: { headers: headers },
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export async function updateDevicePower(ids, powerSwitch) {
  const url = `${API_URL}device/changerelay`;
  const headers = {
    "Content-Type": "application/json",
    "cache-control": "no-cache",
  };

  let formData = { ids, powerSwitch };

  return axios({
    url: url,
    method: "POST",
    data: formData,
    config: { headers: headers },
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export async function createGroup(data) {
  const url = `${API_URL}device/group/create`;
  const headers = {
    "Content-Type": "application/json",
    "cache-control": "no-cache",
  };

  return axios({
    url: url,
    method: "POST",
    data,
    config: { headers: headers },
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export async function updateGroup(data) {
  const url = `${API_URL}device/group/update`;
  const headers = {
    "Content-Type": "application/json",
    "cache-control": "no-cache",
  };

  return axios({
    url: url,
    method: "POST",
    data,
    config: { headers: headers },
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export async function deleteGroup(data) {
  const url = `${API_URL}device/group/delete`;
  const headers = {
    "Content-Type": "application/json",
    "cache-control": "no-cache",
  };

  return axios({
    url: url,
    method: "POST",
    data,
    config: { headers: headers },
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}
