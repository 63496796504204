let FlavorLottie
if (process.env.REACT_APP_FLAVOR == "Bike") {
    FlavorLottie = {
        yourAnimationData: require("./Lottie Animations/Bike/bell.json"),
        spinner: require("./Lottie Animations/Bike/spinner.json"),
        dataplan_exclamation_mark: require("./Lottie Animations/Bike/dataplan_exclamation_mark.json"),
        dataplan_checkmark: require("./Lottie Animations/Bike/dataplan_checkmark.json"),
        levelup: require("./Lottie Animations/Bike/levelup.json"),
        challenge_completed: require("./Lottie Animations/Bike/challenge_completed.json"),
        challenge_Failed: require("./Lottie Animations/Bike/challenge_cancelled_or_failed.json")
    }

}
else if (process.env.REACT_APP_FLAVOR == 'Tracking') {
    FlavorLottie = {
        yourAnimationData: require("./Lottie Animations/Tracking/bell.json")
    }
}
else if (process.env.REACT_APP_FLAVOR == 'Alco') {
    FlavorLottie = {
        yourAnimationData: require("./Lottie Animations/Alco/bell.json")
    }
}
export default FlavorLottie
