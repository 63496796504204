import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  authToken: null,
  authData: null,
  activeLanguage: false,
  notificationMessage:false,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setAuthData: (state, action) => {
      state.authData = action.payload.authData;
    },
    setNotificationMessage: (state, action) => {
      state.notificationMessage = action.payload.notificationMessage;
    },
    setAuthToken: (state, action) => {
      state.authToken = action.payload.authToken;
    },
    setActiveLanguage: (state, action) => {
      state.activeLanguage = action.payload.activeLanguage;
    },
  },
});
