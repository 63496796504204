import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import BackButton from "../../components/Buttons/backButton";
import DownloadButton from "../../components/Buttons/downloadButton";
// import signal_green from "../../assets/images/signal_green.svg";
// import signal_red from "../../assets/images/signal_red.svg";
// import watch from "../../assets/images/watch.svg";
import { useParams } from "react-router-dom"; 
// import distance from "../../assets/images/distance.svg";
import * as helper_functions from "../../helpers/helper_functions";
import { locationHistorySlice } from "../../slice/locationHistorySlice";
import { format } from "date-fns";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import * as genericService from "../../services/genericService";
import { INDEX_URL } from "../../helpers/constants";
import FlavorImages from "../../assetsFolderImport";



const downloadFile = async (starttime, stoptime, deviceId) => {
  let data = {
    deviceid: deviceId,
    starttime: starttime,
    stoptime: stoptime
  };

  const response = await genericService.post("history/export", data);
  if (response.status === 200) {
    helper_functions.default.downloadFileFromURL(response.data.downloadlink)
  }
}
const LocationHistoryInformation = (props) => {
  const locationHistoryActions = locationHistorySlice.actions;
  const dispatch = useDispatch();
  let { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const { locationHistorystate } = useSelector(
    (state) => ({ locationHistorystate: state.locationHistory }),
    shallowEqual
  );
  const getDate = (datetoFormat) => {
    let date = format(new Date(datetoFormat), "dd/MM HH:mm");
    return date;
  };
  let [data, setData] = useState({});
  const timepassed = (date) => {
    data = helper_functions.default.timePassedInUnixtimestamp(date);
    setData(data);
  };
  useEffect(() => {
    timepassed(locationHistorystate.deviceHistoryData[0].totalactivetime);
  }, [locationHistorystate.deviceHistoryData[0].totalactivetime, locationHistorystate.startEndDateLocationHistoryDate.deviceid]);

  const onClose = () => {
    // history.push(INDEX_URL + "locationHistory")
    dispatch(locationHistoryActions.setisHistoryInfromation({ isHistoryInfromation: false }));
  };

  return (
    <>
      <React.Fragment>
        <div
          className="col-12 col section-left_data h-50 pl-0"
          style={{ marginTop: "20px" }}
        >
          <div style={{ width: "30%" }}>
            <BackButton
              title={t("LocationHistory.Back")}
              onClick={() => onClose()}
            />
          </div>
          <div className="row  box_shadow_1 pl-md-0 pl-2 rounded-top mx-0 mt-4" style={{ background: "#ffffff" }}>
            <div
              className="col-12  col text-capitalize"
              style={{ width: "100%" }}
            >
              <label className="text_22_700 mt-2 ml-3 ">{t("LocationHistory.HistoryInformation")}</label>
            </div>
          </div>
          <div
            className="row mx-0 py-3 box_shadow_1 pl-md-0 pl-2 rounded-bottom "
            style={{ background: "#ffffff", marginTop: 0.9 }}
          >
            <div className="col-md-12">
              <div className="row">
                <div className="col-12"></div>
              </div>
              <div className="row  text-white">
                <div className="col-12">
                  <div className="row">
                    <div
                      className="col-md-2"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {locationHistorystate.startEndDateLocationHistoryDate.isActive === "online" ? (
                        <img
                          src={FlavorImages.signal_green}
                          className={`size_24 ml-2 ${props.powerMobileisSlection
                            ? "d-none d-sm-block"
                            : ""
                            }`}
                          alt="..."
                        />
                      ) : (
                        <img
                          src={FlavorImages.signal_red}
                          className={`size_24 ml-2 ${props.powerMobileisSlection
                            ? "d-none d-sm-block"
                            : ""
                            }`}
                          alt="..."
                        />
                      )}
                    </div>
                    <div className="col">
                      <div className="row" style={{ background: "" }}>
                        <label className={`text_17_700`}>
                          {locationHistorystate.deviceHistoryData[0]
                            ? locationHistorystate.deviceHistoryData[0].devicename
                            : ""}
                        </label>
                      </div>
                      <div className="row" style={{ background: "" }}>
                        <label className={`text_17_700`}>
                          {locationHistorystate.deviceHistoryData[0]
                            ? getDate(locationHistorystate.deviceHistoryData[0].starttime)
                            : ""}{" "}
                          {"- "}
                          {locationHistorystate.deviceHistoryData[0]
                            ? getDate(locationHistorystate.deviceHistoryData[0].stoptime)
                            : ""}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-12"></div>
              </div>
              <div className="row  text-white">
                <div className="col-12">
                  <div className="row">
                    <div className="col-md-2">
                      <img
                        src={FlavorImages.watch}
                        className="size_24 ml-2  d-none d-sm-block"
                        alt="..."
                      />
                    </div>
                    <div className="col">
                      <div className="row" style={{ background: "" }}>
                        <label className={`text_16_400`}>
                          {t("LocationHistory.Totaltimeactive")}
                        </label>
                        <label className={`text_16_700`}>
                          &nbsp;{data ? data.days : ""}
                          <label className={`text_16_400`}>
                            &nbsp; {t("LocationHistory.days")}&nbsp;
                          </label>
                        </label>
                        <label className={`text_16_700`}>
                          {data ? data.hours : ""}&nbsp;
                        </label>
                        <label className={`text_16_400`}>
                          {t("LocationHistory.hours")}&nbsp;
                        </label>
                        <label className={`text_16_700`}>
                          {data ? data.minutes : ""}&nbsp;
                        </label>
                        <label className={`text_16_400`}>
                          {t("LocationHistory.minutes")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-12"></div>
              </div>
              <div className="row  text-white">
                <div className="col-12">

                  <div className="row">
                    <div className="col-md-2">
                      <img
                        src={FlavorImages.distance_two}
                        className="size_24 ml-2  d-none d-sm-block"
                        alt="..."
                      />
                    </div>
                    <div className="col">
                      <div className="row" style={{ background: "" }}>
                        <label className={`text_16_400 `}>
                          {t("LocationHistory.Total distance")}
                        </label>
                        <label className={`text_16_700`}>
                          &nbsp;
                          {(locationHistorystate.deviceHistoryData[0])
                            ?
                            Math.round((((locationHistorystate.deviceHistoryData[0].totaldistance) / 1000) * 10)) / 10
                            : ""}
                          &nbsp; {t("LocationHistory.km")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <DownloadButton
                  title={t("LocationHistory.download")}
                  onClick={() => {
                    downloadFile(locationHistorystate.startEndDateLocationHistoryDate.starttime, locationHistorystate.startEndDateLocationHistoryDate.stoptime,
                      locationHistorystate.startEndDateLocationHistoryDate.deviceid)
                  }
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default LocationHistoryInformation;
