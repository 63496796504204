import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  mapAlarms: [],
  action: false,
  shareRemoveAction: "",
  selectedAlarmMarkerid: null,
  showAlarmInfo: false,
  showAlarmUnreadDotOnSidebar: false,
  filteredAlarm: [],
  limitedAlarm: [],
  alarms:[]
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const alarmsSlice = createSlice({
  name: "alarms",
  initialState: initialState,
  reducers: {
    setMapAlarms: (state, action) => {
      state.mapAlarms = action.payload.alarms;
    },
    setAlarms: (state, action) => {
      state.alarms = action.payload.alarms;
    },
    setFilteredAlarm: (state, action) => {
      state.filteredAlarm = action.payload.filteredAlarm;
    },
    setAction: (state, action) => {
      state.action = action.payload.action;
    },
    setLimittedAlarm: (state, action) => {
      state.limitedAlarm = action.payload.limitedAlarm;
    },
    setShareRemoveAction: (state, action) => {
      state.shareRemoveAction = action.payload.shareRemoveAction;
    },
    setAlarmMarkerid: (state, action) => {
      state.selectedAlarmMarkerid = action.payload.selectedAlarmMarkerid;
    },
    setShowAlarmInfo: (state, action) => {
      state.showAlarmInfo = action.payload.showAlarmInfo;
    },
    setShowAlarmUnreadDotOnSidebar: (state, action) => {
      state.showAlarmUnreadDotOnSidebar =
        action.payload.showAlarmUnreadDotOnSidebar;
    },
    resetAllAlarmstate: (state, action) => {
      state.mapAlarms = []
      state.action = false
      state.shareRemoveAction = ""
      state.selectedAlarmMarkerid = null
      state.showAlarmInfo = false
      state.showAlarmUnreadDotOnSidebar = false
      state.filteredAlarm = []
    }
  },
});
