import React from "react";
// import green_tick from "../../assets/images/green_tick.svg";
// import cross_red from "../../assets/images/cross_red.svg";
// import default_avatar from "../../assets/images/default_awatar.svg";
import FlavorImages from "../../assetsFolderImport";
const FriendRequestcard = (props) => {
    let myString = props.request?.name;
    let [firstPartName, secondPartName] = myString.split(" ");
    return (
        <div className="stripRow">
            <div className="card border-0 pl-4 py-2 rounded-0 d-flex">
                <div className="shadow-none d-flex">
                    <div className="col-8 pl-0 shadow-none">
                        <img
                            className="mt-2 width_height_50 border_36 mt-n4"
                            src={props.request?.image ? props.request?.image : FlavorImages.default_avatar}
                            alt={"...No image"}
                        />
                        <label className="text_16_700 mt-2 font_700_gloria ml-3 text-break pr-0 col-8">{props.request.email} 
                        {/* <br />  
                        <span className={`text_13_300 font_300_gloria ${!secondPartName && "ml-2"} `}>{secondPartName ? secondPartName : " "}</span>   */}
                        </label>
                    </div>
                    <div className="col-2 shadow-none">
                        {props.requestType == "inviteRequest" ?
                            <img
                                onClick={() => {
                                    props.acceptCancelRequest('accept', props.request?.name)
                                    props.setselectedId(props.request.frienduserid)
                                }}

                                className="mt-4"
                                src={FlavorImages.green_tick}
                                alt="..."
                                style={{ width: "25px", height: "19px" }}
                            /> : ""}
                    </div>
                    <div className="col-2 shadow-none">
                        <img
                            onClick={() => {
                                props.acceptCancelRequest('cancel', props.request?.name)
                                props.setselectedId(props.request.frienduserid)
                            }}
                            src={FlavorImages.cross_red}
                            className="mt-4"
                            alt="..."
                            style={{ width: "25px", height: "22px" }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )

}
export default FriendRequestcard;
