import axios from "axios";
import { API_URL } from "../../helpers/constants";

export async function updateProfile(data) {
  const url = `${API_URL}/account/update`;
  const header = {
    "Content-Type": "application/json",
    "cache-control": "no-cache",
  };
  return axios({
    url: url,
    method: "post",
    data,
    config: {
      header: header,
    },
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}
