import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
// import geo_fence_icon from "../../assets/images/geo_fence_icon.svg";
// import geo_fence_icon_helkama from "../../assets/Helkama_images/geofence.svg";
// import info_icon from "../../assets/images/info_icon.svg";
// import info_icon_helkama from "../../assets/Helkama_images/extended_info.svg";
import SideBarNew from "../../components/SideBar/SideBarNew";
import { INDEX_URL, SITE_TITLE } from "../../helpers/constants";
import { seo } from "../../helpers/seo";
import Map from "../Maps/Map";
import CreateGeofence from "./CreateGeofence";
import GeofenceTutorial from "./GeofenceTutorial";
import AddButton from "../../components/Buttons/AddButton";
import { useParams } from "react-router-dom";
import FlavorImages from "../../assetsFolderImport";

const Geofence = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [activeAddButton, setActiveAddButton] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  let { id } = useParams();
  if (id) id = parseInt(id);
  const on_load = () => {
    seo({
      title: SITE_TITLE,
      metaDescription: "",
    });
  };

  useEffect(() => {
    on_load();
    if (!id) {
      setActiveAddButton(true);
    }
    return () => { };
  }, []);

  const onClose = () => {
    history.push(INDEX_URL + "geofence");
  };
  const OnTutorialClose = () => {
    setShowTutorial(false);
  };

  return (
    <>
      <React.Fragment>
        <section className="section-home " id="login-page">
          <div className="middle-content d-flex justify-content-between h-100">
            <SideBarNew
              page="Geofence"
              onClick={(e) => {
                history.push(INDEX_URL + e);
              }}
            />

            <div className="col row section-data ml-5 ">
              <div
                className={` ${"col-12 col-md-6 col-lg-4 section-left_bottom_data"

                  } `}
              >
                <div className="col-12 home_title_bar d-none d-md-block">
                  <div className="d-none d-md-flex align-items-center">
                    <img
                      src={ FlavorImages.geo_fence_icon}
                      className="home_title_icon"
                      alt="..."
                    />
                    <label className="text_34_700 uppercase">{t("sideBar.Geofences")}</label>

                    <img
                      src={ FlavorImages.info_icon}
                      className="home_title_icon ml-3 my-1 cursor-pointer"
                      alt="..."
                      onClick={() => {
                        setShowTutorial(true);
                      }}
                    />
                  </div>
                </div>
                {activeAddButton ? (
                  <div className=" control_bar d-none d-md-flex " style={{ marginTop: "15px" }}>
                    <AddButton
                      active={true}
                      title={t("Geofences.CreateGeofence")}
                      onClick={() => onClose()}
                    />
                  </div>
                ) : (
                  <div className=" control_bar d-none d-md-flex invisible" style={{ marginTop: "15px" }}>
                    <AddButton
                      active={true}
                      title={t("Geofences.CreateGeofence")}
                      onClick={() => onClose()}
                    />
                  </div>
                )}

                <CreateGeofence />
              </div>

              {
                <div className="col px-0 section-right_data">
                  <Map page="addeditgeofence" />
                </div>
              }
            </div>

            <GeofenceTutorial
              show={showTutorial}
              onClose={() => OnTutorialClose()}
            />
          </div>
        </section>
      </React.Fragment>
    </>
  );
};

export default Geofence;
