import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { registerSlice } from "../../slice/registerSlice";
import { authSlice } from "../../slice/authSlice";
import { MAIL_FORMATE, INDEX_URL, SITE_TITLE } from "../../helpers/constants";
import { seo } from "../../helpers/seo";
import ReactHtmlParser from "react-html-parser";
import LanguageChanger from "../../components/LanguageChanger/LanguageChanger";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
// import oop_char from "../../assets/images/oop_char.svg";
import * as signUpService2 from "../../services/user/signUpService2";
import * as accountService from "../../services/user/accountService2";
// import cross_red from "../../assets/images/cross_red.svg";
// import check_light_navy from "../../assets/images/check_light_navy.svg";
import ErrorDialog from "../LocationHistory/ErrorDialog";
import FlavorImages from "../../assetsFolderImport";
const authactions = authSlice.actions;
const registerActions = registerSlice.actions;
const SignUpEmailExist = () => {
  const { registerState } = useSelector(
    (state) => ({ registerState: state.register }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [errorDialog, setShowErrorDialog] = useState(false)
  const { t } = useTranslation();
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [is_valid_email, setIsValidEmail] = useState(-1);
  const [is_valid_confirm_email, setIsValidConfirmEmail] = useState(-1);
  const { authState } = useSelector(
    (state) => ({ authState: state.auth }),
    shallowEqual
  )
  const clickNext = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      // dispatch(
      //   registerActions.setStepVINNumberData({ email: fields["Email"] })
      // );
      //console.log(registerState.imeinumber)
      const data = {
        email: fields["Email"],
        password: registerState.password,
        path: "",
        username: registerState.username,
        imeinumber: [registerState.imeinumber],
      };
      let response = await signUpService2.register(data);
      if (parseInt(response.data.errorCode) === 20025) {
        setShowErrorDialog(response.data.message)
      } 
      else if (response.status === 802) {
        let errors = {};
        errors["Email"] = t(`errorCode.${response.data.errorCode}`);
        setIsValidEmail(0);
        setErrors(errors);
      } else if (response.status === 200) {
        let token = response.data.accessToken;
        dispatch(authactions.setAuthToken({ authToken: token }));
        const user_info_response = await accountService.getAccountInfo(token);
        if (user_info_response.status === 200) {
          dispatch(
            authactions.setAuthData({ authData: user_info_response.data })
          );
          history.push(INDEX_URL + "signup/complete");
        }
      }
    }
  };

  const handleValidation = () => {
    let fields_temp = _.cloneDeep(fields);
    let errors = {};
    let formIsValid = true;

    if (fields_temp.Email !== "") {
      if (MAIL_FORMATE.test(fields_temp.Email)) {
        errors["Email"] = "";
        setIsValidEmail(1);
      } else {
        formIsValid = false;
        errors["Email"] = t("login.Incorrect email Please try again");
        setIsValidEmail(0);
      }
    } else {
      errors["Email"] = "";
      setIsValidEmail(-1);
    }

    if (fields_temp.ConfirmEmail !== "") {
      if (MAIL_FORMATE.test(fields_temp.ConfirmEmail)) {
        errors["ConfirmEmail"] = "";
        setIsValidConfirmEmail(1);
      } else {
        formIsValid = false;
        errors["ConfirmEmail"] = t("login.Incorrect email Please try again");
        setIsValidConfirmEmail(0);
      }
    } else {
      errors["ConfirmEmail"] = "";
      setIsValidConfirmEmail(-1);
    }

    if (!fields_temp["Email"]) {
      formIsValid = false;
      errors["Email"] = t("errors.Please enter email");
      setIsValidEmail(0);
    }

    if (!fields_temp["ConfirmEmail"]) {
      formIsValid = false;
      errors["ConfirmEmail"] = t("errors.Please enter email");
      setIsValidEmail(0);
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleChange = (field, e) => {
    let fields_temp = _.cloneDeep(fields);
    let errors = {};
    fields_temp[field] = e.target.value;

    if (fields_temp.Email !== "" && fields_temp.Email !== undefined) {
      if (MAIL_FORMATE.test(fields_temp.Email)) {
        setIsValidEmail(1);
        errors["Email"] = "";
      } else {
        setIsValidEmail(0);
      }
    } else {
      errors["Email"] = "";
      setIsValidEmail(-1);
    }

    if (
      fields_temp.ConfirmEmail !== "" &&
      fields_temp.ConfirmEmail !== undefined
    ) {
      if (MAIL_FORMATE.test(fields_temp.ConfirmEmail)) {
        setIsValidConfirmEmail(1);
        errors["ConfirmEmail"] = "";
      } else {
        setIsValidConfirmEmail(0);
      }
    } else {
      errors["ConfirmEmail"] = "";
      setIsValidConfirmEmail(-1);
    }

    setFields(fields_temp);
    setErrors(errors);
  };

  const validatePrefilledData = () => {
    const fields_to_validate = ["email", "password", "username", "imeinumber"];
    for (const field of fields_to_validate) {
      if (!registerState[field]) {
        history.push(INDEX_URL + "signup");
        break;
      }
    }
  };

  const on_load = () => {
    seo({
      title: SITE_TITLE,
      metaDescription: "",
    });
  };

  useEffect(() => {
    on_load();
  }, []);

  const emptyEmail = () => {
    setFields({ ...fields, Email: "" });
    setIsValidEmail(-1);
  };

  const emptyConfirmEmail = () => {
    setFields({ ...fields, ConfirmEmail: "" });
    setIsValidConfirmEmail(-1);
  };

  return (
    <>
      <React.Fragment>
        <div className="col-12 p-4 vh-100" id="login-page">
          <div className="container-fluid p-0">
            <div className="vh-100  col-md-6 offset-md-3 col-lg-4 offset-lg-4 pt-4 align-items-center ">
              <img
                src={FlavorImages.app_logo}
                className="img-fluid mx-auto d-none d-sm-block photo"
                alt="..."
              />
              <div className="col-12 container-fluid  ">
                <img className="signup-oops  align-self-end  " src={FlavorImages.oop_char} />
              </div>
              <div className="col-12 pl-2 pt-4" style={{ marginTop: "-170px" }}>
                <div className="col-12   d-flex align-items-center  px-0">
                  <label
                    htmlFor="Email"
                    className="text_34_700 col-form-label font_700_italic_gloria"
                  >
                    {"5. " + ReactHtmlParser(t("signup.Oops"))}
                  </label>
                </div>

                <div className="col-12  pl-4">
                  <label
                    htmlFor="Email"
                    className="text_16_400 font_400_italic_gloria text-navy font_400_italic_gloria col-form-label"
                  >
                    {ReactHtmlParser(t("signup.OopsSubTitle"))}
                  </label>
                </div>

                <div className="col-12 inputBlock  col-sm-12 pl-4">
                  <TextField
                    htmlFor="Email"
                    value={fields["Email"]}
                    className="col-12 Mui-focused label-input-bold"
                    id="Email"
                    label={ReactHtmlParser(t("login.Email"))}
                    variant="standard"
                    placeholder="email@email.com"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {is_valid_email === 0 ? (
                            <img
                              src={FlavorImages.cross_red}
                              className="input-icon cursor-pointer"
                              onClick={() => {
                                emptyEmail();
                              }}
                            ></img>
                          ) : is_valid_email === 1 ? (
                            <img
                              src={FlavorImages.check_light_navy}
                              className="input-icon"
                            ></img>
                          ) : (
                            ""
                          )}
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleChange("Email", e);
                    }}
                    defaultValue={fields["Email"]}
                  />

                  <span></span>
                  {typeof errors["Email"] !== "undefined" &&
                    errors["Email"] !== "" ? (
                    <span className="invalid-feedback bottom">{errors["Email"]}</span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-12 inputBlock col-sm-12 pl-4">
                  <TextField
                    htmlFor="ConfirmEmail"
                    value={fields["ConfirmEmail"]}
                    className="col-12 Mui-focused label-input-bold"
                    id="ConfirmEmail"
                    label={ReactHtmlParser(t("signup.ConfirmEmail"))}
                    variant="standard"
                    placeholder="Email@gmail.com"
                    onChange={(e) => {
                      handleChange("ConfirmEmail", e);
                    }}
                    defaultValue={fields["ConfirmEmail"]}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {is_valid_confirm_email === 0 ? (
                            <img
                              src={FlavorImages.cross_red}
                              className="input-icon cursor-pointer"
                              onClick={() => {
                                emptyConfirmEmail();
                              }}
                            ></img>
                          ) : is_valid_confirm_email === 1 ? (
                            <img
                              src={FlavorImages.check_light_navy}
                              className="input-icon"
                            ></img>
                          ) : (
                            ""
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />

                  <span></span>
                  {typeof errors["ConfirmEmail"] !== "undefined" &&
                    errors["ConfirmEmail"] !== "" ? (
                    <span className="invalid-feedback bottom">
                      {errors["ConfirmEmail"]}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div
                  className="px-0 pl-4 col-12  no-gutters"
                  style={{ marginTop: "65px" }}
                >
                  <div className="col-sm-12   ">
                    <button
                      type="submit"
                      className="btn_login font_700_italic_gloria background_black_helkama"
                      id="loginSubmit"
                      onClick={(e) => clickNext(e)}
                    >
                      {ReactHtmlParser(t("signup.Next"))}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <LanguageChanger />
          </div>
        </div>
        <ErrorDialog
                    showErrorDialog={errorDialog}
                    select={"Device"}
                    errorMesage={errorDialog}
                    onClose={() => setShowErrorDialog(false)}
                  />
      </React.Fragment>
    </>
  );
};

export default SignUpEmailExist;
