import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  circle: {},
  polygon: [],
  shapeMethod: "",
  geofences: [],
  mapGeofences: [],
  filteredGeofences: [],
  focusGeofenceOnMap: null,
  editgeofence: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const geofencesSlice = createSlice({
  name: "geofences",
  initialState: initialState,
  reducers: {
    setCircleData: (state, action) => {
      state.circle = action.payload;
    },
    setPolygonData: (state, action) => {
      state.polygon = action.payload.path;
    },
    setShapeMethod: (state, action) => {
      state.shapeMethod = action.payload.shapeMethod;
    },
    setFilteredGeofencesData: (state, action) => {
      state.filteredGeofences = action.payload.geofences;
    },
    setGeofencesData: (state, action) => {
      state.geofences = action.payload.geofences;
    },
    setMapGeofences: (state, action) => {
      state.mapGeofences = action.payload.geofences;
    },
    focusGeofenceOnMap: (state, action) => {
      state.focusGeofenceOnMap = action.payload.id;
    },
    setEditGeofence: (state, action) => {
      state.editgeofence = action.payload.editgeofence;
    },
    resetAllgeoFencestate: (state, action) => {
      state.circle = {}
      state.polygon = []
      state.shapeMethod = ""
      state.geofences = []
      state.mapGeofences = []
      state.filteredGeofences = []
      state.focusGeofenceOnMap = null
      state.editgeofence = null
    }
  },
});
