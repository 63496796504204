/*
 * @Author: SweTrack
 */
import axios from "axios";
import { API_URL } from "../../helpers/constants.js";
export async function getAccountInfo(accesstokenkey) {
  const url = `${API_URL}account/info`;
  const headers = {
    "Content-Type": "application/json",
    "cache-control": "no-cache",
    authorization: "" + accesstokenkey + "",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  };
  return axios({
    url: url,
    method: "GET",
    config: headers,
    headers: headers,
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export async function updateAccountSettings(accesstokenkey, data, type) {
  const url = `${API_URL}updateAccountSettings`;
  let formData = "";
  const headers = {
    "Content-Type": "application/json",
    "cache-control": "no-cache",
    authorization: "" + accesstokenkey + "",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  };
  if (type === "email") {
    formData = { Email: data["newEmail"] };
  } else if (type === "name") {
    formData = { Name: data["Name"] };
  } else if (type === "password") {
    formData = { OldPassword: data["OldPassword"], Password: data["Password"] };
  } else if (type === "notification") {
    formData = { Notifications: data };
  }
  return axios({
    url: url,
    method: "POST",
    config: headers,
    data: formData,
    headers: headers,
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export async function getLoginHistory(accesstokenkey) {
  const url = `${API_URL}getLoginHistory`;
  const headers = {
    "Content-Type": "application/json",
    "cache-control": "no-cache",
    authorization: "" + accesstokenkey + "",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  };
  return axios({
    url: url,
    method: "GET",
    config: headers,
    headers: headers,
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export async function removeLoginHistory(accesstokenkey) {
  const url = `${API_URL}removeLoginHistory`;
  const headers = {
    "Content-Type": "application/json",
    "cache-control": "no-cache",
    authorization: "" + accesstokenkey + "",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  };
  return axios({
    url: url,
    method: "GET",
    config: headers,
    headers: headers,
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export async function removeAccount(accesstokenkey) {
  const url = `${API_URL}removeAccount`;
  const headers = {
    "Content-Type": "application/json",
    "cache-control": "no-cache",
    authorization: "" + accesstokenkey + "",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  };
  return axios({
    url: url,
    method: "GET",
    config: headers,
    headers: headers,
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}
