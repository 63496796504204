import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
// import geo_fence_icon from "../../assets/images/geo_fence_icon.svg";
// import info_icon from "../../assets/images/info_icon.svg";
// import geo_fence_icon_helkama from "../../assets/Helkama_images/geofence.svg";
// import info_icon_helkama from "../../assets/Helkama_images/extended_info.svg";
import ActionButton from "../../components/Buttons/ActionButton";
import AddButton from "../../components/Buttons/AddButton";
import ScrollMenuName from "../../components/ScrollBar/ScrollMenuName";
import SearchControl from "../../components/Search/SearchControl";
import SideBarNew from "../../components/SideBar/SideBarNew";
import * as genericService from "../../services/genericService";
import _ from "lodash";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { INDEX_URL } from "../../helpers/constants";
import Map from "../Maps/Map";
import GeofenceTutorial from "./GeofenceTutorial";
import { devicesSlice } from "../../slice/deviceSlice";
import GeofenceRow from "./GeofenceRow";
import * as devicesService2 from "../../services/devices/devicesService2";
import { geofencesSlice } from "../../slice/geofencesSlice";
import { mapSettingsSlice } from "../../slice/mapSettingsSlice";
import DeleteActionDialog from "../../pages_v2/Alarm/DeleteActionDialog";
import EnableDisableButton from "../../components/Buttons/EnableDisableButton";
import ConfrimDeleteGeofence from "./ConfrimDeleteGeofence";
// import up_arrow_grey from "../../assets/images/up_arrow_grey.svg";
// import up_arrow_black from "../../assets/Helkama_images/up_arrow_black.svg";
import { locationHistorySlice } from "../../slice/locationHistorySlice";
import { groupSlice } from "../../slice/groupSlice";
import FlavorImages from "../../assetsFolderImport";

const geofencesActions = geofencesSlice.actions;
const locationHistoryActions = locationHistorySlice.actions;
const mapSettingsActions = mapSettingsSlice.actions;
const Geofence = () => {
  const location = useLocation();
  let path = location.pathname
  const [downArrowClick, setDownArrowClick] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showActionDialog, setShowActionDialog] = useState(false);
  const [isSelection, setIsSelection] = useState(false);
  const [firstDownClick, setFirstDownClick] = useState(false)
  const [searchKeys, addSearchKey] = useState([]);
  const [chooseAll, setChooseAll] = useState(false);
  const [confrmDeleteDialog, setConfrimDeleteDialog] = useState(false);
  const [enableDisblaeButton, setenableDisblaeButton] = useState(false);
  const [selected, setSelected] = useState(false);
  const groupActions = groupSlice.actions;
  const deviceActions = devicesSlice.actions;

  const [geofences, setGeofence] = useState([]);
  const { geofencesState } = useSelector(
    (state) => ({ geofencesState: state.geofences }),
    shallowEqual
  );
  const isAnyGeofenceSelected = () => {
    return geofencesState.filteredGeofences.some((geofence) => geofence.isSelected);
  };
  const [showTutorial, setShowTutorial] = useState(false);
  let { id } = useParams();
  const loadGeofences = async () => {
    const response = await genericService.get("geofence/info");
    if (response.status === 200) {
      const geofences = response.data ?? [];
      dispatch(geofencesActions.setGeofencesData({ geofences }));
      if (id) {
        let arrayTobePushed = []
        let keyFilter = id
        let keyTogetFilter = keyFilter.split(",")
        for (let i = 1; i < keyTogetFilter.length; i++) {
          arrayTobePushed.push(
            {
              "name": keyTogetFilter[i]
            }
          )
        }
        let items = []
        let SearchedItems = []
        addSearchKey(arrayTobePushed);
        arrayTobePushed.map((arr) => {
          return items.push(arr.name)
        })
        for (const item of items) {
          let temp = geofences.filter((geofence) => {
            return geofence.name.toLowerCase().indexOf(item.toLowerCase()) > -1;
          });

          Array.prototype.push.apply(SearchedItems, temp);
        }
        dispatch(geofencesActions.setFilteredGeofencesData({ geofences: SearchedItems }));
        setGeofence(SearchedItems);
      } else {
        dispatch(
          geofencesActions.setFilteredGeofencesData({
            geofences: geofences,
          })
        );
        setGeofence(geofences);
      }

      dispatch(mapSettingsActions.setSetting({ show_geofence: true }));
    }
  };

  const setConfirmDelete = () => {
    setConfrimDeleteDialog(true);
    setDownArrowClick(!downArrowClick)
  };

  const loadGroups = async () => {
    const response = await devicesService2.getDeviceGroupsList();
    if (response.status === 200) {
      const groups = response.data ?? [];
      dispatch(
        groupActions.setUpGroupData({
          groups: groups,
        })
      );
      dispatch(
        groupActions.setUpFilteredGroupsData({
          groups: groups,
        })
      );
    }
  };
  const loadDevices = async () => {
    const response = await devicesService2.getDevicesCompactInfo();
    if (response.status === 200) {
      const devices = response.data.devices ?? [];
      const pendingDevices = response.data.pendingSharedDevices ?? [];
      const newDevices = devices.concat(pendingDevices);
      dispatch(
        deviceActions.setUpDeviceData({
          devices: newDevices,
        })
      );
    }
  };
  const on_load = () => {
    loadGroups()
    loadDevices()
    loadGeofences();
    dispatch(
      locationHistoryActions.locationHistoryPage({
        locationPage: "locationHistory",
      })
    )
    dispatch(locationHistoryActions.resetLocationState());
  };
  const onGeofenceClick = (index) => {
    var array = _.cloneDeep(geofencesState.filteredGeofences);
    if (index !== -1) {
      var geoFence = array[index];

      if (geoFence.isSelected !== true) geoFence.isSelected = true;
      else geoFence.isSelected = false;
      array[index] = geoFence;
      dispatch(
        geofencesActions.setFilteredGeofencesData({
          geofences: array,
        })
      );
    }
  };
  useEffect(() => {
    on_load();
    dispatch(
      locationHistoryActions.locationHistoryPage({
        locationPage: "locationHistory",
      })
    )
    return () => {
      dispatch(
        deviceActions.setGroupRowSettings({
          showgroupssetting: false,
        })
      );
      dispatch(mapSettingsActions.setSetting({ show_geofence: false }));
    };
  }, []);

  const OnSearchRemove = (index) => {
    var array = [...searchKeys];
    if (index !== -1) {
      let searchKey = array[index]
      let path2
      if (searchKeys.length > 1)
        path2 = path.replace(`,${searchKey.name}`, '');
      else
        path2 = path.replace(`,${searchKey.name}`, '');
      array.splice(index, 1);
      addSearchKey(array);
      filterGeofence(array);
      history.push(path2);
    }
  };

  const OnClearAll = () => {
    const results = _.cloneDeep(geofencesState.geofences);
    var array = [...searchKeys];
    array.splice(0, array.length);
    addSearchKey(array);
    dispatch(geofencesActions.setFilteredGeofencesData({ geofences: results }));
    history.push(INDEX_URL + "geofence/")
  };

  const OnAdd = () => {
    history.push(INDEX_URL + "addeditgeofence");
  };

  const onKeyChange = (searchKey) => {
    if (!searchKey) {
      var array = [...searchKeys];
      filterGeofence(array);
    }
    else {
      var searchObject = { name: searchKey };
      var array = [...searchKeys];
      array.push(searchObject);
      filterGeofence(array);
    }
  }
  const closeBottomPopupMobile = () => {
    setDownArrowClick(!downArrowClick)
    setFirstDownClick(true)
  }
  const addKey = (searchKey) => {
    var searchObject = { name: searchKey };
    var array = [...searchKeys];
    array.push(searchObject);
    addSearchKey(array);
    filterGeofence(array);
    if (id) {
      history.push(path + "," + searchKey);
    }
    else {
      history.push(INDEX_URL + "geofence/search/," + searchKey);
    }
  };
  const setDeleteGeofence = () => {
    setIsSelection(true);
    setenableDisblaeButton(true);
  };
  const removeDeleteGeofence = () => {
    setIsSelection(false);
    setenableDisblaeButton(false);
  };
  const filterGeofence = (array) => {
    if (!array.length) {
      const results = _.cloneDeep(geofencesState.geofences);
      dispatch(
        geofencesActions.setFilteredGeofencesData({ geofences: results })
      );
      return;
    }
    const items = [];
    const filteredGeofence = [];
    array.map((arr) => items.push(arr.name));
    for (let item of items) {
      let temp = geofences.filter((goefence) => {
        return goefence.name.toLowerCase().indexOf(item.toLowerCase()) > -1;
      });
      _.uniq(temp)
      const newTemp = _.uniq(temp)
      Array.prototype.push.apply(filteredGeofence, newTemp);
    }
    dispatch(
      geofencesActions.setFilteredGeofencesData({ geofences: _.uniq(filteredGeofence) })
    );
  };
  const OnTutorialClose = () => {
    setShowTutorial(false);
  };

  return (
    <>
      <React.Fragment>
        <section className="section-home " id="login-page">
          <div className="middle-content d-flex justify-content-between h-100">
            <SideBarNew
              page="Geofence"
              OnAdd={() => OnAdd()}
              onClick={(e) => {
                history.push(INDEX_URL + e);
              }}
            />
            <div className="col row section-data ml-5">
              <div
                className={`  ${"col-12 col-md-6 col-lg-4 section-left_bottom_data"

                  } `}
              >
                <div className={`${downArrowClick === false && firstDownClick ? "animate_hight_down " :
                  firstDownClick && downArrowClick ?
                    "animate_hight_up" : "heigh_50_mobile"} `} >
                    <div className="dragbar_toucharea" draggable="true" onTouchStart={() => closeBottomPopupMobile()}>
                  <div className="dragbar" ></div>
                </div>
                  <div className="col-12 home_title_bar">
                    <div className="d-none d-md-flex align-items-center">
                      <img
                        src={FlavorImages.geo_fence_icon}
                        className="home_title_icon"
                        alt="..."
                      />
                      <label className="text_34_700 uppercase">{t("sideBar.Geofences")}</label>
                      <img
                        src={ FlavorImages.info_icon}
                        className="home_title_icon ml-3 my-1 cursor-pointer"
                        alt="..."
                        onClick={() => {
                          setShowTutorial(true);
                        }}
                      />
                    </div>
                    <div className="d-md-none d-flex w-100 justify-content-between ">
                      <div className="row col-8">
                        <label className="text_22_700 uppercase text_dark_helkama">{t("sideBar.Geofences")}</label>
                        <img
                          src={FlavorImages.info_icon}
                          className="home_title_icon-sm m-2"
                          alt="..."
                          onClick={() => {
                            setShowTutorial(true);
                            setDownArrowClick(!downArrowClick)
                          }}
                        />
                      </div>
                      <div className="row col-2 justify-content-end">
                        <img
                          onClick={() => {closeBottomPopupMobile()}}
													// style={{transform: 'scaleY(-1)'}}
													src={FlavorImages.up_arrow}
                          className={`size_30 ml-2 mt-2 d-md-none d-sm-flex padding_6 bg-grey2 radius_2
                          ${downArrowClick ? "rotate_btn_maximize" : "rotate_btn_minmize"}`}
                          alt="..."
                        />
                      </div>
                    </div>
                  </div>

                  <div className=" control_bar d-none d-md-flex " style={{ marginTop: "15px" }}>
                    <AddButton
                      active={false}
                      title={t("Geofences.CreateGeofence")}
                      onClick={() => OnAdd()}
                    />
                  </div>

                  <div>
                    <div className="control_bar mt-md-3 " style={{ marginBottom: "15px" }}>
                      <SearchControl
                        onAddSearchKey={(e) => addKey(e)}
                        onChangeSearchKey={(e) => onKeyChange(e)}
                        hideSorting={true}
                      />
                    </div>

                    <div className={`control_bar  ${enableDisblaeButton ? "ml-2" : ""}  d-none d-md-flex mt-3`}>
                      <div className="accordion" id="accordiontwo">
                        {enableDisblaeButton === false ? (
                          <ActionButton
                            onClick={() => setShowActionDialog(true)}
                          />
                        ) : (
                          <EnableDisableButton
                            geofenceState={geofencesState}
                            select={"geofence"}
                            isChecked={chooseAll}
                            onClick={(e) => setChooseAll(e)}
                          />
                        )}
                        <DeleteActionDialog
                          select={"geofence"}
                          showActionDialog={showActionDialog}
                          isSelection={isSelection}
                          setIsSelection={() => setDeleteGeofence()}
                          resetIsSelection={() => removeDeleteGeofence()}
                          onClose={() => setShowActionDialog(false)}
                        />
                      </div>
                      <div className="search_capsule_bar row ">
                        <ScrollMenuName
                          menuItems={searchKeys}
                          onRemove={(e) => OnSearchRemove(e)}
                          OnClearAll={() => OnClearAll()}
                        />
                      </div>
                    </div>

                    <div className={`${isSelection ? "scroll_div_16_geofence_buttons" : "scroll_div_16_geofence"} ${   geofencesState.filteredGeofences?.length ? "alarm_list_shadow" : ""}  mt-md-3 mb-3`}>
                      {
                        geofencesState.filteredGeofences?.length ? (
                          geofencesState.filteredGeofences.map(
                            (geofence, index) => {
                              return (
                                <div key={index}>
                                  <GeofenceRow
                                    setDownArrowClick={() => setDownArrowClick(!downArrowClick)}
                                    setConfirmDelete={setConfirmDelete}
                                    isSelection={isSelection}
                                    selected={chooseAll ? true : false}
                                    setChooseAll={setChooseAll}
                                    onClick={() => onGeofenceClick(index)}
                                    index={index}
                                    geofence={geofence}
                                    key={index}
                                  />
                                </div>
                              );
                            }
                          )
                        ) : (
                          <>
                            <label className="no_data_row d-none d-md-flex mt-4 px-2">
                              {t("Geofences.NoGeofenceMsg")}
                            </label>
                            <div className="d-flex row d-md-none">
                              <label className="no_data_row justify-content-center w-100 mt-4r text-22-700 text-grey2 px-2">
                                {t("Geofences.NoGeofenceMsgMobile1")}
                              </label>
                              <label className="no_data_row justify-content-center w-100 text-12-500 px-2">
                                {t("Geofences.NoGeofenceMsgMobile2")}
                              </label>
                            </div>
                          </>
                        )}
                    </div>
                  </div>
                </div>
                {isSelection === true ? (
                  <div className="w-100  d-flex pr-1 mt-1  justify-content-md-end justify-content-sm-start bottom_button_devices">
                    <button
                      onClick={() => removeDeleteGeofence()}
                      className="grey_btn_device mt-1"
                    >
                      {t("Device.CANCEL")}
                    </button>
                    <button
                      onClick={() => setConfirmDelete()}
                      disabled={!isAnyGeofenceSelected()} // Disable the button if no geofence is selected
                      className={`${isAnyGeofenceSelected() ? "btn_theme_device bg_dark_helkama" : "btn_theme_device_disabled"} ml-2 mt-1 text-uppercase`}
                      id="next"
                    >
                      {t("Geofences.Deletegeofence")}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {
                <div className="col px-0 section-right_data">
                  <Map
                    page={"geofence"}
                  />
                </div>
              }
            </div>
            <ConfrimDeleteGeofence
              show={confrmDeleteDialog}
              loadGeofences={() => loadGeofences()}
              resetIsSelection={() => removeDeleteGeofence()}
              closeConfirmDeleteDialog={() => setConfrimDeleteDialog(false)}
            />
            <GeofenceTutorial
              show={showTutorial}
              onClose={() => OnTutorialClose()}
            />
          </div>
        </section>
      </React.Fragment>
    </>
  );
};

export default Geofence;
