import React from "react";
import ReactHtmlParser from "react-html-parser";
// import down_arrow from "../../assets/images/down_arrow.svg";
import { useTranslation } from "react-i18next";
import FlavorImages from "../../assetsFolderImport";
const ActionButton = (props) => {
  const { t } = useTranslation();
  const actionClick = () => {
    if (props.massShareBtn === true) {
      props.setShowSharedActionDialog();
    } else if (props.massShareBtn === false) {
      props.setActionDialog();
    } else {
      props.onClick();
    }
  };
  return (
    <React.Fragment>
      <div
        onClick={() => actionClick()}
        data-toggle="collapse"
        data-target="#collapsetwo"
        aria-expanded="true"
        aria-controls="collapsetwo"
        className="add_button cursor-pointer"
        style={{ width: "80px" }}
      >
        <img src={FlavorImages.down_arrow} className="add_button_icon cursor-pointer" alt="..." />
        <label htmlFor="Email" className="cursor-pointer text-capitalize text_11_700">
          {ReactHtmlParser(t("labels.Actions"))}
        </label>
      </div>
    </React.Fragment>
  );
};

export default ActionButton;
