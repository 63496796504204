import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import forward_arrow_grey from "../../assets/images/forward_arrow_grey.svg";
// import forward_arrow_white from "../../assets/images/forward_arrow_white.svg";
// import settings from "../../assets/images/settings.svg";
import SideBarNew from "../../components/SideBar/SideBarNew";
import { INDEX_URL, SITE_TITLE } from "../../helpers/constants";
// import  setting_icon from "./assets/Helkama_images/cog.svg";
import PopUp from "../../pages_v2/Device/PopUp";
import Succespopup from "../../pages_v2/Device/SuccesPopup";
import * as genericService from "../../services/genericService";
import { authSlice } from "../../slice/authSlice";
import { devicesSlice } from "../../slice/deviceSlice";
import AcceptedShareBike from "./AcceptedShareBike";
import BikeDetail from "./BikeDetail";
import BikeNotifications from "./BikeNotifications";
import GeneralNotifications from "./GeneralNotifications";
import Bikes from "./Bikes";
import Billing from "./Billing";
import Credit from "./Credit";
import DataPlanDialog from "./DataPlanDialog";
import DeleteAccount from "./DeleteAccount";
import DeleteBike from "./DeleteBike";
import DeleteShareBike from "./DeleteShareBike";
import EditAccount from "./EditAccount";
import EditBikeName from "./EditBikeName";
import EditEmail from "./EditEmail";
import EditName from "./EditName";
import EditPassword from "./EditPassword";
import LocationServiceRow from "./LocationServiceRow";
import Safety from "./Safety";
import ShareBike from "./ShareBike";
import SharingStatus from "./SharingStatus";
import SignOutDialog from "./SignOut";
import { sounds1sec } from "./Sounds";
import SoundUI from "./SoundUI";
import Support from "./Support";
import UserManual from "./UserManual";
import Warranty from "./Warranty";
// import pic_default from "../../assets/images/pic_default.svg";
import { settingsSlice } from "../../slice/settingsSlice";
import Socials from "./Socials";
import Customize from "./Customize";
import ErrorDialog from "../LocationHistory/ErrorDialog";
import { locationHistorySlice } from "../../slice/locationHistorySlice";
import FlavorImages from "../../assetsFolderImport";
let width = window.innerWidth;  // use only when no imapact while changing screen from web to mobile
const locationHistoryActions = locationHistorySlice.actions;
const settingsActions = settingsSlice.actions;
const Settings = () => {
  const [parent_location, setParent] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const authAction = authSlice.actions;
  const history = useHistory();
  const [error, setError] = useState("");
  const [select, setSelect] = useState("");
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [editEmailDialog, showEditEmailDialog] = useState(false);
  const [passwordDialog, setPasswordDialog] = useState(false);
  const [shairedBike, setShairedBike] = useState(false);
  const [signOutDialog, setSignoutDialog] = useState(false);
  const [editBike, setEditBikeName] = useState(false);
  const [errorDialog, setShowErrorDialog] = useState(false)
  const [deviceName, setDeviceName] = useState("");
  const [sharedbyme, setsharedbyme] = useState("");
  const [spinerActve, setSpinerActve] = useState(false);
  const [deviceId, setDeviceId] = useState(0);
  const [soundId, setSoundId] = useState("");
  const [soundDuration, setSoundDuration] = useState(0);
  const [success, setSucces] = useState("")
  const [sameForAll, setSameForAll] = useState(false);
  const [alarmName, setAlarmName] = useState("");
  const [errorMesage, setErrorMesage] = useState("")
  const [bikeDetail, setBikeDetail] = useState("")
  const { devicesState } = useSelector(
    (state) => ({ devicesState: state.devices }),
    shallowEqual
  );


  const setbikeName = () => {

  }

  const deleteDialog = () => {
    setSignoutDialog(true);
    setSelect("Signout");
  };
  const { authState } = useSelector(
    (state) => ({ authState: state.auth }),
    shallowEqual
  );
  const [confirmDialog, setConfrimDialog] = useState(false);
  const loadProfileSetting = async () => {
    const response = await genericService.get("account/info");
    if (response.status === 200) {
      var info = response.data;
      dispatch(
        authAction.setAuthData({
          authData: info,
        })
      );
    }
  };

  const on_load = () => {
    loadProfileSetting();
    dispatch(settingsActions.setPage({ page: "" }))
  };

  useEffect(() => {
    on_load();
    dispatch(
      locationHistoryActions.locationHistoryPage({
        locationPage: "locationHistory",
      })
    )
    dispatch(locationHistoryActions.resetLocationState());
  }, []);

  const defaultProps = {
    center: {
      lat: 60.1282,
      lng: 18.6435,
    },
    zoom: 8,
  };

  const updateSoundId = (alarmId) => {
    setSoundId(alarmId);
    var sounds = sounds1sec;

    for (var i = 0; i < sounds.length; i++) {
      var sound = sounds[i];
      if (sound.id === alarmId) {
        setAlarmName(sound.name);
        break;
      }
    }
  };

  const updateSoundDuration = (duration) => {
    setSoundDuration(duration);
  };

  const updateAlarm = (alarm) => {
    if (alarm.alarmid === null) {
      setAlarmName("");
      setSoundId("");
      setSoundDuration(-1);
    } else {
      setSoundId(alarm.alarmid);
      setSoundDuration(alarm.alarmduration);
      var sounds = sounds1sec;
      for (var i = 0; i < sounds.length; i++) {
        var sound = sounds[i];
        if (sound.id === alarm.alarmid) {
          setAlarmName(sound.name);
          break;
        }
      }
    }
  };

  return (
    <>
      <React.Fragment>
        <div
          onClick={() => setSpinerActve(false)}
          className={` mobileSpiner ${spinerActve ? "d-block" : "d-none"}`}>
          <div className="text-center text_17_700 text-white margin_top_411 " >
            <div className="spinner-border" role="status">
            </div>
          </div>
        </div>
        <section className="section-home " id="login-page">
          <div className="middle-content d-flex justify-content-between h-100">
            <SideBarNew
              page="Settings"
              onClick={(e) => {
                history.push(INDEX_URL + e);
              }}
            />

            <div className="col-12 row section-data margin_top_76_mobile noScroll  ml-5 ">
              <div className={`col-12 col-md-4 settings_screen-left ${!select ? "d-block" : "d-none"} d-md-block  border-right`}>
                <div className={`${width < 768 ? "d-md-block d-none" : "" } col-12 home_title_bar_setting`}>
                  <img src={FlavorImages.setting_icon } className="home_title_icon vertical_align_sub " alt="..." />
                  <label>{t("Settings.Settings")}</label>
                </div>
                {/* {console.log(authState)} */}
                {process.env.REACT_APP_FLAVOR === "Bike"
                  &&
                  <div
                    className="row justify-content-center mt-2 d-md-none">
                      {authState.authData &&
                        <img
                        className="size_75 fit_cover_image border_36 mt-4 align-self-center"
                        src={(authState.authData.response.userimage && !authState.authData.response.userimage.includes("www.swetrack.com/dhhddh3333333.png")) ? authState.authData.response.userimage : FlavorImages.pic_default}
                        alt={"...No image"}
                      />
                      }

                  </div>
                }
                <label className="text-uppercase margin-left_11 pt-3 pt-md-0 mt-md-4  text_11_300">
                  {t("Settings.MyAccount")}
                </label>
                <div className={`col-12  d-flex scroll_div_7_md flex-column justify-content-start align-items-start px-2 ${process.env.REACT_APP_FLAVOR === "Bike" ? "mt-2" : "mt-0"}  pb-3`}>
                  <div className="px-0  bg-white w-100 d-flex flex-column settings_rounded_white justify-content-start align-items-start">
                    <div
                      className={`${select === "EditAccount" || select === "Delete"
                        ? "bg-navy background_black_helkama"
                        : "bg-white"
                        } w-100 settings_edit_account_div p-3 d-flex  border-bottom rounded-top justify-content-between align-items-center cursor-pointer`}
                      onClick={() => setSelect("EditAccount")}
                    >
                      <label
                        className={`${select === "EditAccount" || select === "Delete"
                          ? "text-white"
                          : "text-navy"
                          } transition_color ml-1 text_16_400  my-0`}
                      >
                        {t("Settings.EditAccount")}
                      </label>
                      <img
                        src={
                          select === "EditAccount" || select === "Delete"
                            ? FlavorImages.forward_arrow_white
                            : FlavorImages.forward_arrow_grey
                        }
                        className="size_15"
                        alt="..."
                      />
                    </div>

                    <div
                      className={`cursor-pointer ${select === "Safety" ? "bg-navy background_black_helkama" : "bg-white"
                        } w-100 settings_edit_account_div p-3    d-flex  border-bottom justify-content-between align-items-center`}
                      onClick={() => {
                        setSelect("Safety")
                        dispatch(settingsActions.setPage({ page: "Safety" }))
                      }}
                    >
                      <label
                        className={`${select === "Safety" ? "text-white" : "text-navy"
                          } transition_color ml-1 text_16_400  my-0`}
                      >
                        {t("Settings.Safety")}
                      </label>

                      <img
                        src={
                          select === "Safety"
                            ? FlavorImages.forward_arrow_white
                            : FlavorImages.forward_arrow_grey
                        }
                        className="size_15"
                        alt="..."
                      />
                    </div>

                    <div
                      className={`cursor-pointer ${select === "Signout" ? "bg-navy background_black_helkama" : "bg-white"
                        } w-100 settings_edit_account_div p-3 d-flex  justify-content-between rounded-bottom align-items-center`}
                      onClick={() => deleteDialog()}
                    >
                      <label className=" text_16_400  ml-1 text-red my-0">
                        {t("Settings.Logout")}{" "}
                      </label>

                      <img
                        src={FlavorImages.forward_arrow_grey}
                        className="size_15"
                        alt="..."
                      />
                    </div>
                  </div>
                  <label className="text-uppercase  ml-1 mt-4 text_11_300">
                    {t("Settings.Managment")}
                  </label>

                  <div className="p-0  bg-white w-100 d-flex flex-column settings_rounded_white justify-content-start align-items-start">
                    <div
                      className={`${select === "Bikes" ||
                        select === "BikeDetail" ||
                        select === "DeleteBike"
                        ? "bg-navy background_black_helkama"
                        : "bg-white"
                        } ${process.env.REACT_APP_FLAVOR === "Alco" || process.env.REACT_APP_FLAVOR === "Bike"
						? "rounded"
						: "rounded-top"
					  	} w-100 settings_edit_account_div p-3 cursor-pointer d-flex  border-bottom rounded-top justify-content-between align-items-center`}
                      onClick={() => setSelect("Bikes")}
                    >
                      <label
                        className={`${select === "Bikes" ||
                          select === "BikeDetail" ||
                          select === "DeleteBike"
                          ? "text-white"
                          : "text-navy"
                          } transition_color ml-1 text_16_400  my-0`}
                      >
                        {t("customFile.Bikes")}
                      </label>

                      <img
                        src={
                          select === "Bikes" ||
                            select === "BikeDetail" ||
                            select === "DeleteBike"
                            ? FlavorImages.forward_arrow_white
                            : FlavorImages.forward_arrow_grey
                        }
                        className="size_15"
                        alt="..."
                      />
                    </div>

                    {process.env.REACT_APP_FLAVOR === "" ? (
                      <div
                        className={`${select === "Socials" ? "bg-navy background_black_helkama" : "bg-white"
                          } w-100 settings_edit_account_div p-3    d-flex  border-bottom justify-content-between align-items-center`}
                        onClick={() => setSelect("Socials")}
                      >
                        <label
                          className={`${select === "Socials" ? "text-white" : "text-navy"
                            } transition_color ml-1 text_16_400  my-0`}
                        >
                          {t("Settings.Socials")}
                        </label>

                        <img
                          src={
                            select === "Socials"
                              ? FlavorImages.forward_arrow_white
                              : FlavorImages.forward_arrow_grey
                          }
                          className="size_15"
                          alt="..."
                        />
                      </div>
                    ) : null}
                     {process.env.REACT_APP_FLAVOR === "Alco" || process.env.REACT_APP_FLAVOR === "Bike" ? (
                     null
                    ) : 
                    <div
                    className={`cursor-pointer ${select === "Billing" ? "bg-navy background_black_helkama" : "bg-white"
                      } w-100 settings_edit_account_div p-3    d-flex  rounded-bottom justify-content-between align-items-center`}
                    onClick={() => setSelect("Billing")}
                     >
                    <label
                      className={`${select === "Billing" ? "text-white" : "text-navy"
                        } transition_color ml-1 text_16_400  my-0`}
                    >
                      {t("Settings.Billing")}
                    </label>

                    <img
                      src={
                        select === "Billing"
                          ? FlavorImages.forward_arrow_white
                          : FlavorImages.forward_arrow_grey
                      }
                      className="size_15"
                      alt="..."
                    />
                    </div>
                  }
                  </div>

                  <label className="text-uppercase ml-1 curosor-pointer mt-4 text_11_300">
                    {t("Settings.Notifications")}
                  </label>

                  <div className="p-0 cursor-pointer bg-white  w-100 d-flex flex-column settings_rounded_white justify-content-start align-items-start">
                    {process.env.REACT_APP_FLAVOR === "Bike" ? (
                      <div className="w-100">
                        <div
                          className={`cursor-pointer ${select === "BikeNotifications" ||
                            select === "SoundNotification"
                            ? "bg-navy background_black_helkama"
                            : "bg-white"
                            } w-100 settings_edit_account_div p-3    d-flex  border-bottom   rounded justify-content-between align-items-center`}
                          onClick={() => {
                            setSelect("BikeNotifications");
                            setParent("BikeNotifications");
                          }}
                        >
                          <label
                            className={`${select === "BikeNotifications" ||
                              select === "SoundNotification"
                              ? "text-white"
                              : "text-navy"
                              } transition_color ml-1 cursor-pointer text_16_400  my-0`}
                          >
                            {t("Settings.BikeNotifications")}
                          </label>

                          <img
                            src={
                              select === "BikeNotifications"
                                ? FlavorImages.forward_arrow_white
                                : FlavorImages.forward_arrow_grey
                            }
                            className="size_15 cursor-pointer"
                            alt="..."
                          />
                        </div>


                        {/* <div
                          className={`${select === "GeneralNotifications"
                            ? "bg-navy background_black_helkama"
                            : "bg-white"
                            } w-100 settings_edit_account_div p-3    d-flex  rounded-bottom justify-content-between align-items-center`}
                          onClick={() => {
                            setSelect("GeneralNotifications");
                            setParent("GeneralNotifications");
                          }}
                        >
                          <label
                            className={`${select === "GeneralNotifications"
                              ? "text-white"
                              : "text-navy"
                              } transition_color ml-1 text_16_400  my-0`}
                          >
                            {t("Settings.GeneralNotifications")}
                          </label>

                          <img
                            src={
                              select === "GeneralNotifications"
                                ? FlavorImages.forward_arrow_white
                                : FlavorImages.forward_arrow_grey
                            }
                            className="size_15"
                            alt="..."
                          />
                        </div> */}
                      </div>
                    ) : (
                      <div
                        className={`${select === "BikeNotifications" ||
                          select === "SoundNotification"
                          ? "bg-navy background_black_helkama"
                          : "bg-white"
                          } w-100 settings_edit_account_div p-3    d-flex  cursor-pointer  rounded-bottom  rounded-top justify-content-between align-items-center`}
                        onClick={() => { setSelect("BikeNotifications"); dispatch(settingsActions.setPage({ page: "Safety" })) }}
                      >
                        <label
                          className={`${select === "BikeNotifications" ||
                            select === "SoundNotification"
                            ? "text-white"
                            : "text-navy"
                            } transition_color ml-1 cursor-pointer text_16_400  my-0`}
                        >
                          {t("Settings.setNotifications")}
                        </label>

                        <img
                          src={
                            select === "BikeNotifications"
                              ? FlavorImages.forward_arrow_white
                              : FlavorImages.forward_arrow_grey
                          }
                          className="size_15"
                          alt="..."
                        />
                      </div>
                    )}
                  </div>
                  {process.env.REACT_APP_ASSETS === "arctictracks" ? (
                  null                  
                  ) : (
                  <>
                  <label className="text-uppercase ml-1 mt-4 text_11_300">
                    {t("Settings.Support")}
                  </label>
                  <div className="p-0  bg-white w-100 d-flex flex-column settings_rounded_white justify-content-start align-items-start">
                    <div
                      className={`cursor-pointer ${select === "Support" || select === "ServiceLocation"
                        ? "bg-navy background_black_helkama"
                        : "bg-white"
                        } w-100 settings_edit_account_div p-3    d-flex  border-bottom  rounded-top justify-content-between align-items-center`}
                      onClick={() => setSelect("Support")}
                    >
                      <label
                        className={`${select === "Support" || select === "ServiceLocation"
                          ? "text-white"
                          : "text-navy"
                          } transition_color ml-1 text_16_400  my-0`}
                      >
                        {t("Settings.Support")}
                      </label>

                      <img
                        src={
                          select === "Support" || select === "ServiceLocation"
                            ? FlavorImages.forward_arrow_white
                            : FlavorImages.forward_arrow_grey
                        }
                        className="size_15"
                        alt="..."
                      />
                    </div>
                    {process.env.REACT_APP_FLAVOR === "Alco" 
                    ? 
                      ("")
                    : 
                    (<div
                      className={`cursor-pointer ${select === "Warranty" ? "bg-navy background_black_helkama" : "bg-white"
                        } w-100 settings_edit_account_div p-3    d-flex  border-bottom justify-content-between align-items-center`}
                      onClick={() => setSelect("Warranty")}
                    >
                      <label
                        className={`${select === "Warranty" ? "text-white" : "text-navy"
                          } transition_color ml-1 text_16_400  my-0`}
                      >
                        {t("Settings.Warranty")}
                      </label>

                      <img
                        src={
                          select === "Warranty"
                            ? FlavorImages.forward_arrow_white
                            : FlavorImages.forward_arrow_grey
                        }
                        className="size_15 cursor-pointer"
                        alt="..."
                      />
                      </div>)
                    }

                  <div
                    className={`cursor-pointer ${select === "UserManuals" ? "bg-navy background_black_helkama" : "bg-white"
                      } w-100 settings_edit_account_div p-3    d-flex  rounded-bottom justify-content-between align-items-center`}
                    onClick={() => setSelect("UserManuals")}
                  >
                    <label
                      className={`${select === "UserManuals" ? "text-white" : "text-navy"
                        } transition_color ml-1 text_16_400  my-0`}
                    >
                      {t("Settings.UserManuals")}
                    </label>

                    <img
                      src={
                        select === "UserManuals"
                          ? FlavorImages.forward_arrow_white
                          : FlavorImages.forward_arrow_grey
                      }
                      className="size_15 cursor-pointer"
                      alt="..."
                    />
                  </div> 

                    {/* CREDIT UTKOMMENTERAD
                    <div
                      className={`cursor-pointer ${select === "Credit" ? "bg-navy background_black_helkama" : "bg-white"
                        } w-100 settings_edit_account_div p-3    d-flex  rounded-bottom justify-content-between align-items-center`}
                      onClick={() => setSelect("Credit")}
                    >
                      <label
                        className={`${select === "Credit" ? "text-white" : "text-navy"
                          } transition_color ml-1 text_16_400  my-0`}
                      >
                        {t("Settings.Credit")}
                      </label>

                      <img
                        src={
                          select === "Credit"
                            ? forward_arrow_white
                            : forward_arrow_grey
                        }
                        className="size_15"
                        alt="..."
                      />
                    </div> */}
                  </div>
                  </>
                  )}
                </div>
              </div>

              {select !== "" &&
                <div className="col-md-8 px-0 pt-md-0 dvh-100" style={{ overflowY: "scroll" }}>

                  {select === "EditAccount" && <EditAccount
                    setSelect_null={() => setSelect("")}
                    showEditDialog={() => setShowEditDialog(true)}
                    showEditEmailDialog={() => showEditEmailDialog(true)}
                    showtEditPasswordDialog={() => setPasswordDialog(true)}
                    selectDelete={() => setSelect("Delete")}
                    showUI={select === "EditAccount"}
                  />}


                  {select === "Safety" && <Safety
                    setSelect_null={() => setSelect("")}
                    setError={setError}
                    select={select}
                    setSelect={() => setSelect("Safety")}
                    showUI={select === "Safety"}
                  />}

                  {select === "Bikes" && <Bikes
                    setSelect_null={() => setSelect("")}
                    setSelect={(data) => setSelect(data)}
                    setShairedBike={() => setShairedBike(true)}
                    setDeviceName={(data) => setDeviceName(data)}
                    showUI={select === "Bikes"}
                  />}

                  {select === "Support" && <Support
                    setSelect_null={() => setSelect("")}
                    setSelect={(data) => setSelect(data)}
                    showUI={select === "Support"}
                  />}

                  {select === "Warranty" && <Warranty
                    setSelect_null={() => setSelect("")}
                    showUI={select === "Warranty"} />}

                  {select === "UserManuals" && <UserManual
                    setSelect_null={() => setSelect("")}
                    showUI={select === "UserManuals"} />}

                  {select === "Credit" && <Credit
                    setSelect_null={() => setSelect("")}
                    showUI={select === "Credit"} />}
                  {select === "Delete" && <DeleteAccount
                    setSelect_null={() => setSelect("")}
                    select={select}
                    setError={(errormsg) => setShowErrorDialog(errormsg)}
                    setSelect={(data) => setSelect(data)}
                    showUI={select === "Delete"}
                  />}

                  {select === "DeleteBike" && <DeleteBike
                    setSelect_null={() => setSelect("")}
                    setSelect={(data) => setSelect(data)}
                    deviceName={deviceName}
                    setError={(errormsg) => setShowErrorDialog(errormsg)}
                    select={select}
                    showUI={select === "DeleteBike"}
                  />}

                  {select === "DeleteShareBike" && <DeleteShareBike
                    setSelect_null={() => setSelect("")}
                    setSelect={(data) => setSelect(data)}
                    bikeDetail={bikeDetail}
                    deviceName={deviceName}
                    setError={setError}
                    select={select}
                    showUI={select === "DeleteShareBike"}
                  />}

                  {select === "BikeDetail" && <BikeDetail
                    setSelect_null={() => setSelect("")}
                    setSelect={(data) => setSelect(data)}
                    setEditBikeName={() => setEditBikeName(true)}
                    deviceName={deviceName}
                    setsharedbyme={() => setsharedbyme()}
                    showUI={select === "BikeDetail"}
                  />}

                  {select === "DataPlan" && <DataPlanDialog
                    setSelect_null={() => setSelect("")}
                    select={select}
                    selectGoBack={() => setSelect("BikeDetail")}
                    showUI={select === "DataPlan"}
                  />}

                  {select === "SharingStatus" && <SharingStatus
                    setSelect_null={() => setSelect("")}
                    select={select}
                    deviceName={deviceName}
                    setSelect={(data) => setSelect(data)}
                    showUI={select === "SharingStatus"}
                  />}

                  {select === "AcceptedShareBike" && <AcceptedShareBike
                    setSelect_null={() => setSelect("")}
                    deviceName={deviceName}
                    setBikeDetail={(data) => setBikeDetail(data)}
                    setEditBikeName={() => setEditBikeName(true)}
                    setSelect={(data) => setSelect(data)}
                    showUI={select === "AcceptedShareBike"}
                  />}

                  {select === "Socials" && <Socials
                    setSelect_null={() => setSelect("")}
                    setSelect={(data) => setSelect(data)}
                    showUI={select === "Socials"}
                  />}
                  {select === "Customize" && <Customize
                    setSelect_null={() => setSelect("")}
                    setSelect={(data) => setSelect(data)}
                    showUI={select === "Customize"}
                  />}



                  {select === "BikeNotifications" && <BikeNotifications
                    setSelect_null={() => setSelect("")}
                    setError={(msg) => setError(msg)}
                    setSelect={(data) => setSelect(data)}
                    alarmName={alarmName}
                    setSpinerActve={(value) => setSpinerActve(value)}
                    setDeviceId={(data) => setDeviceId(data)}
                    setSameForAll={(data) => setSameForAll(data)}
                    selectedAlarm={(data) => updateAlarm(data)}
                    showUI={select === "BikeNotifications"}
                  />}

                  {select === "GeneralNotifications" && <GeneralNotifications
                    setSelect_null={() => setSelect("")}
                    alarmName={alarmName}
                    setSelect={(data) => setSelect(data)}
                    showUI={select === "GeneralNotifications"}
                  />}

                  {select === "SoundNotification" && <SoundUI
                    setSelect_null={() => setSelect("")}
                    setSelect={(data) => setSelect(data)}
                    parent={parent_location}
                    sameForAll={sameForAll}
                    alarmId={soundId}
                    deviceId={deviceId}
                    setSoundDuration={(data) => setSoundDuration(data)}
                    duration={soundDuration}
                    setSoundId={(data) => updateSoundId(data)}
                    setSoundDuartion={(data) => updateSoundDuration(data)}
                    showUI={select === "SoundNotification"}
                  />}

                  {select === "ServiceLocation" && <LocationServiceRow
                    setSelect_null={() => setSelect("")}
                    setSelect={(data) => setSelect(data)}
                    select={select}
                    showUI={select === "ServiceLocation"}
                  />}

                  {select === "Billing" && <Billing
                    setSelect_null={() => setSelect("")}
                    setSelect={(data) => setSelect(data)}
                    showUI={select === "Billing"}
                  />}
                </div>
              }
            </div>
            {error ? <PopUp setError={setError} error={error} /> : ""}
            {success ? <Succespopup setSuccess={setSucces} success={success} /> : ""}
            {authState.authData ? (
              <EditEmail
                closeEditEmailDialog={() => showEditEmailDialog(false)}
                loadProfileSetting={() => loadProfileSetting()}
                show={editEmailDialog}
                setError={() => setError()}
              />
            ) : (
              ""
            )}

            {/* {authState.authData ? (
              <EditName
                closeEditNameDialog={() => setShowEditDialog(false)}
                show={showEditDialog}
                setError={setError}
              />
            ) : (
              ""
            )} */}

            <EditPassword
              show={passwordDialog}
              setError={(msg) => setShowErrorDialog(msg)}
              closeEditPasswordDialog={() => setPasswordDialog(false)}
            />
            <SignOutDialog
              select={select}
              show={signOutDialog}
              setError={(msg) => setError(msg)}
              setSelect={() => setSelect("EditAccount")}
              closeSingoutDialog={() => setSignoutDialog(false)}
            />
            <ShareBike
              setShowErrorDialog={() => setShowErrorDialog(true)
              }
              setErrorMesage={(msg) => setShowErrorDialog(msg)}
              setSucces={(msg) => setSucces(msg)}
              show={shairedBike}
              closeShairedBikeDialog={() => setShairedBike(false)}
            />
            {devicesState.devices ? (
              <EditBikeName
                setShowErrorDialog={() => setShowErrorDialog(true)
                }
                setBikeName={setbikeName}
                setErrorMesage={(msg) => setShowErrorDialog(msg)}
                closeditBikeNameDialog={() => setEditBikeName(false)}
                show={editBike}
                deviceName={deviceName}
                setError={setError}
              />
            ) : (
              ""
            )}
          </div>
          <ErrorDialog
            showErrorDialog={errorDialog}
            errorMesage={errorDialog}
            // select={"settings"}
            onClose={() => setShowErrorDialog(false)}
          />
        </section>
      </React.Fragment>
    </>
  );
};

export default Settings;
