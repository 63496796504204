import { useEffect, useState, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { SITE_TITLE } from "../../helpers/constants";
import { seo } from "../../helpers/seo";
import * as genericService from "../../services/genericService";
import SafetyRow from "./SafetyRow";
import SignOutDialog from "./SignOut";
import ConfirmDialog from "./ConfirmDialog";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import PopUp from "../../pages_v2/Device/PopUp";
import BackWardArrow from "../../components/Buttons/BackwardArrow";
import DialogBox from "./DialogBox";
import { settingsSlice } from "../../slice/settingsSlice";
import toast, { Toaster } from 'react-hot-toast';

const settingsActions = settingsSlice.actions;
const Safety = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loginHistory, setLoginHistory] = useState();
  const [error, setError] = useState("");
  const [clearLoginDialog, setclearLoginDialog] = useState(false);
  const [signOutDialog, setSignoutDialog] = useState(false);
  const getLoginHistory = async () => {
    const response = await genericService.get("login/history");
    if (response.status === 200) {
      var info = response.data;
      setLoginHistory(info);
    }
  };
  const { settingsState } = useSelector(
    (state) => ({ settingsState: state.settings }),
    shallowEqual
  );

  const confirmClearHistory = async () => {
    var loginids = loginHistory.map((history) => history.id);
    const response = await genericService.post("login/delete", {
      loginid: loginids,
      deleteAll: true
    });
    if (response.status === 200) {
      setclearLoginDialog(false);
      getLoginHistory()
      toast.success(t("toast.Done"));

    } else {
      setError(response.data.message);
      setclearLoginDialog(false);
    }
  };

  const on_load = () => {
    getLoginHistory();
    seo({
      title: SITE_TITLE,
      metaDescription: "",
    });
  };

  useEffect(() => {
    on_load();
  }, []);

  return (
    <>
      <div
        className={`${props.showUI === true ? "show_ui" : "hide_ui"
          } col-md-8 d-flex margin_top_18 flex-column  inner`}
      >
        <BackWardArrow show={false}
          onClick={() => {
            props.setSelect_null()
            dispatch(settingsActions.setPage({ page: "" }));
          }}
        />
        <label className="mt-4 text_22_700 text-left mt-2 ml-2">
          {t("Settings.LoginHistory")}
        </label>
        <div className="d-flex flex-column  mt-4  ">

          <div className="scroll_div_11 alarm_list_shadow rounded">
            {loginHistory !== undefined
              ? loginHistory.map((history, index) => {
                return (
                  <SafetyRow
                    history={history}
                    index={index}
                    key={index}
                    isFirst={index === 0}
                    isLast={loginHistory.length === index + 1}
                  />
                );
              })
              : null}
          </div>
        </div>

        {loginHistory !== undefined && loginHistory.length > 0 
        ? (
          <div className="w-100 mt-3 d-none d-md-flex justify-content-end">
            <button
              className="btn btn_navy bg_dark_helkama text-uppercase ml-3 mb-3 box_shadow_1"
              onClick={() => setclearLoginDialog(true)}
            >
              {t("Settings.ClearHistory")}
            </button>
            <button
              onClick={() => setSignoutDialog(true)}
              className="btn btn_red text-uppercase ml-3 mb-3 box_shadow_1"
            >
              {t("Settings.LogoutAllInstances")}
            </button>
          </div>
        )
      : (
          <button
            onClick={() => setSignoutDialog(true)}
            className="btn btn_red text-uppercase ml-3 mb-3 box_shadow_1"
          >
            {t("Settings.LogoutAllInstances")}
          </button>
      )
      }
        <SignOutDialog
          show={signOutDialog || settingsState.logoutdial}
          select={props.select}
          setError={(msg) => setError(msg)}
          setSelect={() => props.setSelect()}
          closeSingoutDialog={() => setSignoutDialog(false)}
        />
        <DialogBox
          show={settingsState.dialog}
          select={props.select}
          setSelect={() => props.setSelect()}
          closeSingoutDialog={() => setSignoutDialog(false)}
        />
        <ConfirmDialog
          setError={() => props.setError()}
          onConfirm={() => confirmClearHistory()}
          closeConfirmDialog={() => setclearLoginDialog(false)}
          show={clearLoginDialog || settingsState.cleardial}
          select={props.select}
        />
        {error ? <PopUp setError={setError} error={error} /> : ""}
      </div>
    </>
  );
};

export default Safety;
