import React from "react";
import { devicesSlice } from "../../slice/deviceSlice";
import { useTranslation } from "react-i18next";
// import check_empty from "../../assets/images/check_empty.svg";
import { useDispatch } from "react-redux";
// import checked from "../../assets/images/checked.svg";
import _ from "lodash";
import FlavorImages from "../../assetsFolderImport";

const ChooseAllCheckBox = (props) => {
  const { t } = useTranslation();
  const deviceActions = devicesSlice.actions;
  const dispatch = useDispatch();
  const checkClick = () => {
    var array = _.cloneDeep(props.devicesState.filteredDevices);
    var device;
    if (props.isChecked) {
      for (let index = 0; index < array.length; index++) {
        device = array[index];
        device.isSelected = false;
        array[index] = device;
      }
      dispatch(
        deviceActions.setUpFilteredDeviceData({
          devices: array,
        })
      );
      props.onClick(false);
    } else {
      for (let index = 0; index < array.length; index++) {
        device = array[index];
        device.isSelected = true;
        array[index] = device;
      }
      dispatch(
        deviceActions.setUpFilteredDeviceData({
          devices: array,
        })
      );
      props.onClick(true);
    }
  };

  return (
    <React.Fragment>
      <div
        className="d-flex align-items-center "
        style={{ minWidth: "104px" }}
      >
        {props.isChecked ? (
          <img
            className="cursor-pointer"
            alt="..."
            style={{ width: "20px", height: "20px" }}
            src={FlavorImages.checked}
            onClick={() => checkClick()}
          />
        ) : (
          <img
            className="cursor-pointer"
            alt="..."
            style={{ width: "20px", height: "20px" }}
            src={FlavorImages.check_empty}
            onClick={() => checkClick()}
          />
        )}

        <label
          className="text_12_700 text-navy px-2 m-0  ml-2 "
          style={{ textTransform: "capitalize" }}
        >
          {t("Geofences.ChooseAll")}
        </label>
      </div>
    </React.Fragment>
  );
};

export default ChooseAllCheckBox;
