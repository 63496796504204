import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import forward_arrow_grey from "../../assets/images/forward_arrow_grey.svg";
import { SITE_TITLE } from "../../helpers/constants";
import { seo } from "../../helpers/seo";
import * as genericService from "../../services/genericService";
import DoubleSwitchRow from "./DoubleSwitchRow";
import SwitchRow from "./SwitchRow";
import NameButton from "../../components/Buttons/NameButton";
import { useDispatch } from "react-redux";
import { settingsSlice } from "../../slice/settingsSlice";
import BackWardArrow from "../../components/Buttons/BackwardArrow";
const settingsActions = settingsSlice.actions;
const BikeNotifications = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [notifications, setNotification] = useState();
  const [sameForAll, setSameForAll] = useState(true);
  const [notificationdevice, setNotificationDevice] = useState([]);
  const [notificationsForAll, setNotificationsForAll] = useState();
  const [activateForAll, setActivateForAll] = useState(false);
  const [online, setOnLine] = useState(true);
  const [offline, setOffLine] = useState(true);
  const [moving, setMoving] = useState(true);
  const [stop, setStop] = useState(true);
  const [ignitionOn, setIgnitionOn] = useState(true);
  const [ignitionOff, setIgnitionOff] = useState(true);
  const [geofenceEnter, setGeofenceEnter] = useState(true);
  const [geofenceExit, setGeofenceExit] = useState(true);
  const [tampering, setTempering] = useState(true);
  const [lowBattery, setLowBattery] = useState(true);
  const [lowExternalBattery, setLowExternalBattery] = useState(true);
  const [vibration, setVibration] = useState(true);
  const [powerCut, setPowerCut] = useState(true);
  const [sos, setSOS] = useState(true);
  const [deviceOverSpeed, setDeviceOverSpeed] = useState(true);
  const [deviceUnknown, setDeviceUnknown] = useState(true);
  const [bypass, setBypass] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedDevice, setSelectedDevice] = useState();

  const getNotificationData = async (index) => {
    const response = await genericService.get("notification/settings");
    if (response.status === 200) {
      props.setSpinerActve(false)
      var info = response.data[0].notificationssettings;
      setNotification(info);
      setSameForAll(info.sameforalldevices);
      props.setSameForAll(info.sameforalldevices);
      if (info.sameforalldevices === true) {
        if(process.env.REACT_APP_FLAVOR === "Alco"){
          //console.log(info.notification)

          if (info.notification.ignitionOff && info.notification.lowBattery && info.notification.powerCut && info.notification.bypass) {
            setActivateForAll(true)
          } else {
            setActivateForAll(false)
          }
          }else if(process.env.REACT_APP_FLAVOR === "Bike"){
            if (info.notification.ignitionOn && info.notification.ignitionOff && info.notification.lowBattery && info.notification.geofenceEnter && info.notification.geofenceExit && info.notification.vibration && info.notification.deviceOverspeed) {
              setActivateForAll(true)
            }else{
              console.log("FALSE?");
              setActivateForAll(false)
            } 
        } else {
          const areFalse = Object.values(info.notification).every(
            value => value === false
          );
          if (areFalse) {
            setActivateForAll(false)
          }
          else {
            setActivateForAll(true)
          }
        }


        setNotificationsForAll(info.notification);
        updateNotifications(info.notification);
        props.selectedAlarm(info.alarmsound);
        setNotificationDevice([]);
      }
      else if (index) {
        const areFalse = Object.values(info.notificationdevice[index].notifications).every(
          value => value === false
        );
        if (areFalse) {
          setActivateForAll(false)
        }
        else {
          setActivateForAll(true)
        }
        setNotificationDevice(info.notificationdevice);
        setSelectedDevice(info.notificationdevice[index]);
        props.setDeviceId(info.notificationdevice[index].deviceid);
        props.selectedAlarm(info.notificationdevice[index].alarmsound);
        updateNotifications(info.notificationdevice[index].notifications);
      }
      else {
        const areFalse = Object.values(info.notificationdevice[0].notifications).every(
          value => value === false
        );
        if (areFalse) {
          setActivateForAll(false)
        }
        else {
          setActivateForAll(true)
        }
        setNotificationDevice(info.notificationdevice);
        setSelectedDevice(info.notificationdevice[0]);
        props.setDeviceId(info.notificationdevice[0].deviceid);
        props.selectedAlarm(info.notificationdevice[0].alarmsound);

        updateNotifications(info.notificationdevice[0].notifications);

      }
    }
  };

  const updateNotifications = (notification) => {
    notification.deviceOnline ? setOnLine(true) : setOnLine(false);
    notification.deviceOffline ? setOffLine(true) : setOffLine(false);
    notification.deviceMoving ? setMoving(true) : setMoving(false);
    notification.deviceStopped ? setStop(true) : setStop(false);
    notification.ignitionOn ? setIgnitionOn(true) : setIgnitionOn(false);
    notification.ignitionOff ? setIgnitionOff(true) : setIgnitionOff(false);
    notification.geofenceEnter
      ? setGeofenceEnter(true)
      : setGeofenceEnter(false);
    notification.geofenceExit ? setGeofenceExit(true) : setGeofenceExit(false);

    notification.deviceOverspeed
      ? setDeviceOverSpeed(true)
      : setDeviceOverSpeed(false);

    notification.lowBattery ? setLowBattery(true) : setLowBattery(false);

    notification.lowExternalBattery
      ? setLowExternalBattery(true)
      : setLowExternalBattery(false);

    notification.powerCut ? setPowerCut(true) : setPowerCut(false);

    notification.tampering ? setTempering(true) : setTempering(false);

    notification.deviceUnknown
      ? setDeviceUnknown(true)
      : setDeviceUnknown(false);

      notification.bypass
      ? setBypass(true)
      : setBypass(false);

    notification.sos ? setSOS(true) : setSOS(false);

    notification.vibration ? setVibration(true) : setVibration(false);


  };

  const updateNotification = (notificationId, value) => {
    if (notificationId === "deviceOnline") setOnLine(value);
    else if (notificationId === "deviceOffline") setOffLine(value);
    else if (notificationId === "deviceMoving") setMoving(value);
    else if (notificationId === "deviceStopped") setStop(value);
    else if (notificationId === "ignitionOn") setIgnitionOn(value);
    else if (notificationId === "ignitionOff") setIgnitionOff(value);
    else if (notificationId === "geofenceEnter") setGeofenceEnter(value);
    else if (notificationId === "geofenceExit") setGeofenceExit(value);
    else if (notificationId === "deviceOverspeed") setDeviceOverSpeed(value);
    else if (notificationId === "lowBattery") setLowBattery(value);
    else if (notificationId === "lowExternalBattery") setLowExternalBattery(value);
    else if (notificationId === "powerCut") setPowerCut(value);
    else if (notificationId === "tampering") setTempering(value);
    else if (notificationId === "deviceUnknown") setDeviceUnknown(value);
    else if (notificationId === "sos") setSOS(value);
    else if (notificationId === "vibration") setVibration(value);
    else if (notificationId === "bypass") setBypass(value);


    if (sameForAll) {
      updateAllNotification(notificationId, value);
    } else {
      updateDeviceNotification(notificationId, value);
    }
  };

  const activateAll = async (value) => {
    // props.setSpinerActve(true)
    if(process.env.REACT_APP_FLAVOR === "Alco"){
      var notificationArray = [];
      var ignitionOffArray = ["ignitionOff", value];
      var lowBatteryArray = ["lowBattery", value];
      var powerCutArray = ["powerCut", value];
      var bypassArray = ["bypass", value];
      notificationArray.push(ignitionOffArray);
      notificationArray.push(lowBatteryArray);
      notificationArray.push(powerCutArray);
      notificationArray.push(bypassArray);
    }else if (process.env.REACT_APP_FLAVOR === "Bike"){
      var notificationArray = [];
      var ignitionOnArray = ["ignitionOn", value];
      var ignitionOffArray = ["ignitionOff", value];
      var geofenceEnterArray = ["geofenceEnter", value];
      var geofenceExitArray = ["geofenceExit", value];
      var lowBatteryArray = ["lowBattery", value];
      var vibrationArray = ["vibration", value];
      var deviceOverSpeedArray = ["deviceOverspeed", value];

      notificationArray.push(ignitionOnArray);
      notificationArray.push(ignitionOffArray);
      notificationArray.push(geofenceEnterArray);
      notificationArray.push(geofenceExitArray);
      notificationArray.push(lowBatteryArray);
      notificationArray.push(deviceOverSpeedArray);
      notificationArray.push(vibrationArray);

    }else{
      var notificationArray = [];
      var onlineArray = ["deviceOnline", value];
      var offlineArray = ["deviceOffline", value];
      var ignitionOnArray = ["ignitionOn", value];
      var ignitionOffArray = ["ignitionOff", value];
      var geofenceEnterArray = ["geofenceEnter", value];
      var geofenceExitArray = ["geofenceExit", value];
      var deviceOverSpeedArray = ["deviceOverspeed", value];
      var lowBatteryArray = ["lowBattery", value];
      var lowExternalBatteryArray = ["lowExternalBattery", value];
      var powerCutArray = ["powerCut", value];
      var tamperingArray = ["tampering", value];
      var vibrationArray = ["vibration", value];
      // var bypassArray = ["bypass", value];
      
  
      notificationArray.push(onlineArray);
      notificationArray.push(offlineArray);
      notificationArray.push(ignitionOnArray);
      notificationArray.push(ignitionOffArray);
      notificationArray.push(geofenceEnterArray);
      notificationArray.push(geofenceExitArray);
      notificationArray.push(deviceOverSpeedArray);
      notificationArray.push(lowBatteryArray);
      notificationArray.push(lowExternalBatteryArray);
      notificationArray.push(powerCutArray);
      notificationArray.push(tamperingArray);
      notificationArray.push(vibrationArray);
      // notificationArray.push(bypassArray);
    }


    var data
    if (!sameForAll) {
      data = {
        deviceid: selectedDevice.deviceid,
        sameforalldevices: sameForAll,
        notifications: notificationArray,
      };
    }
    else {
      data = {
        sameforalldevices: sameForAll,
        notifications: notificationArray,
      };
      //console.log(data);
    }
    const response = await genericService.post(
      "notification/settingsupdate",
      data
    );
    if (response.status === 200 && response.data.successful) {
      getNotificationData();
      setActivateForAll(value);
    }
    else {
      props.setSpinerActve(false)
      props.setError(response.data.message)
    }
  };
  const updateDeviceNotification = async (notificationId, value) => {
    if (value === false) setActivateForAll(value);
    const data = {
      sameforalldevices: false,
      deviceid: selectedDevice.deviceid,
      notifications: [[notificationId, value]],
    };
    const response = await genericService.post(
      "notification/settingsupdate",
      data
    );

    if (response.status === 200 && response.data.successful) {
    }
  };

  const updateAllNotification = async (notificationId, value) => {

    if (value === false) setActivateForAll(value);

    const data = {
      sameforalldevices: true,
      notifications: [[notificationId, value]],
    };

    const response = await genericService.post(
      "notification/settingsupdate",
      data
    );

    if (response.status === 200 && response.data.successful) {
      const response2 = await genericService.get("notification/settings");
      if (response2.status === 200) {
        console.log(response2);
        const allTrue = Object.values(response2.data[0].notificationssettings.notification).every(
          value => value === true
        );
        if(allTrue){
          setActivateForAll(true)
        }else{
          setActivateForAll(false)
        }
      } 
    }
  };

  const updateSameForAll = async (sameForAll) => {
    getNotificationData();
    setSameForAll(sameForAll);
    props.setSameForAll(sameForAll);
    const data = {
      sameforalldevices: sameForAll,
    };
    const response = await genericService.post(
      "notification/settingsupdate",
      data
    );
    if (response.status === 200 && response.data.successful) {
      // getNotificationData();
    }
  };

  const selectDevice = async (index) => {
    setSelectedIndex(index);
    setSelectedDevice(notificationdevice[index]);
    props.setDeviceId(notificationdevice[index].deviceid);
    props.selectedAlarm(notificationdevice[index].alarmsound);
    getNotificationData(index)
    updateNotifications(notificationdevice[index].notifications);
  };

  const on_load = () => {
    getNotificationData();
    seo({
      title: SITE_TITLE,
      metaDescription: "",
    });

  };

  useEffect(() => {
    on_load();
  }, []);



  return (
    <>

      <div
        className={`${props.showUI === true ? "show_ui" : "hide_ui"
          } col-md-8 d-flex  margin-top_97 flex-column  inner`}
      >
        <BackWardArrow show={false}       //if passed false, it will be visible on below md devices, if passed true it will be visible irrespectively
          onClick={() => {
            props.setSelect_null()
            dispatch(settingsActions.setPage({ page: "" }));
          }}
        />

        {notifications !== undefined ? (
          <div className=" mt-md-3 noScroll scroll_div_6 d-flex flex-column px-1 pb-5">
            {notificationdevice !== undefined ? (

              <div className="col-12 m-0 p-0">
                <label className="mt-4 d-md-none text_22_700 text-left mt-2">
                  {t("Settings.Notifications")}
                </label>
                <div className="col m-0 p-0 bikenotification">
                  <div className="slider_x pb-1">
                    {notificationdevice.map((device, index) => (
                      <NameButton
                        name={device.devicename}
                        selected={selectedIndex === index}
                        onSelect={() => selectDevice(index)}
                        key={index}
                      />
                    ))}
                  </div>
                </div>
              </div>
            ) : null}


            <SwitchRow
              title={t("Settings.NotificationsPerBike")}
              switch={!sameForAll}
              SwitchClick={(e) => updateSameForAll(!e)}  // update all on chagne state
            />
            {/* <label className="text-uppercase text_11_300 text-left mt-4">
              {t("Settings.NotificationSound")}
            </label>

            <div
              className="p-3 cursor-pointer d-flex   rounded_white justify-content-between align-items-center"
              style={{ background: "#ffffff", width: "100%" }}
              onClick={() => {
                props.setSelect("SoundNotification");
              }}
            >
              <label className=" text_16_400 text-navy my-0">
                {t("Settings.Sound")}{" "}
              </label>

              <div>
                <label className=" text_12_500 text-navy my-0">
                  {props.alarmName}
                </label>
                <img
                  src={forward_arrow_grey}
                  className="size_15 ml-4"
                  alt="..."
                />
              </div>
            </div> */}

            <label className="text-uppercase text_11_300 text-left mb-0 mt-4">
              {t("Settings.NotificationSettings")}
            </label>

            <SwitchRow
              title={t("Settings.ActivateAllNotifications")}
              switch={activateForAll}
              SwitchClick={(e) => activateAll(e)}
            />

            {process.env.REACT_APP_FLAVOR === "Alco" || process.env.REACT_APP_FLAVOR === "Bike"
              ?
                null
              :
                <DoubleSwitchRow
                title1={t("Alarm.deviceOnline")}
                title2={t("Alarm.deviceOffline")}
                switch1={online}
                switch2={offline}
                SwitchOneClick={(e) => updateNotification("deviceOnline", e)}
                SwitchTwoClick={(e) => updateNotification("deviceOffline", e)}
                />
            }

            {process.env.REACT_APP_FLAVOR === "Alco" 
              ?
                <SwitchRow
                title={t("Alarm.PositiveBlow")}
                switch={ignitionOff}
                SwitchClick={(e) => updateNotification("ignitionOff", e)}
                />
              :
                <DoubleSwitchRow
                title1={t("Alarm.IgnitionOn")}
                title2={t("Alarm.IgnitionOff")}
                switch1={ignitionOn}
                switch2={ignitionOff}
                SwitchOneClick={(e) => updateNotification("ignitionOn", e)}
                SwitchTwoClick={(e) => updateNotification("ignitionOff", e)}
                />
            }

            {process.env.REACT_APP_FLAVOR === "Alco" 
              ?
                null
              :
                <DoubleSwitchRow
                title1={t("Alarm.GeofenceEnter")}
                title2={t("Alarm.GeofenceExit")}
                switch1={geofenceEnter}
                switch2={geofenceExit}
                SwitchOneClick={(e) => updateNotification("geofenceEnter", e)}
                SwitchTwoClick={(e) => updateNotification("geofenceExit", e)}
                />
            }
            
            {process.env.REACT_APP_FLAVOR === "Alco" || process.env.REACT_APP_FLAVOR === "Bike"
              ?
                null
              :
                <SwitchRow
                title={t("Alarm.Tampering")}
                switch={tampering}
                SwitchClick={(e) => updateNotification("tampering", e)}
                />
            }
            
            {process.env.REACT_APP_FLAVOR === "" 
              ?
                null
              :
                <SwitchRow
                title={t("Alarm.LowBattery")}
                switch={lowBattery}
                SwitchClick={(e) => updateNotification("lowBattery", e)}
                />
            }
            
            {process.env.REACT_APP_FLAVOR === "Alco" || process.env.REACT_APP_FLAVOR === "Bike"
              ?
                null
              :
                <SwitchRow
                title={t("Alarm.LowExternalBattery")}
                switch={lowExternalBattery}
                SwitchClick={(e) => updateNotification("lowExternalBattery", e)}
                />
            }
            
            {process.env.REACT_APP_FLAVOR === "Alco" 
              ?
                null
              :
                <SwitchRow
                title={t("Alarm.Vibration")}
                switch={vibration}
                SwitchClick={(e) => updateNotification("vibration", e)}
                />
            }
            
            {process.env.REACT_APP_FLAVOR === "Bike" 
              ?
                null
              :
                <SwitchRow
                title={t("Alarm.PowerCut")}
                switch={powerCut}
                SwitchClick={(e) => updateNotification("powerCut", e)}
                />
            }
            
            {process.env.REACT_APP_FLAVOR === "Alco" 
              ?
                null
              :
                <SwitchRow
                title={t("Alarm.DeviceOverspeed")}
                switch={deviceOverSpeed}
                SwitchClick={(e) => updateNotification("deviceOverspeed", e)}
                />
            }

            {process.env.REACT_APP_FLAVOR === "Alco" 
              ?
                <SwitchRow
                title={t("Alarm.Bypass")}
                switch={bypass}
                SwitchClick={(e) => updateNotification("bypass", e)}
                />
              :
                null
            }


          </div>
        ) : null}
      </div>

    </>
  );
};

export default BikeNotifications;
