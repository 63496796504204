import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import dot_selected from "../../assets/images/dot_selected.svg";
// import dot_selected_helkam from "../../assets/Helkama_images/dot_selected_helkam.svg";
// import dot_unselected from "../../assets/images/dot_unselected.svg";
import LanguageChanger from "../../components/LanguageChanger/LanguageChanger";
import { INDEX_URL, SITE_TITLE } from "../../helpers/constants";
import { seo } from "../../helpers/seo";
import { registerSlice } from "../../slice/registerSlice";
import CustomDropdown1 from "../../components/dropdowns/CustomDropdown1";
import CustomInput1 from "../../components/inputs/CustomInput1";
import * as accountService from "../../services/user/accountService2";
// import cross_red from "../../assets/images/cross_red.svg";
// import check_light_navy from "../../assets/images/check_light_navy.svg";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
// import backward_arrow_grey from "../../assets/images/backward_arrow_grey.svg";
import ErrorDialog from "../LocationHistory/ErrorDialog";
import { MAIL_FORMATE } from '../../helpers/constants';
import * as signUpService2 from '../../services/user/signUpService2';
import { authSlice } from "../../slice/authSlice";
import FlavorImages from "../../assetsFolderImport";
import {
  enUS,
  sv,
  it,
  nb,
  da,
  fi,
  de,
  fr,
  nl,
  pl,
  es,
  cs,
  pt,
  el,
} from 'date-fns/locale';

let dates = [];
for (var i = 31; i > 0; i--) {
  dates.push({ value: i, label: i });
}

let months = [
  { value: "01", label: "JAN" },
  { value: "02", label: "FEB" },
  { value: "03", label: "MAR" },
  { value: "04", label: "APR" },
  { value: "05", label: "MAY" },
  { value: "06", label: "JUN" },
  { value: "07", label: "JUL" },
  { value: "08", label: "AUG" },
  { value: "09", label: "SEP" },
  { value: "10", label: "OCT" },
  { value: "11", label: "NOV" },
  { value: "12", label: "DEC" },
];

let years = [];
let max_year_show = new Date().getFullYear();
let min_year_show = new Date().getFullYear() - 70;
for (let i = max_year_show; i >= min_year_show; i--) {
  years.push({ value: i, label: i });
}

const getLanguage = () => {
  const language = localStorage.getItem('language');
  switch (language) {
    case 'en':
      return enUS;
    case 'sv':
      return sv;
    case 'it':
      return it;
    case 'nb':
      return nb;
    case 'da':
      return da;
    case 'fi':
      return fi;
    case 'de':
      return de;
    case 'fr':
      return fr;
    case 'nl':
      return nl;
    case 'pl':
      return pl;
    case 'es':
      return es;
    case 'cs':
      return cs;
    case 'pt':
      return pt;
    case 'el':
      return el;
    default:
      return enUS; // Fallback to English if the language is not recognized
  }
};

const registerActions = registerSlice.actions;
const SignUpEmailAccountDetail = () => {
  const { t } = useTranslation();
  const [isForcePickerOpen, setIsOpen] = useState(false);
  const dateCopy = new Date();
  dateCopy.setFullYear(dateCopy.getFullYear() - 18);
  const [dateString, setdateString] = useState('')
  //console.log("🚀 ~ file: SignUpEmailAccountDetail.js:63 ~ SignUpEmailAccountDetail ~ dateString:", dateString)
  const [selectedDate, handleDateChange] = useState(dateCopy);
  const [age, SetAge] = useState(false)
  const [selectAge, setSelectAge] = useState(false)
  const [btnText, setbtnText] = useState(ReactHtmlParser(t("signup.Next")))
  const [day, setDay] = useState(new Date().getDate());
  let current_month_number = new Date().getMonth() + 1;
  if (current_month_number < 10)
    current_month_number = "0" + current_month_number;
  const authactions = authSlice.actions;
  const [month, setMonth] = useState(current_month_number);
  const [year, setYear] = useState(new Date().getFullYear());
  const [weight, setWeight] = useState("");
  const [gender, setGender] = useState("");
  const { registerState } = useSelector(
    (state) => ({ registerState: state.register }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [is_valid_vin, setIsValidVin] = useState(-1);
  const [errorDialog, setShowErrorDialog] = useState(false)

  const getAge = (e) => {
    handleDateChange(e)
    var d = new Date(e),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    var today = new Date();
    var birthDate = new Date([year, month, day].join('-'));
    let dateString = year + "-" + month + "-" + day
    setdateString(dateString)
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (age > 0)
      return setSelectAge(age);
    else
      return setSelectAge(0)
  }

  const clickNext = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      if (!registerState.pretoken && (process.env.REACT_APP_FLAVOR === "Tracking") || process.env.REACT_APP_FLAVOR === "Alco") {
        dispatch(
          registerActions.setStepAccountDetailData({
            username: process.env.REACT_APP_FLAVOR === "Bike" ? fields : fields["UserName"],
            step: "vinnumber",
          })
        );
        history.push(INDEX_URL + "signup/vinnumber");
      }
      else if (!registerState.pretoken && process.env.REACT_APP_FLAVOR === "Bike") {
        dispatch(
          registerActions.setStepAccountDetailData({
            username: fields["UserName"],
            step: "vinnumber",
          })
        );
        dispatch(
          registerActions.setUserAccountData({
            userData: fields,
            step: "vinnumber",
          })
        );
        history.push(INDEX_URL + "signup/vinnumber");
      } else if(registerState.pretoken && process.env.REACT_APP_FLAVOR !== "Bike") {
        //console.log(registerState.email);
        const data = {
          invitetoken: registerState.email,
          password: registerState.password,
          username: fields["UserName"],
        };
        let response = await signUpService2.registerPreEmail(data);
        if (response.status === 802) {
          if (parseInt(response.data.errorCode) === 20025) {
            dispatch(registerActions.setStep({ step: "emailexist" }));
            history.push(INDEX_URL);
          }else if (parseInt(response.data.errorCode) === 20069) {
            dispatch(registerActions.setStep({ step: "emailexist" }));
            setShowErrorDialog(response.data.message)
          }
        } else if (response.status === 200) {
          let token = response.data.accessToken;
          dispatch(
            registerActions.setokenData({
              pretoken: false,
            })
          );
          dispatch(authactions.setAuthToken({ authToken: token }));
          const user_info_response = await accountService.getAccountInfo(token);
          if (user_info_response.status === 200) {
            dispatch(
              authactions.setAuthData({ authData: user_info_response.data })
            );
            setTimeout(() => {
              history.push(INDEX_URL + "devices");
            }, 1200);
          }
        }
      }else{

        const data = {
          invitetoken: registerState.email,
          password: registerState.password,
          birthday: fields.birthday,
          gender: fields.gender,
          imageurl: fields.imageurl,
          weight: fields.weight && parseInt(fields.weight),
          username: fields["UserName"]
        };
        if (fields.imageurl) {
          let updatePictureResponse = await signUpService2.putPicture(fields.imageurl)
          if (updatePictureResponse.status == 200) {
            data["imageurl"] = updatePictureResponse.data.imageurl
          }
        }else{
          delete data["imageurl"]
        }
        let response = await signUpService2.registerPreEmail(data);
        if (response.status === 802) {
          if (parseInt(response.data.errorCode) === 20025) {
            dispatch(registerActions.setStep({ step: "emailexist" }));
            history.push(INDEX_URL);
          }else if (parseInt(response.data.errorCode) === 20069) {
            dispatch(registerActions.setStep({ step: "emailexist" }));
            setShowErrorDialog(response.data.message)
          }
        } else if (response.status === 200) {
          let token = response.data.accessToken;
          dispatch(
            registerActions.setokenData({
              pretoken: false,
            })
          );
          dispatch(authactions.setAuthToken({ authToken: token }));
          const user_info_response = await accountService.getAccountInfo(token);
          if (user_info_response.status === 200) {
            dispatch(
              authactions.setAuthData({ authData: user_info_response.data })
            );
            setTimeout(() => {
              history.push(INDEX_URL + "devices");
            }, 1200);
          }
        }
      }

    }
  };

  const handleValidation = () => {
    let fields_temp = _.cloneDeep(fields);
    let errors = {};
    let formIsValid = true;

    if (!fields_temp["UserName"]) {
      formIsValid = false;
      errors["UserName"] = t("errors.EnterUserName");
      setIsValidVin(0);
    }

    setErrors(errors);
    return formIsValid;
  };
  const setDate = (field, value) => {
    let fields_temp = _.cloneDeep(fields);
    if(field === "Day"){
      if(value < 10){
        value = "0"+value
      }
      //console.log(value);
    setDay(value)

    }else if (field === "Month")
    setMonth(value)
    else if (field === "year")
    {
      setYear(value)
    }
    let dateString = year + "-" + month + "-" + day
    fields_temp["birthday"] = dateString;

    //console.log(fields_temp);
    setFields(fields_temp);
  }
  const handleChange = (field, e) => {
    let fields_temp = _.cloneDeep(fields);
    let errors = {};
    if (field === "UserName") {
      fields_temp[field] = e.target?.value
    }
    else if(field === "weight"){
      var lock
      if(e>999){
        lock = 999
      }else if(e<0){
        lock = 0
      }else{
        lock = e
      }
      fields_temp[field] = lock
    }
    else {
      fields_temp[field] = e
    }
    if (fields_temp.UserName !== "" && fields_temp.UserName !== undefined) {
      setIsValidVin(1);
    } else {
      errors["UserName"] = "";
      setIsValidVin(-1);
    }


    setFields(fields_temp);
    setErrors(errors);
  };

  const validatePrefilledData = () => {
    const fields_to_validate = ["email", "password"];
    for (const field of fields_to_validate) {
      if (!registerState[field]) {
        history.push(INDEX_URL + "signup");
        break;
      }
    }
  };

  const on_load = () => {
    validatePrefilledData();
    seo({
      title: SITE_TITLE,
      metaDescription: "",
    });
  };

  useEffect(() => {
    if (registerState.pretoken) {
      setbtnText(ReactHtmlParser(t("signup.CreateAccount")))
    }
    else {
      // on_load();
    }
  }, []);

  const emptyUserName = () => {
    setFields({ ...fields, UserName: "" });
    setIsValidVin(-1);
  };

  const [fileSrc, setFileSrc] = useState("");

  const onProfilePhotoSelect = (e) => {
    let fields_temp = _.cloneDeep(fields);
    if (e.target.files && e.target.files[0]) {
      setFileSrc(URL.createObjectURL(e.target.files[0]));
      fields_temp["imageurl"] = e.target.files[0]
      setFields(fields_temp);
    }
  };

  const clearProfilePhoto = () => {
    let fields_temp = _.cloneDeep(fields);
    setFileSrc("");
    fields_temp["imageurl"] = null;
    setFields(fields_temp);
  };
  const setageOnaccept = () => {
    let fields_temp = _.cloneDeep(fields);
    fields_temp["birthday"] = dateString;
    setFields(fields_temp);
    SetAge(selectAge)
  }
  return (
    <>
      <React.Fragment>
        <div className="col-12 p-4 h-100" id="login-page">
                <div className="row pt-2">
                    <div
                      className="btn_back rounded_white cursor-pointer justify-content-center align-items-center ml-3"
                      style={{ width: "35px", height: "35px" }}
                      onClick={() => window.history.back()}
                    >
                      <img src={FlavorImages.backward_arrow_grey} className="backward_button cursor-pointer" alt="..." />
                    </div>
                </div>
          <div className="container-fluid p-0">
            <div className="col-md-6 offset-md-3 col-lg-4 offset-lg-4 pt-4 align-items-center">
              <img
                src={FlavorImages.app_logo}
                className="img-fluid mx-auto d-none d-md-block photo"
                alt="..."
              />
              <div className="col-12 mt-5 pt-5">
                <div 
                className="col-12   d-flex align-items-center  px-0"
                style={{ left: "-32px" }}
                >
                  <label
                    htmlFor="Email"
                    className="text_34_700 col-form-label font_700_italic_gloria"
                  >
                    {"3. " + ReactHtmlParser(t("signup.AccountDetails"))}
                  </label>
                </div>

                <div className="col-12 font_400_italic_gloria  p-0">
                  <label
                    htmlFor="Email"
                    className="text_16_400 font_400_italic_gloria text-navy col-form-label"
                  >
                    {ReactHtmlParser(t("signup.AccountDetailsSubTitle"))}
                  </label>
                </div>

                <div className={`col-12 mt-4 mb-3 ${process.env.REACT_APP_FLAVOR === "Bike" ? "d-flex" : "d-none"} justify-content-center`}>
                  <div
                    style={{ backgroundImage: `url(${fileSrc})` }}
                    className="register_avatar_placeholder_div"
                  >
                    {fileSrc ? (
                      <></>
                    ) : (
                      <img
                      onClick={() =>
                        document.getElementById("upload_file").click()
                      }
                        className="placeholder_image"
                        alt="Profile"
                        src={FlavorImages.placeholder_image}
                      />
                    )}
                    <img
                      onClick={() =>
                        document.getElementById("upload_file").click()
                      }
                      className="placeholder_circle"
                      alt="Profile"
                      src={FlavorImages.placeholder_circle}
                    />
                  {fileSrc && (
                    <img
                    onClick={clearProfilePhoto}
                    className="placeholder_delete"
                    alt="Profile"
                    src={FlavorImages.placeholder_delete}
                  />
                  )}
                  </div>
                  <input
                    id="upload_file"
                    type="file"
                    style={{ display: "none" }}
                    onChange={onProfilePhotoSelect}

                  />

                </div>

                <div className={`col-12 ${process.env.REACT_APP_FLAVOR === "Tracking" && "margin_top_8_rem"} inputBlock p-0 `} >
                  <TextField
                    value={fields["UserName"]}
                    className="col-12 Mui-focused label-input-bold"
                    id="UserName"
                    label={ReactHtmlParser(t("signup.UserName"))}
                    variant="standard"
                    placeholder={t("signup.Name")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {is_valid_vin === 0 ? (
                            <img
                              src={FlavorImages.cross_red}
                              className="input-icon cursor-pointer"
                              onClick={() => {
                                emptyUserName();
                              }}
                            ></img>
                          ) : is_valid_vin === 1 ? (
                            <img
                              src={FlavorImages.check_light_navy}
                              className="input-icon"
                            ></img>
                          ) : (
                            ""
                          )}
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleChange("UserName", e);
                    }}
                    defaultValue={fields["UserName"]}
                  />

                  <span></span>
                  {typeof errors["UserName"] !== "undefined" &&
                    errors["UserName"] !== "" ? (
                    <span className="invalid-feedback font_400_gloria">
                      {errors["UserName"]}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-12 mt-3 p-0">

                  <div className={`row ${process.env.REACT_APP_FLAVOR === "Bike" ? "d-flex flex-row" : "d-none"} text_17_700 px-3 `}>
                    <div className="col-3 col-md-3 mt-2 mr-3 m-0 p-0">
                      <CustomDropdown1
                        caseSensitivity={"uppercase"}
                        // onChange={setGender}
                        setValue={(e) => {
                          handleChange("gender", e);
                        }}
                        value={fields?.gender ? t(`Gender.${fields?.gender}`) : ""}
                        label={t("signup.Gender")}
                        dropdownLabel={t("signup.Gender")}
                        values={[
                          { value: "female", label: t("Gender.female") },
                          { value: "male", label: t("Gender.male") },
                          { value: "other", label: t("Gender.other") },
                        ]}
                      />
                    </div>
                    <div className="col-3 col-md-2 m-0 p-0 mt-2 mr-3">
                      <div className="col-12">
                        <label className="custom-label font_700_italic_gloria">{t("signup.Age")}</label>
                      </div>
                      <div >

                        <LocalizationProvider dateAdapter={AdapterDateFns}
                        locale={getLanguage()}>
                          <MobileDatePicker
                            label={t("signup.Age")}
                            okText={t("signup.Confirm")}
                            cancelText={t("button.Cancel")}
                            open={isForcePickerOpen}
                            onClose={() => setIsOpen(false)}
                            onAccept={() =>
                              setageOnaccept()
                            }
                            views={['year', 'month', 'day']}
                            disabled={false}
                            value={selectedDate}
                            onChange={getAge}
                            renderInput={({
                              ref,
                              inputProps,
                              disabled,
                              onAccept,
                              onChange,
                              value,
                              ...other
                            }) => (
                              <div ref={ref} {...other}>
                                <div
                                  className={`custom-ul-header  background_black_helkama border_30_px justify-content-center  py-2 px-3`}
                                  onClick={() => setIsOpen((isOpen) => !isOpen)}
                                >
                                  <span className="text_11_700 w_50_px ellipsis pr-1" >
                                    {age}{'\u00a0'}{age ? '' : ''}
                                  </span>
                                  <img
                                    className="custom-ul-header-arrow"
                                    alt="show"
                                    src={FlavorImages.custom_dropdown_arrow}
                                  />
                                </div>
                              </div>
                            )}
                          />
                        </LocalizationProvider>

                      </div>
                    </div>
                    <div className="col-3 col-md-3 p-0 m-0 mt-2">
                      <CustomInput1
                        label={t("signup.Weight")}
                        addonLabel={t("signup.kg")}
                        onChange={setWeight}
                        value={fields?.weight}
                        setValue={(e) => {
                          handleChange("weight", e);
                        }}
                        type={"number"}
                      />
                    </div>
                  </div>
                  <div className={`row`}>
                    {/*    <div className={`col-12 ${process.env.REACT_APP_FLAVOR === "Bike" ? "d-md-flex" : "d-none"}  d-none mt-3`}>
                      <label className="custom-label font_700_italic_gloria">{t("signup.Age")}</label>
                    </div>
                    <div className={`col-6 ${process.env.REACT_APP_FLAVOR === "Bike" ? "d-md-flex" : "d-none"} d-none px-3 p-0 m-0`}>
                      <div className="col  p-0 mr-3">
                        <CustomDropdown1
                          caseSensitivity={"uppercase"}
                          setValue={(e) => {
                            setDate("Day", e);
                          }}
                          value={day}
                          values={dates}
                          onChange={(e) => {
                            setDate("Day", e.target.value);
                          }}
                        />
                      </div>
                      <div className="col p-0 mr-3">
                        <CustomDropdown1
                          caseSensitivity={"uppercase"}
                          setValue={(e) => {
                            setDate("Month", e);
                          }}
                          value={
                            months.find((monthObj) => monthObj.value == month)?.label
                          }
                          values={months}
                          onChange={(e) => {
                            setDate("Month", e.target.value);
                          }}
                        />
                      </div>
                      <div className="col  p-0 mr-3">
                        <CustomDropdown1
                          caseSensitivity={"uppercase"}
                          setValue={(e) => {
                            setDate("year", e);
                          }}
                          value={year}
                          values={years}
                          onChange={(e) => {
                            setDate("year", e.target.value);
                          }}
                        />
                      </div>
                    </div>
*/}
                    <div className={`col-12 mt-4 text_12_400 text-grey ${process.env.REACT_APP_FLAVOR === "Bike" ? "d-md-flex" : "d-none"}`}>
                      <span>
                        {t("signup.Enter this info to get the best experience")}
                      </span>
                    </div>

                    <div className="col-12 mt-4">
                      <button
                        type="submit"
                        className="btn_login background_black_helkama text_gloria_17_700_italic"
                        id="loginSubmit"
                        onClick={(e) => clickNext(e)}
                      >
                        {btnText}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="p-0 mb-2 mt-4 row col-12 justify-content-center align-items-center mx-0">
                  <img
                    alt="Step"
                    className="dot"
                    src={ FlavorImages.dot_selected}
                    onClick={history.goBack}
                  />
                  <img
                    alt="Step"
                    className="dot ml-2"
                    src={ FlavorImages.dot_selected}
                    onClick={history.goBack}
                  />

                  <img
                    alt="Step"
                    className="dot ml-2"
                    src={ FlavorImages.dot_selected}
                    onClick={history.goBack}
                  />
                  {!registerState.pretoken &&
                    <img alt="Step" className="dot ml-2" src={FlavorImages.dot_unselected} />}
                </div>
              </div>
            </div>
            <LanguageChanger />
          </div>
        </div>
        <ErrorDialog
          showErrorDialog={errorDialog}
          select={"Device"}
          errorMesage={errorDialog}
          onClose={() => setShowErrorDialog(false)}
        />
      </React.Fragment>
    </>
  );
};

export default SignUpEmailAccountDetail;
