import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual } from "react-redux";
import BackWardArrow from "../../components/Buttons/BackwardArrow";
import ConfirmDelete from "./ConfirmDelete";
import { useDispatch } from "react-redux";
import * as genericService from "../../services/genericService";
import { devicesSlice } from "../../slice/deviceSlice";
import { authSlice } from "../../slice/authSlice";
import { geofencesSlice } from "../../slice/geofencesSlice";
import helper_functions from "../../helpers/helper_functions";
import { livestreamSlice } from "../../slice/liveStreamSlice";
import { mapSettingsSlice } from "../../slice/mapSettingsSlice";
import { useHistory } from "react-router-dom";
import { INDEX_URL } from "../../helpers/constants";
import { alarmsSlice } from "../../slice/alarmsSlice";
let flavor = process.env.REACT_APP_FLAVOR; //when we have APIs for helkama; remove static value and use flavor from env

const deviceActions = devicesSlice.actions;
const geofencesActions = geofencesSlice.actions;
const alarmActions = alarmsSlice.actions;
const streamAction = livestreamSlice.actions;
const mapSettingsActions = mapSettingsSlice.actions
const DeleteAccount = (props) => {
  const { t } = useTranslation();
  const authactions = authSlice.actions;
  const history = useHistory();
  const dispatch = useDispatch();
  const [confrimDelete, setConfrimDelete] = useState(false);
  const { devicesState } = useSelector(
    (state) => ({ devicesState: state.devices }),
    shallowEqual
  );
  const { authState } = useSelector(
    (state) => ({ authState: state.auth }),
    shallowEqual
  );
  const removeLocalStorage = async () => {
    dispatch(authactions.setAuthToken({ authToken: null }));
    dispatch(authactions.setAuthData({ mapDevices: [] }));
    dispatch(deviceActions.resetAllDeviceState());
    dispatch(geofencesActions.resetAllgeoFencestate());
    dispatch(alarmActions.resetAllAlarmstate());
    dispatch(streamAction.resetAllliveStreamstate());
    dispatch(mapSettingsActions.resetMapsettingsState());
    await helper_functions.removeLocalStorageValue("token");
    await helper_functions.removeLocalStorageValue("data");
    history.push(INDEX_URL);
  };
  const confirmDelete = async () => {
    let id = ""
    if(process.env.REACT_APP_FLAVOR === "dignita"){
      id = authState.authData.userid
    }else{
      id = authState.authData.response.userid
    }
    const response = await genericService.post("account/delete", {
      userid: id,
    });
    if (response.status === 200) {
      removeLocalStorage()
    } else {
      props.setError(response.data.message);
      setConfrimDelete(false);
    }
  };
  return (
    <>
      <div
        className={`${props.showUI === true ? "show_ui" : "hide_ui"
          } col-md-8 d-flex  mt-3 flex-column  inner`}
      >
        <BackWardArrow show={true}       //if passed false, it will be visible on below md devices, if passed true it will be visible irrespectively
          onClick={() => props.setSelect("EditAccount")}
        />
        <label className="text_22_700 mt-2">
          {t("Settings.DeleteAccount")}
        </label>
        <label className="ml-1 text-uppercase text_11_300 text-left mt-2">
          {t("DeleteBike.importantinformation")}
        </label>

        <div className="d-flex flex-column  rounded_white ">
          <div className="p-3  w-100  d-flex border-bottom justify-content-between align-items-center">
            <p className="text-12-400" style={{ textAlign: "start" }}>
            {process.env.REACT_APP_FLAVOR === "Bike"
            ?
            t("Settings.titlDeleteAccout")
            : process.env.REACT_APP_FLAVOR === "Alco" ?
            t("Settings.titlDeleteAccoutDeviceNoDataplan")
            :
            t("Settings.titlDeleteAccoutDevice")
            }
             {" "}
            </p>
            <div></div>
          </div>
        </div>

        {/* CODE FOR SHOWING WHAT DEVICES WILL BE DELETED, NO API SUPPORTS THIS RIGHT NOW */}
        {/* {devicesState.devices.length > 0 ? (
          <label className="ml-1 text-uppercase text_11_300 text-left mt-3">
            {process.env.REACT_APP_FLAVOR === "Bike"
            ?
            t("DeleteBike.Thefollwingbikeswillbedeleted")
            :
            t("DeleteBike.Thefollwingdeviceswillbedeleted")
            }
            
          </label>
        ) : (
          ""
        )}

        <div className="scroll_rem_14 alarm_list_shadow">
          {devicesState.devices.map((device, index) => {
            return (
              <div
                className="p-3 d-flex  border-bottom justify-content-start align-items-center"
                style={{ background: "#ffffff", width: "100%" }}
              >
                <div className="col">
                  <div className="row">
                    <label className=" text_16_400 text-navy my-0">
                      {device.name}{" "}
                    </label>
                  </div>
                  <div className="row">
                  {flavor === "Bike" ? (
                  <label
                      className=" my-0"
                      style={{ fontSize: "small", color: "gray" }}
                    >

                      {t("Settings.vin")}{":"}
                      {device.uniqueid}{" "}
                    </label>) 
                    : (
                    <label
                      className=" my-0"
                      style={{ fontSize: "small", color: "gray" }}
                    >
                      
                      {t("Device.IMEI")}{":"}
                      {device.uniqueid}{" "}
                    </label>) 
                    
                  }
                  </div>
                </div>
              </div>
            );
          })}
        </div> */}

        <label className="ml-1 text-uppercase text_11_300 text-left mt-2">
          {"Confirm"}
        </label>

        <div className="d-flex flex-column cursor-pointer rounded_white ">
          <div
            onClick={() => setConfrimDelete(true)}
            className="p-3 cursor-pointer  w-100  d-flex  border-bottom justify-content-center align-items-center"
          >
            <label
              style={{ color: "red" }}
              className="cursor-pointer text_16_400 text-navy my-0"
            >
              {t("Settings.DeleteAccount")}{" "}
            </label>
            <div></div>
          </div>
        </div>
      </div>
      <ConfirmDelete
        onConfirm={() => confirmDelete()}
        select={props.select}
        confirmBodyText={t("ConfirmDelete.confirmTiltfour")}
        confirmBtnText={t("Settings.DeleteAccount")}
        show={confrimDelete}
        closeConfirmDeleteDialog={() => setConfrimDelete(false)}
      />
    </>
  );
};

export default DeleteAccount;
