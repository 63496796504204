import React from "react";
// import check_empty from "../../assets/images/check_empty.svg";
// import checked from "../../assets/images/checked.svg";
import { withTranslation } from "react-i18next";
import FlavorImages from "../../assetsFolderImport";

class CheckBox extends React.Component {
  constructor(props) {
    super(props);
    this.checkClick = this.checkClick.bind(this);
  }
  checkClick() {
    if (this.props.isChecked) this.props.onClick(false);
    else this.props.onClick(true);
  }
  render() {
    const { isChecked } = this.props;

    return (
      <React.Fragment>
        {isChecked ? (
          <img
            className="cursor-pointer"
            src={FlavorImages.checked} alt="..." onClick={() => this.checkClick()} />
        ) : (
          <img
            className="cursor-pointer"
            src={FlavorImages.check_empty} alt="..." onClick={() => this.checkClick()} />
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation("common")(CheckBox);
