import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import close_gray from "../../assets/images/close_gray.svg";
import * as ProfileService from "../../services/user/ProfileService";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import { authSlice } from "../../slice/authSlice";
import { MAIL_FORMATE } from "../../helpers/constants";
import toast, { Toaster } from 'react-hot-toast';
import FlavorImages from "../../assetsFolderImport";



const EditEmail = (props) => {
  const { t } = useTranslation();
  const [email, setemail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [confirmEmailError, setConfirmEmailError] = useState("");
  const authAction = authSlice.actions;
  const { authState } = useSelector(
    (state) => ({ authState: state.auth }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const setEmailField = (value) => {
    setemail(value);
    setEmailError("");
  };
  const setConfirmEmailField = (value) => {
    setConfirmEmail(value);
    setConfirmEmailError("");
  };

  const updateEmail = async () => {
    if (email === "") {
      setEmailError("This field is required");
    }
    else if (confirmEmail === "") {
      setConfirmEmailError("This field is required");
    } else if (email !== confirmEmail) {
      setConfirmEmailError("Email do not match");
      setEmailError("confirmerror");
    }
    else if (!MAIL_FORMATE.test(confirmEmail) && !MAIL_FORMATE.test(email)) {
      setConfirmEmailError(t(" Email is not of valid format"))
      setEmailError(t(" Email is not of valid format"))
    }
    else if (!MAIL_FORMATE.test(email)) {
      setEmailError(t(" Email is not of valid format"))
    }
    else if (!MAIL_FORMATE.test(confirmEmail)) {
      setConfirmEmailError(t(" Email is not of valid format"))
    }
    else {
      let editEmail = {
        newemail: email,
        newemailconfirm: confirmEmail,
      };
      const response = await ProfileService.updateProfile(editEmail);
      if (response.status === 200) {
        // let info = {
        //   userid: authState.authData.userid,
        //   username: authState.authData.username,
        //   email: email,
        // };
        // dispatch(
        //   authAction.setAuthData({
        //     authData: info,
        //   })
        // );
        toast.success(t("toast.Saved"));
        props.closeEditEmailDialog();
        props.loadProfileSetting();
      } else {
        props.setError(response.data.message);
        props.closeEditEmailDialog();
      }
    }
  };

  return (
    <>
      <Dialog maxWidth={"xs"} fullWidth open={props.show}>
        <Card className="card">
          <div className="py-3 px-4 d-flex justify-content-between align-items-center card-header bg-white">
            <label className=" mb-0 text-grey text_17_700">
              {" "}
              {t("editProfile.changeaccountemail")}
            </label>
            <img
              className="size_1-5_rem cursor-pointer"
              src={FlavorImages.close_gray}
              onClick={() => props.closeEditEmailDialog()}
              alt="..."
            />
          </div>
          <div className="card-body py-3">
            <div className="row">
              <div className="col-12">
                <div className="form-group col-12 mt-1">
                  <label
                    className="col-form-label text_12_500 text-grey"
                    style={{ fontSize: "small" }}
                    data-success="right"
                  >
                    {t("editProfile.newemail")}
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setEmailField(e.target.value)}
                    style={{ background: "#DDDDDD" }}
                    className={`form-control ${emailError === "" ? "" : "is-invalid"
                      }`}
                  />
                  <div className="invalid-feedback bottom">
                    {emailError === "confirmerror" ? "" : emailError}
                  </div>
                </div>
                <div className="form-group col-12 mt-1">
                  <label
                    className="col-form-label text_12_500 text-grey"
                    style={{ fontSize: "small" }}
                    data-success="right"
                  >
                    {t("editProfile.confirmemail")}
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setConfirmEmailField(e.target.value)}
                    style={{ background: "#DDDDDD" }}
                    className={`form-control ${confirmEmailError === "" ? "" : "is-invalid"
                      }`}
                  />
                  <div className="invalid-feedback bottom">{confirmEmailError}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="m-0 card-footer  d-flex justify-content-end">
            <button
              className="btn bg-transparent btn-sm font-bold text-grey"
              onClick={() => props.closeEditEmailDialog()}
              variant="light"
            >
              {t("actionDialog.cancel")}
            </button>

            <button
              className="btn btn_navy bg_dark_helkama text-uppercase ml-3"
              onClick={() => updateEmail()}
              id="next"
            >
              {t("editProfile.saveEmail")}
            </button>
          </div>
        </Card>
      </Dialog>
    </>
  );
};

export default EditEmail;
