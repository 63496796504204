import ClickAwayListener from "@mui/material/ClickAwayListener";
import React from "react";
import FlavorImages from "../../assetsFolderImport";
const DropDown = (props) => {
  const [openCloseDropdown, setOpenCloseDropdown] = React.useState(false);

  return (
    <>
      {props.label ? (
        <label className={`custom-label font_700_italic_gloria`}>{props.label}</label>
      ) : (
        <></>
      )}
      <ClickAwayListener onClickAway={() => setOpenCloseDropdown(false)}>
        <div
          className={`custom-ul medal_dopdown ${
            props.caseSensitivity ? `text-${props.caseSensitivity}` : ""
          }`}
        >
          <div
            className={`custom-ul-header background_black_helkama ${
              openCloseDropdown ? "opened" : ""
            } rounded-circle justify-content-center  py-2 px-3`}
            onClick={() => setOpenCloseDropdown(!openCloseDropdown)}
          >
            <span className="text_11_700 font_700_gloria text-truncate">
              {props.value ? props.value : props.dropdownLabel}
            </span>{" "}
            <img
              className="custom-ul-header-arrow ml-1"
              alt="show"
              src={FlavorImages.custom_dropdown_arrow}
            />
          </div>
          <div className="w-100 position-absolute">
            {openCloseDropdown && (
              <ul className={`custom-ul-header-options background_black_helkama`}>
                {props.values.map((list, key) => {
                  return (
                    <li
                      className="text_11_700 font_700_gloria py-1 px-3"
                      key={key}
                      onClick={() => {
                        props.setValue(list.value, list.label);
                        setOpenCloseDropdown(false);
                      }}
                    >
                      {list.label}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      </ClickAwayListener>
    </>
  );
};

export default DropDown;
