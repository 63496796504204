import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import * as genericService from "../../services/genericService";
// import close_gray from "../../assets/images/close_gray.svg";
import FlavorImages from "../../assetsFolderImport";
const InactivateDeiveDialog = (props) => {
    const { t } = useTranslation();
    
    const deactivateSelectedDevicesConfirm = async () => {
          props.setSpinerActve("run");
          props.setSpinerError("");
          props.setSpinnerScreenText(t("DataPlan.textDeActivateDataPlan"));
          const data = { deviceid: props.toInActivate };
          const response = await genericService.post("dataplan/stop", data);
          if (response.status == 200 || response.status == 201) {
            props.setSpinerActve(false);
            props.onsuccess()
            props.setSpinerActve("success");
            props.onClose()
            props.setSpinnerScreenText(t("DataPlan.DataplaneDeactivated"));
          } else if (response.data.hasOwnProperty("errorCode")) {
            props.setSpinerActve("error");
            props.setSpinnerScreenText(t("DataPlan.ErrorCode") + response.data.message);
            props.setTextSpinnerOne(t("Settings.ContactSupport"));
            props.setSpinerError( t("DataPlan.ErrorCode") + response.data.message);
          }
      };
      useEffect(() => {
        props.setTextSpinnerOne("");
    }, []);
    return (
        <>
            <Dialog open={props.showInAcivateDialog}>
                <Card className={`card ${props.spinerActve && "effectactivateDialog" }` }style={{ width: "500px" }}>
                    <div
                        className="dialog_top_sort_row pb-2 pt-0 card-header"
                        style={{ backgroundColor: "white" }}
                    >
                        <label className="text_17_700 text-navy">{t("geofences.CONFIRM")}</label>
                        <img className="cursor-pointer" src={FlavorImages.close_gray}
                            onClick={() => props.onClose()} alt="..." />
                    </div>
                    <div className="card-body overflow-auto">
                        <label className="power_device-label text_12_500 text-grey">
                            {t("DataPlan.Didyouwanttodeactivatedevice")}:
                        </label>
                        <div className="row">
                            <div className="col-6">
                                <label className="power_device-label text_12_500 text-grey">
                                    {t("devicepower.Name")}
                                </label>
                            </div>
                            <div className="col-6">
                                <label className="power_device-label text_12_500 text-grey">
                                    {t("devicepower.IMEI")}
                                </label>
                            </div>
                        </div>
                        {props.devicesForInActivate.map((device, index) => {
                            return (
                                <div className="row font" key={index}>
                                    <div className="col-6">
                                        <label className="line_height_1  text_12_500  font-weight-bold">
                                            {device.name}
                                        </label>
                                    </div>
                                    <div className="col-6">
                                        <label className="line_height_1   text_12_500 font-weight-bold">
                                            {" "}
                                            {device.uniqueid}
                                        </label>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                    <div className="m-0 card-footer  d-flex justify-content-end">
                        <button onClick={() => props.onClose()} className="btn bg-transparent btn-sm font-bold text-grey text_12_700">
                            {t("actionDialog.cancel")}
                        </button>
                        <button
                              onClick={() => deactivateSelectedDevicesConfirm()}
                            className="btn btn_navy py-0 px-3 text-white ml-3"
                        >
                            {t("DataPlan.Deactivate")}
                        </button>
                    </div>
                </Card>
            </Dialog>
        </>
    );
};
export default InactivateDeiveDialog;
