import React from "react";
// import plus from "../../assets/images/back.svg";
import FlavorImages from "../../assetsFolderImport";
const BackButton = (props) => {
  const addClick = () => {
    props.onClick();
  };
  return (
    <React.Fragment>
      <div
        className="add_button ml-2 ml-md-0 cursor-pointer"
        style={props.active === true ? { backgroundColor: process.env.REACT_APP_FLAVOR === 'Bike' ? 'var(--dark_helkama)' : "var(--light_navy)" } : {}}
        onClick={() => addClick()}
      >
        {props.active === true ? (
          <img src={FlavorImages.plus} className="add_button_icon white_icon" alt="..." />
        ) : (
          <img src={FlavorImages.plus} className="add_button_icon " alt="..." />
        )}
        <label className="cursor-pointer" style={props.active === true ? { color: "#FFFFFF" } : {}}>
          {props.title}
        </label>
      </div>
    </React.Fragment>
  );
};

export default BackButton;
