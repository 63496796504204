import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import LanguageChanger from "../../components/LanguageChanger/LanguageChanger";
import LanguageChangerMobile from "../../components/LanguageChanger/LanguageChangerMobile";
import { useTranslation } from "react-i18next";
import helper_functions from "../../helpers/helper_functions";
import { authSlice } from "../../slice/authSlice";
import { alarmsSlice } from "../../slice/alarmsSlice";
import FlavorLottie from './../../lottiesFilesImport';

// import ham_burger from "../../assets/images/ham_burger.svg";
// import borgir_helkama from "../../assets/Helkama_images/borgir.svg";
// borgir
import { INDEX_URL } from "../../helpers/constants";
// import Side_left_Arrow_white from "../../assets/images/Side_left_Arrow_white.svg";
// import Side_right_Arrow_white from "../../assets/images/Side_right_Arrow_white.svg";
import { useState } from "react";
import * as genericService from "../../services/genericService";
import { useSelector } from "react-redux";
import { shallowEqual } from "react-redux";
import { settingsSlice } from "../../slice/settingsSlice";
import SideBarClassic from "./SideBarClassic";
import { devicesSlice } from "../../slice/deviceSlice";
import * as devicesService2 from "../../services/devices/devicesService2";
import { useHistory } from "react-router-dom";
import lottie from 'lottie-web';
// import yourAnimationData from "../../assets/lotties/bell.json";
import FlavorImages from "../../assetsFolderImport";

let width = window.innerWidth;  // use only when no imapact while changing screen from web to mobile
const settingsActions = settingsSlice.actions;
const deviceActions = devicesSlice.actions;
const SideBarNew = (props) => {
  const CLASSICHAMBURGER = false;
  const history = useHistory();
  const { t } = useTranslation();
  const [hoverpageTags, sethoverpageTags] = useState("")
  const dispatch = useDispatch();
  const authAction = authSlice.actions;
  const alarmActions = alarmsSlice.actions;
  const [hideMainMenu, setshowMainMenu] = useState(false);
  const [showLanguageMoble, setShowLanguageMoble] = useState(false)
  const [menuBtnClick, setMenuBtnClick] = useState(false)
  const [currentPage, setCurrentPage] = useState('')
  const { authState } = useSelector(
    (state) => ({ authState: state.auth }),
    shallowEqual
  )
  var swetrackPageArray
  let width = window.screen.width;  // use only when no imapact while changing screen from web to mobile
  let locationRoutes =
    process.env.REACT_APP_FLAVOR === "Bike"  ? "routes" : "locationHistory";
  let pageName =
    process.env.REACT_APP_FLAVOR === "Bike"  ? "Routes" : "LocationHistory";
  if (process.env.REACT_APP_FLAVOR === "Bike" ) {
    swetrackPageArray = ["Devices", "Geofence", "Routes", "LiveStream", "Alarm", "Medal", "news", "DataPlan", "Settings"]
  }
  else if (process.env.REACT_APP_FLAVOR === "Tracking") {
    swetrackPageArray = ["Devices", "Alarm", "Geofence", "LocationHistory", "DataPlan", "Settings"]

  }
  else if (process.env.REACT_APP_FLAVOR === "Alco") {
    swetrackPageArray = ["Devices", "Alarm", "Settings"]

  }
  let excludePagesArr = [pageName, "LiveStream", "Alarm", "Medal1", "Medal3", 'Statistics', "news"];
  const { alarmState } = useSelector(
    (state) => ({ alarmState: state.alarms }),
    shallowEqual
  );

  const { deviceState } = useSelector(

    (state) => ({
      deviceState: state.devices
    })
  )
  const { settingsState } = useSelector(
    (state) => ({ settingsState: state.settings }),
    shallowEqual
  );

  const isElementLoaded = async selector => {
    while (document.querySelector(selector) === null) {
      await new Promise(resolve => requestAnimationFrame(resolve))
    }
    return document.querySelector(selector);
  };

  React.useEffect(() => {
    isElementLoaded('#bling').then((selector) => {
      const animation = lottie.loadAnimation({
        container: document.querySelector("#bling"),
        animationData: FlavorLottie.yourAnimationData,
        loop: false
      });
    });
  }, [])

  const setNextPage = () => {
    const currentIndex = swetrackPageArray.indexOf(currentPage);
    const nextIndex = (currentIndex + 1) % swetrackPageArray.length;
    if (nextIndex <= swetrackPageArray.length) {
      props.onClick(swetrackPageArray[nextIndex]);
    }
  }
  const setPreviousPage = () => {
    const currentIndex = swetrackPageArray.indexOf(currentPage);
    const nextIndex = (currentIndex - 1) % swetrackPageArray.length;
    if (nextIndex >= 0) {
      props.onClick(swetrackPageArray[nextIndex]);
    }
  }
  const copyDataFromLocalStorageToRedux = async () => {
    let activeLanguage = await helper_functions.getActiveLanguageValue(
      "language"
    );
    if (activeLanguage) {
      dispatch(
        authAction.setActiveLanguage({
          activeLanguage: activeLanguage,
        })
      );
    } else {
      dispatch(
        authAction.setActiveLanguage({
          activeLanguage: "en",
        })
      );
    }
  };
  const setPagePopUP = () => {
    if (props.page === "Devices") {
      props.setShowAddDialog();
    }
    else if (props.page === "Geofence") {
      props.OnAdd();
    }
    else if (props.page === "Settings") {
      if (settingsState.page === "Safety") {
        dispatch(settingsActions.setDialog({ dialog: true }));
      }
    }
    else if (props.page === "map") {
      props.onClose()
    }
    else {
      props.onClickMenuBtn()
    }
  };

  const getNewMessageAlarm = async () => {
    const response = await genericService.post("notification/newmessagealarm");
    if (response.status === 200) {
      const data = response.data;
      dispatch(
        authAction.setNotificationMessage({
          notificationMessage: data,
        })
      );
      const newalarms = data.newalarms ?? false;
      dispatch(
        alarmActions.setShowAlarmUnreadDotOnSidebar({
          showAlarmUnreadDotOnSidebar: newalarms,
        })
      );
    }
  };
  // console.log(alarmState.showAlarmUnreadDotOnSidebar);
  const scrollTocenter = () => {
    if (props.page === "DataPlan" || props.page === "Settings"
      || props.page === "news" || props.page === "Statistics" || props.page === "Medal1" || props.page === "Medal2" || props.page === "Medal3" || props.page === "Medal4" && width < 768) {
      var container = document.getElementById('container');
      container.scrollLeft = 280
    }
  }

  useEffect(() => {
    scrollTocenter()
    setCurrentPage(props.page)
    copyDataFromLocalStorageToRedux();
    getNewMessageAlarm();
  }, []);


  const loadDevices = async () => {
    const response = await devicesService2.getDevicesInfo();
    if (response.status === 200) {
      const { data } = response;
      if (data.hasOwnProperty("errorCode") && data.errorCode !== "") {
        dispatch(deviceActions.setStreamingDevice({ device: null }));
      } else {
        const streamingDevice = data.find((device) => device.streaming);
        if (streamingDevice) {
          dispatch(
            deviceActions.setStreamingDevice({ device: streamingDevice })
          );
          const response = await genericService.get("streaminglive/list");
          if (response.status === 200) {
            dispatch(
              deviceActions.setLiveStream({ liveStream: response.data[0] })
            );
          } else {
            dispatch(deviceActions.setLiveStream({ liveStream: null }));
            dispatch(deviceActions.setStreamingDevice({ device: null }));
          }
        } else {
          dispatch(deviceActions.setLiveStream({ liveStream: null }));
          dispatch(deviceActions.setStreamingDevice({ device: null }));
        }
      }
    }
  };

  useEffect(() => {
    if (props.page === "LiveStream") {
      loadDevices();
    }
  }, [])
  const getSideBarImages = (page) => {
    let addMenuImagesPage = ["Devices", "Geofence"]
    if (process.env.REACT_APP_FLAVOR === 'Bike') {
      if (page === 'map') return  FlavorImages.setting_icon
      else if (addMenuImagesPage.includes(page)) return FlavorImages.plus
      else return FlavorImages.edit_dots
    }
    else {
      if (addMenuImagesPage.includes(page))
      {
        return require(`./../../assets/${process.env.REACT_APP_ASSETS}/${page}_menu_icon.svg`).default
      }
    }
  }

  const iconClick = async (argument) => {
    if (argument == "language") {
      setShowLanguageMoble(!showLanguageMoble)
    }
    else {
      if (argument !== props.page) {
        props.onClick(argument);
      }
    }

  };
  const setMainMenu = () => {
    setshowMainMenu(!hideMainMenu)
    setMenuBtnClick(true)
  }
  return (
    <React.Fragment>
      <div className="toolBar d-md-none pt-1 d-sm-block pl-3">
        <div className="row">
          <div className="col-4">
            <img
              onClick={() => setMainMenu()}
              src={FlavorImages.ham_burger}
              className="side_bar_map cursor-pointer"
              alt="..."
            />
          </div>
          <div className="col-4 d-flex align-items-center">
            <img
              src={FlavorImages.app_logo}
              className="mx-auto logo_img pr-2 cursor-pointer"
              alt="..."
            />
          </div>
          <div className={`col-4 ${props.page === "Settings" && settingsState.page === "" || excludePagesArr.includes(props.page) ? "d-none" : ""}  `}>
            <img
              onClick={() => setPagePopUP()}
              // src={`${INDEX_URL}images/${process.env.REACT_APP_FLAVOR}_${props.page}_menu_icon.svg`}
              src={getSideBarImages(props.page)}
              className="mt-md-2 mt-1 side_bar_map float-right mr-3 cursor-pointer"
              alt="..."
            />
          </div>
        </div>
      </div>
      <div className={`d-md-none bg-White_mobile  previous_page_button justify-content-center  ${hideMainMenu && process.env.REACT_APP_FLAVOR === "Bike"  ? "slide_right_menu"
        : menuBtnClick && process.env.REACT_APP_FLAVOR === "Bike"  ?
          "slide_left_menu" : ""}
			 ${CLASSICHAMBURGER ? "d-none" : "d-flex"}
			 ${hideMainMenu && process.env.REACT_APP_FLAVOR !== "Bike" ? "slide_up_menu"
          : menuBtnClick && process.env.REACT_APP_FLAVOR !== "Bike" ?
            "slide_down_menu" : ""}`}
      >
        <img
          className={`size_15 cursor-pointer mt-1 d-md-none d-sm-inline`}
          src={FlavorImages.Side_left_Arrow}
          onClick={() => setPreviousPage()}
          alt="..."
        />
      </div>
      {/* for sweettrack :  <div className={`alignment sidebar_new col d-flex ${hideMainMenu ? "slide_up_menu"
			: menuBtnClick ?
			 "slide_down_menu" : ""}`}></div> */}
      <div className={`alignment sidebar_new col  ${hideMainMenu && process.env.REACT_APP_FLAVOR === "Bike"  ? "slide_right_menu"
        : menuBtnClick && process.env.REACT_APP_FLAVOR === "Bike"  ?
          "slide_left_menu" : ""}
			 ${CLASSICHAMBURGER ? "d-none d-md-block" : "d-flex"}
			 ${hideMainMenu && process.env.REACT_APP_FLAVOR !== "Bike" ? "slide_up_menu"
          : menuBtnClick && process.env.REACT_APP_FLAVOR !== "Bike" ?
            "slide_down_menu" : ""}
			 `}>
        <a href={process.env.REACT_APP_BASE_URL}>
          <img
            src={FlavorImages.app_logo}
            className="mx-auto side_bar_map d-none cursor-pointer d-md-inline"
            alt="..."
          />
        </a>
        {props.page === "map" ? (
          <div
            className="sid_bar_bg cursor-pointer d-md-block d-flex cornerBefore margin_top_55 back_ground_gradient_grey pl-4"
            onClick={() => iconClick("map")}
          >
            <img
              src={
                FlavorImages.active_arrow
              }
              className="side_bar_icon d-md-none cursor-pointer d-sm-block"
              alt="..."
            />
            <span className="d-md-none pr-2 d-sm-block text_sideBar_Mobile">{t("sideBar.Map")}</span>
          </div>

        ) : (
          <div
            className="sid_bar_bg cursor-pointer mt-md-2 mt-1 d-md-none d-flex d-md-none pl-4"
            onClick={() => iconClick("map")}
          >
            <img
              src={
                FlavorImages.up_arrow_active
              }
              className="side_bar_icon d-md-none cursor-pointer  d-sm-block"
              alt="..."
            />
          </div>

        )}


        {props.page === "Devices" ? (
          <div className="sid_bar_bg d-md-block d-flex cornerBefore margin_top_55 back_ground_gradient_grey">
            <img
              src={
                FlavorImages.device_active
              }
              className="side_bar_icon  cursor-pointer d-md-inline d-none"
              alt="..."
            />
            <img
              src={
                FlavorImages.device_inactive
              }
              className="side_bar_icon cursor-pointer d-md-none d-sm-inline"
              alt="..."
            />
            <span className="d-md-none pr-2 d-sm-block text_sideBar_Mobile">{t("sideBar.Devices")}</span>
          </div>
        ) : (
          <div
            className="sid_bar_bg cursor-pointer d-md-block d-flex  margin_top_55 bg-White_mobile bg_dark_helkama_sideabr bg-navy"
            onClick={() => iconClick("devices")}
          >
            <img
              src={
                FlavorImages.device_inactive
              }
              onMouseEnter={() =>
                sethoverpageTags("Devices")
              }
              onMouseLeave={() => sethoverpageTags("")}
              className="side_bar_icon cursor-pointer d-md-inline d-none"
              alt="..."
            />
            <img
              src={
                FlavorImages.device_active
              }
              className="side_bar_icon d-md-none cursor-pointer d-sm-inline"
              alt="..."
            />
          </div>
        )}

        {props.page === "Alarm" ? (
          <div className="sid_bar_bg mt-md-2 mt-1 d-md-block d-flex cornerBefore back_ground_gradient_grey">
            {alarmState.showAlarmUnreadDotOnSidebar && (
              <div className="alarm_sidebar_unread_dot"></div>
            )}
            <img
              src={
                FlavorImages.bell_active

              }
              className="side_bar_icon d-none cursor-pointer  d-md-inline"
              alt="..."
            />
            <img
              src={
                FlavorImages.bell_inactive
              }
              className="side_bar_icon d-md-none cursor-pointer d-sm-inline"
              alt="..."
            />
            <span className="d-md-none pr-2 d-sm-block text_sideBar_Mobile">{("Alarm")}</span>

          </div>
        ) : (
          <div
            className="sid_bar_bg mt-md-2 mt-1 cursor-pointer d-md-block d-flex bg-White_mobile bg-navy"
            onClick={() => iconClick("Alarm")}
          >
            {alarmState.showAlarmUnreadDotOnSidebar
              ? <div className="alarm_sidebar_unread_dot"></div>
              : ""}
            {process.env.REACT_APP_ASSETS != "helkama" ? (
              <>
                {width < 768 ? (
                  <>
                    <img
                      src={FlavorImages.bell_inactive
                      }
                      onMouseEnter={() => sethoverpageTags("Alarm")}
                      onMouseLeave={() => sethoverpageTags("")}
                      className="img-fluid mx-auto side_bar_icon d-none cursor-pointer d-md-inline"
                      alt="..." /><img
                      src={
                        FlavorImages.bell_active

                      }
                      className="side_bar_icon d-md-none cursor-pointer d-sm-inline"
                      alt="..." /></>
                ) : (
                  <div id="bling"
                    className="lottiecontainer"
                    style={{ height: 30, width: 60 }}
                    onMouseEnter={() => sethoverpageTags("Alarm")}
                    onMouseLeave={() => sethoverpageTags("")} />
                  // <MyLottieAnimation />
                )}

              </>
            ) : (
              <>
                <img
                  src={FlavorImages.bell_inactive
                  }
                  onMouseEnter={() => sethoverpageTags("Alarm")}
                  onMouseLeave={() => sethoverpageTags("")}
                  className="img-fluid mx-auto side_bar_icon d-none cursor-pointer d-md-inline"
                  alt="..." /><img
                  src={
                    FlavorImages.bell_active

                  }
                  className="side_bar_icon d-md-none cursor-pointer d-sm-inline"
                  alt="..." /></>)}
          </div>
        )}
        {process.env.REACT_APP_FLAVOR == "Bike" || process.env.REACT_APP_FLAVOR == "Tracking" ? (
          <>
            {props.page === "Geofence" ? (
              <div className="sid_bar_bg mt-md-2 mt-1 d-md-block d-flex  cornerBefore back_ground_gradient_grey">
                <img
                  src={
                    FlavorImages.geofence_active
                  }

                  className="side_bar_icon d-none cursor-pointer d-md-inline"
                  alt="..."
                />
                <img
                  src={
                    FlavorImages.geofence_inactive
                  }

                  className="side_bar_icon d-md-none cursor-pointer  d-sm-block"
                  alt="..."
                />
                <span className="d-md-none d-sm-block pr-2 text_sideBar_Mobile">{t("sideBar.Geofence")}</span>

              </div>
            ) : (
              <div
                className="sid_bar_bg cursor-pointer d-md-block d-flex  mt-md-2 mt-1 bg-White_mobile bg_dark_helkama_sideabr bg-navy"
                onClick={() => iconClick("geofence")}
              >
                <img
                  src={
                    FlavorImages.geofence_inactive
                  }
                  onMouseEnter={() =>
                    sethoverpageTags("Geofence")
                  }
                  onMouseLeave={() => sethoverpageTags("")}
                  className="side_bar_icon d-none  d-md-inline"
                  alt="..."
                />
                <img
                  src={
                    FlavorImages.geofence_active
                  }
                  className="side_bar_icon d-md-none cursor-pointer d-sm-block"
                  alt="..."
                />

              </div>
            )}
          </>
        ) : (
          null
        )}

        {process.env.REACT_APP_FLAVOR == "Bike" || process.env.REACT_APP_FLAVOR == "Tracking" ? (
          <>
            {props.page === pageName ? (
              <div className="sid_bar_bg mt-md-2 mt-1 d-md-block d-flex cornerBefore back_ground_gradient_grey">
                <img
                  src={
                    require(`./../../assets/${process.env.REACT_APP_ASSETS}/sidebar/${pageName}_active.svg`).default
                  }
                  className="side_bar_icon d-none cursor-pointer d-md-inline"
                  alt="..."
                />
                <img
                  src={
                    require(`./../../assets/${process.env.REACT_APP_ASSETS}/sidebar/${pageName}_inactive.svg`).default
                  
                  }
                  className="side_bar_icon d-md-none cursor-pointer d-sm-block"
                  alt="..."
                />
                <span
                  className={`d-md-none d-sm-block   text_sideBar_Mobile ${process.env.REACT_APP_FLAVOR !== "Bike"
                    ? "width_118_mobile"
                    : "pr-2"
                    } `}
                >
                  {t("sideBar.Locationhistory")}{" "}
                </span>
              </div>
            ) : (
              <div
                className="sid_bar_bg cursor-pointer d-md-block d-flex  mt-md-2 mt-1 bg-White_mobile bg_dark_helkama_sideabr bg-navy"
                onClick={() => iconClick(locationRoutes)}
              >
                <img
                  src={
                    require(`./../../assets/${process.env.REACT_APP_ASSETS}/sidebar/${pageName}_inactive.svg`).default
                  }
                  onMouseEnter={() => {
                    if (process.env.REACT_APP_FLAVOR === "Bike" ) {
                      sethoverpageTags("routes");
                    } else {
                      sethoverpageTags("locationHistory");
                    }
                  }}
                  onMouseLeave={() => sethoverpageTags("")}
                  className="img-fluid mx-auto side_bar_icon d-none cursor-pointer  d-md-inline"
                  alt="..."
                />
                <img
                  src={
                    require(`./../../assets/${process.env.REACT_APP_ASSETS}/sidebar/${pageName}_active.svg`).default
                  }
                  className="side_bar_icon d-md-none cursor-pointer  d-sm-inline"
                  alt="..."
                />
              </div>
            )}
          </>
        ) : (
          null
        )}

        {process.env.REACT_APP_FLAVOR == "" ? (
          <>
            {props.page === "LiveStream" ? (
              <div className="sid_bar_bg mt-md-2 mt-1 d-md-block d-flex cornerBefore back_ground_gradient_grey">
                <img
                  src={
                    FlavorImages.stream_active
                  }
                  className="side_bar_icon d-none cursor-pointer  d-md-inline"
                  alt="..."
                />
                <img
                  src={
                    FlavorImages.stream_inactive

                  }
                  className="side_bar_icon d-md-none cursor-pointer  d-sm-inline"
                  alt="..."
                />
                <span className="d-md-none d-sm-block text_sideBar_Mobile width_86"
                >{t("sideBar.LiveStream")}</span>
              </div>
            ) : (
              <div
                className="sid_bar_bg cursor-pointer mt-md-2 mt-1 d-md-block d-flex bg-White_mobile bg_dark_helkama_sideabr bg-navy"
                onClick={() => iconClick(deviceState.streamingDevice ? "LiveStream" : "addeditlivestream")}
              >
                <img
                  src={
                    FlavorImages.stream_inactive
                  }
                  onMouseEnter={() =>
                    sethoverpageTags("LiveStream")
                  }
                  onMouseLeave={() => sethoverpageTags("")}
                  className="img-fluid mx-auto cursor-pointer side_bar_icon d-none  d-md-inline"
                  alt="..."
                />
                <img
                  src={
                    FlavorImages.stream_active

                  }
                  className="side_bar_icon d-md-none  cursor-pointer d-sm-inline"
                  alt="..."
                />
              </div>
            )}
          </>
        ) : (
          null
        )}

        {process.env.REACT_APP_FLAVOR === ""  ? (
          <>
            {props.page === "Medal1" || props.page === "Medal2" || props.page === "Medal3" || props.page === "Medal4" ? (
              <div className="sid_bar_bg  mt-md-2 mt-1  d-md-block d-flex cornerBefore back_ground_gradient_grey">
                <img
                  src={
                    FlavorImages.medal_active
                  }
                  className="side_bar_icon d-none cursor-pointer  d-md-inline"
                  alt="..."
                />
                <img
                  src={
                    FlavorImages.medal_inactive
                  }
                  className=" side_bar_icon  d-md-none cursor-pointer  d-sm-inline"
                  alt="..."
                />
                <span className="d-md-none pr-2 d-sm-block text_sideBar_Mobile">{t(" Me")}</span>

              </div>
            ) : (
              <div
                className="sid_bar_bg cursor-pointer d-md-block d-flex mt-md-2 mt-1 bg-White_mobile bg_dark_helkama_sideabr bg-navy"
                onClick={() => iconClick("Medal")}
              >
                <img
                  src={
                    FlavorImages.medal_inactive
                  }
                  onMouseEnter={() =>
                    sethoverpageTags("me")
                  }
                  onMouseLeave={() => sethoverpageTags("")}
                  className="side_bar_icon d-none cursor-pointer  d-md-inline"
                  alt="..."
                />
                <img
                  src={
                    FlavorImages.medal_active
                  }
                  className="side_bar_icon  d-md-none cursor-pointer  d-sm-inline"
                  alt="..."
                />
              </div>
               )}
              </>
              ) : null}

            {process.env.REACT_APP_FLAVOR === "Bike" ? (
            <>
            {props.page === "Statistics" ? (
              <div className="sid_bar_bg mt-md-2 mt-1 d-md-block d-flex  cornerBefore back_ground_gradient_grey">
                <img
                  src={
                    FlavorImages.statistics_active
                  }
                  className="side_bar_icon d-none cursor-pointer  d-md-inline"
                  alt="..."
                />
                <img
                  src={
                    FlavorImages.statistics_inactive
                  }
                  className="side_bar_icon  d-md-none cursor-pointer  d-sm-inline"
                  alt="..."
                />
                <span className="d-md-none pr-2 d-sm-block text_sideBar_Mobile">{t(" Statistics")}</span>
              </div>
            ) : (
              <div
                className="sid_bar_bg cursor-pointer d-md-block d-flex mt-md-2 mt-1 bg-White_mobile bg_dark_helkama_sideabr bg-navy"
                onClick={() => iconClick("Statistics")}
              >
                <img
                  src={
                    FlavorImages.statistics_inactive
                  }
                  onMouseEnter={() =>
                    sethoverpageTags("statistics")
                  }
                  onMouseLeave={() => sethoverpageTags("")}
                  className="side_bar_icon d-none cursor-pointer  d-md-inline"
                  alt="..."
                />
                <img
                  src={
                    FlavorImages.statistics_active
                  }
                  className="side_bar_icon d-md-none cursor-pointer d-sm-inline"
                  alt="..."
                />
              </div>
            )}
            </>
            ) : null}
            {process.env.REACT_APP_FLAVOR === "Bike" ? (
            <>
            {props.page === "news" ? (
              <div className="sid_bar_bg pl-md-0 pl-2 mt-md-2 mt-1 d-md-block d-flex cornerBefore back_ground_gradient_grey">
                <img
                  src={
                    FlavorImages.news_active
                  }
                  className="side_bar_icon d-none cursor-pointer d-md-inline"
                  alt="..."
                />
                <img
                  src={
                    FlavorImages.news_inactive
                  }
                  className="img-fluid mx-auto side_bar_icon d-md-none cursor-pointer  d-sm-inline"
                  alt="..."
                />
                <span className="d-md-none d-sm-block pl-1 text_sideBar_Mobile pr-2">{("News")}</span>
              </div>
            ) : (
              <div
                className="sid_bar_bg mt-md-2 mt-1 cursor-pointer d-md-block d-flex bg-White_mobile bg_dark_helkama_sideabr bg-navy"
                onClick={() => iconClick("news")}
              >
                <img
                  src={
                    FlavorImages.news_inactive
                  }
                  onMouseEnter={() =>
                    sethoverpageTags("news")
                  }
                  onMouseLeave={() => sethoverpageTags("")}
                  className="img-fluid mx-auto side_bar_icon d-none cursor-pointer d-md-inline"
                  alt="..."
                />
                <img
                  src={
                    FlavorImages.news_active
                  }
                  className="side_bar_icon d-md-none cursor-pointer  d-sm-inline"
                  alt="..."
                />
              </div>
            )}
          </>
        ) : null}

        {process.env.REACT_APP_ASSETS == "helkama" || process.env.REACT_APP_ASSETS == "swetrack" ? (
          <>

            {props.page === "DataPlan" ? (
              <div className="sid_bar_bg d-md-block d-flex mt-md-2 mt-1 cornerBefore back_ground_gradient_grey">
                <img
                  src={
                    FlavorImages.card_active
                  }
                  className="side_bar_icon d-none cursor-pointer d-md-inline"
                  alt="..."
                />
                <img
                  src={
                    FlavorImages.card_inactive
                  }
                  className=" side_bar_icon d-md-none cursor-pointer d-sm-inline"
                  alt="..."
                />
                <span className="d-md-none d-sm-block text_sideBar_Mobile mr-2"
                  style={{ width: "auto" }}
                >{t("sideBar.DataPlan")}</span>

              </div>
            ) : (
              <div
                className="sid_bar_bg d-md-block cursor-pointer d-flex mt-md-2 mt-1 bg-White_mobile bg_dark_helkama_sideabr bg-navy"
                onClick={() => iconClick("DataPlan")}
              >
                <img
                  src={
                    FlavorImages.card_active

                  }
                  className="side_bar_icon d-md-none cursor-pointer  d-sm-inline"
                  alt="..."
                />
                <img
                  src={
                    FlavorImages.card_inactive
                  }
                  onMouseEnter={() =>
                    sethoverpageTags("DataPlan")
                  }
                  onMouseLeave={() => sethoverpageTags("")}
                  className="img-fluid mx-auto side_bar_icon d-none cursor-pointer d-md-inline"
                  alt="..."
                />
              </div>
            )}
          </>
        ) : (
          null
        )}

        {props.page === "Settings" ? (
          <div className="sid_bar_bg d-md-block  d-flex mt-md-2 mt-1 cornerBefore back_ground_gradient_grey">
            <img
              src={
                FlavorImages.settings_active
              }
              className="side_bar_icon d-none cursor-pointer  d-md-inline"
              alt="..."
            />
            <img
              src={
                FlavorImages.settings_inactive
              }
              className=" side_bar_icon d-md-none cursor-pointer  d-sm-inline"
              alt="..."
            />
            <span className="d-md-none pr-2 d-sm-block text_sideBar_Mobile">{t("sideBar.Settings")}</span>

          </div>
        ) : (
          <div
            className="sid_bar_bg cursor-pointer d-md-block   d-flex mt-md-2 mt-1 bg-White_mobile  bg_dark_helkama_sideabr bg-navy"
            onClick={() => iconClick("Settings")}
          >
            <img
              src={
                FlavorImages.settings_inactive
              }
              onMouseEnter={() =>
                sethoverpageTags("Settings")
              }
              onMouseLeave={() => sethoverpageTags("")}
              className=" side_bar_icon d-none cursor-pointer  d-md-inline"
              alt="..."
            />
            <img
              src={
                FlavorImages.settings_active
              }
              className="side_bar_icon d-md-none cursor-pointer  d-sm-inline"
              alt="..."
            />
          </div>
        )}
        <div
          onClick={() => iconClick("language")}
          className="sid_bar_bg_lng d-md-none  d-flex mt-md-2 mt-1 cornerBefore pr-5">
          <span className="d-md-none pr-2 text_lng_mobile d-sm-block text-theme">{authState.activeLanguage}</span>

        </div>
      </div>
      <div className={`d-md-none bg-White_mobile bg-navy bg_dark_helkama_sideabr next_page_button justify-content-center ${hideMainMenu && process.env.REACT_APP_FLAVOR === "Bike"  ? "slide_right_menu"
        : menuBtnClick && process.env.REACT_APP_FLAVOR === "Bike"  ?
          "slide_left_menu" : ""}
			 ${CLASSICHAMBURGER ? "d-none" : "d-flex"}
			 ${hideMainMenu && process.env.REACT_APP_FLAVOR !== "Bike" ? "slide_up_menu"
          : menuBtnClick && process.env.REACT_APP_FLAVOR !== "Bike" ?
            "slide_down_menu" : ""}`}
      >
        <img
          className={`size_15 cursor-pointer mt-1 d-md-none d-sm-inline`}
          src={FlavorImages.Side_right_Arrow}
          onClick={() => setNextPage()}
          alt="..."
        />
      </div>
      <SideBarClassic
        CLASSICHAMBURGER={CLASSICHAMBURGER}
        page={props.page}
        hideMainMenu={hideMainMenu}
        menuBtnClick={menuBtnClick}
        iconClick={(page) => iconClick(page)}
      />
      {process.env.REACT_APP_FLAVOR !== "" &&
        <div className={`Nametage`}>

          <div className={`deviceTag ${hoverpageTags === "Devices" ? "nametag_fade_in" : "nametag_fade_out"}`}>
            <label className="text_16_700 text-white">{t("sideBar.Devices")}</label>
          </div>

          <div className={`deviceTag margin_top_8 ${hoverpageTags === "Alarm" ? "nametag_fade_in" : "nametag_fade_out"} `}>
            <label className="text_16_700 text-white" >{t("sideBar.Alarms")}</label>
          </div>

          {process.env.REACT_APP_FLAVOR === "Bike"  || process.env.REACT_APP_FLAVOR === "Tracking" ?
            <div className={`deviceTag margin_top_8 ${hoverpageTags === "Geofence" ? "nametag_fade_in" : "nametag_fade_out"}`}>
              <label className="text_16_700 text-white" >{t("sideBar.Geofences")}</label>
            </div>
            : ""
          }

          {process.env.REACT_APP_FLAVOR === "Tracking" ?
            <div className={`deviceTag margin_top_8 ${hoverpageTags === "locationHistory" ? "nametag_fade_in" : "nametag_fade_out"} `}>
              <label className="text_16_700 text-white" >{t("sideBar.Locationhistory")}</label>
            </div>
            : ""
          }

          {process.env.REACT_APP_FLAVOR === "Bike"  ?
            <div className={`deviceTag margin_top_8 ${hoverpageTags === "routes" ? "nametag_fade_in" : "nametag_fade_out"} `}>
              <label className="text_16_700 text-white" >{t("sideBar.Routes")}</label>
            </div>
            : ""
          }

          {process.env.REACT_APP_FLAVOR === "" ?
            <div className={`deviceTag margin_top_8 ${hoverpageTags === "LiveStream" ? "nametag_fade_in" : "nametag_fade_out"}`}>
              <label className="text_16_700 text-white" >{t("sideBar.Streaming")}</label>
            </div>
            : ""
          }

          {process.env.REACT_APP_FLAVOR === ""  ?
            <div className={`deviceTag margin_top_8 ${hoverpageTags === "me" ? "nametag_fade_in" : "nametag_fade_out"}`}>
              <label className="text_16_700 text-white" >{t("sideBar.Me")}</label>
            </div>
            : ""
          }

          {process.env.REACT_APP_FLAVOR === "Bike"  ?
            <div className={`deviceTag margin_top_8 ${hoverpageTags === "statistics" ? "nametag_fade_in" : "nametag_fade_out"}`}>
              <label className="text_16_700 text-white" >{t("sideBar.Statistics")}</label>
            </div>
            : ""
          }

          {process.env.REACT_APP_FLAVOR === "Bike"  ?
            <div className={`deviceTag margin_top_8 ${hoverpageTags === "news" ? "nametag_fade_in" : "nametag_fade_out"}`}>
              <label className="text_16_700 text-white" >{t("sideBar.News")}</label>
            </div>
            : ""
          }

          {process.env.REACT_APP_ASSETS === "helkama"  || process.env.REACT_APP_ASSETS === "swetrack" ?
            <div className={`deviceTag margin_top_8 ${hoverpageTags === "DataPlan" ? "nametag_fade_in" : "nametag_fade_out"} `}>
              <label className="text_16_700 text-white" >{t("sideBar.Subscriptions")}</label>
            </div>
            : ""
          }

          <div className={`deviceTag margin_top_8 ${hoverpageTags === "Settings" ? "nametag_fade_in" : "nametag_fade_out"} `}>
            <label className="text_16_700 text-white" >{t("sideBar.Settings")}</label>
          </div>


        </div>
      }
      <LanguageChangerMobile
        showLang={showLanguageMoble}
        closeShowLanguageMoble={() => setShowLanguageMoble(false)}
        select={"sidebar"} />
      <div className="language_style">
        <LanguageChanger select={"sidebar"} />
      </div>
    </React.Fragment>
  );
};

export default SideBarNew;
