import React from "react";
// import switch_off from "../../assets/images/switch_off.svg";
// import switch_on from "../../assets/images/switch_on.svg";
// import switch_on_helkama from "../../assets/images/switch_on_helkama.svg";
import FlavorImages from "../../assetsFolderImport";

const SwitchUI = (props) => {
  const checkClick = () => {
    if (props.isChecked) props.onClick(false);
    else props.onClick(true);
  };
  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-center ">
        {props.isChecked ? (
          <img
            className="cursor-pointer"
            style={{ width: "50px", height: "30px" }}
            src={  FlavorImages.switch_on}
            onClick={() => checkClick()}
            alt="..."
          />
        ) : (
          <img
            className="cursor-pointer"
            style={{ width: "50px", height: "30px" }}
            src={FlavorImages.switch_off}
            onClick={() => checkClick()}
            alt="..."
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default SwitchUI;
