import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { INDEX_URL } from "../../helpers/constants";
import AddEditLiveStream from "../../pages_v2/LiveStream/AddEditLiveStream";
import AuthChecker from "../AuthChecker/AuthChecker";
import SignInPage2 from "../../pages_v2/login/SignInPage2";
import TermsOfUse from "../../pages_v2/Signup/TermsOfUse";
import ForgetPassword from "../../pages_v2/ForgetPassword/ForgetPassword";
import ResetPassword from "../../pages_v2/ResetPassword/ResetPassword";
import SignUpEmail from "../../pages_v2/Signup/SignUpEmail";
import SignUpPassword from "../../pages_v2/Signup/SignUpPassword";
import SignUpEmailAccountDetail from "../../pages_v2/Signup/SignUpEmailAccountDetail";
import SignUpVINNumber from "../../pages_v2/Signup/SignUpVINNumber";
import NewsDetail from "../../pages_v2/news/NewsDetail";
import SignUpEmailExist from "../../pages_v2/Signup/SignUpEmailExist";
import SignUpComplete from "../../pages_v2/Signup/SignUpComplete";
import Devices from "../../pages_v2/Device/Devices";
import UrlScreen from "../../pages_v2/LiveStream/UrlScreen";
import Geofence from "../../pages_v2/Geofence/Geofence";
import Alarm from "../../pages_v2/Alarm/Alarm";
import LocationHistory from "../../pages_v2/LocationHistory/LocationHistory";
import AddEditGeofence from "../../pages_v2/Geofence/AddEditGeofence";
import LiveStream from "../../pages_v2/LiveStream/LiveStream";
import DataPlan from "../../pages_v2/DataPlan/DataPlan";
import Settings from "../../pages_v2/Settings/Settings";
import Statistics from "../../pages_v2/statistics/Statistics";
import News from "../../pages_v2/news/News";
import SearchedLocationhistory from "../../pages_v2/LocationHistory/SearchLocationHistory";
import MobileMapView from "../../pages_v2/Maps/MobileMapView"
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Medal from '../../pages_v2/Medal/Medal';
import PreassginedSignup from '../../pages_v2/Signup/PreassignedSignup';


function Main() {
  const location = useLocation();
  const path = location.pathname;
  let keytogetforfilter = path.split(',')
  let routesLocations = process.env.REACT_APP_FLAVOR === "Bike"
    ? "routes"
    : "locationHistory"
  keytogetforfilter.shift()
  return (
    <>
      <AuthChecker />
      <TransitionGroup>
        <CSSTransition
          timeout={keytogetforfilter.length ? 99999999999999 : 100}
          classNames="route_transition"
          key={keytogetforfilter.length ? '' : location.key}
        >
          <Switch location={location}>
            <Route exact path={INDEX_URL} component={SignInPage2} />
            <Route
              exact
              path={INDEX_URL + "forget-password"}
              component={ForgetPassword}
            />
            <Route
              exact
              path={INDEX_URL + "forgotpass/:token"}
              component={ResetPassword}
            />
            <Route exact path={INDEX_URL + "signup"} component={SignUpEmail} />
            <Route
              exact
              path={INDEX_URL + "signup/password"}
              component={SignUpPassword}
            />
            <Route
              exact
              path={INDEX_URL + "createaccountbyinvitation/:token"}
              component={PreassginedSignup}
            />
            <Route
              exact
              path={INDEX_URL + "medal"}
              component={Medal}
            />

            <Route
              exact
              path={INDEX_URL + "signup/accountDetail"}
              component={SignUpEmailAccountDetail}
            />
            <Route
              exact
              path={INDEX_URL + "signup/vinnumber"}
              component={SignUpVINNumber}
            />

            <Route
              exact
              path={INDEX_URL + "signup/emailexist"}
              component={SignUpEmailExist}
            />
            <Route
              exact
              path={INDEX_URL + "signup/complete"}
              component={SignUpComplete}
            />

            <Route
              exact
              path={INDEX_URL + "terms-of-use"}
              component={TermsOfUse}
            />

            <Route exact path={INDEX_URL + "devices"} component={Devices} />
            <Route exact path={INDEX_URL + "geofence"} component={Geofence} />
            <Route exact
              path={INDEX_URL + "addeditgeofence/:id?"}
              component={AddEditGeofence}
            />
            <Route
              exact
              path={INDEX_URL + "viewlivestream/:token?"}
              component={UrlScreen}
            />
            <Route
              exact
              path={INDEX_URL + routesLocations}
              component={LocationHistory}
            />
            <Route
              exact
              path={INDEX_URL + "map"}
              component={MobileMapView}
            />
            <Route
              exact
              path={INDEX_URL + "LiveStream"}
              component={LiveStream}
            />
            <Route
              exact
              path={INDEX_URL + "addeditlivestream/:id?"}
              component={AddEditLiveStream}
            />
            <Route exact path={INDEX_URL + "Alarm/:id?"} component={Alarm} />
            <Route exact path={INDEX_URL + "DataPlan"} component={DataPlan} />
            <Route exact path={INDEX_URL + "Settings"} component={Settings} />
            <Route
              exact
              path={INDEX_URL + "Statistics"}
              component={Statistics}
            />
            <Route exact path={INDEX_URL + "news"} component={News} />
            <Route exact path={INDEX_URL + "news/:id"} component={NewsDetail} />
            <Route
              exact
              path={INDEX_URL + "search"}
              component={SearchedLocationhistory}
            />
          </Switch>
        </CSSTransition>
      </TransitionGroup>

      <Switch>
        <Route exact path={INDEX_URL + "devices/search/:id?"} component={Devices} />
        <Route exact path={INDEX_URL + "geofence/search/:id?"} component={Geofence} />
        <Route exact path={INDEX_URL + "addeditlivestream/search/:id?"} component={AddEditLiveStream} />
        <Route exact path={INDEX_URL + routesLocations + "/search/:id?"} component={LocationHistory} />
        {/* <Route exact path={INDEX_URL + "alarm/search/:id?"} component={Alarm} /> */}
      </Switch>
    </>
  );
}

export default Main;
