import React, { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
// import search from "../../assets/images/search.svg";
// import sort_button from "../../assets/images/sort_button.svg";
// import sort_button_helkama from "../../assets/Bike/sort_button.svg";
import FlavorImages from "../../assetsFolderImport";
const SearchControl = (props) => {
  const { t } = useTranslation();

  const [searchKey, setSearchKey] = useState("");
  const [cdBool, setCdBool] = useState(false)
  const addSearch = () => {
    if (props.searchClick === true && searchKey.length === 0) {
      props.setSearchClick();
    }
    if (searchKey.length > 0) props.onAddSearchKey(searchKey);
    setSearchKey("");
  };

  const handleChange = (field, e) => {
    if (!cdBool) {
      setCdBool(true)
      setTimeout(() => {
        setCdBool(false);
      }, 1000);
    }
    setSearchKey(e.target.value);
    // props.onChangeSearchKey(e.target.value)
    // setSearchKey(e.target.value);
  };
  useEffect(() => {
    // console.log(searchKey);
    props.onChangeSearchKey(searchKey)
  }, [cdBool]);

  const onKeyDown = (e) => {
    const { key } = e;
    if (key === "Enter" && props.page !== "DataPlane") {
      if (props.searchClick === true && searchKey.length === 0) {
        props.setSearchClick();
      }

      if (searchKey.length > 0) {
        // console.log('onAddSearchKey', searchKey)
        props.onAddSearchKey(searchKey);
      }

      setSearchKey("");
    }
  };
  return (
    <React.Fragment>
      <div
        className={` ${props.page === "DataPlane" ? "dataplane_search" : "search_bar"}  ${props.searchClick === true ? "" : "d-none d-md-flex"
          }`}
      >
        <img
          src={FlavorImages.search}
          className="search_bar_search_icon cursor-pointer"
          onClick={() => addSearch()}
          alt="..."
        />
        <input
          autoComplete="off"
          type="text"
          className={`form-control ${props.error && !props.isLiveStreamDeviceSearch ? "is-invalid" : ""}`}
          id="newName"
          value={searchKey}
          onKeyDown={onKeyDown}
          onChange={(e) => {
            handleChange("SearchKey", e);
          }}
          placeholder={ReactHtmlParser(t("labels.Search"))}
        />
        {props.hideSorting === false || props.hideSorting === undefined ? (
          <img
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
            src={FlavorImages.sort_button}
            className="search_bar_sort_icon cursor-pointer"
            onClick={() => props.onSortClick()}
            alt="..."
          />
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default SearchControl;
