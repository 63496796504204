import CircularProgress from '@mui/material/CircularProgress';
import lottie from "lottie-web/build/player/lottie_light";
import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
// import default_avatar from "../../assets/images/default_awatar.svg";
// import KM from "../../assets/Helkama_images/KM.svg";
// import medal from "../../assets/Helkama_images/me.svg";
// import tachometer_alt from "../../assets/images/tachometer-alt.svg";
import * as devicesService2 from "../../services/devices/devicesService2";
// import clock from "../../assets/images/clock.svg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import Side_left_Arrow_white from "../../assets/images/Side_left_Arrow_white.svg";
// import Side_right_Arrow_white from "../../assets/images/Side_right_Arrow_white.svg";
// import fire_alt from "../../assets/images/fire-alt.svg";
// import plus from "../../assets/images/plus.svg";
// import up_arrow_green from "../../assets/images/up_arrow_green.svg";
// import down_arrow_red from "../../assets/images/down_arrow_red.svg";
// import levelup from "../../assets/lotties/levelup.json";
import SideBarNew from "../../components/SideBar/SideBarNew";
import { INDEX_URL } from "../../helpers/constants";
import * as genericService from "../../services/genericService";
import AchievementView from './AchievementView';
import ChallengeInviteCard from "./ChallengeInviteCard";
import FriendRequestcard from "./FriendRequestcard";
import { devicesSlice } from "../../slice/deviceSlice";
import FriendsCard from "./FriendsCard";
import AddFriend from './AddFriends';
import GenericConfirmPopup from './GenericConfirmPopup';
import LeaderBoardCard from "./LeaderBoardCard";
import CreateEditChallengePopup from './CreateEditChallengePopup';
import RunningChallengeCard from "./RunningChallengeCard";
import AchievementPopup from './AchievementPopup';
import FlavorImages from "../../assetsFolderImport";
import FlavorLottie from '../../lottiesFilesImport';


const Medal = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [userinfo, setUserInfo] = useState("")
    const [trends, setTrends] = useState("")
    const [confirmButtontext, setconfirmButtontext] = useState('')
    const [leaderBoard, setLeaderBoard] = useState("")
    const [createEditChallengeshow, setCreateEditChallengeshow] = useState(false)
    const [challenges, setChallenges] = useState("")
    const [confirBodyText, setconfirBodyText] = useState('')
    let width = window.screen.width;
    const [achievements, setAchievements] = useState([]);
    const [selectedId, setselectedId] = useState('')
    const [showConfirmPop, setshowConfirmPop] = useState(false)
    const [firstButtoncolor, setfirstButtoncolor] = useState('')
    const [secondButtoncolor, setsecondButtoncolor] = useState('')
    const [achievementPopup, setAchievementsPopup] = useState(false)
    const [achievementType, setAchievementsType] = useState({})
    const [mobileUI, setMobileUI] = useState(1)
    const deviceActions = devicesSlice.actions;
    const [editChallengeValue, setEditchallengValue] = useState('')
    const dispatch = useDispatch();
    const [showAddFriend, setShowFriend] = useState(false)
    const [friends, setFriends] = useState("")
    const anime = useRef(null);
    const animationContainer = useRef(null);
    const animationMobileContainter = useRef(null);
    const [progress, setProgress] = React.useState(0);
    const progressref = React.useRef(progress);
    const setprogressRef = (x) => {
        progressref.current = x;
        setProgress(x);
    };
    const { devicesState } = useSelector(
        (state) => ({ devicesState: state.devices }),
        shallowEqual
    );
    const { authState } = useSelector(
        (state) => ({ authState: state.auth }),
        shallowEqual
    )
    React.useEffect(() => {
        let interval = setInterval(function () {
            anime.current?.destroy()
            checkandUdateProgress()
        }, 5000);
        return () => {
            clearInterval(interval)
        }
    }, []);
    const checkandUdateProgress = async () => {
        const getinfo = await genericService.get("account/info");
        if (getinfo.status === 200 || getinfo.status === 201) {
            let addprogress = getinfo.data.response.experience - progressref.current
            if (addprogress > 0) {
                setLevelUpanimation()
                setprogressRef((progressref.current + addprogress));
            }
        }
    }
    const getMobileMainimageContainer = () => {
        return (
            <div className="picContainer">
                <div
                    className="mt-4 gif_medal_main border_36"
                    ref={animationMobileContainter}></div>
                <img
                    className="size_75 mt-4 fit_cover_image  border_36"
                    src={userinfo?.userimage === " " || !userinfo?.userimage || userinfo?.userimage == 'www.swetrack.com/dhhddh3333333.png' ? FlavorImages.default_avatar : userinfo?.userimage}
                    alt={"...No image"}
                />
                <div className="position-absolute" style={{ top: "14px", left: "-9.7px", borderRadius: "8px" }}>
                    <CircularProgress
                        variant="determinate"
                        style={{ width: "95px", height: '95px' }}
                        sx={{
                            color: '#C4C4C4'
                        }}
                        value={100}
                    />
                    <CircularProgress
                        style={{ width: "95px", height: '95px', position: "absolute", marginTop: "0px", marginLeft: "-95px" }}
                        sx={{
                            borderRadius: 5,
                            color: '#4FAADB'
                        }}
                        variant="determinate" value={progressref.current} />
                </div>
            </div>
        )
    }
    const getMainimageContainer = () => {
        return (
            <div className="picContainer">
                <div
                    className="mt-4 gif_medal_main border_36"
                    ref={animationContainer}></div>
                <img
                    className="size_75 mt-4 fit_cover_image  border_36"
                    src={userinfo?.userimage === " " || !userinfo?.userimage || userinfo?.userimage == 'www.swetrack.com/dhhddh3333333.png' ? FlavorImages.default_avatar : userinfo?.userimage}
                    alt={"...No image"}
                />
                <div className="position-absolute" style={{ top: "14px", left: "-9.7px", borderRadius: "8px" }}>
                    <CircularProgress
                        variant="determinate"
                        style={{ width: "95px", height: '95px' }}
                        sx={{
                            color: '#C4C4C4'
                        }}
                        value={100}
                    />
                    <CircularProgress
                        style={{ width: "95px", height: '95px', position: "absolute", marginTop: "0px", marginLeft: "-95px" }}
                        sx={{
                            borderRadius: 5,
                            color: '#4FAADB'
                        }}
                        variant="determinate" value={progressref.current} />
                </div>
            </div>
        )
    }
    const setLevelUpanimation = () => {
        if (animationContainer.current) {
            anime.current = lottie.loadAnimation({
                container: animationContainer.current,
                renderer: "svg",
                loop: false,
                autoplay: true,
                animationData: FlavorLottie.levelup,
            });
        }
        if (animationMobileContainter.current && width < 768) {
            anime.current = lottie.loadAnimation({
                container: animationMobileContainter.current,
                renderer: "svg",
                loop: false,
                autoplay: true,
                animationData: FlavorLottie.levelup,
            });
        }
    }
    const loadDevices = async () => {
        const response = await devicesService2.getDevicesCompactInfo();
        if (response.status === 200) {
            const devices = response.data.devices ?? [];
            const pendingDevices = response.data.pendingSharedDevices ?? [];
            const newDevices = devices.concat(pendingDevices);
            dispatch(
                deviceActions.setUpDeviceData({
                    devices: newDevices,
                })
            );
        }
    };
    // let selectType
    const selectType = React.useRef('')
    const acceptCanelRequest = (type, name) => {
        selectType.current = type
        if (type == 'accept') {
            setshowConfirmPop(true)
            setconfirBodyText(`Accept ${name}'s friend request?`)
            setconfirmButtontext('Accept')
            setfirstButtoncolor('bg-transparent')
            setsecondButtoncolor('bg-danger')
        }
        else if (type == 'cancel') {
            setshowConfirmPop(true)
            setconfirBodyText(`Are you sure you want to decline 
            ${name}'s friend request?`)
            setconfirmButtontext('Decline')
            setfirstButtoncolor('bg-transparent')
            setsecondButtoncolor('bg-danger')
        }
        else if (type === "deletefriend") {
            setshowConfirmPop(true)
            setconfirBodyText(`Are you sure you want to delete ${name}?`)
            setconfirmButtontext('Delete')
            setfirstButtoncolor('d-none')
            setsecondButtoncolor('bg-danger')
        }
        else {
            setshowConfirmPop(true)
            setconfirBodyText(`Cancel your friend request?`)
            setconfirmButtontext('Delete')
            setfirstButtoncolor('d-none')
            setsecondButtoncolor('bg-danger')
        }

    }

    const confrimEditorDelete = async (value) => {
        let API
        let accept
        if (selectType.current === 'cancel') {
            accept = false
            API = 'acceptdeny'
        }
        else if (selectType.current === 'accept') {
            API = 'acceptdeny'
            accept = true
        }
        else if (selectType.current === 'deletefriend' || selectType.current == 'cancelRequt') {
            API = 'delete'
        }

        let friendRequestResponse = await genericService.post(`social/friends/${API} `,
            {
                friendsuserid: value,
                accept: accept && accept
            });
        if (friendRequestResponse.status === 200) {
            setshowConfirmPop(false)
            getAccountInfo()
        }
        else {
            setshowConfirmPop(false)
        }
    }

    const getChallenges = async () => {
        const response = await genericService.get("challenges/getall")
        if (response.status === 200 || response.status === 200) {
            setChallenges(response.data.response)
        }
        else {
            setChallenges(challenges)
        }
    }
    useEffect(() => {
        getAccountInfo()
        loadDevices()
    }
        , []);
    const sortLeaderBoard = async (value) => {
        let leaderBoardResponse = await genericService.post("social/leaderboard/list", {
            "leaderboard_type": "all",
            "category": value
        });
        if (leaderBoardResponse.status === 200) {
        }
        setLeaderBoard(leaderBoardResponse.data.response)
    }
    const getAccountInfo = async () => {
        const allPromise = await Promise.all([
            genericService.get("account/info"),
            genericService.get("statistic/trends"),
            genericService.get("social/friends/list"),
            genericService.post("social/leaderboard/list", {
                "leaderboard_type": "all",
                "category": "distance"
            }),
            genericService.get("challenges/getall"),
        ]);

        if (allPromise[0].status == 200) {
            setprogressRef(allPromise[0].data.response.experience)
            setUserInfo(allPromise[0].data.response)
            setAchievements(allPromise[0].data.response.achievements)
        }
        if (allPromise[1].status == 200) {
            setTrends(allPromise[1].data)
        }
        if (allPromise[2].status === 200) {
            setFriends(allPromise[2].data.response)
        }
        if (allPromise[3].status === 200) {
            setLeaderBoard(allPromise[3].data.response)
        }
        if (allPromise[4].status === 200) {
            setChallenges(allPromise[4].data.response)
        }

    }

    const getmeDetailMobileView = () => {
        return (
            <div className='row mt-3 px-3'>
                <div
                    className="card px-1 mt-2 mb-3 py-4 small_shadow border-0 pb-3 width_achievemnet_353" >
                    <label className="text_22_700  text-center font_700_italic_gloria shadow-none">{t("Medal.Achievements")}</label>
                    {achievements ?
                        <>
                            <div className="d-flex">
                                {achievements.map((achievement, index) => {
                                    return (
                                        index < 4 &&
                                        <>
                                            {achievement.visible ? (
                                                <AchievementView
                                                    achievement={achievement}
                                                    setAchievementsPopup={() => setAchievementsPopup(true)}
                                                    setAchievementsType={(leveltype, type, achievementdetail) => setAchievementsType({ leveltype, type, achievementdetail })}
                                                    index={index}
                                                    visibility={achievement.visible}
                                                    lastindex={achievements.length}
                                                />
                                            ) : (
                                                <AchievementView
                                                    achievement={achievement}
                                                    setAchievementsPopup={() => setAchievementsPopup(true)}
                                                    index={index}
                                                    visibility={achievement.visible}
                                                    lastindex={achievements.length}
                                                />
                                            )}
                                        </>
                                    );
                                })}
                            </div>
                            <div className="d-flex">
                                {achievements.map((achievement, index) => {
                                    return (
                                        index >= 4 && index <= 8 &&
                                        <>
                                            {achievement.visible ? (
                                                <AchievementView
                                                    achievement={achievement}
                                                    setAchievementsPopup={() => setAchievementsPopup(true)}
                                                    setAchievementsType={(leveltype, type, achievementdetail) => setAchievementsType({ leveltype, type, achievementdetail })}
                                                    index={index}
                                                    visibility={achievement.visible}
                                                    lastindex={achievements.length}
                                                />
                                            ) : (
                                                <AchievementView
                                                    achievement={achievement}
                                                    setAchievementsPopup={() => setAchievementsPopup(true)}
                                                    index={index}
                                                    visibility={achievement.visible}
                                                    lastindex={achievements.length}
                                                />
                                            )}
                                        </>
                                    );
                                })}
                            </div>
                        </>
                        : ""}
                </div>
            </div>
        )
    }

    const getFriendMobileView = () => {
        return (
            <div className="px-1 margin_top_26 mb-3">
                <div>
                    {friends.request?.length ?
                        <label className=" text_22_700 line_height_9 font_700_italic_gloria " >{t("Medal.FriendRequest")} </label> : ""}
                    {friends.request?.length ?
                        <div className=" scroll_div_16 pb-2 legend_even alarm_list_shadow mt-2">
                            {friends.request.map((request, index) => {
                                return (<FriendRequestcard
                                    request={request}
                                    setselectedId={(value) => setselectedId(value)}
                                    acceptCancelRequest={(value, name) => acceptCanelRequest(value, name)}
                                    requestType={"inviteRequest"}
                                />)
                            })}
                        </div> : ""
                    }
                </div>
                {friends.invited?.length ? <>
                    <label className=" text_22_700 line_height_9 font_700_italic_gloria " >{t(" Pending Request")} </label>
                    <div className="mt-2 scroll_div_16 pb-2 mt-2 legend_even alarm_list_shadow">
                        {friends.invited.map((request, index) => {
                            return (<FriendRequestcard
                                request={request}
                                setselectedId={(value) => setselectedId(value)}
                                acceptCancelRequest={(value, name) => acceptCanelRequest('cancelRequt', name)}
                                requestType={"sendRequest"}
                            />)
                        })}
                    </div>
                </>
                    : ""
                }

                <label className=" text_22_700 mt-3 ml-1 line_height_9 font_700_italic_gloria " >{t(" Friends")} </label>
                {friends ? <>
                    <div className="mt-2 scroll_div_16  alarm_list_shadow">
                        {friends.friends.map((friend, index) => {
                            return (<FriendsCard
                                index={index}
                                setselectedId={(value) => setselectedId(value)}
                                acceptCancelRequest={(value, name) => acceptCanelRequest(value, name)}
                                setAchievementsPopup={() => setAchievementsPopup(true)}
                                setAchievementsType={(leveltype, type, achievementdetail) => setAchievementsType({ leveltype, type, achievementdetail })}
                                friend={friend}
                            />)
                        })}
                    </div>
                </> :
                    <div className="mt-3 scroll_div_16 legend_even alarm_list_shadow">
                        <div className="stripRow">
                            <div className="card border-0 py-2 px-4 mt-n1 height_85 accordion-no-styles">
                                <div className="shadow-none d-flex">
                                    <label className="text_16_700 mt-2 ml-3"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }

    const getChallengesMobileView = () => {
        return (
            <>
                {challenges && <>
                    <label className=" text_22_700 ml-2 line_height_9 font_700_italic_gloria  margin_top_38" > {t("Medal.Newchallengerequest")}</label>

                    {challenges.map((challenge, index) => {
                        return (challenge.waitingforaccept === 1 ?
                            <ChallengeInviteCard
                                challenge={challenge}
                                devices={devicesState.devices}
                            />
                            : "")
                    })
                    }
                    <div className="d-flex mt-3 flex-column">
                        {challenges.map((challenge, index) => {
                            return (challenge.waitingforaccept == 0 ? <RunningChallengeCard
                                challenge={challenge}
                                adminCheck={authState.authData.response.userid == challenge.adminuserid}
                                setCreateEditChallengeshow={(value) => setCreateEditChallengeshow(value)}
                                refreshChallenges={() => getChallenges()}
                            /> : "")
                        })
                        }
                    </div>
                </>
                }
            </>
        )
    }


    const showMObilePopView = (view) => {
        if (view === 2) setShowFriend(true)
        else setCreateEditChallengeshow(true)
    }
    const getTrendsCard = (trends) => {
        return (
            <>
                <div className="card border-0 mt-1 trend_cardWidth_height px-2 py-4 small_shadow">
                    <div className="d-flex justify-content-around">
                        {/* {trends.response[0].distance24hours_value ? */}
                        <label className="text_17_700 font_700_gloria">{trends.response[0].distance24hours_value ? trends.response[0].distance24hours_value.toFixed(0) : 0} {" "}{trends?.measurement?.distance_measurement} <br /> <span className="text_16_400 font_400_gloria"> {t("Medal.thisday")} </span></label>
                        {/* : */}
                        {/* <label className=" text-center text_17_700 mt-n2 font_700_gloria">{"No data"}<br /> <span className="text_16_400 font_400_gloria">registered <br /> today</span></label> */}
                        {/* } */}
                        <div className="d-flex flex-column">
                            <img
                                className={` size_13_23 ${trends.response[0].distance24hours_status
                                    ? 'd-none' : 'mb-3 mt-1'}`}
                                src={FlavorImages.KM}
                                alt={"...No image"}
                            />
                            <img
                                className={`size_13_23 ${trends.response[0].distance24hours_status
                                    ? 'mb-3' : ''}`}
                                src={trends.response[0].distance24hours_status
                                    ? FlavorImages.up_arrow_green : FlavorImages.down_arrow_red}
                                alt={"...No image"}
                            />
                            <img
                                className={`size_13_23 ${trends.response[0].distance24hours_status
                                    ? '' : 'd-none'}`}
                                src={FlavorImages.KM}

                                alt={"...No image"}
                            />
                        </div>
                    </div>
                </div>
                <div className="card border-0 mt-2 trend_cardWidth_height px-2 py-4 small_shadow">
                    <div className="d-flex justify-content-around">
                        {/* {trends.response[0].kcal24hours_value ? */}
                        <label className="text_17_700 font_700_gloria">{trends.response[0].kcal24hours_value ? Math.round(trends.response[0].kcal24hours_value) : 0}  kcal <br /> <span className="text_16_400 font_400_gloria"> {t("Medal.thisday")} </span></label>
                        {/* :
                            <label className=" text-center text_17_700 mt-n2 font_700_gloria">{"No data"}<br /> <span className="text_16_400 font_400_gloria">registered <br /> today</span></label>
                        } */}
                        <div className="d-flex flex-column">
                            <img
                                className={` size_25 ${trends.response[0].kcal24hours_status
                                    ? 'd-none' : 'mb-2'}`}
                                src={FlavorImages.fire_alt}
                                alt={"...No image"}
                            />
                            <img
                                className={`size_13_23 ${trends.response[0].kcal24hours_status
                                    ? 'mb-2' : ''}`}
                                src={trends.response[0].kcal24hours_status
                                    ? FlavorImages.up_arrow_green : FlavorImages.down_arrow_red}
                                alt={"...No image"}
                            />
                            <img
                                className={`size_25 ${trends.response[0].kcal24hours_status
                                    ? '' : 'd-none'}`}
                                src={FlavorImages.fire_alt}

                                alt={"...No image"}
                            />
                        </div>
                    </div>
                </div>
                <div className="card border-0 mt-2 trend_cardWidth_height px-2 py-4 small_shadow">
                    <div className="d-flex justify-content-around">
                        <label className="text_17_700 font_700_gloria">{trends.response[0].averagespeedcompared_value ? trends.response[0].averagespeedcompared_value.toFixed(0) : 0} {trends?.measurement?.speed_measurement}<br /> <span className="text_16_400 font_400_gloria"> {t("Medal.thisday")} </span></label>
                        {/* : */}
                        {/* <label className=" text-center text_17_700 mt-n2 font_700_gloria">{"No data"}<br /> <span className="text_16_400 font_400_gloria">registered <br /> today</span></label> */}
                        {/* } */}
                        <div className="d-flex flex-column">
                            <img
                                className={` size_13_23 ${trends.response[0].averagespeedcompared_status
                                    ? 'd-none' : 'mb-3'}`}
                                src={FlavorImages.tachometer_alt}
                                alt={"...No image"}
                            />
                            <img
                                className={`size_13_23 ${trends.response[0].averagespeedcompared_status
                                    ? 'mb-3' : ''}`}
                                src={trends.response[0].averagespeedcompared_status
                                    ? FlavorImages.up_arrow_green : FlavorImages.down_arrow_red}
                                alt={"...No image"}
                            />
                            <img
                                className={` size_13_23 ${trends.response[0].averagespeedcompared_status
                                    ? '' : 'd-none'}`}
                                src={FlavorImages.tachometer_alt}
                                alt={"...No image"}
                            />
                        </div>
                    </div>
                </div>
                <div className="card border-0 mt-2 trend_cardWidth_height px-2 py-4 small_shadow">
                    <div className="d-flex justify-content-around">
                        {/* {trends.response[0].averageridetimecompared_value ? */}
                        <label className="text_17_700 font_700_gloria">{trends.response[0].averageridetimecompared_value ? trends.response[0].averageridetimecompared_value.toFixed(0) : 0} {trends?.measurement?.time_measurement} <br /> <span className="text_16_400 font_400_gloria"> {t("Medal.thisday")} </span> </label>
                        {/* :
                            <label className=" text-center text_17_700 mt-n2 font_700_gloria">{"No data"}<br /> <span className="text_16_400 font_400_gloria">registered <br /> today</span></label>
                        } */}
                        <div className="d-flex flex-column">
                            <img
                                className={` size_13_23 ${trends.response[0].averageridetimecompared_status
                                    ? 'd-none' : 'mb-3'}`}
                                src={FlavorImages.clock}
                                alt={"...No image"}
                            />
                            <img
                                className={`size_13_23 ${trends.response[0].averageridetimecompared_status
                                    ? 'mb-3' : ''}`}
                                src={trends.response[0].averageridetimecompared_status
                                    ? FlavorImages.up_arrow_green : FlavorImages.down_arrow_red}
                                alt={"...No image"}
                            />
                            <img
                                className={` size_13_23 ${trends.response[0].averageridetimecompared_status
                                    ? '' : 'd-none'}`}
                                src={FlavorImages.clock}
                                alt={"...No image"}
                            />
                        </div>
                    </div>
                </div></>
        )
    }
    const seteditchallenge = (value) => {
        setCreateEditChallengeshow(true)
        setEditchallengValue(value)
    }

    const getTrendsCardMobile = (trends) => {
        return (
            <>
                <div className='row mt-3 px-3 justify-content-between'>
                    <div className="card border-0 mt-1 trend_cardWidth_height px-2 py-4 small_shadow">
                        <div className="d-flex justify-content-around">
                            <label className="text_17_700 font_700_gloria">
                            {trends.response?.[0].distance24hours_value ? trends.response?.[0].distance24hours_value.toFixed(0) : 0} {" "}{trends?.measurement?.distance_measurement} <br /> <span className="text_16_400 font_400_gloria"> {t("Medal.thisday")} </span></label>
                            <div className="d-flex flex-column">
                                <img
                                    className="mb-3"
                                    src={FlavorImages.up_arrow_green}
                                    alt={"...No image"}
                                />
                                <img
                                    className=""
                                    src={FlavorImages.KM}
                                    alt={"...No image"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card border-0 mt-1 trend_cardWidth_height px-2 py-4 small_shadow">
                        <div className="d-flex justify-content-around">
                            <label className="text_17_700 font_700_gloria">
                            {trends.response?.[0].kcal24hours_value ? Math.round(trends.response?.[0].kcal24hours_value) : 0} kcal <br /> <span className="text_16_400 font_400_gloria"> {t("Medal.thisday")} </span></label>
                            <div className="d-flex flex-column">
                                <img
                                    className="mb-2"
                                    src={FlavorImages.up_arrow_green}
                                    alt={"...No image"}
                                />
                                <img
                                    className=" border_36 size_25"
                                    src={FlavorImages.fire_alt}
                                    alt={"...No image"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mt-2 justify-content-between px-3'>
                    <div className="card border-0 mt-2 trend_cardWidth_height px-2 py-4 small_shadow">
                        <div className="d-flex justify-content-around">
                            <label className="text_17_700 font_700_gloria">
                                {trends.response?.[0].averagespeedcompared_value ? trends.response?.[0].averagespeedcompared_value.toFixed(0) : 0} {trends?.measurement?.speed_measurement}
                                <br /> <span className="text_16_400 font_400_gloria"> {t("Medal.thisday")} </span></label>
                            <div className="d-flex flex-column">
                                <img
                                    className="size_13_23 mb-2  size_13_23"
                                    src={FlavorImages.up_arrow_green}
                                    alt={"...No image"}
                                />
                                <img
                                    className=" border_36"
                                    src={FlavorImages.tachometer_alt}
                                    alt={"...No image"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card border-0 mt-2 trend_cardWidth_height px-2 py-4 small_shadow">
                        <div className="d-flex justify-content-around">
                            <label className="text_17_700 font_700_gloria">{trends.response?.[0].averageridetimecompared_value ? trends.response?.[0].averageridetimecompared_value.toFixed(0) : 0} {(" ")} {trends?.measurement?.time_measurement} <br /> <span className="text_16_400 font_400_gloria"> {t("Medal.thisday")} </span></label>
                            <div className="d-flex flex-column">
                                <img
                                    className="size_13_23 mb-2"
                                    src={FlavorImages.up_arrow_green}
                                    alt={"...No image"}
                                />

                                <img
                                    className="size_13_23 mb-4 border_36"
                                    src={FlavorImages.clock}
                                    alt={"...No image"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    return (
        <>
            <React.Fragment>
                    <div className="middle-content d-flex justify-content-between">
                        <SideBarNew
                            page={`Medal${mobileUI}`}
                            onClickMenuBtn={() => showMObilePopView(mobileUI)}
                            onClick={(e) => {
                                history.push(INDEX_URL + e);
                            }}
                        />
                    </div>
                    <div className="col row d-md-flex d-none section-data ml-5 pr-5" >
                        <div className="col-2 px-0" >
                            <div className="">
                                <div className="home_title_bar disply_none_small padding_left_30">
                                    <img
                                        src={FlavorImages.medal_active}
                                        className="home_title_icon"
                                        alt="..."
                                    />
                                    <label className='text_gloria_13_700_italic'>{t("Medal.Me")}</label>
                                </div>
                                <div className="d-flex flex-column align-items-center" >
                                    {getMainimageContainer()}
                                    <label className="text_22_700 text_gloria_17_700_italic mt-4 text-truncate w-75 text-center" >{userinfo?.username}</label>
                                    <label className="text_16_400 font_400_gloria">{userinfo?.rank}</label>
                                    <div
                                        onClick={() => setShowFriend(true)}
                                        className="card cursor-pointer border-0 width_35_160 me_buttons small_shadow">
                                        <div className="d-flex ">
                                            <div className="imgsectionOne">
                                                <img
                                                    className="  border_36"
                                                    src={FlavorImages.plus}
                                                    alt={"...No image"}
                                                />
                                            </div>
                                            <label className="text_12_700 font_700_gloria mt-2 cursor-pointer" >{t("Medal.AddFriend")}</label>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => setCreateEditChallengeshow(true)}
                                        className="card border-0 cursor-pointer mt-3 width_35_160 me_buttons small_shadow">
                                        <div className="d-flex">
                                            <div className="imgsectiontwo">
                                                <img
                                                    className="  border_36"
                                                    src={FlavorImages.plus}
                                                    alt={"...No image"}
                                                />
                                            </div>
                                            <label className="text_12_700 font_700_gloria mt-2 cursor-pointer" >{t("Medal.CreateChallenge")} </label>
                                        </div>
                                    </div>
                                    <div className="witdth_70_percent" >
                                        <label className="text_22_700 font_700_italic_gloria  mt-4 line_height_9" >{t("Medal.Trends")}</label>
                                    </div>

                                    {trends.response && getTrendsCard(trends)}
                                </div>
                            </div>
                        </div>
                        <div className='col-10 px-0'>
                            <div className='row mx-2'>
                                <div className="col-4  px-1" >
                                    <label className=" text_22_700 ml-1  line_height_9 font_700_italic_gloria margin_top_38" >{t("Medal.Friends")} </label>
                                    {friends.friends ? 
                                    
                                    friends.friends.length > 0
                                        ?
                                        <>
                                        <div className="mt-2 scroll_div_16  alarm_list_shadow d-none d-sm-none d-md-block">
                                            {friends.friends.map((friend, index) => {
                                                return (<FriendsCard
                                                    index={index}
                                                    setselectedId={(value) => setselectedId(value)}
                                                    acceptCancelRequest={(value, name) => acceptCanelRequest(value, name)}
                                                    setAchievementsPopup={() => setAchievementsPopup(true)}
                                                    setAchievementsType={(leveltype, type, achievementdetail) => setAchievementsType({ leveltype, type, achievementdetail })}
                                                    friend={friend}
                                                />)
                                            })}
                                        </div>
                                    </> 
                                        :
                                        <div className="mt-2 alarm_list_shadow">
                                        <div className="">
                                            <div className="card border-0 py-4 px-4 text-center accordion-no-styles">
                                                <div className="shadow-none d-flex flex-column">
                                                    <label className="text_22_700 text-grey mt-2 ml-3">you have no friend yet</label>
                                                    <label className="text_16_700 text-grey mt-2 ml-3">press the<span className='text-bold text-black'> + </span>   to send a friend request</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    : null
   
                                    }
                                    <label className=" text_22_700 ml-2 font_700_italic_gloria shadow-none line_height_9 margin_top_38" >{t("Medal.Achievements")} </label>
                                    <div className="card px-1  mt-2 py-4 small_shadow pb-3 border-0">
                                        {achievements ?
                                            <>
                                                <div className="d-flex">
                                                    {achievements.map((achievement, index) => {
                                                        return (
                                                            index < 4 &&
                                                            <>
                                                                {achievement.visible ? (
                                                                    <AchievementView
                                                                        achievement={achievement}
                                                                        setAchievementsPopup={() => setAchievementsPopup(true)}
                                                                        setAchievementsType={(leveltype, type, achievementdetail) => setAchievementsType({ leveltype, type, achievementdetail })}
                                                                        index={index}
                                                                        visibility={achievement.visible}
                                                                        lastindex={achievements.length}
                                                                    />
                                                                ) : (
                                                                    <AchievementView
                                                                        achievement={achievement}
                                                                        setAchievementsPopup={() => setAchievementsPopup(true)}
                                                                        index={index}
                                                                        visibility={achievement.visible}
                                                                        lastindex={achievements.length}
                                                                    />
                                                                )}
                                                            </>
                                                        );
                                                    })}
                                                </div>
                                                <div className="d-flex">
                                                    {achievements.map((achievement, index) => {
                                                        return (
                                                            index >= 4 && index <= 8 &&
                                                            <>
                                                                {achievement.visible ? (
                                                                    <AchievementView
                                                                        achievement={achievement}
                                                                        setAchievementsPopup={() => setAchievementsPopup(true)}
                                                                        setAchievementsType={(leveltype, type, achievementdetail) => setAchievementsType({ leveltype, type, achievementdetail })}
                                                                        index={index}
                                                                        visibility={achievement.visible}
                                                                        lastindex={achievements.length}
                                                                    />
                                                                ) : (
                                                                    <AchievementView
                                                                        achievement={achievement}
                                                                        setAchievementsPopup={() => setAchievementsPopup(true)}
                                                                        index={index}
                                                                        visibility={achievement.visible}
                                                                        lastindex={achievements.length}
                                                                    />
                                                                )}
                                                            </>
                                                        );
                                                    })}
                                                </div>
                                                <div className="d-flex">
                                                    {achievements.map((achievement, index) => {
                                                        return (
                                                            index >= 8 && index <= 16 &&
                                                            <>
                                                                {achievement.visible ? (
                                                                    <AchievementView
                                                                        achievement={achievement}
                                                                        setAchievementsPopup={() => setAchievementsPopup(true)}
                                                                        setAchievementsType={(leveltype, type, achievementdetail) => setAchievementsType({ leveltype, type, achievementdetail })}
                                                                        index={index}
                                                                        visibility={achievement.visible}
                                                                        lastindex={achievements.length}
                                                                    />
                                                                ) : (
                                                                    <AchievementView
                                                                        achievement={achievement}
                                                                        setAchievementsPopup={() => setAchievementsPopup(true)}
                                                                        index={index}
                                                                        visibility={achievement.visible}
                                                                        lastindex={achievements.length}
                                                                    />
                                                                )}
                                                            </>
                                                        );
                                                    })}
                                                </div>
                                            </>
                                            : ""}
                                    </div>
                                </div>
                                <div className="col-4  px-1" >
                                    <label className=" text_22_700 ml-2 line_height_9 font_700_italic_gloria margin_top_38" > {t("Medal.Leaderboard")}</label>
                                    <LeaderBoardCard
                                        leaderBoard={leaderBoard}
                                        sortLeaderBoard={(value) => sortLeaderBoard(value)}
                                    />
                                </div>
                                <div className="col-4  px-1  pr-2 " >
                                    <div className="scroll_div_2 px-1 margin_top_26">
                                        <label className=" text_22_700 line_height_9 font_700_italic_gloria " >{t("Medal.FriendRequest")} </label>
                                        {friends.request?.length ?
                                            <div className=" scroll_div_16 legend_even alarm_list_shadow mt-2">
                                                {friends.request.map((request, index) => {
                                                    return (<FriendRequestcard
                                                        request={request}
                                                        setselectedId={(value) => setselectedId(value)}
                                                        acceptCancelRequest={(value, name) => acceptCanelRequest(value, name)}
                                                        requestType={"inviteRequest"}
                                                    />)
                                                })}
                                            </div> : <div className="my-3 alarm_list_shadow">
                                                <div className="">
                                                    <div className="card border-0 py-4 px-4 text-center accordion-no-styles">
                                                        <div className="shadow-none d-flex flex-column">
                                                            <label className="text_22_700 text-grey mt-2 ml-3">{t("Medal.NoNewFriends")}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {friends.invited?.length ?
                                            <>  <label className="mt-4 text_22_700 line_height_9 font_700_italic_gloria " >{t(" Pending Request")} </label>
                                                <div className="mt-2 scroll_div_16 mt-2 legend_even alarm_list_shadow d-none d-sm-none d-md-block">
                                                    {friends.invited.map((request, index) => {
                                                        return (<FriendRequestcard
                                                            request={request}
                                                            setselectedId={(value) => setselectedId(value)}

                                                            acceptCancelRequest={(value, name) => acceptCanelRequest('cancelRequt', name)}
                                                            requestType={"sendRequest"}
                                                        />)
                                                    })}
                                                </div></>
                                            : ""
                                        }

                                        {challenges && <>
                                            <label className=" text_22_700 ml-2 line_height_9 font_700_italic_gloria  margin_top_38" > {t("Medal.Newchallengerequest")}</label>
                                            {challenges.map((challenge, index) => {
                                                return (challenge.waitingforaccept === 1 ?
                                                    <ChallengeInviteCard
                                                        challenge={challenge}
                                                        refreshChallenges={() => getChallenges()}
                                                        devices={devicesState.devices}
                                                    />
                                                    : "")
                                            })
                                            }
                                            <div className="d-flex mt-3 flex-column">
                                                {challenges.map((challenge, index) => {
                                                    return (challenge.waitingforaccept == 0 ? <RunningChallengeCard
                                                        challenge={challenge}
                                                        refreshChallenges={() => getChallenges()}
                                                        adminCheck={authState.authData.response.userid == challenge.adminuserid}
                                                        setCreateEditChallengeshow={(value) => seteditchallenge(value)}
                                                    /> : "")
                                                })
                                                }
                                            </div>
                                        </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container d-md-none d-block overflow-y">
                        <div className="row margin_top_76_mobile ">
                            <div className={`col-12 pt-4 settings_screen-left  `}>
                                <div className="d-flex d-md-none flex-column align-items-center" >
                                    {getMobileMainimageContainer()}
                                    <label className="text_22_700 text_gloria_17_700_italic mt-4 line_height_9" >{userinfo?.username}</label>
                                    <label className="text_16_400 font_400_gloria"> {userinfo?.rank}</label>
                                    <div className='d-flex'>
                                        {mobileUI > 1 
                                        ? <img
                                            className={` pt-1 pr-3 mb-2`}
                                            onClick={() => setMobileUI(mobileUI - 1)}
                                            src={FlavorImages.Side_left_Arrow}
                                            alt="..."
                                        /> 
                                        : <img
                                        className={` pt-1 pr-3 mb-2 invisible`}
                                        onClick={() => setMobileUI(mobileUI - 1)}
                                        src={FlavorImages.Side_left_Arrow}
                                        alt="..."
                                        />}
                                        <label className="text_22_700 font_400_gloria">
                                            {mobileUI === 1 ? 'Your Trends' : mobileUI === 2 ? 'Friends' : mobileUI === 3 ? 'Leader Board' : mobileUI === 4 ? 'Challenges' : ""}

                                        </label>
                                        {mobileUI < 4 
                                            ? <img
                                                onClick={() => setMobileUI(mobileUI + 1)}
                                                className={` pt-1 pl-3 mb-2`}
                                                src={FlavorImages.Side_right_Arrow}
                                                alt="..."
                                            /> 
                                            : <img
                                                onClick={() => setMobileUI(mobileUI + 1)}
                                                className={` pt-1 pl-3 mb-2 invisible`}
                                                src={FlavorImages.Side_right_Arrow}
                                                alt="..."
                                            />}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {mobileUI === 1 ? getTrendsCardMobile(trends) : ""}
                        {mobileUI === 1 ? getmeDetailMobileView() : mobileUI === 2 ? getFriendMobileView()
                            : mobileUI === 3 ?
                                <>
                                    <label className=" text_22_700 ml-2 line_height_9 font_700_italic_gloria margin_top_38" > {t("Medal.Leaderboard")}</label>
                                    <LeaderBoardCard
                                        leaderBoard={leaderBoard}
                                        sortLeaderBoard={(value) => sortLeaderBoard(value)}
                                    />
                                </>
                                : mobileUI === 4 ? getChallengesMobileView() : ""}
                    </div>
                <AddFriend
                    show={showAddFriend}
                    getAccountInfo={() => getAccountInfo()}
                    closeAddFrinds={() => setShowFriend(false)}
                />
                <GenericConfirmPopup
                    mainHeadingText={'Confirm'}
                    show={showConfirmPop}
                    value={selectedId}
                    firstButtoncolor={firstButtoncolor}
                    secondButtoncolor={secondButtoncolor}
                    onConfirm={() => confrimEditorDelete(selectedId, selectType)}
                    bodyText={confirBodyText}
                    closeDialogBox={() => setshowConfirmPop(false)}
                    confirmButtonText={confirmButtontext}
                />
                <CreateEditChallengePopup
                    refreshChallenges={() => getChallenges()}
                    show={createEditChallengeshow}
                    editChallengeValue={editChallengeValue}
                    friends={friends}
                    closePopup={() => {
                        setCreateEditChallengeshow(false)
                        setEditchallengValue('')
                    }}
                />
                <AchievementPopup
                    show={achievementPopup}
                    achievementType={achievementType}
                    resetAchievements={() => setAchievementsType({})}
                    closeachievementPopup={() => setAchievementsPopup(false)}
                />
            </React.Fragment>
        </>
    );
};

export default Medal;
