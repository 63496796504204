import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
// import close_gray from "../../assets/images/close_gray.svg";
import { shallowEqual, useSelector } from "react-redux";
import _ from "lodash";
import * as devicesService2 from "../../services/devices/devicesService2";
// import switch_on from "../../assets/images/switch_on.svg";
// import switch_off from "../../assets/images/switch_off.svg";
// import check_empty from "../../assets/images/check_empty.svg";
// import checked from "../../assets/images/checked.svg";
// import cross_inactive from "../../assets/images/cross_inactive.svg";
import FlavorImages from "../../assetsFolderImport";

const EditGroup = (props) => {
	const [devices, setDevices] = useState([]);
	const [allSelected, setAllSelected] = useState(false);
	const [groupNameField, setGroupNameField] = useState("set group name here")
	const { groupState } = useSelector(
		(state) => ({ groupState: state.groups }),
		shallowEqual
	);
	const { devicesState } = useSelector(
		(state) => ({ devicesState: state.devices }),
		shallowEqual
	);
	const onDeviceClick = (index) => {
		var array = _.cloneDeep(devices);
		if (index !== -1) {
			var device = array[index];
			if (device.isSelected !== true) device.isSelected = true;
			else device.isSelected = false;
			array[index] = device;
			setDevices(array);
		}
	};
	const getDeviceIDs = () => {
		var deviceIds = [];
		devices.map((device, index) => {
			if (device.isSelected === true) {
				deviceIds.push(device.id);
			}
		});
		return deviceIds;
	};
	const EditGroup = async () => {
		if (props.edit_AddGroup === "addGroup") {
			if (getDeviceIDs().length > 0) {
				const data = {
					name: groupNameField,
					deviceid: getDeviceIDs(),
				};
				const response = await devicesService2.createGroup(data);
				if (response.status === 200 && response.data.successful) {
					props.onClose()
				}
			}
		}
		else {
			if (getDeviceIDs().length > 0) {
				const data = {
					groupid: groupState.selectedGroup.id,
					name: groupNameField,
					deviceid: getDeviceIDs(),
				};
				const response = await devicesService2.updateGroup(data);
				if (response.status === 200 && response.data.successful) {
					props.onClose()
				}
			}
		}

	};
	const setaddDevices = () => {
		var groupDevices = [];
		var deviceList = _.cloneDeep(devicesState.devices);
		deviceList.map((tempDevice, index) => {
			tempDevice.isSelected = false;
			groupDevices.push(tempDevice);
		});
		setDevices(groupDevices);
	}
	useEffect(() => {
		if (props.edit_AddGroup === "addGroup") {
			setaddDevices();
			setGroupNameField("")
		}
		else {
			setDevices(groupState.selectedGroupDevices);
			setGroupNameField(groupState.groupName)
		}
	}, [groupState.selectedGroupDevices, groupState.groupName])
	const { t } = useTranslation();
	return (
		<>
			<Dialog open={props.editGroup}>
				<Card className="card_edit_group">
					<div
						className="dialog_top_sort_row px-2 py-0"
						style={{ backgroundColor: "white" }}
					>
						<label className="text_16_700  text-dark"
						>
							{props.edit_AddGroup === "addGroup" ? t(" Add New Group") : t(" Edit group")
							}
						</label>
						<img src={FlavorImages.close_gray} onClick={() => props.onClose()} alt="..." />
					</div>
					<div className="row justify-content-center">
						<div className="col-11">
							<div className="form-group mt-1">
								<label
									className="col-form-label text_13_300  text-dark text-bold"
									style={{ fontSize: "small" }}
									data-success="right"
								>
									{t(" Group Name")}
								</label>
								<input
									type="text"
									onChange={(e) => setGroupNameField(e.target.value)}
									value={groupNameField}
									style={{ background: "#DDDDDD" }}
									className={`form-control
										
										`}
								/>
								<div className="invalid-feedback bottom">
								</div>
							</div>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-11">
							<label
								className="col-form-label text_13_700 text-dark"
								data-success="right"
							>
								{t("Geofences.ChooseDevice")}
							</label>
							<div className="card scroll_div_34" >
								<ul className="list-group list-group-flush mt-1">

									<li className="cursor-pointer list-group-item d-flex justify-content-between align-items-center">
										<label className="text_13_700  text-dark pl-1">{"Choose all devices"}</label>

										{allSelected ? <img
											className="m-1 pr-1 pb-1"
											src={FlavorImages.switch_on}
											onClick={() => setAllSelected(!allSelected)}
											style={{ width: "35px", height: "26px" }}
											alt="..."
										/> :
											<img
												className="m-1 pr-1 pb-1"
												src={FlavorImages.switch_off}
												onClick={() => setAllSelected(!allSelected)}
												style={{ width: "35px", height: "26px" }}
												alt="..."
											/>
										}
									</li>
									{devices.map((device, index) =>
										<li className="cursor-pointer list-group-item d-flex justify-content-between align-items-center">
											<label className="text_12_500  text-dark pl-1">{device.name}</label>
											{allSelected || device.isSelected ? <img
												className="mr-3 check_box_style"
												src={FlavorImages.checked}
												alt="checked"
												onClick={() => onDeviceClick(index)}
											/> : <img
												className="mr-3 check_box_style"
												src={FlavorImages.cross_inactive}
												alt="cross_inactive"
												onClick={() => onDeviceClick(index)}
											/>}

										</li>)}

								</ul>
							</div>
						</div>
					</div>
					<div className="d-flex py-2 px-4">
						<button
							className="btn_editGroup"
							onClick={() => EditGroup()}
						>
							{props.edit_AddGroup === "addGroup" ? t(" Create group") : t(" Edit group")
							}

						</button>
					</div>
				</Card>
			</Dialog>
		</>
	);
};
export default EditGroup;
