// import fire_blue from "../../assets/images/fire_blue.svg";
import { useTranslation } from "react-i18next";
import DropDown from './DropDown';
import { shallowEqual, useSelector } from "react-redux";
import * as genericService from '../../services/genericService';
import React, { useEffect, useState } from "react";
import { formatDistance } from "date-fns";
import ErrorDialog from '../LocationHistory/ErrorDialog';
import FlavorImages from './../../assetsFolderImport';

const ChallengeInviteCard = (props) => {
    const [errorDialog, setShowErrorDialog] = useState(false);
    const { t } = useTranslation();
    const [defaultValue, setdefaultValue] = useState("")
    const defaultOtionRef = React.useRef();
    const [bikeId, setBikeId] = useState([])
    const setValue = (value, label) => {
        setBikeId(value)
        setdefaultValue(label)
    }
    const { devicesState } = useSelector(
        (state) => ({ devicesState: state.devices }),
        shallowEqual
    );
    let option = []
    useEffect(() => {
        devicesState.devices.forEach(device => {
            option.push({
                label: device.name,
                value: device.id
            })
        });
        setdefaultValue(devicesState.devices[0]?.name)
        setBikeId(devicesState.devices[0]?.id)
        if (option.length) defaultOtionRef.current = option
    }
        , [devicesState.devices]);
    const accecptDenyChallenge = async (accept) => {
        let data = {
            challengeid: props.challenge?.id,
            deviceid: bikeId,
            accept: accept
        }
        const responseData = await genericService.post('challenges/acceptdenyinvitation', { ...data })
        if (responseData.status == 200 || responseData.status == 201) {
            props.refreshChallenges()
        }
        else {
            setShowErrorDialog(responseData.data.message)
        }
    }
    return (
        <div className="card border-0 pt-2 px-0  mt-3  accordion-no-styles">
            <div className="row mt-3 padding_x_29">
                <div className="col-11">
                    <div className="row">
                        <img
                            className="mr-2 size_35_40 border_36"
                            src={FlavorImages.fire_blue}
                            alt={"...No image"}
                        />
                        <div className="d-flex flex-column mt-2">
                            <label className="text_16_700 font_700_gloria line_height_5">{t("Medal.challengeinvitation")}</label>
                            <label className="text_12_400 font_400_gloria">Calorie burn challenge</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row padding_x_29">
                <div className="d-flex flex-column">
                    <label className="text_12_500">{props.challenge?.name}</label>
                    <label className="text_12_400 font_400_gloria">{t("Medal.Createdby")}: <span className="text_12_500 font_500_gloria">"{props.challenge?.adminusername}"</span></label>
                    <label className="text_12_400 font_400_gloria">{t("Medal.Goal")} : <span className="text_12_500 font_500_gloria">{props.challenge?.goal} kcal in {formatDistance(new Date(props.challenge.starttime),
                        new Date(props.challenge.stoptime),
                        { addSuffix: false })}</span></label>
                </div>
            </div>
            <div className="row py-2 padding_x_29 align-items-center">
                <label className="text_12_400 font_400_gloria mr-2 mb-0  pb-0">{t("Medal.Joiningwith")}:
                </label>
                {defaultOtionRef.current?.length ?
                    <DropDown
                        caseSensitivity={"uppercase"}
                        setValue={(value, label) => setValue(value, label)}
                        value={defaultValue}
                        values={defaultOtionRef.current}
                    /> : ""}
            </div>

            <div className="row  col-md-12 px-0 ml-0">
                <div
                    onClick={() => accecptDenyChallenge(false)}
                    className="col-6 share_button cursor-pointer" >
                    {t("Medal.Decline")}
                </div>
                <div
                    onClick={() => accecptDenyChallenge(true)}
                    className="col-6 accept_button cursor-pointer"
                >
                    {t("signup.Accept")}
                </div>
            </div>
            <ErrorDialog
                showErrorDialog={errorDialog}
                select={"editChallenge"}
                errorMesage={errorDialog}
                onClose={() => setShowErrorDialog(false)}
            />
        </div>

    )

}
export default ChallengeInviteCard;
