import { createSlice } from "@reduxjs/toolkit";
const initialState = {
	streamDevice: "",
	locationLiveStream: [],
	streamDeviceData: null,
	streamingMapCenter: []
};
export const livestreamSlice = createSlice({
	name: "groups",
	initialState: initialState,
	reducers: {
		setStreamDevice: (state, action) => {
			state.streamDevice = action.payload.streamDevice;
		},
		setLocationLiveStream: (state, action) => {
			state.locationLiveStream = action.payload.locationLiveStream
		},
		setStreamDeviceData: (state, action) => {
			state.streamDeviceData = action.payload.streamDeviceData
			state.streamDevice = action.payload.streamDeviceData.id
		},
		setMapCenter: (state, action) => {
			state.streamingMapCenter = action.payload.streamingMapCenter
		},
		resetAllliveStreamstate: (state, action) => {
			state.streamDevice = ""
			state.locationLiveStream = []
			state.streamDeviceData = null
			state.streamingMapCenter = []
		}
	},
});
