import axios from "axios";
import { API_URL } from "../helpers/constants.js";

export async function post(apiName, data = {}) {
  const url = `${API_URL}${apiName}`;

  return axios({
    url: url,
    method: "POST",
    data,
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}


export async function postCustom(apiName, data = {}, customHeaders = {}) {
  const url = `https://api.cloudappapi.com/${apiName}`;

  console.log(customHeaders);
  const headers = {
    'Content-Type': 'application/json',
    ...customHeaders,
  };

  return axios({
    url: url,
    method: "POST",
    data,
    crossDomain: true,
    headers,
    responseType: 'blob'
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export async function get(apiName) {
  const url = `${API_URL}${apiName}`;

  return axios({
    url: url,
    method: "GET",
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}
export async function getwithParam(apiName, params={}) {
  const url = `${API_URL}${apiName}`;
  return axios({
    url: url,
    method: "GET",
    crossDomain: true,
    params
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

