import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import _ from "lodash";
import { devicesSlice } from "../../slice/deviceSlice";
import { useTranslation } from "react-i18next";
import * as helper_functions from "../../helpers/helper_functions";
import SegmentedControl from "../../components/SegmentControl/SegmentedControl";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useHistory } from "react-router-dom";
import * as genericService from "../../services/genericService";
import CustomSwitch1 from "../../components/switches/CustomSwitch1";
import WaitingForAcceptRow from "./WaitingForAcceptRow";
import TextField from "@mui/material/TextField";
import ErrorDialog from "../LocationHistory/ErrorDialog";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { locationHistorySlice } from "../../slice/locationHistorySlice";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useDispatch } from "react-redux";
import { useSelector, shallowEqual } from "react-redux";
import { subDays } from 'date-fns'
import HelkamaSwitch from "../../components/SegmentControl/HelkamaSwitch";
import { INDEX_URL } from "../../helpers/constants";
import FlavorImages from "../../assetsFolderImport";


let flavor = process.env.REACT_APP_FLAVOR; //when we have APIs for helkama; remove static value and use flavor from env
let someType = null; //this will also come within device row once helkama api is integrated
const MoreDeviceRow = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [detailDeviceid, setdetailDeviceid] = useState(null)
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const [powerSaveValue, setPowerSaveValue] = useState("");
  const containerRef = React.useRef(null);
  const [PowerSwitchValue, setPowerSwitchValue] = useState("");
  const [powerMode, setPowerMode] = useState("");
  const [lightsMode, setlightsMode] = useState("")
  const [lockswitchMode, setlockswitchMode] = useState("")
  const [alarmswitchMode, setalarmswitchMode] = useState("")
  const [start, setStart] = useState(subDays(new Date(), 1));
  const [selected, setSelected] = useState(false);
  const [name, setName] = useState("");
  const [speed, setSpeed] = useState("");
  const [end, setEnd] = useState(new Date());
  const [errorDialog, setShowErrorDialog] = useState(false)
  const [iconColor, setIconColor] = useState("default");
  const locationHistoryActions = locationHistorySlice.actions;
  const [distance, setDistance] = useState();
  const deviceActions = devicesSlice.actions;

  const dataplanDate = props.device.dataplanexpiringdate ? helper_functions.default.formatDateTime(props.device.dataplanexpiringdate, "yyyy-MM-dd HH:mm"): "";
  const todaysDate = helper_functions.default.formatDateTime(new Date(), "yyyy-MM-dd HH:mm");
  const showPosition = (position) => {
    let unit = "K"
    let lat1 = position.coords.latitude;
    let lon1 = position.coords.longitude;
    let lat2 = props.device.latitude
    let lon2 = props.device.longitude
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    }
    else {
      var radlat1 = Math.PI * lat1 / 180;
      var radlat2 = Math.PI * lat2 / 180;
      var theta = lon1 - lon2;
      var radtheta = Math.PI * theta / 180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == "K") { dist = dist * 1.609344 }
      if (unit == "N") { dist = dist * 0.8684 }
      let finalValue = Math.ceil(dist)
      setDistance(finalValue);
    }

  };

  const location = useLocation();
  let path = location.pathname

  // console.log(path);

  const getLocationHistory = async (selectedDevice, deviceisActive) => {

    const data = {
      deviceid: selectedDevice,
      starttime: start,
      stoptime: end,
      isActive: deviceisActive
    };
    var array = _.cloneDeep(devicesState.filteredDevices);
    array.forEach((device) => (device.isSelected = false));
    array.map((device, index) => {
      if (device.id == selectedDevice) {
        device.isSelected = true
        array[index] = device;
      }
    })
    dispatch(locationHistoryActions.setSelectedDevice({ selectedDevice: selectedDevice }));
    dispatch(
      deviceActions.setUpFilteredDeviceData({
        devices: array,
      })
    );
    dispatch(
      deviceActions.setLocationHistroyDevices({
        locatoinHistoryDevice: array,
      })
    );
    dispatch(
      locationHistoryActions.locationHistoryPage({
        locationPage: "showhistory",
      })
    )

    dispatch(
      locationHistoryActions.setEnd({
        end: end,
      })
    );
    dispatch(
      locationHistoryActions.setStart({
        start: start,
      })
    );
    //clear the current one
    dispatch(locationHistoryActions.setActiveDevice({ history: null }));
    const response = await genericService.post("history/list", data);
    if (response.status === 200) {
      history.push(INDEX_URL + "locationHistory");
      const historydata = response.data[0];
      dispatch(locationHistoryActions.setisHistoryInfromation({ isHistoryInfromation: true }));
      dispatch(locationHistoryActions.setdeviceHistoryData({ deviceHistoryData: response.data }));
      if (historydata) {
        dispatch(locationHistoryActions.setActiveDevice({ history: historydata }));
      }
      dispatch(locationHistoryActions.setStartEndDateLocationHistory({ startEndDateLocationHistoryDate: data }));
    } else {
      setShowErrorDialog(t("errors.LocationNotFound"))
    }
  };
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    }
  };
  useEffect(() => {
    getLocation();
    // console.log(props.device.modelspecificinfo?.powersavingmode?.powersavingmodeinfo);
    let mode =
      props.device.modelspecificinfo?.powersavingmode?.powersavingmodeinfo;
   // if (mode) mode = mode.charAt(0).toUpperCase() + mode.slice(1);
   // else mode = "";
    setPowerSaveValue(mode);
  }, []);
  const { devicesState } = useSelector(
    (state) => ({ devicesState: state.devices }),
    shallowEqual
  );

  const focusDeviceOnMap = (device, event) => {
    if (device.latitude && device.longitude) {
      dispatch(
        deviceActions.setSlectedMapDeviceId({
          selectedMapDeviceId: device.id === devicesState.selectedMapDeviceId ? "" : device.id,
        }),
      )

      dispatch(
        deviceActions.setSlectedMapDevice({
          selectedMapDevice: device

        })
      )
    }
  };
  const Toggle = (id) => {
    setSelected(!selected);
    if (process.env.REACT_APP_FLAVOR === "Bike") {
      props.device.communicationmode.powerswitch?.info ? setPowerMode("on") : setPowerMode("off")
      props.device.communicationmode.lockswitch?.info ? setlockswitchMode("on") : setlockswitchMode("off")
      props.device.communicationmode.lights?.info ? setlightsMode("on") : setlightsMode("off")
      props.device.communicationmode.alarmswitch?.info ? setalarmswitchMode("on") : setalarmswitchMode("off")
    }
    setdetailDeviceid(id)
    setName(props.device.name);
    setSpeed(props.device.modelspecificinfo.speedlimit.speedlimitinfo);
    props.onClick();
  };

  const getDate = () => {
    if (props.device.status === "online") return t("Device.Now");
    else {
      let date = !props.device.lastupdate ? t("Device.NeverConnected") : helper_functions.default.formatDateMonthTime(
        props.device.lastupdate, "yyyy-MM-dd HH:mm"
      );
      return date;
    }
  };

  const renderDeviceRow = () => {
    return (
      <div className="col-12 p-0 text-break">
        <div className="device_row_small_select box_shadow_none" ref={containerRef}>
          <Accordion className="accordion-no-styles box_shadow_none color_light_grey">
            <AccordionSummary
              className=" p-0 m-0 "
              aria-controls="panel1a-content"
            >
              <div className="col-12 px-0">
                {props.device.sharedstatus.waitingforaccept ? (
                  <WaitingForAcceptRow
                    updateShareWithMe={() => props.getDevicesList()}
                    device={props.device}
                    index={props.index}
                    key={props.index}
                    className="more_height"
                  />
                ) : (
                  <div
                    className={`card border-0 more_height
           ${selected === false || props.isSelection ? "" : "bg-light-grey"}
           p-0`}
                  >
                    <div
                      className={`p-0 m-0 d-flex flex-column justify-content-center card-body w-100
            ${selected === false || props.isSelection ? "text-navy" : "text-white"}
              ${selected === false || props.isSelection
                          ? props.index % 2 === 0
                            ? "bg-white"
                            : "grey_shadow_f2"
                          : "bg-navy"
                        }
              ${props.isFirst
                          ? ""
                          : props.isLast
                            ? ""
                            : ""
                        }
              ${props.isLast ? "" : ""}
              `}
                      onClick={() => Toggle(props.device.id)}
                    >
                      <div className="row pr-2 min_height_30  d-flex align-items-center p-0 m-0">
                        <div className="col-5 p-0 ">
                          <div className="row d-flex justify-content-center align-items-center p-0 m-0">
                            {props.isSelection ? (
                              <div className="col-2 p-0 m-0 ">
                                {props.selected || props.device.isSelected ? (
                                  <img
                                    className="border_radius_25 check_box_style"
                                    src={FlavorImages.checked}
                                    alt="checked"
                                    onClick={() => props.setChooseAll(false)}
                                  />
                                ) : (
                                  <img
                                    className="border_radius_25 check_box_style"
                                    src={FlavorImages.check_empty}
                                    alt="unchecked"
                                  />
                                )}
                              </div>
                            ) : null}
                            <div
                              className={`${props.isSelection ? "col-8 px-1 " : "col-12 px-3 "
                                } p-0 m-0 mw-100 text_13_700_no_color break_text`}
                            >
                              {props.device.name}
                            </div>
                          </div>
                        </div>

                        <div className={` p-0  m-0 col-7`}>
                          <div className="row d-flex justify-content-around m-0 p-0">
                            <div
                              className={`col-5 p-0 margin_top_1_point_5 justify-content-center text_11_400 `}
                            >
                              <div
                                className={`row m-0 p-0 d-flex  ${selected === false || props.isSelection
                                  ? ""
                                  : "text-white"
                                  } `}
                              >
                                {props.device.hasOwnProperty("status") ? (
                                  <>
                                    <span
                                      className={`dot  mr-2 margin_top_2_point_1	 
                                        ${props.device.status === "online"
                                          ? "bg-online"
                                          : props.device.status === "offline"
                                            ? "bg-offline"
                                            : "bg-neverconnected"
                                        } `}
                                    ></span>
                                    <span>
                                      {getDate()}
                                    </span>
                                  </>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-3 p-0 m-0 text_12_700 break_text">
                              {props.device.groupname}
                            </div>
                            <div
                              onClick={(event) =>
                                focusDeviceOnMap(props.device, event)
                              }
                              className="col-2 p-0 m-0  text_gray_12_500 cursor-pointer">
                              {flavor === "Bike"
                                ? props.device.communicationmode.vehiclebattery
                                  .info
                                : props.device.modelspecificinfo.internalbattery.internalbatteryinfo
                              } {"%"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <img
                        src={FlavorImages.map_maker_alt_two}
                        onClick={(event) =>
                          focusDeviceOnMap(props.device, event)
                        }
                        className={`device_row_map_icon devicemapicon m-0 responsive cursor-pointer ${selected === false || props.isSelection
                          ? ""
                          : "white_icon"
                          }`}
                        alt="..."
                      />
                    </div>
                  </div>
                )}
              </div>
            </AccordionSummary>
            {props.isSelection || props.device.sharedstatus.waitingforaccept ? (
              ""
            ) : (
              <AccordionDetails className="p-0">
                {detailDeviceid === props.device.id ? renderDeviceDetail() : ""}
              </AccordionDetails>
            )}
          </Accordion>
        </div>
      </div>

    );
  };
  const handleChangePowerSaveValue = async (value) => {
    let mode = value;

    //save this value
    const response = await genericService.post("device/updatepowersavingmode", {
      deviceid: props.device.id,
      mode,
    });
    if (response.status === 200 && response.data.successful) {
      //show success
      setPowerSaveValue(value);
      props.getDevicesList();
    } else {
      props.setError(response.data.message);
    }
  };

  const renderThreeSegmentControl = () => {
    const segments = [
      {
        label: t("Device.On"),
        value: true,
      },
      {
        label: t("Device.Off"),
        value: false,
      }
    ];
    return (
      <SegmentedControl
        name="group-1"
        callback={(val) => handleChangePowerSaveValue(val)}
        powerSaveValue={powerSaveValue}
        segments={segments}
      />
    );
  };
  const handleChangePowerSwitch = async (val) => {
    var powerswitchval;
    if (val === false) {
      powerswitchval = false;
    } else if (val === true) {
      powerswitchval = true;
    }
    const response = await genericService.post("device/changerelay", {
      deviceid: [props.device.id],
      relay: powerswitchval,
    });
    if (response.status === 200 && response.data.successful) {
      setPowerSwitchValue(val);
      props.getDevicesList();
    } else {
      props.setError(response.data.message);
    }
  };
  const renderPowerSegmentControl = () => {
    const segments = [
      {
        label: t("Device.Off"),
        value: false,
      },
      {
        label: t("Device.On"),
        value: true,
      },
    ];
    return (
      <SegmentedControl
        name="group-2"
        callback={(val) => handleChangePowerSwitch(val)}
        powerSaveValue={props.device.modelspecificinfo.powerswitch.powerswitchinfo}
        segments={segments}
      />
    );
  };
  const changeHelkamaSwitch = async (switchType, mode) => {
    var switchValuetosend;
    var switchValueOnupdate
    var sendObject
    if (mode === "off") {
      switchValuetosend = false;
      switchValueOnupdate = "on"
    } else if (mode === "on") {
      switchValuetosend = true;
      switchValueOnupdate = "off"
    }
    let api
    if (switchType == "power") {
      api = "changepowerswitch"
      sendObject =
      {
        deviceid: [props.device.id],
        powerswitch: switchValuetosend
      }

    }
    else if (switchType == "light") {
      api = "changelightswitch"
      sendObject =
      {
        deviceid: props.device.id,
        lightswitch: switchValuetosend
      }

    }
    else if (switchType == "bell") {
      api = "changealarmswitch"
      sendObject =
      {
        deviceid: [props.device.id],
        alarmswitch: switchValuetosend
      }
    }
    else if (switchType == "lock") {
      api = "changelockswitch"
      sendObject =
      {
        deviceid: props.device.id,
        lockswitch: switchValuetosend
      }
    }
    const response = await genericService.post(`device/${api}`, sendObject);
    if (response.status === 200 && response.data.successful) {
      if (switchType == "power") {
        // console.log('***********');
        // console.log('switchValueOnupdate++++++========== power switch case ===========>', switchValueOnupdate);
        // console.log('***********')
        setPowerMode(mode)
      }
      else if (switchType == "light") {
        // console.log('***********');
        // console.log('mode++++++========== light switch case ===========>', mode);
        // console.log('***********')
        setlightsMode(mode)
      }
      else if (switchType == "bell") {
        // console.log('***********');
        // console.log('mode++++++========== bell switch case ===========>', mode);
        // console.log('***********')
        setalarmswitchMode(mode)
      }
      else if (switchType == "lock") {
        // console.log('***********');
        // console.log('mode++++++========== check bell case ===========>', mode);
        // console.log('***********')
        setlockswitchMode(mode)
      }
      // props.getDevicesList();
    } else {
      props.setError(response.data.message);
    }
  }
  const renderAssistancePills = (value) => {
    if (value == 1) {
      return (
        <div className="custom-rectangle1 bg-black2"></div>
      )
    }
    else if (value == 2) {
      return (
        <>
          <div className="custom-rectangle1 bg-black2"></div>
          <div className="custom-rectangle1 bg-black2"></div>
        </>
      )
    }
    else if (value == 3) {
      return (
        <>
          <div className="custom-rectangle1 bg-black2"></div>
          <div className="custom-rectangle1 bg-black2"></div>
          <div className="custom-rectangle1 bg-black2"></div>
        </>
      )

    }
    else if (value >= 4) {
      return (
        <>
          <div className="custom-rectangle1 bg-black2"></div>
          <div className="custom-rectangle1 bg-black2"></div>
          <div className="custom-rectangle1 bg-black2"></div>
          <div className="custom-rectangle1 bg-black2"></div>
        </>
      )
    }

  }


  const renderDeviceDetail = () => {
    return (
      <div className="container mb-3">
        {flavor === "Bike" ? (
          <>
            <div className="row px-5 my-3">
              <div className="col-12 text-center">
                <img
                  className="w-20-rem"
                  alt="Bike"
                  src={props.device.modelspecificinfo.modelphoto}
                />
              </div>
            </div>
            <div className="row my-3">
              <div className={`${(props.device.communicationmode.communicationtype == null || props.device.communicationmode.communicationtype == "RELAY") ? "col-md-10 offset-md-1 pb-3" : "col-md-8 offset-md-2"} `}>
                <div className={`row ${(props.device.communicationmode.communicationtype == null || props.device.communicationmode.communicationtype == "RELAY") && "pb-3"}`}>
                  {/* speed div */}
                  {(props.device.communicationmode.communicationtype == null || props.device.communicationmode.communicationtype == "RELAY") ?
                    <>
                      <div className="col-7">
                        <div className="d-flex flex-column align-items-center justify-content-center pr-5">
                          <img
                            src={props.device.modelspecificinfo.ignition.ignitioninfo ?
                              ( FlavorImages.online_icon) :
                              (FlavorImages.offline_icon)
                            }
                            className="size_25"
                            alt="..."
                          />
                          <label className="text_17_700 m-0 p-0 mt-2 text-capatalize">
                            {props.device.modelspecificinfo.ignition.ignitioninfo ? t("Device.Online") : t("Device.Offline")}
                          </label>
                          <label className="text_10_300 m-0 p-0">
                            {/* {t("Device.Lastonline")} {"14:24"} */}
                            {props.device.lastupdate ? getDate() : t("Device.NeverConnected")}
                          </label>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="d-flex flex-column align-items-center justify-content-center" style={{ marginTop: "7px" }}>
                          <img
                            src={FlavorImages.union}
                            className="size_53x10"
                            alt="..."
                          />
                          <label className="text_17_700 m-0 p-0 mt-3">
                            {props.device.communicationmode.distanceleft.info ? props.device.communicationmode.distanceleft.info : "0"} {"KM"}
                          </label>
                          <label className="text_10_300 m-0 p-0">
                            {t("Device.cycledtoday")}
                          </label>
                        </div>
                      </div>
                    </>
                    :
                    <>
                      <div className="col-md-4 d-flex flex-column align-items-center">
                        <div className="d-flex">
                          {/* {renderAssistancePills(parseInt(props.device.communicationmode.assistancelevel.info))} */} 
                        </div>
                        <div className="d-flex flex-column align-items-center" style={{ width: "150px" }}>
                          {/* <p className="helkama_device_speed_label text_22_700">{props.device.communicationmode.distanceleft.info} {props.device.statistics.measurement.distance_measurement}</p>
                          <label className="helkama_device_speed_sub_label text-gray text_12_400_helkama">
                            left with assistance
                          </label> */}
                          <img
                            src={props.device.modelspecificinfo.ignition.ignitioninfo ?
                              (FlavorImages.online_icon) :
                              (FlavorImages.offline_icon)
                            }
                            className="size_25"
                            alt="..."
                          />
                          <label className="text_17_700 m-0 p-0 mt-2 text-capatalize">
                          {props.device.modelspecificinfo.ignition.ignitioninfo ? t("Device.Online") : t("Device.Offline")}
                          </label>
                          <label className="text-gray text_12_400 m-0 p-0">
                            {props.device.lastupdate ? getDate() : t("Device.NeverConnected")}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3 offset-md-1 d-flex flex-column align-items-center">
                        {props.device.communicationmode.vehiclebattery
                          .valid === true ? (
                            <img
                            className="size_25"
                            alt="Battery"
                            src={
                              props.device.communicationmode.vehiclebattery
                              .info > 75
                                ? FlavorImages.battery_full
                                : props.device.communicationmode.vehiclebattery
                                .info > 50
                                ? FlavorImages.battery_three_quarters
                                : props.device.communicationmode.vehiclebattery
                                .info > 25 
                                ? FlavorImages.battery_half
                                : props.device.communicationmode.vehiclebattery
                                .info > 10
                                ? FlavorImages.battery_quarter
                                : FlavorImages.battery_empty
                            }
                          />
                        ) :
                          <div className="margin_top_point_sixtofive_rem">
                          </div>

                        }
                        <div className="d-flex flex-column align-items-center mt-2">
                          <p className="helkama_device_speed_label ">{props.device.communicationmode.vehiclebattery.info}{"%"}</p>
                          <label className="helkama_device_speed_sub_label text-gray text_12_400">
                          {t("deviceSortList.Battery")}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3 offset-md-1 d-flex flex-column align-items-center">
                        <img
                          className="size_25"
                          style={{width: "auto"}}
                          alt="Battery"
                          src={FlavorImages.distance_device}
                        />
                        <div className="d-flex flex-column align-items-center mt-2" style={{ width: "150px" }}>
                          <p className="helkama_device_speed_label">{props.device.statistics.totaltoday.distance === null ? "0" : props.device.statistics.totaltoday.distance} Km</p>
                          <label className="helkama_device_speed_sub_label text-gray text_12_400">
                          {t("Device.cycledtoday")}
                          </label>
                        </div>
                      </div></>
                  }
                </div>
                <div className={`row ${(props.device.communicationmode.communicationtype == "CAN" || props.device.communicationmode.communicationtype == "UART") && "mt-3 mb-2"} `}>
                  {props.device.communicationmode.powerswitch.valid &&
                    <div className={`${(props.device.communicationmode.communicationtype == "CAN" || props.device.communicationmode.communicationtype == "UART") ? "col-3" : "offset-1 col-7"} `}>
                      <HelkamaSwitch
                        switchMode={powerMode}
                        switch={"power"}
                        changeHelkamaSwitch={(switchType, mode) => changeHelkamaSwitch(switchType, mode)}
                        verticalMode={(props.device.communicationmode.communicationtype == "CAN" || props.device.communicationmode.communicationtype == "UART") ? true : false}
                      />
                    </div>
                  }
                  {props.device.communicationmode.lights.valid &&
                    <div className="col-3">
                      <HelkamaSwitch
                        switchMode={lightsMode}
                        switch={"light"}
                        changeHelkamaSwitch={(switchType, mode) => changeHelkamaSwitch(switchType, mode)}
                        verticalMode={(props.device.communicationmode.communicationtype == "CAN" || props.device.communicationmode.communicationtype == "UART") ? true : false}
                      />
                    </div>
                  }
                  {(props.device.communicationmode.communicationtype == "CAN" || props.device.communicationmode.communicationtype == "UART") &&
                    <> <div className="col-3">
                      {props.device.communicationmode.lockswitch.valid &&
                        <HelkamaSwitch
                          switchMode={lockswitchMode}
                          switch={"lock"}
                          changeHelkamaSwitch={(switchType, mode) => changeHelkamaSwitch(switchType, mode)}
                          verticalMode={(props.device.communicationmode.communicationtype == "CAN" || props.device.communicationmode.communicationtype == "UART") ? true : false}
                        />
                      }
                    </div>
                      {props.device.communicationmode.alarmswitch.valid &&
                        <div className="col-3">
                          <HelkamaSwitch
                            switchMode={alarmswitchMode}
                            switch={"bell"}
                            changeHelkamaSwitch={(switchType, mode) => changeHelkamaSwitch(switchType, mode)}
                            verticalMode={(props.device.communicationmode.communicationtype == "CAN" || props.device.communicationmode.communicationtype == "UART") ? true : false}
                          />
                        </div>
                      }
                    </>
                  }
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row">
              <div className="col-12 mt-2 text-center">
                {
                  <img
                    src={props.device.modelspecificinfo.modelphoto}
                    className="device_shadow_icon"
                    alt="..."
                  />
                }
              </div>
            </div>

            <div className="row">
              <div className="mt-2 col-5 d-flex justify-content-start">
                <img src={FlavorImages.info_icon} className="info_icon ml-10" alt="..." />
                <label className="device_row_left_active_label pl-1 ml-3">
                  {t("Device.Info")}
                </label>
              </div>
            </div>
            <div className="row ml-4 pl-3 px-3 mt-2">
                <div className="col-6 pl-0">
                  <label
                    className="device_row_left_active_label ml-0 "
                    style={{ fontSize: "11px", fontWeight: "400" }}
                  >
                    {t("Device.Model") + ":"}
                  </label>
                  <label
                    className="device_row_left_active_label ml-1 "
                    style={{ fontSize: "11px", fontWeight: "500" }}
                  >
                    {props.device.modelspecificinfo.model}
                  </label>
                </div>

                <div className="col-6 pl-0">
                  <label
                    className="device_row_left_active_label ml-0 "
                    style={{ fontSize: "11px", fontWeight: "400" }}
                  >
                    {t("Device.IMEI")}
                  </label>

                  <label
                    className="device_row_left_active_label ml-0 "
                    style={{ fontSize: "11px", fontWeight: "500" }}
                  >
                    {": " + props.device.uniqueid}
                  </label>
                </div>

                {process.env.REACT_APP_FLAVOR === "Alco" 
                  ?
                    null
                  :
                props.device.modelspecificinfo.speedlimit.speedlimitvalid ? (
                  <div className="col-6 pl-0">
                    <div
                      className=""
                      style={{ paddingRight: "0px" }}
                    >
                      <label
                        className="device_row_left_active_label ml-0 "
                        style={{ fontSize: "11px", fontWeight: "400" }}
                      >
                        {t("Device.SpeedLimit") + ":"}
                      </label>
                      <label
                        className="device_row_left_active_label ml-1 "
                        style={{ fontSize: "11px", fontWeight: "500" }}
                      >
                        {
                          props.device.modelspecificinfo.speedlimit
                            .speedlimitinfo
                        }{" "}
                        {
                          props.device.modelspecificinfo.speedlimit
                            .speedlimitunit
                        }
                      </label>
                    </div>
                  </div>
                ) : null}

                {process.env.REACT_APP_FLAVOR === "Alco" 
                    ?
                      null
                    :
                  <div className="col-6 pl-0">
                    <label
                      className="device_row_left_active_label ml-0 "
                      style={{ fontSize: "11px", fontWeight: "400" }}
                    >
                      {t("Device.Dataplan") + ":"}
                    </label>
                    <label
                      className={"device_row_left_active_label ml-1 " + (dataplanDate > todaysDate ? '' : 'text-red')}
                      style={{ fontSize: "11px", fontWeight: "500" }}
                    >
                      {dataplanDate > todaysDate
                      ? t("Device.Active")
                      : t("Device.Inactive")}
                    </label>
                  </div>
                }

              {process.env.REACT_APP_FLAVOR === "Alco" 
                  ?
                    null
                  :
                  <>
                    {props.device.modelspecificinfo.externalpowersupply
                      .externalpowervalid ? (
                      <div
                        className="col-6 pl-0"
                        style={{ paddingRight: "0px" }}
                      >
                        <label
                          className="device_row_left_active_label ml-0 "
                          style={{ fontSize: "11px", fontWeight: "400" }}
                        >
                          {props.device.modelspecificinfo.model.toLowerCase() == "maxitracker" ? t("Device.internalpower") : t("Device.ExternalPower") + ":"}
                        </label>
                        <label
                          className="device_row_left_active_label ml-1 mb-0"
                          style={{ fontSize: "11px", fontWeight: "500" }}
                        >
                          { props.device.modelspecificinfo.model.toLowerCase() == "maxitracker" && props.device.modelspecificinfo.externalpowersupply.externalpowerinfo
                          ?
                          <>  {props.device.modelspecificinfo.internalbattery.internalbatteryinfo}% <img src={FlavorImages.battery_charging} className="size_12" /></>
                            : props.device.modelspecificinfo.model.toLowerCase() == "maxitracker"
                              ?
                                props.device.modelspecificinfo.internalbattery.internalbatteryinfo + "%"
                                :
                                  props.device.modelspecificinfo.externalbattery.externalbatteryvalid === true && props.device.modelspecificinfo.externalbattery
                                    .externalbatteryinfo === 0
                                    ?
                                      <label
                                        className="device_row_left_active_label ml-0 mb-0"
                                        style={{ fontSize: "11px", fontWeight: "500" }}
                                      >
                                      {t("Device.No")}
                                      </label>
                                      :
                                        props.device.modelspecificinfo.externalbattery.externalbatteryvalid === true
                                          ?
                                            <label
                                              className="device_row_left_active_label ml-0 mb-0"
                                              style={{ fontSize: "11px", fontWeight: "500" }}
                                            >
                                            {props.device.modelspecificinfo.externalbattery
                                              .externalbatteryinfo + "V" }
                                            </label>
                                            :
                                              props.device.modelspecificinfo.externalpowersupply
                                                .externalpowerinfo === true
                                                ? t("Device.Yes")
                                                : t("Device.No")}
                                              </label>
                                            </div>
                    ) : null}
                  </>
               }

                {process.env.REACT_APP_FLAVOR === "Alco" 
                  ?
                    null
                  :
                <div className="col-6 pl-0">
                  {props.device.modelspecificinfo.ignition.ignitionvalid ? (
                    <div
                      className=""
                      style={{ paddingRight: "0px" }}
                    >
                      <label
                        className="device_row_left_active_label ml-0 "
                        style={{ fontSize: "11px", fontWeight: "400" }}
                      >
                        {t("Device.Ignition") + ":"}
                      </label>
                      <label
                        className="device_row_left_active_label ml-1"
                        style={{ fontSize: "11px", fontWeight: "500" }}
                      >
                        {props.device.modelspecificinfo.ignition
                          .ignitioninfo === true
                          ? t("Device.On")
                          : t("Device.Off")}
                      </label>
                    </div>
                  ) : null}
                </div>
              }
            </div>

                {process.env.REACT_APP_FLAVOR === "Alco" 
                  ?
                    null
                  :
                    <>
                      <div className="row">
                        <div className="col-12 mt-2 d-flex justify-content-start">
                          {props.device.modelspecificinfo.powersavingmode
                            .powersavingmodevalid ? (
                            <div className="row mt-3 justify-content-start">
                              <img
                                src={FlavorImages.power_black_icon}
                                className="info_icon ml-3"
                                alt="..."
                              />
                              <label className="device_row_left_active_label pl-1 ml-3">
                                {t("Device.PowerSavingMode")}
                              </label>
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {props.device.modelspecificinfo.powersavingmode
                        .powersavingmodevalid && (
                          <div className="row mb-2 margin-left_25">
                            <div className="col-12  d-flex justify-content-start">
                              {props.device.modelspecificinfo.powersavingmode
                                .powersavingmodepending ? (
                                <div className="power_switch_pending_div text-capitalize text_white text_15_700_2">
                                  {props.device.modelspecificinfo.powersavingmode.powersavingmodependingnewmode
                                  }{" "}
                                  {t("Device.pending")}
                                </div>
                              ) : (
                                <div
                                  className="mt-3"
                                  style={{ height: "40px", width: "500px" }}
                                >
                                  {renderThreeSegmentControl()}
                                </div>
                              )}
                            </div>
                          </div>
                      )}
                    </>
                }

                {process.env.REACT_APP_FLAVOR === "Alco" 
                  ?
                    null
                  :
                  <>
                    <div className="row">
                      <div className="col-7 mt-2 d-flex justify-content-start">
                        <img
                          src={FlavorImages.loc_history_icon}
                          className="info_icon ml-1"
                          alt="..."
                        />
                        <label className="device_row_left_active_label  ml-3">
                          {t("Device.LocationHistory")}
                        </label>
                      </div>
                    </div>
                    <div className="row mb-2 mt-2 margin-left_23 ">
                      <div className="col-12  col-8  d-flex justify-content-start">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateTimePicker
                            inputFormat="yyyy-MM-dd HH:mm"
                            ampm={false}    
                            mask={"____-__-__ __:__"}
                            value={start}
                            onChange={(newValue) => {
                              setStart(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                style={{ width: "180px" }}
                                className="date_label" {...params} />
                            )}
                          />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateTimePicker
                            inputFormat="yyyy-MM-dd HH:mm"
                            ampm={false}  
                            mask={"____-__-__ __:__"}
                            value={end}
                            onChange={(newValue) => {
                              setEnd(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                style={{ width: "180px" }}
                                className="date_label ml-2" {...params} />
                            )}
                          />
                        </LocalizationProvider>
                        <img
                          src={FlavorImages.next_arrow_btn}
                          onClick={() => getLocationHistory(props.device.id, props.device.status)}
                          className="next_arrow_icon cursor-pointer"
                          style={{ marginLeft: "10px" }}
                          alt="..."
                        />
                      </div>

                    </div>
                  </>
                }

            {/* //// power switch changes */}
                {/* {process.env.REACT_APP_FLAVOR === "Alco" 
                  ?
                    null
                  :
                  <> */}
                    <div className="row">
                      <div className="col-sm-5  pl-3 d-flex justify-content-lg-start">
                        {props.device.modelspecificinfo.powerswitch.powerswitchvalid ? (
                          <div className="pl-0">
                            <img src={FlavorImages.power_off} className="info_icon" alt="..." />
                            <label className="device_row_left_active_label pl-1 ml-3">
                              {t("Device.PowerSwitch")}
                            </label>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={`row margin-left_23 mb-4 ${props.device.modelspecificinfo.powerswitch.powerswitchvalid || props.device.modelspecificinfo.powerswitch
                      .powerswitchpending ? "d-block" : "d-none"}`}>
                      <div className="col-5 d-flex justify-content-center">
                        {props.device.modelspecificinfo.powerswitch
                          .powerswitchpending ? (
                          <div className="border_25 text-white py-2 px-2 w-100  bg-navy text-center text-12-700">
                            {t(" Power")}{" "}
                            {t(
                              `Device.${props.device.modelspecificinfo.powerswitch.powerswitchpendingnewmode}`
                            )}{" "}
                            {t("Device.pending")}
                          </div>
                        ) : (
                          <div
                            style={{ height: "40px", width: "500px" }}
                          >
                            {props.device.modelspecificinfo.powerswitch.powerswitchvalid
                              ? renderPowerSegmentControl()
                              : ""}
                          </div>
                        )}
                      </div>
                    </div>
                  {/* </>
                } */}
          </>
        )}
      </div>
    );
  };
  return (
    <React.Fragment>
      <div className={`device_row p-0 ${props.isLast ? "" : ""} `}>{renderDeviceRow()}</div>
      <ErrorDialog
        showErrorDialog={errorDialog}
        select={"locationhistory"}
        onClose={() => setShowErrorDialog(false)}
      />
    </React.Fragment>
  );
};

export default MoreDeviceRow;
