import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import close_gray from "../../assets/images/close_gray.svg";
import * as ProfileService from "../../services/user/ProfileService";
import { shallowEqual, useSelector } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import FlavorImages from "../../assetsFolderImport";

const EditPassword = (props) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [oldPasswordError, setoldPasswordError] = useState("");
  const { accountState } = useSelector(
    (state) => ({ accountState: state.account }),
    shallowEqual
  );
  const setOldPasswordField = (value) => {
    setOldPassword(value);
    setoldPasswordError("");
  };
  const setNewPasswordField = (value) => {
    setNewPassword(value);
    setNewPasswordError("");
  };
  const setConfirmPasswordField = (value) => {
    setConfirmPassword(value);
    setConfirmPasswordError("");
  };
  const updatePassword = async () => {
    if (oldPassword === "") {
      setoldPasswordError("This field is required");
    }
    if (newPassword === "") {
      setNewPasswordError("This field is required");
    }
    if (confirmPassword === "") {
      setConfirmPasswordError("This field is required");
    } else if (confirmPassword !== newPassword) {
      setConfirmPasswordError("Password do not match");
      setNewPasswordError("confirmerror");
    } else {
      let passwordData = {
        oldpassword: oldPassword,
        newpassword: newPassword,
        newpasswordconfirm: confirmPassword,
      };
      const response = await ProfileService.updateProfile(passwordData);
      if (response.status === 200) {
        props.closeEditPasswordDialog();
        toast.success(t("toast.Saved"));
      } else {
        props.setError(response.data.message);
        props.closeEditPasswordDialog();
      }
    }
  };
  const { t } = useTranslation();
  return (
    <>
      <Dialog maxWidth={"xs"} open={props.show}>
        <Card className="card">
          <div className="py-3 px-4 d-flex justify-content-between align-items-center card-header bg-white">
            <label className="text-grey mb-0 text_17_700 ">
              {" "}
              {t("editProfile.changePassword")}
            </label>
            <img
              className="size_1-5_rem cursor-pointer"
              src={FlavorImages.close_gray}
              onClick={() => props.closeEditPasswordDialog()}
              alt="..."
            />
          </div>
          <div className="card-body py-3">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="form-group col-12 mt-1">
                    <label
                      className="col-form-label text_12_500 text-grey"
                      data-success="right"
                    >
                      {t("editProfile.oldPassword")}
                    </label>
                    <input
                      type="password"
                      onChange={(e) => setOldPasswordField(e.target.value)}
                      style={{ background: "#DDDDDD" }}
                      className={`form-control ${oldPasswordError === "" ? "" : "is-invalid"
                        }`}
                    />
                    <div className="invalid-feedback bottom">{oldPasswordError}</div>
                  </div>

                  <div className="form-group col-12 mt-1">
                    <label
                      className="col-form-label text_12_500 text-grey"
                      style={{ fontSize: "small" }}
                      data-success="right"
                    >
                      {t("editProfile.newPassword")}
                    </label>
                    <input
                      type="password"
                      style={{ background: "#DDDDDD" }}
                      onChange={(e) => setNewPasswordField(e.target.value)}
                      className={`form-control ${newPasswordError === "" ? "" : "is-invalid"
                        } `}
                    />
                    <div className="invalid-feedback bottom">
                      {newPasswordError === "confirmerror"
                        ? ""
                        : newPasswordError}
                    </div>
                  </div>
                  <div className="form-group col-12 mt-1">
                    <label
                      className="col-form-label text_12_500 text-grey"
                      style={{ fontSize: "small" }}
                      data-success="right"
                    >
                      {t("editProfile.confirmPassword")}
                    </label>
                    <input
                      onChange={(e) => setConfirmPasswordField(e.target.value)}
                      type="password"
                      style={{ background: "#DDDDDD" }}
                      className={`form-control ${confirmPasswordError === "" ? "" : "is-invalid"
                        }`}
                    />
                    <div className="invalid-feedback bottom">
                      {confirmPasswordError}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="m-0 card-footer  d-flex justify-content-end">
            <button
              className="btn bg-transparent btn-sm font-bold text-grey"
              onClick={() => props.closeEditPasswordDialog()}
              variant="light"
            >
              {t("actionDialog.cancel")}
            </button>

            <button
              className="btn btn_navy bg_dark_helkama text-uppercase ml-3"
              onClick={() => updatePassword()}
            >
              {t("editProfile.editProfilebtn")}
            </button>
          </div>
        </Card>
      </Dialog>
    </>
  );
};

export default EditPassword;
