import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import bell_active from "../../assets/images/bell_active.svg";
// import bell_helkama from "../../assets/Helkama_images/bell_bold.svg";
// import checked from "../../assets/images/checked.svg";
// checked
// import check_empty from "../../assets/images/check_empty.svg";
// check_empty
import ActionButton from "../../components/Buttons/ActionButton";
// import mobile_priority_select from "../../assets/images/priority_alarm_mobile.svg"
// import mobile_priority from "../../assets/images/mobile_priority.svg"
// import Remove_email from "../../assets/images/Remove_email.svg"
// import mass_share from "../../assets/images/mass_share.svg";
// import mass_share_select from "../../assets/images/massShareSelect.svg";
import MassShareButton from "../../components/Buttons/MassShareButton";
import PriorityButton from "../../components/Buttons/PriorityButton";
import ScrollMenuName from "../../components/ScrollBar/ScrollMenuName";
import SearchControl from "../../components/Search/SearchControl";
import SideBarNew from "../../components/SideBar/SideBarNew";
// import search from "../../assets/images/search_gray.svg";
// import search_black from "../../assets/Helkama_images/search_black.svg";
import { INDEX_URL } from "../../helpers/constants";
import * as genericService from "../../services/genericService";
import { alarmsSlice } from "../../slice/alarmsSlice";
import SortAlarm from "../Alarm/Sort_FIlterAlarm";
import PopUp from "../Device/PopUp";
import Map from "../Maps/Map";
import { useParams, useLocation } from "react-router-dom";
import AlarmRow from "./AlarmRow";
import AlarmSharedRow from "./AlarmsharedRow";
import ConfirmDelete from "./ConfirmDelete";
import DeleteActionDialog from "./DeleteActionDialog";
import ShareActionDialog from "./ShareActionDialog";
import ShareRemoveEmails from "./ShareRemoveEmails";
// import up_arrow_grey from "../../assets/images/up_arrow_grey.svg";
// import up_arrow_black from "../../assets/Helkama_images/up_arrow_black.svg";
// import deleteIcon from "../../assets/images/DeleteIcon.svg";....
// import DeleteIcon from "../../assets/Helkama_images/DeleteIcon-black.svg";.....
import { locationHistorySlice } from "../../slice/locationHistorySlice";
// import deleteIconClick from "../../assets/images/deleteIconClick.svg";
import { devicesSlice } from "../../slice/deviceSlice";
import toast from 'react-hot-toast';
import FlavorImages from "../../assetsFolderImport";



const alarmActions = alarmsSlice.actions;
const locationHistoryActions = locationHistorySlice.actions;
const sortActions = devicesSlice.actions;

let animate_hight_up = 'animate_hight_up'
const Alarm = () => {

	const [powerSwitchKey, _setPowerSwitchKey] = useState(false)
	const powerSwitchKeyRef = React.useRef(powerSwitchKey);
	const setPowerSwitchKeyData = (x) => {
		powerSwitchKeyRef.current = _.cloneDeep(x); // keep updated
		_setPowerSwitchKey(x)
	};

	const location = useLocation();
	let path = location.pathname
	let { id } = useParams();
	let width = window.innerWidth;  // use only when no imapact while changing screen from web to mobile
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const history = useHistory();
	const [error, setError] = useState("");
	const [searchKeys, addSearchKey] = useState([]);
	const [alarms, setAlarms] = useState([]);
	const deviceActions = devicesSlice.actions;
	const [filteredAlarms, setFilteredAlarms] = useState([]);
	// console.log("🚀 ~ file: Alarm.js:56 ~ Alarm ~ filteredAlarms", filteredAlarms)
	const [alramType, setAlarmType] = useState([]);
	const [showSortDialog, setShowSortDialog] = useState(false);
	const [alarmsLoaded, setalarmsLoaded] = useState(false)
	const [activeButton, setactiveButton] = useState(false)
	const [dateOrder, setdateOrder] = useState(sessionStorage.getItem("sort") ?? "Latest");
	const [mobileIsSelcetion, setMobileIsselection] = useState(false)
	const [enableDisblaeButton, setenableDisblaeButton] = useState(false);
	const [listOfUl, setlistOfUl] = useState([]);
	const [showActionDialog, setShowActionDialog] = useState(false);
	const [showSharedActionDialog, setShowSharedActionDialog] = useState(false);
	const [firstDownClick, setFirstDownClick] = useState(false)
	const [slideDownClick, setSlideDownClick] = useState(false)
	const [isSelection, setIsSelection] = useState(false);
	const [chooseAll, setChooseAll] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [sharedAlarm, setShareAlarm] = useState([]);
	const [priorityBtn, setPriorityBtn] = useState(true);
	const [searchClick, setSearchClick] = useState(false);
	const [massShareBtn, setMassShareBtn] = useState(false);
	const alarmAction = alarmsSlice.actions;
	const [selected, setSelected] = useState(false);
	const [showRemoveEmail, setShowRemoveEmail] = useState(false);
	const [sharedWith, setShareWith] = useState([]);
	const [alarmId, setAlarmId] = useState("");
	const [downArrowClick, setDownArrowClick] = useState(false);
	const [DeleteIconisClick, setDeleteIconisClick] = useState(false)
	const [arrayOffilter, setarrayOffilter] = useState([])
	const [deviceType, setDeviceType] = useState("web")
	const [deleteAllArray, setDeleteAllArray] = useState([])
	const { alarmState } = useSelector(
		(state) => ({ alarmState: state.alarms }),
		shallowEqual
	);
	const { sortState } = useSelector(
		(state) => ({ sortState: state.devices }),
		shallowEqual
	);
	const listInnerRef = React.createRef()
	const [page, setPage] = useState(1)
	const [scrollList, setScrollList] = useState([])
	const [alarmListTest, setAlarmListTest] = useState([])


	const onScroll = () => {
		if (listInnerRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
			if (scrollTop + clientHeight === scrollHeight) {
				if (alarmListTest.length > page) {
					setPage(page + 1)
					setFilteredAlarms(filteredAlarms => [...filteredAlarms, ...alarmListTest[page]])
				}
			} else {
			}

		}
	};

	const loadAlarms = async () => {
		var response;
		if (process.env.REACT_APP_FLAVOR === "Alco" && priorityBtn === true) {
			response = await genericService.get("events/list-highprio");
			const alarms = response.data;
			dispatch(
				alarmActions.setMapAlarms({
					alarms: alarms,
				})
			);
		} else {
			response = await genericService.get("events/list");
		}
		if (response.status === 200) {
			const alarms = response.data;
			if (sessionStorage.getItem("searchkeys") && sessionStorage.getItem("searchkeys").length > 0) {
				// console.log(sessionStorage.getItem("searchkeys").length);
				let arrayTobePushed = []
				// let keyFilter = id
				// let keyTogetFilter = keyFilter.split(",")
				// for (let i = 1; i < keyTogetFilter.length; i++) {
				// 	arrayTobePushed.push(
				// 		{
				// 			"name": keyTogetFilter[i]
				// 		}
				// 	)
				// }
				arrayTobePushed = JSON.parse(sessionStorage.getItem("searchkeys"))
				let items = []
				let SearchedItems = []
				addSearchKey(arrayTobePushed);
				arrayTobePushed.map((arr) => {
					return items.push(arr.name)
				})
				// console.log(arrayTobePushed);
				const getAlarmName = (inp) => {
					if (inp === "deviceMoving") return t("Alarm.DeviceMoving");
					else if (inp === "deviceStopped")
						return t("Alarm.DeviceStopped");
					else if (inp === "deviceOnline")
						return t("Alarm.deviceOnline");
					else if (inp === "deviceOffline")
						return t("Alarm.deviceOffline");
					else if (inp === "geofenceExit")
						return t("Alarm.GeofenceExit");
					else if (inp === "geofenceEnter")
						return t("Alarm.GeofenceEnter");
					else if (inp === "tampering") return t("Alarm.Tampering");
					else if (inp === "lowBattery") return t("Alarm.LowBattery");
					else if (inp === "lowExternalBattery")
						return t("Alarm.LowExternalBattery");
					else if (inp === "ignitionOn") return t("Alarm.IgnitionOn");
					else if (inp === "ignitionOff") return process.env.REACT_APP_FLAVOR === "Alco"
						? t("Alarm.PositiveBlow")
						: t("Alarm.IgnitionOff")
					else if (inp === "vibration") return t("Alarm.Vibration");
					else if (inp === "powerCut") return t("Alarm.PowerCut");
					else if (inp === "sos") return t("Alarm.sos");
					else if (inp === "deviceOverspeed")
						return t("Alarm.DeviceOverspeed");
					else if (inp === "deviceUnknown")
						return t("Alarm.DeviceUnknown");
					else if (inp === "bypass")
						return t("Alarm.Bypass")
					else
						return inp;
				};
				let fiteredValues;
				for (let item of items) {
					fiteredValues = alarms.filter((alarm) => alarm.devicenameorfrom.toLowerCase().indexOf(item.toLowerCase()) > -1 || getAlarmName(alarm.type).toLowerCase().indexOf(item.toLowerCase()) > -1)
					Array.prototype.push.apply(SearchedItems, fiteredValues);
				}
				for (let item of items) {
					item = item.replace(/\s/g, '')
					fiteredValues = alarms.filter((alarm) => alarm.type.toLowerCase().indexOf(item.toLowerCase()) > -1 || getAlarmName(alarm.type).toLowerCase().indexOf(item.toLowerCase()) > -1)
					Array.prototype.push.apply(SearchedItems, fiteredValues);
				}
				var size = 50;
				if (dateOrder === "Oldest") {
					const sorted = _.uniq(SearchedItems).sort(
						(a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
					)
					for (var i = 0; i < alarms.length; i += size) {
						alarmListTest.push(sorted.slice(i, i + size));
					}
					setFilteredAlarms(alarmListTest[0]);
				} else {
					for (var i = 0; i < alarms.length; i += size) {
						alarmListTest.push(_.uniq(SearchedItems).slice(i, i + size));
					}
					setFilteredAlarms(alarmListTest[0]);
				}

				dispatch(
					alarmAction.setFilteredAlarm({
						filteredAlarm: SearchedItems
					})
				);
			}
			else {
				var size = 50;
				if (dateOrder === "Oldest") {
					const sorted = alarms.sort(
						(a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
					)
					for (var i = 0; i < sorted.length; i += size) {
						alarmListTest.push(sorted.slice(i, i + size));
					}
					setFilteredAlarms(alarmListTest[0]);
					dispatch(
						alarmAction.setFilteredAlarm({
							filteredAlarm: sorted
						})
					);
				} else {
					// console.log(alarms);
					// console.log("sista");

					for (var i = 0; i < alarms.length; i += size) {
						// console.log("PUSHAR FLERA");
						alarmListTest.push(alarms.slice(i, i + size));
					}
					setFilteredAlarms(alarmListTest[0]);
					dispatch(
						alarmAction.setFilteredAlarm({
							filteredAlarm: alarms
						})
					);
				}

			}
			setalarmsLoaded(true)
			setAlarms(alarms);
			dispatch(
				alarmAction.setAlarms({
					alarms: alarms
				})
			);
		} else if (response.status === 801) {
			history.push(INDEX_URL);
		}

		if (process.env.REACT_APP_FLAVOR === "Alco") {
			const sharedresponse = await genericService.get("events/share/list");
			if (sharedresponse.status === 200) {
				const sharealarms = sharedresponse.data;
				setShareAlarm(sharealarms);
			}
		}

	};
	const reloadMassSharedAlarm = async () => {
		const sharedresponse = await genericService.get("events/share/list");
		setFilteredAlarms([])
		alarmListTest.length = 0
		setPage(1)
		if (sharedresponse.status === 200) {
			const sharealarms = sharedresponse.data;
			var size = 50;
			for (var i = 0; i < sharealarms.length; i += size) {
				alarmListTest.push(sharealarms.slice(i, i + size));
			}
			setFilteredAlarms(alarmListTest[0]);
			// console.log(alarmListTest[0]);
			//setFilteredAlarms(sharealarms);
			setactiveButton(false);

		}
	}
	const loadMassShareAlarm = () => {
		setFilteredAlarms([])
		alarmListTest.length = 0
		setPage(1)
		if (massShareBtn) {
			var size = 50;
			for (var i = 0; i < alarms.length; i += size) {
				alarmListTest.push(alarms.slice(i, i + size));
			}
			setFilteredAlarms(alarmListTest[0]);
			//setFilteredAlarms(alarms);
			setIsSelection(false)
			setMassShareBtn(!massShareBtn);
			setChooseAll(false);
		} else {
			var size = 50;
			for (var i = 0; i < alarms.length; i += size) {
				alarmListTest.push(sharedAlarm.slice(i, i + size));
			}
			setFilteredAlarms(alarmListTest[0]);
			//setFilteredAlarms(sharedAlarm);
			setSearchClick(false)
			setIsSelection(false)
			setMassShareBtn(!massShareBtn);
			setChooseAll(false);
		}
	};
	const closeBottomPopupMobile = () => {
		setDownArrowClick(!downArrowClick)
		setFirstDownClick(true)
		animate_hight_up = 'animate_hight_up'
	}
	const loadPriorityAlarm = async () => {
		setFilteredAlarms([])
		alarmListTest.length = 0
		setPage(1)
		var response;
		if (
			process.env.REACT_APP_FLAVOR === "Alco" &&
			priorityBtn === false &&
			massShareBtn === false
		) {
			response = await genericService.get("events/list-highprio");
			setPriorityBtn(true);
		} else if (
			process.env.REACT_APP_FLAVOR === "Alco" &&
			priorityBtn === true &&
			massShareBtn === false
		) {
			response = await genericService.get("events/list-lowprio");
			setPriorityBtn(false);
		} else if (process.env.REACT_APP_FLAVOR === "Alco") {
			response = await genericService.get("events/share/list");
		}
		if (response.status === 200) {
			const alarms = response.data;
			setAlarms(alarms);
			var size = 50;
			for (var i = 0; i < alarms.length; i += size) {
				alarmListTest.push(alarms.slice(i, i + size));
			}
			setFilteredAlarms(alarmListTest[0]);
			//setFilteredAlarms(alarms);
			dispatch(
				alarmAction.setFilteredAlarm({
					filteredAlarm: alarms
				}));
			dispatch(
				alarmActions.setMapAlarms({
					alarms: alarms,
				})
			);
		}
	};
	const setSearchMobile = () => {
		setSearchClick(true)
		setMassShareBtn(false)
		var size = 50;
		for (var i = 0; i < alarms.length; i += size) {
			alarmListTest.push(alarms.slice(i, i + size));
		}
		// console.log(alarmListTest);
		setFilteredAlarms(alarmListTest[0]);
	}

	const setAlarmslist = () => {
		setFilteredAlarms([])
		alarmListTest.length = 0
		setPage(1)
		var size = 50;
		for (var i = 0; i < alarms.length; i += size) {
			alarmListTest.push(alarms.slice(i, i + size));
		}
		setFilteredAlarms(alarmListTest[0]);
		//setFilteredAlarms(alarms)
	}

	const sortAlarmByDate = (list) => {
		// console.log(list);
		setFilteredAlarms([])
		alarmListTest.length = 0
		setPage(1)
		let res = [];
		let fiteredValues = []
		if (sessionStorage.getItem("searchkeys")) {
			let items = []

			let arrayTobePushed = JSON.parse(sessionStorage.getItem("searchkeys"))
			arrayTobePushed.map((arr) => {
				return items.push(arr.name)
			})
			for (let item of items) {
				fiteredValues = alarms.filter((alarm) => alarm.devicenameorfrom.toLowerCase().indexOf(item.toLowerCase()) > -1 || alarm.type.toLowerCase().indexOf(item.toLowerCase()) > -1)
				res.push(...fiteredValues)
			}
			for (let item of items) {
				item = item.replace(/\s/g, '')
				fiteredValues = alarms.filter((alarm) => alarm.type.toLowerCase().indexOf(item.toLowerCase()) > -1 || alarm.type.toLowerCase().indexOf(item.toLowerCase()) > -1)
				res.push(...fiteredValues)
			}
		}
		if (!list.length) {
			list = []
		}
		let underListAlarm
		if (list.length > 0) {
			if (!priorityBtn) {
				underListAlarm = alarmState.filteredAlarm.filter((alarm) => list.includes(alarm.type));
			} else {
				underListAlarm = alarmState.alarms.filter((alarm) => list.includes(alarm.type));
			}
			res.push(...underListAlarm)
		} else if (!sessionStorage.getItem("searchkeys")) {
			// console.log(alarmState);
			if (!priorityBtn) {
				underListAlarm = alarmState.filteredAlarm;
			} else {
				underListAlarm = alarmState.alarms;
			}
			res.push(...underListAlarm)
		}

		if (res.length) {
			//setFilteredAlarms(res)
		}
		if (dateOrder === "Oldest") {
			const sorted = res.sort(
				(a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
			)
			var size = 50;
			for (var i = 0; i < sorted.length; i += size) {
				alarmListTest.push(sorted.slice(i, i + size).sort(
					(a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
				));
			}
			// console.log(alarmListTest)
			//alarmListTest.reverse()
			if (alarmListTest[0].length < 10 && alarmListTest[1]) {
				var merged = alarmListTest[0].concat(alarmListTest[1])
				setPage(2)
				setFilteredAlarms(merged);
			} else {
				setFilteredAlarms(alarmListTest[0]);
			}

			//	setFilteredAlarms(sortedAlarm);
		} else {
			// console.log(res);
			const sorted = res.sort(
				(a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
			)
			var size = 50;
			for (var i = 0; i < sorted.length; i += size) {
				alarmListTest.push(sorted.slice(i, i + size).sort(
					(a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
				));
			}
			setFilteredAlarms(alarmListTest[0]);
		}
	};
	const onKeyChange = (searchKey) => {
		var array = [...searchKeys];
		if (searchKey) {
			var searchObject = { name: searchKey };
			array.push(searchObject);
		}
		filterAlarm(array)
	}

	const addKey = (searchKey) => {
		var searchObject = { name: searchKey };
		var array = [...searchKeys];
		array.push(searchObject);
		addSearchKey(array);
		sessionStorage.setItem("searchkeys", JSON.stringify(array))
		filterAlarm(array);
		setPowerSwitchKeyData(priorityBtn)
		return
		// if (id) {
		// 	history.push(path + "," + searchKey);
		// 	window.location.reload()
		// }
		// else {
		// 	history.push(path + "/," + searchKey);
		// 	window.location.reload()
		// }
	};

	const openSortDialog = () => {
		if (width < 768) {
			setDeviceType("mobile")
			// console.log("nu");
			setShowSortDialog(true);
		}
	};
	const ConfirmDeleteAlarms = async () => {
		let eventids = [];
		
		deleteAllArray.map((alarm, index) => {

			if (alarm.isSelected === true) {
				eventids.push(alarm.eventid);
			}
		});
		console.log(eventids);
		//GUSTAV

		const response = await genericService.post("events/delete", {
			eventid: eventids,
		});
		if (response.status === 200) {
			setConfirmDelete(false);
			setalarmsLoaded(false)
			toast.success(t("toast.Done"))
			setTimeout(() => {
				loadAfterDeleteAlarm();
			}, 600);
		} else {
			toast.error(t("toast.Error"))
		}
	};
	const loadAfterDeleteAlarm = async () => {
		setFilteredAlarms([])
		alarmListTest.length = 0
		setPage(1)
		let response = await genericService.get("events/list");
		const alarms = response.data;
		if (response.status == 200 || response.status == 201) {
			let SearchedItems = []
			let fiteredValues;
			if (id) {
				let items = []
				let arrayTobePushed = []
				let keyFilter = id
				let keyTogetFilter = keyFilter.split(",")
				for (let i = 1; i < keyTogetFilter.length; i++) {
					arrayTobePushed.push(
						{
							"name": keyTogetFilter[i]
						}
					)
				}
				arrayTobePushed.map((arr) => {
					return items.push(arr.name)
				})
				for (let item of items) {
					fiteredValues = alarms.filter((alarm) => alarm.devicenameorfrom.toLowerCase().indexOf(item.toLowerCase()) > -1 || alarm.type.toLowerCase().indexOf(item.toLowerCase()) > -1)
					Array.prototype.push.apply(SearchedItems, fiteredValues);
				}
				for (let item of items) {
					item = item.replace(/\s/g, '')
					fiteredValues = alarms.filter((alarm) => alarm.type.toLowerCase().indexOf(item.toLowerCase()) > -1 || alarm.type.toLowerCase().indexOf(item.toLowerCase()) > -1)
					Array.prototype.push.apply(SearchedItems, fiteredValues);
				}
			}
			if (listOfUl.length) {
				for (let item of listOfUl) {
					fiteredValues = alarms.filter((alarm) => alarm.type.toLowerCase().indexOf(item.toLowerCase()) > -1 || alarm.type.toLowerCase().indexOf(item.toLowerCase()) > -1)
					Array.prototype.push.apply(SearchedItems, fiteredValues);
				}
			}
			if (SearchedItems.length > 0) {
				// console.log("jag körs 1")
				var size = 50;
				for (var i = 0; i < alarms.length; i += size) {
					alarmListTest.push(_.uniq(SearchedItems).slice(i, i + size));
				}
				setFilteredAlarms(alarmListTest[0]);
				//setFilteredAlarms( _.uniq(SearchedItems))
				dispatch(
					alarmAction.setFilteredAlarm({
						filteredAlarm: _.uniq(SearchedItems)
					})
				);
			} else {
				var size = 50;
				for (var i = 0; i < alarms.length; i += size) {
					alarmListTest.push(_.uniq(alarms).slice(i, i + size));
				}
				setFilteredAlarms(alarmListTest[0]);
				// 	console.log("jag körs 2")
				// 	var size = 50;
				// 	for (var i=0; i<alarms.length; i+=size) {
				// 		alarmListTest.push(_.uniq(alarms).slice(i,i+size));
				// 	}
				// 	setFilteredAlarms(alarmListTest[0]);
				// 	//setFilteredAlarms(_.uniq(alarms))
				dispatch(
					alarmAction.setFilteredAlarm({
						filteredAlarm: _.uniq(alarms)
					})
				);
			}

		}

	}
	const filterAlarm = (array) => {
		setFilteredAlarms([])
		alarmListTest.length = 0
		setPage(1)
		if (!array.length) {
			// console.log("nu");
			var size = 50;

			if (massShareBtn) {
				for (var i = 0; i < alarms.length; i += size) {
					alarmListTest.push(_.uniq(sharedAlarm).slice(i, i + size));
				}
				setFilteredAlarms(alarmListTest[0]);
				return;
			}
			else if (listOfUl.length) {
				sortAlarmByDate(listOfUl);
				setarrayOffilter({ ...arrayOffilter, listOfUl })
			}
			else {
				for (var i = 0; i < alarms.length; i += size) {
					alarmListTest.push(_.uniq(alarms).slice(i, i + size));
				}
				setFilteredAlarms(alarmListTest[0]);
				return;
			}
		}
		const items = [];
		let fiteredValues;
		const SearchedItems = [];
		const getAlarmName = (inp) => {
			if (inp === "deviceMoving") return t("Alarm.DeviceMoving");
			else if (inp === "deviceStopped")
				return t("Alarm.DeviceStopped");
			else if (inp === "deviceOnline")
				return t("Alarm.deviceOnline");
			else if (inp === "deviceOffline")
				return t("Alarm.deviceOffline");
			else if (inp === "geofenceExit")
				return t("Alarm.GeofenceExit");
			else if (inp === "geofenceEnter")
				return t("Alarm.GeofenceEnter");
			else if (inp === "tampering") return t("Alarm.Tampering");
			else if (inp === "lowBattery") return t("Alarm.LowBattery");
			else if (inp === "lowExternalBattery")
				return t("Alarm.LowExternalBattery");
			else if (inp === "ignitionOn") return t("Alarm.IgnitionOn");
			else if (inp === "ignitionOff") return process.env.REACT_APP_FLAVOR === "Alco"
				? t("Alarm.PositiveBlow")
				: t("Alarm.IgnitionOff")
			else if (inp === "vibration") return t("Alarm.Vibration");
			else if (inp === "powerCut") return t("Alarm.PowerCut");
			else if (inp === "sos") return t("Alarm.sos");
			else if (inp === "deviceOverspeed")
				return t("Alarm.DeviceOverspeed");
			else if (inp === "deviceUnknown")
				return t("Alarm.DeviceUnknown");
			else if (inp === "bypass")
				return t("Alarm.Bypass")
			else
				return inp;
		};
		if (massShareBtn) {
			fiteredValues = sharedAlarm.filter((alarm) => {
				for (const item of items) {
					return (
						alarm.devicename.toLowerCase().indexOf(item.toLowerCase()) > -1
					);
				}
			});
		}
		else if (array.length) {
			array.map((arr) => items.push(arr.name));


			for (let item of items) {
				fiteredValues = priorityBtn
					? alarmState.alarms.filter((alarm) => alarm.devicenameorfrom.toLowerCase().indexOf(item.toLowerCase()) > -1 || getAlarmName(alarm.type).toLowerCase().indexOf(item.toLowerCase()) > -1)
					: alarmState.filteredAlarm.filter((alarm) => alarm.devicenameorfrom.toLowerCase().indexOf(item.toLowerCase()) > -1 || getAlarmName(alarm.type).toLowerCase().indexOf(item.toLowerCase()) > -1)
				Array.prototype.push.apply(SearchedItems, fiteredValues);

			}

			for (let item of items) {
				item = item.replace(/\s/g, '')
				fiteredValues = priorityBtn
					? alarmState.alarms.filter((alarm) => alarm.devicenameorfrom.toLowerCase().indexOf(item.toLowerCase()) > -1 || alarm.type.toLowerCase().indexOf(item.toLowerCase()) > -1)
					: alarmState.filteredAlarm.filter((alarm) => alarm.devicenameorfrom.toLowerCase().indexOf(item.toLowerCase()) > -1 || alarm.type.toLowerCase().indexOf(item.toLowerCase()) > -1)
				Array.prototype.push.apply(SearchedItems, fiteredValues);
			}
			var size = 50;
			for (var i = 0; i < alarms.length; i += size) {
				alarmListTest.push(_.uniq(SearchedItems).slice(i, i + size));
			}
			setFilteredAlarms(alarmListTest[0]);

			//	setFilteredAlarms(_.uniq(SearchedItems));
			// KANSKE MÅSTE TA BORT HÄR
		}
		else if (array.length) {
			array.map((arr) => items.push(arr.name));
			for (let item of items) {
				fiteredValues = alarms.filter((alarm) => alarm.devicenameorfrom.toLowerCase().indexOf(item.toLowerCase()) > -1 || alarm.type.toLowerCase().indexOf(item.toLowerCase()) > -1)
				Array.prototype.push.apply(SearchedItems, fiteredValues);
			}
			for (let item of items) {
				item = item.replace(/\s/g, '')
				fiteredValues = alarms.filter((alarm) => alarm.type.toLowerCase().indexOf(item.toLowerCase()) > -1 || alarm.type.toLowerCase().indexOf(item.toLowerCase()) > -1)
				Array.prototype.push.apply(SearchedItems, fiteredValues);
			}
			// console.log("DENNA SOM KÖRS VID MOBILE");
			setFilteredAlarms(_.uniq(SearchedItems));
		}
	};
	const OnSearchRemove = (index) => {
		var array = [...searchKeys];
		if (index !== -1) {
			let searchKey = array[index]
			let path2
			if (searchKeys.length > 1)
				path2 = path.replace(`/,${searchKey.name}`, '');
			else
				path2 = path.replace(`/,${searchKey.name}`, '');
			array.splice(index, 1);
			addSearchKey(array);
			filterAlarm(array);
			if (array.length > 1) {
				sessionStorage.setItem("searchkeys", JSON.stringify(array))
			} else {
				sessionStorage.removeItem("searchkeys");
			}
			//history.push(path2)
		}
	};
	const checkClick = () => {
		var array = _.cloneDeep(filteredAlarms);
		var alarm;
		var allArray = []
		for (let i = 0; i<alarmListTest.length; i++){
			for (let j = 0; j < alarmListTest[i].length; j++) {
				allArray.push(alarmListTest[i][j]);
			}
		}
		var array = _.cloneDeep(allArray);
		if (chooseAll) {
			for (let index = 0; index < array.length; index++) {
				alarm = array[index];
				alarm.isSelected = false;
				array[index] = alarm;
			}
			// var size = 50;
			// for (var i = 0; i < alarms.length; i += size) {
			// 	alarmListTest.push(array.slice(i, i + size));
			// }
			// setFilteredAlarms(alarmListTest[0]);
			//setFilteredAlarms(array);
			setDeleteAllArray(array)
			setChooseAll(false);
		} else {
			for (let index = 0; index < array.length; index++) {
				alarm = array[index];
				alarm.isSelected = true;
				array[index] = alarm;
			}
			setDeleteAllArray(array)
			// var size = 50;
			// for (var i = 0; i < alarms.length; i += size) {
			// 	alarmListTest.push(array.slice(i, i + size));
			// }
			// setFilteredAlarms(alarmListTest[0]);
			//setFilteredAlarms(array);
			setChooseAll(true);
		}
	};
	const setIsSelectionMobile = () => {
		if (mobileIsSelcetion) {
			setSlideDownClick(true)
			setMobileIsselection(false)
		}
		else {
			setMobileIsselection(true)
		}
		setIsSelection(!isSelection)
	}
	const onAlarmClick = (index) => {
		var array = _.cloneDeep(filteredAlarms);
		if (index !== -1) {
			var alarm = array[index];

			if (alarm.isSelected !== true){
				alarm.isSelected = true;
				deleteAllArray.push(alarm)
			}else{ 
				alarm.isSelected = false;
				deleteAllArray.splice(alarm, 1)
			}
			array[index] = alarm;
			for (let i = 0; i < array.length; i++) {
				if (array[i].isSelected == true) {
					setactiveButton(true);
					break;
				}
				else {
					setactiveButton(false);
				}
			}
			// console.log("DENNA KÖRS EHOO");
			setFilteredAlarms(array);
		}
	};
	const OnClearAll = () => {
		var array = [...searchKeys];
		array.splice(0, array.length);
		addSearchKey(array);
		dispatch(
			alarmAction.setAction({
				action: false,
			})
		);
		filterAlarm(array);
		sessionStorage.removeItem("searchkeys");

		//   setFilteredAlarms(alarms);
		//   loadAlarms();
		//history.push(INDEX_URL + "Alarm")
	};
	const setActionDialog = (check) => {
		setShowActionDialog(check);
	};
	const resetCheck = () => {
		if (mobileIsSelcetion) {
			setMobileIsselection(false)
		}
		setlistOfUl([])
		setIsSelection(false);
		dispatch(
			alarmAction.setAction({
				action: false,
			})
		);
		var size = 50;
		for (var i = 0; i < alarms.length; i += size) {
			alarmListTest.push(alarms.slice(i, i + size));
		}
		setFilteredAlarms(alarmListTest[0]);
		//setFilteredAlarms(alarms)
		setenableDisblaeButton(false);
	};
	const on_load = () => {
		dispatch(
			locationHistoryActions.locationHistoryPage({
				locationPage: "locationHistory",
			})
		)
		dispatch(locationHistoryActions.resetLocationState());
		// loadAlarms();
	};


	useEffect(() => {
		window.addEventListener('scroll', onScroll);
		if (id) {
			setDownArrowClick(!downArrowClick)
			setFirstDownClick(true)
			animate_hight_up = ''
		}
		on_load();
		toast.promise(loadAlarms(), {
			loading: t("toast.AlarmLoading"),
			success: t("toast.AlarmLoaded"),
			error: t("toast.AlarmError"),
		});
		return () => {
			dispatch(
				deviceActions.setGroupRowSettings({
					showgroupssetting: false,
				})
			);
		};
	}, []);
	const Toggle = (index, id, sharedwith) => {
		setShareWith(sharedwith);
		setSelected(!selected);
		if (alarmState.shareRemoveAction === "") {
			if (id === alarmId) {
				setAlarmId("");
			} else {
				setAlarmId(id);
			}
		}
		if (isSelection === true) onAlarmClick(index);
	};
	return (
		<>
			<React.Fragment>
				<section className="section-home " id="login-page">
					<div className="middle-content d-flex justify-content-between h-100" >
						<SideBarNew
							page="Alarm"
							onClick={(e) => {
								history.push(INDEX_URL + e);
							}}
						/>
						<div className="col row section-data ml-5 ">
							<div
								className={`col-12 col-md-6 col-lg-4 ${"section-left_bottom_data"
									} `}
							>
								<div className="col-12 home_title_bar disply_none_small ">
									<img
										src={FlavorImages.bell_active}
										className="home_title_icon"
										alt="..."
									/>
									<label className="home_title_bar">{t("Alarm.Alarm")}</label>
								</div>

								<div className={` ${downArrowClick === false && firstDownClick ? "animate_hight_down " :
									firstDownClick && downArrowClick ?
										`${animate_hight_up}` : "heigh_50_mobile"} `} >
									<div className="dragbar_toucharea" draggable="true" onTouchStart={() => closeBottomPopupMobile()}>
										<div className="dragbar" ></div>
									</div>
									<div className="control_bar border-bottom pl-1 d-md-none d-block" style={{ marginTop: "15px" }}>
										<div className="row  pl-3 pr-2" style={{ marginBottom: "15px" }}>
											<div className={`${searchClick === false ? "col-5" : "col-1 ellipsis"}  p-0 m-0 `}
											>
												<div className={`accordion ${searchClick ? 'margin_minus_left_10' : ""}`} id="accordianMobile">
													<label
														data-toggle="collapse"
														data-target="#collapsemobile"
														aria-expanded="true"
														aria-controls="collapsemobile"
														className={`mt-2   d-md-none d-sm-flex   ${process.env.REACT_APP_FLAVOR !== "Alco" && searchClick ? "margin_minus_left_25" :
															searchClick ? "margin_minus_left_38" : "ml-2 d-sm-flex"
															} text_22_700`}
													>
														{t("Alarm.Alarm")}
													</label>
													{process.env.REACT_APP_FLAVOR === "Alco" ? <>
														<img
															className="signal_icon ml-2"
															onClick={() => loadMassShareAlarm()}
															src={massShareBtn ? FlavorImages.mass_share_select : FlavorImages.mass_share}
															alt="..."
														/>
														<img
															className={`signal_icon ${searchClick ? "d-none" : "ml-2 d-sm-flex"
																}`}
															onClick={() => loadPriorityAlarm()}
															src={priorityBtn && !massShareBtn ? FlavorImages.mobile_priority_select : FlavorImages.mobile_priority}
															alt="..."
														/>
													</> : ""}

												</div>
											</div>
											<div className={`${searchClick === false && !massShareBtn ? "col-4" :
												!massShareBtn && searchClick ?
													"col-8 mt-2" : "col-3"}  p-0`}>

												<div className="accordion" id="accordionone">
													<SearchControl
														searchClick={searchClick}
														onAddSearchKey={(e) => addKey(e)}
														onChangeSearchKey={(e) => onKeyChange(e)}
														onSortClick={() => openSortDialog()}
														setSearchClick={() => setSearchClick(false)}
													/>
												</div>
												{searchClick === false ? (
													<img
														onClick={() => setSearchMobile()}
														style={{ float: "right" }}
														src={FlavorImages.search}
														className="search_btn_mobile radius_2 d-md-none d-sm-flex mt-2  bg-grey2"
														alt="..."
													/>
												) : (
													""
												)}
											</div>
											<div className={`${massShareBtn ? "col-4" : "col-3"} mt-2 p-0`}>
												<img
													src={isSelection && massShareBtn ? FlavorImages.mass_share_select :
														massShareBtn ? FlavorImages.mass_share : isSelection && !massShareBtn ? FlavorImages.deleteIconClick : (FlavorImages.DeleteIcon)}
													onClick={() => {
														setIsSelectionMobile()

													}}
													className={`size_30 radius_2 ml-2  bg-grey2  `}
													alt="..."
												/>
												<img
													src={FlavorImages.Remove_email}
													onClick={() => setShowRemoveEmail(true)}
													className={`size_30 radius_2 ml-2 d-md-none   bg-grey2 ${massShareBtn ? "d-sm-flex" : "d-none"}`}
													alt="..."
												/>
												<img
													onClick={() => closeBottomPopupMobile()}
													src={FlavorImages.up_arrow_two}
													className={`${downArrowClick ? "rotate_btn_maximize" : "rotate_btn_minmize"} size_30 radius_2 ml-2 d-md-none d-sm-flex padding_6   bg-grey2`}
													alt="..."
												/>
											</div>
										</div>
									</div>
									<div className="control_bar d-none d-md-flex" style={{ paddingTop: "26px" }}>
										<div className="accordion" id="accordionone">
											<SearchControl
												onAddSearchKey={(e) => addKey(e)}
												onChangeSearchKey={(e) => onKeyChange(e)}
												onSortClick={() => openSortDialog()}
												searchClick={searchClick}
												setSearchClick={() => setSearchClick(false)}
											/>
											<SortAlarm
												setAlarmType={setAlarmType}
												setdateOrder={setdateOrder}
												sortAlarmByDate={(list) => sortAlarmByDate(list)}
												sortFilter={setarrayOffilter}
												arrayOffilter={arrayOffilter}
												setAlarmslist={setAlarmslist}
												listOfUl={listOfUl ? listOfUl : []}
												setlistOfUl={(data) => setlistOfUl(data)}
												loadAlarm={alarms}
												show={showSortDialog}
												onClose={() => setShowSortDialog(false)}
											/>
										</div>
										{process.env.REACT_APP_FLAVOR === "Alco" ? (
											<>
												{massShareBtn === false ? (
													<PriorityButton
														active={priorityBtn}
														onClick={() => loadPriorityAlarm()}
													/>
												) : (
													""
												)}
												<MassShareButton
													active={massShareBtn}
													onClick={() => loadMassShareAlarm()}
												/>
											</>
										) : null}
									</div>

									<div className="control_bar  d-md-flex ">
										{isSelection ? (
											chooseAll ? (
												<img
													className="mr-3 margin-left_15 mt-3 cursor-pointer d-none d-md-block"
													style={{ width: "20px", height: "20px" }}
													src={FlavorImages.checked}
													alt="checked"
													onClick={() => checkClick()}
												/>
											) : (
												<img
													className="mr-3 margin-left_15 mt-3 cursor-pointer d-none d-md-block"
													style={{ width: "20px", height: "20px" }}
													src={FlavorImages.check_empty}
													alt="unchecked"
													onClick={() => checkClick()}
												/>
											)
										) : null}

										<div className="d-none accordion  d-md-block mt-3" id="accordiontwo">
											<ActionButton
												massShareBtn={massShareBtn}
												setActionDialog={() => setActionDialog(true)}
												setShowSharedActionDialog={() =>
													setShowSharedActionDialog(true)
												}
											/>

											<DeleteActionDialog
												setenableDisblaeButton={setenableDisblaeButton}
												showActionDialog={showActionDialog}
												massShareBtn={massShareBtn}
												isSelection={isSelection}
												select={"alarm"}
												setShowRemoveEmail={() => setShowRemoveEmail(true)}
												setIsSelection={() => setIsSelection(true)}
												resetIsSelection={() => setIsSelection(false)}
												onClose={() => setShowActionDialog(false)}
												Toggle={() => Toggle()}
											/>
										</div>

										<div className={`search_capsule_bar2 ${width > 786 ? "" : "w-100"} mt-3 row d-md-block d-sm-none`}>
											<ScrollMenuName
												menuItems={searchKeys}
												onRemove={(e) => OnSearchRemove(e)}
												OnClearAll={() => OnClearAll()}
											/>
										</div>
									</div>
									<div className="control_bar d-none d-sm-block">
										<div className="d-none d-md-block d-sm-none accordion mt-3" id="accordiontwo">
										</div>
										<div className="search_capsule_bar2 row d-md-none d-sm-block">
											<ScrollMenuName
												menuItems={searchKeys}
												onRemove={(e) => OnSearchRemove(e)}
												OnClearAll={() => OnClearAll()}
											/>
										</div>
									</div>
									{width < 786 ?
										<div ref={listInnerRef} onScroll={() => onScroll()} className={`scroll_div_alarm_mobile d-md-none d-block legend ${filteredAlarms && filteredAlarms.length && "alarm_list_shadow"}`} style={{}}>
											{massShareBtn ? (
												<AlarmSharedRow
													Toggle={(index, deviceid, sharedWith) =>
														Toggle(index, deviceid, sharedWith)
													}
													sharedAlarm={filteredAlarms}
													alarmId={alarmId}
													sharedWith={sharedWith}
													onClick={(index) => onAlarmClick(index)}
													setChooseAll={setChooseAll}
													isSelection={isSelection}
													selected={chooseAll ? true : false}
												/>
											) : (
												<>
													{
														filteredAlarms && filteredAlarms.length ?
															filteredAlarms.map((alarm, index) => {
																return (
																	<React.Fragment key={index}>
																		<AlarmRow
																			alarm={alarm}
																			isSelection={isSelection}
																			selected={chooseAll ? true : false}
																			key={index}
																			setChooseAll={setChooseAll}
																			massShareBtn={massShareBtn}
																			index={index}
																			onClick={() => onAlarmClick(index)}
																		/>
																	</React.Fragment>
																);
															}) :
															alarmsLoaded &&
															<>
																<div className="d-flex row d-md-none pb-5">
																	<label className="no_data_row justify-content-center w-100 mt-4r text-22-700 text-grey2 px-2">
																		{t("Alarm.Noregisteredalarms")}
																	</label>
																</div>
															</>
													}
												</>
											)}
										</div>
										:
										<div ref={listInnerRef} onScroll={() => onScroll()} className={`mb-3 ${isSelection ? "scroll_div_alarm_buttons" : "scroll_div_alarm"} ${filteredAlarms && filteredAlarms.length > 0 ? "alarm_list_shadow legend" : ""}  d-none d-sm-none d-md-block`}>
											{massShareBtn ? (
												<AlarmSharedRow
													Toggle={(index, deviceid, sharedWith) =>
														Toggle(index, deviceid, sharedWith)
													}
													loadAlarms={() => reloadMassSharedAlarm()}
													sharedAlarm={filteredAlarms}
													alarmId={alarmId}
													sharedWith={sharedWith}
													onClick={(index) => onAlarmClick(index)}
													setChooseAll={setChooseAll}
													isSelection={isSelection}
													selected={chooseAll ? true : false}
												/>
											) :
												filteredAlarms && filteredAlarms.length ?
													filteredAlarms.map((alarm, index) => {
														return (
															<AlarmRow
																alarm={alarm}
																isSelection={isSelection}
																selected={chooseAll ? true : false}
																key={index}
																setChooseAll={setChooseAll}
																massShareBtn={massShareBtn}
																index={index}
																onClick={() => onAlarmClick(index)}
															/>
														);
													}) :
													alarmsLoaded &&
													<>
														<div className="d-flex row pb-5">
															<label className="no_data_row justify-content-center w-100 mt-4r text-22-700 text-grey  px-2">
																{t("Alarm.Noregisteredalarms")}
															</label>
														</div>
													</>
											}
										</div>
									}
								</div>

								<div className="d-none d-md-block mt-2 d-sm-none w-100 d-md-flex justify-content-end">
									{isSelection === true && !massShareBtn ? (
										<div className="">
											<button onClick={() => { resetCheck(); setDeleteIconisClick(false) }} className="grey_btn">
												{t("Device.Cancel")}
											</button>
											<button
												onClick={() => setConfirmDelete(true)}
												className="btn_theme ml-3   background_black_helkama rounded"
												id="next"
											>
												{t("Alarm.DeleteAlarm")}
											</button>
										</div>
									) : isSelection === true ? (
										alarmState.shareRemoveAction === "removeEmail" ? (
											<div className="">
												<button onClick={() => { setIsSelection(false); dispatch(alarmAction.setShareRemoveAction({ shareRemoveAction: "", })); }} className="grey_btn mr-2 ">
													{t("Device.Cancel")}
												</button>
												<button
													disabled={!activeButton}
													onClick={() => setShowRemoveEmail(true)}
													className={`${activeButton ? "primary_btn" : "grey_btn"} rounded`}
												>
													{t("Remove")}
												</button>
											</div>
										) : (
											<div className="">
												<button onClick={() => { setIsSelection(false); dispatch(alarmAction.setShareRemoveAction({ shareRemoveAction: "", })); }}
													className="grey_btn mr-2 ">
													{t("Device.Cancel")}
												</button>
												<button
													disabled={!activeButton}

													onClick={() => setShowRemoveEmail(true)}
													className={`${activeButton ? "primary_btn" : "grey_btn"} rounded`}
												>
													{t("deviceMarker.Share")}
												</button>
											</div>

										)
									) : (
										""
									)}
								</div>

								<div className={`w-100 ${mobileIsSelcetion && downArrowClick ? "slide_up_toggle_btn" :
									slideDownClick ? "slide_down_toggle_btn" : "invisible"}   d-md-none  d-flex  justify-content-md-end bottom_button_devices`}>
									<button
										onClick={() => {
											resetCheck();
											setDeleteIconisClick(false)
										}}
										className={`grey_btn_device`}
									>
										{t("Device.CANCEL")}
									</button>
									<button
										onClick={() => setConfirmDelete(true)}
										className={`red_btn ml-2`}
										id="next"
									>
										{t("Alarm.DeleteAlarm")}
									</button>
								</div>


							</div>
							{
								<div className="section-right_data">
									{error ? <PopUp setError={setError} error={error} /> : ""}
									<Map
										page={"alarm"}
									/>
								</div>
							}
						</div>

						<ShareActionDialog
							setenableDisblaeButton={setenableDisblaeButton}
							showActionDialog={showSharedActionDialog}
							isSelection={isSelection}
							Toggle={() => Toggle()}
							setIsSelection={() => setIsSelection(true)}
							resetIsSelection={() => setIsSelection(false)}
							onClose={() => setShowSharedActionDialog(false)}
						/>
						<ConfirmDelete
							show={confirmDelete}
							onConfirm={() => ConfirmDeleteAlarms()}
							closeConfirmDeleteDialog={() => setConfirmDelete(false)}
							alarms={filteredAlarms}
							loadAlarms={() => loadAlarms()}
							select={"RemoveAlarm"}
						/>
						<ShareRemoveEmails
							show={showRemoveEmail}
							loadAlarms={() => reloadMassSharedAlarm()}
							sharedAlarm={filteredAlarms}
							shareRemoveAction={alarmState.shareRemoveAction}
							closeRemoveEmailDialog={() => setShowRemoveEmail(false)}
							setError={setError}
						/>
						{deviceType === "mobile" ? <SortAlarm
							setAlarmType={setAlarmType}
							setdateOrder={setdateOrder}
							deviceType={deviceType}
							sortAlarmByDate={sortAlarmByDate}
							listOfUl={listOfUl ? listOfUl : []}
							setlistOfUl={(data) => setlistOfUl(data)}
							sortFilter={setarrayOffilter}
							arrayOffilter={arrayOffilter}
							loadAlarm={alarms}
							show={showSortDialog}
							onClose={() => setShowSortDialog(false)}
						/> : ""}


					</div>
				</section>
			</React.Fragment>
		</>
	);
};

export default Alarm;
