/*
 * @Author: Abhi Bhatt
 * @Date:   2020-12-23 14:12:09
 * @Last Modified by:   abhi
 * @Last Modified time: 2021-04-14 12:41:01
 */

// right side stats list(don't forget)
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import statistics_line from "../../assets/images/statistics_line.svg";
// import down_array_grey2 from "../../assets/images/down_array_grey2.svg";

import Statsmodal from "./stats_Modal"
import FlavorImages from './../../assetsFolderImport';
const StatisticsRow = (props) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState({ value: t('Statistics.Car'), isSelected: true })
  const [detail1, setDetail1] = useState('')
  const [detail2, setDetail2] = useState('')
  const [showCarsDropdown, setShowCarsDropDown] = useState(false)

  const [carsData, setCarsData] = useState([
    { value: t('Statistics.Car'), isSelected: true },
    { value: t('Statistics.Train'), isSelected: false },
    { value: t('Statistics.AirPlane'), isSelected: false }
  ])

  const onSelect = (data) => {
    setCarsData(data)
    const selection = data.filter(item => item.isSelected)[0]
    setSelected(selection)

    
    if (selection) {
      switch (selection.value) {
        case t('Statistics.Car'):
          setDetail1(props.device.co2_saved_vs_car_average_per_ride + props.unit)
          setDetail2(props.device.co2_saved_vs_car + props.unit)
          break
        case t('Statistics.Train'):
          setDetail1(props.device.co2_saved_vs_train_average_per_ride + props.unit)
          setDetail2(props.device.co2_saved_vs_train + props.unit)
          break
        case t('Statistics.AirPlane'):
          setDetail1(props.device.co2_saved_vs_airplan_average_per_ride + props.unit)
          setDetail2(props.device.co2_saved_vs_airplan + props.unit)
          break
        default:
          break
      }
    } else {
      setDetail1(props.device.co2_saved_vs_car_average_per_ride + props.unit)
      setDetail2(props.device.co2_saved_vs_car + props.unit)
    }
  }

  useEffect(() => {
    if (props.device && props.unit) {
      setDetail1(props.device.co2_saved_vs_car_average_per_ride + props.unit)
      setDetail2(props.device.co2_saved_vs_car + props.unit)
    } else {
      setDetail1(props.detail1)
      setDetail2(props.detail2)
    }
  }, [props.detail1, props.detail2, props.device, props.unit])

  return (
    <React.Fragment>
      <div className="bg-white  p-0 statistics_cell_size mt-3 d-flex justify-content-between align-items-center w-100 small_shadow rounded_8">
        <div className="bg-navy stats-summary-title-container h-100 statistics_sub_cell_width d-flex flex-column justify-content-center align-items-center" style={{ borderRadius: '8px 0 0 8px' }}>
          <img src={props.image} className='mt-2' height={'25px'} alt="..." />

          <label className="text-white text_12_700 mt-1 mb-1">
            {props.title}
          </label>

          {props.Showdevices ?
            <div className="d-flex justify-content-center" style={{width: '120px', height: '40px'}}>

              <Statsmodal
                data={carsData}
                label={selected ? selected.value : ""}
                onSelect={onSelect}
                singleSelection={true}
                theme='light'
                classes={'car_light_selection_modal'}
                insideCard={true}
              />
            </div> : <></>}
        </div>

        <div className="bg-white h-100 statistics_sub_cell_width d-flex flex-column  justify-content-center align-items-center">
          <label className="text-navy text_12_500 mb-0">{props.title1}</label>
          <label className="text-navy text_gloria_17_700 text-center mt-1 mb-0">
            {detail1}
          </label>
        </div>

        <img src={FlavorImages.statistics_line} className="h-100" alt="..." />

        <div className="bg-white h-100 statistics_sub_cell_width d-flex flex-column  justify-content-center align-items-center" style={{ borderRadius: '0 8px 8px 0' }}>
          <label className="text-navy text_12_500 mb-0">{props.title2}</label>
          <label className="text-navy text_gloria_17_700 text-center mt-1 mb-0">
            {detail2}
          </label>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StatisticsRow;
