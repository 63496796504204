
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
// import signup_congrats from "../../assets/images/signup_congrats.svg";
// import sparking from "../../assets/images/sparking.gif";
import LanguageChanger from "../../components/LanguageChanger/LanguageChanger";
import { INDEX_URL, SITE_TITLE } from "../../helpers/constants";
import { seo } from "../../helpers/seo";
import FlavorImages from "../../assetsFolderImport";

const SignUpComplete = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const clickNext = async (e) => {
    e.preventDefault();
    history.push(INDEX_URL + "devices");
  };

  const on_load = () => {
    seo({
      title: SITE_TITLE,
      metaDescription: "",
    });
  };

  useEffect(() => {
    on_load();
  }, []);

  return (
    <>
      <React.Fragment>
        <div className="col-12 p-4 h-100 " id="login-page">
          <div className="container-fluid p-0 mt-5">
            <div className="vh-100 col-md-6 offset-md-3 col-lg-4 offset-lg-4 pt-4  align-items-center">
              <img
                src={FlavorImages.app_logo}
                className="img-fluid mx-auto d-none d-sm-block photo"
                alt="..."
              />
              <div className="col-12  pt-4 ">
                <div className="col-12  d-flex align-items-center">
                  <label
                    htmlFor="Email"
                    className="text_34_700 font_700_italic_gloria text-center  col-form-label w-100"
                  >
                    {t("signup.Congratulations!")}
                  </label>
                </div>

                <div className="row col-12 justify-content-center align-items-center ml-0 mt-4">
                  <img
                    alt="signup-sparking"
                    className="signup-sparking"
                    src={FlavorImages.sparking}
                  />
                </div>

                <div className=" row  col-12 justify-content-center align-items-center ml-0 mt-4">
                  <img
                    alt="signup-sparking"
                    className="signup-congrats"
                    src={FlavorImages.signup_congrats}
                  />
                </div>

                <div className="col-12 pl-4 ">
                  <label
                    htmlFor="Email"
                    className="text_16_700 font_700_italic_gloria text-center text-navy col-form-label w-100 mt-4"
                  >
                    {t("signup.AccountCreated")}
                  </label>
                </div>

                <div className="px-0 pl-4 col-12  no-gutters mt-3">
                  <div className="col-sm-12   ">
                    <button
                      type="submit"
                      className="btn_login background_black_helkama font_700_italic_gloria"
                      style={{ textTransform: "uppercase" }}
                      id="loginSubmit"
                      onClick={(e) => clickNext(e)}
                    >
                      {process.env.REACT_APP_FLAVOR === "Bike"
                      ?
                      t("signup.LoginAndBiking")
                      :
                      t("signup.LoginAndTracking")
                      }
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <LanguageChanger />
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default SignUpComplete;
