import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import GroupRow from "./GroupRow";
import { devicesSlice } from "../../slice/deviceSlice";
const deviceActions = devicesSlice.actions;
const GroupRows = (props) => {
  const [openedGroupId, setOpenedGroupId] = useState(null);
  const dispatch = useDispatch();
  const changeOpenedGroup = (id) => {
    if (id === openedGroupId) {
      //close it
      setOpenedGroupId(null);
    } else {
      //open it
      setOpenedGroupId(id);
    }
  };
  useEffect(() => {
    dispatch(deviceActions.setPage({ page: "groups" }));

    return () => {
      dispatch(deviceActions.setPage({ page: "devices" }));
    };
  }, []);

  return (
    <>
      {props.groups.map((group, index) => {
        return (
          <GroupRow
            opened={openedGroupId === group.id}
            changeOpenedGroup={changeOpenedGroup}
            group={group}
            key={index}
            onAddGroup={() => props.onAddGroup()}
            devices={props.devices}
          />
        );
      })}
    </>
  );
};

export default GroupRows;
