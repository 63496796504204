import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { mapSettingsSlice } from "../../slice/mapSettingsSlice";
import { devicesSlice } from "../../slice/deviceSlice";
// import groups from "../../assets/images/groups.svg";
// import groups_select from "../../assets/images/groups_select.svg";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
// import signal_green from "../../assets/images/signal_green.svg";
// import signal_red from "../../assets/images/signal_red.svg";
// import wifi_disconnected from "../../assets/Helkama_images/wifi_disconnected.svg";
// import wifi_connected from "../../assets/Helkama_images/wifi.svg";
import GroupDetailRow from "./GroupDetailRow";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import FlavorImages from './../../assetsFolderImport';

const deviceActions = devicesSlice.actions;
const mapSettingActions = mapSettingsSlice.actions;
const GroupRow = (props) => {
  const { t } = useTranslation();
  const containerRef = React.useRef(null);
  const dispatch = useDispatch();
  const [icon, setIcon] = useState('')
  
  const { devicesState } = useSelector(
    (state) => ({ devicesState: state.devices }),
    shallowEqual
    );
  const Toggle = () => {
    if (!props.opened) {
      let tempDevices = [];
      for (let i = 0; i < props.group.deviceId.length; i++) {
        let filterIndex=  devicesState.mapDevicesorignal.findIndex((dev) => dev.id === props.group.deviceId[i])
        tempDevices.push(devicesState.mapDevicesorignal[filterIndex]);
      }
      dispatch(
        deviceActions.setMapDevices({
          mapDevices: tempDevices,
        })
      );
    } else {
      dispatch(
        deviceActions.setMapDevices({
          mapDevices: [],
        })
      );
    }
    props.changeOpenedGroup(props.group.id);
    if (props.isSelection === true) props.onClick();
  };

  const updateGroupDone = () => {
    props.onAddGroup();
    Toggle();
  };

  const getActiveDeviceCount = () => {
    var count = 0;
    props.devices.map((device, index) => {
      if (
        device.status === "online" &&
        props.group.deviceId.indexOf(device.id) > -1
      )
        count = count + 1;
    });
    return count;
  };

  const getInActiveDeviceCount = () => {
    var count = 0;
    props.devices.map((device, index) => {
      if (
        device.status !== "online" &&
        props.group.deviceId.indexOf(device.id) > -1
      )
        count = count + 1;
    });
    return count;
  };

  useEffect(() => {
      setIcon(props.opened ? 'white_icon' : '')
  }, [props.opened])

  const renderDeviceRow = () => {
    return (
      <div className="cursor-pointer " ref={containerRef}>
        <Accordion className="accordion-no-styles  color_light_grey" expanded={props.opened}>
          <AccordionSummary className="p-0" aria-controls="panel1a-content">
            <div
              className={
                props.opened === false
                  ? "group_row_unselect "
                  : "group_row_unselect group_row_select"
              }
              onClick={() => Toggle()}
            >
              <div className="d-flex cursor-pointer align-items-center w-100 ">
                <img
                  src={props.opened === false ? FlavorImages.group : FlavorImages.groups_select}
                  className="size_25"
                  alt="..."
                />

                <div className="d-flex cursor-pointer flex-column col-6 ml-3  px-0">
                  <label
                    className={
                      props.opened === false
                        ? "device_row_name_title cursor-pointer p-0 m-0 text-truncate"
                        : "device_row_name_title cursor-pointer  p-0 m-0 text-white text-truncate"
                    }
                  >
                    {props.group.name}
                  </label>

                  <label
                    className={
                      props.opened === false
                        ? "text_12_500 text-left cursor-pointer p-0 m-0 mt-1"
                        : "text_12_500 text-left cursor-pointer text-white p-0 m-0 mt-1"
                    }
                  >
                    {props.group.deviceId.length + " " + t("Device.devices")}
                  </label>
                </div>

                <div className="d-flex flex-column cursor-pointer justify-content-end col-4 ml-3   px-0">
                  <div className="d-flex flex-row cursor-pointer   align-items-center ">
                    <img src={FlavorImages.signal_green} 
                      className={`signal_icon cursor-pointer ${icon}`} 
                      alt="..." />

                    <label
                      className={
                        props.opened === false
                          ? "text_11_500 cursor-pointer  p-0 ml-2 mb-0"
                          : "text_11_500 cursor-pointer text-white p-0 ml-2 mb-0"
                      }
                    >
                      {getActiveDeviceCount() + " " + t("Device.ActiveDevices")}
                    </label>
                  </div>

                  <div className="d-flex cursor-pointer flex-row mt-2 align-items-center">
                    <img src={FlavorImages.signal_red} 
                      className={`signal_icon cursor-pointer ${icon}`} 
                      alt="..." />

                    <label
                      className={
                        props.opened === false
                          ? "text_11_500 text-left  p-0 ml-2 mb-0 cursor-pointer"
                          : "text_11_500 text-white p-0 ml-2 mb-0 cursor-pointer"
                      }
                    >
                      {getInActiveDeviceCount() +
                        " " +
                        t("Device.InActiveDevices")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className="p-0">
            <GroupDetailRow
              show={props.opened}
              group={props.group}
              mode={"Edit"}
              devices={props.devices}
              onAddGroup={() => updateGroupDone()}
              onCancel={() => props.onCancel()}
            />
          </AccordionDetails>
        </Accordion>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className=" pb-3 px-1 ">{renderDeviceRow()}</div>
    </React.Fragment>
  );
};

export default GroupRow;
