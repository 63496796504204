/*
 * @Author: SweTrack
 */
import axios from "axios";
import { API_URL } from "../../helpers/constants.js";
var FormData = require('form-data');
var fs = require('fs')
export async function register(data) {
  const url = `${API_URL}account/create`;
  const headers = {
    "Content-Type": "application/json",
    "cache-control": "no-cache",
  };
  return axios({
    url: url,
    method: "POST",
    data,
    config: { headers: headers },
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export async function registerPreEmail(data) {
  const url = `${API_URL}Account/createfromshared`;
  const headers = {
    "Content-Type": "application/json",
    "cache-control": "no-cache",
  };
  return axios({
    url: url,
    method: "POST",
    data,
    config: { headers: headers },
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export  async function putPicture(data)
{

  var datapic = new FormData();
  datapic.append('File',data);
  datapic.append('publictoken', 'wcySegj73wuAA-swet-up-4W6&76+0D/f?f$Q+$f3$5?3D$2b$10$Dlwq5z2fAA2qwcySegj73wu4pf/Cf.33xHaBTrCi7afkascg5IJBgC');
  const url = `${API_URL}account/picture/upload`;
  const headers = {
    "Content-Type": "application/json",
    "cache-control": "no-cache",
  };
  return axios({
    url: url,
    method: "PUT",
    data : datapic,
    config: { headers: headers },
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}