import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import BackWardArrow from "../../components/Buttons/BackwardArrow";
// import cross_red from "../../assets/images/cross_red.svg";
import ConfirmDialog from "./ConfirmDialog";
import * as genericService from "../../services/genericService";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from 'react-hot-toast';

import PopUp from "../../pages_v2/Device/PopUp";
import FlavorImages from "../../assetsFolderImport";
const SharingStatus = (props) => {
  const [sharedStatus, setSharedStatus] = useState([]);
  const [ConfirmStopSharing, setConfirmStopSharing] = useState(false);
  const [emailOfIndex, setEmailOfIndex] = useState("");
  const [bikeDetail, setBikeDetail] = useState([]);
  const { t } = useTranslation();
  const [SharedId, setSharedId] = useState("");
  const [error, setError] = useState("");
  const setEmailAndSharedID = (email, id) => {
    setSharedId(id);
    setEmailOfIndex(email);
  };
  const confirmDelete = async () => {
    var response
    if(SharedId)
    {
       response = await genericService.post("device/delete", {
        deviceid: [bikeDetail.id],
        sharedid: [SharedId],
      });
    }
    else 
    {
       response = await genericService.post("device/share/revokependinginvitation", {
        deviceid: [bikeDetail.id],
        email: [emailOfIndex],
      });
    }
    if (response.status === 200) {
      setConfirmStopSharing(false);
      toast.success(t("toast.Done"));
    } else {
      setError(response.data.message);
      setConfirmStopSharing(false);
    }
  };
  const { devicesState } = useSelector(
    (state) => ({ devicesState: state.devices }),
    shallowEqual
  );
  const deviceDetail = () => {
    devicesState.devices.map((device) => {
      if (device.name === props.deviceName) {
        setSharedStatus(device.sharedstatus.sharedwith);
        setBikeDetail(device);
      }
    });
  };
  useEffect(() => {
    deviceDetail();
  }, []);

  return (
    <>
      <div
        className={`${props.showUI === true ? "show_ui" : "hide_ui"
          } col-md-8 ml-2 d-flex  mt-3 flex-column  inner`}
      >
        <BackWardArrow show={true} onClick={() => props.setSelect("BikeDetail")} />
        {sharedStatus.length ? 
        <div className="d-flex mt-4 flex-column  rounded_white ">
          {sharedStatus.map((account, index) => {
            return (
              <div
                onClick={() =>
                  setEmailAndSharedID(account.email, account.sharedid)
                }
                key={index}
                className="p-3    d-flex  border-bottom justify-content-between align-items-center"
                style={{ background: "#ffffff", width: "100%" }}
              >
                <div className="d-flex flex-column">
                <label className=" text_15_700 text-navy my-0">
                  {account.email}{" "}
                </label>
                {account.pending  ? 
                   <label className=" text_12_500 text-navy my-0">
                   {t("Device.Pending Invite")}
                 </label>
                : "" }
                  </div>
                <div>
                  <img
                    onClick={() => setConfirmStopSharing(true)}
                    src={FlavorImages.cross_red}
                    className="size_20 ml-2 cursor-pointer"
                    alt="..."
                  />
                </div>
              </div>
            );
          })}
        </div> : 
        <p className="text_16_700 text-grey text-center mt-5">{t("Device.NoshareDevices")}</p>
        }
      </div>

      <ConfirmDialog
        bikeDetail={bikeDetail}
        onConfirm={()=>confirmDelete()}
        SharedId={SharedId}
        email={emailOfIndex}
        setError={() => setError()}
        closeConfirmDialog={() => setConfirmStopSharing(false)}
        show={ConfirmStopSharing}
        select={props.select}
      />
      {error ? <PopUp setError={setError} error={error} /> : ""}
    </>
  );
};

export default SharingStatus;
