import React from "react";
import { useState } from "react";
import Main from "./components/Main/Main";
import "./css/app.css";
import "./css/mediaquery.css";
import { Toast } from "react-bootstrap";
import { Toaster } from 'react-hot-toast';


// IF STATMENT TO CHANGE THEME
if (process.env.REACT_APP_ASSETS === "swetrack") {
  import ("./css/scolor.css")
} else if (process.env.REACT_APP_ASSETS === "dignita") {
  import ("./css/scolorDignita.css")
}else if (process.env.REACT_APP_ASSETS === "helkama") {
  import ("./css/scolorHelkama.css")
}else if (process.env.REACT_APP_ASSETS === "arctictracks") {
  import ("./css/scolorArctictracks.css")
} else {
  import ("./css/scolor.css")
}


if (process.env.REACT_APP_ASSETS === "helkama") {
  const helkamaCss = require('./css/helkama.css')
}
function App() {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState("");

  return (
    <React.Fragment>
      <Main />
      <Toaster  
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{
          top: 8,

        }}
        toastOptions={{
          // Define default options
          className: 'text_14_500 rounded',
          duration: 5000,
          style: {
            boxShadow: "0px 51px 20px rgba(0, 0, 0, 0.01), 0px 28px 17px rgba(0, 0, 0, 0.05), 0px 13px 13px rgba(0, 0, 0, 0.09), 0px 3px 7px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)",
          },
          // Default options for specific types
          success: {
          duration: 2000,
          iconTheme: {
            primary: "var(--green)",
            secondary: '#fff',
          },
          },
          error: {
            iconTheme: {
              primary: "var(--red)",
              secondary: '#fff',
            },
            },
        }}
        />

      {
        
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
          animation
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            minWidth: 200,
          }}
          className="bg-dark"
        >
          <Toast.Header className="bg-dark">
            <strong className="text_16_400 text-white">
              {process.env.REACT_APP_FLAVOR}
            </strong>
          </Toast.Header>

          <Toast.Body className="text_13_300 text-white">
            {notification.body}
          </Toast.Body>
        </Toast>
      }
    </React.Fragment>
  );
}
export const globaleStr = [];
export default App;
