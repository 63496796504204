import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as helper_functions from "../../helpers/helper_functions";
import { Marker, InfoWindow } from "react-google-maps";
import * as genericService from "../../services/genericService";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { devicesSlice } from "../../slice/deviceSlice";
import { INDEX_URL } from "../../helpers/constants";
import FlavorImages from "../../assetsFolderImport";

const DeviceStreamingMarker = (props) => {
  const [copyText, setTextCopied] = useState(false);
  const [streamingLink, setStreamingLink] = useState("Streaming Link");
  const history = useHistory();
  const dispatch = useDispatch();
  const deviceActions = devicesSlice.actions;
  const { mapSettingsState } = useSelector(
    (state) => ({ mapSettingsState: state.mapSettings }),
    shallowEqual
  );
  const [name, setName] = useState("");
  const { t } = useTranslation();
  const endLiveStream = async () => {
    const response = await genericService.post("streaminglive/delete", {
      streamid: props.liveStream.id,
    });
    if (response.status === 200) {
      // console.log("response ok ")
      dispatch(deviceActions.setLiveStream({ liveStream: null }));
      dispatch(deviceActions.setStreamingDevice({ device: null }));

      history.push(INDEX_URL + "addeditlivestream");
    }
  };


  const copyStreamLink = () => {
    helper_functions.default.copyToClipboard(streamingLink, setTextCopied);
  };

  useEffect(() => {
    let { device } = props;
    let start = new Date(device.streamingstart);
    let now = new Date();

    if (start < now) {
      setName(t("LiveStream.Stream pending"));
    }
    setStreamingLink(
      process.env.REACT_APP_BASE_URL +
      "viewlivestream/" +
      props.liveStream.token
    );

  }, []);
  const { devicesState } = useSelector(
    (state) => ({ devicesState: state.devices }),
    shallowEqual
  );



  const setDeiveInof = () => {
    if (props.device.id === devicesState.selectedMapDeviceId) {
      dispatch(
        deviceActions.setSlectedMapDeviceId({
          selectedMapDeviceId: "",
        })
      );
    } else {
      dispatch(
        deviceActions.setSlectedMapDeviceId({
          selectedMapDeviceId: props.device.id,
        })
      );
    }
  };

  return (
    <>
      <Marker
        onClick={() => setDeiveInof()}
        icon={{
          url:
            FlavorImages.map_straming_marker,
        }}
        position={{
          lat: parseFloat(props.device.latitude),
          lng: parseFloat(props.device.longitude),
        }}
      >
        {mapSettingsState.show_device_name ? (
          <InfoWindow>
            <div className={`custom-tooltip-container color2`}>
              <div
                className="fade show mt-3 mr-4 tooltip bs-tooltip-bottom"
                data-popper-reference-hidden="false"
                data-popper-escaped="false"
                data-popper-placement="bottom"
              >
                <div className="tooltip-arrow"></div>
                <div className="tooltip-inner">{name}</div>
              </div>
            </div>
          </InfoWindow>
        ) : (
          ""
        )}

        {props.device.id === devicesState.selectedMapDeviceId ? (
          <InfoWindow
            zIndex={9999}
          >
            <div
              className={`custom-popover-container infor_streaming_marker_mobile Streaming_infor_margin popover fade show bs-popover-bottom  `}
            >
              <div className="arrow"></div>
              <div className="popover-body px-0">
                <div className="row m-0 px-3">
                  <div className="header_div d-flex col-12 py-2 pr-0 justify-content-between">
                    <div className="row">
                      <img
                        alt="Status"
                        className=" pl-1 pb-3"
                        src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/device_${props.device.status}.svg`).default}
                      />
                      <p className="text-navy font-bold text-22-700 ml-2">
                        {props.device.name}
                      </p>
                    </div>
                    <img
                      onClick={() =>
                        dispatch(
                          deviceActions.setSlectedMapDeviceId({
                            selectedMapDeviceId: "",
                          })
                        )
                      }
                      className="cursor-pointer"
                      alt="Close device"
                      src={FlavorImages.close_gray}
                    />
                  </div>

                  <div className="body_div col-12 py-3 px-0">

                    <div className="row  mb-1 align-items-center">
                      <div className="col-1">
                        <img
                          className="size_20"
                          alt={t("deviceMarker.Position")}
                          src={FlavorImages.map_maker_alt}
                        />
                      </div>
                      <div className="col-9   offset-1 px-0 d-flex flex-column">
                        <p className="text-navy text_12_700 p-0 m-0">
                          {t("deviceMarker.Position")}
                        </p>
                        <p className=" p-0 m-0 text-12-400 line_height_17 ">
                          {parseFloat(props.device.latitude).toFixed(5) +
                            ", " +
                            parseFloat(props.device.longitude).toFixed(5)}
                        </p>
                        <p className="cursor-pointer p-0 m-0 text-12-400 line_height_17 ">
                          {t("deviceMarker.Tap to calculate address")}
                        </p>
                      </div>
                    </div>

                    {/* connection time and speed */}

                    <div className="row  mb-1">
                      <div className="col-7">
                        <div className="row align-items-center">
                          <div className="col-2">
                            <img
                              className="size_20"
                              alt={t("deviceMarker.Connection time")}
                              src={FlavorImages.clock}
                            />
                          </div>
                          <div className="offset-1 col-8">
                            <p className="text-navy text_12_700 p-0 m-0">
                              {t("deviceMarker.Connection time")}
                            </p>
                            <p className="mb-0 text-12-400 line_height_17 ">
                              {helper_functions.default.formatDateTime(
                                props.device.lastconnectiontime
                              )}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-5">
                        <div className="row align-items-center">
                          <div className="col-2">
                            <img
                              className="size_20"
                              alt={t("deviceMarker.Speed")}
                              src={FlavorImages.tachometer_alt}
                            />
                          </div>
                          <div className="offset-1 col-8">
                            <p className="text-navy text_12_700 p-0 m-0">
                              {t("deviceMarker.Speed")}
                            </p>
                            <p className="p-0 m-0 text-12-400 line_height_17 ">
                              {props.device.communicationmode.speed.speedinfo}{" "}
                              Km/h
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>


                    {/* position time and battery */}

                    <div className="row  mb-1">
                      <div className="col-7">
                        <div className="row align-items-center">
                          <div className="col-2">
                            <img
                              className="size_20"
                              alt={t("deviceMarker.Position time")}
                              src={FlavorImages.device_position_time}
                            />
                          </div>
                          <div className="offset-1 col-9">
                            <p className="text-navy text_12_700 p-0 m-0">
                              {t("deviceMarker.Position time")}
                            </p>
                            <p className="p-0 m-0 text-12-400 line_height_17 ">
                              {helper_functions.default.formatDateTime(
                                props.device.lastpositiontime
                              )}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-5">
                        <div className="row align-items-center">
                          <div className="col-2">
                            <img
                              className="size_20"
                              alt={t("deviceMarker.Battery")}
                              src={FlavorImages.device_battery}
                            />
                          </div>
                          <div className="offset-1 col-8">
                            <p className="text-navy text_12_700 p-0 m-0">
                              {t("deviceMarker.Battery")}
                            </p>
                            <p className="p-0 m-0 text-12-400 line_height_17 ">
                              {props.device.communicationmode.battery ?? "0"}{" "}
                              %
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>


                <div className="row  col-md-12 px-0 ml-0">
                  <div className="col-6 streaming_marker_share_button" onClick={() => copyStreamLink()}>
                    {t("deviceMarker.Share")}
                  </div>
                  <div
                    onClick={() => endLiveStream()}
                    className="col-6 streaming_end_live_stream_button"
                  >
                    {t("deviceMarker.End Livestream")}
                  </div>
                </div>


              </div>
            </div>
          </InfoWindow>
        ) : (
          ""
        )}
      </Marker>
    </>
  );
};

export default DeviceStreamingMarker;
