/*
 * @Author: SweTrack
 */
import React, { useEffect, useState } from "react";
import { INDEX_URL, SITE_TITLE } from "../../helpers/constants";
import { seo } from "../../helpers/seo";
import ReactHtmlParser from "react-html-parser";
import LanguageChanger from "../../components/LanguageChanger/LanguageChanger";
import * as loginService from "../../services/user/loginService2";
import TextField from "@mui/material/TextField";
import { shallowEqual, useSelector } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment"
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useParams } from "react-router";
// import cross_red from "../../assets/images/cross_red.svg";
// import check_light_navy from "../../assets/images/check_light_navy.svg";
import Succespopup from "../../pages_v2/Device/SuccesPopup";
// import backward_arrow_grey from "../../assets/images/backward_arrow_grey.svg";
import toast, { Toaster } from 'react-hot-toast';
import FlavorImages from "../../assetsFolderImport";



const ResetPassword = () => {
  let useparams = useParams();
  const { authState } = useSelector(
    (state) => ({ authState: state.auth }),
    shallowEqual
  )
  let token = useparams.token;
  const { t } = useTranslation();
  const [is_valid_email, setIsValidEmail] = useState(-1);
  const history = useHistory();
  const [success, setSucces] = useState("")
  const [is_valid_password, setIsValidPassword] = useState(-1);
  const [is_valid_confirm_password, setIsValidConfirmPassword] = useState(-1);
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const submitResetPassword = async (e) => {
    let errors = {};
    e.preventDefault();

    if (handleValidation()) {
      const response = await loginService.CreateNewPassword(
        fields.Email,
        token,
        fields.NewPassword,
        fields.ConfirmPassword,
        authState.activeLanguage
      );
      if (response.status === 802) {
        if (parseInt(response.data.errorCode) === 20018) {
          errors["NewPassword"] = response.data.message;
          setIsValidPassword(0);
        }
        if (parseInt(response.data.errorCode) === 20035  || parseInt(response.data.errorCode) === 20036) {
          errors["Email"] = response.data.message;
          setIsValidPassword(0);
        }
        if (parseInt(parseInt(response.data.errorCode)) === 20020) {
          errors["Email"] = response.data.message;
          setIsValidEmail(0);
        }
        if (parseInt(parseInt(response.data.errorCode)) === 20034) {
          errors["NewPassword"] = response.data.message;
          errors["ConfirmPassword"] = response.data.message;
          setIsValidEmail(0);
        }
        setErrors(errors)
      } else if (response.status === 200) {
        setIsValidPassword(1);
        errors["NewPassword"] = "";
        // setSucces("password reset succeed")
        toast.success(t("toast.Passchanged"));
        setTimeout(() => {
          history.push(INDEX_URL);
        }, 600);
      } else {
        setIsValidPassword(1);
        // setSucces("password reset succeed")
        toast.success(t("toast.Passchanged"));
        setTimeout(() => {
          history.push(INDEX_URL);
        }, 600);
        errors["NewPassword"] = "";
      }
    }
  };

  const handleValidation = () => {
    let fields_temp = _.cloneDeep(fields);
    let errors = {};
    let formIsValid = true;

    if (!fields_temp["Email"]) {
      formIsValid = false;
      errors["Email"] = t(
        "forgotPassword.Enter email associated with the account"
      );
      setIsValidEmail(0);
    }

    if (!fields_temp["NewPassword"]) {
      formIsValid = false;
      errors["NewPassword"] = t("errors.Please enter password");
      setIsValidPassword(0);
    }

    if (!fields_temp["ConfirmPassword"]) {
      formIsValid = false;
      errors["ConfirmPassword"] = t("errors.Please enter password");
      setIsValidConfirmPassword(0);
    }
    setErrors(errors);
    return formIsValid;
  };

  const handleChange = (field, e) => {
    let fields_temp = _.cloneDeep(fields);
    fields_temp[field] = e.target.value;
    let errors = {};

    if (fields_temp.Email !== "") {
      setIsValidEmail(-1);
    }

    if (fields_temp.NewPassword !== "") {
      setIsValidPassword(-1);
    }

    if (fields_temp.ConfirmPassword !== "") {
      setIsValidConfirmPassword(0);
    }

    setFields(fields_temp);
    setErrors(errors);
  };

  const on_load = () => {
    seo({
      title: SITE_TITLE,
      metaDescription: "",
    });
  };

  useEffect(() => {
    on_load();
  }, []);

  const emptyEmail = () => {
    setFields({ ...fields, Email: "" });
    setIsValidEmail(-1);
  };

  const emptyPassword = () => {
    setFields({ ...fields, NewPassword: "" });
    is_valid_password(-1);
  };

  const emptyConfirmPassword = () => {
    setFields({ ...fields, ConfirmPassword: "" });
    is_valid_confirm_password(-1);
  };

  return (
    <React.Fragment>
      <div className="col-12 px-4 vh-100" id="login-page">
        <div className="row pt-2">
            <div
              className="btn_back rounded_white cursor-pointer justify-content-center align-items-center ml-3 invisible"
              style={{ width: "35px", height: "35px" }}
              onClick={() => window.history.back()}
            >
              <img src={FlavorImages.backward_arrow_grey} className="backward_button cursor-pointer" alt="..." />
            </div>
        </div>
        <div className="container-fluid p-0 mt-5">
          <div className="col-md-6 offset-md-3 col-lg-4 offset-lg-4">
            <img
              src={FlavorImages.app_logo}
              className="img-fluid mx-auto d-block photo"
              alt="..."
            />

            <div className="col-12 mt-5">
              <div className="col-12 d-flex align-items-center">
                <label
                  htmlFor="NewPassword"
                  className="text_34_700 text-center col-form-label w-100"
                >
                  {ReactHtmlParser(t("Password.ResetPassword"))}
                </label>
              </div>

              <div className="col-12  d-flex align-items-center">
                <label
                  htmlFor="NewPassword"
                  className="text_16_400 text-navy text-center col-form-label w-100"
                >
                  {ReactHtmlParser(t("Password.ResetPasswordTitle"))}
                </label>
              </div>

              <div className="col-12  d-flex align-items-center">
                <label
                  htmlFor="Email"
                  className="text_16_400 text-navy text-center col-form-label w-100"
                >
                  {ReactHtmlParser(
                    t("forgotPassword.Enter email associated with the account")
                  )}
                </label>
              </div>

              <div className="col-12 inputBlock   col-sm-12">
                <TextField
                  type="text"
                  htmlFor="Email"
                  className="col-12 Mui-focused label-input-bold"
                  label={ReactHtmlParser(t("login.Email"))}
                  variant="standard"
                  placeholder="mail@mail.com"
                  onChange={(e) => handleChange("Email", e)}
                  defaultValue={fields["Email"]}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {is_valid_email === 0 ? (
                          <img
                            src={FlavorImages.cross_red}
                            className="input-icon cursor-pointer"
                            onClick={() => {
                              emptyEmail();
                            }}
                          ></img>
                        ) : is_valid_email === 1 ? (
                          <img
                            src={FlavorImages.check_light_navy}
                            className="input-icon"
                          ></img>
                        ) : (
                          ""
                        )}
                      </InputAdornment>
                    ),
                  }}
                />

                <span></span>
                {typeof errors["Email"] !== "undefined" &&
                  errors["Email"] !== "" ? (
                  <span className="invalid-feedback bottom position-relative">{errors["Email"]}</span>
                ) : (
                  ""
                )}
              </div>

              <div className="col-12 inputBlock  col-sm-12">
                <TextField

                  htmlFor="NewPassword"
                  className="col-12 Mui-focused label-input-bold"
                  id="NewPassword"
                  label={ReactHtmlParser(t("Password.NewPassword"))}
                  variant="standard"
                  placeholder="• • • • • •"
                  onChange={(e) => handleChange("NewPassword", e)}
                  defaultValue={fields["NewPassword"]}
                  InputProps={{
                    autoComplete:'new-password',
                    type:"Password",
                    endAdornment: (
                      <InputAdornment position="end">
                        {is_valid_password === 0 ? (
                          <img src={FlavorImages.cross_red}
                            onClick=
                            {() => {
                              emptyPassword();
                            }}
                            className="text-red input-icon">
                          </img>
                        ) : is_valid_password === 1 ? (
                          <img
                            src={FlavorImages.check_light_navy}
                            className="input-icon"
                          ></img>
                        ) : (
                          ""
                        )}
                      </InputAdornment>
                    ),
                  }}
                />

                <span></span>
                {typeof errors["NewPassword"] !== "undefined" &&
                  errors["NewPassword"] !== "" ? (
                  <span className="invalid-feedback bottom">
                    {errors["NewPassword"]}
                  </span>
                ) : (
                  ""
                )}
              </div>

              <div className="col-12 inputBlock col-sm-12">
                <TextField
                  type="Password"
                  htmlFor="ConfirmPassword"
                  className="col-12 Mui-focused label-input-bold"
                  id="ConfirmPassword"
                  label={ReactHtmlParser(t("Password.ConfirmPassword"))}
                  variant="standard"
                  placeholder="• • • • • •"
                  onChange={(e) => handleChange("ConfirmPassword", e)}
                  defaultValue={fields["ConfirmPassword"]}
                  InputProps={{
                    autoComplete:'new-password',
                    type:"Password",
                    endAdornment: (
                      <InputAdornment position="end">
                        {is_valid_confirm_password === 0 ? (
                          <img
                            src={FlavorImages.cross_red}
                            className="input-icon cursor-pointer"
                            onClick={() => {
                              emptyConfirmPassword();
                            }}
                          ></img>
                        ) : is_valid_confirm_password === 1 ? (
                          <img
                            src={FlavorImages.check_light_navy}
                            className="input-icon"
                          ></img>
                        ) : (
                          ""
                        )}
                      </InputAdornment>
                    ),
                  }}
                />

                <span></span>
                {typeof errors["ConfirmPassword"] !== "undefined" &&
                  errors["ConfirmPassword"] !== "" ? (
                  <span className="invalid-feedback bottom">
                    {errors["ConfirmPassword"]}
                  </span>
                ) : (
                  ""
                )}
              </div>

              <div className="px-0 mt-5  col-12  no-gutters">
                <div className="col-sm-12   ">
                  <button
                    type="submit"
                    className="btn_login"
                    id="loginSubmit"
                    onClick={(e) => submitResetPassword(e)}
                  >
                    {ReactHtmlParser(t("Password.ResetPassword"))}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <LanguageChanger />
        </div>
      </div>
      {success ? <Succespopup setSuccess={setSucces} success={success} /> : ""}
    </React.Fragment>
  );
};
export default ResetPassword;
