import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import close_gray from "../../assets/images/close_gray.svg";
import * as ProfileService from "../../services/user/ProfileService";
import { shallowEqual, useSelector } from "react-redux";
import { authSlice } from "../../slice/authSlice";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from "@mui/material/TextField";

import { useDispatch } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import FlavorImages from "../../assetsFolderImport";

import {
  enUS,
  sv,
  it,
  nb,
  da,
  fi,
  de,
  fr,
  nl,
  pl,
  es,
  cs,
  pt,
  el,
} from 'date-fns/locale';

const getLanguage = () => {
  const language = localStorage.getItem('language');
  switch (language) {
    case 'en':
      return enUS;
    case 'sv':
      return sv;
    case 'it':
      return it;
    case 'nb':
      return nb;
    case 'da':
      return da;
    case 'fi':
      return fi;
    case 'de':
      return de;
    case 'fr':
      return fr;
    case 'nl':
      return nl;
    case 'pl':
      return pl;
    case 'es':
      return es;
    case 'cs':
      return cs;
    case 'pt':
      return pt;
    case 'el':
      return el;
    default:
      return enUS; // Fallback to English if the language is not recognized
  }
};

const EditName = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  //console.log("🚀 ~ file: EditName.js:20 ~ EditName ~ firstName:", firstName)
  const [end, setEnd] = useState(new Date());
  const [email, setemail] = useState("");
  const [nameFieldError, setNameFieldError] = useState("");
  const authAction = authSlice.actions;
  const { authState } = useSelector(
    (state) => ({ authState: state.auth }),
    shallowEqual
  );

  const setNameField = (value) => {
    setFirstName(value);
    setNameFieldError("");
  };

  const popperSx = {
    "& .MuiPaper-root": {
      backgroundColor: "rgba(120, 120, 120, 0.2)"
    },
    "& .MuiCalendarPicker-root": {
      backgroundColor: "rgba(45, 85, 255, 0.4)"
    },
    "& .MuiPickersDay-dayWithMargin": {
      color: "rgb(229,228,226)",
      backgroundColor: "rgba(50, 136, 153)"
    },
    "& .MuiTabs-root": { backgroundColor: "rgba(120, 120, 120, 0.4)" }
  };
  useEffect(() => {
    if (props.check === 'weight') {
      setFirstName('');
    }
    else {
      setFirstName(
        process.env.REACT_APP_FLAVOR === "Bike"
        ? authState.authData.response.username
        : authState.authData.username

      );
    }
    setemail(
      process.env.REACT_APP_FLAVOR === "Bike"
      ?      authState.authData.response.email
      :      authState.authData.email

      );
  }, [props.check]);
  const updateName = async () => {
    if (firstName === "") {
      setNameFieldError("This field is required");
    } else {
      if (props.check === 'editage') {
        props.onConfirm(end)
      }
      else if (props.check === 'weight') {
        props.onConfirm(firstName)
      }
      else {
        let name = {
          username: firstName,
        };
        const response = await ProfileService.updateProfile(name);
        if (response.status === 200) {
          props.closeEditNameDialog();
          props.loadProfileSetting();
        } else {
          props.setError(response.data.message);
          props.closeEditNameDialog();
        }
      }
    }
  };

  return (
    <>
      <Dialog maxWidth={"xs"} fullWidth open={props.show}>
        <Card className="card">
          <div className="py-3 px-4 d-flex justify-content-between align-items-center card-header bg-white">
            <label className="  text-grey mb-0  text_17_700">
              {" "}
              {props.check == 'editage' ? t("signup.Age") : props.check == 'weight' ? t("signup.Weight") : t("editProfile.changeAccountName")}
            </label>
            <img
              className="size_1-5_rem cursor-pointer"
              src={FlavorImages.close_gray}
              onClick={() => props.closeEditNameDialog()}
              alt="..."
            />
          </div>
          <div className="card-body py-3">
            <div className="row">
              <div className="col-12">
                <div className="form-group col-12 mt-2">
                  {props.check == 'editage' ?
                    <LocalizationProvider
                    locale={getLanguage()}
                    className={"width_300px"}
                    dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat="yyyy/MM/dd"
                      value={end}
                      onChange={(newValue) => {
                        setEnd(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="date_label text_edit_age_picker" {...params} />
                      )}
                    />
                  </LocalizationProvider>
                    :
                    <input
                      type="text"
                      onChange={(e) => setNameField(e.target.value)}
                      style={{ background: "#DDDDDD" }}
                      value={firstName}
                      className={`form-control ${nameFieldError === "" ? "" : "is-invalid"
                        }`}
                    />}
                  <div className="invalid-feedback bottom">{nameFieldError}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="m-0 card-footer  d-flex justify-content-end">
            <button
              className="btn bg-transparent btn-sm font-bold text-grey"
              onClick={() => props.closeEditNameDialog()}
              variant="light"
            >
              {t("actionDialog.cancel")}
            </button>

            <button
              className="btn btn_navy bg_dark_helkama text-uppercase ml-3 "
              onClick={() => updateName()}
              id="next"
            >
              {props.check == 'editage' ? t("button.Save") : props.check == 'weight' ? t("button.Save") : t("actionDialog.SaveName")}
            </button>
          </div>
        </Card>
      </Dialog>
    </>
  );
};

export default EditName;
