import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SITE_TITLE } from "../../helpers/constants";
import { seo } from "../../helpers/seo";
import * as genericService from "../../services/genericService";
import UserManualRow from "./UserManualRow";
import { settingsSlice } from "../../slice/settingsSlice";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import BackWardArrow from "../../components/Buttons/BackwardArrow";
const settingsActions = settingsSlice.actions;
const UserManual = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [warrantyList, setWarrantyList] = useState();
  const { authState } = useSelector(
    (state) => ({ authState: state.auth }),
    shallowEqual
  )
  const data = {
    language: authState.activeLanguage,
  };
  const getWarranty = async () => {
    const response = await genericService.post("services/vehiclemanuals", data);
    if (response.status === 200) {
      var info = response.data.manuals;
      setWarrantyList(info);
    }
  };

  const on_load = () => {
    getWarranty();

    seo({
      title: SITE_TITLE,
      metaDescription: "",
    });
  };

  useEffect(() => {
    on_load();
  }, []);

  return (
    <>
      <div
        className={`${props.showUI === true ? "show_ui" : "hide_ui"
          } col-md-8 d-flex  mt-3 flex-column  inner`}
      >
        <BackWardArrow show={false}       //if passed false, it will be visible on below md devices, if passed true it will be visible irrespectively
          onClick={() => {
            props.setSelect_null()
            dispatch(settingsActions.setPage({ page: "" }));
          }}
        />
        <label className="text_28_700 text-left mt-4">
          {t("Settings.UserManuals")}
        </label>

        <div className="d-flex flex-column   rounded_white ">
          {warrantyList !== undefined
            ? warrantyList.map((manual, index) => {
              return (
                <UserManualRow
                  manual={manual}
                  index={index}
                  key={index}
                  isFirst={index === 0}
                  isLast={warrantyList.length === index + 1}
                />
              );
            })
            : null}
        </div>
      </div>
    </>
  );
};

export default UserManual;
