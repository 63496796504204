import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
// import card_icon from "../../assets/images/card_icon.svg";
// import checked from "../../assets/images/checked.svg";
// import dataplan from "../../assets/Helkama_images/dataplan.svg";
// import check_empty from "../../assets/images/check_empty.svg";
// import download from "../../assets/images/FlavorImages.download.svg";
import SearchControl from "../../components/Search/SearchControl";
import SideBarNew from "../../components/SideBar/SideBarNew";
import { INDEX_URL, SITE_TITLE } from "../../helpers/constants";
import helper_functions from "../../helpers/helper_functions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import Side_left_Arrow_white from "../../assets/images/Side_left_Arrow_white.svg";
// import spinner from "../../assets/lotties/spinner.json";
// import dataplan_exclamation_mark from "../../assets/lotties/dataplan_exclamation_mark.json";
// import dataplan_checkmark from "../../assets/lotties/dataplan_checkmark.json";
// Victors Old
// import success_dataplan from "../../assets/lotties/dataplan_checkmark.json";
// import failed_dataplan from "../../assets/lotties/dataplan_exclamation_mark.json";

import lottie from "lottie-web/build/player/lottie_light"
// import Side_right_Arrow_white from "../../assets/images/Side_right_Arrow_white.svg";
import { seo } from "../../helpers/seo";
import * as genericService from "../../services/genericService";
import AddCard from "./AddCard";
import CardDetail from "./CardDetail";
import ActiveDevices from "./ActiveDevices";
import { devicesSlice } from "../../slice/deviceSlice";
import ActivateDeviceDialog from "./ActivateDeviceDialog"
import InactivateDeiveDialog from "./InactivateDeiveDialog"
import { locationHistorySlice } from "../../slice/locationHistorySlice";
import _ from "lodash";
import FlavorImages from "../../assetsFolderImport";
import FlavorLottie from "../../lottiesFilesImport";
const locationHistoryActions = locationHistorySlice.actions;
let width = window.screen.width;  // use only when no imapact while changing screen from web to mobile


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const deviceActions = devicesSlice.actions;

const DataPlan = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [receiptList, setReceiptList] = useState([]);
  const [activeList, setActiveList] = useState([]);
  const [showAcivateDialog, setShowAcivateDialog] = useState(false)
  const [showInAcivateDialog, setShowInAcivateDialog] = useState(false)
  const [inactiveList, setInactiveList] = useState([]);
  const [activateAll, setActivateAll] = useState(false);
  const [inActivateAll, setInActivateAll] = useState(false);
  const [isSelection, setIsSelection] = useState(true);
  const [toActivate, setToActivate] = useState([]);
  const [toInActivate, setToInActivate] = useState([]);
  const [successResponse, setsuccessResponse] = useState(false)
  const [orinalActiveList, setorinalActiveList] = useState([])
  const [orginalInactiveList, setorginalInactiveList] = useState([])
  const [spinerActve, setSpinerActve] = useState(false);
  const [devicesForActivate, setdevicesForActivate] = useState([])
  const [devicesForInActivate, setdevicesForInActivate] = useState([])
  const [activeClick, setActiveClick] = useState(false);
  const [mobileUI, setmobileUI] = useState(t("DataPlan.DataPlan"));
  const [textSpinnerOne, setTextSpinnerOne] = useState("");
  const [mode, setMode] = useState("view");
  const [card, setCard] = useState(null);
  const [cardIndex, setCardIndex] = useState(0);
  const [downloadIsselection, setDownloadIsselection] = useState(true);
  const [cards, setCards] = useState([]);
  const [spinerError, setSpinerError] = useState("");
  const animationContainer = useRef(null);
  const [spinnerScreenText, setSpinnerScreenText] = useState("");
  const anime = useRef(null);
  const dispatch = useDispatch();
  const [sorting, setSorting] = useState({ field: "name", ascending: "orignal" });
  const { devicesState } = useSelector(
    (state) => ({ devicesState: state.devices }),
    shallowEqual
  );
  let mobileUIArr = [t("DataPlan.DataPlan"), t("DataPlan.Card"), t("DataPlan.Receipts")];

  const setNextUI = (currentUI) => {
    const currentIndex = mobileUIArr.indexOf(currentUI);
    const nextIndex = (currentIndex + 1) % mobileUIArr.length;
    if (nextIndex <= mobileUIArr.length) {
      setmobileUI(mobileUIArr[nextIndex]);
    }
  };

    const setPreviousUI = (currentUI) => {
      const currentIndex = mobileUIArr.indexOf(currentUI);
      const nextIndex = (currentIndex - 1) % mobileUIArr.length;
      if (nextIndex >= 0) {
        setmobileUI(mobileUIArr[nextIndex]);
      }else{
        setmobileUI(mobileUIArr[mobileUIArr.length - 1]);
      }
    };

    const setSpiner=(type)=>
    {
      type == "success"  && setsuccessResponse(true)
      anime.current?.destroy();
      setSpinerActve(true)
      if (animationContainer.current) {
        anime.current = lottie.loadAnimation({
          container: animationContainer.current,
          renderer: "svg",
          loop: true,
          autoplay: true,
          animationData:type== "error"  ? FlavorLottie.dataplan_exclamation_mark : type== "success" ? FlavorLottie.dataplan_checkmark  :  FlavorLottie.spinner,
        });
      }}

  const inActivateSelectedDevicesMobile = async () => {
    if (toInActivate.length) {
      setSpiner("run");
      setSpinerError("");
      setSpinnerScreenText(t("DataPlan.textDeActivateDataPlan"));
      const data = { deviceid: toInActivate };
      const response = await genericService.post("dataplan/stop", data);
      if (response.status === 200 && response.data.successful) {
        setToInActivate([]);
        setSpiner("success");
        setSpinnerScreenText(t("DataPlan.DataplaneDeactivated"));
        loadDataPlan();
      } else if (response.data.hasOwnProperty("errorCode")) {
        setSpinnerScreenText(t("DataPlan.ErrorCode") + response.data.message);
        setTextSpinnerOne("Contact Support");
        setSpiner("error");
        setSpinerError("Error Code:" + response.data.message);
      }
    }
  };

  const activateSelectedDevicesMobile = async () => {
    if (toActivate.length) {
      setSpiner("run");
      setSpinerError("");
      setSpinnerScreenText(t("DataPlan.textActivateDataPlan"));
      setActiveClick(true);
      const data = { deviceid: toActivate };
      const response = await genericService.post("dataplan/start", data);
      if (response.status === 200 && response.data.successful) {
        setSpiner("run");
        setTextSpinnerOne("Start tracking");
        setToActivate([]);
        loadDataPlan();
        setSpiner("success");
      } else if (response.data.hasOwnProperty("errorCode")) {
        setSpiner("error");
        setSpinnerScreenText(t("DataPlan.ErrorCode") + response.data.message);
        setTextSpinnerOne("Contact Support");
        setSpinerError("Error Code:" + response.data.message);
      }
    }
  };

  const renderReceiptRow = () => {
    return receiptList.length ? (
      <>
        {/* <button
          onClick={() => activateSelectedDevices()}
          className={`btn_active_device float-right py-1 mt-1 mb-2 px-3 text-12-500 ${downloadIsselection ? "d-block" : ""
            }
							`}
          htmlFor=""
        >
          {t("DataPlan.Download")}
        </button> */}

        <table className="table table-striped mb-4 scroll_div_28 alarm_list_shadow border_8 overflow-hidden">
          <tbody>
            {receiptList.map((receipt, index) => {
              return (
                <tr key={index}>
                  <td className="text_12_700 text-navy pl-3">
                    {""}
                    {getDate(receipt.date)}
                  </td>
                  <td className="text_12_700 text-navy">{receipt.type}</td>
                  <td>
                    {/* {downloadIsselection ? (
                      receipt.isSelected ? (
                        <img
                          className="mr-3 cursor-pointer float-right check_box_style border_radius_25 "
                          src={checked}
                          alt="checked"
                        />
                      ) : (
                        <img
                          className="cursor-pointer mr-3 check_box_style border_radius_25 float-right my-2"
                          src={check_empty}
                          alt="unchecked"
                        />
                      )
                    ) : ( */}
                      <img
                        onClick={() =>
                          helper_functions.downloadFileFromURL(
                            receipt.receiptlink
                          )
                        }
                        className="cursor-pointer mr-3 check_box_style float-right my-2"
                        style={{ width: "20px", height: "20px" }}
                        src={FlavorImages.download}
                        alt="checked"
                      />
                    {/* )} */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    ) : (
      <div
        className="w-100 d-flex justify-content-center align-items-center rounded_white"
        style={{ borderRadius: 4 }}
      >
        <h2 className="text-grey2 text_22_700 m-4"> {t("DataPlan.Noreceiptsfound")} </h2>
      </div>
    );
  };

  const renderDeviceRow = () => {
    return (
      <div className="pb-3">
        <SearchControl
          onAddSearchKey={(e) => addKey(e)}
          onChangeSearchKey={(e) => addKey(e)}
          page={"DataPlane"}
          hideSorting={true}
          searchClick={true}
        />
        <div className="d-flex mt-3 mb-0 px-0 justify-content-between align-items-end">
          <label
            className="text_13_300 pl-1 pb-1 text-uppercase"
            htmlFor=""
            style={{ color: "#393939" }}
          >
            {t("DataPlan.ActiveDevices")}
          </label>
          <button
            onClick={() => inActivateSelectedDevicesMobile()}
            className={`btn_active_device text-uppercase text_11_700 ml-2 py-2 px-3 mb-2 ${downloadIsselection ? "d-block" : "d-none"
              }`}
            htmlFor=""
          >
            {t("DataPlan.Deactivate")}
          </button>
        </div>

        {activeList.length ? (
          <div className="border_8 scroll_div_28_no_padding alarm_list_shadow">
            {activeList.map((device, index) => {
              return (
                  <ActiveDevices
                    index={index}
                    key={index}
                    onClick={(deviceid) => onChangeInActivate(deviceid)}
                    style={{ width: "20px", height: "20px" }}
                    src={
                      toInActivate.includes(device.deviceid)
                        ? FlavorImages.checked
                        : FlavorImages.check_empty
                    }
                    device={device}
                    isSelection={isSelection}
                    deviceId={device.deviceid}
                    devicename={device.name}
                    deviceuniqueid={device.uniqueid}
                  />
              );
            })}
          </div>
        ) : (
          <div className="border_8 min-vh-25 alarm_list_shadow w-100 h-100 d-flex rounded_white flex-column justify-content-center align-items-center p-3">
            <h2 className="text-grey2 text_22_700 mb-2 text-center">
              {t("DataPlan.Nodeviceswithanactivedata")}
            </h2>
            <p className="text-grey text_12_500 text-center">
              {t(
                "DataPlan.SelectyourdeviceunderInactivedevices"
              )}
            </p>
          </div>
        )}

        <div
          className="d-flex mt-4 mb-0 px-0 justify-content-between align-items-end"
          style={{ borderRadius: "8px", color: "#393939" }}
        >
          <label className="text_13_300 pl-1 pb-1 text-uppercase" htmlFor="">
            {t("DataPlan.InactiveDevices")}
          </label>
          <button
            onClick={() => activateSelectedDevicesMobile()}
            className={`btn_active_device text-uppercase text_11_700 ml-2 py-2 px-3 mb-2 ${downloadIsselection ? "d-block" : "d-none"
              }`}
            htmlFor=""
          >
            {t("DataPlan.Activate")}
          </button>
        </div>

        {inactiveList.length ? (
          <div className="border_8 scroll_div_28_no_padding alarm_list_shadow">
            {inactiveList.map((device, index) => {
              return (
                <ActiveDevices
                  index={index}
                  key={index}
                  onClick={(deviceid) => onChangeActivate(deviceid)}
                  style={{ width: "20px", height: "20px" }}
                  src={
                    toActivate.includes(device.deviceid) ? FlavorImages.checked : FlavorImages.check_empty
                  }
                  device={device}
                  isSelection={isSelection}
                  deviceId={device.deviceid}
                  devicename={device.name}
                  deviceuniqueid={device.uniqueid}
                />
              );
            })}
          </div>
        ) : (
          <div className="border_8 min-vh-25 alarm_list_shadow w-100 h-100 d-flex rounded_white flex-column justify-content-center align-items-center p-3">
            <h2 className="text-grey2 text_22_700 mb-2">
              {t("Device.Nodevicesfound")}
            </h2>
            <p className="text-grey text_12_500 mb-0">
              {t("DataPlan.Pleaseaddadevicetocreateadataplanforit")}
            </p>
          </div>
        )}
      </div>
    );
  };

  const inActivateSelectedDevices = async () => {
    if (toActivate.length) {

    }
    if (toInActivate.length) {
      setShowInAcivateDialog(true)
      let toDeactiveDeiveArray = []
      for (let active of activeList) {
        if (toInActivate.includes(active.deviceid))
          toDeactiveDeiveArray.push(active)
      }
      if (toDeactiveDeiveArray.length) {
        setdevicesForInActivate(toDeactiveDeiveArray)
      }
    }
  };

  const activateSelectedDevices = () => {
    if (toActivate.length) {
      setShowAcivateDialog(true)
      let toActivateDeviceArray = []
      for (let inactive of inactiveList) {
        if (toActivate.includes(inactive.deviceid))
          toActivateDeviceArray.push(inactive)
      }
      if (toActivateDeviceArray.length) {
        setdevicesForActivate(toActivateDeviceArray)
      }
    }
  };

  const onChangeInActivate = (deviceid) => {
    const temp = _.cloneDeep(toInActivate);
    if (temp.includes(deviceid)) temp.splice(temp.indexOf(deviceid), 1);
    else temp.push(deviceid);
    setToInActivate(temp);
  };

  const onChangeInActivateAll = (value) => {
    setInActivateAll(value);
    if (value) {
      let temp = [];
      activeList.forEach((device) => {
        temp.push(device.deviceid);
      });
      setToInActivate(temp);
    } else {
      setToInActivate([]);
    }
  };

  const onChangeActivate = (deviceid) => {
    const temp = _.cloneDeep(toActivate);
    if (temp.includes(deviceid)) temp.splice(temp.indexOf(deviceid), 1);
    else temp.push(deviceid);
    setToActivate(temp);
  };

  const onChangeActivateAll = (value) => {
    setActivateAll(value);

    if (value) {
      let temp = [];
      inactiveList.forEach((device) => {
        temp.push(device.deviceid);
      });
      setToActivate(temp);
    } else {
      setToActivate([]);
    }
  };

  const loadCards = async () => {
    const response = await genericService.get("dataplan/cards/getall");
    setMode("view");
    if (response.status === 200) {
      const cardsList = response.data.data ?? [];
      setCards(cardsList);
      setCard(cardsList[0]);
      setCardIndex(0);
    } else {
      setCards([]);
      setCard(null);
      setCardIndex(0);
    }
  };

  const menuBtnClicked = () => {
    setIsSelection(!isSelection)
    setDownloadIsselection(!downloadIsselection);
  };

  const loadReceipts = async () => {
    const response = await genericService.get("dataplan/receipts");
    if (response.status === 200) {
      const receipts = response.data.data ?? [];
      setReceiptList(receipts);
    }
  };

  const loadDataPlan = async () => {
    const response = await genericService.get("dataplan/list/all");
    // console.log("🚀 ~ file: DataPlan.js:438 ~ loadDataPlan ~ response", response)
    if (response.status === 200) {
      const activeDevice = response.data.active ?? [];
      const inactiveDevice = response.data.inactive ?? [];
      setorinalActiveList(activeDevice)
      setActiveList(activeDevice);
      setInactiveList(inactiveDevice);
      setorginalInactiveList(inactiveDevice);
    }
  };

  const on_load = () => {
    loadCards();
    loadDataPlan();
    loadReceipts();

    seo({
      title: SITE_TITLE,
      metaDescription: "",
    });
  };
  useEffect(() => {
    on_load();
    dispatch(
      locationHistoryActions.locationHistoryPage({
        locationPage: "locationHistory",
      })
    )
    dispatch(locationHistoryActions.resetLocationState());
  }, []);
  const addKey = async (searchKey) => {
    if (searchKey || !searchKey) {
      const response = await genericService.get("dataplan/list/all");
      if (response.status === 200) {
        const activeDevice = response.data.active ?? [];
        const inactiveDevice = response.data.inactive ?? [];
        const copyActiveList = [...activeDevice];
        setActiveList(
          copyActiveList.filter((x) =>
            x.name.toLowerCase().includes(searchKey.toLowerCase())
          )
        );
        const copyInactiveList = [...inactiveDevice];
        setInactiveList(
          copyInactiveList.filter((x) =>
            x.name.toLowerCase().includes(searchKey.toLowerCase())
          )
        );
      }
    }
  };

  function applySorting(key, ascending, type) {
    if (sorting.ascending === "ASC") {
      setSorting({ key: key, ascending: "DSC" });
    }
    else if (sorting.ascending === "DSC") {
      setSorting({ key: key, ascending: "orignal" });
    }
    else if (sorting.ascending === "orignal") {
      setSorting({ key: key, ascending: "ASC" });
    }
    if (sorting.ascending === "orignal" || sorting.ascending === "ASC") {
      if (type === "active") {
        const ListCopy = [...activeList];
        const sortedList = ListCopy.sort((a, b) => {
          if (a[key] === null)
            return 1;
          else if (b[key] === null)
            return -1;
          else
            return a[key].localeCompare(b[key]);
        });
        setActiveList(sorting.ascending === "orignal" ? sortedList : sortedList.reverse());
      } else if (type === "inactive") {
        const ListCopy = [...inactiveList];
        const sortedList = ListCopy.sort((a, b) => {
          if (a[key] === null)
            return 1;
          else if (b[key] === null)
            return -1;
          else
            return a[key].localeCompare(b[key]);
        });
        setInactiveList(sorting.ascending === "orignal" ? sortedList : sortedList.reverse());
      }
    }
    else {
      if (type === "active") {
        setActiveList(orinalActiveList)
      }
      else {
        setInactiveList(orginalInactiveList)
      }
    }


  }
  const stopSpinner = (value) => {
    if (value == "successorerror") {
      if (successResponse == true) {

        setSpinerActve(false)
        setShowInAcivateDialog(false)
        setShowAcivateDialog(false)
        setSpinerError("")
        setSpinnerScreenText("")
      }
    }
    else {
      setSpinerActve(false)
      setShowInAcivateDialog(false)
      setShowAcivateDialog(false)
      setSpinerError("")
      setSpinnerScreenText("")
    }


  }

  const getDate = (date) => {
    let formateDate = helper_functions.formatDateTime(date, "yyyy-MM-dd");
    return formateDate;
  };
 
  const downloadPdf = (data, filename) => {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename; // Set the file name for the download

    // Append the link to the document, trigger a click, and then remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Clean up the URL object
    window.URL.revokeObjectURL(url);
};

  const generateReceipt = async (id, date) => {
    let dateshort = date.split("T")[0]
    const response = await genericService.postCustom("payments/receipts/generate", {
      invoiceid: id,
    }, { flavor: process.env.REACT_APP_ASSETS });
    if (response.status === 200) {  
      downloadPdf(response.data, `${process.env.REACT_APP_ASSETS}_${dateshort}_invoice`);

    }
  }

  return (
    <>
      <React.Fragment>
        <div
          onClick={() => stopSpinner("successorerror")}
          // onClick={() => setSpinerActve(false)}
          className={` mobileSpiner ${spinerActve ? " d-block" : "d-none"
            }`}
        >
          <div className="text-center text_17_700 text-white margin_top_33vh">
          <p className={`text-white align-items-center text_17_700 ${spinerError == "" ? "d-none" : ""}`}>
              {ReactHtmlParser(t("DataPlan.Something went wrong"))}
          </p>
          <div className="d-flex justify-content-center">
          <div className="data_plan_spinner"
          ref={animationContainer}
          ></div>
          </div>

            {/* <div className="spinner-border" role="status"></div> */}
          </div>
          <p className="text-white text_17_700">
            {" "}
            {ReactHtmlParser(spinnerScreenText)}{" "}
          </p>
          <div className={` ${spinerError == "" ? "d-none" : "d-flex"} align-items-center flex-column`}>
            <button
              className={`btn_active_device_grey text_13_700_no_color text-navy py-2 px-4`}
              htmlFor=""
              onClick={() => stopSpinner("stop")}
            >
              <a className="text-navy" href="mailto:support@swetrack.com">{ReactHtmlParser(textSpinnerOne)}</a>
            </button>
            <button
            onClick={()=>stopSpinner("stop")}
              className={`btn_active_device_lightblue  text_13_700_no_color mt-2 text-white py-2 px-4 ${spinerError ? "" : "d-none"
								}`}
              htmlFor=""
            >
              {t("DataPlan.Igotthisonmyown")}
            </button>
          </div>
        </div>

        <section
          className={`section-home overflow-y ${spinerActve ? "opacit_03_mobile" : ""}`}
          id="login-page"
        >
          <div className="middle-content d-flex justify-content-between h-100">
            <SideBarNew
              page="DataPlan"
              onClickMenuBtn={() => menuBtnClicked()}
              onClick={(e) => {
                history.push(INDEX_URL + e);
              }}
            />

            <div className="col-12 section-data d-none d-md-block  ml-5">
              {/* <div className="row max-vh-48 mb-0 d-md-flex d-none pl-3 padding_right_64"> */}
              <div className="row mb-0 d-md-flex d-none pl-3 padding_right_64">
              <div className="home_title_bar_data_plan w-100 pl-4">
                <img
                  src={FlavorImages.card_icon}
                  className="home_title_icon " 
                        // style={{verticalAlign:'sub'}}
                  alt="..."
                />
                <label>{t("DataPlan.DataPlan")}</label>
              </div>
                <div className="col-6 pl-4">
                  <div className="flex-column">

                    <div className="mt-2 d-none">
                      <SearchControl
                        onAddSearchKey={(e) => addKey(e)}
                        onChangeSearchKey={(e) => addKey(e)}
                        page={"DataPlane"}
                        hideSorting={true}
                      />
                    </div>

                    <div
                      className="mt-4 d-flex flex-column"
                      style={{ flex: 1 }}
                    >
                      <label className="text_22_700">
                        {t("DataPlan.Card")}
                      </label>
                      {mode === "add" ? (
                        <Elements stripe={stripePromise}>
                          <AddCard
                            setMode={setMode}
                            loadCards={() => loadCards()}
                          />
                        </Elements>
                      ) : card ? (
                        <CardDetail
                          select={"dataplane"}
                          setMode={setMode}
                          card={card}
                          cards={cards}
                          cardIndex={cardIndex}
                          count={cards.length}
                          loadCards={() => loadCards()}
                        />
                      ) : (
                        <div className="rounded_white w-100 d-flex flex-column justify-content-center align-items-center py-4">
                          <h2 className="text-grey2 text_22_700 mb-2 mt-5">
                            {t("DataPlan.Nocardsadded")}
                          </h2>
                          <div className="text-grey text_12_500 d-inline">
                            {t("DataPlan.Click")}{" "}
                            <p className="text-grey text_12_700 d-inline">
                              {" "}
                              “{t("DataPlan.AddNewCard")}{" "}
                            </p>{" "}
                            {t("DataPlan.toaddacard")}
                          </div>
                          <button
                            onClick={() => setMode("add")}
                            className="btn_theme bg_dark_helkama border-0 ml-auto mr-5"
                            style={{
                              minWidth: "90px",
                              height: "36px",
                              fontSize: "12px",
                              right: "2.5rem",
                            }}
                            id="next"

                          >
                            {t("DataPlan.AddCard")}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className=" text-left col-6 pl-0 ">
                  <div className="col-12">
                    <label className="mt-4 text_22_700 mb-0">
                      {t("DataPlan.Receipts")}
                    </label>
                    <div className="d-flex">
                      <div className="col-5 p-1">
                        <label className="data_plane_text m-0 pb-1 text-grey"> {t("DataPlan.PaymentDate")}</label>

                      </div>
                      <div className="col-5 p-1">
                        <label className="data_plane_text m-0 pb-1 text-grey">{t("DataPlan.PaymentType")}</label>
                      </div>
                      <div className="col-2 pl-0 pb-1">
                        <label className="data_plane_text m-0 pb-1 text-grey">  {t("DataPlan.Download")}</label>
                      </div>

                    </div>
                    {receiptList.length ? (
                      <div className="scroll_div_receipts alarm_list_shadow">
                        <table className="table data_plane_list_div mb-0 table-striped h-100 ">
                          <tbody>
                            {receiptList.map((receipt, index) => {
                              return (
                                <tr className="row mx-0" key={index}>
                                  <td className="col-5 text_12_700 pl-3 text-navy border-0 break_line">
                                    {getDate(receipt.date)}
                                  </td>
                                  <td className="col-5 text_12_700 text-navy border-0 break_line">
                                    {receipt.type}
                                  </td>
                                  <td className="col-2 border-0 ">
                                    <img
                                      onClick={() =>
                                        generateReceipt(receipt.invoiceid, receipt.date)
                                      }
                                      className="cursor-pointer mx-3"
                                      style={{ width: "20px", height: "20px" }}
                                      src={FlavorImages.download}
                                      alt="checked"
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className=" data_plane_list_div">
                        <table className="table data_plane_list_div mb-1 table-striped tableFixHead">
                          <tbody>
                            <tr></tr>
                            <tr style={{ height: "20vh" }}>
                              <td colSpan={3} className="m-0 p-0 border-0">
                                <div
                                  className="w-100 h-100 d-flex justify-content-center align-items-center rounded_white"
                                  style={{ borderRadius: 4 }}
                                >
                                  <h2 className="text-grey2 text_22_700">
                                    {" "}
                                    {t("DataPlan.Noreceiptsfound")}{" "}
                                  </h2>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="row max-vh-48 mt-0 pl-2 pl-3 padding_right_64"> */}
              <div className="row mt-0 pl-2 pl-3 padding_right_64">
                <div className=" text-left col-6 pr-0">
                  <div className="col-12">
                    <label className="mt-4 text_22_700 ">
                      {t("DataPlan.ActiveDevices")}
                    </label>

                    <div className="d-flex row mx-0">
                      <div className="col-1 m-0" >
                      <img
                        className="m-0 cursor-pointer"
                        onClick={() =>
                          onChangeInActivateAll(!inActivateAll)
                        }
                        style={{ minWidth: "20px", minHeight: "20px", maxWidth: "20px", maxHeight: "20px" }}
                        src={inActivateAll ? FlavorImages.checked : FlavorImages.check_empty}
                        alt="checked"
                      /></div>

                      <div className="col-3 ml-n2 m-0" onClick={() =>
                        applySorting(
                          "name",
                          !sorting.ascending,
                          "active"
                        )
                      } > <label className="data_plane_text p-0 m-0">{t("signup.Name")}</label></div>
                      <div
                        onClick={() =>
                          applySorting(
                            "uniqueid",
                            !sorting.ascending,
                            "active"
                          )
                        } className="col-3">
                          <label className="data_plane_text p-0 m-0">{t("Device.IMEI")}</label></div>
                      <div className="col-2 " onClick={() =>
                        applySorting(
                          "paymentmethod",
                          !sorting.ascending,
                          "active"
                        )
                      }> <label className="data_plane_text p-0 m-0" >{t("DataPlan.Payment")}</label></div>
                      <div className="col-3 m-0" onClick={() =>
                        applySorting(
                          "status",
                          !sorting.ascending,
                          "active"
                        )
                      }> <label className="data_plane_text pl-0 m-0">{t("DataPlan.Status")}</label></div>
                    </div>
                    {activeList.length ? (
                      <div
                        className={`${toInActivate.length ? "scroll_div_28_no_padding_button" : "scroll_div_28_no_padding"} data_plane_list mb-3 alarm_list_shadow`}
                      >
                        <div className="row mx-0">
                          <table className="table data_plane_list_div table-striped mb-0">
                            <tbody className="  rounded">
                              {activeList.map((dataplan, index) => {
                                return (
                                  <tr className="row mx-0" key={index}>
                                    <td className="border-0 col-1" >
                                      <img
                                        className="ml-2 cursor-pointer"
                                        onClick={() =>
                                          onChangeInActivate(dataplan.deviceid)
                                        }
                                        style={{ minWidth: "20px", minHeight: "20px", maxWidth: "20px", maxHeight: "20px" }}
                                        src={
                                          toInActivate.includes(dataplan.deviceid)
                                            ? FlavorImages.checked
                                            : FlavorImages.check_empty
                                        }
                                        alt="checked"
                                      />
                                    </td>
                                    <td className="text_12_700 border-0 text-navy col-3 break_line">
                                      {dataplan.name}
                                    </td>
                                    <td className="text_12_400 border-0 text-navy text_dark_helkama col-3 break_line">
                                      {dataplan.uniqueid}
                                    </td>
                                    <td className="text_12_700 border-0 text-navy col-2 break_line">
                                      {dataplan.paymentmethod}
                                    </td>
                                    <td className="text_12_400 border-0 text-navy text_dark_helkama col-3 break_line">
                                    {!dataplan.status 
                                            ? t("DataPlan.unknown")
                                            : dataplan.statustext + " "}
                                      {helper_functions.formatDate(
                                          dataplan.renewedexpiringdate, "yyyy-MM-dd"
                                        )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : (
                      <div className="">
                        <table className="table data_plane_list_div mb-1 table-striped tableFixHead">
                          <tbody>
                            <tr></tr>
                            <tr style={{ height: "20vh" }}>
                              <td colSpan={4} className="m-0 p-0 border-0">
                                <div
                                  className="w-100 h-100 d-flex rounded_white flex-column justify-content-center align-items-center"
                                  style={{ borderRadius: 4 }}
                                >
                                  <h2 className="text-grey2 text_22_700 mb-2">
                                    {t(
                                      "DataPlan.Nodeviceswithanactivedata"
                                    )}
                                  </h2>
                                  <p className="text-grey text_12_500">
                                    {t(
                                      "DataPlan.SelectyourdeviceunderInactivedevices"
                                    )}
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}

                    <button
                      onClick={() => inActivateSelectedDevices()}
                      className={`btn_theme bg_dark_helkama ml-1 mb-3 text_11_700 ${toInActivate.length ? "" : "d-none"
                        }`}
                      style={{
                        minWidth: "85px",
                        height: "36px",
                        backgroundColor: "var(--navy)",
                        fontWeight: "700",
                        fontSize: "12px",
                      }}
                      id="next"
                    >
                      {t("DataPlan.Deactivate")}
                    </button>
                  </div>
                </div>

                <div className=" text-left col-6 pl-0 ">
                  <div className="col-12">
                    <label className="mt-4 text_22_700">
                      {t("DataPlan.InactiveDevices")}
                    </label>
                    <div className="d-flex row mx-0">

                      <div className="col-1 m-0">
                      <img
                        className=" p-0 m-0 cursor-pointer"

                        onClick={() =>
                          onChangeActivateAll(!activateAll)
                        }
                        style={{ minWidth: "20px", minHeight: "20px", maxWidth: "20px", maxHeight: "20px" }}
                        src={activateAll ? FlavorImages.checked : FlavorImages.check_empty}
                        alt="checked"
                      /></div>

                      <div className="col-3 p-0 m-0" onClick={() =>

                        applySorting(
                          "name",
                          !sorting.ascending,
                          "inactive"
                        )
                      } > <label className="data_plane_text m-0">{t("signup.Name")}</label></div>
                      <div
                        onClick={() =>
                          applySorting(
                            "uniqueid",
                            !sorting.ascending,
                            "inactive"
                          )
                        } className="col-3 p-0 m-0 "  >  <label className="data_plane_text m-0">{t("Device.IMEI")}</label></div>
                      <div className="col-2 p-0 m-0 " onClick={() =>
                        applySorting(
                          "paymentmethod",
                          !sorting.ascending,
                          "inactive"
                        )
                      }> <label className="data_plane_text m-0">{t("DataPlan.Payment")}</label></div>
                      <div className="col-3 p-0 m-0" onClick={() =>
                        applySorting(
                          "status",
                          !sorting.ascending,
                          "inactive"
                        )
                      }> <label className="data_plane_text p-0 m-0">{t("DataPlan.Status")}</label></div>
                    </div>
                    {inactiveList.length ? (
                      <div
                        className={`${toActivate.length ? "scroll_div_28_no_padding_button" : "scroll_div_28_no_padding"} data_plane_list mb-3 alarm_list_shadow`}
                      >
                        <div className="row mx-0">
                          <table className="table data_plane_list_div table-striped mb-0">
                            <tbody className="  rounded">
                              {inactiveList.map((dataplan, index) => {
                                return (
                                  <tr className="row mx-0" key={index}>
                                    <td className="border-0 col-1">
                                      <img
                                        className="ml-2 cursor-pointer"
                                        onClick={() =>
                                          onChangeActivate(dataplan.deviceid)
                                        }
                                        style={{ minWidth: "20px", minHeight: "20px", maxWidth: "20px", maxHeight: "20px" }}
                                        src={
                                          toActivate.includes(dataplan.deviceid)
                                            ? FlavorImages.checked
                                            : FlavorImages.check_empty
                                        }
                                        alt="checked"
                                      />
                                    </td>
                                    <td className="text_12_700 border-0 text-navy col-3 break_line">
                                      {dataplan.name}
                                    </td>
                                    <td className="text_12_400 border-0 text-navy text_dark_helkama col-3 break_line">
                                      {dataplan.uniqueid}
                                    </td>
                                    <td className="text_12_700 border-0 text-navy col-2 break_line">
                                      {dataplan.paymentmethod}
                                    </td>
                                    <td className="text_12_400 border-0 text-navy text_dark_helkama col-3 break_line">
                                      {dataplan.renewedexpiringdate ?
                                           <> 
                                           {!dataplan.status 
                                            ? t("DataPlan.unknown")
                                            : dataplan.statustext + " "}
                                       {helper_functions.formatDate(
                                          dataplan.renewedexpiringdate, "yyyy-MM-dd"
                                        )}
                                        </>
                                      : ""}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : (
                      <div className=" ">
                        <table className="data_plane_list_div table mb-1 table-striped tableFixHead">

                          <tbody>
                            <tr></tr>
                            <tr style={{ height: "20vh" }}>
                              <td colSpan={4} className="m-0 p-0 border-0">
                                <div
                                  className="w-100 h-100 d-flex rounded_white flex-column justify-content-center align-items-center"
                                  style={{ borderRadius: 4 }}
                                >
                                  <h2 className="text-grey2 text_22_700 mb-2 ">
                                    {t("Device.Nodevicesfound")}
                                  </h2>
                                  <p className="text-grey text_12_500 ">
                                    {t(
                                      "DataPlan.Pleaseaddadevicetocreateadataplanforit"
                                    )}
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                    <button
                      onClick={() => activateSelectedDevices()}
                      className={`btn_theme bg_dark_helkama ml-1 mb-3 ${toActivate.length ? "" : "d-none"
                        }`}
                      style={{
                        minWidth: "85px",
                        height: "36px",
                        backgroundColor: "var(--navy)",
                        fontWeight: "700",
                        fontSize: "12px",
                      }}
                      id="next"
                    >
                      {t("DataPlan.Activate")}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-12 d-md-none d-block"
              style={{ marginTop: "90px",  marginBottom: "calc(1rem + env(safe-area-inset-bottom))" }}
            >
              <div className="row justify-content-center align-items-center my-2">
                <img
                  className={`previosButtonimg mr-2 size_20`}
                  src={FlavorImages.Side_left_Arrow}
                  onClick={() => setPreviousUI(mobileUI)}
                  alt="..."
                />

                <label className={`${mobileUI === t("DataPlan.DataPlan")
                  ? "text-navy"
                  : "text-grey2"} col-3 text-truncate text-center text_22_700 m-1 p-0 `}
                  onClick={() => setmobileUI(t("DataPlan.DataPlan"))}
                  htmlFor="">{t("DataPlan.DataPlan")}</label>

                <label className={`${mobileUI === t("DataPlan.Card")
                  ? "text-navy"
                  : "text-grey2"} col-3 text-truncate text-center text_22_700 m-1 p-0`} 
                  onClick={() => setmobileUI(t("DataPlan.Card"))}
                  htmlFor="">{t("DataPlan.Card")}</label>
                <label className={`${mobileUI === t("DataPlan.Receipts")
                  ? "text-navy"
                  : "text-grey2"} col-3 text-truncate text-center text_22_700 m-1 p-0`} 
                  onClick={() => setmobileUI(t("DataPlan.Receipts"))}
                  htmlFor="">{t("DataPlan.Receipts")}</label>
                <img
                  className={`nextButtonimg ml-2 size_20`}
                  src={FlavorImages.Side_right_Arrow}
                  onClick={() => setNextUI(mobileUI)}
                  alt="..."
                />
              </div>
              {mobileUI === t("DataPlan.Card") ? (
                <div className="mt-3">
                  {mode === "add" ? (
                    <Elements stripe={stripePromise}>
                      <AddCard
                        setMode={setMode}
                        loadCards={() => loadCards()}
                      />
                    </Elements>
                  ) : card ? (
                    <CardDetail
                      setMode={setMode}
                      card={card}
                      cards={cards}
                      cardIndex={cardIndex}
                      count={cards.length}
                      loadCards={() => loadCards()}
                    />
                  ) : (
                    <div
                      className="rounded_white w-100 d-flex flex-column justify-content-center align-items-center pt-5 "
                      style={{ flex: 1, borderRadius: 4 }}
                    >
                      <h2 className="text-grey2 text_22_700 mb-2">
                        {t("DataPlan.Nocardsadded")}
                      </h2>
                      <div className="text-grey text_12_500 d-inline">
                        {t("DataPlan.Click")}{" "}
                        <p className="text-grey text_12_700 d-inline">
                          {" "}
                          “{t("DataPlan.AddNewCard")}”{" "}
                        </p>{" "}
                        {t("DataPlan.toaddacard")}
                      </div>
                      <button
                        onClick={() => setMode("add")}
                        className="btn_theme bg_dark_helkama border-0 ml-auto mr-4 mb-3"
                        style={{
                          minWidth: "90px",
                          height: "35px",
                          fontSize: "14px",
                          right: "2.5rem",
                        }}
                        id="next"
                      >
                        {t("DataPlan.AddCard")}
                      </button>
                    </div>
                  )}
                </div>
              ) : mobileUI === t("DataPlan.DataPlan") ? (
                renderDeviceRow()
              ) : (
                renderReceiptRow()
              )}
            </div>
          </div>
        </section>
        <ActivateDeviceDialog
          showAcivateDialog={showAcivateDialog}
          onsuccess={() => loadDataPlan()}
          spinerActve={spinerActve}
          setSpinerActve={(type) => setSpiner(type)}
          setTextSpinnerOne={(value) => setTextSpinnerOne(value)}
          setSpinerError={(value) => setSpinerError(value)}
          setSpinnerScreenText={(value) => setSpinnerScreenText(value)}
          devicesForActivate={devicesForActivate}
          toActivate={toActivate}
          onClose={() => setShowAcivateDialog(false)}
        />
        <InactivateDeiveDialog
          showInAcivateDialog={showInAcivateDialog}
          onsuccess={() => loadDataPlan()}
          setSpinerActve={(value) => setSpiner(value)}
          spinerActve={spinerActve}
          setTextSpinnerOne={(value) => setTextSpinnerOne(value)}
          setSpinerError={(value) => setSpinerError(value)}
          setSpinnerScreenText={(value) => setSpinnerScreenText(value)}
          devicesForInActivate={devicesForInActivate}
          toInActivate={toInActivate}
          onClose={() => setShowInAcivateDialog(false)}
        />
      </React.Fragment>
    </>
  );
};

export default DataPlan;
