import React, { useEffect, useState } from "react";
// import info_icon from "../../assets/images/info_icon.svg";
import ErrorDialog from "../LocationHistory/ErrorDialog";
import FlavorImages from './../../assetsFolderImport';

const NotificationBannerRow = (props) => {
  return (
    <React.Fragment>
      <div
        onClick={() => props.showTermofService(props.message.id)}
        className={`device_row cursor-pointer pl-0 pb-0 pt-3`} style={{ paddingRight: "7.5px" }}>
        <div className="col-12 cursor-pointer px-0">
          <div className="device_row_small_select cursor-pointer pb-0">
            <div
              className={`card cursor-pointer border-0 border_radius_0_mobile height_60 justify-content-center`}
            >
              <div className="d-flex ">
                <img
                  src={FlavorImages.info_icon}
                  className="home_title_icon ml-3 cursor-pointer"
                  alt="..."
                />
                <div className="pt-1 widhthheigth_notification_banner">
                  <p className="text-12-500 ml-2 text_ellipsis ">{props.message.header}</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <ErrorDialog
      // showErrorDialog={errorDialog}
      // select={"locationhistory"}
      // onClose={() => setShowErrorDialog(false)}
      />
    </React.Fragment>
  );
};

export default NotificationBannerRow;
