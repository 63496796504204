import { createSlice } from "@reduxjs/toolkit";
import { subDays } from 'date-fns'
const initialState = {
  activeDevice: null,
  activestatistics: false,
  end: new Date(),
  start: subDays(new Date(), 1),
  isHistoryInfromation: false,
  deviceHistoryData: false,
  locationPage: "locationHistory",
  routeIdstrip: false,
  locationHistoryTime: '',
  selectedDevice: 0,
  startEndDateLocationHistoryDate: false
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const locationHistorySlice = createSlice({
  name: "locationHistory",
  initialState: initialState,
  reducers: {
    setActiveDevice: (state, action) => {
      state.activeDevice = action.payload.history;
    },
    setActivestatistics: (state, action) => {
      state.activestatistics = action.payload.activestatistics
    },
    setisHistoryInfromation: (state, action) => {
      state.isHistoryInfromation = action.payload.isHistoryInfromation
    },
    setdeviceHistoryData: (state, action) => {
      state.deviceHistoryData = action.payload.deviceHistoryData
    },
    setStartEndDateLocationHistory: (state, action) => {
      state.startEndDateLocationHistoryDate = action.payload.startEndDateLocationHistoryDate
    },

    setDataPoint: (state, action) => {
      state.dataPoint = action.payload.dataPoint
    },
    setlocationHistoryTime: (state, action) => {
      state.locationHistoryTime = action.payload.locationHistoryTime
    },
    locationHistoryPage: (state, action) => {
      state.locationPage = action.payload.locationPage
    },
    setSelectedDevice: (state, action) => {
      state.selectedDevice = action.payload.selectedDevice
    },
    setRouteIdStrip: (state, action) => {
      state.routeIdstrip = action.payload.routeIdstrip
    },
    setStart: (state, action) => {
      state.start = action.payload.start
    },
    setEnd: (state, action) => {
      state.end = action.payload.end
    },
    resetLocationState: (state, action) => {
      state.isHistoryInfromation = false
      state.activeDevice = null
      state.startEndDateLocationHistoryDate = false
      state.deviceHistoryData = false
    }
  },
});
