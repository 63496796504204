
import _ from "lodash";
import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// import close_gray from "../../assets/images/close_gray.svg";
import * as genericService from "../../services/genericService";
import { useDispatch } from "react-redux";
import { devicesSlice } from "../../slice/deviceSlice";
import toast, { Toaster } from 'react-hot-toast';
import FlavorImages from "../../assetsFolderImport";

const deviceActions = devicesSlice.actions;
const AddBike = (props) => {
  const { t } = useTranslation();
  const [bikeName, setBikeName] = useState("");
  const [BikeVin, setBikeVin] = useState("");
  const dispatch = useDispatch();
  const BikeName = (value) => {
    setBikeName(value);
  };
  const vinNumber = (value) => {
    setBikeVin(value);
  };
  const getDevicesList = async () => {
    const response = await genericService.get("devices/compactinfo");
    if (response.status === 200) {
      const devices = response.data.devices ?? [];
      var MyBikes = [];
      dispatch(
        deviceActions.setUpDeviceData({
          devices: devices
        })
      );
      devices.map((device, index) => {
        if (device.sharedstatus.sharedwithme !== true) {
          MyBikes.push(device);
        }
      });
      dispatch(
        deviceActions.setUpFilteredDeviceData({
          devices: MyBikes
        })
      );
    }
  };
  const addBike = async () => {
    let imeiNumber = _.cloneDeep(BikeVin)
    let splitValue = imeiNumber.split(" ").join("");
    let imeiNo = parseInt(splitValue)
    let vinNo = splitValue

    var data = process.env.REACT_APP_FLAVOR == "Bike" ?
    {
      vinnumber: vinNo,
      name: bikeName,
    }
    :
    {
      imeinumber: imeiNo,
      name: bikeName,
    }
    const response = await genericService.post("/device/create", data);
    if (response.status === 200 && response.data.successful) {
      props.closeAddBikeDialog()
      props.loadDevices();
      getDevicesList()
      toast.success(t("toast.Added"));
    } else {
      props.setShowErrorDialog();
      props.setErrorMesage(response.data.message)
      props.closeAddBikeDialog()
    }
  };
  

  return (
    <>
      <Dialog open={props.show}>
        <Card className="card" style={{ width: "500px" }}>
          <div
            className="dialog_top_sort_row py-1 card-header"
            style={{ backgroundColor: "white" }}
          >
            <label className="card-title ml-4 text_22_700"> {t("Device.AddDevice")}</label>
            <img
              src={FlavorImages.close_gray}
              className="cursor-pointer"
              onClick={() => props.closeAddBikeDialog()}
              alt="..."
            />
          </div>
          <div className="card-body py-0">
            <div className="row">
              <div className="col-12">
                <div className="form-group col-12 mt-2">
                  <label
                    className="  text-black1 text_11_700"
                    data-success="right"
                  >
                    {t("Device.deviceName")}
                  </label>
                  <input
                    type="text"
                    onChange={(e) => BikeName(e.target.value)}
                    style={{ background: "#DDDDDD" }}
                    className={`form-control`}
                  />
                  <div className="invalid-feedback bottom">
                  </div>
                </div>
                <div className="form-group col-12 mt-2">
                  <label
                    className=" text-black1 text_11_700"

                    data-success="right"
                  >
                    {process.env.REACT_APP_FLAVOR === "Bike"
                      ? t("Device.VIN")
                      : t("Device.IMEI")
                    }
                  </label>
                  <input
                    type="text"
                    onChange={(e) => vinNumber(e.target.value)}
                    style={{ background: "#DDDDDD" }}
                    className={`form-control`}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="m-0 card-footer  d-flex justify-content-end">
            <button
              className="btn text-12-700 py-2  text-grey"
              onClick={() => props.closeAddBikeDialog()}
              variant="light"
            >
              {t("actionDialog.cancel")}
            </button>
            <button
              className="btn btn_navy bg_dark_helkama text-white"
              onClick={() => addBike()}
              id="next"
            >
              {t("Device.AddDevice")}
            </button>
          </div>
        </Card>
      </Dialog>
    </>
  );
};

export default AddBike;
