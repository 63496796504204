import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import i18n from "i18next";
import { INDEX_URL, SITE_TITLE } from "../../helpers/constants";
import { seo } from "../../helpers/seo";
import ReactHtmlParser from "react-html-parser";
import { registerSlice } from "../../slice/registerSlice";
import * as genericService from "../../services/genericService";
import * as helper_functions from "../../helpers/helper_functions";


// import backward_arrow_grey from "../../assets/images/backward_arrow_grey.svg";
import FlavorImages from "../../assetsFolderImport";

let width = window.innerWidth;  // use only when no imapact while changing screen from web to mobile

const registerState = registerSlice.actions;

const TermsOfUse = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [updateDate, setUpdateDate] = useState("");
  const [terms, setTerms] = useState("");
  const getTerms = async () => {
    const data = {
      language: getLangValue(),
    };
    const response = await genericService.post("account/gettermsofuse", data);
    if (response.status === 200) {
      const termsOfUse = response.data.termsofuse;
      setUpdateDate(helper_functions.default.formatDateTime(
        termsOfUse.lastupdated, "yyyy/MM/dd HH:mm"
      )); 
      var formatted;
      formatted = termsOfUse.body.replace(/(?:\r\n|\r|\n)/g, '<br>');
      setTerms(formatted);
    }
  };

  const getLangValue = () => {
    const lang = navigator.language.split("-");
    var activeLanguage = i18n.language;
    var splitText = i18n.language.split("-");

    if (i18n.options.whitelist.includes(lang[0])) {
      activeLanguage = splitText[0];
    } else {
      activeLanguage = "en";
    }

    return activeLanguage;
  };

  const on_load = () => {
    getTerms();
    seo({
      title: SITE_TITLE,
      metaDescription: "",
    });
  };

  useEffect(() => {
    on_load();
  }, []);

  const Accepted = () => {
    dispatch(registerState.setAgree({ agree: true }));

    history.goBack();
  };

  const convertedText = () => {
		return {__html: terms};
	};

  return (
    <React.Fragment>
      <div className="col-12 p-4 vh-100" id="login-page">
        <div className="row pt-2">
         <div className="btn_back rounded_white cursor-pointer justify-content-center align-items-center ml-3"
              style={{ width: "35px", height: "35px" }}
              onClick={() => window.history.back()}
            >
          <img src={FlavorImages.backward_arrow_grey} className="backward_button cursor-pointer" alt="..." />
        </div>
        </div>
        <div className="container-fluid p-0">
        {/* <img
              src={`${INDEX_URL}images/logo_${process.env.REACT_APP_FLAVOR}.svg`}
              className="img-fluid  d-none d-sm-block photo tosimg"
              alt="..."
            /> */}
          <div className="col-sm-12 col-md-6 offset-md-3 col-lg-8 offset-lg-2 pt-4 align-items-center px-0">
              <div className="col-12 px-0">
                <label htmlFor="Email" className="text_28_700 col-form-label font_700_italic_gloria">
                  {ReactHtmlParser(t("signup.TermsOfService"))}
                </label>
              </div>

              <div className="col-12 px-0 mb-3">
                <label htmlFor="Email" className="text_16_400 col-form-label">
                  {updateDate}{" "}
                </label>
              </div>
            <div className={`${width > 786 ? "scroll_div_12" : "scroll_div_tos_mobile"} px-0 w-100 tosstyle`} >

              <div className={`${width > 786 ? "pl-5 pr-5" : "pl-3 pr-3" } col-12 px-0  tostext`}>
              <div dangerouslySetInnerHTML={convertedText()}/>
                {/* <label htmlFor="Email" className="text_16_400 col-form-label">
                  {terms}
                </label> */}
              </div>

              <div className="col-12 d-sm-none background_black_helkama d-flex stickbottom pl-4 no-gutters row justify-content-center">
                <button
                  type="submit"
                  className="btn_login  w-75"
                  id="loginSubmit"
                  onClick={() => Accepted()}
                >
                  {ReactHtmlParser(t("signup.Accept"))}
                </button>
              </div>
              </div>
              {width > 786 ? (
              <div className="col-12 d-none d-flex pl-4 my-4 no-gutters row justify-content-center">
                <button
                  type="submit"
                  className="btn_login background_black_helkama  w-25 font_700_italic_gloria"
                  id="loginSubmit"
                  onClick={() => Accepted()}
                >
                  {ReactHtmlParser(t("signup.Accept"))}
                </button>
            </div>
            ) : ""
          }
              
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TermsOfUse;
