import { format } from "date-fns";
import { useTranslation } from "react-i18next";

const helper_functions = {
  async setLocalStorageValue(variable, value) {
    //settimeout is for testing purposes
    localStorage.setItem(
      process.env.REACT_APP_LOCAL_STORAGE_VARIABLE + variable,
      value
    );
  },

  async removeLocalStorageValue(variable) {
    localStorage.removeItem(
      process.env.REACT_APP_LOCAL_STORAGE_VARIABLE + variable
    );
  },

  async getLocalStorageValue(variable) {
    return localStorage.getItem(
      process.env.REACT_APP_LOCAL_STORAGE_VARIABLE + variable
    );
  },
  async getActiveLanguageValue(variable) {
    return localStorage.getItem(variable);
  },
  getSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  },




    sortFunctionWIthOrder(prop,sortOrder) {
    if(sortOrder==='ASC'){
    return function (a, b) {

 
     if(prop==='battery'){

      if(!a['modelspecificinfo'] || !b['modelspecificinfo'])
      {
        return 0
      }
       return +a['modelspecificinfo']['internalbattery']['internalbatteryinfo'] - b['modelspecificinfo']['internalbattery']['internalbatteryinfo'];
  }
  
return (a[prop] || b[prop]) ? (!a[prop] ? -1 : !b[prop] ? 1 : a[prop].localeCompare(b[prop])) : 0;

    };
  }
  else if(sortOrder==="DSC"){
    
     return function (a, b) {

     
 
  if(prop==='battery'){
    if(!a['modelspecificinfo'] || !b['modelspecificinfo'])
      {
        return 0
      }
     return -a['modelspecificinfo']['internalbattery']['internalbatteryinfo'] + b['modelspecificinfo']['internalbattery']['internalbatteryinfo'];
    
  }
    return (a[prop] || b[prop]) ? (!a[prop] ? 1 : !b[prop] ? -1 : b[prop].localeCompare(a[prop])) : 0;
   

    };
    
  }
  },
  formatDateTime(datetime, dateFormat = "yyyy/MM/dd HH:ii") {
    return format(new Date(datetime), dateFormat);
  },
  formatISODate(datetime, dateFormat = "yyyy-MM-dd HH:mm:ss") {
    return format(new Date(datetime), dateFormat);
  },
  formatDateMonthTime(datetime, dateFormat = "dd/MM HH:ii") {
    return format(new Date(datetime), dateFormat);
  },
  formatTime(datetime, dateFormat = "HH:ii") {
    return format(new Date(datetime), dateFormat);
  },
  formatDate(datetime, dateFormat = "yyyy/MM/dd") {
    return format(new Date(datetime), dateFormat);
  },
  formatDatedashFormate(datetime, dateFormat = "yyyy-MM-dd") {
    return format(new Date(datetime), dateFormat);
  },
  getCurrentDate(dateFormat = "yyyy/MM/dd HH:ii") {
    return format(new Date(), dateFormat);
  },
  //using moment// time zone is not supportable by datefns///
  formateIsoTime(datetime ,dateFormat = "HH:mm") {
    const slicetime = new Date(datetime.slice(0, -1));
    return format(new Date(slicetime), dateFormat);
  },
  formatIsoDateTime(datetime, dateFormat = "yyyy/MM/dd HH:mm") {
    const slicetime = new Date(datetime.slice(0, -1));
    return format(new Date(slicetime), dateFormat);
  },
  formatIsoDateSlashFormate(datetime, dateFormat = "yyyy/MM/dd") {
    const slicetime = new Date(datetime.slice(0, -1));
    return format(new Date(slicetime), dateFormat);
  },
  formatTime(minutes, t) {
    const days = Math.floor(minutes / (60 * 24));
    const hours = Math.floor((minutes % (60 * 24)) / 60);
    const remainingMinutes = Math.floor(minutes % 60);

    let result = "";
    if (days > 0) {
      result += `${days} d `;
    }
    if (days > 0 || hours > 0) { // Display hours if there are any days
      result += `${hours} h `;
    }
    
    // If there are only minutes
    if (days === 0 && hours === 0) {
      result += `${remainingMinutes} ${t('LocationHistory.minutes')}`;
    }
    // If there are days or hours, show minutes with m
    else if (remainingMinutes > 0) {
      result += `${remainingMinutes} m`;
    }

    return result.trim(); // Remove any trailing whitespace
},
  fallbackCopyTextToClipboard(text, stateMethod) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      if (successful) {
        stateMethod(true);
      }
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  },

  copyToClipboard(copyText, stateMethod) {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(copyText, stateMethod);
      return;
    }
    navigator.clipboard.writeText(copyText).then(
      () => {
        stateMethod(true);
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  },

  downloadFileFromURL(fileURL) {
    const link = document.createElement("a");
    link.target = "_blank";
    link.href = fileURL;
    link.setAttribute("download", true); //or any other extension
    document.body.appendChild(link);
    link.click();
  },

  //dates should be unixtimestamp
  // date1 should be futureDate
  timePassedInUnixtimestamp(unixtimestamp) {
    const record = {
      days: 0,
      hours: 0,
      minutes: 0,
    };

    //days
    let hours = 0;
    let minutes = 0;
    let days = Math.floor(unixtimestamp / 86400);
    record.days = days;
    //hours subtract seconds that are being passed in days and convert remainint to get hours and minutes
    let SecondsForHours = unixtimestamp - days * 86400;

    if (SecondsForHours > 0) {
      hours = Math.floor(SecondsForHours / 3600);
      record.hours = hours;
      //now subtract number of seconds that are being taken by hours
      let SecondsForMinutes = unixtimestamp - SecondsForHours * 3600;

      if (SecondsForMinutes > 0) {
        minutes = Math.ceil(SecondsForMinutes / 60);
        record.minutes = minutes;
      }
    }

    return record;
  },

  UtctoDateTimeConverter(date){
    let dateArray=date.split("T")
    let hourAndMinArray=dateArray[1].split(":")
    const convertedDate=dateArray[0]+" "+hourAndMinArray[0]+":"+hourAndMinArray[1]
    return convertedDate
 },

 calculatingDistance(latlong1,latlong2){
  let unit="K"
      if (latlong1 && latlong2) {
          let lat1=  latlong1.lat;
          let lon1= latlong1.lng;
          let lat2= latlong2.lat
          let lon2=latlong2.lng
            if ((lat1 == lat2) && (lon1 == lon2)) {
              return 0;
            }
          else {
          var radlat1 = Math.PI * lat1/180;
          var radlat2 = Math.PI * lat2/180;
          var theta = lon1-lon2;
          var radtheta = Math.PI * theta/180;
          var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
          if (dist > 1) {
              dist = 1;
          }
          dist = Math.acos(dist);
          dist = dist * 180/Math.PI;
          dist = dist * 60 * 1.1515;
          if (unit=="K") { dist = dist * 1.609344 }
          if (unit=="N") { dist = dist * 0.8684 }
          let finalValue = Math.ceil(dist)
          return finalValue
          }
        }
},
async distanceBetweenLatLong(latlongs){
//  console.log("lat longs ",latlongs)
 let distance=0

 if(latlongs.length>1){
 for(let i=0;i<latlongs.length-1;i++)
 {
   distance=distance +  this.calculatingDistance(latlongs[i],latlongs[i+1])
 }
}
else{
 distance=0
}
return distance  
}};

export default helper_functions;
