import React from "react";
// import backward_arrow_grey from "../../assets/images/backward_arrow_grey.svg";
import { useTranslation } from "react-i18next";
import FlavorImages from "../../assetsFolderImport";

const BackWardArrow = (props) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className={` row pt-2 cursor-pointer ${props.show ? "" : "d-md-none"}`}>
        <div
          className={`btn_back rounded_white cursor-pointer	${props.margin ? props.margin : ""} justify-content-center align-items-center ml-3`}
          style={{ width: "35px", height: "35px" }}
          onClick={() => props.onClick()}
        >
          <img src={FlavorImages.backward_arrow_grey} className="backward_button cursor-pointer" alt="..." />
        </div>
        {props.select === "DataPlan" ? (
          <label className="ml-3 pt-2 text-uppercase text_16_700 text-left">
            {t("DataPlan.DataPlaneFound")}
          </label>
        ) : props.sharedbyme === true ? (
          <label className="ml-3 pt-2 text-uppercase text_16_700 text-left">
            {t("DataPlan.DeleteShareBike")}
          </label>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
};

export default BackWardArrow;
