// import download from "../../assets/images/download.svg";
import FlavorImages from "../../assetsFolderImport";
import * as helper_functions from "../../helpers/helper_functions";
const WarrantyRow = (props) => {

  return (
    <>
      <div>
        <div
          className={`
          ${props.isFirst ? "rounded-top" : props.isLast ? "rounded-bottom" : ""
            }
           ${props.isLast ? "" : "border-bottom"}
           p-3  bg-white w-100  d-flex white_shadow  justify-content-between align-items-center"`}
        >
          <label className=" text_16_400 text-navy my-0">
            {props.warranty.header}
          </label>

          <img
            onClick={() =>
              helper_functions.default.downloadFileFromURL(
                props.warranty.pdfurl
              )
            }
            src={FlavorImages.download}
            className="size_15 cursor-pointer my-1"
            alt="..."
          />
        </div>
      </div>
    </>
  );
};

export default WarrantyRow;
