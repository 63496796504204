import _ from "lodash";
import React, { useEffect } from "react";
import StatististicsGraphLIveStream from "./StatististicsGraphLIveStream";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { livestreamSlice } from "../../slice/liveStreamSlice";
import * as genericService from "../../services/genericService";
import Map from "../Maps/Map";
import { useParams } from "react-router-dom";
import { useState } from "react";
const UrlScreen = () => {
	const dispatch = useDispatch();
	let { token } = useParams();
	const [streamName, setStreamName] = useState('')
	const [deviceName, setDeviceName] = useState('')
	const [streamTime, setStreamTime] = useState('')
	const [streamingDevices, setStreamingDevices] = useState('')
	const [dataOnceSet, setDataOnceSet] = useState(false)

	let lat = 0
	let lng = 0
	var location = []
	var mapCenter = []
	const liveStreamAction = livestreamSlice.actions;
	let latlong
	const { streamState } = useSelector(
		(state) => ({ streamState: state.liveStream }),
		shallowEqual
	);

	const getStreamDevices = async () => {


		// let points=[{lat:61.34354666666666,lng:20.032766666666667},{lat:62.34354666666666,lng:21.032766666666667},{lat:63.34354666666666,lng:22.032766666666667},{lat:64.34354666666666,lng:23.032766666666667},
		// {lat:70.34354666666666,lng:60.032766666666667},{lat:72.34354666666666,lng:71.032766666666667},{lat:73.34354666666666,lng:22.032766666666667},{lat:74.34354666666666,lng:73.032766666666667}]

		var streamgDevices = []
		const response = await genericService.post('streaminglive/public/', {
			streamtoken: token
		});
		if (response.status === 200) {
			const data = response.data[0];
			const date1 = new Date(data.starttime);
			const date2 = new Date(data.stoptime);
			const diffTime = Math.abs(date1 - date2);

			let hours = Math.floor(diffTime / (1000 * 60 * 60));
			setStreamTime(date2)
			setStreamName(data.streamname)
			if (streamState.streamDevice === '') {

				setDeviceName(data.devices[0].devicename)
				dispatch((liveStreamAction.setStreamDeviceData({ streamDeviceData: data.devices[0] })))
				data.devices[0].points.forEach((point) => {
					location.push({
						lat: parseFloat(point.latitude),
						lng: parseFloat(point.longitude)
					})
				})
				latlong = {
					lat: data.devices[0].points[0].latitude,
					lng: data.devices[0].points[0].longitude
				}
				mapCenter = []
				mapCenter.push(latlong)
				dispatch(liveStreamAction.setLocationLiveStream({ locationLiveStream: location }))

			}
			else {
				let foundDevice = data.devices.find((device) => device.id === streamState.streamDevice)
				if (foundDevice) {
					latlong = { lat: foundDevice.points[0].latitude, lng: foundDevice.points[0].longitude }
				}
				mapCenter = []
				mapCenter.push(latlong)
			}
			data.devices.forEach((device) => {
				streamgDevices.push(device)
			})

			setStreamingDevices(streamgDevices)
		}
		dispatch(liveStreamAction.setMapCenter({ streamingMapCenter: mapCenter }))
	}

	const loadStream = async (points) => {
		location = []
		const response = await genericService.post('streaminglive/public/', {
			streamtoken: token
		});
		if (response.status === 200) {
			const data = response.data[0]
			if (!streamState.streamDevice) {
				setDataOnceSet(true)
			}
			let foundDevice = data.devices.find((device) => device.id === streamState.streamDevice)
			if (foundDevice) {
				foundDevice.points.forEach((point) => {
					location.push({
						lat: parseFloat(point.latitude),
						lng: parseFloat(point.longitude)
					})
				})
				latlong = [{ lat: foundDevice.points[0].latitude, lng: foundDevice.points[0].longitude }]
			}
			if (latlong) {
				dispatch(liveStreamAction.setMapCenter({ streamingMapCenter: latlong }))
			}
			dispatch(liveStreamAction.setLocationLiveStream({ locationLiveStream: location }))

		}
	}

	useEffect(() => {
		let points = [{ lat: 61.34354666666666, lng: 20.032766666666667 }, { lat: 62.34354666666666, lng: 21.032766666666667 }, { lat: 63.34354666666666, lng: 22.032766666666667 }, { lat: 64.34354666666666, lng: 23.032766666666667 },
		{ lat: 70.34354666666666, lng: 60.032766666666667 }, { lat: 72.34354666666666, lng: 71.032766666666667 }, { lat: 73.34354666666666, lng: 22.032766666666667 }, { lat: 74.34354666666666, lng: 73.032766666666667 }]
		if (!streamingDevices.length) {
			getStreamDevices();
		}
		const interval = setInterval(() => {
			loadStream(points)
		}, 5000);
		return () => {
			clearInterval(interval);
		};
	}, [streamingDevices])
	return (
		<>
			<React.Fragment>
				<section className="section-home " id="login-page">
					<div className="row p-0 m-0 justify-content-center">
						<h1
							className="text_40_1000 text-uppercase mt-4"
							style={{ zIndex: "99999" }}>helkama</h1>

						<Map
							page="urlscreen"
						/>
						{deviceName ? <StatististicsGraphLIveStream
							setDeviceName={(deviceName) => setDeviceName(deviceName)}
							getStreamDevices={() => getStreamDevices()}
							screen={"urlscreen"}
							streamName={streamName}
							streamgDevices={streamingDevices}
							deviceName={deviceName}
							streamTime={streamTime}
						/> : ""}

					</div>
				</section>
			</React.Fragment>
		</>
	);
};

export default UrlScreen;
