import React, { useEffect, useState } from "react";
// import cross from "../../assets/images/cross.svg";
// import tick from "../../assets/images/tick.svg";
import { INDEX_URL } from "../../helpers/constants";
import _ from "lodash";
import FlavorImages from "../../assetsFolderImport";
const DeviceCheckList = (props) => {
  const [fold, setFold] = useState("");
  const Toggle = () => {
    if (props.isSelection === true) props.onClick();
  };
  useEffect(() => {
    setFold(props.device.mapicon?.split("_")[1]);
  }, [])
  return (
    <React.Fragment>
      <div className="device_row  ">
        <div className="device_row_small px-3" onClick={() => Toggle()}>
          <div className="d-flex justify-content-between" >
            <img
              src={props.device.mapicon === 'default_blue' ? 
              `${INDEX_URL}images/device_icons/map_icons/blue_pins/svg/default_blue.svg` : 
              `${INDEX_URL}images/device_icons/map_icons/${fold}_pins/svg/${props.device.mapicon}.svg`}
              className="signal_icon" alt="..."
            />
            <label className="text_16_400 mb-0 ml-3">
              {props.device.name}
            </label>
          </div>
          <div className={`d-flex align-items-center justify-content-center
            ${props.selected || props.device.isSelected ? "bg-online" : "bg-White_mobile"} choicediv ml-auto `}
            onClick={() => props.setChooseAll(false)}
          >
            <img
              src={props.selected || props.device.isSelected ? FlavorImages.tick : FlavorImages.cross}
              className="" alt="..."
            />
          </div>

        </div>
      </div>
    </React.Fragment>
  );
};

export default DeviceCheckList;
