// import forward_arrow_grey from "../../assets/images/forward_arrow_grey.svg";
import FlavorImages from "../../assetsFolderImport";
const BikeRow = (props) => {
  return (
    <>
      <div>
        <div
          onClick={() => { props.setDeviceName(props.device.name); props.selectBikeDetail() }}
          className={` cursor-pointer
          ${props.isFirst ? "rounded-top" : props.isLast ? "rounded-bottom" : ""
            }
                     ${props.isLast ? "" : "border-bottom"} 

           p-3 d-flex  white_shadow justify-content-between align-items-center bg-white w-100  `}
        >
          <label className=" text_16_400 text-navy my-0">
            {props.device.name}
          </label>

          <img
            onClick={() => props.selectBikeDetail()}
            src={FlavorImages.forward_arrow_grey}
            className="size_15"
            alt="..."
          />
        </div>
      </div>
    </>
  );
};

export default BikeRow;
