/*
 * @Author: Abhi Bhatt
 * @Date:   2020-12-23 14:12:09
 * @Last Modified by:   abhi
 * @Last Modified time: 2021-04-14 12:41:01
 */
import React, { useEffect } from "react";
// import testfornews from "../../assets/images/testfornews.svg"
import BackWardArrow from "../../components/Buttons/BackwardArrow";
import { useTranslation } from "react-i18next";
// import facebook_active from "../../assets/images/facebook_active.svg";
import { INDEX_URL } from "../../helpers/constants";
// import envelope_active from "../../assets/images/envelope_active.svg";
// import twitter_active from "../../assets/images/twitter_active.svg";
// import Ellipse_sky_blue from "../../assets/images/Ellipse_sky_blue.svg";
import SideBarNew from "../../components/SideBar/SideBarNew";
import { useHistory } from "react-router-dom";
import * as newsJson from "../mocks/news";
import { useState } from "react";
import { useParams } from "react-router-dom";
import * as genericService from '../../services/genericService';
import { useSelector, shallowEqual } from "react-redux";
import * as helper_functions from "../../helpers/helper_functions";
import FlavorImages from "../../assetsFolderImport";


const NewsDetail = (props) => {
	const { t } = useTranslation();
	const [newsSelected, setNewsSelected] = useState("")
	let { id } = useParams();
	const history = useHistory();
	const { authState } = useSelector(
		(state) => ({ authState: state.auth }),
		shallowEqual
	)
	const getData = async () => {
		const newReponse = await genericService.post("news/newslist", { language: authState.activeLanguage });
		newReponse.data.news.forEach(news => {
			if (news.newsid == id) {
				setNewsSelected(news)
			}
		});
	};
	const setNewPage = () => {
		history.push(INDEX_URL + "news")
	}
	useEffect(() => {
		getData();
	}, [])
	//(newsSelected.newsdate);
	return (
		<React.Fragment>
			<section className="section-home " id="login-page">
				<div className="middle-content d-flex justify-content-between h-100">
					<SideBarNew
						page="news"
						onClick={(e) => {
							history.push(INDEX_URL + e);
						}}
					/>
					<div className={`w-100 ml-4 pl-md-3 pl-0`}>

						<div
							className=" p-0  news_gradient rounded news_height"
							style={{ backgroundImage: `url(${newsSelected.newsimage})` }}
						>
							<div className="row">
								<div className="col align-items-center ml-4 mt-2">
									<img
										src={
											FlavorImages.news_inactive
										}
										className="home_title_icon" alt="..." />
									<label className="text_34_700 text-white mb-0 ml-3 ">
										{t("News.News")}
									</label>
								</div>
							</div>
							<div className="position-relative  p-0 news_gradient news_height rounded">
								<div className=" w-100 d-flex flex-column ml-4   bottom_div ">
									<BackWardArrow
										show={true}
										onClick={() => setNewPage()} />
								</div>
							</div>
						</div>
						<div className="section_text_news ml-2">
							<div className="row">
								<div className="col d-flex">
									<label className="text_34_700 mb-0 ml-3 ">
										{newsSelected.newstitle}
									</label>

								</div>

								{/* <div className="size_35 ml-2 picContainer">
									<img
									src={facebook_active
									}
									className="size_35" alt="..." />
								</div>
								<div className="size_35 ml-2 picContainer">
									<img
										src={twitter_active
										}
										className="pic2 p-2 size_35 " alt="..." />
									<img
										src={Ellipse_sky_blue
										}
										className="size_35" alt="..." />
								</div>
								<div className="size_35 ml-2 picContainer">
									<img
										src={envelope_active
										}
										className="pic2 p-2 size_35 " alt="..." />
									<img
										src={Ellipse_sky_blue
										}
										className="size_35" alt="..." />
								</div> */}

							</div>
							<div className="row">
								<div className="col">
									<label className="text_12_700 ml-3">
										{newsSelected.newsdate &&
										helper_functions.default.formatDatedashFormate(
											newsSelected.newsdate, "yyyy-MM-dd"
										)}
			
									</label>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<label className="dialog_body_detail ml-3">
										{newsSelected.newsbody}
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</React.Fragment>


	);
};

export default NewsDetail;
