/*
 * @Author: SweTrack
 */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { registerSlice } from "../../slice/registerSlice";
// import dot_selected_helkam from "../../assets/Helkama_images/dot_selected_helkam.svg";
import { authSlice } from "../../slice/authSlice";
import { INDEX_URL, SITE_TITLE } from "../../helpers/constants";
import { seo } from "../../helpers/seo";
import * as signUpService2 from "../../services/user/signUpService2";
import * as accountService from "../../services/user/accountService2";
import ReactHtmlParser from "react-html-parser";
import LanguageChanger from "../../components/LanguageChanger/LanguageChanger";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
// import dot_selected from "../../assets/images/dot_selected.svg";
// import cross_red from "../../assets/images/cross_red.svg";
// import check_light_navy from "../../assets/images/check_light_navy.svg";
// import backward_arrow_grey from "../../assets/images/backward_arrow_grey.svg";
import FlavorImages from "../../assetsFolderImport";
let flavor = process.env.REACT_APP_FLAVOR; //when we have APIs for helkama; remove static value and use flavor from env



const authactions = authSlice.actions;
const registerActions = registerSlice.actions;
const SignUpVINNumber = () => {
  const { registerState } = useSelector(
    (state) => ({ registerState: state.register }),
    shallowEqual
  );
  const { authState } = useSelector(
    (state) => ({ authState: state.auth }),
    shallowEqual
  )
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [is_valid_vin, setIsValidVin] = useState(-1);
  const updatePicture = () => {

  }

  const clickNext = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      dispatch(
        registerActions.setStepVINNumberData({
          imeinumber: fields["VINNumber"],
        })
      );
      var data
      if (process.env.REACT_APP_FLAVOR === "Bike")
        data = {
          email: registerState.email,
          password: registerState.password,
          birthday: registerState.userData.birthday,
          gender: registerState.userData.gender,
          imageurl: registerState.userData.imageurl,
          username: registerState.username,
          weight: registerState.userData.weight && parseInt(registerState.userData.weight),
          vinnumber: [fields["VINNumber"]],
          language: authState.activeLanguage
        }
      else {
        data = {
          email: registerState.email,
          password: registerState.password,
          path: "",
          username: registerState.username,
          language: authState.activeLanguage,
          imeinumber: [fields["VINNumber"]],
        };
      }
      let response
      if (process.env.REACT_APP_FLAVOR === "Bike") {
        if (registerState.userData.imageurl) {
          let updatePictureResponse = await signUpService2.putPicture(registerState.userData.imageurl)
          if (updatePictureResponse.status == 200) {
            data["imageurl"] = updatePictureResponse.data.imageurl
            response = await signUpService2.register(data);
          }
        }
        else {
          response = await signUpService2.register(data);
        }
      }
      else {
        response = await signUpService2.register(data);
      }
      if (response.status === 802) {
        if (parseInt(response.data.errorCode) === 20025) {
          dispatch(registerActions.setStep({ step: "emailexist" }));
          history.push(INDEX_URL + "signup/emailexist");
        } else {
          const errors = {};
          errors["VINNumber"] = t(response.data.message);
          setIsValidVin(0);
          setErrors(errors);
        }
      } else if (response.status === 200) {
        let token = response.data.accessToken;
        //console.log("🚀 ~ file: SignUpVINNumber.js:110 ~ clickNext ~ token", token)
        dispatch(authactions.setAuthToken({ authToken: token }));
        const user_info_response = await accountService.getAccountInfo(token);
        if (user_info_response.status === 200) {
          dispatch(
            authactions.setAuthData({ authData: user_info_response.data })
          );
          setTimeout(() => {
            history.push(INDEX_URL + "devices");
          }, 1200);
        }
      }
    }
  };

  const handleValidation = () => {
    let fields_temp = _.cloneDeep(fields);
    let errors = {};
    let formIsValid = true;

    if (!fields_temp["VINNumber"]) {
      formIsValid = false;
      errors["VINNumber"] = t("errors.EnterVINNumber");
      setIsValidVin(0);
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleChange = (field, e) => {
    let fields_temp = _.cloneDeep(fields);
    let errors = {};
    fields_temp[field] = e.target.value;

    if (fields_temp.VINNumber !== "" && fields_temp.VINNumber !== undefined) {
      setIsValidVin(1);
    } else {
      errors["VINNumber"] = "";
      setIsValidVin(-1);
    }

    setFields(fields_temp);
    setErrors(errors);
  };

  const validatePrefilledData = () => {
    const fields_to_validate = ["email", "password", "username"];
    for (const field of fields_to_validate) {
      if (!registerState[field]) {
        history.push(INDEX_URL + "signup");
        break;
      }
    }
  };

  const on_load = () => {
    validatePrefilledData();
    seo({
      title: SITE_TITLE,
      metaDescription: "",
    });
  };

  useEffect(() => {
    on_load();
  }, []);

  const emptyVINNumber = () => {
    setFields({ ...fields, VINNumber: "" });
    setIsValidVin(-1);
  };

  return (
    <>
      <React.Fragment>
        <div className="col-12 p-4 h-100" id="login-page">
                <div className="row pt-2">
                    <div
                      className="btn_back rounded_white cursor-pointer justify-content-center align-items-center ml-3"
                      style={{ width: "35px", height: "35px" }}
                      onClick={() => window.history.back()}
                    >
                      <img src={FlavorImages.backward_arrow_grey} className="backward_button cursor-pointer" alt="..." />
                    </div>
                </div>
          <div className="container-fluid p-0">
            <div className="col-md-6 offset-md-3 col-lg-4 offset-lg-4 pt-4 align-items-center">
              <img
                src={FlavorImages.app_logo}
                className="img-fluid mx-auto d-none d-sm-block photo"
                alt="..."
              />
              <div className="col-12 mt-5 pt-5">
                <div 
                className="col-12 d-flex align-items-center px-0"
                style={{ left: "-32px" }}
                >
                  <label className="text_34_700  font_700_italic_gloria col-form-label ">
                    { flavor === "Bike" ? "4. " + ReactHtmlParser(t("signup.VINNumber")) : "4. " + ReactHtmlParser(t("signup.IMEI")) }
                  </label>
                </div>

                <div className="col-12 font_400_italic_gloria  p-0">
                  <label className="text_16_400 font_400_italic_gloria text-navy col-form-label">
                  {flavor === "Bike" ? ReactHtmlParser(t("signup.VINNumberSubTitle")) : ReactHtmlParser(t("signup.IMEINumberSubTitle")) }
                  </label>
                </div>

                <div className="col-12 inputBlock  col-sm-12  p-0">
                  <TextField
                    value={fields["VINNumber"]}
                    className="col-12 Mui-focused label-input-bold"
                    id="VINNumber"
                    label={flavor === "Bike" ? ReactHtmlParser(t("signup.VINNumber")) : ReactHtmlParser(t("signup.IMEI")) }
                    variant="standard"
                    placeholder=""
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {is_valid_vin === 0 ? (
                            <img
                              src={FlavorImages.cross_red}
                              className="text-red input-icon"
                              onClick={() => {
                                emptyVINNumber();
                              }}
                            />
                          ) : is_valid_vin === 1 ? (
                            <img
                              src={FlavorImages.check_light_navy}
                              className="nput-icon"
                            ></img>
                          ) : (
                            ""
                          )}
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleChange("VINNumber", e);
                    }}
                    defaultValue={fields["VINNumber"]}
                  />

                  <span></span>
                  {typeof errors["VINNumber"] !== "undefined" &&
                    errors["VINNumber"] !== "" ? (
                    <span className="invalid-feedback font_400_gloria bottom">
                      {errors["VINNumber"]}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div
                  className="px-0 p-0 col-12  no-gutters  p-0"
                  style={{ marginTop: "130px" }}
                >
                  <div className="col-sm-12   ">
                    <button
                      type="submit"
                      className="btn_login font_700_italic_gloria background_black_helkama"
                      id="loginSubmit"
                      onClick={(e) => clickNext(e)}
                    >
                      {ReactHtmlParser(t("signup.CreateAccount"))}
                    </button>
                  </div>
                </div>

                <div className="p-0 mt-4 row col-12 justify-content-center align-items-center mx-0">
                  <img
                    alt="Step"
                    className="dot"
                    src={FlavorImages.dot_selected}

                    onClick={history.goBack}
                  />
                  <img
                    alt="Step"
                    className="dot ml-2"
                    src={FlavorImages.dot_selected}

                    onClick={history.goBack}
                  />
                  <img
                    alt="Step"
                    className="dot ml-2"
                    src={FlavorImages.dot_selected}

                    onClick={history.goBack}
                  />

                  <img alt="Step" className="dot ml-2" src={FlavorImages.dot_selected}
                  />
                </div>
              </div>
            </div>
            <LanguageChanger />
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default SignUpVINNumber;
