import React from "react";
const NameButton = (props) => {
  return (
    <React.Fragment>
      <div
        className="search_capsule cursor-pointer"
        onClick={() => props.onSelect()}
        style={
          props.selected === true
            ? {
              whiteSpace: "nowrap",
              width: "fit-content",
              paddingRight: "4px",
              paddingLeft: "4px",
              marginLeft: "5px",
              marginRight: "5px",
            }
            : {
              whiteSpace: "nowrap",
              background: "#DDDDDD",
              maxWidth: "fit-content",
              paddingRight: "4px",
              paddingLeft: "4px",
              marginLeft: "5px",
              marginRight: "5px",
            }
        }
      >
        <label className="cursor-pointer" style={{ marginLeft: "0px", marginRight: "0px" }}>
          {props.name}
        </label>
      </div>
    </React.Fragment>
  );
};

export default NameButton;
