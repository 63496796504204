import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { shallowEqual, useSelector } from "react-redux";
import React from "react";
import { useTranslation } from "react-i18next";
// import check from "../../assets/images/check.svg";
// import close_gray from "../../assets/images/close_gray.svg";
import { useDispatch } from "react-redux";
import { devicesSlice } from "../../slice/deviceSlice";
import FlavorImages from "../../assetsFolderImport";
const deviceActions = devicesSlice.actions;
const SortrouteDialog = (props) => {
  const { sortState } = useSelector(
    (state) => ({ sortState: state.devices }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleChange = (newsort) => {
    if (newsort === sortState.sort) {
      dispatch(
        deviceActions.setSort({
          sort: "",
        })
      );
    } else {
      dispatch(
        deviceActions.setSort({
          sort: newsort,
        })
      );
    }
  };

  const renderCheckImage = (status) => {
    return (
      <img
        className={sortState.sort === status ? "remove-check" : "set-check"}
        src={FlavorImages.check}
        alt="..."
      />
    );
  };

  return (
    <>
      <div
        id="collapseOne"
        className="collapse mt-1"
        style={{ width: "300px" }}
        data-parent="#accordionone">
        <Card className={`py-0 px-1 ${props.deviceType === "mobile" ? "width_250" : "width_300"}`}>
          <div className="dialog_top_sort_row p-1 border-bottom">
            <label className="text_17_700">
              {t("Routes.SortRouteBy")}
            </label>
            <img src={FlavorImages.close_gray} className="size_20 right_7 position-absolute cursor-pointer"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne" alt="..." />
          </div>
          <ul className="list-group list-group-flush ">
            <li
              onClick={() => {
                handleChange("starttime");
              }}
              className="cursor-pointer py-md-1 py-0 list-group-item d-flex justify-content-between align-items-center"
            >
              <p
                className={`my-2 text_12_700 ${sortState.sort === "starttime" ? "text-navy" : "text-gray"
                  }`}
              >
                {t("Date")}
              </p>

              {renderCheckImage("starttime")}
            </li>

            <li
              onClick={() => {
                handleChange("devicename");
              }}
              className="cursor-pointer py-md-1 py-0 list-group-item d-flex justify-content-between align-items-center"
            >
              <p
                className={`my-2 text_12_700 ${sortState.sort === "devicename" ? "text-navy" : "text-gray"
                  }`}
              >
                {t("Device ")}
              </p>

              {renderCheckImage("devicename")}
            </li>
            <li
              onClick={() => {
                handleChange("routename");
              }}
              className="cursor-pointer py-md-1 py-0 list-group-item d-flex justify-content-between align-items-center"
            >
              <p
                className={`my-2 text_12_700 ${sortState.sort === "routename" ? "text-navy" : "text-gray"
                  }`}
              >
                {t("Name")}
              </p>
              {renderCheckImage("routename")}
            </li>
          </ul>
          <div className=" d-flex justify-content-center mb-2">
            <button
              onClick={() => props.onClose()}
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            className="btn-sm border-0 bg-grey2 py-1 px-4 text-grey text_12_700 "

            >
              {t("button.Cancel")}
            </button>
            <button
              onClick={() => props.sortRouteArray()}
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              className="btn-sm border-0 py-1 px-4 bg-navy bg_dark_helkama text-white ml-3 text_12_700"
              id="next"
            >
              {t("button.Select")}
            </button>
          </div>
        </Card>
      </div>
    </>
  );
};
export default SortrouteDialog;
