import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ScrollMenuName from "../../components/ScrollBar/ScrollMenuName";
import SearchControl from "../../components/Search/SearchControl";
import * as devicesService2 from "../../services/devices/devicesService2";
import DeviceRow from "../Device/DeviceRow";
import DeviceRowMobile from "../Device/DeviceRowMobile";
import { useHistory, useLocation } from "react-router-dom";
import * as helper_functions from "../../helpers/helper_functions";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ErrorDialog from "./ErrorDialog";
import * as genericService from "../../services/genericService";
import { locationHistorySlice } from "../../slice/locationHistorySlice";
import _ from "lodash";
import { devicesSlice } from "../../slice/deviceSlice";
// import forward_arrow_white from "../../assets/images/forward_arrow_white.svg";
import SortDevice from "../Device/SortDevice";
import { useParams } from "react-router-dom";
import { subDays } from 'date-fns'
import PopUp from "../Device/PopUp";
import LocationHistoryInformation from "./HistoryInformation";
import { INDEX_URL } from "../../helpers/constants";
import toast, { Toaster } from 'react-hot-toast';
import FlavorImages from "../../assetsFolderImport";

const locationHistoryActions = locationHistorySlice.actions;
const SearchLocationHistory = (props) => {
  const location = useLocation();
  let path = location.pathname
  let { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const deviceActions = devicesSlice.actions;
  const [searchKeys, addSearchKey] = useState([]);
  const [errorDialog, setShowErrorDialog] = useState(false)
  const [devices, setDevices] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [newBtn, setBtn] = useState("Select");
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(0);
  const [deviceisActive, setDeviceisActive] = useState(false);
  const [showSortDialog, setShowSortDialog] = useState(false);
  const [error, setError] = useState("");
  let routesLocations = process.env.REACT_APP_FLAVOR === "Bike"
  const loadDevices = async () => {
    const response = await devicesService2.getDevicesCompactInfo();
    if (response.status === 200) {
      const devices = response.data.devices ?? [];
      setDevices(devices);
      setFilteredDevices(devices);
      dispatch(
        deviceActions.setUpDeviceData({
          devices: devices,
        })
      );
      if (id) {
        let arrayTobePushed = []
        let keyFilter = id
        let keyTogetFilter = keyFilter.split(",")
        for (let i = 1; i < keyTogetFilter.length; i++) {
          arrayTobePushed.push(
            {
              "name": keyTogetFilter[i]
            }
          )
        }
        let items = []
        let SearchedItems = []
        addSearchKey(arrayTobePushed);
        arrayTobePushed.map((arr) => {
          return items.push(arr.name)
        })
        for (const item of items) {
          let temp = devices.filter((device) => {
            return device.name.toLowerCase().indexOf(item.toLowerCase()) > -1;
          });
          Array.prototype.push.apply(SearchedItems, temp);
        }
        dispatch(
          deviceActions.setUpFilteredDeviceData({
            devices: _.uniq(SearchedItems)
          })
        );
      }
      else {
        dispatch(
          deviceActions.setUpFilteredDeviceData({
            devices: devices,
          })
        );
      }
    }
  };
  const { devicesState } = useSelector(
    (state) => ({ devicesState: state.devices }),
    shallowEqual
  );
  const { locationHistorystate } = useSelector(
    (state) => ({ locationHistorystate: state.locationHistory }),
    shallowEqual
  );
  const on_load = () => {
    if (devicesState.locatoinHistoryDevice.length) {
      setShowSubmitButton(true)
      const results = _.cloneDeep(devicesState.locatoinHistoryDevice);
      dispatch(
        deviceActions.setUpFilteredDeviceData({
          devices: results
        })
      );
    }
    else {
      loadDevices();
    }
  };
  useEffect(() => {
    on_load();
    return () => {
      dispatch(
        deviceActions.setLocationHistroyDevices({
          locatoinHistoryDevice: [],
        })
      );
      dispatch(
        deviceActions.setGroupRowSettings({
          showgroupssetting: false,
        })
      );
    };
  }, []);

  const filterDevices = (array) => {
    if (!array.length) {
      const results = _.cloneDeep(devicesState.devices);
      results.sort(helper_functions.default.sortFunctionWIthOrder(devicesState.sort, devicesState.sortOrder));
      dispatch(
        deviceActions.setUpFilteredDeviceData({
          devices: results,
        })
      );
      return;
    }

    const items = [];
    const SearchedItems = [];
    array.map((arr) => items.push(arr.name));
    for (const item of items) {
      let temp = devices.filter((device) => {
        return device.name.toLowerCase().indexOf(item.toLowerCase()) > -1;
      });
      Array.prototype.push.apply(SearchedItems, temp);
    }
    for (const item of items) {
      let temp = devices.filter((device) => {
        if (device.groupname) {
          return device.groupname.toLowerCase().indexOf(item.toLowerCase()) > -1;
        }
      });
      Array.prototype.push.apply(SearchedItems, temp);
    }
    dispatch(
      deviceActions.setUpFilteredDeviceData({
        devices: _.uniq(SearchedItems),
      })
    );
  };

  const sortDevicesArray = () => {
    let deviceData = _.cloneDeep(devicesState.filteredDevices);
    deviceData.sort(helper_functions.default.sortFunctionWIthOrder(devicesState.sort, devicesState.sortOrder));
    dispatch(
      deviceActions.setUpFilteredDeviceData({
        devices: deviceData,
      })
    );
    setShowSortDialog(false);
  };
  const onKeyChange = (searchKey) => {
    if (!searchKey) {
      var array = [...searchKeys];
      filterDevices(array);
    }
    else {
      var searchObject = { name: searchKey };
      var array = [...searchKeys];
      array.push(searchObject);
      filterDevices(array)
    }
  }
  const OnSearchRemove = (index) => {
    var array = [...searchKeys];
    if (index !== -1) {

      addSearchKey(array);
      filterDevices(array);
      let searchKey = array[index]
      let path2
      if (searchKeys.length > 1)
        path2 = path.replace(`,${searchKey.name}`, '');
      else
        path2 = path.replace(`,${searchKey.name}`, '');


      array.splice(index, 1);
      addSearchKey(array);

      filterDevices(array);
      history.push(path2);
    }
  };
  const openSortDialog = () => {
    setShowSortDialog(true);
  };
  const OnClearAll = () => {
    const results = _.cloneDeep(devicesState.devices);
    var array = [...searchKeys];
    array.splice(0, array.length);
    addSearchKey(array);
    results.sort(helper_functions.default.sortFunctionWIthOrder(devicesState.sort, devicesState.sortOrder));
    dispatch(
      deviceActions.setUpFilteredDeviceData({
        devices: results,
      })
    );
    history.push("/" + routesLocations)
  };

  const addKey = (searchKey) => {
    var searchObject = { name: searchKey };
    var array = [...searchKeys];
    array.push(searchObject);
    addSearchKey(array);
    filterDevices(array);
    if (id) {
      history.push(path + "," + searchKey);
    }
    else {
      history.push("/" + routesLocations + "/search/," + searchKey);
    }
  };

  const getLocationHistory = async () => {
    const data = {
      deviceid: locationHistorystate.selectedDevice,
      starttime: locationHistorystate.start,
      stoptime: locationHistorystate.end,
      isActive: deviceisActive
    };
    dispatch(locationHistoryActions.setActiveDevice({ history: null }));
    const response = await genericService.post("history/list", data);
    if (response.status === 200) {
      dispatch(
        locationHistoryActions.locationHistoryPage({
          locationPage: "showhistory",
        })
      )
      history.push(routesLocations)
      dispatch(locationHistoryActions.setisHistoryInfromation({ isHistoryInfromation: true }));
      const historyData = response.data[0];
      dispatch(locationHistoryActions.setdeviceHistoryData({ deviceHistoryData: response.data }));
      if (historyData) {
        dispatch(locationHistoryActions.setActiveDevice({ history: historyData }));
      }
      dispatch(locationHistoryActions.setStartEndDateLocationHistory({ startEndDateLocationHistoryDate: data }));
    } else {
      setShowErrorDialog(true)
    }
  };

  const onDeviceClick = (index) => {
    var array = _.cloneDeep(devicesState.filteredDevices);
    if (index !== -1) {
      let device = _.cloneDeep(array[index]);
      setDeviceisActive(device.status);
      if (!device.isSelected) {
        device.isSelected = true;
        setShowSubmitButton(true);
        // setSelectedDevice(device.id);
        dispatch(locationHistoryActions.setSelectedDevice({ selectedDevice : device.id }));
      } else {
        device.isSelected = false;
        setShowSubmitButton(false);
        dispatch(locationHistoryActions.setSelectedDevice({ selectedDevice : 0 }));
        // setSelectedDevice(0);
      }
      array.forEach((device) => (device.isSelected = false));
      array[index] = {};
      array[index] = _.cloneDeep(device);
      dispatch(
        deviceActions.setUpFilteredDeviceData({
          devices: array,
        })
      );
      dispatch(
        deviceActions.setLocationHistroyDevices({
          locatoinHistoryDevice: array,
        })
      );
    }
  };

  return (
    <>
      <React.Fragment>
        {!locationHistorystate.isHistoryInfromation ? (
          <div className="d-flex flex-column  ml-sm-2 ">
            <div className={`${props.downArrowClick ? "mobile_date_time" : "d-none d-md-block"} `}>
              <label className="dialog_body_title text_22_700 text_13_mobile text-left mt-3">
                {t("LocationHistory.ChooseTime")}
              </label>

              <div className="d-flex mt-md-3">
                <div className=" d-flex col-5 px-0 flex-column">
                  <label className="device_row_name_title d-md-block d-none ml-0">
                    {t("LocationHistory.Start")}
                  </label>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      inputFormat="yyyy-MM-dd HH:mm"
                      value={locationHistorystate.start}
                      onChange={(newValue) => {
                        dispatch(
                          locationHistoryActions.setStart({
                            start: newValue,
                          })
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="date_label" {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </div>

                <div className=" d-flex col-5 px-0 ml-3 flex-column">
                  <label className="device_row_name_title d-md-block d-none ml-0">
                    {t("LocationHistory.End")}
                  </label>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      inputFormat="yyyy-MM-dd HH:mm"
                      value={locationHistorystate.end}
                      onChange={(newValue) => {
                        dispatch(
                          locationHistoryActions.setEnd({
                            end: newValue,
                          })
                        );
                      }}
                      renderInput={(params) => (
                        <TextField 
                          className="date_label" {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className=" d-flex d-md-none col-4 px-0 ml-3 flex-column">
                  <div
                    className="rounded_primary justify-content-center  align-items-center"
                    style={{ width: "30px", height: "30px" }}
                    onClick={() => getLocationHistory()}
                  >
                    <img src={FlavorImages.forward_arrow_white} className="size_15 " alt="..." />
                  </div>
                </div>
              </div>
            </div>
            <label className="device_row_name_title pl-md-0 pl-sm-2 ml-0 margin_top_4">
              {t("Geofences.ChooseDevice")}
            </label>

            <div className="control_bar d-md-block d-none " style={{ marginTop: "5px" }}>
              <div className="accordion" id="accordionone">
                <SearchControl
                  error={false}
                  onChangeSearchKey={(e) => onKeyChange(e)}
                  onAddSearchKey={(e) => addKey(e)}
                  onSortClick={() => openSortDialog()}
                />
                <SortDevice
                  newBtn={newBtn}
                  setBtn={setBtn}
                  sortDevicesArray={sortDevicesArray}
                  loadDevices={loadDevices}
                  show={showSortDialog}
                  onClose={() => setShowSortDialog(false)}
                />
              </div>
            </div>
            <div className="control_bar  d-md-block d-none" style={{ marginTop: "15px", paddingBottom: "15px" }}>
              <div className="search_capsule_bar row ">
                <ScrollMenuName
                  menuItems={searchKeys}
                  onRemove={(index) => OnSearchRemove(index)}
                  OnClearAll={() => OnClearAll()}
                />
              </div>
            </div>

            <div className={`${showSubmitButton ? "scroll_div_20_locationhistory_button" : "scroll_div_20_locationhistory"} d-md-block d-none pr-0 location_history_div alarm_list_shadow`}>
              {error ? <PopUp setError={setError} error={error} /> : ""}
              {devicesState.filteredDevices.map((device, index) => {
                return (
                  <DeviceRow
                    isSelection={true}
                    key={index}
                    index={index}
                    selected={false}
                    device={device}
                    onClick={() => onDeviceClick(index)}
                    className="m-0 p-0"
                    setChooseAll={() => {
                      return false;
                    }}
                  />
                );
              })}
            </div>

            <div className="scroll_div_20 margin_bottom_95 height_mobile_300 d-md-none d-block row mt-2 p-0">
              {error ? <PopUp setError={setError} error={error} /> : ""}
              {devicesState.filteredDevices.map((device, index) => {
                return (
                  <DeviceRowMobile
                    isSelection={true}
                    page={"locationhistory"}
                    key={index}
                    selected={false}
                    device={device}
                    onClick={() => onDeviceClick(index)}
                    setChooseAll={() => {
                      return false;
                    }}
                  />
                );
              })}
            </div>

            <div className="w-100 mt-3 padding_right_9_point_4 d-md-flex d-none justify-content-end transition">
              {showSubmitButton && (
                <button
                  onClick={() => getLocationHistory()}
                  className="btn_theme bg_dark_helkama"
                  style={{ position: 'absolute', bottom: "calc(15px + env(safe-area-inset-bottom))" }}
                  id="next"
                >
                  {t("LocationHistory.GetLocationHistory")}
                </button>
              )}
            </div>
          </div>
        ) : (
          locationHistorystate.deviceHistoryData && (
            <LocationHistoryInformation
              setPageName={(data) => props.setPageName(data)}
            />
          )
        )}
      </React.Fragment>
      <ErrorDialog
        showErrorDialog={errorDialog}
        select={"locationhistory"}
        onClose={() => setShowErrorDialog(false)}
      />
    </>
  );
};

export default SearchLocationHistory;
