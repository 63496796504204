import { Card } from "@mui/material";
import _ from "lodash";
import Dialog from "@mui/material/Dialog";
import { shallowEqual, useSelector } from "react-redux";
import React, { useState } from "react";

import { useTranslation } from "react-i18next";
// import check from "../../assets/images/check.svg";
// import vector from "../../assets/images/Vector.svg";
// import vectortoggle from "../../assets/images/vectortoggle.svg";
// import close_gray from "../../assets/images/close_gray.svg";
import { useDispatch } from "react-redux";
import { devicesSlice } from "../../slice/deviceSlice";
import FlavorImages from "../../assetsFolderImport";
const deviceActions = devicesSlice.actions;
const SortAlarm = (props) => {
  const { sortState } = useSelector(
    (state) => ({ sortState: state.devices }),
    shallowEqual
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const handleChange = (newsort) => {
    // console.log(newsort);
    if (newsort === sortState.sort) {
      props.setdateOrder("");
      sessionStorage.setItem("sort", "")
      dispatch(
        deviceActions.setSort({
          sort: "",
        })
        );
      } else {
      props.setdateOrder(newsort);
      sessionStorage.setItem("sort", newsort)
      dispatch(
        deviceActions.setSort({
          sort: newsort,
        })
      );
    }
  };
  const handleChangeUL = (status) => {
    if (props.listOfUl.includes(status)) {
      let itemToBeRemoved = [status];
      var filteredArray = props.listOfUl.filter(
        (item) => !itemToBeRemoved.includes(item)
      );
      props.setlistOfUl(filteredArray);
      // props.setlistOfUl(filteredArray)
    } else {
      props.setlistOfUl((oldArray) => [...oldArray, status]);  
      props.setAlarmType((oldArray) => [...oldArray, status]);
    }
  };
  const closeAll = () => {
    props.onClose();
    setToggle(false);
    // props.setlistOfUl([])
    // props.sortAlarmByDate([]);
    // props.setAlarmslist()
  };

  const renderCheckImage2 = (status) => {
    return (
      <img
        className={
          status ? "remove-check pr-2" : "set-check pr-2"
        }
        src={FlavorImages.check}

        alt="..."
      />
    ); 
  }

  const renderCheckImage = (status) => {

    return (
      <img
        className={
          sortState.sort === status ? "remove-check pr-2" : "set-check pr-2"
        }
        src={FlavorImages.check}

        alt="..."
      />
    );
  };
  const setSorting = () => {
    props.sortAlarmByDate(props.listOfUl);
    let listTobeSort=_.cloneDeep(props.listOfUl)
    props.sortFilter({...props.arrayOffilter,listTobeSort})
    props.onClose();
    setToggle(false);
  };
	const renderDetailCard = (status) => {
  return (
        <Card className="py-2 px-0 size_300">
          <ul className="list-group list-group-flush mt-1">
            <li className="cursor-pointer list-group-item d-flex justify-content-between align-items-center">
              <label className="text_16_700 pl-1">{t("Alarm.SortAlarmBy")}</label>
              <img
                className="size_20 cursor-pointer position-absolute close_img mb-2"
                src={FlavorImages.close_gray}
                onClick={() => closeAll()}
                alt="..."
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              />
            </li>

            {/* <li className="list-group-item d-flex justify-content-between align-items-center">
              <label className="my-2 pl-1 text_12_400">{t("marker.Date")}</label>
            </li> */}
            <li
              onClick={() => {
                handleChange("Latest");
              }}
              className="cursor-pointer list-group-item d-flex justify-content-between align-items-center"
            >
              <p
                className={`my-2 pl-1 	${
                  sortState.sort === "Latest"
                    ? "text_12_400"
                    : "text-gray text-12-400"
                }`}
              >
                {t("Alarm.latest")}
              </p>
              {renderCheckImage("Latest")}
            </li>
            
            <li
              onClick={() => {
                handleChange("Oldest");
              }}
              className="cursor-pointer list-group-item d-flex justify-content-between align-items-center"
            >
              <p
                className={`my-2 pl-1 text-12-400	${
                  sortState.sort === "Oldest" ? "" : "text-gray"
                }`}
              >
                {t("Alarm.Oldest")}
              </p>

              {renderCheckImage("Oldest")}
            </li>

            <li className="cursor-pointer list-group-item d-flex justify-content-between align-items-center">
              <label className="my-2 pl-1  text_16_700">
                {t("Alarm.FilterAlarmBy")}
              </label>
            </li>
            <li
              onClick={() => {
                setToggle(!toggle);
              }}
              className="cursor-pointer list-group-item d-flex justify-content-between align-items-center"
            >
              <label
                className={`my-2 pl-1 ${
                  toggle === true ? "text_12_700" : "text-12-700 text-gray"
                }`}
              >
                {t("Alarm.Type")}
              </label>
              <img
                className="pr-2"
                src={toggle === true ? FlavorImages.vectortoggle : FlavorImages.vector}
                alt="..."
              />
            </li>
            {toggle === true ? (
					<div  className="overflow_filter_alrm">
                <ul className="list-group list-group-flush">
                {process.env.REACT_APP_FLAVOR === "Alco" || process.env.REACT_APP_FLAVOR === "Bike"
                  ?
                    null
                  :
                    <li
                      onClick={() => {
                        handleChangeUL("deviceStopped");
                      }}
                      className="cursor-pointer list-group-item d-flex justify-content-between align-items-center"
                    >
                      <p
                        className={`my-2 ml-3  ${
                          props.listOfUl.includes("deviceStopped")
                            ? "text_12_400"
                            : "text-12-400 text-gray"
                        }`}
                      >
                        {t("Alarm.DeviceStopped")}
                      </p>

                    </li>
                  }
                  {process.env.REACT_APP_FLAVOR === "Alco" || process.env.REACT_APP_FLAVOR === "Bike"
                  ?
                    null
                  :
                    <li
                      onClick={() => {
                        handleChangeUL("deviceOnline");
                      }}
                      className="cursor-pointer list-group-item d-flex justify-content-between align-items-center"
                    >
                      <p
                        className={`my-2 ml-3 ${
                          props.listOfUl.includes("deviceOnline")
                            ? "text_12_400"
                            : "text-12-400 text-gray text-gray"
                        }`}
                      >
                        {t("Alarm.deviceOnline")}
                      </p>
                    </li>
                  }
                  {process.env.REACT_APP_FLAVOR === "Alco" || process.env.REACT_APP_FLAVOR === "Bike"
                  ?
                    null
                  :
                    <li
                      onClick={() => {
                        handleChangeUL("deviceOffline");
                      }}
                      className="cursor-pointer list-group-item d-flex justify-content-between align-items-center"
                    >
                      <p
                        className={`my-2 ml-3 ${
                          props.listOfUl.includes("deviceOffline")
                            ? "text_12_400"
                            : "text-12-400 text-gray text-gray"
                        }`}
                      >
                        {t("Alarm.deviceOffline")}
                      </p>
                    </li>
                  }
                  {process.env.REACT_APP_FLAVOR === "Alco" 
                  ?
                    null
                  :
                    <li
                      onClick={() => {
                        handleChangeUL("geofenceExit");
                      }}
                      className="cursor-pointer list-group-item d-flex justify-content-between align-items-center"
                    >
                      <p
                        className={`my-2 ml-3 ${
                          props.listOfUl.includes("geofenceExit")
                            ? "text_12_400 text-capitalize"
                            : "text-12-400 text-gray text-gray"
                        }`}
                      >
                        {t("Alarm.GeofenceExit")}
                      </p>
                      {renderCheckImage2(props.listOfUl.includes("geofenceExit"))}
                    </li>
                  }
                  {process.env.REACT_APP_FLAVOR === "Alco" 
                  ?
                    null
                  :
                    <li
                      onClick={() => {
                        handleChangeUL("geofenceEnter");
                      }}
                      className="cursor-pointer list-group-item d-flex justify-content-between align-items-center"
                    >
                      <p
                        className={`my-2 ml-3 ${
                          props.listOfUl.includes("geofenceEnter")
                            ? "text_12_400"
                            : "text-12-400 text-gray text-gray"
                        }`}
                      >
                        {t("Alarm.GeofenceEnter")}
                      </p>
                      {renderCheckImage2(props.listOfUl.includes("geofenceEnter"))}
                    </li>
                  }
                  {process.env.REACT_APP_FLAVOR === "Alco" || process.env.REACT_APP_FLAVOR === "Bike"
                  ?
                    null
                  :
                    <li
                      onClick={() => {
                        handleChangeUL("tampering");
                      }}
                      className="cursor-pointer list-group-item d-flex justify-content-between align-items-center"
                    >
                      <p
                        className={`my-2  ml-3 ${
                          props.listOfUl.includes("tampering")
                            ? "text_12_400"
                            : "text-12-400 text-gray text-gray"
                        }`}
                      >
                        {t("Alarm.Tampering")}
                      </p>
                    </li>
                  }
                  {process.env.REACT_APP_FLAVOR === "" 
                  ?
                    null
                  :
                    <li
                    onClick={() => {
                      handleChangeUL("lowBattery");
                    }}
                    className="cursor-pointer list-group-item d-flex justify-content-between align-items-center"
                    >
                      <p
                        className={`my-2 ml-3 ${
                          props.listOfUl.includes("lowBattery")
                            ? "text_12_400"
                            : "text-12-400 text-gray text-gray"
                        }`}
                      >
                        {t("Alarm.LowBattery")}
                      </p>
                      {renderCheckImage2(props.listOfUl.includes("lowBattery"))}

                  </li>
                  }
                  {process.env.REACT_APP_FLAVOR === "Alco" || process.env.REACT_APP_FLAVOR === "Tracking" || process.env.REACT_APP_FLAVOR === "Bike"
                  ?
                    null
                  :
                    <li
                    onClick={() => {
                      handleChangeUL("lowExternalBattery");
                    }}
                    className="cursor-pointer list-group-item d-flex justify-content-between align-items-center"
                    >
                      <p
                        className={`my-2 ml-3 ${
                          props.listOfUl.includes("lowExternalBattery")
                            ? "text_12_400"
                            : "text-12-400 text-gray text-gray"
                        }`}
                      >
                        {t("Alarm.LowExternalBattery")}
                      </p>
                      {renderCheckImage2(props.listOfUl.includes("lowExternalBattery"))}

                    </li>
                  }
                  {process.env.REACT_APP_FLAVOR === "Alco" 
                  ?
                    null
                  :
                    <li
                    onClick={() => {
                      handleChangeUL("ignitionOn");
                    }}
                    className="cursor-pointer list-group-item d-flex justify-content-between align-items-center"
                    >
                      <p
                        className={`my-2 ml-3 ${
                          props.listOfUl.includes("ignitionOn")
                            ? "text_12_400"
                            : "text-12-400 text-gray text-gray"
                        }`}
                      >
                        {t("Alarm.IgnitionOn")}
                      </p>
                      {renderCheckImage2(props.listOfUl.includes("ignitionOn"))}

                    </li>
                  }
                  {process.env.REACT_APP_FLAVOR === "" 
                  ?
                    null
                  :
                    <li
                    onClick={() => {
                      handleChangeUL("ignitionOff");
                    }}
                    className="cursor-pointer list-group-item d-flex justify-content-between align-items-center"
                    >
                      <p
                        className={`my-2 ml-3 ${
                          props.listOfUl.includes("ignitionOff")
                            ? "text_12_400"
                            : "text-12-400 text-gray text-gray"
                        }`}
                      >
                        {process.env.REACT_APP_FLAVOR === "Alco" 
                          ? t("Alarm.PositiveBlow")
                          : t("Alarm.IgnitionOff")}
                      </p>
                      {renderCheckImage2(props.listOfUl.includes("ignitionOff"))}
                    </li>
                  }
                  {process.env.REACT_APP_FLAVOR === "Alco" 
                  ?
                    null
                  :
                    <li
                    onClick={() => {
                      handleChangeUL("vibration");
                    }}
                    className="cursor-pointer list-group-item d-flex justify-content-between align-items-center"
                    >
                      <p
                        className={`my-2 ml-3 ${
                          props.listOfUl.includes("vibration")
                            ? "text_12_400"
                            : "text-12-400 text-gray text-gray"
                        }`}
                      >
                        {t("Alarm.Vibration")}
                      </p>
                      {renderCheckImage2(props.listOfUl.includes("vibration"))}
                    </li>
                  }
                  {process.env.REACT_APP_FLAVOR === "Bike" 
                  ?
                    null
                  :
                    <li
                    onClick={() => {
                      handleChangeUL("powerCut");
                    }}
                    className="cursor-pointer list-group-item d-flex justify-content-between align-items-center"
                    >
                      <p
                        className={`my-2 ml-3 ${
                          props.listOfUl.includes("powerCut")
                            ? "text_12_400"
                            : "text-12-400 text-gray text-gray"
                        }`}
                      >
                        {t("Alarm.PowerCut")}
                      </p>
                    </li>
                  }
                  {process.env.REACT_APP_FLAVOR === "Alco" || process.env.REACT_APP_FLAVOR === "Bike"
                  ?
                    null
                    :
                    <li
                      onClick={() => {
                        handleChangeUL("sos");
                      }}
                      className="cursor-pointer list-group-item d-flex justify-content-between align-items-center"
                    >
                      <p
                        className={`my-2 ml-3 ${
                          props.listOfUl.includes("sos")
                            ? "text_12_400"
                            : "text-12-400 text-gray text-gray"
                        }`}
                      >
                        {t("Alarm.Sos")}
                      </p>
                    </li>
                  }
                  {process.env.REACT_APP_FLAVOR === "Alco" 
                  ?  
                    null
                  :
                    <li
                    onClick={() => {
                      handleChangeUL("deviceOverspeed");
                    }}
                    className="cursor-pointer list-group-item d-flex justify-content-between align-items-center"
                    >
                      <p
                        className={`my-2 ml-3 ${
                          props.listOfUl.includes("deviceOverspeed")
                            ? "text_12_400"
                            : "text-12-400 text-gray text-gray"
                        }`}
                      >
                        {t("Alarm.DeviceOverspeed")}
                      </p>
                      {renderCheckImage2(props.listOfUl.includes("deviceOverspeed"))}
                  </li>
                  }
                  {process.env.REACT_APP_FLAVOR === "Alco" || process.env.REACT_APP_FLAVOR === "Bike"
                  ?
                    null
                  :
                  <li
                  onClick={() => {
                    handleChangeUL("deviceUnknown");
                  }}
                  className="cursor-pointer list-group-item d-flex justify-content-between align-items-center"
                >
                  <p
                    className={`my-2 ml-3 ${
                      props.listOfUl.includes("deviceUnknown")
                        ? "text_12_400"
                        : "text-12-400 text-gray text-gray"
                    }`}
                  >
                    {t("Alarm.deviceUnknown")}
                  </p>
                </li>
                  }
                  {process.env.REACT_APP_FLAVOR === "Alco" 
                    ?  
                    <li
                    onClick={() => {
                      handleChangeUL("Bypass");
                    }}
                    className="cursor-pointer list-group-item d-flex justify-content-between align-items-center"
                    >
                    <p
                      className={`my-2 ml-3 ${
                        props.listOfUl.includes("Bypass")
                          ? "text_12_400"
                          : "text-12-400 text-gray text-gray"
                      }`}
                      >
                        {t("Alarm.Bypass")}
                      </p>
                    </li>
                    :
                    null
                  }
                </ul>
              </div>
            ) : (
              ""
            )}
          </ul>
          <div className="d-flex justify-content-end mb-1 mt-2 mr-2">
            <button
					className="btn bg-transparent btn-sm font-bold text-grey text_12_700"
              onClick={() => closeAll()}
              variant="light"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              {t("actionDialog.cancel")}
            </button>
            <button
					className="btn btn_navy bg_dark_helkama py-0 px-4 text-white ml-3"
              onClick={() => setSorting()}
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              {t("actionDialog.select")}
            </button>
          </div>
        </Card>
    );
  };
  return (
    <>
			{props.deviceType ===  "mobile" ? <Dialog
		open={props.show}>
       { renderDetailCard()}
      </Dialog>
			:  <div id="collapseOne" className="collapse mt-1">
       { renderDetailCard()}
      </div>}


    </>
  );
};
export default SortAlarm;
