import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  streamingDevice: null,
  liveStream: null,
  traceUnitData: [],
  showgroupssetting:false,
  devices: [],
  bannerClick:false,
  showTermofService:false,
  filteredDevices: [],
  locatoinHistoryDevice:[],
  checkeDevices: [],
  mapDevices: [],
  mapDevicesorignal:[],
  pendingDevices: [],
  sort: sessionStorage.getItem("sort") ?? "Latest",
  action: false,
  selectedMapDeviceId: "",
  selectedLocationPointId:"",
  checkDevices: [],
  selectedMapDevice: "",
  sharedbyme: "",
  sortOrder: "",
  page: "devices",
  zoomlevel: ""
};
export const devicesSlice = createSlice({
  name: "devices",
  initialState: initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload.page;
    },
    setTraceUnitData: (state, action) => {
      state.traceUnitData = action.payload.traceUnitData;
    },
    setUpFilteredDeviceData: (state, action) => {
      state.filteredDevices = action.payload.devices;
    },
    setGroupRowSettings: (state, action) => {
      state.showgroupssetting = action.payload.showgroupssetting;
    },
    setUpDeviceData: (state, action) => {
      state.devices = action.payload.devices;
    },
    setUpPendingDevices: (state, action) => {
      state.pendingDevices = action.payload.devices;
    },
    setSort: (state, action) => {
      state.sort = action.payload.sort;
    },
    setSortOrder: (state, action) => {
      state.sortOrder = action.payload.sortOrder;
    },
    setAction: (state, action) => {
      state.action = action.payload.action;
    },
    setMapDevices: (state, action) => {
      state.mapDevices = action.payload.mapDevices;
    },
    setMapDevicesOrignal: (state, action) => {
      state.mapDevicesorignal = action.payload.mapDevicesorignal;
    },
    setLocationHistroyDevices: (state, action) => {
      state.locatoinHistoryDevice = action.payload.locatoinHistoryDevice;
    },
    setStreamingDevice: (state, action) => {
      state.streamingDevice = action.payload.device;
    },
    setLiveStream: (state, action) => {
      state.liveStream = action.payload.liveStream;
    },
    setBannerClick: (state, action) => {
      state.bannerClick = action.payload.bannerClick;
    },
    setshowTermofService: (state, action) => {
      state.showTermofService = action.payload.showTermofService;
    },
    setsharedbyme: (state, action) => {
      state.sharedbyme = action.payload.sharedbyme;
    },
    setSlectedMapDeviceId: (state, action) => {
      state.selectedMapDeviceId = action.payload.selectedMapDeviceId;
    },
    setSelectedLocationPointId: (state, action) => {
      state.selectedLocationPointId = action.payload.selectedLocationPointId;
    },
    setSlectedMapDevice: (state, action) => {
      state.selectedMapDevice = action.payload.selectedMapDevice
    },
    setZoomLevel: (state, action) => {
      state.zoomlevel = action.payload.zoomlevel
    },
    resetAllDeviceState: (state, action) => {
      state.streamingDevice = null
      state.liveStream = null
      state.traceUnitData = []
      state.devices = []
      state.filteredDevices = []
      state.checkeDevices = []
      state.mapDevices = []
      state.pendingDevices = []
      state.sort = "name"
      state.action = false
      state.selectedMapDeviceId = ""
      state.checkDevices = []
      state.selectedMapDevice = ""
      state.sharedbyme = ""
      state.sortOrder = ""
      state.page = "devices"
      state.zoomlevel = ""
    }
  },
});
