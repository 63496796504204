import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams, useLocation } from "react-router-dom";
import SideBarNew from "../../components/SideBar/SideBarNew";
import { INDEX_URL } from "../../helpers/constants";
import SearchControl from "../../components/Search/SearchControl";
import * as devicesService2 from "../../services/devices/devicesService2";
import Map from "../Maps/Map";
import Routes from "./Routes";
import _ from "lodash";
import SearchLocationHistory from "./SearchLocationHistory";
import SortrouteDialog from "./SortRouteDialog";
import * as helper_functions from "../../helpers/helper_functions";
import { devicesSlice } from "../../slice/deviceSlice";
import { locationHistorySlice } from "../../slice/locationHistorySlice";
import ScrollMenuName from "../../components/ScrollBar/ScrollMenuName";
import * as genericService from "../../services/genericService";
import { groupSlice } from "../../slice/groupSlice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import RoutesTutorial from "./RoutesTutorial";


// assets
// import search from "../../assets/images/search_gray.svg";
// import search_black from "../../assets/Helkama_images/search_black.svg";
// import location_icon from "../../assets/images/location_icon.svg";
// import location_icon_helkama from "../../assets/helkama_assets/routes.png";
// import info_icon_helkama from "../../assets/Helkama_images/extended_info.svg";
// import info_icon from "../../assets/images/info_icon.svg";
// import up_arrow_grey from "../../assets/images/up_arrow_grey.svg";
// import up_arrow_black from "../../assets/Helkama_images/up_arrow_black.svg";
// import Inverted_Badge from "../../assets/images/Inverted_Badge.svg";
// import inverted_filled_badge from "../../assets/images/inverted_filled_badge.svg";
// import bookmark_icon from "../../assets/helkama_assets/bookmark.png";
// import bookmark_icon_filled from "../../assets/helkama_assets/bookmark_filled.svg";
// import Side_left_Arrow_white from "../../assets/images/Side_left_Arrow_white.svg";
// import Side_left_Arrow_black from "../../assets/Helkama_images/Side_left_Arrow_black.svg";
import FlavorImages from "../../assetsFolderImport";

const locationHistoryActions = locationHistorySlice.actions;

let animate_hight_up = 'animate_hight_up_mobile_routes'
let animate_hight_down = 'animate_hight_down_mobile_routes'
const LocationHistory = () => {
	const [searchKeys, addSearchKey] = useState([]);
	const dispatch = useDispatch();
	const location = useLocation();
	let path = location.pathname;
	const [downArrowClick, setDownArrowClick] = useState(false);
	const [statisticsGraphShow, setStatisticsGraphShow] = useState(false)
	const deviceActions = devicesSlice.actions;
	const [searchClick, setSearchClick] = useState(false);
	const [initialRoutes, setInitialRoutes] = useState([]);
	const [initialSavedRoutes, setInitialSavedRoutes] = useState([]);
	const [routes, setRoutes] = useState([]);
	const groupActions = groupSlice.actions;
	const [filterRoute, setFilterRoute] = useState([]);
	const [savedRoutesList, setSavedRoutesList] = useState([]);
	const [savedRoutesText, setSavedRoutesText] = useState('0/0');
	const [routesaveCheck, setRoutesaveCheck] = useState(false)
	const [firstDownClick, setFirstDownClick] = useState(false)
	const [showSortDialog, setShowSortDialog] = useState(false);
	const [showTutorial, setShowTutorial] = useState(false);
	const { t } = useTranslation();
	const history = useHistory();
	let { id } = useParams();
	const closeBottomPopupMobile = () => {
		setDownArrowClick(!downArrowClick)
		setFirstDownClick(true)
		animate_hight_up = 'animate_hight_up_mobile_routes'
	}
	const { locationHistorystate } = useSelector(
		(state) => ({ locationHistorystate: state.locationHistory }),
		shallowEqual
	);
	const { devicesState } = useSelector(
		(state) => ({ devicesState: state.devices }),
		shallowEqual
	);

	const openSortDialog = () => {
		setShowSortDialog(true);
	};
	
	const OnTutorialClose = () => {
		setShowTutorial(false);
	  };

	  const onKeyChange = (searchKey) => {
		if (searchKey) {
			const newFilteredRoutes = initialRoutes.filter(route => 
				route.devicename.toLowerCase().includes(searchKey.toLowerCase()) || 
				route.routename.toLowerCase().includes(searchKey.toLowerCase())
			);
	
			const newFilteredSavedRoutes = initialSavedRoutes.filter(route => 
				route.devicename.toLowerCase().includes(searchKey.toLowerCase()) || 
				route.routename.toLowerCase().includes(searchKey.toLowerCase())
			);
	
			setFilterRoute(newFilteredRoutes);
			setSavedRoutesList(newFilteredSavedRoutes);
		} else {
			// reset to the initial routes and savedRoutesList if the search is cleared
			setFilterRoute(initialRoutes);
			setSavedRoutesList(initialSavedRoutes);
		}
	};
	
	

	const addKey = (searchKey) => {
		var searchObject = { name: searchKey };
		var array = [...searchKeys, searchObject];
		addSearchKey(array);
		filterRoutes(array);

		if (id) {
			history.push(path + "," + searchKey);
		}
		else {
			history.push(INDEX_URL + "routes/search/," + searchKey);
		}
	};

	const sortRouteArray = () => {
		let routeAllData = _.cloneDeep(filterRoute);
		let routeSavedData = _.cloneDeep(savedRoutesList);
		routeAllData.sort(helper_functions.default.getSortOrder(devicesState.sort));
		routeSavedData.sort(helper_functions.default.getSortOrder(devicesState.sort));

		setFilterRoute(routeAllData);
		setSavedRoutesList(routeSavedData);
		// setSavedRoutesList(savedArray)
		setShowSortDialog(false);
	};

	const loadDevices = async () => {
		const response = await devicesService2.getDevicesCompactInfo();
		if (response.status === 200) {
			const devices = response.data.devices ?? [];
			const pendingDevices = response.data.pendingSharedDevices ?? [];
			const newDevices = devices.concat(pendingDevices);
			dispatch(
				deviceActions.setUpDeviceData({
					devices: newDevices,
				})
			);
		}
	};

	const loadRoutes = async () => {
		var arrayTobePushed = []

		if (id) {
			let keyFilter = id
			let keyTogetFilter = keyFilter.split(",")
			for (let i = 1; i < keyTogetFilter.length; i++) {
				arrayTobePushed.push({ "name": keyTogetFilter[i] })
			}

			addSearchKey(arrayTobePushed);
		}

		// console.log('routes loading...')
		var routesList = [];
		var savedRouteList = [];
		const routeResponse = await genericService.get("routes/list/all");
		const savedRouteResponse = await genericService.post("routes/list/saved/byfilter");

		if (routeResponse.status === 200) {
			routesList = routeResponse.data.response ?? [];
			setRoutes(routesList);
			setFilterRoute(routesList);
			setInitialRoutes(routesList);
		}
		
		if (savedRouteResponse.status === 200) {
			savedRouteList = savedRouteResponse.data.response ?? [];
			setSavedRoutesText(savedRouteResponse.data.savedroutes);
			setSavedRoutesList(savedRouteList);
			setInitialSavedRoutes(savedRouteList);
		}

		if (arrayTobePushed.length > 0) {
			filterRoutes(arrayTobePushed, routesList, savedRouteList);
		}
	};

	const OnSearchRemove = (index) => {
		if (index !== -1) {
			let searchKey = searchKeys[index]
			let path2
			if (searchKeys.length > 1)
				path2 = path.replace(`,${searchKey.name}`, '');
			else
				path2 = path.replace(`,${searchKey.name}`, path);


			var array = _.cloneDeep(searchKeys);

			array.splice(index, 1);
			if (array.length > 0) {
				filterRoutes(array);
				history.push(path2);
			} else {
				history.push(INDEX_URL + "routes")
			}

			addSearchKey(array);
		}
	};

	const OnClearAll = () => {
		var array = [...searchKeys];
		array.splice(0, array.length);
		addSearchKey(array);
		// loadRoutes();
		// history.push(INDEX_URL + "routes");
	};

	const filterRoutes = (array, routesList = [], savedRouteList = []) => {
		if (!array.length) {
			// loadRoutes();
		} else {
			const items = [...(array.map((arr) => arr.name))];
			const filteredRoutes = [];
			const savedRoutes = [];

			if (routesList.length === 0) {
				routesList = [...filterRoute]
			}
			if (savedRouteList.length === 0) {
				savedRouteList = [...savedRoutesList]
			}

			let temp1;
			let temp2;
			for (const item of items) {
				temp1 = routesList.filter((route) => {
					const deviceMatch = route.devicename.toLowerCase().indexOf(item.toLowerCase()) > -1;
					const routeNameMatch = route.routename.toLowerCase().indexOf(item.toLowerCase()) > -1;
					return deviceMatch || routeNameMatch;
				});
				temp2 = savedRouteList.filter((route) => {
					const deviceMatch = route.devicename.toLowerCase().indexOf(item.toLowerCase()) > -1;
					const routeNameMatch = route.routename.toLowerCase().indexOf(item.toLowerCase()) > -1;
					return deviceMatch || routeNameMatch;
				});
				Array.prototype.push.apply(filteredRoutes, temp1);
				Array.prototype.push.apply(savedRoutes, temp2);
			}

			// console.log("temp", temp1, temp2)
			// console.log('filtered data', filteredRoutes, savedRoutes)

			const uniqueRoutes = _.uniq(filteredRoutes)
			const uniqueSavedRoutes = _.uniq(savedRoutes)
			setFilterRoute(uniqueRoutes);
			setSavedRoutesList(uniqueSavedRoutes)
		}
	};

	const loadGroups = async () => {
		const response = await devicesService2.getDeviceGroupsList();
		if (response.status === 200) {
			const groups = response.data ?? [];
			dispatch(
				groupActions.setUpGroupData({
					groups: groups,
				})
			);
			dispatch(
				groupActions.setUpFilteredGroupsData({
					groups: groups,
				})
			);
		}
	};
	useEffect(() => {
		if (process.env.REACT_APP_FLAVOR === 'Bike') {
			animate_hight_up = 'animate_hight_up_mobile_routes';
			animate_hight_down = 'animate_hight_down_mobile_routes'
		} else {
			animate_hight_up = 'animate_hight_up';
			animate_hight_down = 'animate_hight_down'
		}

		if (id) {
			setDownArrowClick(!downArrowClick)
			setFirstDownClick(true)
			animate_hight_up = ''
		}
		loadGroups()
		loadDevices()
	}, []);

	return (
		<>
			<React.Fragment>
				<section className="section-home " id="login-page">
					<div className="middle-content d-flex justify-content-between h-100">
						<SideBarNew
							page={
								process.env.REACT_APP_FLAVOR === "Bike"
									? "Routes"
									: "LocationHistory"
							}
							onClick={(e) => {
								history.push(INDEX_URL + e);
							}}
						/>
						<div className="col row section-data ml-5 ">
							<div className="col-12 col-md-6 col-lg-4 section-left_bottom_data">
								<div className="d-md-block d-none home_title_bar">
									<img
										src={FlavorImages.location_icon}
										className="home_title_icon mb-3 ml-1 m-0"
										alt="..."
									/>
									<label className="text_34_700 uppercase d-inline">{t("customFile.Routes")}</label>
									{process.env.REACT_APP_FLAVOR ==="Bike" 
									? <img
									src={ FlavorImages.info_icon}
									className="home_title_icon ml-3 mb-3 cursor-pointer"
									alt="..."
									onClick={() => {
									setShowTutorial(true);
										}}
									/> 
									: null}
								</div>
								<div className={`${downArrowClick === false && firstDownClick ? `${animate_hight_down}` :
									firstDownClick && downArrowClick ?
										`${animate_hight_up}` : "heigh_50_mobile heigh_50_routes_mobile"} `} >
										<div className="dragbar_toucharea" draggable="true" onTouchStart={() => closeBottomPopupMobile()}>
								<div className="dragbar" ></div>
								</div>
									<div className={`control_bar pl-1 pr-2 d-md-none ${process.env.REACT_APP_FLAVOR === "Bike" ? "d-none" : "d-block"}  border-bottom`} style={{ marginTop: "15px", paddingBottom: "15px" }}>
										<div className="row ">
											<div className="col-10"
											>
												<label
													className={`mt-2 mr-1  d-md-none d-sm-flex ml-2 text_22_700`}
												>
													{t("customFile.Searchlocationhistory")}
												</label>
											</div>

											<div className="col-2  m-0 p-0">
												<img
													onClick={() => closeBottomPopupMobile()}
													src={FlavorImages.up_arrow}
													className={`size_30 mt-2 ml-3 d-md-none d-sm-flex padding_6 bg-grey2 radius_2
												${downArrowClick ? "rotate_btn_maximize" : "rotate_btn_minmize"}`}
													alt="..."
												/>
											</div>
										</div>
									</div>

									{/* control bar for mobile  */}
									<div className={`control_bar pl-1 border-bottom ${process.env.REACT_APP_FLAVOR === "Bike" ? "d-md-none d-block" : "d-none"} `} style={{ marginTop: "15px", paddingBottom: "15px", height: 'calc(3.125rem + 20px)' }}>
										<div className="row mb-2 pt-2 pl-3 justify-content-between align-items-start">
											<div className={`${searchClick === false ? "" : "col-2 ellipsis"}  p-0 m-0 `}
											>
												<div className={`accordion d-flex ${searchClick ? (routesaveCheck ? 'margin_minus_left_140' : 'margin_minus_left_110') : ""}`} id="accordianMobile">
													<div
														className={`size_30 ml-2 padding_6 bg-grey2 radius_2 ${statisticsGraphShow ? "d-flex justify-content-center" : "d-none"} `}
													>
														<img height={'18px'} onClick={() => setStatisticsGraphShow(false)}
															src={FlavorImages.Side_left_Arrow}
															alt="..."
														/>
													</div>
													<label
														data-toggle="collapse"
														data-target="#collapsemobile"
														aria-expanded="true"
														aria-controls="collapsemobile"
														className={`mb-0 mr-1  d-md-none d-sm-flex ml-2  text_22_700`}
													>
														{!routesaveCheck ? (
															<label className="text_22_700 uppercase">
																{t("Routes.AllRoutes")}
															</label>
														) : (
															<>
																<div className="text_22_700 mb-0 uppercase">
																	{t("Routes.SavedRoutes")}<br />
																	<div className="text_16_700" style={{ lineHeight: 'normal' }}>
																		{savedRoutesText}
																	</div>
																</div>
															</>
														)}
													</label>
													<div className={`justify-content-center size_30 padding_6 bg-grey2 radius_2 ${statisticsGraphShow ? "d-none" : "d-inline-flex"}`}>
														<img
															height={'18px'}
															className="cursor-pointer"
															onClick={() => setRoutesaveCheck(!routesaveCheck)}
															src={routesaveCheck ? (FlavorImages.bookmark_icon_filled) :
																(FlavorImages.bookmark_icon )}
															alt="..."
														/>
													</div>
													<img
															src={FlavorImages.info_icon}
															className="home_title_icon-sm ml-2 m-1"
															alt="..."
															onClick={() => {
																setShowTutorial(true);
																setDownArrowClick(!downArrowClick)
															}}
															/>
												</div>
											</div>
											<div className={`${searchClick === false ? "col" : "col-8"}  ${statisticsGraphShow ? "d-none" : "d-block"}  m-0 p-0`}> 	{/* with serachbar  */}

												<div className="accordion" id="accordionone">

													<SearchControl
														searchClick={searchClick}
														onAddSearchKey={(e) => addKey(e)}
														onChangeSearchKey={(e) => onKeyChange(e)}
														setSearchClick={() => setSearchClick(false)}
														onSortClick={() => openSortDialog()}
													/>
												</div>
												{downArrowClick === false || searchClick === false ? "" :
													<div className="d-block d-sm-none">
														<SortrouteDialog
															sortRouteArray={() => sortRouteArray()}
															onClose={() => setShowSortDialog(false)}
														// show={showSortDialog}
														/>
													</div>}
												{searchClick === false ? (
													<div
														className={`size_30 d-md-none d-flex align-items-center justify-content-center padding_6 bg-grey2 radius_2`}
														style={{ float: "right" }}>
														<img
															width={'20px'}
															onClick={() => setSearchClick(true)}
															src={FlavorImages.search}
															alt="..."
														/>
													</div>
												) : (
													""
												)}
											</div>
											<div className={`col ${searchClick ? '' : 'col-2'} mr-2 p-0`}>
												<div
													className={`size_30 ${searchClick ? 'ml-2' : 'ml-3'}  bg-grey2 radius_2 d-md-none d-flex align-items-center justify-content-center padding_6 ${downArrowClick
														? "rotate_btn_maximize"
														: "rotate_btn_minmize"
														}`}>
													<img
														width={'20px'}
														onClick={() => {
															closeBottomPopupMobile(!downArrowClick);
														}}
														src={ FlavorImages.up_arrow}
														alt="..."
													/>
												</div>
											</div>
										</div>
									</div>
									{process.env.REACT_APP_FLAVOR === "Bike" ? (
										<div>
											<div className="d-none d-md-block">
											<SearchControl
												searchClick={searchClick}
												onAddSearchKey={(e) => addKey(e)}
												onChangeSearchKey={(e) => onKeyChange(e)}
												setSearchClick={() => setSearchClick(false)}
												onSortClick={() => openSortDialog()}
											/>
											</div>
											<div className="d-none d-md-block">
												<SortrouteDialog
													sortRouteArray={() => sortRouteArray()}
													onClose={() => setShowSortDialog(false)}
												// show={showSortDialog}
												/>
											</div>
											<div className="d-none d-md-block d-sm-none accordion mt-3" id="accordiontwo">
											</div>
											<div className="d-block d-md-none" style={{
												position: 'absolute',
												marginTop: routesaveCheck ? '-170px' : '-140px',
												width: '100%'
											}}>
												<ScrollMenuName
													menuItems={searchKeys}
													onRemove={(e) => {
														// console.log('search remove')
														OnSearchRemove(e)
													}}
													OnClearAll={() => OnClearAll()}
												/>
											</div>

											<div className="d-none d-md-block">
												<ScrollMenuName
													menuItems={searchKeys}
													onRemove={(e) => {
														// console.log('search remove')
														OnSearchRemove(e)
													}}
													OnClearAll={() => OnClearAll()}
												/>
											</div>

											<Routes
												loadRoutes={loadRoutes}
												filterRoute={filterRoute}
												setFilterRoute={setFilterRoute}
												savedRoutesList={savedRoutesList}
												setSavedRoutesList={setSavedRoutesList}
												savedRoutesText={savedRoutesText}
												filterRoutes={filterRoutes}
												searchClick={searchClick}
												setSearchClick={setSearchClick}
												downArrowClick={downArrowClick}
												setStatisticsGraphShow={setStatisticsGraphShow}
												statisticsGraphShow={statisticsGraphShow}
												routesaveCheck={routesaveCheck}
											/>
										</div>
									) : (

										<SearchLocationHistory
											setPageName={(data) =>
												dispatch(
													locationHistoryActions.locationHistoryPage({
														locationPage: data,
													})
												)
											}
											downArrowClick={downArrowClick}
										/>
									)}
								</div>
							</div>

							{
								<div className="col section-right_data ">
									<Map page={locationHistorystate.locationPage} />
								</div>
							}
						</div>
					</div>
				</section>
				<RoutesTutorial
				show={showTutorial}
				onClose={() => OnTutorialClose()}
				/>
			</React.Fragment>
		</>
	);
};

export default LocationHistory;
