import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import ActionButton from "../../components/Buttons/ActionButton";
import AddButton from "../../components/Buttons/AddButton";
import EnableDisableButton from "../../components/Buttons/EnableDisableButton";
import ErrorDialog from "../LocationHistory/ErrorDialog";
import GroupsButton from "../../components/Buttons/GroupsButton";
import ScrollMenuName from "../../components/ScrollBar/ScrollMenuName";
import SearchControl from "../../components/Search/SearchControl";
import SideBarNew from "../../components/SideBar/SideBarNew";
import { INDEX_URL } from "../../helpers/constants";
import * as helper_functions from "../../helpers/helper_functions";
import * as devicesService2 from "../../services/devices/devicesService2";
import { devicesSlice } from "../../slice/deviceSlice";
import { groupSlice } from "../../slice/groupSlice";
import { mapSettingsSlice } from "../../slice/mapSettingsSlice";
import ActionDialog from "./ActionDialog";
import Map from "../Maps/Map";
import AddDevice from "./AddDevice";
import CreateGroupRow from "./CreateGroupRow";
import DevicePower from "./DevicePower";
import TermofService from "./TermofService";
import DeviceRow from "./DeviceRow";
import EditGroup from "./EditGroup";
import GroupRows from "./GroupRows";
import PopUp from "./PopUp";
import SortDevice from "./SortDevice";
import GroupSelector from "./GroupSelector"
import MoreDeviceRow from "./MoreDevicesRow";
import HelkamaDevices from "./HelkamaDevices";
import { locationHistorySlice } from "../../slice/locationHistorySlice";
import { authSlice } from "../../slice/authSlice";
import NotificationBannerRow from "./NotificationBannerRow"
import FlavorImages from './../../assetsFolderImport';

const deviceActions = devicesSlice.actions;
const groupActions = groupSlice.actions;
const mapSettingsActions = mapSettingsSlice.actions;
const locationHistoryActions = locationHistorySlice.actions;
let animate_hight_up = 'animate_hight_up'
const authAction = authSlice.actions;

const Devices = () => {
  const location = useLocation();
  let { id } = useParams();
  const [searchid, setSearchId] = useState("")
  useEffect(() => {
    if (id) {
      let searchid = _.cloneDeep(id)
      setSearchId(searchid)
    }
    else {
      setSearchId("")
    }
  }
    , [id]);
  const [searchKey, _setSearchKey] = useState(false)
  const searchkeyRef = React.useRef(searchKey);
  const setTraceUnitData = (x) => {
    searchkeyRef.current = _.cloneDeep(x);
    _setSearchKey(x)
  };

  const [arrayKey, _setArrayKey] = useState([])
  const arraykeyRef = React.useRef(arrayKey);
  const setArrayKeyBig = (x) => {
    arraykeyRef.current = _.cloneDeep(x);
    _setArrayKey(x)
  };


  const [sortKey, setsortkey] = useState("")
  const sortKeyRef = React.useRef(sortKey);
  const setsortkeyRef = (sortkey) => {
    sortKeyRef.current = _.cloneDeep(sortkey);
    setsortkey(sortkey);
  };
  const [sortorderKey, setsortOrderkey] = useState("")
  const sortorderKeyRef = React.useRef(sortorderKey);
  const setsortOrderkeyRef = (sortorderKey) => {
    sortorderKeyRef.current = _.cloneDeep(sortorderKey);
    setsortOrderkey(sortorderKey);
  }
  let path = location.pathname
  let flavor = process.env.REACT_APP_FLAVOR; //when we have APIs for helkama; remove static value and use flavor from env
  let width = window.innerWidth;  // use only when no imapact while changing screen from web to mobile
  const dispatch = useDispatch();
  const [devicesLoaded, setdevicesLoaded] = useState(false)
  const { t } = useTranslation();
  const [showPowerDialog, setShowPowerDialog] = useState(false);
  const [devices, setDevices] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [editGroup, showEditGroup] = useState(false)
  const [showActionDialog, setShowActionDialog] = useState(false);
  const [enableDisblaeButton, setenableDisblaeButton] = useState(false);
  const [deviceLabelMobile, setDeviceLabelMobile] = useState(t("Device.labelMobile"))
  const [deviceType, setDeviceType] = useState("web")
  const history = useHistory();
  const [searchKeys, addSearchKey] = useState([]);
  const [error, setError] = useState("");
  const [newBtn, setBtn] = useState("Select");
  const [createGroup, setCreateGroup] = useState(false);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [firstDownClick, setFirstDownClick] = useState(false)
  const [isSelection, setIsSelection] = useState(false);
  const [chooseAll, setChooseAll] = useState(false);
  const [showSortDialog, setShowSortDialog] = useState(false);
  const [waitingForAcceptBikes, setwaitingforacceptBikes] = useState([]);
  const [compactDevices, setCompactDevices] = useState([]);
  const [searchClick, setSearchClick] = useState(false);
  const [selectedGroupDevices, setselectedGroupDevices] = useState([])
  const [downArrowClick, setDownArrowClick] = useState(false);
  const [errorDialog, setShowErrorDialog] = useState(false)
  const [powerMobileisSlection, setPowerMobileisSlection] = useState(false);
  const [edit_AddGroup, setEdit_AddGroup] = useState("")
  const [groups, setGroups] = useState("")
  const [groupSelctor, setGroupSelector] = useState(false)
  const [selectedDeviceId, setSelectedDeviceId] = useState(null)
  const { mapSettingsState } = useSelector(
    (state) => ({ mapSettingsState: state.mapSettings }),
    shallowEqual
  );
  const { devicesState } = useSelector(
    (state) => ({ devicesState: state.devices }),
    shallowEqual
  );
  const { authState } = useSelector(
    (state) => ({ authState: state.auth }),
    shallowEqual
  )

  const setSearch = () => {
    setSearchClick(true)
    setIsSelection(false);
    dispatch(
      deviceActions.setAction({
        action: false,
      })
    );
    setenableDisblaeButton(false);
  }
  const setPowerMobile = () => {
    if (downArrowClick) {
      if (powerMobileisSlection) {
        setPowerMobileisSlection(false)
        setIsSelection(false)
        dispatch(
          deviceActions.setUpFilteredDeviceData({
            devices: devices,
          })
        );
      }
      else {
        const results = _.cloneDeep(devicesState.devices);
        let powerDeives = []
        for (let item of results) {
          if (item.hasOwnProperty("modelspecificinfo")) {
            if (item.modelspecificinfo.powerswitch.powerswitchvalid) {
              powerDeives.push(item)
            }
          }
        }
        if (powerDeives.length) {
          setSearchClick(false)
          setIsSelection(false);
          setPowerMobileisSlection(!powerMobileisSlection);
          setenableDisblaeButton(true)
          setIsSelection(true)
          dispatch(
            deviceActions.setUpFilteredDeviceData({
              devices: powerDeives,
            })
          );
        }
        else {
          setError(t("Device.Nopowerdevicetoshow"))
        }
      }

    }
  };
  const { groupState } = useSelector(
    (state) => ({ groupState: state.groups }),
    shallowEqual
  );
  const loadIntervalBasedDevices = async () => {
    const response = await devicesService2.getDevicesCompactInfo();
    if (response.status === 200) {
      const devices = response.data.devices ?? [];
      const pendingDevices = response.data.pendingSharedDevices ?? [];
      const devicetoset = devices.concat(pendingDevices);
      //use case one when id is present ///
      if (searchid) {
        // console.log("TOPP");
        let arrayTobePushed = []
        let keyFilter = searchid
        let keyTogetFilter = keyFilter.split(",")
        for (let i = 1; i < keyTogetFilter.length; i++) {
          arrayTobePushed.push(
            {
              "name": keyTogetFilter[i]
            }
          )
        }
        let items = []
        let SearchedItems = []
        addSearchKey(arrayTobePushed);
        arrayTobePushed.map((arr) => {
          return items.push(arr.name)
        })
        for (const item of items) {
          let temp = devicetoset.filter((device) => {
            const itemName = item.toLowerCase();
            return (
              device.name.toLowerCase().indexOf(itemName) > -1 ||
              device.uniqueid.toLowerCase().indexOf(itemName) > -1
            );
          });
          Array.prototype.push.apply(SearchedItems, temp);
        }
        //use case two when id and search key both are present 
        if (searchkeyRef.current) {
          // console.log("MITTEN");
          const items = [];
          items.push(searchkeyRef.current)
          for (const item of items) {
            let temp = devicetoset.filter((device) => {
              const itemName = item.toLowerCase();
              return (
                device.name.toLowerCase().indexOf(itemName) > -1 ||
                device.uniqueid.toLowerCase().indexOf(itemName) > -1
              );
            });
            Array.prototype.push.apply(SearchedItems, temp);
          }
          for (const item of items) {
            let temp = devicetoset.filter((device) => {
              if (device.groupname) {
                return device.groupname.toLowerCase().indexOf(item.toLowerCase()) > -1;
              }
            });
            Array.prototype.push.apply(SearchedItems, temp);
          }
        }
        dispatch(
          deviceActions.setUpFilteredDeviceData({
            devices: _.uniq(SearchedItems)
          })
        );
      }
      if (searchkeyRef.current.length > 0 && arraykeyRef.current.length === 0) {
        var array = [];
        var searchObject = { name: searchkeyRef.current };
        array.push(searchObject);
        // console.log(array);
        devicetoset.sort(helper_functions.default.sortFunctionWIthOrder(sortKeyRef.current, sortorderKeyRef.current));
        filterIntervalDevices(array, devicetoset);
      }
      if (arraykeyRef.current.length === 1 && searchkeyRef.current.length === 0) {
        // console.log("RÄTTA");
        devicetoset.sort(helper_functions.default.sortFunctionWIthOrder(sortKeyRef.current, sortorderKeyRef.current));
        filterIntervalDevices(arraykeyRef.current, devicetoset);
      }

      if (arraykeyRef.current.length === 1 && searchkeyRef.current.length > 0) {
        var array2 = [];
        var searchObject2 = { name: searchkeyRef.current };
        array2.push(searchObject2);
        var merged = array2.concat(arraykeyRef.current)
        // console.log(merged);
        devicetoset.sort(helper_functions.default.sortFunctionWIthOrder(sortKeyRef.current, sortorderKeyRef.current));
        filterIntervalDevices(merged, devicetoset);
      }

      if (arraykeyRef.current.length > 1 && searchkeyRef.current.length === 0) {
        // console.log("mer");
        devicetoset.sort(helper_functions.default.sortFunctionWIthOrder(sortKeyRef.current, sortorderKeyRef.current));
        filterIntervalDevices(arraykeyRef.current, devicetoset);
      }

      if (arraykeyRef.current.length > 1 && searchkeyRef.current.length > 0) {
        var array3 = [];
        var searchObject3 = { name: searchkeyRef.current };
        array3.push(searchObject3);
        var merged2 = array3.concat(arraykeyRef.current)
        // console.log(merged2);
        devicetoset.sort(helper_functions.default.sortFunctionWIthOrder(sortKeyRef.current, sortorderKeyRef.current));
        filterIntervalDevices(merged2, devicetoset);
      }

      if (arraykeyRef.current.length === 0 && searchkeyRef.current === 0) {
        // console.log("LÄNGST NER");
        devicetoset.sort(helper_functions.default.sortFunctionWIthOrder(sortKeyRef.current, sortorderKeyRef.current));
        dispatch(
          deviceActions.setUpFilteredDeviceData({
            devices: devicetoset,
          })
        );
      }

    }
  }

  const loadDevices = async () => {
    const response = await devicesService2.getDevicesCompactInfo();
    if (response.status === 200) {
      const devices = response.data.devices ?? [];
      setCompactDevices(devices);
      const pendingDevices = response.data.pendingSharedDevices ?? [];
      const newDevices = devices.concat(pendingDevices);
      setDevices(newDevices);
      setFilteredDevices(newDevices);
      setdevicesLoaded(true)
      dispatch(
        deviceActions.setUpDeviceData({
          devices: newDevices,
        })
      );
      if (id) {
        let arrayTobePushed = []
        let keyFilter = id
        let keyTogetFilter = keyFilter.split(",")
        for (let i = 1; i < keyTogetFilter.length; i++) {
          arrayTobePushed.push(
            {
              "name": keyTogetFilter[i]
            }
          )
        }
        let items = []
        let SearchedItems = []
        addSearchKey(arrayTobePushed);
        arrayTobePushed.map((arr) => {
          return items.push(arr.name)
        })
        for (const item of items) {
          let temp = newDevices.filter((device) => {
            const itemName = item.toLowerCase();
            return (
              device.name.toLowerCase().indexOf(itemName) > -1 ||
              device.uniqueid.toLowerCase().indexOf(itemName) > -1
            );
          });
          Array.prototype.push.apply(SearchedItems, temp);
        }
        dispatch(
          deviceActions.setUpFilteredDeviceData({
            devices: _.uniq(SearchedItems)
          })
        );
      }
      else {
        dispatch(
          deviceActions.setUpFilteredDeviceData({
            devices: newDevices,
          })
        );
      }

    }
  };

  useEffect(() => {
    let intervalId
    if (!isSelection) {
      if (mapSettingsState.show_groups) return
      intervalId = setInterval(() => {
        loadDevices();
        // loadIntervalBasedDevices();
      }, 10000);
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [isSelection, mapSettingsState.show_groups, id]);
  

  const filterIntervalDevices = (array, devices) => {
    const items = [];
    const SearchedItems = [];
    array.map((arr) => {
      return items.push(arr.name)
    })
    for (const item of items) {
      let temp = devices.filter((device) => {
        const itemName = item.toLowerCase();
        return (
          device.name.toLowerCase().indexOf(itemName) > -1 ||
          device.uniqueid.toLowerCase().indexOf(itemName) > -1
        );
      });
      Array.prototype.push.apply(SearchedItems, temp);
    }
    for (const item of items) {
      let temp = devices.filter((device) => {
        if (device.groupname) {
          return device.groupname.toLowerCase().indexOf(item.toLowerCase()) > -1;
        }
      });
      SearchedItems.sort(helper_functions.default.sortFunctionWIthOrder(sortKeyRef.current, sortorderKeyRef.current));
      Array.prototype.push.apply(SearchedItems, temp);
    }
    dispatch(
      deviceActions.setUpFilteredDeviceData({
        devices: (SearchedItems),
      })
    );
  }
  const setGroupSelection = () => {
    setGroupSelector(!groupSelctor)
    setDownArrowClick(true)
  }
  const filterGroups = (array) => {
    if (!array.length) {
      const results = _.cloneDeep(groupState.groups);
      if (devicesState.sort === "name") {
        results.sort(helper_functions.default.sortFunctionWIthOrder(devicesState.sort, devicesState.sortOrder));
      }
      dispatch(
        groupActions.setUpFilteredGroupsData({
          groups: results,
        })
      );
      return;
    }
    const items = [];
    var SearchedItems = [];
    const searchedDevices = [];
    var searchDeviceIds = []
    array.map((arr) => items.push(arr.name));
    for (const item of items) {
      let temp = groups.filter((group) => {
        return group.name.toLowerCase().indexOf(item.toLowerCase()) > -1;
      });
      Array.prototype.push.apply(SearchedItems, temp);
    }
    if (!SearchedItems.length) {
      for (const item of items) {
        console.log("TJOS3?");
        let temp = devices.filter((device) => {
          const itemName = item.toLowerCase();
          return (
            device.name.toLowerCase().indexOf(itemName) > -1 ||
            device.uniqueid.toLowerCase().indexOf(itemName) > -1
          );
        });
        Array.prototype.push.apply(searchedDevices, temp);
      }
      searchedDevices.map((device, index) => {
        searchDeviceIds.push(device.id)
      })
      groups.map((group, index) => {
        group.deviceId.map((groupDeviceId, index) => {
          if (searchDeviceIds.includes(groupDeviceId)) {
            SearchedItems.push(group)
          }
        })
      })
    }
    dispatch(
      groupActions.setUpFilteredGroupsData({
        groups: SearchedItems,
      })
    );
  };

  const filterDevices = (array) => {
    if (!array.length) {
      const results = _.cloneDeep(devicesState.devices);
      results.sort(helper_functions.default.sortFunctionWIthOrder(devicesState.sort, devicesState.sortOrder));
      dispatch(
        deviceActions.setUpFilteredDeviceData({
          devices: results,
        })
      );
      return;
    }
    const items = [];
    const SearchedItems = [];
    array.map((arr) => {
      return items.push(arr.name)
    })
    for (const item of items) {
      console.log("TJOS4?");
      let temp = devicesState.devices.filter((device) => {
        const itemName = item.toLowerCase();
        return (
          device.name.toLowerCase().indexOf(itemName) > -1 ||
          device.uniqueid.toLowerCase().indexOf(itemName) > -1
        );
      });

      Array.prototype.push.apply(SearchedItems, temp);
    }
    for (const item of items) {
      let temp = devicesState.devices.filter((device) => {
        if (device.groupname) {
          return device.groupname.toLowerCase().indexOf(item.toLowerCase()) > -1;
        }
      });
      Array.prototype.push.apply(SearchedItems, temp);
    }
    dispatch(
      deviceActions.setUpFilteredDeviceData({
        devices: (SearchedItems),
      })
    );
  };
  const OnClearAll = () => {
    var results = _.cloneDeep(!mapSettingsState.show_groups ? devicesState.devices : groupState.groups);
    var array = [...searchKeys];
    array.splice(0, array.length);
    addSearchKey(array);
    results.sort(helper_functions.default.sortFunctionWIthOrder(devicesState.sort, devicesState.sortOrder));
    dispatch(
      deviceActions.setUpFilteredDeviceData({
        devices: devicesState.devices,
      })
    );
    if (!mapSettingsState.show_groups) {
      //  history.push("/devices")
      addSearchKey([]);
      setTraceUnitData("");
      setArrayKeyBig([])
    }
    else {
      dispatch(
        groupActions.setUpFilteredGroupsData({
          groups: results,
        })
      );
    }

  };
  const OnSearchRemove = (index) => {
    var array = [...searchKeys];
    if (index !== -1) {

      let searchKey = array[index]

      let path2
      if (searchKeys.length > 1)
        path2 = path.replace(`,${searchKey.name}`, '');
      else
        path2 = path.replace(`,${searchKey.name}`, '');
      array.splice(index, 1);
      setArrayKeyBig(array)
      addSearchKey(array);
      //HÄR
      if (!mapSettingsState.show_groups) {
        filterDevices(array);
      }
      else {
        filterGroups(array)
      }
      //history.push(path2);
    }
  };
  const onKeyChange = (searchKey) => {
    setTraceUnitData(searchKey)
    var array = [...searchKeys];
    if (searchKey) {
      var searchObject = { name: searchKey };
      array.push(searchObject);
    }
    filterDevices(array);
    filterGroups(array)
  }

  const addKey = (searchKey) => {
    var searchObject = { name: searchKey };
    var array = [...searchKeys];
    array.push(searchObject);
    addSearchKey(array);
    setArrayKeyBig(array)
    setTraceUnitData("")
    // if (id && !mapSettingsState.show_groups) {
    //   history.push(path + "," + searchKey);
    // }
    // else if (!mapSettingsState.show_groups) {
    //   history.push("/devices/," + searchKey);
    // }
    if (!mapSettingsState.show_groups) {
      filterDevices(array);
    }
    else {
      filterGroups(array)
    }

  };

  const resetCheck = () => {
    if (powerMobileisSlection) {
      setPowerMobileisSlection(false);
    }
    dispatch(
      deviceActions.setUpFilteredDeviceData({
        devices: devices,
      })
    );
    setIsSelection(false);
    dispatch(
      deviceActions.setAction({
        action: false,
      })
    );
    setenableDisblaeButton(false);
  };
  const onDeviceClick = (index) => {
    var array = _.cloneDeep(devicesState.filteredDevices);
    if (index !== -1) {
      var device = array[index];
      if (device.isSelected !== true) device.isSelected = true;
      else device.isSelected = false;
      array[index] = device;
      if (isSelection === true) {
        dispatch(
          deviceActions.setUpFilteredDeviceData({
            devices: array,
          })
        );
      }
      else {
        const clickedDevice = array.find(device => device.isSelected === true)
        dispatch(locationHistoryActions.setSelectedDevice({ selectedDevice: clickedDevice?.id }));
        dispatch(
          deviceActions.setUpFilteredDeviceData({
            devices: array,
          })
        );

      }
    }
  };
  const sortDevicesArray = () => {
    if (mapSettingsState.show_groups) {
      let groupData = _.cloneDeep(groupState.filteredGroups);

      if (devicesState.sort === 'battery') {
        return
      }
      groupData.sort(helper_functions.default.sortFunctionWIthOrder(devicesState.sort, devicesState.sortOrder));
      dispatch(
        groupActions.setUpFilteredGroupsData({
          groups: groupData,
        })
      );
    } else {
      setsortOrderkeyRef(devicesState.sortOrder);
      setsortkeyRef(devicesState.sort)
      let deviceData = _.cloneDeep(devicesState.filteredDevices);
      deviceData.sort(helper_functions.default.sortFunctionWIthOrder(devicesState.sort, devicesState.sortOrder));
      dispatch(
        deviceActions.setUpFilteredDeviceData({
          devices: deviceData,
        })
      );
    }
    setShowSortDialog(false);
  };

  const onGroupCreated = () => {
    setCreateGroup(false);
    loadGroups();
    loadDevices();
  };
  const loadGroups = async () => {
    const response = await devicesService2.getDeviceGroupsList();
    if (response.status === 200) {
      const groups = response.data ?? [];
      setGroups(groups)
      dispatch(
        groupActions.setUpGroupData({
          groups: groups,
        })
      );
      dispatch(
        groupActions.setUpFilteredGroupsData({
          groups: groups,
        })
      );
    }
  };
  const on_load = () => {
    dispatch(deviceActions.setPage({ page: "devices" }));
    loadDevices();
    loadGroups();
  };
  const setPowerDevies = () => {
    const results = _.cloneDeep(devicesState.devices);
    let powerDeives = []
    for (let item of results) {
      if (process.env.REACT_APP_FLAVOR === 'Bike') {
        if (item.hasOwnProperty("communicationmode") && item.communicationmode.hasOwnProperty("powerswitch") && !item.communicationmode.powerswitch.pending) {
          if (item.communicationmode.powerswitch.valid) {
            powerDeives.push(item)
          }
        }
      } else {
        if (item.hasOwnProperty("modelspecificinfo")) {
          if (item.modelspecificinfo.powerswitch.powerswitchvalid) {
            powerDeives.push(item)
          }
        }
      }
    }
    if (powerDeives.length) {
      setenableDisblaeButton(true)
      setIsSelection(true)
      dispatch(
        deviceActions.setUpFilteredDeviceData({
          devices: powerDeives,
        })
      );
    }
    else {
      setShowErrorDialog(t("Device.Nopowerdevicetoshow"))
    }
  }
  useEffect(() => {
    on_load();
    dispatch(
      locationHistoryActions.locationHistoryPage({
        locationPage: "locationHistory",
      })
    )
    dispatch(locationHistoryActions.resetLocationState());
    if (id) {
      setDownArrowClick(false)
      setDownArrowClick(true)
      animate_hight_up = ''
      setFirstDownClick(true)
    }

    return () => {
      dispatch(mapSettingsActions.setSetting({ show_groups: false }));
      dispatch(
        deviceActions.setGroupRowSettings({
          showgroupssetting: false,
        })
      );
      // dispatch(
      //   deviceActions.setUpFilteredDeviceData({
      //     devices: devicesState.devices,
      //   })
      // );
    };
  }, []);
  const setTermofServiceDialog = (msgId) => {
    dispatch(
      deviceActions.setshowTermofService({
        showTermofService: true,
      })
    );
    dispatch(
      deviceActions.setBannerClick({
        bannerClick: msgId,
      })
    );
  }

  useEffect(() => {
    if (mapSettingsState.show_groups) {
      setCreateGroup(false);
    }
  }, [mapSettingsState.show_groups]);


  useEffect(() => {
    if (process.env.REACT_APP_FLAVOR === "Bike") {
      var gustav = document.getElementById("helkama_card")
      if (selectedDeviceId == null) {
        gustav.classList.add("card_Helkama_down")
        gustav.classList.remove("card_Helkama_up")
      } else {
        gustav.classList.add("card_Helkama_up")
        gustav.classList.remove("card_Helkama_down")
      }
    }
  }, [selectedDeviceId])

  const OnAddDevice = () => {
    setShowAddDialog(true);
  };
  const showActionbox = () => {
    setShowActionDialog(true);
  };

  const OnAddGroup = () => {
    setCreateGroup(!createGroup);
  };

  const openSortDialog = () => {
    if (width < 768) {
      setDeviceType("mobile")
      setShowSortDialog(true);
    }

  };
  const setPowerDialog = () => {
    var data_filter = devicesState.filteredDevices.filter(
      (element) => element.isSelected
    );
    if (data_filter.length === 0) {
      setShowErrorDialog(t("errors.NODeviceisselected"))
    } else {
      setShowPowerDialog(true);
    }
  };
  const closeBottomPopupMobile = () => {
    setDownArrowClick(!downArrowClick)
    if (downArrowClick) {
      setIsSelection(false)
      dispatch(
        deviceActions.setUpFilteredDeviceData({
          devices: devices,
        })
      );
      setPowerMobileisSlection(false)
    }
    setGroupSelector(false)
    setFirstDownClick(true)
    animate_hight_up = 'animate_hight_up'
  }
  const OnAddClose = () => {
    setShowAddDialog(false);
  };
  const resetDevices = () => {
    setIsSelection(false)
    dispatch(
      deviceActions.setUpFilteredDeviceData({
        devices: devices,
      })
    );
  }
  const showHideGroups = () => {
    dispatch(
      mapSettingsActions.setSetting({
        show_groups: !mapSettingsState.show_groups,
      })
    );
  };

  const handleTransitionEnd = () => { };

  return (
    <>
      <React.Fragment>
        <section className="section-home  " id="login-page">
          <div
            className="middle-content d-flex justify-content-between h-100"
            onTransitionEnd={handleTransitionEnd}
          >
            <SideBarNew
              page="Devices"
              setShowAddDialog={() => setShowAddDialog(true)}
              onClick={(e) => {
                history.push(INDEX_URL + e);
              }}
            />
            <div className="col row section-data ml-5 ">
              <div
                className={`col-12 col-md-6 col-lg-4 ${flavor === "Bike" && "d-none d-md-block"} section-left_bottom_data  `}
              >
                <div className="col-12 pl-1  home_title_bar disply_none_small">
                  <img
                    src={FlavorImages.device_icon}
                    className="home_title_icon"
                    alt="..."
                  />
                  <label className="text_gloria_17_700_italic text_34_700 uppercase">{t("sideBar.MyDevice")}</label>
                </div>

                <div
                  className=" control_bar pl-1 disply_none_small"
                  style={{ marginTop: "15px" }}
                >
                  {mapSettingsState.show_groups ? (
                    <AddButton
                      active={createGroup}
                      FlavorImages={FlavorImages}
                      title={t("Device.CreateGroup")}
                      onClick={() => OnAddGroup()}
                    />
                  ) : (
                    <AddButton
                      title={t("Device.Add")}
                      onClick={() => OnAddDevice()}
                    />
                  )}
                </div>
                <div className={`${downArrowClick === false && firstDownClick ? "animate_hight_down " :
                  firstDownClick && downArrowClick ?
                    `${animate_hight_up}` : "heigh_50_mobile"} `} >
                  <div className="dragbar_toucharea" draggable="true" onTouchStart={() => closeBottomPopupMobile()}>
                    <div className="dragbar" ></div>
                  </div>
                  <div className="control_bar border-bottom pl-1 d-md-none d-block" style={{ marginTop: "15px" }}>
                    <div className="row  pl-3  pr-2" style={{ marginBottom: "10px" }} >
                      <div className={`${searchClick === false ? "col-7" : "col-1 ellipsis"}  p-0 m-0 style={{ marginBottom: "30px" }} `}
                      >
                        <div className="accordion" id="accordianMobile">
                          <label
                            data-toggle="collapse"
                            data-target="#collapsemobile"
                            aria-expanded="true"
                            aria-controls="collapsemobile"
                            onClick={() => setGroupSelection()}
                            className={`mt-2   d-md-none d-sm-flex ${searchClick ? "margin_minus_left_69 " : "ml-2 "
                              } text_22_700`}
                          >
                            {deviceLabelMobile}
                          </label>
                          <img
                            className="size_30 padding_6 mr-3  mb-1"
                            src={FlavorImages.Side_right_Arrow_white}
                            alt="..."
                          />
                          {downArrowClick && <GroupSelector
                            showEditGroup={() => showEditGroup(true)}
                            setDeviceLabelMobile={(groupText) => setDeviceLabelMobile(groupText)}
                            setEdit_AddGroup={(state) => setEdit_AddGroup(state)}
                            groups={groupState.filteredGroups}
                          />}

                        </div>
                      </div>
                      <div className={`${searchClick === false ? "col-2" : "col-8"}  mt-2 m-0 p-0`}> 	{/* with serachbar  */}

                        <div className="accordion" id="accordionone">
                          <SearchControl
                            searchClick={searchClick}
                            onAddSearchKey={(e) => addKey(e)}
                            onChangeSearchKey={(e) => onKeyChange(e)}
                            setIsSelection={() => setIsSelection(false)
                            }
                            setSearchClick={() => setSearchClick(false)}
                            onSortClick={() => openSortDialog()}
                          />
                          {downArrowClick === false || searchClick === false ? "" : <SortDevice
                            newBtn={newBtn}
                            deviceType={deviceType}
                            setBtn={setBtn}
                            sortDevicesArray={sortDevicesArray}
                            loadDevices={loadDevices}
                            onClose={() => setShowSortDialog(false)}
                          />
                          }

                        </div>
                        {searchClick === false ? (
                          <img
                            onClick={() => setSearch()}
                            style={{ float: "right" }}
                            src={FlavorImages.search}
                            className="search_btn_mobile d-md-none d-sm-flex  bg-grey2"
                            alt="..."
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-3 m-0 p-0 mt-2">
                        <img
                          onClick={() => setPowerMobile(true)}
                          src={powerMobileisSlection && isSelection ? FlavorImages.power_button_active : FlavorImages.power_button}
                          className={`size_30  radius_2 ml-2 ${powerMobileisSlection && isSelection ? "background_primary" : "bg-grey2"}  d-md-none d-sm-flex padding_6 `}
                          alt="..."
                        />
                        <img
                          onClick={() => closeBottomPopupMobile()}
                          src={FlavorImages.up_arrow}
                          className={`size_30 radius_2 ml-2 d-md-none d-sm-flex
												${downArrowClick ? "rotate_btn_maximize" : "rotate_btn_minmize"}
												padding_6 bg-grey2`}
                          alt="..."
                        />
                      </div>
                    </div>
                  </div>
                  {/* -------------------------------- */}
                  <div className="control_bar pl-1 d-none d-md-flex" style={{ marginTop: "15px" }}>

                    <div className="accordion" id="accordionone">
                      <SearchControl
                        searchClick={searchClick}
                        onAddSearchKey={(e) => addKey(e)}
                        onChangeSearchKey={(e) => onKeyChange(e)}
                        setIsSelection={() => setIsSelection(false)
                        }
                        setSearchClick={() => setSearchClick(false)}
                        onSortClick={() => openSortDialog()}
                      />
                      <SortDevice
                        newBtn={newBtn}
                        deviceType={deviceType}
                        setBtn={setBtn}
                        sortDevicesArray={sortDevicesArray}
                        loadDevices={loadDevices}
                        show={showSortDialog}
                        onClose={() => setShowSortDialog(false)}
                      />
                    </div>
                    {process.env.REACT_APP_FLAVOR === "Bike"
                      ?
                      ""
                      :
                      <GroupsButton
                        active={mapSettingsState.show_groups}
                        onClick={showHideGroups}
                      />
                    }
                  </div>
                  <div className={`control_bar pl-1 col ${flavor === "Alco" || flavor === "Bike" && "invisible"} ${enableDisblaeButton && "mx-3"} marginTopmobile`}>
                    <div className="accordion disply_none_small" id="accordiontwo">
                      {mapSettingsState.show_groups !== true &&
                        enableDisblaeButton === false ? (
                        <ActionButton onClick={() => showActionbox()} />
                      ) : mapSettingsState.show_groups === false &&
                        enableDisblaeButton === true ? (
                        <EnableDisableButton
                          devicesState={devicesState}
                          select={"devices"}
                          isChecked={chooseAll}
                          onClick={(e) => setChooseAll(e)}
                        />
                      ) : null}
                      <ActionDialog
                        FlavorImages={FlavorImages}
                        setenableDisblaeButton={() => setPowerDevies()}
                        resetActionButton={() => setenableDisblaeButton(false)}
                        showActionDialog={showActionDialog}
                        isSelection={isSelection}
                        resetIsSelection={() => resetDevices()}
                      />
                    </div>
                    <div className="search_capsule_bar row ">
                      <ScrollMenuName
                        menuItems={searchKeys}
                        onRemove={(e) => OnSearchRemove(e)}
                        OnClearAll={() => OnClearAll()}
                      />
                    </div>
                  </div>
                  {authState.notificationMessage ?
                    <div className={`control_bar pl-md-1 pl-0 col flex-column`}>
                      {authState.notificationMessage.message.length > 0 ?
                        authState.notificationMessage.message.map((msg, index) => {
                          if (msg.staysinapp === 1) {
                            return (
                              <NotificationBannerRow
                                key={msg.id}
                                message={msg}
                                showTermofService={(msgId) => setTermofServiceDialog(msgId)}
                              />
                            )
                          }
                        })
                        : ""}
                    </div> : ""}
                  {!mapSettingsState.show_groups ? (

                    devicesState.filteredDevices.length ?
                      <div
                        className={`  ${Object.keys(devicesState.filteredDevices).length > 10 && width > 768 ? "mt-3 " : ""}
                                  ${Object.keys(devicesState.filteredDevices).length < 10 && width > 768 && isSelection && authState.notificationMessage.message.length > 0 ? "mt-3 scroll_div_16_less_than_ten_if_message_and_select alarm_list_shadow mx-1"
                            : Object.keys(devicesState.filteredDevices).length < 10 && width > 768 && isSelection ? "mt-3 scroll_div_16_less_than_ten_if_select alarm_list_shadow mx-1"
                              : Object.keys(devicesState.filteredDevices).length < 10 && width > 768 && authState.notificationMessage.message.length > 0 ? "mt-2 scroll_div_20 alarm_list_shadow mx-1"
                                : Object.keys(devicesState.filteredDevices).length <= 10 && width > 768 ? "mt-3 scroll_div_16_less_than_ten alarm_list_shadow mx-1"
                                  : width <= 768 ? "scroll_div_19 pb-2" : ""}  height_mobile_470
                                  ${groupSelctor === false ? "" : "opacity_03"}
                                     `}
                      >
                        {Object.keys(devicesState.filteredDevices).length > 10 && width > 768 ? (
                          <>
                            <div className={`row-12 m-0 p-0 ${Object.keys(devicesState.filteredDevices).length < 10 ? "d-none" : ""}  text_12_500`}>
                              {" "}
                              <div
                                className={`p-0 px-4 m-0 ${isSelection ? "col-11" : "col-11"
                                  }`}
                              >
                                <div className="row">
                                  <div className="col-5 px-2
                                  m-0 p-0 ">
                                    {t("Device.Name")}
                                  </div>
                                  <div className={` p-0  m-0 col-7`}>
                                    <div className="row d-flex justify-content-between m-0 p-0">
                                      <div className="col-6 padding_left_36 m-0 text-truncate">
                                        {t("Device.LastActive")}
                                      </div>
                                      <div className="col-3 m-0 p-0">
                                        {t("Device.Group")}
                                      </div>
                                      <div className="col-1 m-0 p-0">
                                        {t("Device.Battery")}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={` ${isSelection && authState.notificationMessage.message.length > 0 ? "scroll_div_if_message_and_select"
                              : authState.notificationMessage.message.length > 0 ? "scroll_div_if_message"
                                : isSelection ? "scroll_div_20"
                                  : "scroll_div_16"}
                                            ml-1 alarm_list_shadow`}>
                              {devicesState.filteredDevices.map((device, index) => {
                                return (
                                  <div key={index}>
                                    <MoreDeviceRow
                                      isSelection={isSelection}
                                      powerMobileisSlection={powerMobileisSlection}
                                      selected={chooseAll ? true : false}
                                      key={index}
                                      isFirst={index === 0}
                                      isLast={
                                        devicesState.filteredDevices.length ===
                                        index + 1
                                      }
                                      index={index}
                                      error={error}
                                      setError={setError}
                                      device={device}
                                      getDevicesList={() => loadDevices()}
                                      waitingForAcceptBikes={waitingForAcceptBikes}
                                      setChooseAll={setChooseAll}
                                      onClick={() => onDeviceClick(index)}
                                    />
                                    <div className={`d-md-none  `} >
                                      <DeviceRow
                                        isSelection={isSelection}
                                        powerMobileisSlection={powerMobileisSlection}
                                        selected={chooseAll ? true : false}
                                        key={index}
                                        index={index}
                                        groupSelctor={groupSelctor}
                                        error={error}
                                        setError={setError}
                                        device={device}
                                        getDevicesList={() => loadDevices()}
                                        waitingForAcceptBikes={waitingForAcceptBikes}
                                        setChooseAll={setChooseAll}
                                        onClick={() => onDeviceClick(index)}
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        ) : devicesState.filteredDevices.map((device, index) => {
                          return (
                            <div key={index} className="d-md-block" >
                              <DeviceRow
                                isSelection={isSelection}
                                powerMobileisSlection={powerMobileisSlection}
                                selected={chooseAll ? true : false}
                                key={index}
                                index={index}
                                error={error}
                                setError={setError}
                                groupSelctor={groupSelctor}
                                device={device}
                                getDevicesList={() => loadDevices()}
                                waitingForAcceptBikes={waitingForAcceptBikes}
                                setChooseAll={setChooseAll}
                                onClick={() => onDeviceClick(index)}
                              />
                            </div>

                          );
                        })
                        }
                      </div> : devicesLoaded ? <label className="no_data_row mt-5 text_22_700 justify-content-center text-grey d-md-flex mt-4 px-2">
                        {t("Device.NoRegistered")}
                      </label> : ""
                  ) : (
                    <div className="mt-3 scroll_div_16_groups">
                      {/* {console.log(devicesState)} */}
                      {createGroup === true ? (
                        <CreateGroupRow
                          onCancel={() => setCreateGroup(!createGroup)}
                          onAddGroup={() => onGroupCreated()}
                          devices={devicesState.mapDevices}
                        />
                      ) : null}
                      <GroupRows
                        groups={groupState.filteredGroups}
                        onAddGroup={() => onGroupCreated()}
                        devices={devicesState.mapDevices}
                      />
                    </div>
                  )}
                </div>
                {/* device Rows */}
                {isSelection === true && !mapSettingsState.show_groups ? (
                  <div className="w-100 pr-2 pt-md-3 slide_up_toggle_btn d-flex  justify-content-md-end bottom_button_devices">
                    <button
                      onClick={() => resetCheck()}
                      className={`grey_btn_device    ${downArrowClick && isSelection ? "d-block" : "d-none d-md-block"}`}
                    >
                      {t("Device.CANCEL")}
                    </button>
                    <button
                      onClick={() => setPowerDialog()}
                      className={`btn_theme_device bg_dark_helkama ml-2 ${downArrowClick && isSelection ? "d-block" : "d-none d-md-block"}`}
                      id="next"
                    >
                      {t("Device.TOGGLE POWER")}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {process.env.REACT_APP_FLAVOR === "Bike" &&
                <div id="helkama_card" className={`col card_Helkama_down noScroll overflow-x
              ${flavor === "Bike" ? "d-md-none"
                    : "d-none"
                  }`}>
                  <div className="d-flex scroller  align-items-end" >
                    {devicesState.filteredDevices
                      .filter(device => !device.sharedstatus.waitingforaccept)
                      .map((device, index) => {
                        return (
                          <HelkamaDevices
                            key={index}
                            setSelectedDeviceId={setSelectedDeviceId}
                            selectedDeviceId={selectedDeviceId}
                            device={device}
                            index={index}
                          />
                        );
                      })
                    }
                  </div>
                </div>
              }
              {
                <div className="col section-right_data">
                  {error ? <PopUp setError={setError} error={error} /> : ""}
                  <Map
                    page="devices"
                  />
                </div>
              }
            </div>
            <AddDevice
              FlavorImages={FlavorImages}
              loadDevices={loadDevices}
              show={showAddDialog}
              onClose={() => OnAddClose()}
            />
            <EditGroup
              selectedGroupDevices={selectedGroupDevices}
              editGroup={editGroup}
              edit_AddGroup={edit_AddGroup}
              onClose={() => showEditGroup(false)}
            />
            {deviceType === "mobile" ?
              <SortDevice
                newBtn={newBtn}
                deviceType={deviceType}
                setBtn={setBtn}
                sortDevicesArray={sortDevicesArray}
                loadDevices={loadDevices}
                show={showSortDialog}
                onClose={() => setShowSortDialog(false)}
              /> : ""}
            {
              <DevicePower
                error={error}
                setError={setShowErrorDialog}
                showPowerDialog={showPowerDialog}
                onClose={() => setShowPowerDialog(false)}
              />
            }
            <ErrorDialog
              showErrorDialog={errorDialog}
              select={"Device"}
              errorMesage={errorDialog}
              onClose={() => setShowErrorDialog(false)}
            />
            <TermofService />
          </div>
        </section>
      </React.Fragment>
    </>
  );
};

export default Devices;
