import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "@mui/material";
// import close_gray from "../../assets/images/close_gray.svg";
import { shallowEqual, useSelector } from "react-redux";
// import check from "../../assets/images/check.svg";
import { useDispatch } from "react-redux";
import { alarmsSlice } from "../../slice/alarmsSlice";
import FlavorImages from "../../assetsFolderImport";
const alarmAction = alarmsSlice.actions;
const ShareActionDialog = (props) => {
  const { t } = useTranslation();
  const [localaction, setlocalaction] = useState("");
  const Closebox = () => {
    props.onClose();
    dispatch(
      alarmAction.setShareRemoveAction({
        shareRemoveAction: "",
      })
    );
    setlocalaction("");
    props.resetIsSelection();
  };
  const setCheckbar = () => {
    dispatch(
      alarmAction.setShareRemoveAction({
        shareRemoveAction: localaction,
      })
    );
    props.setenableDisblaeButton(true);
    props.setIsSelection(true);
    props.onClose();
  };
  const resetCheckBar = () => {
    dispatch(
      alarmAction.setShareRemoveAction({
        shareRemoveAction: localaction,
      })
    );
    props.resetIsSelection();

    props.onClose();
  };
  const { alarmState } = useSelector(
    (state) => ({ alarmState: state.alarms }),
    shallowEqual
  );
  const dispatch = useDispatch();
  return (
    <>
      <div
			 id="collapseshare"
			 className={`collapse ${props.massShareBtn ? "" : "d-none"} `}
			 data-parent="#accordionshare"
			>
        <Card className="card" style={{ width: "335px" }}>
          <div className="text-center">
            <div
              className="dialog_top_sort_row py-2 card-header"
              style={{ backgroundColor: "white" }}
            >
              <label className="card-title"> {t("Actions")}</label>
              <img src={FlavorImages.close_gray} onClick={() => props.onClose()} alt="..." />
            </div>
            <div className="card-body py-0  p-0">
              <div
                className="row"
                onClick={() => setlocalaction("shareDevice")}
              >
                <div className="col-6 d-flex justify-content-start">
                  <label
                    className={`mb-3 mt-3  ml-3 text_gray_12_500 ${
                      localaction === "shareDevice" ? "" : "text-gray"
                    }`}
                  >
                    {"Share Device Alarm"}
                  </label>
                </div>
                <div className="col-5 d-flex justify-content-end pr-1">
                  <img
                    style={{ height: "15px" }}
                    className={`mb-3 mt-3 mr-3' ${
                      localaction === "shareDevice" ? "" : "set-check"
                    }`}
                    src={FlavorImages.check}
                    alt="..."
                  />
                </div>
              </div>
              <div
                className="row border-top"
                onClick={() => setlocalaction("removeEmail")}
              >
                <div className="col-6 d-flex justify-content-start">
                  <label
                    className={`mt-3 mb-3 ml-3 text_gray_12_500 ${
                      localaction === "removeEmail" ? "" : "text-gray"
                    }`}
                  >
                    {"Remove emial"}
                  </label>
                </div>
                <div className="col-5 d-flex justify-content-end pr-1">
                  <img
                    style={{ height: "15px" }}
                    className={`mt-3 mb-3 mr-3' ${
                      localaction === "removeEmail" ? "" : "set-check"
                    }`}
                    src={FlavorImages.check}
                    alt="..."
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="m-0 card-footer  d-flex justify-content-end">
            <button
              className="btn bg-transparent btn-sm font-bold text-grey text_12_700"
              onClick={() => Closebox()}
              variant="light"
            >
              {t("actionDialog.cancel")}
            </button>
            {localaction !== "" ? (
              <button
                className="btn btn_navy bg_dark_helkama py-0 px-4 text-white ml-3"
                onClick={() => setCheckbar()}
              >
                {t("actionDialog.select")}
              </button>
            ) : (
              <button
                className="btn btn_navy bg_dark_helkama py-0 px-4 text-white ml-3"
                onClick={() => resetCheckBar()}
              >
                {t("actionDialog.select")}
              </button>
            )}
          </div>
        </Card>
      </div>
    </>
  );
};
export default ShareActionDialog;
