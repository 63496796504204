import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React from "react";
import { useTranslation } from "react-i18next";
// import close_gray from "../../assets/images/close_gray.svg";
import FlavorImages from "../../assetsFolderImport";

const ConfirmDelete = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Dialog open={props.show}>
        <Card className="card" style={{ width: "335px" }}>
          <div className="text-center">
            <div
              className="dialog_top_sort_row py-0 card-header"
              style={{ backgroundColor: "white" }}
            >
              <label className="text_17_700 ml-2">
                {" "}
                {t("forgotPassword.Confirm")}
              </label>
              <img
                src={FlavorImages.close_gray}
                onClick={() => props.closeConfirmDeleteDialog()}
                alt="..."
              />
            </div>
          </div>
          <div className="row ml-1 my-4 py-2">
            <div className=" w-100 row  justify-content-center ">
              <div className="form-outline">
                <label className="text-grey text_12_500">
                  {props.select === "RemoveEmail"
                    ? t("myAlarm.confirmDeleteEmail")
                    : t("myAlarm.confirmDleleteTitle")}{" "}
                </label>
              </div>
            </div>
          </div>
          <div className="m-0 card-footer  d-flex justify-content-end">
            <button
              className="btn text_11_700 btn-sm py-2 font-bold text-grey"
              onClick={() => props.closeConfirmDeleteDialog()}
              variant="light"
            >
              {t("actionDialog.cancel")}
            </button>
            <button
              onClick={() => props.onConfirm()}
              className="btn text_11_700  bg-danger btn-sm text-white py-2 text-uppercase ml-3"
            >
              {t("cardList.Delete")}
            </button>
          </div>
        </Card>
      </Dialog>
    </>
  );
};

export default ConfirmDelete;
