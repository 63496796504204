import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Circle,
  Polyline,
  Polygon,
  Marker,
  InfoWindow
} from "react-google-maps";
import { useParams } from "react-router-dom";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import DeviceMarker from "../../components/Maps/DeviceMarker";
import DeviceStoppedMarker from "../../components/Maps/DeviceStoppedMarker";
import DeviceNonStoppedMarker from "../../components/Maps/DeviceNonStoppedMarker";
import AlarmMarker from "../../components/Maps/AlarmMarker";
import * as devicesService2 from "../../services/devices/devicesService2";
import { devicesSlice } from "../../slice/deviceSlice";
import { alarmsSlice } from "../../slice/alarmsSlice";
import * as genericService from "../../services/genericService";
import { geofencesSlice } from "../../slice/geofencesSlice";
import DeviceStreamingMarker from "../../components/Maps/DeviceStreamingMarker";
// import cluster_marker from "../../assets/images/cluster_marker.svg";
import _ from "lodash";
import mapStyles from './mapStyles'
import lightMode from './mapStyles_light.js'
// import expand from "../../assets/images/expand_arrow.svg";
import FlavorImages from "../../assetsFolderImport";
const MAP_BOUNDS = {
  east: 179.9999,
  north: 85,
  south: -85,
  west: -179.9999
};
const defaultProps = {
  center: {
    lat: 59.38398,
    lng: 17.82492,
  },
  restriction: {
    latLngBounds: {
					 east: 179.9999,
					 north: 85,
					 south: -85,
					 west: -179.9999
				 },
    strictBounds: false,
  },
  minZoom: 1,
  zoom: 1,
};

const radiusesWithZoom = {};
radiusesWithZoom["zoom" + 22] = 9;
radiusesWithZoom["zoom" + 21] = 99;
radiusesWithZoom["zoom" + 20] = 99;
radiusesWithZoom["zoom" + 19] = 99;
radiusesWithZoom["zoom" + 18] = 999;
radiusesWithZoom["zoom" + 17] = 9999;
radiusesWithZoom["zoom" + 16] = 9999;
radiusesWithZoom["zoom" + 15] = 999999;
radiusesWithZoom["zoom" + 14] = 999999;
radiusesWithZoom["zoom" + 13] = 999999;
radiusesWithZoom["zoom" + 12] = 9999999;
radiusesWithZoom["zoom" + 11] = 9999999;
radiusesWithZoom["zoom" + 10] = 9999999;
radiusesWithZoom["zoom" + 9] = 99999999;
radiusesWithZoom["zoom" + 8] = 99999999;
radiusesWithZoom["zoom" + 7] = 99999999;
radiusesWithZoom["zoom" + 6] = 999999999;
radiusesWithZoom["zoom" + 5] = 999999999;
radiusesWithZoom["zoom" + 4] = 999999999;
radiusesWithZoom["zoom" + 3] = 9999999999;
radiusesWithZoom["zoom" + 2] = 9999999999;
radiusesWithZoom["zoom" + 1] = 9999999999;
radiusesWithZoom["zoom" + 0] = 9999999999;
const deviceActions = devicesSlice.actions;
const alarmActions = alarmsSlice.actions;
const geofencesActions = geofencesSlice.actions;
let currentLatitude = defaultProps.center.lat;
let currentLongitude = defaultProps.center.lng;

const RenderMap = withScriptjs(
  withGoogleMap((props) => {
    let mapRef = null;
    let circleRefs = null;
    let polyRefs = null;


    const [alarmOnMap, setAlarmOnMap] = useState({})
    const [polyGonepath, setPolygonepath] = useState([]);
    const [mapZoom, setMapZoom] = useState(defaultProps.zoom);
    const [onMaploaded, setOnMapLoaded] = useState(true);
    const [onlocationMaploaded, setOnlocationMapLoaded] = useState(true);
    const [showCircle, setShowCircle] = useState(false);
    const [showPoly, setShowPoly] = useState(false);
    const [showStoppedMarker, setShowStoppedMarker] = useState(0);
    const [showNonStoppedMarker, setShowNonStoppedMarker] = useState(0);
    const [DeviceZoomLevel, setDeviceZoomLevel] = useState(15)
    const [initialZoom, setInitialZoom] = useState(null)
    const [isMapCentered, setIsMapCentered] = useState(false);
    const [circleRadius, setCircleRadius] = useState(0)
    const [allPoints, setAllPoints] = useState(null)
    const [stoppedPoints, setStoppedPoints] = useState([])
    const [bigClosestPoint, setBigClosestPoint] = useState(null)
    const dispatch = useDispatch();
    const { devicesState } = useSelector(
      (state) => ({ devicesState: state.devices }),
      shallowEqual
    );
    const { alarmState } = useSelector(
      (state) => ({ alarmState: state.alarms }),
      shallowEqual
    );
    const { mapSettingsState } = useSelector(
      (state) => ({ mapSettingsState: state.mapSettings }),
      shallowEqual
    );
    const { locationHistoryState } = useSelector(
      (state) => ({ locationHistoryState: state.locationHistory }),
      shallowEqual
    );
    const { streamState } = useSelector(
      (state) => ({ streamState: state.liveStream }),
      shallowEqual
    );
    const { alarmsState } = useSelector(
      (state) => ({ alarmsState: state.alarms }),
      shallowEqual
    );
    let { token } = useParams();

    const { geofencesState } = useSelector(
      (state) => ({ geofencesState: state.geofences }),
      shallowEqual
    );
    //You might be wondering why I (Dilpazir Ahmad) did that. The reason is
    //Any state that you need in some kind of event e.g. setTimeout, setInterval, sockets
    //You need to store that state in ref and use that otherwise events only pick the state value which was provided to them when the event was registered
    const traceUnitDataRef = React.useRef(devicesState.traceUnitData);

    const setTraceUnitData = (x) => {
      traceUnitDataRef.current = _.cloneDeep(x); // keep updated
      dispatch(deviceActions.setTraceUnitData({ traceUnitData: x }));
    };
    const getDevices = async () => {
      const response = await devicesService2.getDevicesInfo();
      if (response.status === 200) {
        const { data } = response;
        if (data.hasOwnProperty("errorCode") && data.errorCode !== "") {
          dispatch(
            deviceActions.setMapDevices({
              mapDevices: [],
            })
          );
        } else {
          let temp = _.cloneDeep(traceUnitDataRef.current);
          data.forEach((d) => {
            let index = temp.findIndex((t) => t.deviceid === d.id);
            if (index > -1) {
              let tData = _.cloneDeep(temp[index]);
              let { locations } = tData;
              let lastLocation = locations[locations.length - 1];
              if (
                d.latitude &&
                d.longitude &&
                lastLocation.lat !== d.latitude &&
                lastLocation.lng !== d.longitude
              ) {
                locations.push({
                  lat: parseFloat(d.latitude),
                  lng: parseFloat(d.longitude),
                });
                if (locations.length === 11) {
                  locations.shift();
                }
                tData.locations = locations;
                temp[index] = tData;
              }
            } else if (d.latitude && d.longitude) {
              let tData = {
                deviceid: d.id,
                locations: [
                  { lat: parseFloat(d.latitude), lng: parseFloat(d.longitude) },
                ],
              };
              temp.push(tData);
            }
          });
          setTraceUnitData(temp);
          dispatch(
            deviceActions.setMapDevices({
              mapDevices: data,
            })
          );
          dispatch(
            deviceActions.setMapDevicesOrignal({
              mapDevicesorignal: data,
            })
          );
        }
      }
    };

    const expandMap = () => {
      const map = document.querySelector(".map_style");
      const mapExpanded = document.querySelector(".map_style_expanded");
      const info = document.querySelector(".section-left_bottom_data");
      const infoExpanded = document.querySelector(".section-left_bottom_data_expanded");
      const statsCard = document.querySelector(".map_statistics_detail");
      const statsCardExtended = document.querySelector(".map_statistics_detail_expanded");
    
      if (map === null) {
        infoExpanded.classList.replace("section-left_bottom_data_expanded", "section-left_bottom_data");
        if (mapExpanded) {
          mapExpanded.classList.replace("map_style_expanded", "map_style");
        }
        if (statsCardExtended) {
          statsCardExtended.classList.replace("map_statistics_detail_expanded", "map_statistics_detail");
        }
      } else {
        info.classList.replace("section-left_bottom_data", "section-left_bottom_data_expanded");
        if (map) {
          map.classList.replace("map_style", "map_style_expanded");
        }
        if (statsCard) {
          statsCard.classList.replace("map_statistics_detail", "map_statistics_detail_expanded");
        }
      }
    };
    

    const getAlarms = async () => {
      const response = await genericService.get("events/list");
      if (response.status === 200) {
        const { data } = response;
        dispatch(
          alarmActions.setMapAlarms({
            alarms: data,
          })
        );
      }
    };

    const getGeofences = async () => {
      const response = await genericService.get("geofence/info");
      if (response.status === 200) {
        const geofences = response.data ?? [];
        dispatch(geofencesActions.setMapGeofences({ geofences }));
      }
    };

    useEffect(() => {
      if (streamState.streamingMapCenter.length) {
        setMapCenter(
          streamState.streamingMapCenter[0].lat,
          streamState.streamingMapCenter[0].lng
        );
      }

    }, [streamState.streamingMapCenter]);
    const getLimitedAlarm = async () => {
      const limitedAlarmResponse = await genericService.get("/events/list/limitformap");
      if (limitedAlarmResponse.status === 200) {
        const alarms = limitedAlarmResponse.data;
        dispatch(
          alarmActions.setLimittedAlarm({
            limitedAlarm: alarms
          })
        );
      }
    }
    useEffect(() => {
      let intervalId
      if (mapSettingsState.show_alarms) {
        intervalId = setInterval(() => {
          getLimitedAlarm();
        }, 10000);
      }
      return () => clearInterval(intervalId);
    });
    useEffect(() => {
      if (mapSettingsState.show_geofence && props.page !== "urlscreen") {
        getGeofences();
      }
    }, [mapSettingsState.show_geofence]);

    useEffect(() => {
      if (props.page === "alarm" && process.env.REACT_APP_FLAVOR !== "Alco") {
        getAlarms();
      } else if (props.page === "urlscreen") {
        dispatch(
          deviceActions.setMapDevices({
            mapDevices: [],
          })
        );
        dispatch(
          alarmActions.setMapAlarms({
            alarms: [],
          })
        );
      } else {
        dispatch(
          deviceActions.setMapDevices({
            mapDevices: [],
          })
        );
        dispatch(
          alarmActions.setMapAlarms({
            alarms: [],
          })
        );
      }
      var interval
      if (props.page === "devices") {
        interval = setInterval(() => {
          if (
            devicesPageRef.current === "devices" && !devicesState.showgroupssetting
          ) {
            getDevices();
          }
        }, 10000);
      }
      if (!devicesState.showgroupssetting && props.page !== "urlscreen") {
        getDevices();
      }
      else {
        getStreamingDevices()
      }
      return () => {
        if (props.page === "devices") {
          clearInterval(interval);
        }
      };
    }, [devicesState.showgroupssetting]);
    const getStreamingDevices = async () => {
      const response = await genericService.post('streaminglive/public/', {
        streamtoken: token
      });
      if (response.status === 200) {
        const data = response.data[0];
        dispatch(
          deviceActions.setMapDevices({
            mapDevices: data.devices,
          })
        );
      }
    }
    const [devicesPage, _setDevicesPage] = useState(devicesState.page);
    const devicesPageRef = React.useRef(devicesPage);
    const setDevicesPage = (x) => {
      devicesPageRef.current = x; // keep updated
      _setDevicesPage(x);
    };

    useEffect(() => {
      setDevicesPage(devicesState.page);
    }, [devicesState.page]);

    const drawCircle = () => {
      let latitude = parseFloat(_.clone(mapRef.getCenter().lat()));
      let longitude = parseFloat(_.clone(mapRef.getCenter().lng()));
      let radius = 1000;
    //  setInitialZoom(mapRef.getZoom())
      if (geofencesState.editgeofence) {
        const editdata = geofencesState.editgeofence;
        if (editdata.type === "POLYGON") {
          latitude = editdata.points[0].latitude;
          longitude = editdata.points[0].longitude;
        } else {
          latitude = editdata.latitude;
          longitude = editdata.longitude;
          console.log(editdata);
          //console.log(editdata)
           radius = editdata.radius;
        }

        radius = parseFloat(radius);
        setCircleRadius(radius)
        setMapCenter(latitude, longitude);
      }

      setShowCircle(true);

      dispatch(
        geofencesActions.setCircleData({
          radius,
          latitude,
          longitude,
        })
      );
    };

    const removeCircle = () => {
      setShowCircle(false);
      dispatch(geofencesActions.setCircleData({}));
    };

    const drawPoly = () => {
    //  setInitialZoom(8)
      let latitude = _.clone(currentLatitude);
      let longitude = _.clone(currentLatitude);
      let path = [
        { 
          lat: currentLatitude, 
          lng: currentLongitude
        },
        {
          lat: parseFloat(currentLatitude) - 0.01,
          lng: currentLongitude + 0.02,
        },
        {
          lat: parseFloat(currentLatitude) - 0.01,
          lng: currentLongitude - 0.02,
        },
      ];
      let editpath = [
        [currentLatitude, currentLongitude],
        [parseFloat(currentLatitude) - 0.01, currentLongitude + 0.02],
        [parseFloat(currentLatitude) - 0.01, currentLongitude - 0.02],
      ];

      if (geofencesState.editgeofence) {
        const editdata = geofencesState.editgeofence;
        if (editdata.type === "POLYGON") {
          latitude = editdata.points[0].latitude;
          longitude = editdata.points[0].longitude;
        } else {
          latitude = editdata.latitude;
          longitude = editdata.longitude;
        }
        setMapCenter(latitude, longitude);
        const pointsTemp = editdata.points;
        if (pointsTemp) {
          console.log("ÄR I EDIT");
          console.log(pointsTemp);
          path = [];
          editpath = [];
          pointsTemp.forEach((point) => {
            path.push({ lat: point.latitude, lng: point.longitude });
            editpath.push([point.latitude, point.longitude]);
          });
        }
      }
      setShowPoly(true);
      //console.log(path);
      setPolygonepath(path);
      console.log(editpath);
      dispatch(
        geofencesActions.setPolygonData({
          path: editpath,
        })
      );
    };

    const removePoly = () => {
      setShowPoly(false);
      dispatch(geofencesActions.setPolygonData({ path: [] }));
      setPolygonepath([]);
    };

    const onMapZoomChanged = () => {
      const zoomtemp = mapRef.getZoom();
      dispatch(deviceActions.setZoomLevel({ zoomlevel: zoomtemp }))
      setMapZoom(zoomtemp);
    };

    const onMapCenterChanged = () => {
      currentLatitude = mapRef.getCenter().lat();
      currentLongitude = mapRef.getCenter().lng();
    };

    const onCircleMounted = (ref) => {
      if (!circleRefs && ref) circleRefs = ref;
    };

    const onMapMounted = (ref) => {
      let present = false;
      let total = 0;
      let bounds;
    
      const extendBoundsForDevice = (device) => {
        if (device.latitude && device.longitude) {
          total += 1;
          present = true;
          bounds.extend({
            lat: parseFloat(device.latitude),
            lng: parseFloat(device.longitude),
          });
        }
      };
    
      const checkAndFitBounds = () => {
        if (present) {
          setOnMapLoaded(false);
          mapRef.fitBounds(bounds);
        } else {
          const customBound = {
            south: 22.718975,
            west: 3.95446,
            north: 67.1374888888889,
            east: 113.89699777777777,
          };
          mapRef.fitBounds(customBound);
        }
      };
    
      if (ref) {
        mapRef = ref;
        bounds = new window.google.maps.LatLngBounds();
      }
    
      if (onMaploaded && bounds && props.page !== "showhistory" && props.page !== "urlscreen") {
        devicesState.mapDevices.forEach(extendBoundsForDevice);
        checkAndFitBounds();
      } else if (onMaploaded && bounds && props.page === "urlscreen") {
        devicesState.mapDevices.forEach((device) => {
          // Assuming device.points is an array and you're only interested in the first point
          const point = device.points && device.points[0];
          if (point) {
            extendBoundsForDevice(point);
          }
        });
        checkAndFitBounds();
      }
    
      if (
        onlocationMaploaded &&
        locationHistoryState.activeDevice &&
        bounds &&
        (props.page === "showhistory" || props.page === "locationHistory") &&
        props.page !== "urlscreen"
      ) {
        locationHistoryState.activeDevice.points.forEach((point) => {
          if (point.latitude && point.longitude) {
            bounds.extend({
              lat: parseFloat(point.latitude),
              lng: parseFloat(point.longitude),
            });
          }
        });
    
        if (bounds) {
          setOnlocationMapLoaded(false);
          mapRef.fitBounds(bounds);
        }
      }
    
      setTimeout(() => {
        if (present && total < 2) {
          setInitialZoom(12);
        }
      }, 300);
    };



    const onPolyMounted = (ref) => {
      if (!polyRefs && ref) polyRefs = ref;
    };
    const updateCircleConfigs = () => {
       const radius = circleRefs.getRadius();
      const latitude = circleRefs.getCenter().lat();
      const longitude = circleRefs.getCenter().lng();
      dispatch(geofencesActions.setCircleData({ radius, latitude, longitude }));
    };
    const setStoppedMarker = (id) => {

      setShowStoppedMarker(id)
    }
    const updatePolyConfigs = () => {
      const tempPath = [];
      const edittemppath = [];
      polyRefs.getPath().forEach((element, i) => {
        const path = { lat: element.lat(), lng: element.lng() };
        const editpath = [element.lat(), element.lng()];
        tempPath.push(path);
        edittemppath.push(editpath);
      });
      setPolygonepath(tempPath);
      dispatch(geofencesActions.setPolygonData({ path: edittemppath }));
    };

    useEffect(() => {
      if (geofencesState.shapeMethod === "Circle") {
        //draw circle and clear poly
        drawCircle();
        removePoly();
      } else if (geofencesState.shapeMethod === "Poly") {
        //draw poly and clear circle
        removeCircle();
        drawPoly();
      } else {
        //remove all shapes
        removeCircle();
        removePoly();
      }
    }, [geofencesState.shapeMethod]);

    const focusGeoFenceOnMap = (geofence) => {
      let latitude, longitude;

      if (geofence.type === "CIRCLE") {
        latitude = parseFloat(geofence.latitude);
        longitude = parseFloat(geofence.longitude);
      } else if (geofence.type === "POLYGON") {
        latitude = parseFloat(geofence.points[0].latitude);
        longitude = parseFloat(geofence.points[0].longitude);
      }
      setMapCenter(latitude, longitude);
    };

    const setMapCenter = (lat, lng) => {

      currentLatitude = lat;
      currentLongitude = lng;
      if (mapRef) mapRef.panTo({ lat, lng });
    };

    useEffect(() => {
      //if some id is passed, set focus to that geofence; if it is null do nothing
      if (geofencesState.focusGeofenceOnMap) {
        let theGeofence = geofencesState.mapGeofences.find(
          (geofence) => geofence.id === geofencesState.focusGeofenceOnMap
        );
        focusGeoFenceOnMap(theGeofence);
        dispatch(geofencesActions.focusGeofenceOnMap({ id: null }));
      }
    }, [geofencesState.focusGeofenceOnMap, geofencesState.mapGeofences]);
    const getZoomLevel = () => {
       if (devicesState.selectedMapDeviceId || alarmsState.selectedAlarmMarkerid) {
        return 16
      }
      else if (props.page === "urlscreen") {
        return 12
      }else{
        return 8
      }
    }
    useEffect(() => {
      if (alarmsState.selectedAlarmMarkerid) {
        let theAlarm = alarmsState.mapAlarms.find(
          (alarm) => alarm.eventid === alarmsState.selectedAlarmMarkerid
        );
        if (theAlarm) {
          //GUSTAV
          setAlarmOnMap(theAlarm)
          setMapCenter(theAlarm.latitude, theAlarm.longitude)
          setInitialZoom(16)
        }
      }else{
        setAlarmOnMap({})

      }
    }, [alarmsState.selectedAlarmMarkerid, alarmsState.mapAlarms]);

    useEffect(() => {
      setIsMapCentered(false);
    }, [locationHistoryState.activeDevice]);

    const onPolylineMounted = (ref) => {
      //redirect map to the poly
      if (!isMapCentered) {
        const point = locationHistoryState.activeDevice.points[0];
        setMapCenter(point.latitude, point.longitude);
        setIsMapCentered(true);
      }
    };

    useEffect(() => {
      if(locationHistoryState.activeDevice){
      let point = locationHistoryState.activeDevice.points[locationHistoryState.dataPoint]
      if(bigClosestPoint != point){
        setBigClosestPoint(point)
      }else{
        setBigClosestPoint(null)
      }
    }
    }, [locationHistoryState.dataPoint])

    const onPolylineClick = (e) => {
      const points = locationHistoryState.activeDevice.points;

      const clickLat = e.latLng.lat();
      const clickLng = e.latLng.lng();

      let closestPoint = points[0];
      let minDistance = Number.MAX_VALUE;


      for (const point of points) {
          const distance = haversineDistance(clickLat, clickLng, point.latitude, point.longitude);
          if (distance < minDistance) {
              minDistance = distance;
              closestPoint = point;
          }
      }
      if(bigClosestPoint != closestPoint){
        setBigClosestPoint(closestPoint)
      }else{
        setBigClosestPoint(null)
      }
      // Now, closestPoint is the nearest point on your polyline to the click

      console.log('Closest point:', closestPoint);
  };

  // Haversine Distance formula to calculate distance between two points
  const haversineDistance = (lat1, lng1, lat2, lng2) => {
      const toRad = (x) => (x * Math.PI) / 180;
      const R = 6371; // Earth radius in km

      const dLat = toRad(lat2 - lat1);
      const dLng = toRad(lng2 - lng1);
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * 
                Math.sin(dLng / 2) * Math.sin(dLng / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return R * c; // Distance in km
  };

    useEffect(() => {
      setAllPoints(null)
     // setLocationHistoryPoints(locationHistoryState.activeDevice && locationHistoryState.activeDevice.points)
     if (locationHistoryState.activeDevice && locationHistoryState.activeDevice.points) {
      const newPoints = locationHistoryState.activeDevice.points.map(point => ({
        lat: point.latitude,
        lng: point.longitude
      }));
      setAllPoints(newPoints)
      let newStoppedPoints = [];

      for (let i = 0; i < locationHistoryState.activeDevice.points.length; i++) {
          const point = locationHistoryState.activeDevice.points[i];
      
          if (i < locationHistoryState.activeDevice.points.length - 1) { // Check if it's not the last point
              const nextPoint = locationHistoryState.activeDevice.points[i + 1];
              const pointTime = new Date(point.datetime);
              const nextPointTime = new Date(nextPoint.datetime);
      
              const timeDiff = nextPointTime - pointTime; // Time difference in milliseconds
              const tenMinutes = 10 * 60 * 1000; // Ten minutes in milliseconds
      
              if (point.speed === 0 && timeDiff > tenMinutes) {
                  newStoppedPoints.push(point);
              }
          }
      }
      setStoppedPoints(newStoppedPoints)
     }
    }, [locationHistoryState.activeDevice])

    const showPolyline = () => {
      return (
        <>
          <Marker
            icon={{
              url:
                FlavorImages.locationhistoryStart,
            }}
            position={{
              lat: parseFloat(allPoints && allPoints[0].lat),
              lng: parseFloat(allPoints && allPoints[0].lng),
            }}
          />
          {stoppedPoints.map((point, iteration) => {
            return ( 
              <DeviceStoppedMarker
                key={iteration}
                showStoppedMarker={showStoppedMarker === point.positiondid}
                setShowStoppedMarker={setShowStoppedMarker}
                point={point}
              />
            );
          })}
          {bigClosestPoint && 
            <DeviceNonStoppedMarker
             // showNonStoppedMarker={showNonStoppedMarker === point.positiondid}
             // setShowNonStoppedMarker={setShowNonStoppedMarker}
             hideMarker={() => setBigClosestPoint(null)}
              point={bigClosestPoint}
            />
          }
          <Marker
            icon={{
              // scaledSize: new window.google.maps.Size(40, 40),
              // anchor: new window.google.maps.Point(5, 40),
              url:
              FlavorImages.locationhistoryEnd,
            }}
            position={{
              lat: parseFloat(allPoints && allPoints[allPoints.length - 1].lat),
              lng: parseFloat(allPoints && allPoints[allPoints.length - 1].lng),
            }}
          />
                  <Polyline
                  ref={onPolylineMounted}
                  onClick={onPolylineClick}
                  defaultOptions={{
                    cursor: "pointer",
                    fillColor: "#4faadb",
                    fillOpacity: "0.3",
                    strokeColor: "#4faadb",
                    strokeWeight: "5",
                  }}
                  defaultPath={allPoints}
                />
        </>
      );
    };

    useEffect(() => {
      if (devicesState.selectedMapDeviceId) {
        let theDevice = devicesState.mapDevices.find(
          (device) => device.id === devicesState.selectedMapDeviceId
        );
        //console.log(theDevice.latitude, theDevice.longitude)
        setMapCenter(
          parseFloat(theDevice.latitude),
          parseFloat(theDevice.longitude)
        );
        setInitialZoom(16)
      }
    }, [devicesState.selectedMapDeviceId]);

    const drawCircleOnMap = () => {
      return <Circle
        defaultOptions={{
          fillColor: "#4faadb",
          fillOpacity: "0.5",
          strokeColor: "#4faadb",
          strokeWeight: "2",

        }}
        ref={onCircleMounted}
        onRadiusChanged={updateCircleConfigs}
        onMouseUp={updateCircleConfigs}
        onDragEnd={updateCircleConfigs}
        // onDrag={updateCircleConfigs}
        onCenterChanged={updateCircleConfigs}
        defaultCenter={{ lat: currentLatitude, lng: currentLongitude }}
        defaultRadius={circleRadius ? circleRadius : Math.sqrt(radiusesWithZoom["zoom" + initialZoom ?? 9])}
        editable
        // draggable
      />
    }

    useEffect(() => {
      if(props.page == "addeditgeofence" && (showPoly || showCircle)){
        setInitialZoom(12)
      }
    }, [showPoly, showCircle])

    return (
      <GoogleMap
        onZoomChanged={props.page === "addeditgeofence" ? () => { setInitialZoom(mapRef.getZoom()) } : () => { setInitialZoom(mapRef.getZoom()) }}
        onCenterChanged={props.page === "addeditgeofence" ? onMapCenterChanged : () => { }}
        restriction={defaultProps.restriction}
        defaultZoom={8}
        zoom={initialZoom}
        ref={onMapMounted}
        // maxZoom: 18,
        options={{minZoom: 2, zoom: initialZoom, restriction: {latLngBounds: MAP_BOUNDS}, styles: props.mapType === 'DarkMode' ? mapStyles : props.mapType === 'roadmap' ? lightMode : "" , gestureHandling: "greedy" }}        defaultClickableIcons={false}
        defaultCenter={{ lat: defaultProps.center.lat, lng: defaultProps.center.lng }}
        mapTypeId={props.mapType === 'DarkMode' ? 'roadmap' : props.mapType}
      >
        {/* {console.log(mapRef)} */}
        {
          mapSettingsState.cluster_markers ? (
            <MarkerClusterer
              averageCenter
              gridSize={40}
              maxZoom={24}
              minimumClusterSize={2}
              styles={[
                {
                  url: FlavorImages.cluster_map,
                  height: 45,
                  width: 40,
                  anchorText: [-4, 1],
                  textColor: "#FFF",
                  fontFamily: "Roboto",
                  textSize: 15,
                  fontWeight: "bold",
                },
              ]}
            >
              {devicesState.mapDevices.map((device, index) => {
                return device.latitude && device.longitude ? (
                  device.streaming && devicesState.liveStream ? (
                    <DeviceStreamingMarker
                      key={`device_${index}`}
                      device={device}
                      liveStream={devicesState.liveStream}
                    />
                  ) : (
                    <DeviceMarker key={`device_${index}`} device={device} />
                  )
                ) : (
                  ""
                );
              })}
            </MarkerClusterer>
          ) : devicesState.mapDevices.map((device, index) => {
            return device.latitude && device.longitude ? (
              device.streaming && devicesState.liveStream ? (
                <DeviceStreamingMarker
                  key={`device_${index}`}
                  device={device}
                  liveStream={devicesState.liveStream}
                />
              ) : (
                props.page !== "urlscreen" &&
                <DeviceMarker key={`device_${index}`} device={device} />
              )
            ) : (
              ""
            );
          })
        }
        {alarmOnMap && 
            <AlarmMarker  device={alarmOnMap} />
        }
        {
          React.useMemo(() =>
            mapSettingsState.show_alarms && alarmsState.limitedAlarm.map((device, index) => {
              return <AlarmMarker key={`alarm_${index}`} device={device} />;
            })
            , [alarmsState.limitedAlarm, mapSettingsState.show_alarms]
          )}
        {
          // alarmsState.mapAlarms.map((device, index) => {
          //   if (device.eventid === alarmState.selectedAlarmMarkerid) {
          //     return <AlarmMarker key={`alarm_${index}`} device={device} />;
          //   }
          // })

        }
        {showCircle && drawCircleOnMap()}
        {showPoly && (
          <Marker
          >
            <Polygon
              defaultOptions={{
                fillColor: "#4faadb",
                fillOpacity: "0.5",
                strokeColor: "#4faadb",
                strokeWeight: "2",
              }}
              ref={onPolyMounted}
              onDragEnd={updatePolyConfigs}
              onDrag={updatePolyConfigs}
              onMouseUp={updatePolyConfigs}
              defaultPaths={polyGonepath}
              editable
              draggable
            >
            </Polygon>
            <InfoWindow>
              <div
                className={`custom-ul-header rounded-circle   py-2 px-2`}
              >
                <span className="text_11_700 ellipsis pr-1" >{props.deviceName}</span>
                <img
                  className="custom-ul-header-arrow"
                  alt="show"
                  src={`${process.env.REACT_APP_BASE_URL}images/custom-dropdown-arrow.svg`}
                />
              </div>
            </InfoWindow>
          </Marker>
        )}
        {
          mapSettingsState.show_geofence &&
          geofencesState.mapGeofences.map((geofenceNotEditable, index) => {
            let geofence = _.cloneDeep(geofenceNotEditable);
            let points = [];
            if (geofence.type === "POLYGON") {
              points = geofence.points;
              for (const point of points) {
                point.lat = parseFloat(point.latitude);
                point.lng = parseFloat(point.longitude);
                delete point.latitude;
                delete point.longitude;
              }
            }
            return geofence.type === "CIRCLE" ? (
              <Marker
                key={index}
                position={{ lat: geofence.latitude, lng: geofence.longitude, }}
                visible={false}
              >
                <Circle
                  key={index}
                  defaultOptions={{
                    fillColor: "#4faadb",
                    fillOpacity: "0.5",
                    strokeColor: "#4faadb",
                    strokeWeight: "2",
                  }}
                  defaultCenter={{
                    lat: geofence.latitude,
                    lng: geofence.longitude,
                  }}
                  ref={onCircleMounted}
                  defaultRadius={parseFloat(geofence.radius)}
                />
                <InfoWindow>
                  <div className={`custom-tooltip-container color4 mt-n3  rounded-circle`}>
                    <div
                      className="fade show tooltip bs-tooltip-bottom"
                      data-popper-reference-hidden="false"
                      data-popper-escaped="false"
                      data-popper-placement="bottom"
                    >
                      <div className="tooltip-inner tooltip-inner2 line_height_point_0">
                        <span className="text_17_700_2 text-white line_height_point_7"> {geofence.name}</span>
                      </div>
                    </div>
                  </div>
                </InfoWindow>

              </Marker>
            ) : (
              <Marker
                key={index}
                position={{ lat: points[1].lat, lng: points[1].lng }}
                visible={false}
              >
                <Polygon
                  key={index}
                  defaultOptions={{
                    fillColor: "#4faadb",
                    fillOpacity: "0.5",
                    strokeColor: "#4faadb",
                    strokeWeight: "2",
                  }}
                  defaultPaths={points}
                ></Polygon>

                <InfoWindow>
                  <div className={`custom-tooltip-container color4 mt-n3  rounded-circle`}>
                    <div
                      className="fade show tooltip bs-tooltip-bottom"
                      data-popper-reference-hidden="false"
                      data-popper-escaped="false"
                      data-popper-placement="bottom"
                    >
                      <div className="tooltip-inner tooltip-inner2 line_height_point_0">
                        <span className="text_17_700_2 text-white line_height_point_7"> {geofence.name}</span>
                      </div>
                    </div>
                  </div>
                </InfoWindow>

              </Marker>

            );
          })
        }

        {(locationHistoryState.activeDevice && allPoints) ? showPolyline() : ""}
        {/* {locationHistoryState.activeDevice && process.env.REACT_APP_FLAVOR == "Bike" ? showRouteLine() : ""} */}
        {/* {
          React.useMemo(() => {
            if (locationHistoryState.activeDevice) {
              const pointsTemp = locationHistoryState.activeDevice.points;
              const points = [];
              pointsTemp.forEach((point) => {
                points.push({ lat: point.latitude, lng: point.longitude });
              });
              return (
                <Polyline
                  ref={onPolylineMounted}
                  defaultOptions={{
                    cursor: "grab",
                    fillColor: "#4faadb",
                    fillOpacity: "0.3",
                    strokeColor: "#4faadb",
                    strokeWeight: "5",
                  }}
                  defaultPath={points}
                />
              )
            }

          }, [locationHistoryState.activeDevice])} */}


        {mapSettingsState.trace_unit &&
          traceUnitDataRef.current.map((tData, index) => {
            return (
              <React.Fragment key={index}>
                <Polyline
                  defaultOptions={{
                    fillColor: "#4faadb",
                    fillOpacity: "0.5",
                    strokeColor: "#4faadb",
                    strokeWeight: "5",
                  }}
                  defaultPath={tData.locations}
                  path={tData.locations}
                />
              </React.Fragment>
            );
          })}
        {props.page === "urlscreen" && streamState.locationLiveStream && streamState.streamingMapCenter.length && (
          <>
            <Polyline
              defaultOptions={{
                fillColor: "#4faadb",
                fillOpacity: "0.5",
                strokeColor: "#4faadb",
                strokeWeight: "5",
              }}
              path={streamState.locationLiveStream}
            />
            <Marker
              icon={{
                url:
                  process.env.REACT_APP_BASE_URL +
                  "images/markers/live-stream-marker.svg",
              }}
              position={{ lat: streamState.streamingMapCenter[0].lat, lng: streamState.streamingMapCenter[0].lng }}
            />
          </>
        )}

        <button className="btn expand_map" onClick={() => expandMap ()}>
        <img src={FlavorImages.expand} className="expand_map_icon white_icon cursor-pointer" alt="..." />
        </button>

      </GoogleMap>
    );
  })
);

export default RenderMap;
