import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual } from "react-redux";
import BackWardArrow from "../../components/Buttons/BackwardArrow";
// import forward_arrow_grey from "../../assets/images/forward_arrow_grey.svg";
// import dots_vertical from "../../assets/images/dots_vertical.svg";
import { useDispatch } from "react-redux";
import { devicesSlice } from "../../slice/deviceSlice";
import ConfirmDelete from "./ConfirmDelete";
import * as genericService from "../../services/genericService";
import ErrorDialog from "../LocationHistory/ErrorDialog";
import EditSpeedLimit from "./EditSpeedLimit"
import DeleteData from "./DeleteData"
import * as devicesService2 from "../../services/devices/devicesService2";
import EditBikeName from "./EditBikeName";
import toast, { Toaster } from 'react-hot-toast';
import FlavorImages from "../../assetsFolderImport";
let flavor = process.env.REACT_APP_FLAVOR; //when we have APIs for helkama; remove static value and use flavor from env

const BikeDetail = (props) => {
  const { t } = useTranslation();
  const [bikeDetail, setBikeDetail] = useState([]);
  const [editBike, setEditBikeName] = useState(false);
  const [editBikesepeed, setEditbikeSpeed] = useState(false)
  const [deleteDataModal, setDeleteDataModal] = useState(false)
  const [deviceName, setDeviceName] = useState("");
  const [error, setError] = useState("");
  const [sharedbyme, setsharedbyme] = useState("");
  const [editdetail, setEditdetail] = useState("")
  const [showDeleteHistory, setShowDeleteHistory] = useState(false);
  const [errorDialog, setShowErrorDialog] = useState(false);
  const [speedLimit, setspeedLimit] = useState("")
  const [pinSelected, setMapPinSelected] = useState("blue");
  const [iconName, setIconName] = useState("caravan");
  const [bikeSpeed, setBikeSpeed] = useState("")
  //console.log("🚀 ~ file: BikeDetail.js:28 ~ BikeDetail ~ bikeSpeed", bikeSpeed)
  const dispatch = useDispatch();
  const deviceActions = devicesSlice.actions;
  const { devicesState } = useSelector(
    (state) => ({ devicesState: state.devices }),
    shallowEqual
  );
  const setbikeName = (bikeName) => {
    setDeviceName(bikeName)
  }
  const handleIconChange = async (iconName) => {
    setIconName(iconName);
    let fullIconName = iconName + "_" + pinSelected;
    let data = {
      mapicon: fullIconName,
      deviceid: bikeDetail.id,
    };
    const response = await devicesService2.updateDevice(data);
    if (response.status === 200) {
      toast.success(t("toast.Saved"));
    } else {
    }
  };
  const setMapPins = async (event) => {
    setMapPinSelected(event.target.alt);
    let fullIconName = iconName + "_" + event.target.alt;
    let data = {
      mapicon: fullIconName,
      deviceid: bikeDetail.id,
    };
    const response = await devicesService2.updateDevice(data);
    if (response.status === 200) {
      toast.success(t("toast.Saved"));
    } else {
    }
  };
  const setEditBikeSpeedPopup = (speedLimit) => {
    setspeedLimit(speedLimit)
    setEditbikeSpeed(true)
  }
  const setDeleteDataPopup = () => {
    setDeleteDataModal(!deleteDataModal)
  }
  
  const setDownloadLink = async () => {
    const response = await genericService.get("services/downloaderrorlog")
  }
  const checkDataplane = (bikeDetail) => {
    if (
      bikeDetail.dataplanpaymentmethod === "fail" ||
      bikeDetail.dataplanpaymentmethod === "cancelled" ||
      bikeDetail.dataplanpaymentmethod === null || bikeDetail.dataplanpaymentmethod === undefined ||
      bikeDetail.dataplanpaymentmethod === "card_cancel_at_period_end"
    ) {
      props.setSelect("DeleteBike")
    }
    else {
      props.setSelect("DataPlan")
    }
  }
  const removeHistory = async () => {
    const response = await genericService.post("history/delete", {
      deviceid: bikeDetail.id,
    });
    if (response.status === 200) {
      setShowDeleteHistory(false);
      toast.success(t("toast.Done"));

    } else {
      setShowDeleteHistory(false);
      setShowDeleteHistory(false);
      toast.error(response.data.message);
    }
  };
  const deviceDetail = () => {
    devicesState.devices.map((device) => {
      if (device.name === props.deviceName) {
        setBikeDetail(device);
        setDeviceName(device.name);
        setBikeSpeed(device.modelspecificinfo?.speedlimit
          .speedlimitinfo)
        let icon_splitted = device.mapicon.split("_");
        let iconMain = ["airplane", "ambulance", "bicycle", "boat", "bus", "car", "caravan", "container", "electricCar", "engine",
          "excavator", "forklift", "jet", "monsterTruck", "moped", "motorcycle", "pickup",
          "rv", "sailboat", "snowmobile", "default", "taxi", "tractor", "truck", "truckContainer", "van"]
        if (iconMain.includes(icon_splitted[0])) {
          setIconName(icon_splitted[0])
          setMapPinSelected(icon_splitted[1])
        }
        setsharedbyme(device.sharedstatus.sharedbyme);
        dispatch(
          deviceActions.setsharedbyme({
            sharedbyme: device.sharedstatus.sharedbyme,
          })
        );
      }
    });
  };
  useEffect(() => {
    deviceDetail();

  }, [devicesState.devices, props.deviceName]);
  //console.log(bikeDetail);
  return (
    <>
      <div
        className={`${props.showUI === true ? "show_ui" : "hide_ui"
          } col-md-8 d-flex  mt-3 flex-column  inner`}
      >
        <BackWardArrow show={true} onClick={() => props.setSelect("Bikes")} />
        <label className="ml-1 mt-4 text-uppercase text_18_700 text-left mt-2">
          {deviceName}
        </label>

        <div className="d-flex flex-column   ">
          <div onClick={() => setEditBikeName(true)} className="cursor-pointer p-3  w-100  d-flex border-bottom white_shadow rounded-top justify-content-between align-items-center">
            <label className=" text_16_400 text-navy my-0">
              {t("signup.Name")}{" "}
            </label>
            <div>
              <label className=" text_12_500 text-navy my-0">
                {deviceName}{" "}
              </label>
              <img
                onClick={() => setEditBikeName(true)
                }
                src={FlavorImages.dots_vertical}
                className="size_15 ml-2"
                alt="..."
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column   ">
          <div className="p-3 w-100 d-flex border-bottom white_shadow justify-content-between align-items-center">
            <label className=" text_16_400 text-navy my-0">
              {t("Device.IMEI")}{" "}
            </label>
            <div>
              <label className=" text_12_500 text-navy my-0">
                {bikeDetail.uniqueid}{" "}
              </label>
            </div>
          </div>
        </div>
        <div className={`${flavor === "Bike" ? "d-none" : "d-flex flex-column"}`}>
          <div className="p-3 w-100 d-flex border-bottom white_shadow justify-content-between align-items-center">
            <label className=" text_16_400 text-navy my-0">
              {t("Device.internalpower")}{" "}
            </label>
            <div>
              <label className=" text_12_500 text-navy my-0">
                {bikeDetail.modelspecificinfo?.internalbattery?.internalbatteryinfo}{" %"}
              </label>
            </div>
          </div>
        </div>
        {flavor === "Alco"
          ?
          null
          :
          <div
            onClick={() => setEditBikeSpeedPopup(bikeDetail?.modelspecificinfo?.speedlimit)}
            className=" cursor-pointer p-3  w-100  d-flex  border-bottom white_shadow justify-content-between align-items-center">
            <label className=" text_16_400 text-navy my-0">
              {t("Device.SpeedLimit")}{" "}
            </label>

            <div>
              {bikeDetail?.modelspecificinfo?.speedlimit.speedlimitvalid &&
                <label className=" text_12_500 text-navy my-0">
                  {
                    bikeSpeed
                  }{" "}
                  {
                    bikeDetail?.modelspecificinfo?.speedlimit
                      .speedlimitunit
                  }{" "}
                </label>
              }
              <img
                onClick={() => setEditBikeSpeedPopup(bikeDetail?.modelspecificinfo?.speedlimit)}
                src={FlavorImages.dots_vertical}
                className="size_15 ml-2"
                alt="..."
              />
            </div>
          </div>
        }
        {flavor === "Alco"
          ?
          null
          :
          <div
            onClick={() => setDeleteDataPopup()}
            className=" cursor-pointer p-3  w-100  d-flex  border-bottom white_shadow justify-content-between align-items-center">
            <label className=" text_16_400 text-navy my-0">
              {t("bikeDetail.delete_data")}{" "}
            </label>

            <div>
              <img
                onClick={() => setDeleteDataPopup()}
                src={FlavorImages.dots_vertical}
                className="size_15 ml-2"
                alt="..."
              />
            </div>
          </div>
        }
        <div onClick={() => props.setSelect("SharingStatus")} className=" cursor-pointer p-3  w-100  d-flex  border-bottom white_shadow justify-content-between align-items-center">
          <label className=" text_16_400  my-0">
            {t("bikeDetail.sharingStatus")}{" "}
          </label>
          <img
            onClick={() => props.setSelect("SharingStatus")}
            src={FlavorImages.forward_arrow_grey}
            className="size_15"
            alt="..."
          />
        </div>
        {process.env.REACT_APP_FLAVOR === "Bike"
        ? ""
        : <div onClick={() => setShowDeleteHistory(true)} className="cursor-pointer p-3  w-100  d-flex  border-bottom white_shadow justify-content-between align-items-center">
            <label className=" text_16_400  my-0">{t("Settings.DeleteHistory")} </label>

            <img
              onClick={() => setShowDeleteHistory(true)}
              src={FlavorImages.forward_arrow_grey}
              className="size_15"
              alt="..."
            />
          </div>
        }
        <div onClick={() => checkDataplane(bikeDetail)} className="cursor-pointer p-3 w-100   d-flex  rounded-bottom white_shadow justify-content-between align-items-center">
          <label className=" text_16_400 text-red my-0">
            {t("customFile.removeBike")}{" "}
          </label>

          <img
            onClick={() => checkDataplane(bikeDetail)}
            src={FlavorImages.forward_arrow_grey}
            className="size_15"
            alt="..."
          />
        </div>


        <div className={`${flavor === "Bike" ? "" : "d-none"}`}>

          <label className="ml-1 text-uppercase text_11_300 text-left mt-5">
            {t("bikeDetail.batteryHealth")}
          </label>
          <div className="d-flex flex-column   ">
            <div className="p-3 w-100   d-flex  border-bottom white_shadow rounded-top justify-content-between align-items-center">
              <label className=" text_16_400 text-navy my-0">
                {t("bikeDetail.BikeBattery")}{" "}
              </label>

              <div>
                {bikeDetail.communicationmode?.vehiclebattery.valid &&
                  bikeDetail.communicationmode?.vehiclebattery.info !== null ? (
                  <label className=" text_12_500 text-navy my-0">
                    {bikeDetail.communicationmode?.vehiclebattery.info + " %"}
                  </label>
                ) : !bikeDetail.communicationmode?.vehiclebattery.valid ? (
                  <label className=" text_12_500 text-navy my-0">
                    {t("bikeDetail.zeroPercent")}
                  </label>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="d-flex flex-column">
            <div className="p-3 w-100 d-flex rounded-bottom white_shadow justify-content-between align-items-center">
              <label className=" text_16_400 text-navy my-0">
                {t("bikeDetail.GpsBattery")}{" "}
              </label>

              <div>
                {bikeDetail?.modelspecificinfo?.internalbattery.internalbatteryvalid &&
                  bikeDetail?.modelspecificinfo?.internalbattery.internalbatteryinfo !== null ? (
                  <label className=" text_12_500 text-navy my-0">
                    {bikeDetail?.modelspecificinfo?.internalbattery.internalbatteryinfo + " %"}
                  </label>
                ) : bikeDetail?.modelspecificinfo?.internalbattery.internalbatteryvalid ? (
                  <label className=" text_12_500 text-navy my-0">
                    {t("errors.Error")}
                  </label>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
                  {/* Total batterycycles removed helkama request 
          <div className="d-flex flex-column  ">
            <div className="p-3  w-100  d-flex   white_shadow rounded-bottom justify-content-between align-items-center">
              <label className=" text_16_400 text-navy my-0">
                {t("bikeDetail.totalChargeCycles")}{" "}
              </label>

              <div>
                {bikeDetail.batteryhealth &&
                  bikeDetail.batteryhealth.totalchargecycles !== null ? (
                  <label className=" text_12_500 text-navy my-0">
                    {bikeDetail.batteryhealth.totalchargecycles}
                  </label>
                ) : bikeDetail.batteryhealth ? (
                  <label className=" text_12_500 text-navy my-0">{"0"}</label>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div> */}

        </div>
        {flavor === "Tracking" || flavor === "Tracking"
          ?
          <div className="d-flex scroll_bar_device_icon flex-column mt-3 p-1 w-100">
            <label className="ml-1 text-uppercase text_11_300 text-left mt-2">
              {t("Map Icon")}{" "}
            </label>
            <div className="d-flex justify-content-center w-100">
              <img
                onClick={(event) => setMapPins(event)}
                className={`map_icon_selection ${pinSelected === "blue" ? "width_40 " : "width_icon_50"
                  }`}
                alt="blue"
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/blue_pins/svg/${iconName}_blue.svg`).default}
              />
              <img
                onClick={(event) => setMapPins(event)}
                className={`map_icon_selection ${pinSelected === "dark" ? "width_40 " : "width_icon_50"
                  }`}
                alt="dark"
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/dark_pins/svg/${iconName}_dark.svg`).default}
              />
              <img
                onClick={(event) => setMapPins(event)}
                className={`map_icon_selection ${pinSelected === "green" ? "width_40 " : "width_icon_50"
                  }`}
                alt="green"
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/green_pins/svg/${iconName}_green.svg`).default}
              />
              {/* <img
                onClick={(event) => setMapPins(event)}
                className={`map_icon_selection ${pinSelected === "grey" ? "width_40 " : "width_icon_50"
                  }`}
                alt="grey"
                src={`${process.env.REACT_APP_BASE_URL}/images/device_icons/map_icons/grey_pins/svg/${iconName}_grey.svg`).default}
              /> */}
              <img
                onClick={(event) => setMapPins(event)}
                className={`map_icon_selection ${pinSelected === "light" ? "width_40 " : "width_icon_50"
                  }`}
                alt="light"
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/light_pins/svg/${iconName}_light.svg`).default}
              />
              <img
                onClick={(event) => setMapPins(event)}
                className={`map_icon_selection ${pinSelected === "orange" ? "width_40 " : "width_icon_50"
                  }`}
                alt="orange"
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/orange_pins/svg/${iconName}_orange.svg`).default}
              />
              <img
                onClick={(event) => setMapPins(event)}
                className={`map_icon_selection ${pinSelected === "yellow" ? "width_40 " : "width_icon_50"
                  }`}
                alt="yellow"
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/yellow_pins/svg/${iconName}_yellow.svg`).default}
              />
            </div>

            <div
              className={`d-flex justify-content-start  w-50 my-2`}
              style={{ overflowX: "scroll", marginLeft: "150px" }}
            >
              <img
                onClick={() => handleIconChange("default")}
                alt="Select icon"
                className={`map_icon_selection_2 ml-2 ${iconName === "default" ? "width_40 mb-1" : "width_icon_50"
                  }`}
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/default.svg`).default}
              />
              <img
                onClick={() => handleIconChange("airplane")}
                alt="Select icon"
                className={`map_icon_selection_2 ml-2 ${iconName === "airplane" ? "width_40 mb-1" : "width_icon_50"
                  }`}
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/airplane.svg`).default}
              />
              <img
                onClick={() => handleIconChange("ambulance")}
                alt="Select icon"
                className={`map_icon_selection_2 ml-2 ${iconName === "ambulance" ? "width_40 mb-1" : "width_icon_50"
                  }`}
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/ambulance.svg`).default}
              />
              <img
                onClick={() => handleIconChange("bicycle")}
                alt="Select icon"
                className={`map_icon_selection_2 ml-2 ${iconName === "bicycle" ? "width_40 mb-1" : "width_icon_50"
                  }`}
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/bicycle.svg`).default}
              />
              <img
                onClick={() => handleIconChange("boat")}
                alt="Select icon"
                className={`map_icon_selection_2 ml-2 ${iconName === "boat" ? "width_40 mb-1" : "width_icon_50"
                  }`}
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/boat.svg`).default}
              />
              <img
                onClick={() => handleIconChange("bus")}
                alt="Select icon"
                className={`map_icon_selection_2 ml-2 ${iconName === "bus" ? "width_40 mb-1" : "width_icon_50"
                  }`}
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/bus.svg`).default}
              />
              <img
                onClick={() => handleIconChange("car")}
                alt="Select icon"
                className={`map_icon_selection_2 ml-2 ${iconName === "car" ? "width_40 mb-1" : "width_icon_50"
                  }`}
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/car.svg`).default}
              />
              <img
                onClick={() => handleIconChange("electriccar")}
                alt="Select icon"
                className={`map_icon_selection_2 ml-2 ${iconName === "electriccar" ? "width_40 mb-1" : "width_icon_50"
                  }`}
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/electricCar.svg`).default}
              />
              <img
                onClick={() => handleIconChange("taxi")}
                alt="Select icon"
                className={`map_icon_selection_2 ml-2 ${iconName === "taxi" ? "width_40 mb-1" : "width_icon_50"
                  }`}
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/taxi.svg`).default}
              />
              <img
                onClick={() => handleIconChange("caravan")}
                alt="Select icon"
                className={`map_icon_selection_2 ml-2 ${iconName === "caravan" ? "width_40 mb-1" : "width_icon_50"
                  }`}
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/caravan.svg`).default}
              />
              <img
                onClick={() => handleIconChange("container")}
                alt="Select icon"
                className={`map_icon_selection_2 ml-2 ${iconName === "container" ? "width_40 mb-1" : "width_icon_50"
                  }`}
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/container.svg`).default}
              />
              <img
                onClick={() => handleIconChange("engine")}
                alt="Select icon"
                className={`map_icon_selection_2 ml-2 ${iconName === "engine" ? "width_40 mb-1" : "width_icon_50"
                  }`}
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/engine.svg`).default}
              />
              <img
                onClick={() => handleIconChange("excavator")}
                alt="Select icon"
                className={`map_icon_selection_2 ml-2 ${iconName === "excavator" ? "width_40 mb-1" : "width_icon_50"
                  }`}
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/excavator.svg`).default}
              />
              <img
                onClick={() => handleIconChange("forklift")}
                alt="Select icon"
                className={`map_icon_selection_2 ml-2 ${iconName === "forklift" ? "width_40 mb-1" : "width_icon_50"
                  }`}
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/forklift.svg`).default}
              />
              <img
                onClick={() => handleIconChange("helicopter")}
                alt="Select icon"
                className={`map_icon_selection_2 ml-2 ${iconName === "helicopter" ? "width_40 mb-1" : "width_icon_50"
                  }`}
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/helicopter.svg`).default}
              />
              <img
                onClick={() => handleIconChange("jet")}
                alt="Select icon"
                className={`map_icon_selection_2 ml-2 ${iconName === "jet" ? "width_40 mb-1" : "width_icon_50"
                  }`}
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/jet.svg`).default}
              />
              <img
                onClick={() => handleIconChange("monstertruck")}
                alt="Select icon"
                className={`map_icon_selection_2 ml-2 ${iconName === "monstertruck" ? "width_40 mb-1" : "width_icon_50"
                  }`}
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/monsterTruck.svg`).default}
              />
              <img
                onClick={() => handleIconChange("moped")}
                alt="Select icon"
                className={`map_icon_selection_2 ml-2 ${iconName === "moped" ? "width_40 mb-1" : "width_icon_50"
                  }`}
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/moped.svg`).default}
              />
              <img
                onClick={() => handleIconChange("motorcycle")}
                alt="Select icon"
                className={`map_icon_selection_2 ml-2 ${iconName === "motorcycle" ? "width_40 mb-1" : "width_icon_50"
                  }`}
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/motorcycle.svg`).default}
              />
              <img
                onClick={() => handleIconChange("pickup")}
                alt="Select icon"
                className={`map_icon_selection_2 ml-2 ${iconName === "pickup" ? "width_40 mb-1" : "width_icon_50"
                  }`}
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/pickup.svg`).default}
              />
              <img
                onClick={() => handleIconChange("rv")}
                alt="Select icon"
                className={`map_icon_selection_2 ml-2 ${iconName === "rv" ? "width_40 mb-1" : "width_icon_50"
                  }`}
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/rv.svg`).default}
              />
              <img
                onClick={() => handleIconChange("sailboat")}
                alt="Select icon"
                className={`map_icon_selection_2 ml-2 ${iconName === "sailboat" ? "width_40 mb-1" : "width_icon_50"
                  }`}
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/sailboat.svg`).default}
              />
              <img
                onClick={() => handleIconChange("snowmobile")}
                alt="Select icon"
                className={`map_icon_selection_2 ml-2 ${iconName === "snowmobile" ? "width_40 mb-1" : "width_icon_50"
                  }`}
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/snowmobile.svg`).default}
              />
              <img
                onClick={() => handleIconChange("tractor")}
                alt="Select icon"
                className={`map_icon_selection_2 ml-2 ${iconName === "tractor" ? "width_40 mb-1" : "width_icon_50"
                  }`}
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/tractor.svg`).default}
              />
              <img
                onClick={() => handleIconChange("truckcontainer")}
                alt="Select icon"
                className={`map_icon_selection_2 ml-2 ${iconName === "truckcontainer" ? "width_40 mb-1" : "width_icon_50"
                  }`}
                 src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/truckContainer.svg`).default}
              />
              <img
                onClick={() => handleIconChange("truck")}
                alt="Select icon"
                className={`map_icon_selection_2 ml-2 ${iconName === "truck" ? "width_40 mb-1" : "width_icon_50"
                  }`}
                 src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/truck.svg`).default}
              />
              <img
                onClick={() => handleIconChange("van")}
                alt="Select icon"
                className={`map_icon_selection_2 ml-2 ${iconName === "van" ? "width_40 mb-1" : "width_icon_50"
                  }`}
                src={require(`./../../assets/${process.env.REACT_APP_ASSETS}/device_icons/map_icons/simple_icons/van.svg`).default}
              />
            </div>
          </div>
          :
          ""
        }

        {/* Remove first d-none to show download log feature */}
        <div className={`${flavor === "Bike" ? "d-none" : "d-none"}`}>


          <div className="d-flex mt-3 flex-column  ">
            <div className="px-3 w-100 pt-3 align-items-center border-bottom white_shadow rounded-top d-flex">
              <label className="text_17_700 text-navy my-0">
                {t("bikeDetail.downloadErrorLog")}{" "}
              </label>
            </div>
            <div className="px-3  w-100  d-flex white_shadow border-bottom justify-content-between align-items-center">
              <p style={{ fontSize: "small", textAlign: "start" }}>
                {t("bikeDetail.downloadTitle")}{" "}
              </p>
              <div></div>
            </div>
            <div
              // onClick={()=>props.onDeleteBtnClick()}
              className="p-3 w-100 cursor-pointer rounded-bottom  white_shadow d-flex  border-bottom justify-content-center align-items-center"
              style={{ background: "#132439" }}
            >
              <label
                style={{ color: "white" }}
                onClick={() => setDownloadLink()}
                className=" text_16_400 cursor-pointer text-navy my-0"
              >
                {t("bikeDetail.dowloadLog")}{" "}
              </label>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <EditBikeName
        setShowErrorDialog={() => setShowErrorDialog(true)
        }
        setBikeName={setbikeName}
        setErrorMesage={(msg) => setShowErrorDialog(msg)}
        closeditBikeNameDialog={() => setEditBikeName(false)}
        show={editBike}
        deviceName={deviceName}
        setError={setError}
      />
      <EditSpeedLimit
        setShowErrorDialog={() => setShowErrorDialog(true)
        }
        speedLimit={speedLimit}
        setBikeSpeed={(val) => setBikeSpeed(val)}
        setErrorMesage={(msg) => setShowErrorDialog(msg)}
        closedEditbikespeedDialog={() => setEditbikeSpeed(false)}
        show={editBikesepeed}
        deviceName={deviceName}
        setError={setError}
      />

      <DeleteData
        setShowErrorDialog={() => setShowErrorDialog(true)}
        setErrorMesage={(msg) => setShowErrorDialog(msg)}
        closeModal={() => setDeleteDataPopup()}
        show={deleteDataModal}
        deviceDetails={bikeDetail}
        setError={setError}
      />

      <ErrorDialog
        showErrorDialog={errorDialog}
        errorMesage={errorDialog}
        // select={"settings"}
        onClose={() => setShowErrorDialog(false)}
      />
      <ConfirmDelete
        show={showDeleteHistory}
        closeConfirmDeleteDialog={() => setShowDeleteHistory(false)}
        select={"bikedetail"}
        confirmBodyText={t("ConfirmDelete.confirmTilttwo")}
        confirmBtnText={t("ConfirmDelete.DeleteBike")}
        onConfirm={() => removeHistory()}
      />
    </>
  );
};

export default BikeDetail;
