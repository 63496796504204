import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDevicesCompactInfo } from "../../services/devices/devicesService2";
import * as genericService from "../../services/genericService";
import PopUp from "../Device/PopUp";

const ContactSupport = (props) => {
  const { t } = useTranslation();
  const [issueCategories, setIssueCategories] = useState([]);
  const [devices, setDevices] = useState([]);
  const [issueCategoryId, setIssueCategoryId] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [message, setMessage] = useState("");
  const [deviceIdIsValid, setDeviceIdIsValid] = useState(true);
  const [issueCategoryIsValid, setIssueCategoryIsValid] = useState(true);
  const [messageIsValid, setMessageIsValid] = useState(true);
  const [error, setError] = useState("");
  const [contactSupportReqSent, setContactSupportReqSent] = useState(false);
  const [formValid, setFormValid] = useState(false)
  const send = async () => {
    if(contactSupportReqSent) return
    let errors = false;
    setDeviceIdIsValid(true);
    setIssueCategoryIsValid(true);
    setMessageIsValid(true);

    if (deviceId === "") {
      errors = true;
      setDeviceIdIsValid(false);
    }
    if (issueCategoryId === "") {
      errors = true;
      setIssueCategoryIsValid(false);
    }

    if (message === "") {
      errors = true;
      setMessageIsValid(false);
    }

    if (!errors) {
      const language = localStorage.getItem("language") ?? "en";
      const data = {
        language,
        deviceid: parseInt(deviceId),
        issueid: parseInt(issueCategoryId),
        message,
      };
      const response = await genericService.post("services/support/send", data);
      if (response.status === 200 && response.data.successful) {
        setContactSupportReqSent(true);
      } else {
        setError("Error");
      }
    }
  };

  const getIssueCategories = async () => {
    const language = localStorage.getItem("language") ?? "en";
    const response = await genericService.post("services/support/getissues", {
      language,
    });
    if (response.status === 200 && response.data.successful) {
      //console.log(response);
      setFormValid(response.data.valid)
      setIssueCategories(response.data.issues);
    }
  };

  const getDevices = async () => {
    const response = await getDevicesCompactInfo();
    if (response.status === 200) {
      const devices = response.data.devices ?? [];
      setDevices(devices);
    }
  };

  const on_load = () => {
    getIssueCategories();
    getDevices();
  };

  useEffect(() => {
    on_load();
  }, []);

  return (
    <>
      {error ? <PopUp setError={setError} error={error} /> : ""}
      {formValid
        ?
        <div
        className="rounded_white d-flex flex-column p-0 mt-2 justify-items-start mb-2"
        style={{ background: "#ffffff", width: "100%" }}
      >
        <div className="d-flex flex-column p-3  justify-items-start">
          <label className="text_22_700 text-left my-0 ">
            {t("Settings.ContactSupport")}
          </label>

          <label className="text_12_500 text-left my-0 ">
            {process.env.REACT_APP_FLAVOR === "Bike"
              ? t("Settings.ContactSupportDetail")
              : t("Settings.ContactSupportDetail_alt")
            }
          </label>

          <div className="row mt-2 contact_support ">
            <div className="col-md-6">
              <label className="text_12_500 text-left">
                {t("Settings.Device")}
              </label>

              <select
                onChange={(event) => setDeviceId(event.target.value)}
                value={deviceId}
                className={`${deviceIdIsValid ? "" : "is-invalid"
                  } form-control webket_apperience  cursor-pointer form-control-grey2 border-0 inset_shadow`}
                  disabled={contactSupportReqSent ? true : null}
                  >
                <option value="">{t("Select.Select")}</option>
                {devices.map((device, index) => {
                  return (
                    <option key={index} value={device.id}>
                      {device.name}
                    </option>
                  );
                })}
              </select>
              <span
                className={`${deviceIdIsValid ? "d-none" : ""
                  } invalid-feedback bottom`}
              >
                {t("errors.Field is required")}
              </span>
            </div>
            <div className="col-md-6 contact_support">
              <label className="text_12_500 text-left">
                {t("Settings.IssueCategory")}
              </label>

              <select
                onChange={(event) => setIssueCategoryId(event.target.value)}
                value={issueCategoryId}
                className={`${!issueCategoryIsValid ? "is-invalid" : ""
                  } form-control webket_apperience cursor-pointer form-control-grey2 border-0 inset_shadow`}
                  disabled={contactSupportReqSent ? true : null}
              >
                <option value="">{t("Select.Select")}</option>
                {issueCategories.map((cat) => {
                  return (
                    <option key={cat.id} value={cat.id}>
                      {cat.issuename}
                    </option>
                  );
                })}
              </select>
              <span
                className={`${issueCategoryIsValid ? "d-none" : ""
                  } invalid-feedback bottom`}
              >
                {t("errors.Field is required")}
              </span>
            </div>

            <div className="col-12 mt-4">
              <label className="text_12_500 text-left">
                {t("Settings.Message")}
              </label>

              <textarea
                onChange={(event) => setMessage(event.target.value)}
                className={`${!messageIsValid ? "is-invalid" : ""
                  } form-control form-control-grey2 border-0 inset_shadow`}
              />
              <span
                className={`${messageIsValid ? "d-none" : ""} invalid-feedback bottom`}
              >
                {t("errors.Field is required")}
              </span>
            </div>
          </div>
        </div>

        <div
          onClick={send}
          className="w-100 mt-4 button_send py-3 cursor-pointer d-flex flex-column justify-content-center"
        >
          <label className="text_17_700 mb-0 text-white text-center cursor-pointer">
            {contactSupportReqSent ? t("Settings.messagesend") : t("Settings.Send")}
          </label>
        </div>
      </div>
        :
        null
      }
     
    </>
  );
};

export default ContactSupport;
