import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show_groups: false,
  show_alarms: false,
  show_geofence: false,
  trace_unit: false,
  hide_offline_units: false,
  cluster_markers: false,
  show_device_name: false,
  show_settings: false,
  Maptype: "roadmap"
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const mapSettingsSlice = createSlice({
  name: "mapSettings",
  initialState: initialState,
  reducers: {
    setSetting: (state, action) => {
      let keys = Object.keys(action.payload);
      for (let key of keys) {
        state[key] = action.payload[key];
      }
    },

    setMapType: (state, action) => {
      state.Maptype = action.payload
    },
    resetMapsettingsState: (state, action) => {
      state.show_groups = false
      state.show_alarms = false
      state.show_geofence = false
      state.trace_unit = false
      state.hide_offline_units = false
      state.cluster_markers = false
      state.show_device_name = false
      state.show_settings = false
      state.Maptype = "roadmap"
    }
  },
});
