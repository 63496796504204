import SwitchUI from "../../components/CheckBoxes/SwitchUI";
const SwitchRow = (props) => {
  return (
    <>
      <div
        className="p-3 mt-2 d-flex rounded_white justify-content-between align-items-center"
        style={{ background: "#ffffff", width: "100%" }}
      >
        <label className=" text_16_400 text-navy my-0">{props.title} </label>
        <SwitchUI
          isChecked={props.switch}
          onClick={(e) => props.SwitchClick(!props.switch)}
        />
      </div>
    </>
  );
};

export default SwitchRow;
