/*
 * @Author: Abhi Bhatt
 * @Date:   2020-12-23 14:12:09
 * @Last Modified by:   abhi
 * @Last Modified time: 2021-04-14 12:41:01
 */
import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import geo_tutorial_1 from "../../assets/images/geo_tutorial_1.svg";
// import geo_tutorial_2 from "../../assets/images/geo_tutorial_2.svg";
// import geo_tutorial_3 from "../../assets/images/geo_tutorial_3.svg";
// import geo_tutorial_4 from "../../assets/images/geo_tutorial_4.svg";
// import geo_tutorial_5 from "../../assets/images/geo_tutorial_5.svg";
// import geo_tutorial_6 from "../../assets/images/geo_tutorial_6.svg";
// import geo_tutorial_7 from "../../assets/images/geo_tutorial_7.svg";
// import geo_tutorial_8 from "../../assets/images/geo_tutorial_8.svg";
// import close_gray from "../../assets/images/close_gray.svg";
// import dot_selected from "../../assets/images/dot_selected.svg";
// import dot_unselected from "../../assets/images/dot_unselected.svg";
// import backward_arrow_grey from "../../assets/images/backward_arrow_grey.svg";
import FlavorImages from "../../assetsFolderImport";
let width = window.innerWidth;  // use only when no imapact while changing screen from web to mobile


const GeofenceTutorial = (props) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const clickNext = () => {
    setPage(page + 1);
    if (page + 1 === 9) props.onClose();
  };
  const clickPrevious = () => {
    setPage(page - 1);
  };

  const onClose = () => {
    props.onClose();
  };

  useEffect(() => {
    if (props.show) {
      setPage(1);
    }
  }, [props.show]);

  const renderDotUI = () => {
    return (
      <div className="dot_layout" style={{ marginTop: "18px" }}>
        <img
          alt="Step"
          className="dot "
          src={page === 2 ? FlavorImages.dot_selected : FlavorImages.dot_unselected}
        />
        <img
          alt="Step"
          className="dot ml-2"
          src={page === 3 ? FlavorImages.dot_selected : FlavorImages.dot_unselected}
        />
        <img
          alt="Step"
          className="dot ml-2 "
          src={page === 4 ? FlavorImages.dot_selected : FlavorImages.dot_unselected}
        />
        <img
          alt="Step"
          className="dot ml-2"
          src={page === 5 ? FlavorImages.dot_selected : FlavorImages.dot_unselected}
        />
        <img
          alt="Step"
          className="dot ml-2"
          src={page === 6 ? FlavorImages.dot_selected : FlavorImages.dot_unselected}
        />
        <img
          alt="Step"
          className="dot ml-2"
          src={page === 7 ? FlavorImages.dot_selected : FlavorImages.dot_unselected}
        />
        <img
          alt="Step"
          className="dot ml-2"
          src={page === 8 ? FlavorImages.dot_selected : FlavorImages.dot_unselected}
        />
      </div>
    );
  };

  return (
    <>
      <Dialog className="custome_dialog" open={props.show}>
        <div className="add_device_dialog d-flex flex-row">
          <div className="dialog_side_panel d-none d-md-flex w-50">
            <div className="px-0 d-flex flex-column justify-content-center  position-relative ">
              <div
                className={`${page === 1 ? "show_ui" : "hide_ui"}     inner`}
              >
                <img
                  className="add_device_logo px-5"
                  src={FlavorImages.geo_tutorial_1}
                  alt="..."
                />
              </div>
              <div className={`${page === 2 ? "show_ui" : "hide_ui"}   inner `}>
                <img
                  className={`add_device_logo px-5  `}
                  src={FlavorImages.geo_tutorial_2}
                  alt="..."
                />
              </div>
              <div className={`${page === 3 ? "show_ui" : "hide_ui"}   inner `}>
                <img
                  className={` add_device_logo px-5 `}
                  src={FlavorImages.geo_tutorial_3}
                  alt="..."
                />
              </div>

              <div className={`${page === 4 ? "show_ui" : "hide_ui"}  inner `}>
                <img
                  className={` add_device_logo px-5 `}
                  src={FlavorImages.geo_tutorial_4}
                  alt="..."
                />
              </div>

              <div className={`${page === 5 ? "show_ui" : "hide_ui"}   inner `}>
                <img
                  className={` add_device_logo px-5 `}
                  src={FlavorImages.geo_tutorial_5}
                  alt="..."
                />
              </div>

              <div className={`${page === 6 ? "show_ui" : "hide_ui"}   inner`}>
                <img
                  className={` add_device_logo px-5 `}
                  src={FlavorImages.geo_tutorial_6}
                  alt="..."
                />
              </div>

              <div className={`${page === 7 ? "show_ui" : "hide_ui"}   inner `}>
                <img
                  className={` add_device_logo px-5 `}
                  src={FlavorImages.geo_tutorial_7}
                  alt="..."
                />
              </div>

              <div className={`${page === 8 ? "show_ui " : "hide_ui"}    `}>
                <img
                  className={` add_device_logo px-5 `}
                  src={FlavorImages.geo_tutorial_8}
                  alt="..."
                />
              </div>
            </div>
          </div>
          <div className="d-flex jusify-content-center bg-white" style={{ width: "300px" }}>
            <div className="w-100 h-100 d-flex flex-column">
              <div className="d-flex  justify-content-between align-items-center p-3 py-4">
                <img
                  className={`${page === 1 ? "hide_ui" : "show_ui"} size_25 cursor-pointer`}
                  src={FlavorImages.backward_arrow_grey}
                  onClick={() => clickPrevious()}
                  alt="..."
                />
                <label className="text_17_700   mb-0 ">
                  {t("Geofences.HowCreateGeofence")}
                </label>
                <img
                  className="size_25 mr-0 cursor-pointer"
                  src={FlavorImages.close_gray}
                  onClick={() => onClose()}
                  alt="..."
                />
              </div>

              <div className=" px-0 ">
                <div
                  className={`${page === 1 ? "show_ui" : "hide_ui"
                    } d-flex px-4  mt-2 flex-column align-self-stretch inner`}
                >
                  <label className="text_22_700" mt-2>
                    {t("Geofences.WhyGeofence")}
                  </label>
                  <label className="dialog_body_detail">
                    {t("customFile.GeofenceTutorialInfo")}
                  </label>
                </div>

                <div
                  className={`${page === 2 ? "show_ui" : "hide_ui"
                    } d-flex px-4  mt-2 flex-column align-self-stretch inner`}
                >
                  <label className="text_22_700" mt-2>
                    {t("Geofences.PressPlus")}
                  </label>
                  <label className="dialog_body_detail">
                    {t("Geofences.GeofenceTutorial1")}
                  </label>
                </div>

                <div
                  className={`${page === 3 ? "show_ui" : "hide_ui"
                    } d-flex px-4  mt-2 flex-column align-self-stretch inner`}
                >
                  <label className="text_22_700" mt-2>
                    {t("Geofences.ChooseName")}
                  </label>
                  <label className="dialog_body_detail">
                    {t("Geofences.GeofenceTutorial2")}
                  </label>
                </div>

                <div
                  className={`${page === 4 ? "show_ui" : "hide_ui"
                    } d-flex px-4  mt-2 flex-column align-self-stretch inner`}
                >
                  <label className="text_22_700" mt-2>
                    {t("Geofences.ChooseMethod")}
                  </label>
                  <label className="dialog_body_detail">
                    {t("Geofences.GeofenceTutorial3")}
                  </label>
                </div>

                <div
                  className={`${page === 5 ? "show_ui" : "hide_ui"
                    } d-flex px-4  mt-2 flex-column align-self-stretch inner`}
                >
                  <label className="text_22_700" mt-2>
                    {t("customFile.Choosebike")}
                  </label>
                  <label className="dialog_body_detail">
                    {t("customFile.GeofenceTutorial4")}
                  </label>
                </div>

                <div
                  className={`${page === 6 ? "show_ui" : "hide_ui"
                    } d-flex px-4  mt-2 flex-column align-self-stretch inner`}
                >
                  <label className="text_22_700" mt-2>
                    {t("Geofences.PlaceCircle")}
                  </label>
                  <label className="dialog_body_detail">
                    {t("Geofences.GeofenceTutorial5")}
                  </label>
                </div>

                <div
                  className={`${page === 7 ? "show_ui" : "hide_ui"
                    } d-flex px-4  mt-2 flex-column align-self-stretch inner`}
                >
                  <label className="text_22_700" mt-2>
                    {t("Geofences.OrDrawShape")}
                  </label>
                  <label className="dialog_body_detail">
                    {t("Geofences.GeofenceTutorial6")}
                  </label>
                </div>

                <div
                  className={`${page === 8 ? "show_ui" : "hide_ui"
                    } d-flex px-4  mt-2 flex-column align-self-stretch inner`}
                >
                  <label className="text_22_700" mt-2>
                    {t("Geofences.SaveEnjoy")}
                  </label>
                  <label className="dialog_body_detail">
                    {t("Geofences.GeofenceTutorial7")}
                  </label>
                </div>
              </div>

              <div className="row pb-4 mt-auto mx-0 dialog_bottom_height">
                <div className="col-12 p-0 text-center">
                  <div
                    className={`${page === 1 ? "show_ui" : "hide_ui"
                      }  col-12 text-center inner`}
                  >
                    <button
                      type="submit"
                      className="dialog_btn"
                      onClick={() => clickNext()}
                      id="next"
                    >
                      {t("Geofences.TakeGeofenceTutorial")}
                    </button>
                  </div>

                  <div className={`${page !== 1 ? "show_ui" : "hide_ui"}  `}>
                    <button
                      type="submit"
                      className="dialog_btn"
                      onClick={() => clickNext()}
                      id="next"
                    >
                      {page === 8 ? t("Geofences.Startgeofencing") : t("signup.Next")}
                    </button>
                  </div>
                </div>

                <div className="col-12 text-center">
                  {page > 1 ? renderDotUI() : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default GeofenceTutorial;
