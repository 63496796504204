import React from "react";
// import holo_gray from "../../assets/images/holo_gray.svg";
// import Not_available from "../../assets/images/Not_available.svg";
import FlavorImages from "../../assetsFolderImport";

const AchievementView = (props) => {
    const getMedalIcon = (leveltype, type) => {
        let imageType = ''
        if (type == "distance") imageType = `distance-session.svg`;
        else if (type == "kcal") imageType = `calories-session.svg`;
        else {
            imageType = `time-session.svg`;
        }
        if(process.env.REACT_APP_FLAVOR == 'Bike')
        {
            return require(`./../../assets/${process.env.REACT_APP_ASSETS}/medals/${leveltype}/${imageType}`).default
        }
    }
    return (
        <>
            <div
                className={`${props.index === 0 || !props.visibility ? "invisible" : ""} progress_bar_achievement_one ${props.achievement.leveltype ? "background_sky_blue" : "background_grey"}  margin_top_30 `}>
            </div>
            {props.visibility && props.achievement.leveltype ?
                <div className="d-flex flex-column"
                >
                    <div className={`picContainer margin_top_7_point_5`}>
                        <img
                            src={
                                getMedalIcon(props.achievement.leveltype, props.achievement.type)
                            }
                            onClick={() => {
                                props.setAchievementsPopup(true)
                                props.setAchievementsType(props.achievement.leveltype, props.achievement.type, props.achievement)
                            }}
                            className="outer_div cursor-pointer position-absolute"
                            alt="..."
                        />
                    </div>
                    <label className="text-center mt-5 black2 text_10_400 line_break_achvment ">{props.achievement.titletext}  </label>
                </div> :
                props.visibility && !props.achievement.leveltype ?
                    <img
                        onClick={() => {
                            props.setAchievementsPopup(true)
                            props.setAchievementsType(props.achievement.leveltype, props.achievement.type, props.achievement)
                        }}
                        src={
                            FlavorImages.holo_gray
                        }
                        // onClick={() => {
                        //     console.log("KLICK");
                        //     // setShowLang(!showLang)
                        //     props.setAchievementsPopup(true)
                        //     props.setAchievementsType(props.achievement.leveltype, props.achievement.type, props.achievement)
                        // }}
                        className="outer_div mt-2"
                        alt="..."
                    />
                    :
                    <img
                        src={
                            FlavorImages.Not_available
                        }
                        className="outer_div mt-2"
                        alt="..."
                    />
            }
            <div className={`${(props.index == props.lastindex - 1) ? "invisible" : !props.visibility && "invisible"} progress_bar_achievement_two  ${props.achievement.leveltype ? "background_sky_blue" : ""} margin_top_30`}>
            </div>
        </>
    )

}
export default AchievementView;
