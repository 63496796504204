import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BackWardArrow from "../../components/Buttons/BackwardArrow";
import { SITE_TITLE } from "../../helpers/constants";
import { seo } from "../../helpers/seo";
import * as genericService from "../../services/genericService";
import SoundRow from "./SoundRow";
import { sounds1sec, sounds3sec, sounds10sec } from "./Sounds";
// import check from "../../assets/images/check.svg";
import { INDEX_URL } from "../../helpers/constants";
import FlavorImages from "../../assetsFolderImport";
const SoundUI = (props) => {
  const { t } = useTranslation();
  const [newSound, setNewSound] = useState("")
  var audio = new Audio(newSound);
  const [playing, setPlaying] = useState(false);
  const toggle = (playsound) => {
    if (newSound === playsound) {
      setPlaying(false);
    }
    else {
      setPlaying(true);
    }
  }
  const [sounds, setSounds] = useState(null);
  const getData = async () => {
    setSounds(sounds1sec);
    props.setSoundDuration(1)
  };
  useEffect(() => {
    playing ? audio.play() : audio.pause();
  },
  );
  const on_load = () => {
    getData();
    seo({
      title: SITE_TITLE,
      metaDescription: "",
    });
  };
  useEffect(() => {
    on_load();
  }, []);
  const updateSound = (sound) => {
    let newSound = `${INDEX_URL}sounds/${props.duration}sec/${sound.file_1}`
    setNewSound(newSound)
    toggle(newSound)
    if (props.sameForAll) {
      updateAllSoundId(sound.id);
    } else {
      updateSoundId(sound.id);
    }
  };
  const updateSoundId = async (soundId) => {
    const data = {
      sameforalldevices: false,
      deviceid: props.deviceId,
      alarmid: soundId,
    };

    const response = await genericService.post(
      "notification/settingsupdate",
      data
    );

    if (response.status === 200 && response.data.successful) {
      setPlaying(false)
      props.setSoundId(soundId);
    }
  };

  const updateAllSoundId = async (soundId) => {
    const data = {
      sameforalldevices: true,
      alarmid: soundId,
    };

    const response = await genericService.post(
      "notification/settingsupdate",
      data
    );

    if (response.status === 200 && response.data.successful) {
      setPlaying(false)
      props.setSoundId(soundId);
    }
  };

  const updateDuration = (duration) => {
    if (props.sameForAll) {
      if (duration === 3) {
        setSounds(sounds3sec)
      }
      else if (duration === 1) {
        setSounds(sounds1sec)
      }
      else {
        setSounds(sounds10sec)
      }
      updateAllDuration(duration);
    } else {
      if (duration === 3) {
        setSounds(sounds3sec)
      }
      else if (duration === 1) {
        setSounds(sounds1sec)
      }
      else {
        setSounds(sounds10sec)
      }
      updateDeviceDuration(duration);
    }
  };

  const updateDeviceDuration = async (duration) => {
    const data = {
      sameforalldevices: false,
      deviceid: props.deviceId,
      alarmduration: duration,
      alarmid: props.alarmId,
    };

    const response = await genericService.post(
      "notification/settingsupdate",
      data
    );

    if (response.status === 200 && response.data.successful) {
      props.setSoundDuartion(duration);
    }
  };

  const updateAllDuration = async (duration) => {
    const data = {
      sameforalldevices: true,
      alarmduration: duration,
      alarmid: props.alarmId,
    };

    const response = await genericService.post(
      "notification/settingsupdate",
      data
    );

    if (response.status === 200 && response.data.successful) {
      props.setSoundDuartion(duration);
    }
  };

  const isSelectedSound = (sound) => {
    if (sound.id === props.alarmId) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div
        className={`${props.showUI === true ? "show_ui" : "hide_ui"
          } col-md-8 d-flex  mt-2 flex-column  inner`}
      >
        <BackWardArrow show={true} onClick={() => props.setSelect("BikeNotifications")} />
        <label className="mt-4 px-0 d-md-none text_22_700 text-left mt-2">
          {t("Settings.Notifications")}
        </label>
        <label className="ml-1 text-uppercase text_11_300 text-left mt-2">
          {t("Settings.AlarmSound")}
        </label>

        <div className="px-0  scroll_div_20 bg-white w-100 d-flex flex-column settings_rounded_white justify-content-start align-items-start">
          {sounds !== null
            ? sounds.map((sound, index) => {
              return (
                <SoundRow
                  sound={sound}
                  key={index}
                  OnSoundSelect={() => updateSound(sound)}
                  selected={isSelectedSound(sound)}
                />
              );
            })
            : null}
        </div>

        <label className="ml-1 text-uppercase text_11_300 text-left mt-2">
          {t("Settings.SondDuration")}
        </label>

        <div className="px-0  bg-white w-100 d-flex flex-column settings_rounded_white justify-content-start align-items-start">
          <div
            className="p-3 cursor-pointer  w-100  d-flex  border-bottom justify-content-between align-items-center"
            onClick={() => updateDuration(10)}
          >
            <label
              className={` text-navy my-0 cursor-pointer  ${props.duration === 10 ? "text_16_700" : "text_16_400"
                }`}
            >
              {t("Settings.10Seconds")}
            </label>

            {props.duration === 10 ? (
              <img src={FlavorImages.check} className="size_15 cursor-pointer" alt="..." />
            ) : null}
          </div>

          <div
            className="p-3 cursor-pointer  w-100  d-flex  border-bottom justify-content-between align-items-center"
            onClick={() => updateDuration(3)}
          >
            <label
              className={` text-navy my-0 cursor-pointer  ${props.duration === 3 ? "text_16_700" : "text_16_400"
                }`}
            >
              {t("Settings.3Seconds")}
            </label>

            {props.duration === 3 ? (
              <img src={FlavorImages.check} className="size_15 cursor-pointer" alt="..." />
            ) : null}
          </div>

          <div
            className="p-3 w-100 cursor-pointer d-flex  border-bottom justify-content-between align-items-center"
            onClick={() => updateDuration(1)}
          >
            <label
              className={` text-navy my-0 cursor-pointer  ${props.duration === 1 ? "text_16_700" : "text_16_400"
                }`}
            >
              {t("Settings.1Second")}
            </label>

            {props.duration === 1 ? (
              <img src={FlavorImages.check} className="size_15 cursor-pointer" alt="..." />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default SoundUI;
