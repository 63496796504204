import React from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import checked from "../../assets/images/checked.svg";
// import check_empty from "../../assets/images/check_empty.svg";
// import deviceMoving_dark from "../../assets/images/Dark/deviceMoving_dark.svg";
// import devicestopped_dark from "../../assets/images/Dark/devicestopped_dark.svg";
// import deviceUnknown_dark from "../../assets/images/Dark/deviceUnknown_dark.svg";
// import geofence_enter_dark from "../../assets/images/Dark/geofence_enter_dark.svg";
// import geofence_exit_dark from "../../assets/images/Dark/geofence_exit_dark.svg";
// import ingnition_off_dark from "../../assets/images/Dark/ingnition_off_dark.svg";
// import inanition_on_dark from "../../assets/images/Dark/inanition_on_dark.svg";
// import lowExternalBattery_dark from "../../assets/images/Dark/lowExternalBattery_dark.svg";
// import low_battery_dark from "../../assets/images/Dark/low_battery_dark.svg";
// import offline_dark from "../../assets/images/Dark/offline_dark.svg";
// import online_dark from "../../assets/images/Dark/online_dark.svg";
// import powercut_dark from "../../assets/images/Dark/powercut_dark.svg";
// import sos_dark from "../../assets/images/Dark/sos_dark.svg";
// import speed_alarm_dark from "../../assets/images/Dark/speed_alarm_dark.svg";
// import tamper_dark from "../../assets/images/Dark/tamper_dark.svg";
// import vibration_dark from "../../assets/images/Dark/vibration_dark.svg";
// import dark_circle from "../../assets/images/dark_circle.svg";
// import dark_circle_helkama from "../../assets/Helkama_images/dark_circle.svg";
// dark_circle
// import deviceMoving_light from "../../assets/images/deviceMoving_light.svg";
// import devicestopped_light from "../../assets/images/devicestopped_light.svg";
// import deviceUnknown_light from "../../assets/images/deviceUnknown_light.svg";
// import geofence_enter_light from "../../assets/images/geofence_enter_light.svg";
// import geofence_exit_light from "../../assets/images/geofence_exit_light.svg";
// import ingnition_off_light from "../../assets/images/ingnition_off_light.svg";
// import ingnition_on_light from "../../assets/images/ingnition_on_light.svg";
// import lowExternalBattery_light from "../../assets/images/lowExternalBattery_light.svg";
// import low_battery_light from "../../assets/images/low_battery_light.svg";
// import offline_light from "../../assets/images/offline_light.svg";
// import online_light from "../../assets/images/online_light.svg";
// import powercut_light from "../../assets/images/powercut_light.svg";
// import sos_light from "../../assets/images/sos_light.svg";
// import speed_alarm_light from "../../assets/images/speed_alarm_light.svg";
// import tamper_light from "../../assets/images/tamper_light.svg";
// import vibration_light from "../../assets/images/vibration_light.svg";
// import white_circle from "../../assets/images/white_circle.svg";
import * as helper_functions from "../../helpers/helper_functions";
import { alarmsSlice } from "../../slice/alarmsSlice";
// import Mobile_checked from "../../assets/images/deleteAlarmCheckedMobileButton.svg";
// import deleteAlarmUncheckedButton from "../../assets/images/deleteAlarmUncheckedButton.svg";
import toast from 'react-hot-toast';
// import info_icon from "../../assets/images/info_icon.svg";
// import bypass_light from "../../assets/images/bypass_light.svg";
// import bypass_dark from "../../assets/images/Dark/bypass_dark.svg";
import FlavorImages from "../../assetsFolderImport";

// helkama dark icon  image imports 
// import helkam_bell_light_generic from "./../../assets/Helkama_images/Alarm Icons Light/bell_generic_light.svg"
// import helkamBell_dark_generic from "./../../assets/Helkama_images/Alarm Icons Dark/bell_dark_generic.svg"
// import helkama_bike_vibration_dark from "./../../assets/Helkama_images/Alarm Icons Dark/bike_vibration.svg"
// import helkama_geofence_enter_dark from "./../../assets/Helkama_images/Alarm Icons Dark/geofence_enter.svg"
// import helkama_geofence_exit_dark from "./../../assets/Helkama_images/Alarm Icons Dark/geofence_exit.svg"
// import helkama_ignition_off_dark from "./../../assets/Helkama_images/Alarm Icons Dark/ingnition_off.svg"
// import helkama_ignition_on_dark from "./../../assets/Helkama_images/Alarm Icons Dark/ingnition_on.svg"
// import helkama_low_battery_dark from "./../../assets/Helkama_images/Alarm Icons Dark/low_battery.svg"
// import helkama_offline_dark from "./../../assets/Helkama_images/Alarm Icons Dark/offline.svg"
// import helkama_online_dark from "./../../assets/Helkama_images/Alarm Icons Dark/online.svg"
// import helkama_power_off_dark from "./../../assets/Helkama_images/Alarm Icons Dark/power-off.svg"
// import helkama_speed_alarm_dark from "./../../assets/Helkama_images/Alarm Icons Dark/speed_alarm.svg"
// import helkama_bike_vibration_light from "./../../assets/Helkama_images/Alarm Icons Light/bike_vibration.svg"
// import helkama_geofence_enter_light from "./../../assets/Helkama_images/Alarm Icons Light/geofence_enter.svg"
// import helkama_geofence_exit_light from "./../../assets/Helkama_images/Alarm Icons Light/geofence_exit.svg"
// import helkama_ignition_off_light from "./../../assets/Helkama_images/Alarm Icons Light/ingnition_off.svg"
// import helkama_ignition_on_light from "./../../assets/Helkama_images/Alarm Icons Light/ingnition_on.svg"
// import helkama_low_battery_light from "./../../assets/Helkama_images/Alarm Icons Light/ingnition_on.svg"
// import helkama_offline_light from "./../../assets/Helkama_images/Alarm Icons Light/offline.svg"
// import helkama_online_light from "./../../assets/Helkama_images/Alarm Icons Light/online.svg"
// import helkama_power_off_light from "./../../assets/Helkama_images/Alarm Icons Light/power-off.svg"
// import helkama_speed_alarm_light from "./../../assets/Helkama_images/Alarm Icons Light/speed_alarm.svg"




const AlarmRow = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const alarmActions = alarmsSlice.actions;
  const { alarmState } = useSelector(
    (state) => ({ alarmState: state.alarms }),
    shallowEqual
  );

  const getDate = () => {
    if (!props.alarm.date) return "";
    let date = helper_functions.default.formatDate(
      props.alarm.date, "yyyy/MM/dd"
    );
    return date;
  };

  const getTime = () => {
    if (!props.alarm.date) return "";
    let date = helper_functions.default.formatTime(
      props.alarm.date, "HH:mm"
    );
    let date2 = new Date(props.alarm.date).toLocaleTimeString()

    return date2;
  };

  const getAlarmIcon = () => {
    if (props.alarm.type === "deviceMoving") return FlavorImages.deviceMoving_light;
    else if (props.alarm.type === "deviceStopped") return FlavorImages.devicestopped_light;
    else if (props.alarm.type === "deviceOnline") return FlavorImages.online_light;
    else if (props.alarm.type === "deviceOffline") return FlavorImages.offline_light;
    else if (props.alarm.type === "geofenceExit") return FlavorImages.geofence_exit_light;
    else if (props.alarm.type === "geofenceEnter") return FlavorImages.geofence_enter_light;
    else if (props.alarm.type === "tampering") return FlavorImages.tamper_light;
    else if (props.alarm.type === "lowBattery") return FlavorImages.low_battery_light;
    else if (props.alarm.type === "lowExternalBattery") return FlavorImages.lowExternalBattery_light;
    else if (props.alarm.type === "ignitionOn") return FlavorImages.ingnition_on_light;
    else if (props.alarm.type === "ignitionOff") return FlavorImages.ingnition_off_light;
    else if (props.alarm.type === "vibration") return FlavorImages.vibration_light;
    else if (props.alarm.type === "powerCut") return FlavorImages.powercut_light;
    else if (props.alarm.type === "sos") return FlavorImages.sos_light;
    else if (props.alarm.type === "deviceOverspeed") return FlavorImages.speed_alarm_light;
    else if (props.alarm.type === "deviceUnknown") return FlavorImages.deviceUnknown_light;
    else if (props.alarm.type === "bypass") return FlavorImages.bypass_light;
  };

  const getHelkamaLightAlarmIcon = () => {
    // return FlavorImages.bell_generic_light
    if (props.alarm.type === "deviceOnline") return FlavorImages.online_light;
    else if (props.alarm.type === "deviceOffline") return FlavorImages.offline_light
    else if (props.alarm.type === "geofenceExit") return FlavorImages.geofence_exit_light;
    else if (props.alarm.type === "geofenceEnter") return FlavorImages.geofence_enter_light;
    else if (props.alarm.type === "lowBattery") return FlavorImages.low_battery_light;
    else if (props.alarm.type === "ignitionOn") return FlavorImages.ingnition_on_light;
    else if (props.alarm.type === "ignitionOff") return FlavorImages.ingnition_off_light;
    else if (props.alarm.type === "vibration" || props.alarm.type === "vibration2") return FlavorImages.bike_vibration_light;
    else if (props.alarm.type === "powerCut") return FlavorImages.power_off_light;
    else if (props.alarm.type === "deviceOverspeed") return FlavorImages.speed_alarm_light
    else return FlavorImages.bell_generic_light
  };
  const getHelkamaDarkAlarmIcon = () => {
    if (props.alarm.type === "deviceOnline") return FlavorImages.online_dark;
    else if (props.alarm.type === "deviceOffline") return FlavorImages.offline_dark;
    else if (props.alarm.type === "geofenceExit") return FlavorImages.geofence_exit_dark;
    else if (props.alarm.type === "geofenceEnter") return FlavorImages.geofence_enter_dark;
    else if (props.alarm.type === "lowBattery") return FlavorImages.low_battery_dark;
    else if (props.alarm.type === "lowExternalBattery") return FlavorImages.lowExternalBattery_dark;
    else if (props.alarm.type === "ignitionOn") return FlavorImages.ingnition_on_dark;
    else if (props.alarm.type === "ignitionOff") return FlavorImages.ingnition_off_dark;
    else if (props.alarm.type === "vibration" || props.alarm.type === "vibration2") return FlavorImages.bike_vibration_dark;
    else if (props.alarm.type === "powerCut") return FlavorImages.power_off_dark;
    else if (props.alarm.type === "deviceOverspeed") return FlavorImages.speed_alarm_dark
    else return FlavorImages.bell_dark_generic_dark
  };
  const getAlarmBlueIcon = () => {
    if (props.alarm.type === "deviceMoving") return FlavorImages.deviceMoving_dark;
    else if (props.alarm.type === "deviceStopped") return FlavorImages.devicestopped_dark;
    else if (props.alarm.type === "deviceOnline") return FlavorImages.online_dark;
    else if (props.alarm.type === "deviceOffline") return FlavorImages.offline_dark;
    else if (props.alarm.type === "geofenceExit") return FlavorImages.geofence_exit_dark;
    else if (props.alarm.type === "geofenceEnter") return FlavorImages.geofence_enter_dark;
    else if (props.alarm.type === "tampering") return FlavorImages.tamper_dark;
    else if (props.alarm.type === "lowBattery") return FlavorImages.low_battery_dark;
    else if (props.alarm.type === "lowExternalBattery")
      return FlavorImages.lowExternalBattery_dark;
    else if (props.alarm.type === "ignitionOn") return FlavorImages.inanition_on_dark;
    else if (props.alarm.type === "ignitionOff") return FlavorImages.inanition_off_dark;
    else if (props.alarm.type === "vibration") return FlavorImages.vibration_dark;
    else if (props.alarm.type === "powerCut") return FlavorImages.powercut_dark;
    else if (props.alarm.type === "sos") return FlavorImages.sos_dark;
    else if (props.alarm.type === "deviceOverspeed") return FlavorImages.speed_alarm_dark;
    else if (props.alarm.type === "deviceUnknown") return FlavorImages.deviceUnknown_dark;
    else if (props.alarm.type === "bypass") return FlavorImages.bypass_dark;
  };

  const getAlarmName = () => {
    if (props.alarm.type === "deviceMoving") return t("Alarm.DeviceMoving");
    else if (props.alarm.type === "deviceStopped")
      return t("Alarm.DeviceStopped");
    else if (props.alarm.type === "deviceOnline")
      return t("Alarm.deviceOnline");
    else if (props.alarm.type === "deviceOffline")
      return t("Alarm.deviceOffline");
    else if (props.alarm.type === "geofenceExit")
      return t("Alarm.GeofenceExit");
    else if (props.alarm.type === "geofenceEnter")
      return t("Alarm.GeofenceEnter");
    else if (props.alarm.type === "tampering") return t("Alarm.Tampering");
    else if (props.alarm.type === "lowBattery") return t("Alarm.LowBattery");
    else if (props.alarm.type === "lowExternalBattery")
      return t("Alarm.LowExternalBattery");
    else if (props.alarm.type === "ignitionOn") return t("Alarm.IgnitionOn");
    else if (props.alarm.type === "ignitionOff") return process.env.REACT_APP_FLAVOR === "Alco"
      ? t("Alarm.PositiveBlow")
      : t("Alarm.IgnitionOff")
    else if (props.alarm.type === "vibration" || props.alarm.type === "vibration2") return t("Alarm.Vibration");
    else if (props.alarm.type === "powerCut") return t("Alarm.PowerCut");
    else if (props.alarm.type === "sos") return t("Alarm.sos");
    else if (props.alarm.type === "deviceOverspeed")
      return t("Alarm.DeviceOverspeed");
    else if (props.alarm.type === "deviceUnknown")
      return t("Alarm.DeviceUnknown");
    else if (props.alarm.type === "bypass")
      return t("Alarm.Bypass")
    else
      return props.alarm.type;
  };

  const setAlarmMarkerid = (deviceid) => {
    if (alarmState.selectedAlarmMarkerid === deviceid) {
      dispatch(
        alarmActions.setAlarmMarkerid({
          selectedAlarmMarkerid: null,
        })
      );
    } else {
      dispatch(
        alarmActions.setAlarmMarkerid({
          selectedAlarmMarkerid: deviceid,
        })
      );
    }
  };
  const setRemoveBlueStrip = (device) => {

    if (props.isSelection === false) {
      if (alarmState.selectedAlarmMarkerid === device.eventid && device.latitude && device.longitude) {
        setAlarmMarkerid("");
      }
      else if (device.latitude && device.longitude) {
        setAlarmMarkerid(device.eventid);
      } else {
        toast(getAlarmName() + ' ' + t("toast.noPosition"), {
          // Custom Icon
          icon: <img src={FlavorImages.info_icon} className="size_20" alt="React Logo" />,
          duration: 1500,
        });
      }
    }
  };
  const setDeviceIndex = () => {
    console.log("KLICK");
    if (props.isSelection) {
      console.log("KLICK1");
      props.onClick();
    }
  };
  const renderRow = () => (
    <div className="col">
      <div className="row">
        <div
          className="Alarm_row_small bg-transparent"
          onClick={() => setDeviceIndex()}
        >

          <div className="d-none cursor-pointer d-sm-none d-md-block">
            {props.isSelection ? (
              props.selected || props.alarm.isSelected ? (
                <img
                  className="mr-3 cursor-pointer"
                  style={{ width: "20px", height: "20px" }}
                  src={FlavorImages.checked}
                  alt="checked"
                  onClick={() => props.setChooseAll(false)} />
              ) : (
                <img
                  className="mr-3 cursor-pointer"
                  style={{ width: "20px", height: "20px" }}
                  src={FlavorImages.check_empty}
                  alt="unchecked" />
              )
            ) : null}

          </div>


          <div className="d-sm-block col-1 d-md-none px-0 size_35 picContainer">
            {props.alarm.eventid === alarmState.selectedAlarmMarkerid &&
              props.isSelection === false ? (
              <img src={FlavorImages.white_circle} className="size_35 " alt="..." />
            ) : (
              <img src={FlavorImages.dark_circle} className="size_35 " alt="..." />
            )}
            {props.alarm.eventid === alarmState.selectedAlarmMarkerid &&
              props.isSelection === false ? (
              <img
                src={process.env.REACT_APP_FLAVOR === "Bike" ? getHelkamaDarkAlarmIcon() : getAlarmBlueIcon()}
                className="size_35 pic2 p-2 "
                alt="..." />
            ) : (
              <img
                src={process.env.REACT_APP_FLAVOR === "Bike" ? getHelkamaLightAlarmIcon() : getAlarmIcon()}
                className="size_35 pic2 p-2 "
                alt="..." />
            )}
          </div>
          <div className="d-none d-sm-none cursor-pointer d-md-block size_35 picContainer ">
            {props.alarm.eventid === alarmState.selectedAlarmMarkerid &&
              props.isSelection === false ? (
              <img src={FlavorImages.white_circle} className="size_35 cursor-pointer " alt="..." />
            ) : (
              <img src={ FlavorImages.dark_circle} className="size_35  cursor-pointer" alt="..." />
            )}
            {props.alarm.eventid === alarmState.selectedAlarmMarkerid &&
              props.isSelection === false ? (
              <img
                src={process.env.REACT_APP_FLAVOR === "Bike" ? getHelkamaDarkAlarmIcon() : getAlarmBlueIcon()}
                className="size_35 pic2 p-2 cursor-pointer"
                alt="..." />
            ) : (
              <img
                src={process.env.REACT_APP_FLAVOR === "Bike" ? getHelkamaLightAlarmIcon() : getAlarmIcon()}
                className="size_35 pic2 p-2 cursor-pointer "
                alt="..." />
            )}
          </div>

          <div className="col-sm-5 col-4 cursor-pointer">
            <div className="device_row_left_name ml-2 cursor-pointer">
              <label
                className={`device_row_name_title cursor-pointer m-0 p-0 text-truncate ${props.alarm.eventid === alarmState.selectedAlarmMarkerid &&
                  props.isSelection === false
                  ? "text-white"
                  : ""}`}
              >
                {getAlarmName()}
              </label>

              <label
                className={`text_13_400 text-left cursor-pointer px-0 text-truncate ${props.alarm.eventid === alarmState.selectedAlarmMarkerid &&
                  props.isSelection === false
                  ? "text-white"
                  : "text-grey"}`}
              >
                {props.alarm.devicenameorfrom}
              </label>
            </div>
          </div>
          <div className="col-sm-3 col-3 ml-md-4 cursor-pointer pl-4 pr-0">
            <div className="device_row_left_name  ">
              <label
                className={`text_12_700 cursor-pointer  m-0 p-0 ${props.alarm.eventid === alarmState.selectedAlarmMarkerid &&
                  props.isSelection === false
                  ? "text-white"
                  : ""}`}
              >
                {t("Alarm.Date")}
              </label>

              <label
                className={`text_13_400 cursor-pointer m-0 p-0 ${props.alarm.eventid === alarmState.selectedAlarmMarkerid &&
                  props.isSelection === false
                  ? "text-white"
                  : "text-grey"}`}
              >
                {getDate()}
              </label>
            </div>
          </div>
          <div className="col-sm-1 col-3 cursor-pointer">
            <div className="device_row_left_name  ">
              <label
                className={`text_12_700 cursor-pointer m-0 p-0 ${props.alarm.eventid === alarmState.selectedAlarmMarkerid &&
                  props.isSelection === false
                  ? "text-white"
                  : ""}`}
              >
                {t("Alarm.Time")}
              </label>
              <label
                className={`text_13_400 cursor-pointer m-0 p-0 ${props.alarm.eventid === alarmState.selectedAlarmMarkerid &&
                  props.isSelection === false
                  ? "text-white"
                  : "text-grey"}`}
              >
                {getTime()}
              </label>
            </div>
          </div>
          <div className="d-md-none ">
            <img
              className={`mr-2 size_20 ${props.isSelection ? "visible" : "invisible"}`}
              src={props.alarm.isSelected ? FlavorImages.deleteAlarmCheckedMobileButton : FlavorImages.deleteAlarmUncheckedButton}
              alt="checked"
            />
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <React.Fragment>
      <div
        onClick={() => setRemoveBlueStrip(props.alarm)}
        className={`d-flex justify-content-center cursor-pointer align-items-sm-center
								${props.alarm && props.alarm.eventid === alarmState.selectedAlarmMarkerid &&
            !props.isSelection &&
            props.isSelection === false
            ? "table-not-striped "
            : "stripRow"
          }
			`}
      >
        {renderRow()}
      </div>
    </React.Fragment>
  );
};

export default AlarmRow;
