import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React from "react";
import { useTranslation } from "react-i18next";
// import close_gray from "../../assets/images/close_gray.svg";
import { shallowEqual, useSelector } from "react-redux";
import * as genericService from "../../services/genericService";
import { useHistory } from "react-router-dom";
import { INDEX_URL, SITE_TITLE } from "../../helpers/constants";
import toast, { Toaster } from 'react-hot-toast';
import FlavorImages from "../../assetsFolderImport";



const ConfrimDeleteGeofence = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { geofencesState } = useSelector(
    (state) => ({ geofencesState: state.geofences }),
    shallowEqual
  );
  let geoFenceNames = [];
  let geoFenceId = [];
  if(geofencesState.filteredGeofences)
  {
  let geofences = geofencesState.filteredGeofences.map((geofence, index) => {
    if (geofence.isSelected) {
      geoFenceNames.push(geofence.name);
      geoFenceId.push(geofence.id);
    }
  });
  }
  
  const deleteGeofence = async () => {
    var response;
    // console.log('confirm delete:', geoFenceId)
    if (geoFenceId.length > 0) {
    geoFenceId.map((geofenceid, index) => {
      response = genericService.post("geofence/delete", {
        geofenceid: geofenceid,
      });
    });
    // console.log(response)
    response.then((val) => {
      toast.success(t("toast.Done"));
      props.resetIsSelection();
      history.push(INDEX_URL + "geofence")
    }).catch((err) => {
      toast.failed(t("toast.Error"));
    }).finally(() => {
      props.closeConfirmDeleteDialog();
      props.loadGeofences();
    });
    // if (response.status == 200 || response.status == 200) {
    // } else {
    // }
    };
  };
  return (
    <>
      <Dialog open={props.show}>
        <Card className="card"
        >
          <div className="text-center">
            <div
              className="dialog_top_sort_row py-0 card-header text-grey"
              style={{ backgroundColor: "white" }}
            >
              <label className="text_17_700 ml-2 text-grey">
                {" "}
                {t("forgotPassword.Confirm")}
              </label>
              <img
                className="cursor-pointer"
                src={FlavorImages.close_gray}
                onClick={() => props.closeConfirmDeleteDialog()}
                alt="..."
              />
            </div>
          </div>
          <div className="row ml-1 mt-3">
            <div className="col-12 col-md-10 offset-md-1 ">
              <div className="form-outline">
                <label className="text-black1 px-4 mb-0 text-center text_11_500 pb-4 text-grey">
                  {t("actionDialog.YouSure")}{" "}
                  {geoFenceNames && geoFenceNames.length > 1
                    ? t("actionDialog.SelectedGeofences") + "?"
                    : geoFenceNames[0] + " " + t("geofences.geofence") + "?"}
                </label>
              </div>
            </div>
          </div>
          <div className="m-0 card-footer  d-flex justify-content-end">
            <button
              className="btn  btn-sm  py-1 text-12-700 px-1 text-grey"
              onClick={() => props.closeConfirmDeleteDialog()}
              variant="light"
            >
              {t("actionDialog.cancel")}
            </button>
            <button
              onClick={() => deleteGeofence()}
              className="btn btn-sm py-1 px-2 text-12-700 bg-danger text-white ml-2"
            >
              {t("cardList.Delete")}
            </button>
          </div>
        </Card>
      </Dialog>
    </>
  );
};

export default ConfrimDeleteGeofence;
