import { Card } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
// import close_gray from "../../assets/images/close_gray.svg";
import { shallowEqual, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
// import check from "../../assets/images/check.svg";
import { devicesSlice } from "../../slice/deviceSlice";
import FlavorImages from "../../assetsFolderImport";
const deviceActions = devicesSlice.actions;
const ConfrimDeleteAction = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { devicesState } = useSelector(
    (state) => ({ devicesState: state.devices }),
    shallowEqual
  );
  const handleChange = (newaction) => {
    dispatch(
      deviceActions.setAction({
        action: newaction,
      })
    );
  };
  const onConfirm = () => {
    if (devicesState.action) {
      props.setIsSelection(true);
    }
    else 
    {
      props.setIsSelection(false);
    }
    props.closeActionDialog();
  };
  return (
    <>
      <div id="collapsetwo" className="collapse " data-parent="#accordiontwo">
        <Card className="card" style={{ width: "335px" }}>
        <div className="dialog_top_sort_row py-0 card-header bg-white">
        
               <label className="text_17_700 ">
                {" "}
                {t("forgotPassword.Confirm")}
              </label>
              <img
                data-toggle="collapse"
                data-target="#collapsetwo"
                aria-expanded="true"
                aria-controls="collapsetwo"
                className="cursor-pointer"
                src={FlavorImages.close_gray}
                alt="..."
              />
          </div>
          <div
            className="row pl-2 pb-2 mt-3"
            onClick={() => handleChange(!devicesState.action)}
          >
            <div className="col-6 d-flex justify-content-start">
              <label
                className={`my-2  card-title ${devicesState.action ? "" : "text-gray"
                  }`}
                style={{ fontSize: "15px" }}
              >
                {t("Routes.DeleteRoute")}
              </label>
            </div>
            <div className="col-6 pr-4 d-flex justify-content-end">
              <img
                style={{ height: "15px" }}
                className={`my-2 ' ${devicesState.action ? "" : "set-check"}`}
                src={FlavorImages.check}
                alt="..."
              />
            </div>
          </div>
          <div className="m-0 card-footer  d-flex justify-content-center">
            <button
              className="btn bg-grey2 py-1 px-4 text-grey text_12_700  "
              data-toggle="collapse"
              data-target="#collapsetwo"
              aria-expanded="true"
              aria-controls="collapsetwo"
              variant="light"
              // onClick={() => props.closeConfirmDialog()}
            >
              {t("actionDialog.cancel")}
            </button>
            <button
              className="btn  py-1 px-4 bg-navy bg_dark_helkama text-white ml-3 text_12_700  "
              data-toggle="collapse"
              data-target="#collapsetwo"
              aria-expanded="true"
              aria-controls="collapsetwo"
              onClick={() => onConfirm()}
              id="next"
            >
              {t("actionDialog.select")}
            </button>
          </div>
        </Card>
      </div>
    </>
  );
};

export default ConfrimDeleteAction;
