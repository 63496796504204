import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import green_tick from "../../assets/images/green_tick.svg";
// import cross_red from "../../assets/images/cross_red.svg";
import { SITE_TITLE } from "../../helpers/constants";
import { seo } from "../../helpers/seo";
import * as genericService from "../../services/genericService";
import FlavorImages from "../../assetsFolderImport";

const ShareInviteRow = (props) => {
  const { t } = useTranslation();
  const [accountInfo, setAccountInfo] = useState();
  const getLoginHistory = async () => {
    const response = await genericService.get("login/history");
    if (response.status === 200) {
      var info = response.data;
      setAccountInfo(info);
    }
  };
  const setAcceptDeny = async (data, accept) => {
    const response = await genericService.post(
      "device/share/acceptdenyinvitation",
      {
        deviceid: [data],
        accept: accept,
      }
    );
    if (response.status === 200) {
      props.updateShareWithMe();
    } else {
    }
  };

  const on_load = () => {
    getLoginHistory();

    seo({
      title: SITE_TITLE,
      metaDescription: "",
    });
  };

  useEffect(() => {
    on_load();
  }, []);
  return (
    <>
      <div>
        <div
          className={`
          ${props.isFirst ? "rounded-top" : props.isLast ? "rounded-bottom" : ""
            }
           ${props.isLast ? "" : "border-bottom"}
           p-3   white_shadow d-flex  border-bottom justify-content-between align-items-center bg-white w-100 `}
        >
          <label className=" text_12_500 text-navy my-0">
            {props.device.sharedstatus.sharedfrom + " " +t("Settings.wanttoShare") +
              " " +
              props.device.name +
              " " +
              t("Settings.withYou")}
          </label>

          <div>
            <img
              onClick={() => setAcceptDeny(props.device.id, true)}
              src={FlavorImages.green_tick}
              className="size_18 cursor-pointer share_img"
              alt="..."
            />
            <img
              onClick={() => setAcceptDeny(props.device.id, false)}
              src={FlavorImages.cross_red}
              className="size_18 cursor-pointer ml-3 share_img"
              alt="..."
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareInviteRow;
