/*
 * @Author: Abhi Bhatt
 * @Date:   2020-12-23 14:12:09
 * @Last Modified by:   abhi
 * @Last Modified time: 2021-04-14 12:41:01
 */
import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorDialog from "../LocationHistory/ErrorDialog";
import * as genericService from "../../services/genericService";
// import add_device_1 from "../../assets/images/add_device_1.svg";
// import add_device_1_2 from "../../assets/images/add_device_1_2.svg";
// import add_device_2 from "../../assets/images/add_device_2.svg";
// import close_gray from "../../assets/images/close_gray.svg";
// import dot_selected from "../../assets/images/dot_selected.svg";
// import dot_unselected from "../../assets/images/dot_unselected.svg";
import toast, { Toaster } from 'react-hot-toast';
import FlavorImages from "../../assetsFolderImport";

let flavor = process.env.REACT_APP_FLAVOR; //when we have APIs for helkama; remove static value and use flavor from env


const AddDevice = (props) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [errorDialog, setShowErrorDialog] = useState(false)
  const [deviceName, setDeviceName] = useState("");
  const [vin, setVIN] = useState("");
  const [vinNumberError, setvinNumberError] = useState("confirmerror")
  const handleChange = (field, e) => {
    if (field === "name") setDeviceName(e.target.value);
    else setVIN(e.target.value);
  };

  const clickNext = () => {
    if (deviceName !== "") setPage(2);
  };

  const clickAdd = async () => {
    if (vin === "")
    {
      if (flavor === "Bike" ) {
        setvinNumberError(t("errors.EnterVINNumber"))
      } else {
        setvinNumberError(t("errorCode.10010"))

      }
    }
   else
    if (vin !== "") {
      let splitValue = vin.split(" ").join("");
      let imeiNo = parseInt(splitValue)
      let vinNo = splitValue
      var data = process.env.REACT_APP_FLAVOR == "Bike" ?
      {
        vinnumber: vinNo,
        name: deviceName,
      }
      :
      {
        imeinumber: imeiNo,
        name: deviceName,
      }      
      const response = await genericService.post("device/create", data);
      if (response.status === 200 && response.data.successful) {
        props.loadDevices();
        onClose();
        toast.success(t("toast.Added"));
      }
      else if( response.data.errorCode === "20012")
      { 
        setShowErrorDialog(response.data.message)
      }
      else if ( response.data.errorCode ===  "20117")
      {
        setvinNumberError(response.data.message)
      }
      else
      {
        setShowErrorDialog(response.data.message)
      }
    }
 
  };

  const onClose = () => {
    props.onClose();
  };

  useEffect(() => {
    if (props.show) {
      setPage(1);
      setDeviceName("");
      setVIN("");
    }
  }, [props.show]);

  const renderFirstUI = () => {
    return (
      <>
        <label className="dialog_body_title">
          {process.env.REACT_APP_FLAVOR === "Bike" ? "1. " + t("Device.NameBike") : "1. " + t("Device.NameDevice")}
        </label>
        <div className="minHeightOfLabel">
          <label className="dialog_body_detail">
            {process.env.REACT_APP_FLAVOR === "Bike" ? t("Device.NameBikeDetail") : t("Device.NameDeviceDetail")}
          </label>
        </div>
        <input
          type="text"
          className="form-control dialog_body_input"
          id="newName"
          value={deviceName}
          onChange={(e) => {
            handleChange("name", e);
          }}
          placeholder={t("Device.NameHint")}
        />

        <button
          type="submit"
          className={`dialog_btn ${process.env.REACT_APP_FLAVOR === 'Bike' ? 'bg_dark_helkama' : ''}`}
          style={{ marginTop: "50px", borderColor: process.env.REACT_APP_FLAVOR === 'Bike' ? 'var(--dark_helkama)' : 'var(--primary)' }}
          onClick={() => clickNext()}
          id="next"
        >
          {t("signup.Next")}
        </button>

        <div className="dot_layout" style={{ marginTop: "20px" }}>
          <img alt="Step" className="dot" src={FlavorImages.dot_selected} />
          <img alt="Step" className="dot ml-2" src={FlavorImages.dot_unselected} />
        </div>
      </>
    );
  };

  const renderSecondUI = () => {
    return (
      <>
        <label className="dialog_body_title">
          {process.env.REACT_APP_FLAVOR === "Bike" ? "2. " + t("Device.AddVIN") : "2. " + t("Device.AddIMEI")}
        </label>
        <div className="minHeightOfLabel">
          <label className="dialog_body_detail">
            {process.env.REACT_APP_FLAVOR === "Bike" ? t("Device.AddVINDetail") : t("Device.AddIMEIDetail")}
          </label>
        </div>
        <form>
          <div class="form-group">
            <input
              type="text"
              className="form-control dialog_body_input"
              id="newName"
              value={vin}
              onChange={(e) => {
                handleChange("vin", e);
              }}
              placeholder={flavor == "Bike" ? t("Device.VIN") : t("Device.IMEI")}
            />
            <div className="invalid-feedback ml-1 bottom">
              {vinNumberError === "confirmerror" ? "" : vinNumberError}
            </div>
          </div>
        </form>
        <button
          type="submit"
          className={`dialog_btn tn ${process.env.REACT_APP_FLAVOR === 'Bike' ? 'bg_dark_helkama' : ''}`}
          style={{ marginTop: "50px", borderColor: process.env.REACT_APP_FLAVOR === 'Bike' ? 'var(--dark_helkama)' : 'var(--primary)' }}
          onClick={() => clickAdd()}
          id="next"
        >
          {t("Device.Add")}
        </button>

        <div className="dot_layout" style={{ marginTop: "20px" }}>
          <img alt="Step" className="dot" src={FlavorImages.dot_unselected} />
          <img alt="Step" className="dot ml-2" src={FlavorImages.dot_selected} />
        </div>
      </>
    );
  };

  return (
    <>
      <Dialog open={props.show}>
        <Card className="add_device_dialog d-flex flex-row">
          <div className="dialog_side_panel disply_none_small width_50">
            {page === 1 ? (
              <img
                src={props.FlavorImages.add_device_1}
                className="add_device_logo px-5"
                alt="..."
              />
            ) : (
              <img
                src={props.FlavorImages.add_device_2}
                className="add_device_logo px-5"
                alt="..."
              />
            )}
          </div>
          <div
            className="dialog_side_panel width_50"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            <div className="w-100 h-100 ">
              <div className="dialog_top_row">
                <label className="text_17_700">{t("customFile.AddDevice")}</label>
                <img className="cursor-pointer" src={props.FlavorImages.close_gray} onClick={() => onClose()} alt="..." />
              </div>

              <div className="dialog_body">
                {page === 1 ? renderFirstUI() : renderSecondUI()}
              </div>
            </div>
          </div>
        </Card>
      </Dialog>
      <ErrorDialog
                    showErrorDialog={errorDialog}
                    select={"Device"}
                    errorMesage={errorDialog}
                    onClose={() => setShowErrorDialog(false)}
                  />
    </>
    
  );
};

export default AddDevice;
