import { Card } from "@mui/material";
import _ from "lodash";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
// import close_gray from "../../assets/images/close_gray.svg";
import React, { useEffect, useState } from "react";
import * as genericService from "../../services/genericService";
// import amex_primary from "../../assets/images/amex_primary.svg";
// import amex_second from "../../assets/images/amex_second.svg";
// import mastercard_primary from "../../assets/images/mastercard_primary.svg";
// import mastercard_second from "../../assets/images/mastercard_second.svg";
// import default_second from "../../assets/images/default_second.svg";
// import default_primary from "../../assets/images/default_primary.svg";
// import arrow_up from "../../assets/images/arrow_up.svg";
// import arrow_down from "../../assets/images/arrow_down.svg";
import { login } from "../../services/user/loginService2";
// import check_empty from "../../assets/images/check_empty.svg";
// import checked from "../../assets/images/checked.svg";
import FlavorImages from "../../assetsFolderImport";
const ActivateDeviceDialog = (props) => {
    const { t } = useTranslation();
    const [card, setCard] = useState(null);
    const [cards, setCards] = useState([]);

    const [price1, setPrice1] = useState(false)
    const [price2, setPrice2] = useState(false)
    const [noPrice, setNoPrice] = useState(false)

    const [currency, setCurrency] = useState(" $ USD")
    const [cost, setCost] = useState(5.59)

    const [Imgindex, setindex] = useState(0)
    const dropzoneStyle = (index) => ({
        marginLeft: `0.${index + 1}rem`,
        zIndex: index
    });
    const priceInfo = (card) => {
        let country = card.card.country
        console.log(card.card.country);
        
        switch(country){
            case "SE":
                setCost(49)
                setCurrency(" SEK")
            break;
            case 'NO':
                setCost(49)
                setCurrency(" NOK")
            break;
                case 'FI':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
                case 'AX':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
                case 'DK':
                setCost(36)
                setCurrency(" DKK")
            break;
                case 'DE':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
                case 'FR':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
                case 'GB':
                setCost(4.39)
                setCurrency(" £ GBP")
            break;
                case 'IE':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
                case 'PL':
                setCost(22)
                setCurrency(" PLN")
            break;
                case 'NL':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
                case 'BE':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
                case 'IT':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
                case 'ES':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
                case 'CH':
                setCost(4.90)
                setCurrency(" CHF")
            break;
                case 'AT':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'CZ':
                setCost(119)
                setCurrency(" CZK")
            break;
            case 'PT':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'GR':
                setCost(4.79)
                setCurrency( "€ EUR")
            break;
            case 'BG':
                setCost(9)
                setCurrency(" LEV")
            break;
            case 'CY':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'EE':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'LV':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'LT':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'HR':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'LU':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'MT':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'RO':
                setCost(23)
                setCurrency(" RON")
            break;
            case 'SK':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'SI':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            default:
                setCost(5.59)
                setCurrency(" $ USD")
        }
    }

    const changePrice = (price) => {
        console.log(card);
        console.log(cards);
        setNoPrice(false)
        if (price === "1") {
            setPrice1(!price1)
            if (price2) {
                setPrice2(false)
            }
        } else {
            setPrice2(!price2)
            if (price1) {
                setPrice1(false)
            }
        }
    }
    const activateSelectedDevicesConfirm = async () => {

        if (!price1 && !price2) {
            console.log(noPrice);
            setNoPrice(true)
            return
        }
        props.setSpinerActve("run");
        props.setSpinerError("");
        props.setSpinnerScreenText(t("DataPlan.textActivateDataPlan"));
        const data = { deviceid: props.toActivate };

        console.log("KLICK WEBB");

        const response = await genericService.post("dataplan/start", data);
        if (response.status === 200 || response.status == 201) {
            console.log(response);
            props.setSpinerActve("success");
            props.onClose();
            props.onsuccess()
            props.setSpinnerScreenText(t("DataPlan.DataplaneAeactivated"));
            props.setTextSpinnerOne("Start tracking");
        } else if (response.data.hasOwnProperty("errorCode")) {
            props.setSpinerActve("error");
            props.setSpinnerScreenText(t("DataPlan.ErrorCode") + response.data.message);
            props.setTextSpinnerOne(t("Settings.ContactSupport"));
            props.setSpinerError(t("DataPlan.ErrorCode") + response.data.message);
        }

    };
    const setCardAnimation = (index) => {
        if (index === cards.length - 1) {
            setindex(0)
            setCard(cards[0]);
        }
        else {
            setindex(index + 1)
            setCard(cards[index + 1]);
        }
    }
    const updateSelectedCard = (increaseDecrease) => {
        var index = _.cloneDeep(Imgindex);
        if (increaseDecrease === "increase" && index + 1 < cards.length) {
            index = index + 1;
        } else if (increaseDecrease === "decrease" && index > 0) {
            index = index - 1;
        }
        setindex(index);
        setCard(cards[index]);
    };
    const loadCards = async () => {
        const response = await genericService.get("dataplan/cards/getall");
        if (response.status === 200) {
            const cardsList = response.data.data ?? [];
            setCards(cardsList);
            setCard(cardsList[0]);
            priceInfo(cardsList[0])
        } else {
            setCards([]);
            setCard(null);
        }
    };
    useEffect(() => {
        loadCards()
        props.setTextSpinnerOne("");
    }, []);
    const renderImages = () => {
        return (
            <div className="ImageLayout" style={{ marginTop: "10px" }}>
                {cards.map((card, index) => {
                    if (card !== undefined && card.brand === "American Express")
                        if (index === 0) {
                            return <img key={index} style={dropzoneStyle(index)} onClick={() => setCardAnimation(index)}
                                className={`card_data_plane cursor-pointer ${index === Imgindex ? "card--tabs" : "card--space"}  `} src={FlavorImages.amex_primary} alt="..." />
                        }
                        else {
                            return (
                                <img key={index} style={dropzoneStyle(index)} onClick={() => setCardAnimation(index)} className={`card_data_plane cursor-pointer ${index === Imgindex ? "card--tabs" : "card--space"}  `} src={FlavorImages.amex_second} alt="..." />
                            )
                        }
                    else if (card !== undefined && card.brand === "MasterCard") {
                        if (index === 0) {
                            return <img key={index} style={dropzoneStyle(index)} onClick={() => setCardAnimation(index)}
                                className={`card_data_plane cursor-pointer  ${index === Imgindex ? "card--tabs" : "card--space"}  `}
                                src={FlavorImages.mastercard_primary} alt="..." />;
                        }
                        else {
                            return (
                                <img key={index} style={dropzoneStyle(index)} onClick={() => setCardAnimation(index)} className={`card_data_plane cursor-pointer
                    ${index === Imgindex ? "card--tabs" : "card--space"}  `} src={FlavorImages.mastercard_second} alt="..." />)
                        }
                    }
                    else if (index === 0) {
                        return <img key={index} style={dropzoneStyle(index)} onClick={() => setCardAnimation(index)} className={`card_data_plane cursor-pointer   ${index === Imgindex ? "card--tabs" : "card--space"}  `} src={FlavorImages.default_primary} alt="..." />;
                    }
                    else return <img key={index} style={dropzoneStyle(index)} onClick={() => setCardAnimation(index)} className={`card_data_plane cursor-pointer  ${index === Imgindex ? "card--tabs" : "card--space"}  `} src={FlavorImages.default_second} alt="..." />;
                })}
            </div>
        );
    }
    return (
        <>
            <Dialog open={props.showAcivateDialog}>
                <Card className={`card  ${props.spinerActve && "effectactivateDialog"}`} style={{ width: "500px" }}>
                    <div
                        className="dialog_top_sort_row pb-1 pt-0 card-header"
                        style={{ backgroundColor: "white" }}
                    >
                        <label className="text_17_700 text-grey">{t("geofences.CONFIRM")}</label>
                        <img className="cursor-pointer" src={FlavorImages.close_gray}
                            onClick={() => props.onClose()}
                            alt="..." />
                    </div>
                    <div className="card-body d-flex px-4" style={{ overflowX: "hidden", overflowY: "scroll" }}>
                        <div className="col-12">
                            <div className="row">
                                <label className="power_device-label text_12_500 text-grey ">
                                    {t("DataPlan.Doyouwanttoactivate")}
                                </label>
                            </div>
                            <div className="row">
                                <div className="col-7 m-0 p-0">
                                    <label className="power_device-label text_12_500 text-grey">
                                        {t("devicepower.Name")}
                                    </label>
                                </div>
                                <div className="col-5 pr-0">
                                    <label className="power_device-label text_12_500 text-grey">
                                        {t("devicepower.IMEI")}
                                    </label>
                                </div>
                            </div>
                            {props.devicesForActivate.map((device) => {
                                return (
                                    <div className="row font">
                                        <div className="col-7 p-0">
                                            <label className="line_height_1  text_12_500  font-weight-bold">
                                                {device.name}
                                            </label>
                                        </div>
                                        <div className="col-5">
                                            <label className="line_height_1   text_12_500 font-weight-bold">
                                                {" "}
                                                {device.uniqueid}
                                            </label>
                                        </div>
                                    </div>
                                )
                            })}
                            <div className="row mt-3">
                                <div className="col-7 m-0 p-0">
                                    <img
                                        className="mr-1 check_box_style border_radius_25"
                                        src={price1 ? FlavorImages.checked : FlavorImages.check_empty}
                                        alt="check"
                                        onClick={() => changePrice("1")}
                                    />
                                    <label className={`power_device-label text_12_500 ${noPrice ? "text-red" : "text-grey"}  `}>
                                        {cost}{currency} {t("DataPlan.PerMonth")}
                                    </label>
                                </div>
                                {/* <div className="col-7 m-0 p-0">
                                <img
                                    className="mx-1 check_box_style border_radius_25"
                                    src={price2?checked:check_empty}
                                    alt="check"
                                    onClick={() => changePrice("2")}
                                />
                                    <label className="power_device-label text_12_500 text-grey ">
                                        199kr {t("DataPlan.PerYear")}
                                    </label>
                            </div> */}
                                <div className={`col-7 m-0 p-0 ${noPrice ? "" : "d-none"}`}>
                                    <label className="power_device-label text_12_500 text-red">
                                        {t("DataPlan.ChoosePrice")}
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-7 m-0 p-0">
                                    {price1
                                        ? <label className="power_device-label text_12_500 text-grey ">{t("DataPlan.Total")}: {props.devicesForActivate.length * cost}{currency}  {t("DataPlan.PerMonth")}</label>
                                        : price2
                                            ? <label className="power_device-label text_12_500 text-grey ">{t("DataPlan.Total")}: {props.devicesForActivate.length * cost}{currency}  {t("DataPlan.PerYear")}</label>
                                            : <label className="power_device-label text_12_500 text-grey ">{t("DataPlan.Total")}: --</label>
                                    }
                                </div>
                            </div>

                        </div>

                        {/* <div className="col-5 pr-2">
                            <div className="row ml-4 text-grey">
                                    <label className="power_device-label text_12_500 text-grey" >
                                        {t("DataPlan.Cardbeingcharged")}
                                    </label>
                            </div>
                            {renderImages()}

                        </div>
                        <div className="col-1 d-flex flex-column  justify-content-center mr-2">
                                    <img
                                        className="cursor-pointer ml-2 size_20"
                                        onClick={() => updateSelectedCard("increase")}
                                        src={arrow_up}
                                        alt="..."
                                    />

                                    <img
                                        className="mt-3 ml-2 cursor-pointer size_20"
                                        src={arrow_down}
                                        alt="..."
                                        onClick={() => updateSelectedCard("decrease")}
                                    />
                                    </div> */}

                    </div>
                    <div className="m-0 card-footer  d-flex justify-content-end">
                        <button
                            onClick={() => props.onClose()}
                            className="btn bg-transparent btn-sm font-bold text-grey text_12_700">
                            {t("actionDialog.cancel")}
                        </button>
                        <button
                            onClick={() => activateSelectedDevicesConfirm()}
                            className={`btn  py-0 px-3 text-white ml-3 ${!price1 && !price2 ? "disable-hover btn_grey" : "btn_navy" }`}
                            disabled={!price1 && !price2} // Add this line to conditionally disable the button

                        >
                            {t("DataPlan.Activate")}
                        </button>
                    </div>
                </Card>
            </Dialog>
        </>
    );
};
export default ActivateDeviceDialog;
