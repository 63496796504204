import { reduxBatch } from "@manaflair/redux-batch";
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { authSlice } from "./slice/authSlice";
import { registerSlice } from "./slice/registerSlice";
import { devicesSlice } from "./slice/deviceSlice";
import { groupSlice } from "./slice/groupSlice";
import { alarmsSlice } from "./slice/alarmsSlice";
import { mapSettingsSlice } from "./slice/mapSettingsSlice";
import { geofencesSlice } from "./slice/geofencesSlice";
import { locationHistorySlice } from "./slice/locationHistorySlice";
import { accountSlice } from "./slice/accountSlice";
import { livestreamSlice } from "./slice/liveStreamSlice";
import { settingsSlice } from "./slice/settingsSlice";
import { persistReducer } from 'redux-persist';
import { getDefaultMiddleware } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage'
const persistConfig = {
  key: 'mapSettings',
  storage,
}
const authPersistConfig = {
  key: 'auth',
  storage,
}

export const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authSlice.reducer),
  register: registerSlice.reducer,
  devices: devicesSlice.reducer,
  groups: groupSlice.reducer,
  alarms: alarmsSlice.reducer,
  mapSettings: persistReducer(persistConfig, mapSettingsSlice.reducer),
  geofences: geofencesSlice.reducer,
  locationHistory: locationHistorySlice.reducer,
  account: accountSlice.reducer,
  liveStream: livestreamSlice.reducer,
  settings: settingsSlice.reducer
});

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  enhancers: [reduxBatch],
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export default store;
