// import check from "../../assets/images/check.svg";
import FlavorImages from "../../assetsFolderImport";
const SoundRow = (props) => {
  return (
    <>
      <div
        onClick={() => props.OnSoundSelect()}
        className="p-3 cursor-pointer  w-100  d-flex  border-bottom justify-content-between align-items-center"
      >
        <label
          className={` text-navy my-0 cursor-pointer ${
            props.selected ? "text_16_700" : "text_16_400"
          }`}
        >
          {props.sound.name}
        </label>

        {props.selected ? (
          <img
            src={FlavorImages.check}
            className="size_15"
            alt="..."
          />
        ) : null}
      </div>
    </>
  );
};

export default SoundRow;
