import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import close_gray from "../../assets/images/close_gray.svg";
import { useSelector, shallowEqual } from "react-redux";
import * as devicesService2 from "../../services/devices/devicesService2";
import toast, { Toaster } from 'react-hot-toast';
import FlavorImages from "../../assetsFolderImport";

const EditBikeName = (props) => {
  const { t } = useTranslation();
  const { devicesState } = useSelector(
    (state) => ({ devicesState: state.devices }),
    shallowEqual
  );
  const [firstName, setFirstName] = useState("");
  const [nameFieldError, setNameFieldError] = useState("");
  const [bikeDetail, setBikeDetail] = useState([]);
  const deviceDetail = () => {
    devicesState.devices.map((device) => {
      if (device.name === props.deviceName) {
        setBikeDetail(device);
      }
    });
  };

  const setNameField = (value) => {
    setFirstName(value);
    setNameFieldError("");
  };
  useEffect(() => {
    setFirstName(props.deviceName);
    deviceDetail();
  }, [props.deviceName]);

  const updateDeviceName = async () => {
    if (firstName === "") {
      setNameFieldError("This field is required");
    } else {
      let editDevice = {
        deviceid: bikeDetail.id,
        name: firstName,
      };
      const response = await devicesService2.updateDevice(editDevice);
      if (response.status === 200) {
        props.setBikeName(firstName)
        props.closeditBikeNameDialog();
        toast.success(t("toast.Saved"));
      } else {
     
        props.closeditBikeNameDialog();
        props.setShowErrorDialog();
        props.setErrorMesage(response.data.message)
      }
    }
  };

  return (
    <>
      <Dialog open={props.show}>
        <Card className="card">
          <div className="text-center">
            <div
              className="dialog_top_sort_row py-2 card-header"
              style={{ backgroundColor: "white" }}
            >
              <label className="card-title text-grey text_17_700 ml-4">
                {" "}
                {t("Settings.ChangeBikeName")}
              </label>
              <img
                className="cursor-pointer"
                src={FlavorImages.close_gray}
                onClick={() => props.closeditBikeNameDialog()}
                alt="..."
              />
            </div>
            <div className="card-body py-3">
              <div className="row">
                <div className="col-12">
                  <div className="form-group col-12 mt-2">
                    <input
                      type="text"
                      onChange={(e) => setNameField(e.target.value)}
                      style={{ background: "#DDDDDD" }}
                      value={firstName}
                      className={`form-control ${
                        nameFieldError === "" ? "" : "is-invalid"
                      }`}
                    />
                    <div className="invalid-feedback bottom">{nameFieldError}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="m-0 card-footer  d-flex justify-content-end">
            <button
              className="btn_cancel_transparent"
              onClick={() => props.closeditBikeNameDialog()}
              variant="light"
            >
              {t("actionDialog.cancel")}
            </button>

            <button
              className="btn btn_navy bg_dark_helkama text-uppercase ml-3"
              onClick={() => updateDeviceName()}
              id="next"
            >
              {t("actionDialog.SaveName")}
            </button>
          </div>
        </Card>
      </Dialog>
    </>
  );
};

export default EditBikeName;
