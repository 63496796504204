import React from "react";
// import checked from "../../assets/images/checked.svg";
import ReactHtmlParser from "react-html-parser";
// import down_arrow from "../../assets/images/down_arrow.svg";
// import check_empty from "../../assets/images/check_empty.svg";
import { useTranslation } from "react-i18next";
import { devicesSlice } from "../../slice/deviceSlice";
import { geofencesSlice } from "../../slice/geofencesSlice";
import _ from "lodash";
import { useDispatch } from "react-redux";
import FlavorImages from "../../assetsFolderImport";
const EnableDisableButton = (props) => {
  const dispatch = useDispatch();
  const deviceActions = devicesSlice.actions;
  const geofencesActions = geofencesSlice.actions;
  const { t } = useTranslation();
  const checkClick = () => {
    if (props.select === "devices") {
      var array = _.cloneDeep(props.devicesState.filteredDevices);
      var device;
      if (props.isChecked) {
        for (let index = 0; index < array.length; index++) {
          device = array[index];
          device.isSelected = false;
          array[index] = device;
        }
        dispatch(
          deviceActions.setUpFilteredDeviceData({
            devices: array,
          })
        );
        props.onClick(false);
      } else {
        for (let index = 0; index < array.length; index++) {
          device = array[index];
          device.isSelected = true;
          array[index] = device;
        }
        dispatch(
          deviceActions.setUpFilteredDeviceData({
            devices: array,
          })
        );
        props.onClick(true);
      }
    } else {
      var array = _.cloneDeep(props.geofenceState.filteredGeofences);
      var geofence;
      if (props.isChecked) {
        for (let index = 0; index < array.length; index++) {
          geofence = array[index];
          geofence.isSelected = false;
          array[index] = geofence;
        }
        dispatch(
          geofencesActions.setFilteredGeofencesData({ geofences: array })
        );
        props.onClick(false);
      } else {
        for (let index = 0; index < array.length; index++) {
          geofence = array[index];
          geofence.isSelected = true;
          array[index] = geofence;
        }
        dispatch(
          geofencesActions.setFilteredGeofencesData({ geofences: array })
        );
        props.onClick(true);
      }
    }
  };
  return (
    <React.Fragment>
      {props.isChecked ? (
        <img
          className="cursor-pointer size_20 "
          onClick={() => checkClick()}
          src={FlavorImages.checked}
          alt="..."
        />
      ) : (
        <img
          className="cursor-pointer size_20"
          src={FlavorImages.check_empty}
          onClick={() => checkClick()}
          alt="..."
        />
      )}
      <div
        className={`${props.select === "geofence" ? "delete_geofence_button" : "enable_disable_power_button"}  cursor-pointer`}
        data-toggle="collapse"
        data-target="#collapsetwo"
        aria-expanded="true"
        aria-controls="collapsetwo"
      >
        <img src={FlavorImages.down_arrow} className="add_button_icon cursor-pointer" alt="..." />
        <label className="cursor-pointer" htmlFor="Email" style={{ textTransform: "capitalize" }}>
          {props.select === "geofence"
            ? t("Geofences.Deletegeofences")
            : ReactHtmlParser(t("devicepower.enabledisable"))}
        </label>
      </div>
    </React.Fragment>
  );
};

export default EnableDisableButton;
