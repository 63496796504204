import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
// import close_gray from "../../assets/images/close_gray.svg";
import { useTranslation } from "react-i18next";
import ErrorDialog from '../LocationHistory/ErrorDialog';
import _ from "lodash";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { shallowEqual, useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import * as genericService from '../../services/genericService';
import * as helper_functions from '../../helpers/helper_functions';
// import checked from "../../assets/images/checked.svg";

// import default_avatar from "../../assets/images/default_awatar.svg";
// import check_empty_white from "../../assets/images/check_empty_white.svg";
import DropDown from './DropDown';
// import fire_alt from "../../assets/images/fire-alt.svg";
// import cross_inactive from "../../assets/images/cross_inactive.svg";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
// import .KM from "../../assets/Helkama_images/.KM.svg";
// import Rectangle_grey from "../../assets/Helkama_images/Rectangle_grey.svg";
// import KM_white from "../../assets/Helkama_images/KM_white.svg";
// import fire_alt_white from "../../assets/Helkama_images/fire-alt_white.svg";
// import Rectangle_Black from "../../assets/Helkama_images/Rectangle_Black.svg";

import FlavorImages from "../../assetsFolderImport";


const CreateEditChallengePopup = (props) => {
    const { t } = useTranslation();
    const [end, setEnd] = useState(new Date());
    const [errorDialog, setShowErrorDialog] = useState(false);
    const [nameFieldError, setnameFieldError] = useState('')
    const [goalFieldError, setgoadFieldError] = useState('')
    const [nameField, setNameField] = useState('')
    const [challengtype, setchallengtype] = useState('')
    const [slectedDevice, setSelectDevice] = useState([]);
    const [goalField, setGoalField] = useState('')
    const [methodOk, setMethodOk] = useState(true)
    const [friedList, setFriendList] = useState([]);
    const [challengeid, setchallengeid] = useState('')
    const defaultOtionRef = React.useRef();
    const [defaultValue, setdefaultValue] = useState("")
    const { devicesState } = useSelector(
        (state) => ({ devicesState: state.devices }),
        shallowEqual
    );
    useEffect(() => {
        setEnd(props.editChallengeValue.stoptime)
        setGoalField(props.editChallengeValue.goal)
        setNameField(props.editChallengeValue.name)
        setchallengtype(props.editChallengeValue.type)
        setchallengeid(props.editChallengeValue.id)
    }
        , [props.editChallengeValue, props.friends?.friends]);

    const clearFields = () => {
        setEnd(new Date())
        setGoalField('')
        setchallengtype('')
        setNameField('')
        setnameFieldError('')
        setgoadFieldError('')
        setMethodOk(true)
    }
    const setValue = (value, label) => {
        setDeviceId(value)
        setdefaultValue(label)
    }
    const onFriendClick = (index) => {
        var array = _.cloneDeep(friedList);
        var friend = array[index];
        if (friend.isSelected !== true) friend.isSelected = true;
        else friend.isSelected = false;
        array[index] = friend;
        setFriendList(array);
    }
    let option = []
    useEffect(() => {
        devicesState.devices.forEach(device => {
            option.push({
                label: device.name,
                value: device.id
            })
        });

        setdefaultValue(devicesState.devices[0]?.name)
        setDeviceId(devicesState.devices[0]?.id)
        if (option.length) defaultOtionRef.current = option
    }
        , [devicesState.devices]);
    const [deviceId, setDeviceId] = useState('')
    const onDeviceClick = (id) => {
        if (deviceId == id) setDeviceId('')
        else
            setDeviceId(id);
    };

    const handleValidation = () => {
        let returnType = true
        if (!challengtype) {
            setMethodOk(false)
            returnType = false
        }
        if (!nameField) {
            setnameFieldError("Field Required")
            returnType = false
        }
        
        if (!goalField) {
            setgoadFieldError("Field Required")
            returnType = false
        }
        return returnType
    }
    const createEditChallenge = async () => {
        let dispatchArray = [];
        friedList.map((friend) => {
            if (friend.isSelected === true) {
                dispatchArray.push(friend.frienduserid);
            }
        });
        if (handleValidation()) {
            let data = {
                name: nameField,
                type: challengtype,
                goal: parseFloat(goalField),
                deviceid: deviceId,
            }
            if(dispatchArray.length)
            {
                data['friendsuserid']=dispatchArray
                //console.log(data.friendsuserid);
            }
            if (end) {
                data['endtime'] = helper_functions.default.formatISODate(
                    end
                )
            }
            else if (!end) {
                data['endtime'] = helper_functions.default.formatISODate(
                    new Date()
                )
            }
            if (challengeid) {
                data['challengeid'] = challengeid
            }

            const response = await genericService.post(`challenges/${props.editChallengeValue ? 'update' : 'create'} `, {
                ...data,
            });
            if (response.status === 200 || response.status === 201) {
                props.closePopup()
                props.refreshChallenges()
            }
            else {
                setShowErrorDialog(response.data.message)
            }
        }

    }
    useEffect(() => {
        let personalDevice = _.cloneDeep(devicesState.devices)
        setSelectDevice(personalDevice);
        if (props.editChallengeValue?.participants) {
            let tempParticipent = _.cloneDeep(props.friends?.friends)
            let participants = tempParticipent.map(friend => {
                if (props.editChallengeValue?.participants) {
                    const found = props.editChallengeValue?.participants.find(friendNew => friendNew.userid == friend.frienduserid)
                    //console.log(found);
                    if (found) {

                        friend.isSelected = true
                    }
                    else {
                        friend.isSelected = false
                    }
                }
                return friend
            });
            setFriendList(participants)
        }
        else {
            setFriendList(props.friends.friends)
        }
    }, [devicesState, props.friends.friends, props.editChallengeValue]);
    return (
        <>
            <Dialog open={props.show}
                PaperProps={{
                    sx: {
                        maxWidth: "700px",
                    },
                }} >
                <Card className="card">
                    <div
                        className="d-flex justify-content-between px-3 mt-4 width_700"
                    >
                        <label className="text_22_700 font_700_italic_gloria">{t("Medal.CreateChallenge")}</label>
                        <img
                            src={FlavorImages.close_gray}
                            className="cursor-pointer size_30"
                            onClick={() => {
                                props.closePopup()
                                clearFields()
                            }}
                            alt="..."
                        />
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6 col-12 ">
                                <div className="d-flex mt-1 justify-content-between">
                                    <div className="d-flex flex-column">
                                        <label className="text_13_700 my-1 ml-1 font_700_gloria">{t("signup.Name")}</label>
                                        <input
                                            type="text"
                                            value={nameField}
                                            onChange={(e) => {
                                                setNameField(e.target.value)
                                                setnameFieldError('')
                                            }}
                                            className={`form-control width_186_namefield background_grey2`}
                                        />
                                        <div className="invalid-feedback position-relative ml-1 mt-0">{nameFieldError}</div>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <label className="text_13_700 my-1 ml-1  font_700_gloria">{t("Medal.EndDate")}</label>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DateTimePicker
                                                inputFormat="dd/MM HH:mm"
                                                value={end}
                                                onChange={(newValue) => {
                                                    setEnd(newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        className="date_label  text_fieldDate_picker" {...params} />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                                <div className="d-flex mt-1 justify-content-between">
                                    <div className="d-flex my-0 py-0">
                                        <div className="d-flex flex-column">
                                            <label className="text_13_700 my-1 ml-1 font_700_gloria">{t("Alarm.Type")}</label>
                                            <div className={`d-flex ${methodOk ? "" : "border border-danger"}`}>
                                                <div
                                                    onClick={() => {
                                                        setchallengtype('kcal')
                                                        setMethodOk(true)
                                                    }}
                                                    className="d-flex cursor-pointer  align-items-center " >
                                                    <div className=" px-0 cursor-pointer  picContainer">
                                                        {challengtype == 'kcal' ?
                                                            <img
                                                                src={FlavorImages.Rectangle_grey}
                                                                className="cursor-pointer"
                                                                style={{ height: "38px" }}
                                                                alt="..."
                                                            /> : <img
                                                                src={FlavorImages.Rectangle_Black}
                                                                className="cursor-pointer"
                                                                style={{ height: "38px" }}
                                                                alt="..."
                                                            />}
                                                        {challengtype == 'kcal' ?
                                                            <img
                                                                className="size_35 cursor-pointer pic2 p-2 "
                                                                style={{ marginLeft: "2px", marginTop: "2px" }}
                                                                src={FlavorImages.fire_alt_white}
                                                                alt="..." /> : <img
                                                                className="size_35 cursor-pointer pic2 p-2 "
                                                                style={{ marginLeft: "2px", marginTop: "2px" }}
                                                                src={FlavorImages.fire_alt}
                                                                alt="..." />}

                                                    </div>
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        setchallengtype('km')
                                                        setMethodOk(true)
                                                    }}
                                                    className="d-flex cursor-pointer  align-items-center ml-2" >
                                                    <div className=" px-0 cursor-pointer  picContainer">
                                                        {challengtype == 'km' ?
                                                            <img
                                                                src={FlavorImages.Rectangle_grey}
                                                                className="cursor-pointer"
                                                                style={{ height: "38px" }}
                                                                alt="..."
                                                            /> : <img
                                                                src={FlavorImages.Rectangle_Black}
                                                                className="cursor-pointer"
                                                                style={{ height: "38px" }}
                                                                alt="..."
                                                            />}
                                                        {challengtype === 'km' ?
                                                            <img
                                                                className="size_35 cursor-pointer pic2 p-2 "
                                                                style={{ marginLeft: "2px", marginTop: "2px" }}
                                                                src={FlavorImages.KM_white}
                                                                alt="..." /> : <img
                                                                className="size_35 cursor-pointer pic2 p-2 "
                                                                style={{ marginLeft: "2px", marginTop: "2px" }}
                                                                src={FlavorImages.KM}
                                                                alt="..." />}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex  ml-2 flex-column" >
                                        <label className="text_13_700 my-1 ml-1  font_700_gloria">{challengtype === 'km' ? t("Medal.GoalDistance") : t("Medal.goalcalories") }</label>
                                        <input
                                            type="text"
                                            value={goalField}
                                            onChange={(e) => {
                                                setGoalField(e.target.value)
                                                setgoadFieldError('')
                                            }}
                                            className={`form-control background_grey2 `}
                                        />
                                        <div className="invalid-feedback position-relative ml-1 mt-0">{goalFieldError}</div>
                                    </div>
                                </div>
                                <div>
                                    <label className="text_13_700 my-1 ml-1 font_700_gloria">{t("Geofences.ChooseDevice")}</label>
                                    {slectedDevice.length ?
                                        <div className="scroll_div_16 d-md-block d-none rounded_grey flex-column">
                                            {slectedDevice.map((device, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className="px-3 py-2 d-flex  data_plane_shadow justify-content-between align-items-center" style={{ height: "40px" }}>
                                                        <div>
                                                            <label
                                                                className="text-black1 mb-0 text_14_500 font_400_gloria ml-2"
                                                            >
                                                                {device.name}
                                                            </label>
                                                        </div>
                                                        <div className="d-flex border_radius_25 justify-content-end">
                                                            {deviceId == device.id ? (
                                                                <img
                                                                    className="cursor-pointer size_20 border_radius_25  "
                                                                    src={FlavorImages.checked}
                                                                    onClick={() => onDeviceClick(device.id)}
                                                                    alt="unchecked"
                                                                />
                                                            ) : (
                                                                <img
                                                                    className="cursor-pointer size_20 border_radius_25 "
                                                                    src={FlavorImages.check_empty_white}
                                                                    onClick={() => onDeviceClick(device.id)}
                                                                    alt="unchecked"
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </div> : ""
                                    }
                                    <div className="d-md-none d-flex">
                                        {defaultOtionRef.current?.length ?
                                            <DropDown
                                                caseSensitivity={"uppercase"}
                                                setValue={(value, label) => setValue(value, label)}
                                                value={defaultValue}
                                                values={defaultOtionRef.current}
                                            /> : ""}

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <label className="text_13_700 my-1 ml-1 font_700_gloria">{t(" Participants ")}</label>
                                <div className={` scroll_div_25  alarm_list_shadow  d-md-block`}>
                                    {
                                        friedList ?
                                            <>
                                                {friedList.map((friend, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className="px-3 py-2 d-flex  data_plane_shadow justify-content-between align-items-center" style={{ height: "50px" }}>
                                                            <div>
                                                                <img
                                                                    className=" size_30 border_36 fit_cover_image"
                                                                    src={friend?.image ? friend?.image : FlavorImages.default_avatar}
                                                                    alt={"...No image"}
                                                                />
                                                                <label
                                                                    className="text-black1 mb-0 text_14_500 font_400_gloria ml-2"
                                                                >
                                                                    {friend.name}
                                                                </label>
                                                            </div>
                                                            <div className="d-flex border_radius_25 justify-content-end">
                                                                {friend.isSelected ? (
                                                                    <img
                                                                        className="cursor-pointer size_20 border_radius_25  "
                                                                        src={FlavorImages.checked}
                                                                        onClick={() => {
                                                                            onFriendClick(index)
                                                                        }}
                                                                        alt="unchecked"
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        className="cursor-pointer size_20 border_radius_25 "
                                                                        src={FlavorImages.cross_inactive}
                                                                        onClick={() => {
                                                                            onFriendClick(index)
                                                                        }
                                                                        }
                                                                        alt="unchecked"
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </>
                                            : ""
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-6">
                                <button
                                    onClick={() => {
                                        props.closePopup()
                                        clearFields()
                                    }}
                                    className="btn mt-3 btn-block background_grey text_15_700 font_700_gloria text-secondary">{t("Geofences.Cancel")}</button>
                            </div>
                            <div className="col-6">
                                <button
                                    onClick={() => createEditChallenge()}
                                    className="btn mt-3 btn-block bg-dark text_15_700 font_700_gloria text-white">{props.editChallengeValue ? 'Edit' : t("Medal.Create")}</button>
                            </div>
                        </div>
                    </div>
                </Card>
            </Dialog>
            <ErrorDialog
                showErrorDialog={errorDialog}
                select={"editChallenge"}
                errorMesage={errorDialog}
                onClose={() => setShowErrorDialog(false)}
            />
        </>
    )
}
export default CreateEditChallengePopup