import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// import forward_arrow_grey from "../../assets/images/forward_arrow_grey.svg";

import { SITE_TITLE } from "../../helpers/constants";
import { seo } from "../../helpers/seo";
import * as genericService from "../../services/genericService";
import ContactSupport from "./ContactSupport";
import SupportRow from "./SupportRow";
import { settingsSlice } from "../../slice/settingsSlice";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import BackWardArrow from "../../components/Buttons/BackwardArrow";
import FlavorImages from "../../assetsFolderImport";
const settingsActions = settingsSlice.actions;
const Support = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [faq, setFaq] = useState();
  const [faqValid, setFaqValid] = useState(false);
  const [locValid, setLocValid] = useState(false)
  const [UI, setUI] = useState("Main");
  const { authState } = useSelector(
    (state) => ({ authState: state.auth }),
    shallowEqual
  )

  const getLocationService = async () => {
    const response = await genericService.get("services/locations");
    if (response.status === 200) {
      //console.log(response);
     // const locationservices = response.data.servicelocations ?? [];
      setLocValid(response.data.valid);
    }
  };

  const getFaq = async () => {
    const data = {
      language: authState.activeLanguage,
    };

    const response = await genericService.post("services/faq", data);
    if (response.status === 200) {
      var info = response.data.faq;
      setFaqValid(response.data.valid)
      setFaq(info);
    }
  };

  const on_load = () => {
    getLocationService();
    getFaq();
    seo({
      title: SITE_TITLE,
      metaDescription: "",
    });
  };

  useEffect(() => {
    on_load();
  }, []);

  return (
    <>
      {UI === "Main" ? (
        <div
          className={`${props.showUI === true ? "show_ui" : "hide_ui"
            } col-md-8 d-flex  margin-top_21_support flex-column  inner`}
        >
          <BackWardArrow show={false}       //if passed false, it will be visible on below md devices, if passed true it will be visible irrespectively
            onClick={() => {
              props.setSelect_null()
              dispatch(settingsActions.setPage({ page: "" }));
            }}
          />
          <div className="d-flex flex-column mt-2  mt-md-5 ">
          {faq !== undefined && faq.length >0 && faqValid
          ?
            <label className="text_22_700 text-left my-1 px-1">
              {t("Settings.FAQ")}
            </label>
          : null
          }
            <div className="scroll_div_2 px-1 pb-3">
              {faq !== undefined && faqValid
                ? faq.map((question, index) => {
                  return (
                    <SupportRow
                      question={question}
                      index={index}
                      key={`enabled_${index}`}
                    />
                  );
                })
                : null}

              <ContactSupport />

              {process.env.REACT_APP_FLAVOR === "Bike" && locValid ?
              <>
              <label className="text_22_700 text-left my-1 px-1 pt-3">
              {t("Settings.ServiceLocations")}
              </label>
              <div 
              className="p-3 mt-1 cursor-pointer d-flex settings_rounded_white w-100  justify-content-between align-items-center"
              onClick={() => props.setSelect("ServiceLocation")}
              >
                <label className=" text_16_400 text-navy my-0 cursor-pointer">
                  {t("Settings.FindServiceLocations")}
                </label>

                <img
                  onClick={() => props.setSelect("ServiceLocation")}
                  src={FlavorImages.forward_arrow_grey}
                  className="size_15 cursor-pointer"
                  alt="..."
                />
              </div>
              </>
              :""}

              {process.env.REACT_APP_FLAVOR === "Bike"? 
              <>
              <label className="text_22_700 text-left my-1 px-1 pt-3">
              {t("Settings.ExternalLinks")}
              </label>
              <div 
              className="p-3 mb-3 mt-2 cursor-pointer d-flex settings_rounded_white w-100  justify-content-between align-items-center"
              onClick={() => {
                // Replace the URL with the actual external link you want to open
                const externalURL = "https://www.helkamavelox.fi";

                // Redirect to the external URL
                window.open(externalURL, "_blank");
              }}
              >
                <label className=" text_16_400 text-navy my-0 cursor-pointer">
                  {t("Settings.HelkamaSupport")}
                </label>

                <img
                  src={FlavorImages.forward_arrow_grey}
                  className="size_15 cursor-pointer"
                  alt="..."
                />
              </div>
              </>
              :""}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Support;
