import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import helper_functions from "../../helpers/helper_functions";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authSlice } from "../../slice/authSlice";
import { settingsSlice } from "../../slice/settingsSlice";
import { INDEX_URL } from "../../helpers/constants";


const settingsActions = settingsSlice.actions;
const DialogBox = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authactions = authSlice.actions;
  const history = useHistory();

  const { authState } = useSelector(
    (state) => ({ authState: state.auth }),
    shallowEqual
  );

  const closeDialog = () => {
    dispatch(settingsActions.setDialog({ dialog: false }));
  };


  const logout = () => {
    dispatch(settingsActions.setlogout({ logoutdial: true }));

  };
  const clearDialog = () => {
    dispatch(settingsActions.setClear({ cleardial: true }));
  };

  useEffect(() => {
  }, [])

  const removeLocalStorage = async () => {
    dispatch(authactions.setAuthToken({ authToken: null }));
    dispatch(authactions.setAuthData({ authData: null }));
    await helper_functions.removeLocalStorageValue("token");
    await helper_functions.removeLocalStorageValue("data");
    history.push(INDEX_URL);
  };
  return (
    <>
      <Dialog open={props.show}>
        <Card className="card">
          <div className="row ml-1 mt-3 px-3">
            <div className=" w-100 row text-center justify-content-center ">
              <div className="col-12 col-md-10 offset-md-1 ">
                <div className="form-outline">
                  <label className="mb-0 text_22_700 pb-4 text-black2">
                    {" "}
                    {t("Settings.DialogTitle")}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row ml-1 mt-3 px-3">
            <div className=" w-100 row  justify-content-center ">
              <div className="col-12 col-md-10 offset-md-1 ">
                <div className="form-outline">
                  <label className="mb-0 text_12_500 text-center pb-4 text-grey">
                    {" "}
                    {t("Settings.DialogText")}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="m-0 col-12 d-flex justify-content-between ">
            <button
              className="btn col height_40 rounded_8 text_15_700 btn_navy font-bold"
              onClick={() => clearDialog()}
              variant="light"
            >
              {t("Settings.Clearlist")}
            </button>

            <button
              className="btn col height_40 rounded_8 text_15_700 btn_red ml-3"
              onClick={() => logout()}
              id="next"
            >
              {t("Settings.Logoutall")}
            </button>
          </div>
          <div className="m-0 col-12 my-2 d-flex justify-content-between">
            <button
              className="btn col height_40 rounded_8 text_15_700 btn_grey  font-bold"
              onClick={() => closeDialog()}
              variant="light"
            >
              {t("actionDialog.cancel")}
            </button>
          </div>
        </Card>
      </Dialog>
    </>
  );
};

export default DialogBox;
