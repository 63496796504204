import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  accountinfo: [],
};
export const accountSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setAccountInfo: (state, action) => {
      state.accountinfo = action.payload.accountinfo;
    },
  },
});
