/*
 * @Author: SweTrack
 */
import React, { useState, useEffect } from "react";
import { MAIL_FORMATE, INDEX_URL, SITE_TITLE } from "../../helpers/constants";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { seo } from "../../helpers/seo";
import { useTranslation } from "react-i18next";
import * as loginService from "../../services/user/loginService2";
import PopUp from "../Device/PopUp";
import ReactHtmlParser from "react-html-parser";
import LanguageChanger from "../../components/LanguageChanger/LanguageChanger";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import _ from "lodash";
// import cross_red from "../../assets/images/cross_red.svg";
// import check_light_navy from "../../assets/images/check_light_navy.svg";
// import backward_arrow_grey from "../../assets/images/backward_arrow_grey.svg";
import FlavorImages from "../../assetsFolderImport";


const ForgetPassword = () => {
  const { t } = useTranslation();
  const [buttonText, setButtonText] = useState("");
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [is_valid_email, setIsValidEmail] = useState(-1);
	const [error, setError] = useState("");
  const { authState } = useSelector(
    (state) => ({ authState: state.auth }),
    shallowEqual
  )
  const submitForgotPassword = async (e) => {
    let errors = {};
    e.preventDefault();
    if (handleForgotPasswordValidation()) {
      const response = await loginService.ForgotPassword(fields.Email,authState.activeLanguage);
      if (response.status === 802) {
        if(response.data.errorCode === 20018 ||
            response.data.errorCode === "20018")
        {
          errors["Email"] = response.data.message
        }
        else
        {
          errors["Email"] = response.data.message
        }
        // if (
        //   response.data.errorCode === 20018 ||
        //   response.data.errorCode === "20018"
        // ) {
        //   errors["Email"] = t("errorCode.20018");
        //   setIsValidEmail(0);
        // }
      } else if (response.status === 200) {
        setIsValidEmail(1);
        errors["Email"] = "";
        setButtonText(ReactHtmlParser(t("login.ResetEmailSent")));
      } else {
        setIsValidEmail(1);
        errors["Email"] = "";
        // console.log(response.data.message,response.data)
        setError(response.data.message)
      }
      setErrors(errors);
    }
  };

  const handleForgotPasswordValidation = () => {
    let fields_temp = _.cloneDeep(fields);
    let errors = {};
    let formIsValid = true;

    if (fields_temp.Email !== "") {
      if (MAIL_FORMATE.test(fields_temp.Email)) {
        errors["Email"] = "";
        setIsValidEmail(1);
      }
      //  else {
      //   formIsValid = false;
      //   setIsValidEmail(0);
      //   errors["Email"] = t("login.Incorrect email Please try again");
      // }
    } else {
      errors["Email"] = "";
      setIsValidEmail(-1);
    }

    if (!fields_temp["Email"]) {
      formIsValid = false;
      errors["Email"] = t("errors.Please enter email");
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleChange = (field, e) => {
    let fields_temp = _.cloneDeep(fields);
    let errors = {};
    fields_temp[field] = e.target.value;

    if (fields_temp.Email !== "") {
      if (MAIL_FORMATE.test(fields_temp.Email)) {
        setIsValidEmail(1);
        errors["Email"] = "";
      } else {
        setIsValidEmail(0);
      }
    } else {
      errors["Email"] = "";
      setIsValidEmail(-1);
    }

    setFields(fields_temp);
    setErrors(errors);
  };

  const emptyEmail = () => {
    setFields({ ...fields, Email: "" });
    setIsValidEmail(-1);
  };

  const on_load = () => {
    setButtonText(ReactHtmlParser(t("login.ResetPassword")));

    seo({
      title: SITE_TITLE,
      metaDescription: "",
    });
  };

  useEffect(() => {
    on_load();
  }, []);

  return (
    <>
      <React.Fragment>
        <div className="col-12 p-4 h-100" id="login-page">
                <div className="row pt-2">
                    <div
                      className="btn_back rounded_white cursor-pointer justify-content-center align-items-center ml-3"
                      style={{ width: "35px", height: "35px" }}
                      onClick={() => window.history.back()}
                    >
                      <img src={FlavorImages.backward_arrow_grey} className="backward_button cursor-pointer" alt="..." />
                    </div>
                </div>
          <div className="container-fluid p-0 mt-5">
            <div className="col-md-6 offset-md-3 col-lg-4 offset-lg-4 align-items-center">
                
              <img
                src={FlavorImages.app_logo}
                className="img-fluid mx-auto d-block photo"
                alt="..."
              />
              <div className="col-12">
                <div className="col-12 d-flex align-items-center mt-5">
                  <label
                    htmlFor="Email"
                    className="text_34_700 text-center col-form-label font_700_italic_gloria w-100"
                  >
                    {ReactHtmlParser(t("login.Forgot Password"))}
                  </label>
                </div>

                <div className="col-12 d-flex align-items-center">
                  <label
                    htmlFor="Email"
                    className="text_16_400 font_400_italic_gloria text-navy text-center col-form-label w-100"
                  >
                    {ReactHtmlParser(t("login.Forgot Password Detail"))}
                  </label>
                </div>

                <div className="col-12 inputBlock pb-2   col-sm-12">
                  <TextField
                    autoComplete="off"
                    value={fields["Email"]}
                    htmlFor="Email"
                    className="col-12 Mui-focused label-input-bold"
                    id="Email"
                    label={ReactHtmlParser(t("login.Email"))}
                    variant="standard"
                    placeholder="Email@gmail.com"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {is_valid_email === 0 ? (
                            <img
                              src={FlavorImages.cross_red}
                              className="input-icon cursor-pointer"
                              onClick={() => {
                                emptyEmail();
                              }}
                            ></img>
                          ) : is_valid_email === 1 ? (
                            <img
                              src={FlavorImages.check_light_navy}
                              className=" input-icon"
                            ></img>
                          ) : (
                            ""
                          )}
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => handleChange("Email", e)}
                    defaultValue={fields["Email"]}
                  />

                  <span></span>
                  {typeof errors["Email"] !== "undefined" &&
                  errors["Email"] !== "" ? (
                    <span className="invalid-feedback">{errors["Email"]}</span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="px-0 mt-4r col-12  no-gutters">
                  <div className="col-sm-12   ">
                    <button
                      type="submit"
                      className="btn_login background_black_helkama font_700_italic_gloria"
                      id="resetPassword"
                      onClick={(e) => submitForgotPassword(e)}
                    >
                      {buttonText}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <LanguageChanger />
          </div>
        </div>
        {error ? <PopUp setError={setErrors} error={error} /> : ""}
      </React.Fragment>
    </>
  );
};

export default ForgetPassword;
