import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { MAIL_FORMATE, INDEX_URL, SITE_TITLE } from "../../helpers/constants";
import * as loginService from "../../services/user/loginService2";
import { shallowEqual, useSelector } from "react-redux";
import * as accountService from "../../services/user/accountService2";
import LanguageChanger from "../../components/LanguageChanger/LanguageChanger";
import _ from "lodash";
import { seo } from "../../helpers/seo";
import helper_functions from "../../helpers/helper_functions";
import { authSlice } from "../../slice/authSlice";
import { useHistory } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
// import cross_red from "../../assets/images/cross_red.svg";
// import check_light_navy from "../../assets/images/check_light_navy.svg";
import FlavorImages from "../../assetsFolderImport";

const authactions = authSlice.actions;
const SignInPage2 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [is_valid_email, setIsValidEmail] = useState(-1);
  const [is_valid_password, setIsValidPassword] = useState(-1);
  const { authState } = useSelector(
    (state) => ({ authState: state.auth }),
    shallowEqual
  )
  const login = async () => {
    let errors = {};
    if (handleValidation()) {
      const response = await loginService.login(fields.Email, fields.Password, authState.activeLanguage);
      if (response.status === 802) {
        if (
          response.data.errorCode === 20018 ||
          response.data.errorCode === "20018"
        ) {
          errors["Email"] = response.data.message
          setIsValidEmail(0);
        } else if (
          response.data.errorCode === 20019 ||
          response.data.errorCode === "20019"
        ) {
          errors["Password"] = response.data.message
          setIsValidPassword(0);
        } else if (response.data.errorCode === 20020) {
          errors["Email"] = response.data.message
          setIsValidEmail(0);
        }
        else if (response.data.errorCode == 20116) {
          errors["Password"] = response.data.message
          setIsValidPassword(0);
        }
      } else if (response.status === 200) {
        setIsValidEmail(1);
        setIsValidPassword(1);

        //save token to redux
        dispatch(
          authactions.setAuthToken({ authToken: response.data.AccessToken })
        );
        const user_info_response = await accountService.getAccountInfo(
          response.data.AccessToken
        );
        if (user_info_response.status === 200) {
          dispatch(
            authactions.setAuthData({ authData: user_info_response.data })
          );
          //console.log(user_info_response)
          if (process.env.REACT_APP_FLAVOR === "Bike"){
            toast.success(t("toast.login") + user_info_response.data.response.username + "!");
          } else {
            toast.success(t("toast.login") + user_info_response.data.username + "!");
          }
          setTimeout(() => {
            history.push(INDEX_URL + "devices");
          }, 600);
        }
      }
      setErrors(errors);
    }
  };

  const handleValidation = () => {
    let fields_temp = _.cloneDeep(fields);
    let errors = {};
    let formIsValid = true;

    if (fields_temp.Email !== "") {
      if (MAIL_FORMATE.test(fields_temp.Email)) {
        errors["Email"] = "";
        setIsValidEmail(1);
      } else {
        formIsValid = false;
        errors["Email"] = t("login.Incorrect email Please try again");
        setIsValidEmail(0);
      }
    } else {
      errors["Email"] = "";
      setIsValidEmail(1);
    }

    if (!fields_temp["Email"]) {
      formIsValid = false;
      errors["Email"] = t("errors.Please enter email");
      setIsValidEmail(0);
    }

    if (!fields_temp["Password"]) {
      formIsValid = false;
      errors["Password"] = t("errors.Please enter password");
      setIsValidPassword(0);
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleChange = (field, e) => {
    let fields_temp = _.cloneDeep(fields);
    let errors = {};
    fields_temp[field] = e.target.value;

    if (fields_temp.Email !== "") {
      if (MAIL_FORMATE.test(fields_temp.Email)) {
        setIsValidEmail(1);
        errors["Email"] = "";
      } else {
        setIsValidEmail(0);
      }
    } else {
      errors["Email"] = "";
      setIsValidEmail(-1);
    }

    if (fields_temp.Password !== "") {
      setIsValidPassword(-1);
    }

    setFields(fields_temp);
    setErrors(errors);
  };

  //when user presses enter on email or password, call login method
  const callLoginOnEnter = (event) => {
    if (event.key === "Enter") {
      login();
    }
  };

  const on_load = () => {
    seo({
      title: SITE_TITLE,
      metaDescription: "",
    });
  };

  useEffect(() => {
    on_load();
  }, []);

  const emptyEmail = () => {
    setFields({ ...fields, Email: "" });
    setIsValidEmail(-1);
  };

  return (
    <>
      <div className="col-12 p-4 vh-100 d-flex align-items-center" id="login-page">
        <div className="container-fluid p-0 mt-5">
          <div className="h-100 col-md-6 offset-md-3 col-lg-4 offset-lg-4 mr-auto align-items-center login_padding">
            <img
              src={FlavorImages.app_logo_login}
              className="img-fluid mx-auto d-block photo mb-2"
              alt="..."
            />
            <div className="col-12 mx-auto">
              <div className="col-12 d-flex align-items-center">
                <label
                  htmlFor="Email"
                  className={`text_34_700 text-center col-form-label w-100 ${process.env.REACT_APP_FLAVOR === "Tracking" ? "d-none " : "d-block font_700_italic_gloria"} `}
                >
                  {ReactHtmlParser(t('login.title', { AppName: process.env.REACT_APP_NAME}))}
                </label>
              </div>

            {process.env.REACT_APP_FLAVOR === "Tracking" || process.env.REACT_APP_FLAVOR === "Alco" || process.env.REACT_APP_FLAVOR === "Bike"
            ?
            null
            :
            <div className="col-12 d-flex align-items-center">
                <label
                  htmlFor="Email"
                  className="text_16_400 text-navy text-center col-form-label w-100"
                >
                  {ReactHtmlParser(t("login.subTitle"))}
                </label>
              </div>
            }
              

              <div className="col-12 inputBlock login_padding col-sm-12 ">
                <TextField
                  autoComplete="off"
                  value={fields["Email"] ?? ""}
                  htmlFor="Email"
                  className="col-12 Mui-focused label-input-bold "
                  id="Email"
                  label={ReactHtmlParser(t("login.Email"))}
                  variant="standard"
                  placeholder="email@email.com"
                  style={{ background: 'unset' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {is_valid_email === 0 ? (
                          <img
                            alt="..."
                            src={FlavorImages.cross_red}
                            className="input-icon cursor-pointer"
                            onClick={() => {
                              emptyEmail();
                            }}
                          ></img>
                        ) : is_valid_email === 1 ? (
                          <img
                            alt="..."
                            src={FlavorImages.check_light_navy}
                            className="input-icon"
                          ></img>
                        ) : (
                          ""
                        )}
                      </InputAdornment>
                    ),
                  }}
                  onKeyPress={(event) => callLoginOnEnter(event)}
                  onChange={(e) => {
                    handleChange("Email", e);
                  }}
                />

                <span></span>
                {typeof errors["Email"] !== "undefined" &&
                  errors["Email"] !== "" ? (
                  <span className="invalid-feedback font_400_gloria">{errors["Email"]}</span>
                ) : (
                  ""
                )}
              </div>

              <div className="col-12 inputBlock pt-2  col-sm-12">
                <TextField
                  autoComplete="off"
                  type="Password"
                  value={fields["Password"] ?? ""}
                  htmlFor="Password"
                  className="col-12 Mui-focused label-input-bold"
                  id="Password"
                  label={ReactHtmlParser(t("login.Password"))}
                  variant="standard"
                  placeholder="• • • • • •"
                  onKeyPress={(event) => callLoginOnEnter(event)}
                  onChange={(e) => {
                    handleChange("Password", e);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {is_valid_password === 0 ? (
                          <img
                            src={FlavorImages.cross_red}
                            className="text-red input-icon"
                          ></img>
                        ) : is_valid_password === 1 ? (
                          <img
                            src={FlavorImages.check_light_navy}
                            className="nput-icon"
                          ></img>
                        ) : (
                          ""
                        )}
                      </InputAdornment>
                    ),
                  }}
                />

                <span></span>
                {typeof errors["Password"] !== "undefined" &&
                  errors["Password"] !== "" ? (
                  <span className="invalid-feedback font_400_gloria w-70 ">{errors["Password"]}</span>
                ) : (
                  ""
                )}
              </div>

              <div className=" px-0 col-12 no-gutters text-right pt-1">
                <Link
                  to={INDEX_URL + "forget-password"}
                  className=" text_12_500 font_500_gloria"
                  data-whatever="Forgot Password"
                >
                  {ReactHtmlParser(t("login.Forgot Password"))}
                </Link>
              </div>
              <div className=" px-0 mt-4 col-12  no-gutters">
                <div className="col-sm-12   ">
                  <button
                    type="submit"
                    className="btn_login background_black_helkama text_gloria_17_700_italic"
                    onClick={(e) => login(e)}
                  >
                    {ReactHtmlParser(t("login.Login"))}
                  </button>
                </div>
              </div>

              <div className="mt-3 col no-gutters">
                <div className="col-sm-12 d-flex justify-content-center col text-center">
                  <Link
                    to={INDEX_URL + "signup"}
                    className="btn btn-custom btn-block btn-text-only text_15_700 font_700_gloria"
                  >
                    {ReactHtmlParser(t("login.Sign Up"))}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <LanguageChanger select={"sigIn"} />
        </div>
      </div>
    </>
  );
};

export default SignInPage2;
