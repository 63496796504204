import React from "react";
import helper_functions from "../../helpers/helper_functions";
let width = window.screen.width;  // use only when no imapact while changing screen from web to mobile
const ActiveDevices = (props) => {
  let backgroundColor = "#FFFFFF";
  if (props.index % 2 !== 0) backgroundColor = "#F2F2F2";
  const getDate = (date) => {
    let formateDate = helper_functions.formatDate(date);
    return formateDate;
  };
  return (
    <>
      <div
        onClick={() => props.onClick(props.device.deviceid)}
        className={`d-flex justify-content-center align-items-center w-100 pr-2`}
        style={{ backgroundColor, height: "74px" }}>
        <div className="col mr-1 w-75">
          <div className="row">
            <div className="device_active_small pt-2" >
              <div className={`${width < 768 ? "col-6" : "col-9" } pl-1`}>
                <div className="device_row_left_name">
                  <label className={` m-0 p-0 text_17_700 break_text`}>
                    {props.devicename}
                  </label>
                </div>
              </div>
              <div className={`${width < 768 ? "col-6" : "col-3" } pr-1`}>
                <div className="device_row_left_name ">
                  <label className={` m-0 p-0 text-right text_17_700`}>
                    {props.device.status}
                    {/* Invoice */}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-1">
            <div className="device_active_small">
              <div className="col-5 pl-1">
                <div className="device_row_left_name">
                  <label className={`text_12_400 text-left px-0 text-grey`}>
                    {props.deviceuniqueid}
                  </label>
                </div>
              </div>
              <div className="col-7 pr-1">
                <div className="device_row_left_name ">
                  <label className={`text_12_400 text-right px-0 text-grey`}>
                    {getDate(props.device.renewedexpiringdate)}
                    {/* {props.device.paymentmethod}
                    Next payment: 21/10/30 */}
                  </label>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div>
          {
            props.isSelection ? <img
              className="mx-2 check_box_style border_radius_25"
              src={
                props.src
              }
              alt="checked"
            /> : ""
          }
        </div>
      </div>
    </>
  );
};

export default ActiveDevices;
