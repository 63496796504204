import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// import geo_fence_row from "../../assets/images/geo_fence_row.svg";
// import geo_fence_icon_helkama from "../../assets/Helkama_images/geofence.svg";
// import map_marker_alt from "../../assets/images/map_marker_alt.svg";
// import map_marker_alt_helkama from "../../assets/Helkama_images/map-marker-alt.svg";
// import edit_dots from "../../assets/images/edit_dots.svg";
// import edit_dots_helkama from "../../assets/Helkama_images/edit-dots.svg";
import { geofencesSlice } from "../../slice/geofencesSlice";
// import checked from "../../assets/images/checked.svg";
// import check_empty from "../../assets/images/check_empty.svg";
import _ from "lodash";
import { shallowEqual, useSelector } from "react-redux";
import { INDEX_URL } from "../../helpers/constants";
import FlavorImages from "../../assetsFolderImport";

const geofencesActions = geofencesSlice.actions;
const GeofenceRow = (props) => {
  const { geofencesState } = useSelector(
    (state) => ({ geofencesState: state.geofences }),
    shallowEqual
  );
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(false);

  const Toggle = () => {
    setSelected(!selected);
    if (props.isSelection === true) props.onClick();
  };

  const OnDelete = (index) => {
    var array = _.cloneDeep(geofencesState.filteredGeofences);
    if (index !== -1) {
      var geoFence = array[index];

      if (geoFence.isSelected !== true) geoFence.isSelected = true;
      else geoFence.isSelected = false;
      array[index] = geoFence;
      dispatch(
        geofencesActions.setFilteredGeofencesData({
          geofences: array,
        })
      );
    }
    props.setConfirmDelete()
  }

  const focusGeofenceOnMap = (geofence) => {
    dispatch(geofencesActions.focusGeofenceOnMap({ id: geofence.id }));
    if (window.innerWidth < 768) {
      props.setDownArrowClick()
    }
  };
  const setEditGeoFenceUI = (id) => {
    history.push(INDEX_URL + "addeditgeofence/" + id);
  };

  const renderRow = () => {
    return (
      <div className={`${props.index % 2 === 0
                      ? "bg-white"
                      : "grey_shadow_f2" } geofence_row_small`}
                      onClick={() => Toggle()}>
        <div className="d-flex align-items-center w-75" onClick={() =>
          focusGeofenceOnMap(props.geofence)}>
          {props.isSelection ? (
            props.selected || props.geofence.isSelected ? (
              <img
                className="mr-2 border_radius_25 cursor-pointer margin-top-2 size_20"
                src={FlavorImages.checked}
                alt="checked"
                onClick={() => props.setChooseAll(false)}
              />
            ) : (
              <img
                className="mr-2 border_radius_25 cursor-pointer margin-top-2 size_20"
                src={FlavorImages.check_empty}
                alt="unchecked"
              />
            )
          ) : null}
          <img src={ FlavorImages.geo_fence_row} className="size_20 ml-2" alt="..." />

          <label className="text_16_700 geoname m-3 break_line">
            {props.geofence.name}
          </label>
        </div>
        <div className=" d-flex align-items-center ml-auto">
          <div className="d-none d-md-block">
            <label className="cursor-pointer  device_row_left_active_label">
              <a onClick={() => setEditGeoFenceUI(props.geofence.id)}>
                {t("geofences.Edit")}
              </a>
            </label>
          </div>
          <img
            onClick={() =>
              focusGeofenceOnMap(props.geofence)}
              src={ FlavorImages.map_maker_alt_two}
            className="device_row_map_icon mx-2 cursor-pointer"
            alt="..."
          />
          <div>
            <div className="dropdown">
              <img
                className="device_row_map_icon mx-2 d-md-none d-block cursor-pointer dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                alt="show"
                src={FlavorImages.edit_dots}
              />
              <div className="dropdown-menu min_width" aria-labelledby="dropdownMenuButton">
                <a className="dropdown-item text-black " onClick={() => setEditGeoFenceUI(props.geofence.id)}>Edit</a>
                <a className="dropdown-item text-black " onClick={() => OnDelete(props.index)}>Delete</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="device_row">{renderRow()}</div>
    </React.Fragment>
  );
};

export default GeofenceRow;
