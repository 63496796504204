import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import BackWardArrow from "../../components/Buttons/BackwardArrow";
// import check from "../../assets/images/check.svg";
// import forward from "../../assets/images/forward_arrow_grey.svg";
// import pic_default from "../../assets/images/pic_default.svg";

import RowIcon from "../../components/RowIcon/RowIcon";
import SwitchRow from "./SwitchRow";
import FlavorImages from "../../assetsFolderImport";


const Customize = (props) => {
    const myrefs = useRef([]);
    const { t } = useTranslation();
    const [sameForAll, setSameForAll] = useState(true);
    const [check_1, set_1] = useState(false);
    const [check_2, set_2] = useState(false);
    const [letters, setLets] = useState({});

    var data = [{
        "first_name": "Carolann"
    }, {
        "first_name": "Rockwell"
    }, {
        "first_name": "Zeb"
    }, {
        "first_name": "Morna"
    }, {
        "first_name": "Ardyce"
    }, {
        "first_name": "Harry"
    }, {
        "first_name": "Cornall"
    }, {
        "first_name": "Anallise"
    }, {
        "first_name": "Carmencita"
    }, {
        "first_name": "Doralynne"
    }, {
        "first_name": "Vanda"
    }, {
        "first_name": "Sherri"
    }, {
        "first_name": "Bette-ann"
    }, {
        "first_name": "Emory"
    }, {
        "first_name": "Bard"
    }]
    const checked = () => {
        if (check_1 || check_2) {
            set_1(false)
            set_2(false)
        }
    }
    const clicked = (index) => {
        myrefs.current[index].focus();
    }
    const prepareAlphabets = () => {
        let letters = {};
        for (var i = 97; i < 123; i++) {
            let names = []
            data.filter(item =>
                item.first_name.toLowerCase().startsWith(String.fromCharCode(i))
            ).map(item => {
                if (item.hasOwnProperty("first_name")) {
                    names.push(item)
                }
                else {
                    names.push(1)
                }
            })
            if (names) {
                letters[String.fromCharCode(i)] = names
            }
        }
        setLets(letters)
    }
    useEffect(() => {
        true ? set_1(!check_1) : set_2(!check_2);
        prepareAlphabets();

    }, []);

    let textInput = {};

    return (
        <>
            <div
                className={`${props.showUI === true ? "show_ui" : "hide_ui"
                    } col-md-8 d-flex  mt-3 flex-column  inner`}
            >
                <BackWardArrow margin={"mt-4rm"} show={true} onClick={() => props.setSelect("Socials")} />
                <label className=" mt-3 m-0 text_28_700 text-left " >
                    {t("Settings.CustomizeVisibility")}
                </label>
                <div className="row m-0 p-0">
                    <div className="col-12 pr-3 pr-md-0 m-0 p-0">
                        {Object.keys(letters).map((objectKey, index) => {
                            const namesArray = letters[objectKey];
                            if (namesArray[0]) {

                                return (
                                    <>
                                        <div ref={(elem) => (myrefs.current[index] = elem)} className="text_11_700 ml-1 mt-3 mb-2">{objectKey.toUpperCase()}</div>
                                        {
                                            namesArray.map((nameObj, index) => {
                                                return (
                                                    <div name={objectKey.toUpperCase()} className={`height_55  pl-2 pr-3 align-items-center 
                    d-flex justify-content-between bg-white
                    ${objectKey.toUpperCase()} 
                    ${namesArray.length === 1 ? "rounded_all_8px_shadows" : index === 0
                                                            ? "rounded_top_8px_shadows"
                                                            : index === namesArray.length - 1
                                                                ? "rounded_bottom_8px_shadows"
                                                                : "rounded_middle_8px_shadows"}`}>
                                                        <div className="d-flex align-items-center">
                                                            <img
                                                                className="size_30 pl-1 mr-4"
                                                                src={FlavorImages.pic_default}
                                                                alt="..."
                                                            />
                                                            <div className="text_16_400 ml-1">{nameObj.first_name}</div>
                                                        </div>

                                                        <div className="pr-1 size_20 bg-dark rounded_10"></div>

                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                                )
                            }
                        })
                        }
                    </div>
                    <div className="col-1 d-none right_12px position-fixed m-0 p-0">
                        {Object.keys(letters).map((objectKey, index) => {
                            const namesArray = letters[objectKey];
                            if (namesArray[0])
                                return (
                                    <div onClick={() => clicked(index)} className="text_11_700 mt-1 d-flex justify-content-end">{objectKey.toUpperCase()}</div>
                                );
                        })}
                    </div>

                </div>
            </div>
        </>
    );
};
export default Customize;

