/*
 * @Author: Abhi Bhatt
 * @Date:   2020-12-23 14:12:09
 * @Last Modified by:   abhi
 * @Last Modified time: 2021-04-14 12:41:01
 */
import React from "react";
import { useTranslation } from "react-i18next";
import SearchOptionButton from "../Buttons/SearchOptionButton";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import useDrag from "./useDrag";
import "./hideScrollbar.css";

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

const ScrollMenuName = (props) => {
  const { t } = useTranslation();
  const OnRemove = (index) => {
    props.onRemove(index);
  };

  const OnClearAll = () => {
    props.OnClearAll();
  };

  function onWheel(apiObj, ev) {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 1;
  
    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }
  
    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  }
  

  const { dragStart, dragStop, dragMove, dragging } = useDrag();
  const handleDrag =
    ({ scrollContainer }) =>
      (ev) =>
        dragMove(ev, (posDiff) => {
          if (scrollContainer.current) {
            scrollContainer.current.scrollLeft += posDiff;
          }
        });


  return (
    <React.Fragment>
      <ScrollMenu
        itemClassName={"mx-1"}
        scrollContainerClassName={"pe-5"}
        onWheel={onWheel}
        onMouseDown={() => dragStart}
        onMouseUp={() => dragStop}
        onMouseMove={handleDrag}
      >
        {props.menuItems.map(({ name }, index) => (
          <SearchOptionButton
            id={index}
            itemId={name}
            name={name}
            key={name}
            onRemove={() => OnRemove(index)}
          />
        ))}

        {props.menuItems.length > 0 ? (
          <div
            className="search_capsule mr-3"
            onClick={() => OnClearAll()}
            style={{
              backgroundColor: "#E04D43",
            }}
          >
            <label className="text-center px-2 text-uppercase text-truncate" htmlFor="Email">
              {t("common.ClearAll")}
            </label>
          </div>
        ) : (
          <div />
        )}
      </ScrollMenu>
    </React.Fragment>
  );
};

export default ScrollMenuName;