/*
 * @Author: Abhi Bhatt
 * @Date:   2020-12-23 14:12:09
 * @Last Modified by:   abhi
 * @Last Modified time: 2021-04-14 12:41:01
 */
import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import RoutesTutorial from "../../assets/images/RoutesTutorial.svg";
// import backward_arrow_grey from "../../assets/images/backward_arrow_grey.svg";
// import close_gray from "../../assets/images/close_gray.svg";
import FlavorImages from "../../assetsFolderImport";
let width = window.innerWidth;  // use only when no imapact while changing screen from web to mobile


const GeofenceTutorial = (props) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);

  const onClose = () => {
    props.onClose();
  };

  useEffect(() => {
    if (props.show) {
      setPage(1);
    }
  }, [props.show]);


  return (
    <>
      <Dialog className="custome_dialog" open={props.show}>
        <div className="add_device_dialog d-flex flex-row">
          <div className="dialog_side_panel d-none d-md-flex w-50">
            <div className="px-0 d-flex flex-column justify-content-center  position-relative ">
              <div
                className={""}
              >
                <img
                  className="add_device_logo px-5"
                  src={FlavorImages.RoutesTutorial}
                  alt="..."
                />
              </div>
            </div>
          </div>
          <div className="d-flex jusify-content-center bg-white" style={{ width: "300px" }}>
            <div className="w-100 h-100 d-flex flex-column">
              <div className="d-flex  justify-content-between align-items-center p-3 py-4">
              <img
                  className={"size_25 cursor-pointer hide_ui"}
                  src={FlavorImages.backward_arrow_grey}
                  alt="..."
                />
                <label className="text_22_700   mb-0 ">
                  {t("Routes.Routes")}
                </label>
                <img
                  className="size_25 mr-0 cursor-pointer"
                  src={FlavorImages.close_gray}
                  onClick={() => onClose()}
                  alt="..."
                />
              </div>

              <div className=" px-0 ">
                <div
                  className={"d-flex px-4  mt-2 flex-column align-self-stretch inner"}
                >
                  <label className="text_22_700 hide_ui" mt-2>
                    {t("Routes.RoutesTitle")}
                  </label>
                  <label className="dialog_body_detail">
                    {t("Routes.RouteInofTitle")}
                  </label>
                </div>
              </div>

              <div className="row pb-4 mt-auto mx-0 dialog_bottom_height">
                <div className="col-12 p-0 text-center">
                  <div
                    className={"col-12 text-center inner"}
                  >
                    <button
                      type="submit"
                      className="dialog_btn"
                      onClick={() => onClose()}
                      id="next"
                    >
                      {t("Routes.Iunderstand")}
                    </button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default GeofenceTutorial;
