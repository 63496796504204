import React from "react";
import { useTranslation } from "react-i18next";
const RowIcon = (props) => {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <div className={`bg-white py-3 px-3 rowicon_shadow d-flex justify-content-between cursor-pointer
        ${props.border && props.border}
        `}
                onClick={() => { props.onclick() }}
            >
                <label className={`m-0 cursor-pointer text_16_${props.show ? "700" : "400"} text-left p-0`}>
                    {t(props.label)}
                </label>
                <img
                    className={`${props.show || props.Show === "show" ? "d-flex" : "d-none"}`}
                    src={props.src}
                    alt="..."
                />
            </div>
        </React.Fragment>
    );
};

export default RowIcon;
