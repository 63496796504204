import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import { authSlice } from "../../slice/authSlice";
import { useDispatch } from "react-redux";
import { devicesSlice } from "../../slice/deviceSlice";
import * as genericService from "../../services/genericService";

const authAction = authSlice.actions;
const deviceActions = devicesSlice.actions;

const TermofService = (props) => {
  const dispatch = useDispatch();
  const [updatedate, setupdatedate] = useState()
  const [messages, setMessages] = useState(false)
  const [messageId, setMessageId] = useState("")
  const { devicesState } = useSelector(
    (state) => ({ devicesState: state.devices }),
    shallowEqual
  );
  const { authState } = useSelector(
    (state) => ({ authState: state.auth }),
    shallowEqual
  )
  const [bottom, setBottom] = useState(false);
  const [reachBottom, setReachBottom] = useState(false)
  const { t } = useTranslation();

  const acceptCondition = async (msg) => {
    setReachBottom(true)
    if(msg.forcedpopup === 1)
    {
      const response = await genericService.post(`notification/acceptmessage`,{ 
        messageid:messageId
      });
      if (response.status === 200) {
        dispatch(
          deviceActions.setshowTermofService({
            showTermofService: false,
          })
        );
      }
      else if (response.data.errorCode == "20099")
      {
        dispatch(
          deviceActions.setshowTermofService({
            showTermofService: false,
          })
        );
      }
    }
    else
    {
      dispatch(
        deviceActions.setshowTermofService({
          showTermofService: false,
        })
      );
    }

  }
  const handleScroll = (e) => {
    if (!reachBottom) {
      // console.log(e.target.scrollHeight, e.target.scrollTop)
      if(e.target.scrollTop >10)
      {
        setBottom(true)
        bottom && setReachBottom(true)
      }
    }
  };
  useEffect(() => {
    if(authState.notificationMessage)
    {
    authState.notificationMessage.message.forEach(msg => {
      if (msg.forcedpopup === 1) {
          setupdatedate(msg.date)
          setMessages(msg)
          if(msg.body.length < 600)
          {
            setBottom(true)
          }
          dispatch(
            deviceActions.setshowTermofService({
              showTermofService: true,
            })
          );
          setMessageId(msg.id)
        }
      });
    }
},[authState.notificationMessage])
  useEffect(() => {
      if(authState.notificationMessage){
        authState.notificationMessage.message.forEach(msg => {
           if (devicesState.bannerClick === msg.id  && msg.staysinapp === 1)
          {
            setupdatedate(msg.date)
            setMessages(msg)
            if(msg.body.length < 600)
            {
              setBottom(true)
            }
            setMessageId(msg.id)
            }
          })
        }
  },[devicesState.bannerClick])
  return (
    <>
      {messages &&
        <Dialog open={devicesState.showTermofService}>
          <Card
          className="card width_466">
            <div
              className="dialog_top_sort_row pt-0 pb-1 card-header pl-4 ml-1"
              style={{ backgroundColor: "white" }}
            >
              <div className="d-flex flex-column">
                <label className="text_17_700 font_700_italic_gloria ">{messages.header}</label>
                <label className="text_14_500 text-grey "> {t("signup.Last_updated")}
                  {updatedate}</label>
              </div>
            </div>
            <div
              onScroll={(e) => handleScroll(e)}
              className="card-body pt-2 mb-0 pb-0 overflow-auto"
              >
              <>
                <p className="text_12_400 padding_left_11">
                  {messages.body}
                </p>
              </>
            </div>
            <div className="m-0 card-footer  d-flex justify-content-end">
              <button
                disabled={!bottom}
                onClick={() => acceptCondition(messages)}
                className="btn btn-sm py-1 background_black_helkama text-12-700 px-2 bg-navy text-white ml-2 text_gloria_17_700_italic text-uppercase"
              >
                {messages.staysinapp === 0 ? t(" Accept")  : t(" Close") }
              </button>
            </div>
          </Card>
        </Dialog>
      }
    </>
  );
};
export default TermofService;
