import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
// import stream from "../../assets/images/stream.svg";
import SideBarNew from "../../components/SideBar/SideBarNew";
import { INDEX_URL, SITE_TITLE } from "../../helpers/constants";
import { seo } from "../../helpers/seo";
import { devicesSlice } from "../../slice/deviceSlice";
import Map from "../Maps/Map";
import LiveStreamTutorial from "./LiveStreamTutorial";
import * as devicesService2 from "../../services/devices/devicesService2";
import ShowLiveStream from "./ShowLiveStream";
import { groupSlice } from "../../slice/groupSlice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import up_arrow_grey from "../../assets/images/up_arrow_grey.svg";
// import up_arrow_black from "../../assets/Helkama_images/up_arrow_black.svg";
// import livestream from "../../assets/Helkama_images/livestream.svg";
import { locationHistorySlice } from "../../slice/locationHistorySlice";
import FlavorImages from "../../assetsFolderImport";

const locationHistoryActions = locationHistorySlice.actions;
const LiveStream = () => {
  const { t } = useTranslation();
  const groupActions = groupSlice.actions;
  const deviceActions = devicesSlice.actions;
  const [firstDownClick, setFirstDownClick] = useState(false)
  const history = useHistory();
  const [downArrowClick, setDownArrowClick] = useState(false);
  const dispatch = useDispatch();
  const [showTutorial, setShowTutorial] = useState(false);
  const { devicesState } = useSelector(
    (state) => ({ devicesState: state.devices }),
    shallowEqual
  );
  const on_load = () => {
    seo({
      title: SITE_TITLE,
      metaDescription: "",
    });
  };
  const closeBottomPopupMobile = () => {
    setDownArrowClick(!downArrowClick)
    setFirstDownClick(true)
  }
  const loadDevices = async () => {
    const response = await devicesService2.getDevicesCompactInfo();
    if (response.status === 200) {
      const devices = response.data.devices ?? [];
      const pendingDevices = response.data.pendingSharedDevices ?? [];
      const newDevices = devices.concat(pendingDevices);
      dispatch(
        deviceActions.setUpDeviceData({
          devices: newDevices,
        })
      );
    }
  };
  const loadGroups = async () => {
    const response = await devicesService2.getDeviceGroupsList();
    if (response.status === 200) {
      const groups = response.data ?? [];
      dispatch(
        groupActions.setUpGroupData({
          groups: groups,
        })
      );
      dispatch(
        groupActions.setUpFilteredGroupsData({
          groups: groups,
        })
      );
    }
  };
  useEffect(() => {
    on_load();
    dispatch(
      locationHistoryActions.locationHistoryPage({
        locationPage: "locationHistory",
      })
    )
    loadGroups()
    loadDevices()
    dispatch(locationHistoryActions.resetLocationState());
  }, []);

  const OnTutorialClose = () => {
    setShowTutorial(false);
  };

  return (
    <>
      <React.Fragment>
        <section className="section-home " id="login-page">
          <div className="middle-content d-flex justify-content-between h-100">
            <SideBarNew
              page="LiveStream"
              onClick={(e) => {
                history.push(INDEX_URL + e);
              }}
            />

            <div className="col row section-data ml-5">
              <div className="col-md-4 col-12 section-left_bottom_data bg_primary_mobile">
                <div className={`${downArrowClick === false && firstDownClick ? "animate_hight_down_liveStream_view " :
                  firstDownClick && downArrowClick ?
                    "animate_hight_up_liveStream_view" : "heigh_50_mobile"} `} >
                  <div className="dragbar_toucharea" draggable="true" onTouchStart={() => closeBottomPopupMobile()}>
                    <div className="dragbar" ></div>
                  </div>
                  <div className="row p-0 m-0 border_bottom_mobile">
                    <div className="col-md-12 col-10 home_title_bar">
                      <img src={FlavorImages.livestream} className="home_title_icon d-md-block d-none" alt="..." />
                      <label className="text_mobile_title_bar_livestream font_700_italic_gloria" >{t("LiveStream.Livestream")}</label>
                      <img
                        src={
                          FlavorImages.map_marker_malt_red
                        }
                        className=" home_title_icon d-md-none d-block ml-md-4 ml-2"
                        alt="..."

                      />
                    </div>
                    <div className="col-2 mt-3 d-md-none d-block m-0 p-0">
                      <img
                        onClick={() => closeBottomPopupMobile()}
                        src={FlavorImages.up_arrow}
                        className={`size_30 radius_2 ml-4 d-md-none d-sm-flex padding_6 bg-grey2 	${downArrowClick ? "rotate_btn_maximize" : "rotate_btn_minmize"}`}
                        alt="..."
                      />
                    </div>
                  </div>
                  {devicesState.liveStream && (
                    <ShowLiveStream
                      downArrowClick={() => setDownArrowClick(!downArrowClick)}
                      liveStream={devicesState.liveStream} />
                  )}
                </div>
              </div>

              {
                <div className="col px-0 section-right_data">
                  <Map page="LiveStream" />
                </div>
              }
            </div>

            <LiveStreamTutorial
              show={showTutorial}
              onClose={() => OnTutorialClose()}
            />
          </div>
        </section>
      </React.Fragment>
    </>
  );
};

export default LiveStream;
