import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import close_gray from "../../assets/images/close_gray.svg";
import * as genericService from "../../services/genericService";
import toast, { Toaster } from 'react-hot-toast';
import FlavorImages from "../../assetsFolderImport";


const EditBillingDialog = (props) => {
  const { t } = useTranslation();
  const [emailError, setEmailError] = useState("");
  const [email, setemail] = useState("");
  const [VAT_number, setVAT_number] = useState("");
  const [vatNumberError, setVatNumberError] = useState("");
  const [vatTypeError, setVatTypeError] = useState("");
  const [receiptlanguage, setReceiptlanguage] = useState();
  const [vatType, setVatType]=useState("")
  const setEmailField = (value) => {
    setemail(value);
    setEmailError("");
  };
  const optionsLanguages = [
    {
      label: "Arabic",
      value: "ar",
    },
    {
      label: "Bulgarian",
      value: "bg",
    },
    {
      label: "Chinese (Simplified)",
      value: "zh",
    },
    {
      label: "Chinese (Hong Kong)",
      value: "zh-hk",
    },
    {
      label: "Chinese (Taiwan)",
      value: "zh-tw",
    },
    {
      label: "Croatian",
      value: "hr",
    },
    {
      label: "Filipino",
      value: "fil",
    },
    {
      label: "Czech",
      value: "cs",
    },
    {
      label: "Danish",
      value: "da",
    },
    {
      label: "Dutch",
      value: "nl",
    },
    {
      label: "English (United Kingdom)",
      value: "en-gb",
    },
    {
      label: "English (United States)",
      value: "en-us",
    },
    {
      label: "Estonian",
      value: "et",
    },
    {
      label: "Finnish",
      value: "fi",
    },
    {
      label: "French",
      value: "fr",
    },
    {
      label: "French (Canada)",
      value: "fr-ca",
    },

    {
      label: "German",
      value: "de",
    },
    {
      label: "Greek",
      value: "el",
    },
    {
      label: "Hebrew",
      value: "he",
    },
    {
      label: "Hungarian",
      value: "hu",
    },

    {
      label: "Indonesian",
      value: "id",
    },
    {
      label: "Italian",
      value: "it",
    },
    {
      label: "Japanese",
      value: "ja",
    },
    {
      label: "Korean",
      value: "ko",
    },
    {
      label: "Latvian",
      value: "lv",
    },
    {
      label: "Lithuanian",
      value: "lt",
    },

    {
      label: "Malay",
      value: "ms",
    },
    {
      label: "Maltese",
      value: "mt",
    },
    {
      label: "Polish",
      value: "pl",
    },
    {
      label: "Portuguese",
      value: "pt",
    },
    {
      label: "Romanian",
      value: "ro",
    },
    {
      label: "Russian",
      value: "ru",
    },
    {
      label: "Slovak",
      value: "sk",
    },
    {
      label: "Slovenian",
      value: "sl",
    },
    {
      label: "Spanish",
      value: "es",
    },
    {
      label: "Swedish",
      value: "sv",
    },
    {
      label: "Thai",
      value: "th",
    },
    {
      label: "Turkish",
      value: "tr",
    },
    {
      label: "Vietnamese",
      value: "vi",
    },
    {
      label: "Norwegian Bokmål",
      value: "nb",
    },
  ];

  var vatTypeValue = [
    { label: "", value: "null" },
    { label: "Australia", value: "au_abn" },
    { label: "Austria", value: "eu_vat" },
    { label: "Belgium", value: "eu_vat" },
    { label: "Bulgaria", value: "eu_vat" },
    { label: "Chile", value: "cl_tin" },
    { label: "Croatia", value: "eu_vat" },
    { label: "Cyprus", value: "eu_vat" },
    { label: "CzechRepublic", value: "eu_vat" },
    { label: "Denmark", value: "eu_vat" },
    { label: "Estonia", value: "eu_vat" },
    { label: "Finland ", value: "eu_vat" },
    { label: "France   ", value: "eu_vat" },
    { label: "Georgia   ", value: "ge_vat" },
    { label: "Germany    ", value: "eu_vat" },
    { label: "Greece      ", value: "eu_vat" },
    { label: "HongKong", value: "hk_br" },
    { label: "Hungary  ", value: "eu_vat" },
    { label: "Iceland   ", value: "is_vat" },
    { label: "India      ", value: "in_gst" },
    { label: "Indonesia   ", value: "id_npwp" },
    { label: "Ireland      ", value: "eu_vat"},
    { label: "Israel        ", value: "il_vat"},
    { label: "Italy          ", value: "eu_vat"},
    { label: "Latvia          ", value: "eu_vat"},
    { label: "Liechtenstein    ", value: "li_uid"},
    { label: "Lithuania         ", value: "eu_vat"},
    { label: "Luxembourg         ", value: "eu_vat"},
    { label: "Malta               ", value: "eu_vat"},
    { label: "Mexico               ", value: "mx_rfc"},
    { label: "Netherlands         ", value: "eu_vat"},
    { label: "NewZealand          ", value: "nz_gst"},
    { label: "Norway              ", value: "no_vat"},
    { label: "Poland               ", value: "eu_vat"},
    { label: "Portugal             ", value: "eu_vat"},
    { label: "Romania              ", value: "eu_vat"},
    { label: "SaudiArabia          ", value: "sa_vat"},
    { label: "Slovakia             ", value: "eu_vat"},
    { label: "Slovenia            ", value: "eu_vat"},
    { label: "SouthAfrica         ", value: "za_vat"},
    { label: "SouthKorea          ", value: "kr_brn"},
    { label: "Spain               ", value: "eu_vat"},
    { label: "Sweden              ", value: "eu_vat"},
    { label: "Switzerland         ", value: "ch_vat"},
    { label: "Taiwan              ", value: "tw_vat"},
    { label: "Thailand             ", value: "th_vat"},
    {
      label: "Ukraine"               , value : "ua_vat"},
    {
        label: "UnitedArabEmirates "   , value : "ae_trn"},
    {
          label: "UnitedKingdom   "      , value : "gb_vat"},
    {
            label: "UnitedStates   "       , value : "us_ein"}
  ]
  const resetAllField = () => {
    props.CloseEditBillingDialog()
    setVatNumber(props.billingDetail.vatnumber);
    setReceiptlanguage(props.reciptLanguage);
  }
  const setVatNumber = (value) => {
    setVAT_number(value);
    setVatNumberError("");
  };
  const confrimEdit = async () => {
    var response = false;
    if (props.SlectToedit === "email") {

      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        response = await genericService.post("billing/settingsupdate", {
          email: email,
        });
      }
      else {
        setEmailError(t("errorCode.20020"))
      }

    } else if (props.SlectToedit === "receiptlanguage") {
      response = await genericService.post("billing/settingsupdate", {
        receiptlanguage: receiptlanguage,
      });
    } else {
      if(vatType ==="null")
      {
        setVatTypeError("Please select vat type")
      }
      else{
        const foundCountry = vatTypeValue.find(country => country.label === vatType)
        response = await genericService.post("billing/settingsupdate", {
          vatnumber: VAT_number,
          vattype: foundCountry.value,
        });
      }
    }

    if (response) {
      if (response?.status === 200) {
        props.loadBillingDetail();
        resetAllField()
        toast.success(t("toast.Saved"));
        
      } else {
        if (response.data.errorCode === "20140") {
          setVatNumberError(response.data.message)
        }
        else {
          props.setError(response.data.message)
        }

      }
    }
  };
  useEffect(() => {
    setVatNumber(props.billingDetail.vatnumber);
    setVatType("null")
  }, [props.billingDetail]);
  useEffect(() => {
    setReceiptlanguage(props.reciptLanguage);
  }, [props.reciptLanguage]);
  useEffect(() => {
    setemail(props.email);
  }, [props.email]);
  return (
    <>
      <Dialog maxWidth={"xs"} fullWidth open={props.show}>
        <Card className="card">
          <div
            className="py-3 px-4 d-flex justify-content-between align-items-center card-header bg-white"
            style={{ backgroundColor: "white" }}
          >
            <label className=" mb-0 text-grey text_17_700 ">
              {props.SlectToedit === "vatnumber"
                ? t("Settings.ChangeVatNumber")
                : props.SlectToedit === "receiptlanguage"
                  ? t("Settings.ChangeReceiptLanguage")
                  : t("Settings.EditBillingEmail")}
            </label>
            <img
              className="size_1-5_rem cursor-pointer"
              src={FlavorImages.close_gray}
              onClick={() => resetAllField()}
              alt="..."
            />
          </div>
          <div className="card-body py-3">
            <div className="row">
              <div className="col-12">
                <div className="form-group col-12 mt-1">
                  <label
                    className="col-form-label text_12_500 text-grey"
                    style={{ fontSize: "small" }}
                    data-success="right"
                  >
                    {props.SlectToedit === "vatnumber"
                      ? t("Settings.VATNumber")
                      : props.SlectToedit === "receiptlanguage"
                        ? t("Settings.Language")
                        : t("Settings.EditEmail")}
                  </label>

                  {props.SlectToedit === "receiptlanguage" ? (
                    <select
                      style={{ background: "#DDDDDD", borderRadius: "0.25rem" }}
                      className={`form-control form-control-grey2 cursor-pointer`}
                      value={receiptlanguage}
                      onChange={(event) =>
                        setReceiptlanguage(event.target.value)
                      }
                    >
                      {optionsLanguages.map((option) => (
                        <option className="cursor-pointer" value={option.value}>{option.label}</option>
                      ))}
                    </select>
                  ) : props.SlectToedit === "email" ? (
                    <>
                      <input
                        type="text"
                        style={{ background: "#DDDDDD" }}
                        value={email}
                        onChange={(e) => setEmailField(e.target.value)}
                        className={`form-control 
															${emailError === "" ? "" : "is-invalid"}`}
                      />
                      <div className="invalid-feedback bottom">
                        {emailError === "confirmerror" ? "" : emailError}
                      </div>
                    </>


                  ) : (
                    <>
                      <input
                        type="text"
                        onChange={(e) => setVatNumber(e.target.value)}
                        value={VAT_number}
                        style={{ background: "#DDDDDD" }}
                        className={`form-control 
															${vatNumberError === "" ? "" : "is-invalid"}`}
                      />
                      <div className="invalid-feedback bottom">
                        {vatNumberError === "confirmerror" ? "" : vatNumberError}
                      </div>
                      <label
                        className="col-form-label mt-3 text_12_500 text-grey"
                        style={{ fontSize: "small" }}
                        data-success="right"
                      >
                        {t("Settings.Vattype")}{" "}
                      </label>
                      <select
                        onChange={(event) =>
                          setVatType(event.target.value)
                        }
                        style={{
                          background: "#DDDDDD",
                          borderRadius: "0.25rem",
                        }}
                        value={vatType}
                        className={`form-control form-control-grey2 cursor-pointer`}
                      >
                        {vatTypeValue.map((option) => (
                          <option className="cursor-pointer" value={option.label}>{option.label}</option>
                        ))}
                      </select>
                       <div className="invalid-feedback bottom pt-1">
                        {vatTypeError === "confirmerror" ? "" : vatTypeError}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="m-0 card-footer  d-flex justify-content-end">
            <button
              className="btn bg-transparent btn-sm font-bold text-grey"
              onClick={() => resetAllField()}
              variant="light"
            >
              {t("actionDialog.cancel")}
            </button>

            <button
              className="btn btn_navy bg_dark_helkama text-uppercase ml-3"
              onClick={() => confrimEdit()}
              id="next"
            >
              {t("Device.Save")}
              {/* {props.SlectToedit === "vatnumber"
                ? t("Device.Save")
                : props.SlectToedit === "receiptlanguage"
                  ? t("Settings.ChangeLanguage")
                  : t("Settings.EditEmail")} */}
            </button>
          </div>
        </Card>
      </Dialog>
    </>
  );
};

export default EditBillingDialog;
