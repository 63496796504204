import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BackWardArrow from "../../components/Buttons/BackwardArrow";
// import dots_vertical from "../../assets/images/dots_vertical.svg";
import SwitchRow from "./SwitchRow";
import * as genericService from "../../services/genericService";
import EditBillingDialog from "./EditBillingDialog";
import ErrorDialog from "../LocationHistory/ErrorDialog";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { settingsSlice } from "../../slice/settingsSlice";
import FlavorImages from "../../assetsFolderImport";
const settingsActions = settingsSlice.actions;
const Billing = (props) => {
  const dispatch = useDispatch();
  const [errorDialog, setShowErrorDialog] = useState(false)
  const { t } = useTranslation();
  const [sameForAll, setSameForAll] = useState(false);
  const [billingDetail, setBillingDetail] = useState([]);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [receiptLanguage, setReceiptLanguage] = useState("")
  const [slectToedit, setSlectToedit] = useState("");
  const [langaugevalue, setlangaugevalue] = useState("")
  const optionsLanguages = [
    {
      label: "Arabic",
      value: "ar",
    },
    {
      label: "Bulgarian",
      value: "bg",
    },
    {
      label: "Chinese (Simplified)",
      value: "zh",
    },
    {
      label: "Chinese (Hong Kong)",
      value: "zh-hk",
    },
    {
      label: "Chinese (Taiwan)",
      value: "zh-tw",
    },
    {
      label: "Croatian",
      value: "hr",
    },
    {
      label: "Filipino",
      value: "fil",
    },
    {
      label: "Czech",
      value: "cs",
    },
    {
      label: "Danish",
      value: "da",
    },
    {
      label: "Dutch",
      value: "nl",
    },
    {
      label: "English (United Kingdom)",
      value: "en-gb",
    },
    {
      label: "English (United States)",
      value: "en-us",
    },
    {
      label: "Estonian",
      value: "et",
    },
    {
      label: "Finnish",
      value: "fi",
    },
    {
      label: "French",
      value: "fr",
    },
    {
      label: "French (Canada)",
      value: "fr-ca",
    },
    {
      label: "Georgian",
      value: "ka",
    },
    {
      label: "German",
      value: "de",
    },
    {
      label: "Greek",
      value: "el",
    },
    {
      label: "Hebrew",
      value: "he",
    },
    {
      label: "Hungarian",
      value: "hu",
    },
    {
      label: "Icelandic",
      value: "is",
    },
    {
      label: "Indonesian",
      value: "id",
    },
    {
      label: "Italian",
      value: "it",
    },
    {
      label: "Japanese",
      value: "ja",
    },
    {
      label: "Korean",
      value: "ko",
    },
    {
      label: "Latvian",
      value: "lv",
    },
    {
      label: "Lithuanian",
      value: "lt",
    },
    {
      label: "Macedonian",
      value: "mk",
    },
    {
      label: "Malay",
      value: "ms",
    },
    {
      label: "Maltese",
      value: "mt",
    },
    {
      label: "Polish",
      value: "pl",
    },
    {
      label: "Portuguese",
      value: "pt",
    },
    {
      label: "Romanian",
      value: "ro",
    },
    {
      label: "Russian",
      value: "ru",
    },
    {
      label: "Slovak",
      value: "sk",
    },
    {
      label: "Slovenian",
      value: "sl",
    },
    {
      label: "Spanish",
      value: "es",
    },
    {
      label: "Swedish",
      value: "sv",
    },
    {
      label: "Thai",
      value: "th",
    },
    {
      label: "Tibetan",
      value: "bo",
    },
    {
      label: "Turkish",
      value: "tr",
    },
    {
      label: "Vietnamese",
      value: "vi",
    },
    {
      label: "Norwegian Bokmål",
      value: "nb",
    },
  ];
  const loadBillingDetail = async () => {
    const response = await genericService.get("billing/settings");
    if (response.status === 200) {
      const billingdetail = response.data ?? [];
      setBillingDetail(billingdetail);
      if(billingdetail.receiptlanguage)
      {
        optionsLanguages.forEach((language) => {
          let languagedynamic = billingdetail.receiptlanguage.toLowerCase();
          setlangaugevalue(languagedynamic)
          if (language.value === languagedynamic) {
            setReceiptLanguage(language.label)
          }
        })
      }
      setSameForAll(!billingdetail.autosendtomail);
    }
  };

  const sendEmail = async (value) => {
    const response = await genericService.post("billing/settingsupdate", {
      autosendtomail: !value,
    });
    if (response.status === 200) {
      setSameForAll(value);
    } 
  };
  useEffect(() => {
    loadBillingDetail();
  }, []);
  const setEditVatNumber = (data) => {
    setSlectToedit(data);
    setShowEditDialog(true);
  };
  return (
    <>
      <div
        className={`${props.showUI === true ? "show_ui" : "hide_ui"
          } col-md-8 d-flex  margin_top_77 flex-column  inner`}
      >
        <BackWardArrow show={false}       //if passed false, it will be visible on below md devices, if passed true it will be visible irrespectively
          onClick={() => {
            props.setSelect_null()
            dispatch(settingsActions.setPage({ page: "" }));
          }}
        />
        <label className="ml-1 text-uppercase text_11_300 text-left mt-3">
          {t("Settings.BillingInfo")}
        </label>
        <div className="d-flex flex-column rounded_white">
          <div onClick={() => setEditVatNumber("vatnumber")} className="p-3 cursor-pointer   d-flex bg-white w-100 rounded-top border-bottom justify-content-between align-items-center">
            <label className=" text_16_400 text-navy my-0">
              {t("Settings.VATNumber")}{" "}
            </label>

            <div>
              <label className=" text_12_500 text-navy my-0">
                {billingDetail.vatnumber}{" "}
              </label>
              <img
                onClick={() => setEditVatNumber("vatnumber")}
                src={FlavorImages.dots_vertical}
                className="size_15 ml-2 cursor-pointer"
                alt="..."
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column  rounded_white ">
          <div onClick={() => setEditVatNumber("receiptlanguage")} className="p-3  cursor-pointer  d-flex bg-white w-100  border-bottom justify-content-between align-items-center">
            <label className=" text_16_400 text-navy my-0">
              {t("Settings.ReceiptLanguage")}{" "}
            </label>

            <div>
              <label className=" text_12_500 text-navy my-0">
                {receiptLanguage}{" "}
              </label>
              <img
                onClick={() => setEditVatNumber("receiptlanguage")}
                src={FlavorImages.dots_vertical}
                className="size_15 ml-2 cursor-pointer"
                alt="..."
              />
            </div>
          </div>
        </div>
        {billingDetail.vatnumber &&
        <div className="d-flex flex-column mb-4  rounded_white ">
          <div onClick={() => setEditVatNumber("email")} className="p-3  cursor-pointer  d-flex bg-white w-100 rounded-bottom justify-content-between align-items-center">
            <label className=" text_16_400 text-navy my-0">{t("login.Email")} </label>

            <div>
              <label className=" text_12_500 text-navy my-0">
                {" "}
                {billingDetail.email}{" "}
              </label>
              <img
                onClick={() => setEditVatNumber("email")}
                src={FlavorImages.dots_vertical}
                className="size_15 ml-2 cursor-pointer"
                alt="..."
              />
            </div>
          </div>
        </div> }
        {billingDetail.vatnumber ? (
          <SwitchRow
            title={t("Settings.SendToEmail")}
            switch={!sameForAll}
            SwitchClick={(e) => sendEmail(!e)}
          />
        ) : (
          ""
        )}
      </div>
      <EditBillingDialog
        CloseEditBillingDialog={() => setShowEditDialog(false)}
        loadBillingDetail={() => loadBillingDetail()}
        show={showEditDialog}
        SlectToedit={slectToedit}
        email={billingDetail.email}
        setError={(msg) => setShowErrorDialog(msg)}
        reciptLanguage={langaugevalue}
        billingDetail={billingDetail}
      />
            <ErrorDialog
            showErrorDialog={errorDialog}
            errorMesage={errorDialog}
            // select={"settings"}
            onClose={() => setShowErrorDialog(false)}
          />
    </>
  );
};

export default Billing;
