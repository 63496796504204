import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import { mapSettingsSlice } from "../../slice/mapSettingsSlice";
// import Light_Map from "../../assets/images/Light_Map.svg";
// import Satellite_Map from "../../assets/images/Satellite_Map.svg";
// import Hybrid_Map from "../../assets/images/Hybrid_Map.svg";
// import Terrain_Map from "../../assets/images/Terrain_Map.svg";
// import SettingButton from "../../assets/images/settingButton.svg";
// import Dark_Map from "../../assets/images/Dark_Map.svg"
import FlavorImages from "../../assetsFolderImport";

const mapSettingsActions = mapSettingsSlice.actions;
const MobileMapSettings = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { mapSettingsState } = useSelector(
    (state) => ({ mapSettingsState: state.mapSettings }),
    shallowEqual
  );
  const alterSettings = (name, value) => {
    const data = {};
    data[name] = value;
    dispatch(mapSettingsActions.setSetting(data));
  };

  const { mapType } = useSelector(
    (state) => ({ mapType: state.mapSettings.Maptype }),
    shallowEqual
  );

  const setMapType = (name) => {
    dispatch(mapSettingsActions.setMapType(name))
  }

  return (
    <>
      <div className="settingCard"
        style={{ width: "100%", height: "50%", bottom: 0 }}>
        <div className="border-bottom">
          <div className="row align-right d-flex ml-2 mr-2  " style={{ padding: 6 }}>
            <label className="text-22-700 " style={{ marginTop: ".4rem" }}>
              {t("mapOptions.Map settings")}
            </label>
            <img alt={t("Light")} className="MapCrossbutton" src={FlavorImages.SettingButton} onClick={() => {
              props.onClose()
            }} />
          </div>
        </div>
        <div className="border-bottom">
          <div className="row ml-2 pr-3 flex-nowrap Map_sidebar_new ">

            <div className=" col-xs">
              <div
                onClick={() => setMapType("roadmap")}
                className={`map_settings_icons m-0 well`}
              >
                <div className="map_setting_icons_text">
                  <span className="text_gray_12_500  spanMapCard">{t("Light")}</span>
                </div>
                <div
                  className={`m-0     ${mapType === "roadmap" ? "map_setting_box" : ""
                    }`}
                >
                  <img className="map_settings_icons_img" alt={t("Light")} src={FlavorImages.Light_Map} />
                </div>
              </div>
            </div>


            <div className=" col-xs">
              <div
                onClick={() => setMapType("DarkMode")}
                className={`map_settings_icons m-0 well`}
              >
                <div className="map_setting_icons_text">
                  <span className="text_gray_12_500  spanMapCard">{t("mapOptions.Dark Mode")}</span>
                </div>
                <div
                  className={`m-0     ${mapType === "DarkMode" ? "map_setting_box" : ""
                    }`}
                >
                  <img className="map_settings_icons_img" alt={t("Light")} src={FlavorImages.Dark_Map} />
                </div>
              </div>
            </div>
            <div className=" col-xs">
              <div
                onClick={() => setMapType("satellite")}
                className="map_settings_icons well"
              >
                <div className="map_setting_icons_text">

                  <span className="text_gray_12_500 spanMapCard map_setting_icons_text">{t("Satellite")}
                  </span>
                </div>
                <div
                  className={`m-0     ${mapType === "satellite" ? "map_setting_box " : ""
                    }`}
                >
                  <img className="map_settings_icons_img" alt={t("mapOptions.Show geofence")} src={FlavorImages.Satellite_Map} />
                </div>
              </div>
            </div>
            <div className=" col-xs">
              <div
                onClick={() => setMapType("hybrid")}
                className="map_settings_icons well"
              >
                <div className="map_setting_icons_text">
                  <span className="text_gray_12_500 spanMapCard">{t("Hybrid")}</span>
                </div>
                <div
                  className={`m-0     ${mapType === "hybrid" ? "map_setting_box" : ""
                    }`}
                >
                  <img className="map_settings_icons_img" alt={t("mapOptions.Trace unit")} src={FlavorImages.Hybrid_Map} />
                </div>
              </div>
            </div>


            <div className=" col-xs">
              <div
                onClick={() => setMapType("terrain")}
                className="map_settings_icons well"
              >
                <div className="map_setting_icons_text">
                  <span className="text_gray_12_500 spanMapCard">{t("Terrain")}</span>
                </div>
                <div
                  className={`m-0     ${mapType === "terrain" ? "map_setting_box" : ""
                    }`}
                >
                  <img className="map_settings_icons_img " alt={t("mapOptions.Hide offline units")} src={FlavorImages.Terrain_Map} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row ml-2 pr-3 flex-row flex-nowrap Map_sidebar_new">
          <div className=" col-xs">
            <div
              onClick={() =>
                alterSettings("show_geofence", !mapSettingsState.show_geofence)
              }
              className="map_settings_icons well"
            >
              <div className="map_setting_icons_text">
                <span className="text_gray_12_500 spanMapCard" >{t("mapOptions.Show geofence")}</span>
              </div>
              <img className={`map_settings_icons_img  ${mapSettingsState.show_geofence ? "group_active_helkam" : "map_settings_icons_img group_inactive"}`}
                alt={t("mapOptions.Show geofence")}
                src={mapSettingsState.show_geofence ? FlavorImages.show_geofence_setting_active : FlavorImages.show_geofence_setting_inactive
                }
              />
            </div>
          </div>
          <div className=" col-xs">
            <div
              onClick={() =>
                alterSettings("trace_unit", !mapSettingsState.trace_unit)
              }
              className="map_settings_icons well"
            >
              <div className="map_setting_icons_text">
                <span className="text_gray_12_500 spanMapCard"  >{t("mapOptions.Trace unit")}</span>
              </div>
              <img className={`map_settings_icons_img ${mapSettingsState.trace_unit ? "group_active_helkam" : "map_settings_icons_img group_inactive"}`}
                alt={t("mapOptions.Trace unit")}
                src={mapSettingsState.trace_unit ? FlavorImages.trace_unit_active : FlavorImages.trace_unit_inactive}
              />
            </div>
          </div>
          <div className=" col-xs">
            <div
              onClick={() =>
                alterSettings(
                  "hide_offline_units",
                  !mapSettingsState.hide_offline_units
                )
              }
              className="map_settings_icons well"
            >
              <div className="map_setting_icons_text">
                <span className="text_gray_12_500 spanMapCard" >{t("mapOptions.Hide offline units")}</span>
              </div>
              <img className={`map_settings_icons_img  ${mapSettingsState.hide_offline_units ? "group_active_helkam" : "map_settings_icons_img group_inactive"}`}
                alt={t("mapOptions.Hide offline units")}
                src={mapSettingsState.hide_offline_units ? FlavorImages.offline_units_active : FlavorImages.offline_units_inactive
                }
              />
            </div>
          </div>
          <div className=" col-xs">
            <div
              onClick={() =>
                alterSettings(
                  "cluster_markers",
                  !mapSettingsState.cluster_markers
                )
              }
              className="map_settings_icons well"
            >
              <div className="map_setting_icons_text">
                <span className="text_gray_12_500 spanMapCard ">{t("mapOptions.Cluster markers")}</span>
              </div>
              <img className={`map_settings_icons_img ${mapSettingsState.cluster_markers ? "group_active_helkam" : "map_settings_icons_img group_inactive"}`}
                alt={t("mapOptions.Cluster markers")}
                src={mapSettingsState.cluster_markers ? FlavorImages.cluster_active : FlavorImages.cluster_inactive
                }
              />
            </div>
          </div>
          <div className=" col-xs">
            <div
              onClick={() =>
                alterSettings(
                  "show_device_name",
                  !mapSettingsState.show_device_name
                )
              }
              className="map_settings_icons well"
            >
              <div className="map_setting_icons_text">
                <span className="text_gray_12_500 spanMapCard ">{t("mapOptions.Show device name")}</span>
              </div>
              <img className={`map_settings_icons_img  ${mapSettingsState.show_device_name ? "group_active_helkam" : "map_settings_icons_img group_inactive"}`}
                alt={t("mapOptions.Show device name")}
                src={mapSettingsState.show_device_name ? FlavorImages.in_active_device_name : FlavorImages.active_device_name}
              />
            </div>
          </div>
          <div className=" col-xs">
            <div
              onClick={() =>
                alterSettings("show_alarms", !mapSettingsState.show_alarms)
              }
              className="map_settings_icons well"
            >
              <div className="map_setting_icons_text">
                <span className="text_gray_12_500 spanMapCard ">{t("mapOptions.Show alarms")}</span>
              </div>
              <img className={`map_settings_icons_img  ${mapSettingsState.show_alarms ? "group_active_helkam" : "map_settings_icons_img group_inactive"}`}
                alt={t("mapOptions.Show alarms")}
                src={mapSettingsState.show_alarms ? FlavorImages.bell_inactive : FlavorImages.bell_active}
              />
            </div>
          </div>
          <div className=" col-xs">
            {process.env.REACT_APP_FLAVOR === "Bike"
              ?
              ""
              :
              <div
                onClick={() =>
                  alterSettings("show_groups", !mapSettingsState.show_groups)
                }
                className="map_settings_icons well"
              >
                <div className="map_setting_icons_text">
                  <span className="text_gray_12_500 spanMapCard ">{t("mapOptions.Show groups")}</span>
                </div>
                <img className="map_settings_icons_img"
                  alt={t("mapOptions.Show groups")}
                  src={
                    mapSettingsState.show_groups ? FlavorImages.groups_inactive : FlavorImages.group
                  }
                />
              </div>
            }
          </div>
        </div>
        <div className=" col-xs">
          <div
            onClick={() =>
              alterSettings("show_settings", !mapSettingsState.show_settings)
            }
            className="map_settings_icon_container"
          >
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileMapSettings;
