import { Card } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// import close_gray from "../../assets/images/close_gray.svg";
import { shallowEqual, useSelector } from "react-redux";
// import check from "../../assets/images/check.svg";
import { useDispatch } from "react-redux";
import { alarmsSlice } from "../../slice/alarmsSlice";
import FlavorImages from './../../assetsFolderImport';

const alarmAction = alarmsSlice.actions;
const DeleteActionDialog = (props) => {
  const [localaction, setlocalaction] = useState("");
	const { alarmState } = useSelector(
    (state) => ({ alarmState: state.alarms }),
    shallowEqual
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const Closebox = () => {
		if(props.massShareBtn)
		{
			props.onClose();
			dispatch(
				alarmAction.setShareRemoveAction({
					shareRemoveAction: "",
				})
			);
			setlocalaction("");
			props.resetIsSelection();
		}
		else
		{
			props.onClose();
		}
  };
  const setCheckbar = () => {
		if(props.massShareBtn)
		{
			dispatch(
				alarmAction.setShareRemoveAction({
					shareRemoveAction: localaction,
				})
			);
      // console.log(alarmState.shareRemoveAction);
      if(localaction === "removeEmail"){
        props.setShowRemoveEmail(true)
        Closebox()
        props.onClose(); 
      }else{
        props.setenableDisblaeButton(true);
        props.setIsSelection(true);
        props.onClose(); 
      }
		}
		else
		{
			if (props.select !== "geofence") {
				props.setenableDisblaeButton(true);
			}
			props.setIsSelection();
			props.onClose();
		}

  };
  const resetCheckBar = () => {
		if(props.massShareBtn)
		{
			dispatch(
				alarmAction.setShareRemoveAction({
					shareRemoveAction: localaction,
				})
			);
			props.resetIsSelection();

			props.onClose();
		}
		else
		{
			props.resetIsSelection();
			props.onClose();
		}

  };


  const handleChange = (newaction) => {
    dispatch(
      alarmAction.setAction({
        action: newaction,
      })
    );
  };

const	getSharCardBody=()=>
	{
		return( <>
              <div
                className="row cursor-pointer"
                onClick={() => setlocalaction("shareDevice")}
              >
                <div className="col-6 d-flex justify-content-start cursor-pointer">
                  <label
                    className={`mb-3 mt-3 cursor-pointer ml-3 text_gray_12_500 ${
                      localaction === "shareDevice" ? "" : "text-gray"
                    }`}
                  >
                    {t("Alarm.ShareDeviceAlarm")}
                  </label>
                </div>
                <div className="col-5 d-flex justify-content-end pr-1">
                  <img
                    style={{ height: "15px" }}
                    className={`mb-3 mt-3 mr-3' ${
                      localaction === "shareDevice" ? "" : "set-check"
                    }`}
                    src={FlavorImages.check}
                    alt="..."
                  />
                </div>
              </div>
              <div
                className="row border-top cursor-pointer"
                onClick={() => setlocalaction("removeEmail")}
              >
                <div className="col-6 d-flex cursor-pointer justify-content-start">
                  <label
                    className={`mt-3 mb-3 ml-3 cursor-pointer text_gray_12_500 ${
                      localaction === "removeEmail" ? "" : "text-gray"
                    }`}
                  >
                    {t("Alarm.Removeemial")}
                  </label>
                </div>
                <div className="col-5 d-flex justify-content-end pr-1">
                  <img
                    style={{ height: "15px" }}
                    className={`mt-3 mb-3 mr-3' ${
                      localaction === "removeEmail" ? "" : "set-check"
                    }`}
                    src={FlavorImages.check}
                    alt="..."
                  />
                </div>
              </div>
							</>
		)
	}
  const renderBlock = () => {
    return (
      <div className="row" onClick={() => handleChange(!alarmState.action)}>
        <div className="col-6 d-flex justify-content-start">
          <label
            className={`my-3 ml-2 cursor-pointer card-title' ${
              alarmState.action ? "" : "text-gray"
            }`}
            style={{ fontSize: "15px" }}
          >
            {props.select === "geofence"
              ? t("Geofences.Deletegeofences")
              : t("myAlarm.DeleteAlarm")}
            {}
          </label>
        </div>
        <div className="col-6 d-flex justify-content-end">
          <img
            style={{ height: "15px" }}
            className={`my-2 mr-3 align-self-center ${alarmState.action ? "" : "set-check"}`}
            src={FlavorImages.check}
            alt="..."
          />
        </div>
      </div>
    );
  };

  return (
    <>
   	  <div
        id="collapsetwo"
        className="collapse"
        data-parent="#accordiontwo"
      >
				 <Card className="card" style={{ width: "335px" }}>
          <div className="text-center">
            <div className="dialog_top_sort_row py-0 card-header bg-white">
              <label className="text_17_700 ">
                {" "}
                {t("actionDialog.actions")}
              </label>
              <img
                data-toggle="collapse"
                data-target="#collapsetwo"
                aria-expanded="true"
                aria-controls="collapsetwo"
                className="cursor-pointer"
                src={FlavorImages.close_gray}
                onClick={() => Closebox()}
                alt="..."
              />
            </div>
						{props.massShareBtn ?   <div className="card-body py-0">{getSharCardBody()}</div>
						:
            <div className="card-body py-0">{renderBlock()}</div>
						}
          </div>

          <div className="m-0 card-footer d-flex justify-content-end">
            <button
              className="btn bg-transparent btn-sm font-bold text-grey text_12_700"
              data-toggle="collapse"
              data-target="#collapsetwo"
              aria-expanded="true"
              aria-controls="collapsetwo"
              onClick={() => Closebox()}
              variant="light"
            >
              {t("actionDialog.cancel")}
            </button>
            {alarmState.action || localaction !== ""  ? (
              <button
                className="btn btn_navy bg_dark_helkama py-0 px-4 text-white ml-3"
                data-toggle="collapse"
                data-target="#collapsetwo"
                aria-expanded="true"
                aria-controls="collapsetwo"
                onClick={() => setCheckbar()}
              >
                {t("actionDialog.select")}
              </button>
            ) : (
              <button
                className="btn btn_navy bg_dark_helkama py-0 px-4 text-white ml-3"
                data-toggle="collapse"
                data-target="#collapsetwo"
                aria-expanded="true"
                aria-controls="collapsetwo"
                onClick={() => resetCheckBar()}
              >
                {t("actionDialog.select")}
              </button>
            )}
          </div>
        </Card>

      </div>
    </>
  );
};
export default DeleteActionDialog;
