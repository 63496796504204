import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// import dots_vertical from "../../assets/images/FlavorImages.dots_vertical.svg";
// import forward_arrow_grey from "../../assets/images/forward_arrow_grey.svg";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
// import pic_default from "../../assets/images/pic_default.svg";
// import pen from "../../assets/Helkama_images/pen.svg";
import * as genericService from "../../services/genericService";
import { authSlice } from "../../slice/authSlice";
import EditName from "./EditName";
import helper_functions from "../../helpers/helper_functions";
import EditProfilepic from "./EditProfilepic";
// import backward_arrow_grey from "../../assets/images/backward_arrow_grey.svg";
import BackWardArrow from "../../components/Buttons/BackwardArrow";
import ErrorDialog from "../LocationHistory/ErrorDialog";
import EditGenderPopup from "./EditGenderPopup";
import { settingsSlice } from "../../slice/settingsSlice";
import toast, { Toaster } from 'react-hot-toast';
import FlavorImages from "../../assetsFolderImport";

const settingsActions = settingsSlice.actions;
const EditAccount = (props) => {
  const [editcheck, seteditcheck] = useState('')
  const [showEditProfilePic, setShowEditProfilePic] = useState(false)
  const [errorDialog, setShowErrorDialog] = useState(false)
  const [error, setError] = useState("");
  const [profilePicture, setProfilePicture] = useState('')
  const authAction = authSlice.actions;
  const [showeditpopup, setshoweditpopup] = useState(false)
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { authState } = useSelector(
    (state) => ({ authState: state.auth }),
    shallowEqual
  );
  useEffect(() => {
    if(process.env.REACT_APP_FLAVOR === "Bike"){
      setProfilePicture(authState.authData.response.userimage)
    }
  }
    , []);
  const [showGemderPopup, setshowGenderPopup] = useState(false)
  const segments = [
    {
      value: "male", label: t("Gender.male")
    },
    {
      value: "female", label: t("Gender.female")
    },
    {
      value: "other", label: t("Gender.other")
    },
  ];
  const loadProfileSetting = async () => {
    const response = await genericService.get("account/info");
    if (response.status === 200) {
      var info = response.data;
      toast.success(t("toast.Saved"));
      dispatch(
        authAction.setAuthData({
          authData: info,
        })
      );
    } else {
      toast.success(t("toast.Error"));

    }
  };


  const confirmEdit = async (value, check) => {
    let data = {
      email: authState.authData.response.email,
      username: authState.authData.response.username,
    }
    if (check === 'weight') {
      data['weight'] = parseInt(value)
    }
    else if (check == 'editage') {
      data['birthday'] = helper_functions.formatDatedashFormate(value)
    }
    else {
      data['gender'] = value
    }
    let response = await genericService.post('account/update', { ...data });
    if (response.status == 200 || response.status == 201) {
      loadProfileSetting()
      if (check === 'weight' || check == 'editage') {
        setshoweditpopup(false)
      }
      else {
        setshowGenderPopup(false)

      }
      seteditcheck('')
    }
  }

  return (
    <>
      <div
        className={`${props.showUI === true ? "show_ui" : "hide_ui"
          } col-md-8 d-flex  mt-3 flex-column  inner`}
      >
      <BackWardArrow show={false}
        onClick={() => {
          props.setSelect_null()
          dispatch(settingsActions.setPage({ page: "" }));
        }}
      />
        <div className={process.env.REACT_APP_FLAVOR === "Bike" ? "row mt-3 justify-content-center":"d-none"}>
          {/* {console.log(authState.authData)} */}
          {process.env.REACT_APP_FLAVOR === "Bike" &&
            <img
            className="size_75 fit_cover_image mt-4 cursor-pointer align-self-center border_36"
            src={(authState.authData.response.userimage && !authState.authData.response.userimage.includes("www.swetrack.com/dhhddh3333333.png")) ? authState.authData.response.userimage : FlavorImages.pic_default}
            alt={"...No content"}
          />
          }

          <div className="img_edit_pic cursor-pointer">
            <img
              onClick={() => setShowEditProfilePic(true)}
              className="size_13_px"
              src={FlavorImages.pen}
              alt={"...No content"}
            />
          </div>
        </div>
        <label className="text-uppercase text_11_300 text-left mt-2">
          {t("Settings.MyAccount")}
        </label>
        <div className="d-flex flex-column px-0 rounded_white ">
          <div onClick={() => props.showEditDialog()} className="p-3 cursor-pointer  bg-white  d-flex  border-bottom rounded-top justify-content-between align-items-center">
            <label className=" text_16_400 text-navy my-0">
              {t("signup.Name")}{" "}
            </label>

            <div>
              <label className=" text_12_500 text-navy my-0">
                {process.env.REACT_APP_FLAVOR === "Bike" ? authState.authData.response.username : authState.authData.username}{" "}
                
              </label>
              <img
                onClick={() => {
                  setshoweditpopup(true)
                  seteditcheck('name')
                }}
                src={FlavorImages.dots_vertical}
                className="size_15 ml-2"
                alt="..."
              />
            </div>
          </div>

          <div
            className="p-3 cursor-pointer   d-flex  border-bottom justify-content-between align-items-center"
            style={{ background: "#ffffff", width: "100%" }}
            onClick={() => props.showEditEmailDialog()}
          >
            <label className=" text_16_400 text-navy my-0">
              {t("login.Email")}{" "}
            </label>

            <div>
              <label className=" text_12_500 text-navy my-0">
                {process.env.REACT_APP_FLAVOR === "Bike" ? authState.authData.response.email : authState.authData.email}
              </label>
              <img
                onClick={() => props.showEditEmailDialog()}
                src={FlavorImages.dots_vertical}
                className="size_15 ml-2"
                alt="..."
              />
            </div>
          </div>

          <div
            className="p-3   cursor-pointer d-flex  border-bottom justify-content-between align-items-center"
            style={{ background: "#ffffff", width: "100%" }}
            onClick={() => props.showtEditPasswordDialog()}
          >
            <label className=" text_16_400 text-navy my-0">
              {t("login.Password")}{" "}
            </label>

            <div>
              <label className=" text_12_500 text-navy my-0">
                {t("*********")}{" "}
              </label>
              <img
                onClick={() => props.showtEditPasswordDialog()}
                src={FlavorImages.dots_vertical}
                className="size_15 ml-2"
                alt="..."
              />
            </div>
          </div>

          {process.env.REACT_APP_FLAVOR === "Bike" ? (
            <div>
              <div
                className="p-3    d-flex  border-bottom justify-content-between align-items-center"
                style={{ background: "#ffffff", width: "100%" }}
              >
                <label className=" text_16_400 text-navy my-0">
                  {t("signup.Gender")}{" "}
                </label>

                <div>
                  <label className=" text_12_500 text-navy my-0">
                  {authState.authData.response.gender ? t(`Gender.${authState.authData.response.gender}`) : null}
                  </label>
                  <img
                    onClick={() => {
                      setshowGenderPopup(true)
                      seteditcheck('editGender')
                    }
                    }
                    src={FlavorImages.dots_vertical} className="size_15 ml-2 cursor-pointer" alt="..." />
                </div>
              </div>

              <div
                className="p-3    d-flex  border-bottom justify-content-between align-items-center"
                style={{ background: "#ffffff", width: "100%" }}
              >
                <label className=" text_16_400 text-navy my-0">
                  {t("signup.Age")}{" "}
                </label>
                <div>
                  <label className=" text_12_500 text-navy my-0">
                    {authState.authData.response.birthday === null 
                    ? 
                    ""
                    :
                    helper_functions.formatIsoDateSlashFormate(authState.authData.response.birthday)
                    }
                    { }
                  </label>
                  <img
                    onClick={() => {
                      setshoweditpopup(true)
                      seteditcheck('editage')
                    }}
                    src={FlavorImages.dots_vertical} className="size_15 cursor-pointer ml-2" alt="..." />
                </div>
              </div>

              <div
                className="p-3    d-flex  border-bottom justify-content-between align-items-center"
                style={{ background: "#ffffff", width: "100%" }}
              >
                <label className=" text_16_400  text-navy my-0">
                  {t("signup.Weight")}{" "}
                </label>

                <div>
                  <label className=" text_12_500 text-navy my-0">
                    {authState.authData.response.weight}
                    {authState.authData.response.weightmeasurements}
                  </label>
                  <img
                    onClick={() => {
                      setshoweditpopup(true)
                      seteditcheck('weight')
                    }}
                    src={FlavorImages.dots_vertical} className="size_15 cursor-pointer ml-2" alt="..." />
                </div>
              </div>
            </div>
          ) : null}

          <div
            className="p-3 cursor-pointer   d-flex  rounded-bottom justify-content-between align-items-center"
            style={{ background: "#ffffff", width: "100%" }}
            onClick={() => props.selectDelete()}
          >
            <label className=" text_16_400 text-red my-0">
              {t("Settings.DeleteAccount")}{" "}
            </label>

            <img
              onClick={() => props.selectDelete()}
              src={FlavorImages.forward_arrow_grey}
              className="size_15"
              alt="..."
            />
          </div>
        </div>
      </div>
      <ErrorDialog
        showErrorDialog={errorDialog}
        errorMesage={errorDialog}
        onClose={() => setShowErrorDialog(false)}
      />
      <EditName
        check={editcheck}
        closeEditNameDialog={() => setshoweditpopup(false)}
        loadProfileSetting={() => loadProfileSetting()}
        setError={setError}
        show={showeditpopup}
        onConfirm={(value) => confirmEdit(value, editcheck)}
      />
      {process.env.REACT_APP_FLAVOR === "Bike" 
      ? 
      <>
        <EditGenderPopup
        show={showGemderPopup}
        items={segments}
        onConfirm={(value) => confirmEdit(value, editcheck)}
        selectValue={authState.authData.response.gender}
        onclose={() => setshowGenderPopup(false)}
        />
              <EditProfilepic
        show={showEditProfilePic}
        closeprofilePicEdit={() => setShowEditProfilePic(false)}
        loadProfileSetting={() => loadProfileSetting()}
        setError={setError}
        authData={authState.authData}
        picture={authState.authData.response?.userimage}
      />
      </>
      : 
        null
      }



    </>
  );
};

export default EditAccount;
