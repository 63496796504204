import React from "react";
// import Download_icon from "../../assets/images/Download_icon.svg";
import FlavorImages from "../../assetsFolderImport";
const DownloadButton = (props) => {
  return (
    <React.Fragment>
      <div
        className="download_button cursor-pointer"
        style={{ backgroundColor: "#132439" }}
        onClick={() => props.onClick()}
      >
        <div className="row d-flex justify-content-center" >
          <div className="col "   >
            <div className="row ">
              <div style={{ marginLeft: "15px" }}>
                <img src={FlavorImages.Download_icon} style={{ width: "15px", height: "15px" }} className="add_button_icon" alt="..." />
              </div>
            </div>
          </div>
        </div>
        <div>
          <label className="cursor-pointer" style={{ color: "white", marginLeft: "20px" }}>
            {props.title}
          </label>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DownloadButton;
