import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BackWardArrow from "../../components/Buttons/BackwardArrow";
// import check from "../../assets/images/check.svg";
// import forward from "../../assets/images/forward_arrow_grey.svg";
import RowIcon from "../../components/RowIcon/RowIcon";
import SwitchRow from "./SwitchRow";
import * as genericService from "../../services/genericService";
import FlavorImages from "../../assetsFolderImport";

const Socials = (props) => {
    const { t } = useTranslation();
  const [socialSettigns, setSocialSettings] = useState("");
  const [optOutOfSocialFeatures, setOptOutOfSocialFeatures] = useState(true);

  const getSocialsInfo = async () => {
    const response = await genericService.get("account/info");
    const socialStatus = response.data.response.socialstatus;

    if (response.status === 200) {
      setOptOutOfSocialFeatures(socialStatus === "hide");
      setSocialSettings(socialStatus);
        }
  };

  const updateSocialSettings = async (settings) => {
    const response = await genericService.post("social/settingsupdate", {
      status: settings || "all",
    });

    if (response.status === 200) {
      setSocialSettings(settings);
    }
  };

    useEffect(() => {
    getSocialsInfo();
  }, []);

    return (
        <>
            <div
                className={`${props.showUI === true ? "show_ui" : "hide_ui"
                    } col-md-8 d-flex  mt-3 flex-column  inner`}
            >
                <BackWardArrow
                    onClick={() => props.setSelect_null()}
                />
                <label className=" mt-4rm text_28_700 text-left ">
                    {t("Settings.Socialvisibility")}
                </label>
                <label className=" m-0 text_11_300 text-uppercase text-left mt-2">
                    {t("DeleteShareBike.titleOne")}
                </label>

                <div className="bg-white mt-2 alarm_list_shadow m-0 col-12">
                    <label className="text_13_400 text-left my-2 py-2">
                        {t("Settings.SocialWarning")}
                    </label>
                </div>
                <label className=" m-0 pt-2 text-uppercase text_11_300 text-left mt-2">
                    {t("Settings.Hideme")}
                </label>

                <SwitchRow
                    title={t("Settings.OptOutOfSocialFeatures")}
          switch={optOutOfSocialFeatures}
          SwitchClick={(e) => {
            updateSocialSettings(e ? "hide" : "all");
            setOptOutOfSocialFeatures(e);
          }}
                />

        {!optOutOfSocialFeatures && (
          <>
                <label className="m-0 pt-2 text-uppercase text_11_300 text-left my-2">
                    {t("Settings.CustomizeVisibility")}
                </label>
                <div className="alarm_list_shadow rounded">
                <RowIcon
                    show={socialSettigns === "all"}
                    onclick={() => updateSocialSettings("all")}
                    border={"rowicon_borderup"}
                    label={"Settings.ShowForAll"}
                    src={FlavorImages.check}
                />
                <RowIcon
                    show={socialSettigns === "friends"}
                    onclick={() => updateSocialSettings("friends")}
                    label={"Settings.OnlyMyFriends"}
                    border={"rowicon_borderdown"}
                    src={FlavorImages.check}
                />
                </div>
          </>
        )}
            </div>
        </>
    );
};
export default Socials;