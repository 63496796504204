import React from "react";
import BackWardArrow from "../../components/Buttons/BackwardArrow";
const DataPlanDialog = (props) => {
  return (
    <>
      <div
        className={`${props.showUI === true ? "show_ui" : "hide_ui"
          } col-md-8 ml-2 d-flex  mt-5 flex-column  inner`}
      >
        <BackWardArrow
          show={true}
          onClick={() => props.selectGoBack()}
          select={props.select}
        />
        <label className="ml-1 text-uppercase text_11_300 text-left mt-2">
          {"IMPORTANT INFORMATION"}
        </label>

        <div className="d-flex flex-column  rounded_white ">
          <div
            className="p-3 rounded d-flex border-bottom justify-content-between align-items-center"
            style={{ background: "#ffffff", width: "100%" }}
          >
            <p className="text_13_400">
              You still have an active data plan for this bike. You can’t remove
              the bike until you cancel your data plan. You cancel the data plan
              by going to the web application.{" "}
            </p>
            <div></div>
          </div>
        </div>

        <label className="ml-1 text-uppercase text_11_300 text-left mt-4">
          {"Confirm"}
        </label>

        <div className="d-flex cursor-pointer flex-column  rounded_white ">
          <div
            onClick={() => props.selectGoBack()}
            className="p-3 rounded cursor-pointer d-flex border-bottom justify-content-center align-items-center"
            style={{ background: "#ffffff", width: "100%" }}
          >
            <label className="cursor-pointer text_15_700 text-navy my-0">{"Go Back"} </label>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataPlanDialog;
