/*
 * @Author: SweTrack
 */
import axios from "axios";
import { API_URL } from "../../helpers/constants.js";
import * as genericService from "../../services/genericService";
import helper_functions from "../../helpers/helper_functions";



export async function updateTokenLang(lang) {
  const url = `${API_URL}notification/updatelanguages`;
  const headers = {
    "Content-Type": "application/json",
    "cache-control": "no-cache",
  };
  let formData = {
     languages: lang
  };
  return axios({
    url: url,
    method: "POST",
    data: formData,
    config: { headers: headers },
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}


export async function login(Email, Password, language) {
  const url = `${API_URL}login/signin`;
  const headers = {
    "Content-Type": "application/json",
    "cache-control": "no-cache",
  };
  let formData = {
     email: Email,
     password: Password ,
     language : language
  };
  return axios({
    url: url,
    method: "POST",
    data: formData,
    config: { headers: headers },
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export async function ForgotPassword(Email,language) {
  const url = `${API_URL}forgotpassword/sendresetlink`;
  const headers = {
    "Content-Type": "application/json",
    "cache-control": "no-cache",
  };
  let formData = { email: Email, language: language };
  return axios({
    url: url,
    method: "POST",
    data: formData,
    config: { headers: headers },
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export async function CreateNewPassword(
  email,
  token,
  newpassword,
  newpassword2,
  language
) {
  const url = `${API_URL}forgotpassword/newpassword`;
  const headers = {
    "Content-Type": "application/json",
    "cache-control": "no-cache",
  };
  let formData = { email, token, newpassword, newpassword2, language };
  return axios({
    url: url,
    method: "POST",
    data: formData,
    config: { headers: headers },
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

//There�s some general API issues with the livestream. (1) Sometimes the APIs are run twice. (2) updatephonetoken is run on a computer, this should only be applied on phones. (3) Sometimes the APIs don�t run. Especially /streaminglive/list. Could you please review this.  
// export const uploadFirebseToken = async (currentToken) => {
//   let token = await helper_functions.getLocalStorageValue("token");

//   if (token) {
//     const data = {
//       insert: true,
//       phonetoken: currentToken,
//     };

//     const response = await genericService.post(
//       "notification/updatephonetoken",
//       data
//     );

//     if (response.status === 200 && response.data.successful) {
//       //refresh devices
//     }
//   }
// };
