import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// import check_empty from "../../assets/images/check_empty.svg";
// import checked from "../../assets/images/checked.svg";
// import gray_cross from "../../assets/images/gray_cross.svg";
import ConfirmDelete from "./ConfirmDelete";
import * as genericService from "../../services/genericService";
import FlavorImages from "../../assetsFolderImport";

const AlarmSharedRow = (props) => {
  // console.log("props in alarm shared row",props.sharedAlarm)
  const { t } = useTranslation();
  const [showActionDialog, setShowActionDialog] = useState(false);
  const confirmDeleteEmails = async () => {
    let emailArray=[]
    props.sharedWith.forEach(emailObj => {
      emailArray.push(emailObj.email)
    });
    const response = await genericService.post("events/share/delete", {
      deviceid: [props.alarmId],
      email: emailArray,
    });
    if (response.status === 200) {
      props.loadAlarms();
      setShowActionDialog(false);
    } else {
    }
  };
  const removeEmail = async (email, deviceid) => {
    const response = await genericService.post("events/share/delete", {
      deviceid: [deviceid],
      email: [email],
    });
    if (response.status === 200) {
      props.loadAlarms();
    } else {
    }
  };
  // console.log(props.sharedAlarm);
  const AlarmRow = () => {
    return props.sharedAlarm && props.sharedAlarm.map((alarms, index) => {
      return (
        <>
          <tr
            className={`${
              props.alarmId === alarms.deviceid && !props.isSelection ? "table-not-striped" : ""
            }`}
          >
            <td colSpan={7} style={{ padding: "0px" }}>
              <table className="p-0 m-0 mt-2" style={{ width: "100%" }}>
                <tr
                  className="bg-transparent p-0"
                  onClick={() =>
                    props.Toggle(
                      index,
                      alarms.deviceid,
                      alarms.shared.sharedwith
                    )
                  }
                >
                  {props.isSelection ? (
                    <td className="border-0 col-1 pt-0">
                      {alarms.isSelected || props.sharedAlarm.isSelected ? (
                        <img
                          className="ml-1"
                          style={{ width: "20px", height: "20px" }}
                          src={FlavorImages.checked}
                          alt="checked"
                          onClick={() => props.setChooseAll(false)}
                        />
                      ) : (
                        <img
                          className="ml-1"
                          style={{ width: "20px", height: "20px" }}
                          src={FlavorImages.check_empty}
                          alt="unchecked"
                        />
                      )}
                    </td>
                  ) : null}

                  <td className="border-0 col-4 pl-3">
                    <label
                      className={`${
                        props.alarmId === alarms.deviceid && !props.isSelection
                          ? "device_row_table_white_data"
                          : "device_row_table_data"
                      }`}
                    >
                      {alarms.devicename}
                    </label>
                  </td>

                  <td className="border-0 pl-0 col-4">
                    <label
                      className={`ml-0  pr-2 ${
                        props.alarmId === alarms.deviceid && !props.isSelection
                          ? "device_row_table_white_data"
                          : "device_row_table_data"
                      }`}
                    >
                      {alarms.uniqueid}
                    </label>
                  </td>
                  <td className="border-0 pl-0 col-2">
                    <label
                      className={`ml-0 mr-5 ${
                        props.alarmId === alarms.deviceid && !props.isSelection
                          ? "device_row_table_white_data"
                          : "device_row_table_data"
                      }`}
                    >
                       {alarms.shared.shared ? t("Device.Yes") : t("Device.No")}
                    </label>
                  </td>
                  <td className="border-0 pl-0 col-2">
                    <label
                      className={`ml-0  ${
                        props.alarmId === alarms.deviceid && !props.isSelection
                          ? "device_row_table_white_data"
                          : "device_row_table_data"
                      }`}
                    >
                      {alarms.shared.amount}
                    </label>
                  </td>
                </tr>
                <tr
                  className={` ${
                    props.alarmId === alarms.deviceid && !props.isSelection ? "" : "set-check"
                  }`}
                >
                  <td className="p-0 bg-white border-0" colSpan="10">
                    {alarms.shared.sharedwith.length ?
                    <div className="row">
                      <div className="col-10 offset-1">
                        <div className="row">
                          <div className="col-10 offset-1">
                            <label
                              className="text-black1 font-weight-bold text_10_400 mt-3"
                              data-success="right"
                            >
                              {t("sharedevice.titleTwo")}
                            </label>
                          </div>
                        </div>

                        <div className="row mt-2 align-items-center mt-2">
                          <div className="col-10 offset-1">
                            <div className="email_row_border">
                              {alarms.shared.sharedwith.map(
                                (sharedwith, index) => {
                                  return (
                                    <>
                                      <div className="px-3 py-3 d-flex inset_shadow justify-content-between align-items-center">
                                        <label
                                          className="mb-0 text_12_500 "
                                        >
                                          {sharedwith.email}{" "}
                                        </label>
                                        <div className="d-flex justify-content-end">
                                          <img
                                            onClick={() =>
                                              removeEmail(
                                                sharedwith.email,
                                                alarms.deviceid
                                              )
                                            }
                                            className="mr-2 remove_img"
                                            style={{
                                              width: "20px",
                                              height: "20px",
                                            }}
                                            src={FlavorImages.gray_cross}
                                            alt="unchecked"
                                          />
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-10 offset-1">
                            <button
                              style={{ float: "right" }}
                              onClick={() => setShowActionDialog(true)}
                              className="btn text_11_700  bg-danger btn-sm text-white py-2 text-uppercase ml-3-sm btn-danger border-0 mt-3 mb-4 "
                            >
                              {t("AlarmSharedRow.RemoveEmail")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> : 
                     <div className="row">
                     <div className="col-10 offset-1">
                       <div className="row text-center">
                         <div className="col-10 offset-1">
                           <label
                             className="text-grey text_16_700 my-4"
                             data-success="right"
                           >
                             {t("Alarm.noemailFound")}
                           </label>
                         </div>
                       </div>
                       </div>
                       </div>
                    }
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </>
      );
    });
  };

  return (
    <React.Fragment>
      <div style={{ backgroundColor: "white" }}>
        <table className="table table-striped p-0 mb-0">
          <thead style={{ backgroundColor: "white" }}>
            <tr>
              {props.isSelection ? (
                <th scope="col" className="border-0 col-1" width={"8%"}></th>
              ) : (
                ""
              )}
              <th scope="col" className="border-0 col-4 pl-3">
                <strong className="data_plane_text p-0">
                  {t("signup.Name")}
                </strong>
              </th>

              <th
                className="border-0 col-4 pl-0" scope="col">
                <strong className="data_plane_text p-0">
                  {t("AlarmSharedRow.IMEI")}
                </strong>
              </th>

              <th className="border-0 col-2 pl-0" scope="col">
                <strong className="data_plane_text p-0">
                  {t("Alarm.Shared")}
                </strong>
              </th>

              <th scope="col" className="border-0 col-2 pl-0">
                <strong className="data_plane_text p-0">
                  {t("AlarmSharedRow.amount")}
                </strong>
              </th>
            </tr>
          </thead>

          <tbody>{AlarmRow()}</tbody>
        </table>
      </div>
      <ConfirmDelete
        show={showActionDialog}
        closeConfirmDeleteDialog={() => setShowActionDialog(false)}
        onConfirm={() => confirmDeleteEmails()}
        select={"RemoveEmail"}
      />
    </React.Fragment>
  );
};

export default AlarmSharedRow;
