import Dialog from "@mui/material/Dialog";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import lottie from "lottie-web/build/player/lottie_light";
import holo_gray from "../../assets/images/holo_gray.svg";
import axios from "axios";

const AchievementPopup = (props) => {
    //console.log('***********');
    //console.log('props.achievementType', props.achievementType);
    //console.log('***********')
    const anime = useRef(null);
    const animationContainer = useRef(null);
    const { t } = useTranslation();
    const getNamePart = (name, part) => {
        let myString = name;
        if (name) {
            let [firstPartName, secondPartName] = myString?.split(":");
            if (part === "firstpart") return firstPartName
            return secondPartName
        }
    }
    useEffect(() => {
        let medalValue;
        if (props.achievementType.leveltype) {
            switch (props.achievementType.leveltype) {
                case 'bronze':
                    medalValue = "Helkama_Bronze"
                    break;
                case 'silver':
                    medalValue = "Helkama_Silver"
                    break;
                case 'gold':
                    medalValue = "Helkama_Gold"
                    break;
                case 'diamond':
                    medalValue = "Helkama_diamond"
                    break;
                default:
                    break
            }
            switch (props.achievementType.type) {
                case 'kcal':
                    medalValue = `${medalValue}CaloriesSession_Animate`
                    break;
                case 'distance':
                    medalValue = `${medalValue}DistanceSession_Animate`
                    break;
                case 'time':
                    medalValue = `${medalValue}TimeSession_Animate`
                    break;
                case 'speed':
                    medalValue = `${medalValue}TimeSession_Animate`
                    break;
                default:
                    break
            }

            let animationData
            if (process.env.REACT_APP_FLAVOR == 'Bike') {
                animationData = require(`./../../Lottie Animations/${process.env.REACT_APP_FLAVOR}/Helkama_SilverCaloriesSession_Animate.json`)
            }
            setTimeout(() => {
                setLevelUpanimation(animationData)
            }, 50);
        }
        else {

        }
    }
        , [props.show]);
    const setLevelUpanimation = async (animationData) => {
        if (animationContainer.current) {
            anime.current = lottie.loadAnimation({
                container: animationContainer.current,
                renderer: "svg",
                loop: true,
                autoplay: true,
                animationData: animationData,
            });
        }
    }
    return (
        <>
            <Dialog open={props.show}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        width: "350px",
                        overflow: 'hidden'
                    },
                }}
            >
                <div className="d-flex flex-column bg-transparent justify-content-center align-items-center">
                    <label className="text-white text_22_700">{props.achievementType?.achievementdetail?.titletext} </label>
                    {props.achievementType.leveltype ?
                        <>                            <div
                            className=" size_150 "
                            ref={animationContainer}></div>
                            <label className="text-white text_17_700 mt-2 text-center">{props.achievementType.leveltype}<br />
                                {props.achievementType?.achievementdetail?.achievedgoal} <br /><span className="text_12_500 text-white ">{ } </span>  </label>
                        </>
                        :
                        <img
                            src={
                                holo_gray
                            }
                            className="size_100 my-5"
                            alt="..."
                        />
                    }
                    <label className="text-white text_12_400 mt-2 text-center">{t("Medal.Toearn")}{getNamePart(props.achievementType?.achievementdetail?.nextgoal, 'firstpart')}{":"} <br />
                        <span className="text_12_700"> {getNamePart(props.achievementType?.achievementdetail?.nextgoal, 'secondpart')}</span>
                        {" "}<br /> {t("Medal.Currentbest")}: {!props.achievementType?.achievementdetail?.currentbest ? "0" : props.achievementType?.achievementdetail?.currentbest.toFixed(0)}  {props.achievementType?.achievementdetail?.type === "distance" ? t("LocationHistory.km") : props.achievementType?.achievementdetail?.type == "kcal" ? "kcal" : ""}</label>
                    <button
                        onClick={() => props.closeachievementPopup(false)}
                        className="btn btn-sm bg-dark text-white mt-2 text_15_700 px-3 border_radius_15 py-1">Cancel</button>
                </div>
            </Dialog>
        </>
    );
};

export default AchievementPopup;
