import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import * as genericService from "../../services/genericService";
import ErrorDialog from "../LocationHistory/ErrorDialog";
const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const AddCard = (props) => {
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [processing, setProcessing] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const on_load = () => { };
  useEffect(() => {
    on_load();
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    const tokenResponse = await stripe.createToken(cardElement);
    if (tokenResponse.hasOwnProperty("error")) {
      setError(tokenResponse.error.message);
      return;
    }
    if (processing === "addCard") {
      return;
    }
    let { token } = tokenResponse;
    setProcessing("addCard");
		const paymentMethod = await stripe.createPaymentMethod({
			type: 'card',
			card: cardElement
		})
    let cardToken = token.id;
    let response = await genericService.post("dataplan/cards/create", {
      cardtoken: cardToken,
      payment_method: paymentMethod
    });
    if (response.status === 200) {
      const setupIntentResponse = await stripe.retrieveSetupIntent(response.data.client_secret)
      if(setupIntentResponse.hasOwnProperty("error")) {
        setError(setupIntentResponse.error.message);
        return;
      }else{
        const confirmCardResponse = await stripe.confirmCardSetup(setupIntentResponse.setupIntent.client_secret)
        if(confirmCardResponse.hasOwnProperty("error")){
          setError(confirmCardResponse.error.message);
          return;
        }else if(confirmCardResponse.setupIntent.status === "succeeded"){
          let defaultCardResponse = await genericService.post("dataplan/cards/primary", {
            payment_method: confirmCardResponse.setupIntent.payment_method
          });
          if(defaultCardResponse.data.successful === true) {
            props.loadCards();
            setProcessing("");
          }
        }else{
          setError(confirmCardResponse.error.message);
          return;
        }
      }
    } else {
      setError(response.data.message);
    }
  };

  return (
    <>
      <ErrorDialog showErrorDialog={error} onClose={() => { setError(""); setProcessing(""); props.loadCards(); }} errorMesage={error} />
      <div className="rounded_white d-flex flex-column p-3 justify-items-start p-2">
        <label className="text_17_700 my-0 ">{t("DataPlan.AddNewCard")}</label>

        <div className="row mx-0">
          <div className="col-12 mt-2">
            <form className="w-100" onSubmit={handleSubmit}>
              <CardElement options={CARD_ELEMENT_OPTIONS} />
              <div className="mt-3 d-flex justify-content-end">
                <button
                  className="grey_btn ml-2 "
                  onClick={() => props.setMode("view")}
                  style={{ minWidth: "90px", height: "36px", fontSize: "12px" }}
                >
                  {t("button.Cancel")}
                </button>
                <button
                  type="submit"
                  className="btn_theme ml-3"
                  style={{ minWidth: "90px", height: "36px", fontSize: "12px" }}
                  id="next"
                >
                  {processing === "addCard"
                    ? t("DataPlan.Wait")
                    : t("button.Save")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCard;
