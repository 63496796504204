import Dialog from "@mui/material/Dialog";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import close_gray from "../../assets/images/close_gray.svg";
// import dot_selected from "../../assets/images/dot_selected.svg";
// import dot_unselected from "../../assets/images/dot_unselected.svg";
// import live_tutorial_1 from "../../assets/images/live_tutorial_1.svg";
// import live_tutorial_2 from "../../assets/images/live_tutorial_2.svg";
// import live_tutorial_3 from "../../assets/images/live_tutorial_3.svg";
// import live_tutorial_4 from "../../assets/images/live_tutorial_4.svg";
// import live_tutorial_5 from "../../assets/images/live_tutorial_5.svg";
// import live_tutorial_6 from "../../assets/images/live_tutorial_6.svg";
// import live_tutorial_7 from "../../assets/images/live_tutorial_7.svg";
// import live_tutorial_8 from "../../assets/images/live_tutorial_8.svg";
// import marker_stream from "../../assets/images/marker_stream.svg";
// import backward_arrow_grey from "../../assets/images/backward_arrow_grey.svg";
// import marker_stream2 from "../../assets/images/marker_stream2.svg";
import FlavorImages from "../../assetsFolderImport";
const LiveStreamTutorial = (props) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const clickNext = () => {
    setPage(page + 1);
    if (page + 1 === 9) props.onClose();
  };

  const onClose = () => {
    props.onClose();
  };
  useEffect(() => {
    if (props.show) {
      setPage(1);
    }
  }, [props.show]);

  const renderDotUI = () => {
    return (
      <div className="dot_layout" style={{ marginTop: "10px" }}>
        <img
          alt="Step"
          className="dot "
          src={page === 2 ? FlavorImages.dot_selected : FlavorImages.dot_unselected}
        />
        <img
          alt="Step"
          className="dot ml-2"
          src={page === 3 ? FlavorImages.dot_selected : FlavorImages.dot_unselected}
        />
        <img
          alt="Step"
          className="dot ml-2 "
          src={page === 4 ? FlavorImages.dot_selected : FlavorImages.dot_unselected}
        />
        <img
          alt="Step"
          className="dot ml-2"
          src={page === 5 ? FlavorImages.dot_selected : FlavorImages.dot_unselected}
        />
        <img
          alt="Step"
          className="dot ml-2"
          src={page === 6 ? FlavorImages.dot_selected : FlavorImages.dot_unselected}
        />
        <img
          alt="Step"
          className="dot ml-2"
          src={page === 7 ? FlavorImages.dot_selected : FlavorImages.dot_unselected}
        />
        <img
          alt="Step"
          className="dot ml-2"
          src={page === 8 ? FlavorImages.dot_selected : FlavorImages.dot_unselected}
        />
      </div>
    );
  };

  return (
    <>
      <Dialog className="custome_dialog" open={props.show}>
        <div className="add_device_dialog d-flex flex-row">
          <div className="dialog_side_panel d-md-block d-none w-50">
            <div className="px-0 d-flex flex-column justify-content-center  position-relative ">
              <div
                className={`${page === 1 ? "show_ui" : "hide_ui"}     inner`}
              >
                <div >
                  <img

                    className="streamTutorialPage1_redSignal"

                    src={FlavorImages.marker_stream}
                    alt="..."
                  />
                  <img

                    className="streamTutorialPage1_marker"

                    src={FlavorImages.marker_stream2}
                    alt="..."
                  />
                </div>

                <img
                  className="add_device_logo2 img_liveStream px-5"

                  src={FlavorImages.live_tutorial_1}
                  alt="..."
                />
              </div>
              <div className={`${page === 2 ? "show_ui" : "hide_ui"}   inner `}>
                <img
                  className={`add_device_logo2 px-5  `}
                  src={FlavorImages.live_tutorial_2}
                  alt="..."
                />
              </div>
              <div className={`${page === 3 ? "show_ui" : "hide_ui"}   inner `}>
                <img
                  className={` add_device_logo2 px-5 `}
                  src={FlavorImages.live_tutorial_3}
                  alt="..."
                />
              </div>

              <div className={`${page === 4 ? "show_ui" : "hide_ui"}  inner `}>
                <img
                  className={` add_device_logo2 img_liveStream4 px-5 `}
                  src={FlavorImages.live_tutorial_4}
                  alt="..."
                />
              </div>

              <div className={`${page === 5 ? "show_ui" : "hide_ui"}   inner `}>
                <img

                  className={` add_device_logo5  px-5 `}
                  src={FlavorImages.live_tutorial_5}
                  alt="..."
                />
              </div>

              <div className={`${page === 6 ? "show_ui" : "hide_ui"}   inner`}>
                <img


                  className={` img_liveStream6 px-5 `}
                  src={FlavorImages.live_tutorial_6}
                  alt="..."
                />
              </div>

              <div className={`${page === 7 ? "show_ui" : "hide_ui"}   inner `}>
                <img
                  className={` add_device_logo7 px-5 `}
                  src={FlavorImages.live_tutorial_7}
                  alt="..."
                />
              </div>

              <div className={`${page === 8 ? "show_ui " : "hide_ui"}    `}>
                <img
                  className={` add_device_logo2 img_liveStream5 px-5 `}
                  src={FlavorImages.live_tutorial_8}
                  alt="..."
                />
              </div>
            </div>
          </div>
          <div className="d-flex jusify-content-center width_50_desktop bg-white">
            <div className="w-100 h-100  d-flex flex-column">
              <div className="d-flex  justify-content-between align-items-center p-3 py-4">
                <img
                  className={`${page === 1 ? "hide_ui d-md-block d-none" : "show_ui"} size_25 cursor-pointer `}
                  src={FlavorImages.backward_arrow_grey}
                  onClick={() => setPage(page - 1)}
                  alt="..."
                />
                <label className="text_17_700 width_50_desktop_a pl-sm-2  mb-0 ">
                  {t("LiveStream.HowLivestream")}
                </label>
                <img
                  className="size_25 mr-0 cursor-pointer"
                  src={FlavorImages.close_gray}
                  onClick={() => onClose()}
                  alt="..."
                />
              </div>

              <div className=" px-0 ">
                <div
                  className={`${page === 1 ? "show_ui" : "hide_ui"
                    } d-flex px-4  mt-2 flex-column align-self-stretch inner`}
                >
                  <label className="text_22_700 mt-2" >
                    {t("LiveStream.Livestreaming")}
                  </label>
                  <label className="dialog_body_detail">
                    {t("LiveStream.LivestreamInfo")}
                  </label>
                </div>

                <div
                  className={`${page === 2 ? "show_ui" : "hide_ui"
                    } d-flex px-4  mt-2 flex-column align-self-stretch inner`}
                >
                  <label className="text_22_700" mt-2>
                    {t("LiveStream.ChooseName")}
                  </label>
                  <label className="dialog_body_detail">
                    {t("LiveStream.LiveTutorial1")}
                  </label>
                </div>

                <div
                  className={`${page === 3 ? "show_ui" : "hide_ui"
                    } d-flex px-4  mt-2 flex-column align-self-stretch inner`}
                >
                  <label className="text_22_700" mt-2>
                    {t("LiveStream.ChoosePassword")}
                  </label>
                  <label className="dialog_body_detail">
                    {t("LiveStream.LiveTutorial2")}
                  </label>
                </div>

                <div
                  className={`${page === 4 ? "show_ui" : "hide_ui"
                    } d-flex px-4  mt-2 flex-column align-self-stretch inner`}
                >
                  <label className="text_22_700" mt-2>
                    {t("LiveStream.ChooseMethod")}
                  </label>
                  <label className="dialog_body_detail">
                    {t("LiveStream.LiveTutorial3")}
                  </label>
                </div>

                <div
                  className={`${page === 5 ? "show_ui" : "hide_ui"
                    } d-flex px-4  mt-2 flex-column align-self-stretch inner`}
                >
                  <label className="text_22_700" mt-2>
                    {t("customFile.Choosebike")}
                  </label>
                  <label className="dialog_body_detail">
                    {t("customFile.LiveTutorial4")}
                  </label>
                </div>

                <div
                  className={`${page === 6 ? "show_ui" : "hide_ui"
                    } d-flex px-4  mt-2 flex-column align-self-stretch inner`}
                >
                  <label className="text_22_700" mt-2>
                    {t("LiveStream.StartLivestreaming")}
                  </label>
                  <label className="dialog_body_detail">
                    {t("LiveStream.LiveTutorial5")}
                  </label>
                </div>

                <div
                  className={`${page === 7 ? "show_ui" : "hide_ui"
                    } d-flex px-4  mt-2 flex-column align-self-stretch inner`}
                >
                  <label className="text_22_700" mt-2>
                    {t("LiveStream.ShareWithFirend")}
                  </label>
                  <label className="dialog_body_detail">
                    {t("LiveStream.LiveTutorial6")}
                  </label>
                </div>

                <div
                  className={`${page === 8 ? "show_ui" : "hide_ui"
                    } d-flex px-4  mt-2 flex-column align-self-stretch inner`}
                >
                  <label className="text_22_700" mt-2>
                    {t("LiveStream.Stopstreaming")}
                  </label>
                  <label className="dialog_body_detail">
                    {t("LiveStream.LiveTutorial7")}
                  </label>
                </div>
              </div>

              <div className="row pb-4 mt-auto mx-0 dialog_bottom_height">
                <div className="col-12 p-0 text-center" style={{ marginTop: "-13px" }}>
                  <div
                    className={`${page === 1 ? "show_ui" : "hide_ui"
                      }  col-12 text-center inner`}

                  >
                    <button

                      type="submit"
                      style={{ padding: '10px', marginBottom: "6px" }}
                      className="dialog_btn2 cursor-pointer"
                      onClick={() => setPage(page + 1)}
                      id="next"
                    >
                      {t("LiveStream.ShowHowToLivestream")}
                    </button>
                  </div>

                  <div className={`${page !== 1 ? "show_ui" : "hide_ui"}  `}>
                    <button
                      style={{ marginBottom: "13px", width: page !== 8 ? "79%" : 'auto', padding: page === 8 && "10px" }}
                      type="submit"
                      className="dialog_btn2"
                      onClick={() => clickNext()}
                      id="next"
                    >
                      {page < 8 ? t("signup.Next") : t('LiveStream.IGotThisLetsLiveStream')}
                    </button>
                  </div>
                </div>

                <div className="col-12 text-center">
                  {page > 1 ? renderDotUI() : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default LiveStreamTutorial;
