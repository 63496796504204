import React, { useEffect, useState } from "react";
import SweetAlert from "sweetalert2-react";

let isMounted = false;
const PopUp = (props) => {
  const [show, setState] = useState(true);
  let title = "! Something went wrong";
  let color = "red";
  useEffect(() => {
    isMounted = true;
    props.setError("");
    return () => {
      isMounted = false;
    };
  }, []);
  return (
    <SweetAlert
      show={show}
      title={title}
      text={props.error}
      confirmButtonColor={color}
      onConfirm={() => isMounted && setState(false)}
    />
  );
};
export default PopUp;
