import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { formatDuration, intervalToDuration } from "date-fns";
import * as helper_functions from "../../helpers/helper_functions";
import * as genericService from "../../services/genericService";
import { devicesSlice } from "../../slice/deviceSlice";
import { useHistory } from "react-router-dom";
import { INDEX_URL } from "../../helpers/constants";
import FlavorImages from "../../assetsFolderImport";


const formatDistanceLocale = {
  xSeconds: "{{count}} sec",
  xMinutes: "{{count}} min",
  xHours: "{{count}} h",
  xdays: "{{count}} d"
};
const shortEnLocale = {
  formatDistance: (token, count) =>
    formatDistanceLocale[token].replace("{{count}}", count),
};

const deviceActions = devicesSlice.actions;
const ShowLiveStream = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [copyText, setTextCopied] = useState(false);
  const [streamingLink, setStreamingLink] = useState("Streaming Link");
  const dispatch = useDispatch();

  const copyStreamLink = () => {
    helper_functions.default.copyToClipboard(streamingLink, setTextCopied);
  };

  const editLiveStream = () => {
    history.push(INDEX_URL + "addeditlivestream/" + props.liveStream.id);
  };
  useEffect(() => {
    setStreamingLink(
      process.env.REACT_APP_BASE_URL +
      "viewlivestream/" +
      props.liveStream.token
    );
  }, [props.liveStream]);
  const endLiveStream = async () => {
    const response = await genericService.post("streaminglive/delete", {
      streamid: props.liveStream.id,
    });
    if (response.status === 200) {
      dispatch(deviceActions.setLiveStream({ liveStream: null }));
      dispatch(deviceActions.setStreamingDevice({ device: null }));
      history.push(INDEX_URL + "addeditlivestream");
    }
  };
  return (
    <>
      <React.Fragment>
        <div className={`row mx-1  mt-3 py-3 streaming_div streamingDiv2 ${props.downArrowClick ? " d-sm-none d-md-flex" : "d-md-flex d-none"
          }`}>
          <div className="col-md-1 d-md-block d-none pt-2">
            <img
              alt="Streaming device"
              src={
                FlavorImages.map_marker_malt_red
              }
              className="livestream_image_size"
            />
          </div>
          <div className="col-md-11">
            <div className="row d-md-block d-none">
              <div className="col-12">
                <h5 className="text-white font-bold font_700_italic_gloria">
                  {t("LiveStream.Livestream active")}
                </h5>
              </div>
            </div>
            <div className="row text_15_mobile text-white">
              <div className="col-md-4 col-6">



                <label style={{ color: "white" }} className="text_12_400">  {t("LiveStream.Name")} </label>
                <label style={{ color: "white", fontWeight: 'bold' }} className="text_12_400"> &nbsp; {props.liveStream.name} </label>


              </div>
              <div className="col-md-8 text_15_mobile col-6">

                <label style={{ color: "white" }} className="text_12_400">   {t("LiveStream.Time left")}{" "} </label>
                <label style={{ color: "white", fontWeight: 'bold' }} className="text_12_400"> &nbsp;  {formatDuration(
                  intervalToDuration({
                    start: new Date(),
                    end: new Date(props.liveStream.stoptime),
                  }),
                  {
                    format: ["days", "hours", "minutes"],
                    delimiter: ", ",
                  }
                )} </label>


              </div>
            </div>


            <div className="row text-white">
              <div className="col-md-4 text_15_mobile col-6">



                <label style={{ color: "white" }} className="text_12_400">    {t("LiveStream.Password")}{" "} </label>
                <label style={{ color: "white", fontWeight: 'bold' }} className="text_12_400"> &nbsp; {props.liveStream.password ? "******" : ""} </label>


              </div>
              <div className="col-md-7 text_15_mobile col-6">



                <label style={{ color: "white" }} className="text_12_400">       {t("LiveStream.Active devices")}{" "} </label>
                <label style={{ color: "white", fontWeight: 'bold' }} className="text_12_400"> &nbsp; {props.liveStream.devices.length} </label>


              </div>
            </div>

            <div className="row mt-1 text-white align-items-center">
              <div className="col"> <label style={{ color: "white" }} className="text_12_400">{t("LiveStream.Stream link")}</label></div>
              <div className="col-md-9 pl-0 m-0 mr-md-15 " >
                <div className="input-group">
                  <input
                    value={streamingLink}
                    readOnly
                    type="text"
                    className="form-control liveStreamInput"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />
                  <div
                    onClick={() => {
                      copyStreamLink();
                    }}
                    className="cursor-pointer input-group-append"
                  >
                    <span className="input-group-text">
                      <img
                        className="liveStreamInputIcon"
                        alt="Copy"
                        src={
                          FlavorImages.copy
                        }
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>


            <div className="row mt-2  mr-6 justify-content-end">
              <button
                onClick={() => editLiveStream()}
                className="btn text_11_700button add_button2 mr-2"
                style={{
                  color: "#808080",
                  backgroundColor: "#DDDDDD",
                  borderColor: "#DDDDDD",
                }}
              >
                {t("LiveStream.Edit livestream")}
              </button>
              <button
                onClick={() => endLiveStream()}
                className="btn text_11_700button add_button2 text-white px-3 mr-3"
                style={{ backgroundColor: "#E04D43", borderColor: "#E04D43" }}
              >
                {t("LiveStream.End livestream")}
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default ShowLiveStream;
